import React, { useCallback, useState } from 'react';
import { Button, Row, Tooltip } from 'antd';
import * as Quill from 'quill';
import { useIntl } from 'react-intl';
import { Comment, CommentLookup } from '../../../common/types/entity/Conversation';
import { quillFormats, ReactQuillAdapted } from '../../../common/ReactQuillAdapted';
import { UserLabel } from '../../../common/user';
import { Field, useFormContext, FormContext } from '../../../common/form';
import { AutosaveIndicator } from '../../../common/form/AutosaveIndicator';
import { CheckOutlined, DeleteOutlined, ExclamationOutlined } from '@ant-design/icons';
import useCommentAutosave from './hooks/useCommentAutosave';
import CommentTimestamp from './CommentTimestamp';

export interface EditableCommentProps {
  conversationId: number;
  comment: Comment;
  bounds?: string | HTMLElement;
  modules?: Quill.StringMap;
  onCreate?: (comment: Comment, lookup: CommentLookup) => void;
  onDelete?: (comment: Comment) => void;
}

export default function ({ conversationId, comment, bounds, modules, onDelete, onCreate }: EditableCommentProps) {
  const [toolsVisible, setToolsVisible] = useState(false);
  const [onTaskChange, saving, pending, error] = useCommentAutosave(conversationId, comment, onCreate);
  const context = useFormContext(comment, onTaskChange);

  const onMouseOver = useCallback(() => setToolsVisible(true), []);
  const onMouseLeave = useCallback(() => setToolsVisible(false), []);

  const onClickDelete = useCallback(() => {
    onDelete && onDelete(comment);
  }, [comment, onDelete]);

  return (
    <FormContext.Provider value={context}>
      <div className="comment" onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        {comment.id && (
          <Row className="comment-user" justify="space-between">
            <span>
              <UserLabel user={comment?.user} />:
            </span>
            <span>
              {toolsVisible && <DeleteCommentButton onDelete={onClickDelete} />}
              <SaveIndicator pending={pending} saving={saving} error={error} />
              <CommentTimestamp comment={comment} />
            </span>
          </Row>
        )}
        <Field property="content" valueProperty="defaultValue" controlled={false}>
          <ReactQuillAdapted bounds={bounds} theme="bubble" modules={modules} formats={quillFormats} placeholder={comment.id ? 'Empty comment' : 'Add your new comment here...'} />
        </Field>
      </div>
    </FormContext.Provider>
  );
}

function SaveIndicator({ pending, saving, error }: { pending: boolean; saving: boolean; error?: Error }) {
  return <AutosaveIndicator pending={pending} saving={saving} error={error} pendingIcon={<ExclamationOutlined />} savedIcon={<CheckOutlined />} />;
}

function DeleteCommentButton({ onDelete }: { onDelete: () => void }) {
  const translator = useIntl();
  return (
    <Tooltip title={translator.formatMessage({ id: 'generic.deleteComment' })}>
      <Button className="comment-delete-button" size="small" type="link" onClick={onDelete}>
        <DeleteOutlined />
      </Button>
    </Tooltip>
  );
}
