import { LoggedUser } from '@/common/auth/api/login';
import { AuthHeader, AuthSessionStorageManager } from '@/common/auth/storage/AuthSessionStorageManager';

export const handleDeletedSpace = (user: LoggedUser) => {
  const currentSpaceId = user.acting ? AuthSessionStorageManager.getHeader(AuthHeader.ACT_SPACE_ID) : AuthSessionStorageManager.getHeader(AuthHeader.SPACE_ID);

  const existingSpaceId = user.availableSpaceIds.find(spaceId => spaceId === currentSpaceId);

  if (!existingSpaceId && user.availableSpaceIds.length > 0) {
    // A space is stored in the sessionStorage that does not exist anymore
    AuthSessionStorageManager.switchSpace(user.availableSpaceIds[0], user.acting, user.impersonating, user.accountId);
  } else {
    return;
  }
};
