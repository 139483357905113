import React, { FC, PropsWithChildren } from 'react';

interface ContextFieldProps {
  label: string;
  dashed?: boolean;
  boldenLabel?: boolean
}

export const ContextField: FC<PropsWithChildren<ContextFieldProps>> = ({ label, dashed = true, children, boldenLabel }) => {
  return (
    <span className={boldenLabel ? 'bold-label' : ''}>
      {label}:
      <span
        style={{
          marginLeft: '5px',
          fontWeight: 'bold'
        }}
      >
        {children}
      </span>
    </span>
  );
};
