import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { DocumentLookup } from '../../../common/types/entity/DocumentLookup';

export default (id: string): Promise<DocumentLookup> =>
  requestApi({
    method: 'get',
    url: `/api/document/${id}`,
    service: 'DOCUMENT',
    errors: false
  }).then((response: AxiosResponse | undefined) => response?.data);
