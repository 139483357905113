import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedAssetAllocationInfoConfig } from '..';
import { AssetAllocationInfoConfigMapped } from './types';

export default class AssetAllocationInfoConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as AssetAllocationInfoConfigMapped));
  }

  getVisualConfigProps(config: AssetAllocationInfoConfigMapped): ExtendedAssetAllocationInfoConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      categoryHeadersEnabled: config.categoryHeadersEnabled
    });
  }

  setVisualConfig(config: ExtendedAssetAllocationInfoConfig) {
    super.setVisualConfig(config);
    this.categoryHeadersEnabled = config.categoryHeadersEnabled;
    this.total = true;
    this.transpose = true;
  }

  categoryHeadersEnabled: boolean;
  transpose: boolean;
}
