import { retrieveUserLocale } from '@/utils/locale';
import { UserLocalizedText } from '@/common/types/app/User';
import messages from '../translations/messages';

const getUserLocaleText = (userLocale: string = retrieveUserLocale()): UserLocalizedText => {
  // API call with user locale

  return messages;
};

export { getUserLocaleText };
