import requestApi from '@/core/requestApi';
import { AxiosResponse } from 'axios';
import { QuantCatalogueItemMeta } from '../../../common/types/entity/QuantCatalogueItem';

export default (): Promise<QuantCatalogueItemMeta[]> => {
  return requestApi({
    service: 'cms',
    method: 'get',
    url: `/api/quant-catalogue/meta`
  }).then((response: AxiosResponse<QuantCatalogueItemMeta[]> | undefined) => response?.data!);
};
