import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { DocumentStatus } from '../../../common/types/entity/DocumentStatus';
import { DocumentLookup } from '../../../common/types/entity/DocumentLookup';

export default (status: DocumentStatus = DocumentStatus.CREATED): Promise<DocumentLookup[]> =>
  requestApi({
    method: 'get',
    url: `/api/document/enriched`,
    service: 'DOCUMENT',
    params: {
      page: 0,
      size: 1000,
      status
    }
  }).then((response: AxiosResponse | undefined) => response?.data.content);
