import { Text } from '@/common/components/Typography/Text';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { getDocumentUrl } from '@/modules/DMS/api/getDocumentUrl';
import { message } from 'antd';
import type { ValidatedDocument } from './types';

interface LinkRedirectProps {
  document: ValidatedDocument;
}

const DocumentLinkRedirect: React.FC<LinkRedirectProps> = React.memo(({ document }) => {
  const translator = useIntl();
  const history = useHistory();

  const onClickLink = useCallback(() => {
    getDocumentUrl(document.id)
      .catch(() => {
        message.error(translator.formatMessage({ id: 'generic.somethingWentWrongWhenAttemptingToDownload' }, { documentName: document.name }));
      })
      .then(url => {
        if (url) {
          window.open(url);
        }
      });
  }, [history, document, translator]);

  return (
    <div className="entity-links-single-entity">
      <Text onClick={onClickLink} link>
        {document.name}
      </Text>
    </div>
  );
});

export { DocumentLinkRedirect };
