export enum FieldsProperties {
  BANK_NAME = 'bankName',
  TRADE_PRICE = 'tradePrice',
  DESCRIPTION = 'description',
  NAME = 'name',
  DATE = 'date',
  PORTFOLIO_NAME = 'portfolioName',
  COUNTRY = 'country',
  REPORTING_CURRENCY = 'reportingCurrency',
  LOCAL_CURRENCY = 'localCurrency',
  ADMIN_EMAIL = 'adminEmail',
  ADMIN_FIRST_NAME = 'adminFirstName',
  ADMIN_LAST_NAME = 'adminLastName',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  VALUATION_DATE = 'valuationDate',
  ASSET_NAME = 'assetName',
  LATEST_VALUATION = 'latestValuation',
  LATEST_VALUATION_CURRENCY = 'latestValuationCurrency',
  VEHICLE = 'vehicle',
  OWNERS = 'owners',
  PROPERTY_TYPE = 'propertyType',
  PHOTO_ID = 'photoId',
  FINANCIAL_INSTITUTION = 'financialInstitution',
  OWNERSHIP_PERCENTAGE = 'ownershipPercentage',
  TYPE = 'type',
  ASSET_ID = 'assetId',
  ASSET_TYPE = 'assetType',
  QUANTITY = 'quantity',
  PURCHASE_AMOUNT = 'purchaseAmount',
  PURCHASE_PRICE = 'purchasePrice',
  INIT_MORTGAGE_OUT = 'initMortgageOutStanding',
  INIT_MORTGAGE_AMT = 'initMortgageAmount',
  TRADE_CURRENCY = 'tradeCurrency',
  TRANSACTIONS = 'transactions',
  VALUATION_DETAILS = 'valuationDetails',
  PURCHASE_DATE = 'purchaseDate',
  ACCOUNT_VALUE = 'accountValue',
  CURRENCY_CODE = 'currencyCode',
  ACCESS_CODE = 'accessCode'
}

// split this interface by flow

export enum ValidationPattern {
  NUMBER = '^[0-9- _,_.]*[0-9]$',
  STRING = '(.|\\s)*\\S{3,}(.|\\s)*'
}

export const TradePridePlaceholder = '750 000';
