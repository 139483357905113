import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { getEmptyRawColumn } from '@/modules/reporting-v2/utils/getEmptyRawColumn';
import RawAllocationPieConfig from './types';

export const config: RawAllocationPieConfig = {
  type: 'visual',
  breakdown: getEmptyRawColumn(),
  field: getEmptyRawColumn(),
  chartOnly: false,
  _options: {
    pieHole: 0.4,
    legend: {
      position: 'right',
      alignment: 'center'
    }
  },
  _reportContext: VisualReportContext.NONE,
  _labelCrop: false,
  _chartSize: 70,
  _pieHole: 49
};
