import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout, Result, Row } from 'antd';
import './style.css';
import retrievePageTemplate, { PageTemplate } from '@/utils/retrievePageTemplate';

interface EmptyResultProps {
  titleId: string;
  titleSubTitleId: string;
  icon: ReactNode;
  titleSubTitleValues?: Record<string, string>;
}

export const EmptyResult: React.FC<{ children: ReactNode } & EmptyResultProps> = ({ titleId, titleSubTitleId, titleSubTitleValues, icon, children }) => {
  const currentPageTemplate = retrievePageTemplate();
  if (titleSubTitleValues?.entityType === 'holdingSet') {
    titleSubTitleValues.entityType = currentPageTemplate === PageTemplate.PORTFOLIO_VIEW_LEGAL_ENTITY ? 'legal entity' : 'portfolio';
}
  
  return (
    <Layout className='empty-result-container'>
      <Row className="ant-layout-content" align="middle" justify="center">
        <Result
          className="no-margin-bottom empty-entity-result"
          icon={icon}
          title={<FormattedMessage id={titleId} />}
          subTitle={<FormattedMessage id={titleSubTitleId} values={titleSubTitleValues} />}
          extra={children}
        />
      </Row>
    </Layout>
  );
};
