import type { RawColumn } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';

export const getEmptyRawColumn = (): RawColumn => {
  return {
    defaultColumns: [],
    columns: [],
    filters: {},
    options: {}
  };
};
