import { useCallback } from 'react';
import { useSetRecoilState, useResetRecoilState } from 'recoil';
import { snapIndexState } from '@/modules/report-builder/recoil/atoms';
import { RBConfigurationSelector } from '@/modules/report-builder/recoil/selectors';

const useSetConfiguration = () => {
  const setConfiguration = useSetRecoilState(RBConfigurationSelector);
  const setSnapIndex = useResetRecoilState(snapIndexState);
  return useCallback(
    config => {
      setConfiguration(config);
      setSnapIndex();
    },
    [setSnapIndex, setConfiguration]
  );
};

export default useSetConfiguration;
