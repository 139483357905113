import dayjs from 'dayjs';
import { getChart, mergeChartOptions } from '@/modules/reporting-v2/core/components/Highcharts';
import { parseChartStyles } from '@/modules/Styling/utils/parseChartStyles';
import { type RawReportConfig, VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { injectCoverPageStyles } from '@/modules/Styling/utils/injectCoverPageStyles';
import { rbColorToHexAlpha } from '@/utils/toHexAlpha';
import { objectDeepMerge } from '@/utils/objectDeepmerge';
import { getRecoilState } from '@/core/RecoilExternalStatePortal';
import { companyBranding } from '@/recoil/branding';
import { parseStyles } from '@/modules/Styling/utils/parseStyles';
import type { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import type { ChartOptions } from '@/modules/reporting-v2/core/components/Highcharts/types';
import type { HtmlPrintObject } from '@/modules/reporting-v2/types/Print';
import type { RowGroup } from '../types/VisualEngine';
import type { Options } from 'highcharts';

// wait for 1fps = 1000/60s = ~16
export const waitForDOM = (ms: number = 1000 / 60): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

const getHTML = async (reportId: string, visuals: Map<string, VisualEngine>, reportConfig?: RawReportConfig): Promise<HtmlPrintObject> => {
  const reportContainer = document.querySelector(`#report-${reportId}`) as HTMLElement;

  if (!reportContainer) {
    return { content: '' };
  }

  // clone report content for html processing
  const ghostWrapper = document.createElement('div');
  ghostWrapper.setAttribute('style', 'position:absolute;top:0;left:0;width:0;height:0;overflow:hidden;z-index:-1;');
  document.body.appendChild(ghostWrapper);

  const reportContainerClone = reportContainer.cloneNode(true) as HTMLElement;

  ghostWrapper.appendChild(reportContainerClone);

  Array.from(reportContainerClone.querySelectorAll<HTMLElement>('div[data-highcharts-chart]')).forEach(chartWrapper => {
    const chartId = parseInt(chartWrapper.getAttribute('data-highcharts-chart')!);
    const chart = getChart(chartId);

    // remove chart if no height available
    if (!chartWrapper.offsetHeight) {
      chartWrapper.remove();
      return;
    }

    if (chart) {
      const visualId = (chartWrapper.parentNode?.parentNode as HTMLElement)?.getAttribute('id')?.split('report-visual-')[1];
      let chartStyles: Options = {};

      if (visualId) {
        const visual = visuals.get(visualId);
        if (visual?.component !== VisualComponent.TreeMap) {
          const companyStyles = getRecoilState(companyBranding);
          const mergedReportStyles = objectDeepMerge(companyStyles.styles ?? {}, reportConfig?.config?.customStyling ? reportConfig.config?.styles ?? {} : {});
          const parsedStyles = parseStyles(mergedReportStyles);
          const finalStyles = visual?.styles ? objectDeepMerge(parsedStyles, visual.styles) : parsedStyles;

          chartStyles = parseChartStyles(finalStyles);
          if (visual?.styles?.colorScheme?.linkToVisualColorScheme) {
            const refChartVisual = visuals.get(visual?.styles?.colorScheme?.linkToVisualColorScheme);

            if (refChartVisual) {
              const refChartColors = parseChartStyles(refChartVisual.styles ?? {}).colors;

              chartStyles.plotOptions = {};
              let colors: string[] = [];

              if (visual.component === VisualComponent.HistoricalChart) {
                // there is no way to get right groups from a set of historical data
                // set colors on legends instead
                const legends = Array.from(chartWrapper.querySelectorAll<SVGTextElement>('.highcharts-legend text')).map(text => text.textContent);
                colors = legends.map(group => {
                  const chartGroupIndex = (refChartVisual.data.rows as RowGroup[]).findIndex(row => row.group === group);
                  return refChartColors?.[chartGroupIndex] ?? '#000';
                });
              } else {
                const refChartLegends = Array.from(reportContainerClone.querySelectorAll<SVGTextElement>(`#report-visual-${refChartVisual.id} .highcharts-legend text`)).map(
                  text => text.textContent
                );

                if (visual.groupByColumns.filter(groupColumn => groupColumn.isDefault).length > 1) {
                  colors = (visual.data.rows[0] as RowGroup)?.rows.map(row => {
                    const chartGroupIndex = refChartLegends.findIndex(group => group === (row as RowGroup).group);
                    return refChartColors?.[chartGroupIndex] ?? '#000';
                  });
                } else {
                  colors = visual.data.rows.map(row => {
                    const chartGroupIndex = refChartLegends.findIndex(group => group === (row as RowGroup).group);
                    return refChartColors?.[chartGroupIndex] ?? '#000';
                  });
                  chartStyles.plotOptions.column = { colorByPoint: true };
                }
              }

              chartStyles.colors = colors;
              chartStyles.plotOptions.bar = { colorByPoint: true };
            }
          }
        }
      }

      const svg = document.createElement('div');

      const exportOptions = {
        chart: {
          width: chartWrapper.offsetWidth,
          height: chartWrapper.offsetHeight
        },
        plotOptions: {
          series: { animation: false }
        }
      };

      svg.innerHTML = chart.getSVG(mergeChartOptions(exportOptions, chartStyles as ChartOptions));

      const className = (chartStyles as ChartOptions)?.chart?.className;
      if (className) {
        svg.firstElementChild?.classList.add(className);
      }

      (svg.firstChild as HTMLElement).style.display = 'block';
      chartWrapper.replaceWith(svg.firstChild!);
    }
  });

  // no break lines for dates
  // todo: we need to find better solution = metadata for dates fields has formatting.type = "string", should be "date"
  Array.from(reportContainerClone.querySelectorAll<HTMLTableElement>('.report-data-table table')).forEach(table => {
    const row = table.tBodies[0]?.rows[0];
    if (row) {
      Array.from(row.cells).forEach((cell, index) => {
        if (dayjs(cell.innerText, true).isValid()) {
          Array.from(table.querySelectorAll<HTMLTableCellElement>(`tbody tr td:nth-child(${index + 1})`)).forEach(cell => {
            cell.style.whiteSpace = 'nowrap';
          });
        }
      });
    }
  });

  Array.from(reportContainerClone.getElementsByClassName('group-row')).forEach(groupRow => {
    groupRow.classList.add('active');
  });

  Array.from(reportContainerClone.getElementsByClassName('visual-interactivity')).forEach(visualInteractivity => {
    visualInteractivity.remove();
  });

  Array.from(reportContainerClone.querySelectorAll<HTMLElement>('.report-data-table table:not(.collapsible) tbody tr:not(.group-row)')).forEach(row => {
    if (row.style.display === 'none') row.remove();
  });

  Array.from(reportContainerClone.querySelectorAll<HTMLElement>('table tbody')).forEach(tableBody => {
    Array.from(tableBody.querySelectorAll<HTMLElement>('.legend-circle-color')).forEach(legend => {
      legend.style.backgroundColor = legend.dataset.printColor!;
    });
  });

  Array.from(reportContainerClone.querySelectorAll<HTMLElement>('.report-wrapper-table .highcharts-root')).forEach(highChartVisual => {
    highChartVisual.closest('.visual-wrapper')?.classList.add('no-break');
  });

  for (const [id, config] of visuals) {
    const visual = reportContainerClone.querySelector<HTMLElement>(`.report-page:not(.print) #report-visual-${id}, .report-page#disclaimer #report-visual-${id}`);
    if (visual) {
      try {
        // injectStyles(visual, config.styles ?? {});
        injectCoverPageStyles(reportContainerClone, config.styles ?? {});
      } catch (err) {
        console.error(err);
      }
      // fix height for text image visuals
      if (visual.dataset.component === VisualComponent.TextImage && visual.querySelector<HTMLDivElement>('.visual-wrapper div:last-child')?.style.backgroundImage) {
        visual.closest('td')?.classList.add('fix-height');
      }
    }
  }

  Array.from(reportContainerClone.querySelectorAll<HTMLElement>('.nowrap')).forEach(element => {
    let width = 0;

    if (element.classList.contains('visual-title')) {
      element.classList.remove('nowrap');
      element.style.width = 'auto';
      width = element.offsetWidth;
      element.classList.add('nowrap');
    } else {
      width = element.offsetWidth;
    }

    element.style.width = width + 'px';
  });

  Array.from(reportContainerClone.querySelectorAll<HTMLElement>('.text-image-content')).forEach(element => {
    const p = element.querySelectorAll('p');
    p.forEach(text => {
      text.style.margin = '0';
    });
    const span = element.querySelectorAll('span');
    span.forEach(text => {
      text.style.margin = '0';
    });
  });

  const hideSortableIcon = reportConfig?.config.styles?.others?.hideSortableIcons;
  if (hideSortableIcon) {
    reportContainerClone.classList.add('print-no-sortable-icon');
  }

  for (const page of reportConfig?.pages ?? []) {
    if (!page.titleStyle) {
      continue;
    }

    const pageTitleElement = reportContainerClone.querySelector<HTMLDivElement>(`#report-page-${page.id} .report-page-title`)!;
    if (!pageTitleElement) {
      continue;
    }

    const labelCollection = pageTitleElement.getElementsByTagName('label');
    const pageTitleLabel = labelCollection && (Array.from(labelCollection)[0] as HTMLLabelElement | undefined);

    if (!pageTitleLabel) {
      continue;
    }

    const { fontStyle, fontColor } = page.titleStyle;
    const fontSize = fontStyle?.fontSize ? fontStyle?.fontSize + 'px' : undefined;
    const color = rbColorToHexAlpha(fontColor);

    pageTitleElement.style.display = 'block';

    if (fontStyle?.fontFamily) {
      pageTitleLabel.style.fontFamily = fontStyle?.fontFamily;
    }
    if (fontSize) {
      pageTitleLabel.style.fontSize = fontSize;
    }
    if (fontStyle?.fontStyle) {
      pageTitleLabel.style.fontStyle = fontStyle?.fontStyle;
    }
    if (color) {
      pageTitleLabel.style.color = color;
    }
  }

  const returnObject: HtmlPrintObject = {};

  const cover = reportContainerClone.querySelector<HTMLElement>('#cover');
  const disclaimer = reportContainerClone.querySelector<HTMLElement>('#disclaimer');
  const backCover = reportContainerClone.querySelector<HTMLElement>('#backCover');

  const getPagesWrapper = () => {
    return reportContainerClone.cloneNode(false) as HTMLElement;
  };

  const insertEmptyPageForPaginationWorkaround = () => {
    const emptyPage = document.createElement('div');
    emptyPage.style.pageBreakAfter = 'always';
    emptyPage.style.visibility = 'hidden';
    emptyPage.textContent = 'empty';

    reportContainerClone.insertBefore(emptyPage, reportContainerClone.firstElementChild);
  };

  if (cover) {
    reportContainerClone.removeChild(cover);
    const cloneWrapper = getPagesWrapper();
    cloneWrapper.appendChild(cover);
    returnObject.cover = cloneWrapper.outerHTML;

    insertEmptyPageForPaginationWorkaround();
  }

  if (disclaimer) {
    reportContainerClone.removeChild(disclaimer);
    const cloneWrapper = getPagesWrapper();
    cloneWrapper.appendChild(disclaimer);
    returnObject.disclaimer = cloneWrapper.outerHTML;
  }

  if (backCover) {
    reportContainerClone.removeChild(backCover);
    const cloneWrapper = getPagesWrapper();
    cloneWrapper.appendChild(backCover);
    returnObject.backCover = cloneWrapper.outerHTML;
  }

  const reportHasContent = reportContainerClone.querySelector('.report-page');
  if (reportHasContent) {
    returnObject.content = reportContainerClone.outerHTML;
  }

  document.body.removeChild(ghostWrapper);

  return returnObject;
};

export default getHTML;
