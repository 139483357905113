import { message } from 'antd';
import { nanoid } from 'nanoid';
import { FieldResolver } from '@/modules/reporting-v2/types/FieldResolver';
import { RawFilter } from '@/modules/reporting-v2/types/Filter';
import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import { concatenateUniqueIndexFields, ConfigMappedRawColumn } from '@/modules/reporting-v2/utils/IndexUtils';
import { FilterOperator } from '@/types/Filters';
import { duplicateColumnSeparator } from '@/utils/getGenericFieldPath';
import { Condition } from './Condition';
import { Field } from './Field';
import { Column } from '@/modules/reporting-v2/core/Column';

type ConditionValue = string | number | boolean;

type FromRawFilterReturnType<T> = T extends Field ? Filter : Filter | undefined;

export const captureFilterValues = (filter: RawFilter): Array<Field | string> => {
  const values = [];

  if (filter.column && filter.column.defaultColumns) {
    values.push(...filter.column.defaultColumns.map(col => new Field(col)));
  } else if (filter.value) {
    values.push(...filter.value);
  } else if (filter.compareWith?.column && filter.compareWith?.column?.defaultColumns) {
    values.push(...filter.compareWith.column.defaultColumns.map(col => new Field(col)));
  } else if (filter.compareWith?.value) {
    values.push(...filter.compareWith.value);
  }

  return [...new Set(values)];
};

class Filter implements FieldResolver {
  constructor(field: Field, condition: Condition, selectBoxId?: string, isDefault?: boolean, isDateSlicerFilter?: boolean, optionalFieldsFallbacks?: string[]) {
    this.field = field;
    this.condition = condition;
    this.selectBoxId = selectBoxId;
    this.isDefault = isDefault;
    this.isDateSlicerFilter = isDateSlicerFilter;
    this.optionalFieldsFallbacks = optionalFieldsFallbacks;
    this.filterId = nanoid(5);
  }

  field: Field;
  condition: Condition;
  filterId: string;
  selectBoxId?: string;
  isDefault?: boolean;
  isDateSlicerFilter?: boolean;
  optionalFieldsFallbacks?: string[];

  evaluate(row: FlattenObject): boolean {
    const hasNoValuesToCompare = this.condition.values.length === 0;
    const isUndefinedFilter = this.condition.operator === FilterOperator.UNDEFINED_VALUES || this.condition.operator === FilterOperator.EQUALS_OR_UNDEFINED;
    if (hasNoValuesToCompare && !isUndefinedFilter) {
      return true;
    }

    let rowValue: ConditionValue;

    if (this.optionalFieldsFallbacks) {
      rowValue = this.optionalFieldsFallbacks.reduce<ConditionValue | undefined>(
        (acc, field) => (acc ? acc : (row[new Field(field).getElasticPath()] as ConditionValue)),
        undefined
      )!;
    } else {
      rowValue = row[this.field.getElasticPath()]! as ConditionValue;
    }

    const isValueNullish = rowValue === undefined || rowValue === null;
    if (isValueNullish) {
      return !!this.isDateSlicerFilter || isUndefinedFilter;
    }

    const values = this.condition.values
      .map(val => (val instanceof Field ? row[val.getFieldDataPath()] : val))
      .filter((val): val is ConditionValue => val !== undefined && val !== null);

    switch (this.condition.operator) {
      case FilterOperator.STARTS_WITH: {
        return values.some(value => rowValue.toString().toLowerCase().trim().startsWith(value.toString().toLowerCase().trim()));
      }

      case FilterOperator.CONTAINS: {
        return values.some(value => rowValue.toString().toLowerCase().trim().includes(value.toString().toLowerCase().trim()));
      }

      case FilterOperator.DOES_NOT_CONTAIN: {
        return values.every(value => !rowValue.toString().toLowerCase().trim().includes(value.toString().toLowerCase().trim()));
      }

      case FilterOperator.EQUALS_OR_UNDEFINED:
      case FilterOperator.EQUALS: {
        return values.some(value => (this.isDateSlicerFilter ? value === rowValue : value.toString().toLowerCase().trim() === rowValue.toString().toLowerCase().trim()));
      }

      case FilterOperator.NOT_EQUAL: {
        return values.every(value => (this.isDateSlicerFilter ? value !== rowValue : value.toString().toLowerCase().trim() !== rowValue.toString().toLowerCase().trim()));
      }

      case FilterOperator.GREATER_THAN: {
        return values.some(value => (this.isDateSlicerFilter ? rowValue > value : rowValue > Number(value)));
      }

      case FilterOperator.GREATER_OR_EQUAL: {
        return values.some(value => (this.isDateSlicerFilter ? rowValue >= value : rowValue >= Number(value)));
      }

      case FilterOperator.LESS_THAN: {
        return values.some(value => (this.isDateSlicerFilter ? rowValue < value : rowValue < Number(value)));
      }

      case FilterOperator.LESS_OR_EQUAL: {
        return values.some(value => (this.isDateSlicerFilter ? rowValue <= value : rowValue <= Number(value)));
      }

      case FilterOperator.UNDEFINED_VALUES: {
        return rowValue === undefined || rowValue === null;
      }

      default:
        console.error('Unknown operator: ' + this.condition.operator);
        return true;
    }
  }

  getAllUsedFields(): Field[] {
    return concatenateUniqueIndexFields([this.field], this.condition.getAllUsedFields());
  }

  static fromRawFilter<T extends undefined | Field = undefined>(rawFilter: RawFilter, columns: ConfigMappedRawColumn[] | Column[], initialField: T): FromRawFilterReturnType<T> {
    const hasBaseColumn = rawFilter.baseColumn && rawFilter.baseColumn.defaultColumns?.[0];
    if (!initialField && !hasBaseColumn) {
      return undefined as FromRawFilterReturnType<T>;
    }

    if (!hasBaseColumn && initialField) {
      return new Filter(initialField, new Condition(rawFilter.operator, captureFilterValues(rawFilter)));
    }

    let fieldPath = undefined;
    const path = rawFilter.baseColumn!.defaultColumns[0];
    if (path.includes(duplicateColumnSeparator)) {
      const pathId = (columns as Column[]).find(column => column.initialFieldPath === rawFilter.baseColumn?.defaultColumns[0])?.id;

      if (!pathId) {
        const errorMessage = `Filter for column ${initialField?.getElasticPath()} is pointing at fields that have been modified. Please update the configuration`;
        message.error(errorMessage);
      }

      fieldPath = new Field(rawFilter.baseColumn!.defaultColumns[0]).getElasticPath() + pathId;
    } else {
      fieldPath = new Field(rawFilter.baseColumn!.defaultColumns[0]).getElasticPath();
    }

    return new Filter(new Field(fieldPath), new Condition(rawFilter.operator, captureFilterValues(rawFilter)));
  }
}

export { Filter };
