import { getTheme } from '@/modules/reporting-v2/utils/theme';
import { HeaderFooterGenerator } from '@/modules/reporting-v2/types/HeaderFooter';

const ratio = 0.955;

export const ltsHeader: HeaderFooterGenerator = ({ pageProperties }): string => {
  return `
  <style>
    #header {
      padding: 0;
      height: 100%;
      width: 100%;
      -webkit-print-color-adjust: exact;
    }
    #header .header-container {
      width: 100%;
      height: 100%;
      font-family: Lato, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
    #header table {
      width: 100%;
      height: 100%;
      border-collapse: collapse;
      border-spacing: 0;
    }

    .black-band {
      height: 100%;
      width: ${50 * ratio * pageProperties.ratio}px;
      background-color: black;
    }
  </style>
  <div id="header" class="report-theme-${getTheme()}">
    <div class="header-container">
      <table>
        <tr>
          <td></td>
          <td class="black-band"></td>
        </tr>
      </table>
    </div>
  </div>
  `;
};
