import React, { memo, useCallback } from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

type OmitKeys = 'onChange' | 'value';

interface TextInputProps extends Omit<InputProps, OmitKeys> {
  value?: string;
  onChange?: (value: string) => void;
}

const TextInput: React.FC<TextInputProps> = memo(({ onChange, ...props }) => {
  const _onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e.target.value);
    },
    [onChange]
  );

  return <Input {...props} onChange={_onChange} />;
});

export { TextInput };
