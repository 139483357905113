import React from 'react';
import { Column } from '@/modules/reporting-v2/core/Column';
import { getColumns } from '@/modules/reporting-v2/utils/IndexUtils';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { Field } from '@/modules/reporting-v2/core/Field';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { Row, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import { config } from './config';
import RawTransposeTableConfig from './types';
import schema from './schema.json';
import TransposeTableConfig from './TransposeTableConfig';
import { computeReferences } from '../../utils';
import { DataTablev2 } from '@/modules/reporting-v2/core/components/DataTablev2/DataTable';

type ColumnGroup = {
  fields: Array<{ path: string; value: Primitive }>;
  columns: Set<string>;
};

class TransposeTable extends Visual {
  Loader = Loaders.Table;

  static configMapper(visualConfig: RawTransposeTableConfig) {
    const { columns, valueField, rowField, columnField, group, groupCategories, ...rest } = visualConfig;

    const mappedGroup = mapVisualConfigColumns(group);
    const mappedGroupCategories = mapVisualConfigColumns(groupCategories);
    const mappedColumns = mapVisualConfigColumns(columns);
    const mappedRowField = mapVisualConfigColumns(rowField);
    const mappedColumnField = mapVisualConfigColumns(columnField);
    const mappedValueField = mapVisualConfigColumns(valueField);

    mappedColumns.push(...mappedValueField, ...mappedRowField, ...mappedColumnField, ...mappedGroupCategories);

    return {
      ...rest,
      columns: mappedColumns,
      valueField: getColumns(mappedValueField),
      rowField: getColumns(mappedRowField),
      columnField: getColumns(mappedColumnField),
      group: mappedGroup,
      groupCategories: getColumns(mappedGroupCategories)
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as VisualEngine;
  }

  getSchema() {
    return schema;
  }

  getVisual() {
    return this.props.visual as TransposeTableConfig;
  }

  getRowColumns() {
    return this.getVisual().rowField;
  }

  getValueColumn() {
    return this.getVisual().valueField[0];
  }

  getColumnFieldColumn() {
    return this.getVisual().columnField[0];
  }

  getGroupCategories() {
    return this.getVisual().groupCategories;
  }

  getColumnGroupFieldPath(columnGroup: ColumnGroup, columnValue: string | number): string {
    const headersValuesValues = columnGroup.fields.map(field => field.value);
    const fieldPath = `${headersValuesValues.join('-')}-${columnValue}`;

    return fieldPath;
  }

  flattenData(rows: (Row | RowGroup)[]): FlattenObject[] {
    return rows.flatMap(row => {
      if ('rows' in row) return this.flattenData(row.rows);
      return [row.data];
    });
  }

  /**
   * @description check in the list of columnGroups if a columnGroup with the same value
   * for each "groupCategory columns" exist
   * **If it doesn't exist, create one and push it to the list**
   */
  getColumnGroup(columnGroups: ColumnGroup[], columnValue: string | number, originalRow: FlattenObject) {
    const columnGroupFields: ColumnGroup['fields'] = this.getGroupCategories().map(col => {
      return {
        path: col.fieldDataPath,
        value: originalRow[col.fieldDataPath]
      };
    });

    const columnGroupAlreadyExist = columnGroups.find(columnGroup => {
      const hasNoColumnGroups = columnGroup.fields.length === 0 && columnGroupFields.length === 0;
      if (hasNoColumnGroups) {
        return columnGroup;
      }

      const allHeaderValuesAreMatching = columnGroup.fields.every(field => {
        const columnGroupField = columnGroupFields.find(groupField => groupField.path === field.path);
        return field.value === columnGroupField?.value;
      });
      return allHeaderValuesAreMatching;
    });

    if (columnGroupAlreadyExist) {
      columnGroupAlreadyExist.columns.add(columnValue + '');
      return columnGroupAlreadyExist;
    }

    const newColumnGroup: ColumnGroup = {
      columns: new Set([columnValue + '']),
      fields: columnGroupFields
    };
    columnGroups.push(newColumnGroup);

    return newColumnGroup;
  }

  /**
   * @description Build the "fieldPath" based on columnGroup and value of "column column"
   * then add the value of "value column" in the row
   */
  populateRowWithValue(originalRow: FlattenObject, rowToUpdate: FlattenObject, columnGroups: ColumnGroup[], columnColumn: Column, valueColumn: Column, headerColumns: Column[]) {
    const columnValue = originalRow[columnColumn.fieldDataPath];
    const columnValueIsString = typeof columnValue === 'string';
    const columnValueIsNumber = typeof columnValue === 'number';
    if (!(columnValueIsString || columnValueIsNumber)) {
      return;
    }

    const columnGroup = this.getColumnGroup(columnGroups, columnValue, originalRow);

    rowToUpdate[this.getColumnGroupFieldPath(columnGroup, columnValue)] = originalRow[valueColumn.fieldDataPath];
  }

  /**
   * @description check in the list of new rows if a row with the same value for each
   * references already exist. This is done to prevent duplication of values for "row columns".
   * **If the row is new, it is added to the list of new rows**
   */
  getRowToUpdate(newRows: FlattenObject[], originalRow: FlattenObject, references: Field[]) {
    const rowAlreadyExist = newRows.find(newRow => {
      return references.every(ref => {
        const path = ref.getElasticPath();
        return newRow[path] === originalRow[path];
      });
    });

    if (rowAlreadyExist) {
      return rowAlreadyExist;
    }

    const newRow = { ...originalRow };
    newRows.push(newRow);

    return newRow;
  }

  getData(flattenedData: FlattenObject[]) {
    const newRows: FlattenObject[] = [];
    const columnGroups: ColumnGroup[] = [];

    const rowsColumns = this.getRowColumns();
    const columnColumn = this.getColumnFieldColumn();
    const valueColumn = this.getValueColumn();
    const headerColumns = this.getGroupCategories();

    let referenceRowColumn = rowsColumns[0];
    if (rowsColumns.length > 1) {
      referenceRowColumn = rowsColumns[1];
    }
    const references = computeReferences(referenceRowColumn.field.getIndexNode(), columnColumn.field.getIndexNode());

    for (const originalRow of flattenedData) {
      const rowToUpdate = this.getRowToUpdate(newRows, originalRow, references);

      references.forEach(reference => {
        const path = reference.getElasticPath();
        rowToUpdate[path] = originalRow[path];
      });
      rowsColumns.forEach(col => {
        rowToUpdate[col.fieldDataPath] = originalRow[col.fieldDataPath];
      });

      this.populateRowWithValue(originalRow, rowToUpdate, columnGroups, columnColumn, valueColumn, headerColumns);
    }

    const sortColumns = this.getVisual().sortColumns;
    if (sortColumns) {
      this.sortColumnGroups(columnGroups, sortColumns);
    }

    return { rows: newRows, columnGroups };
  }

  sortColumnGroups(columnGroups: ColumnGroup[], order: 'ASC' | 'DESC') {
    columnGroups.forEach(cg => {
      const columns = [...cg.columns];
      const sortedColumns = columns.sort((a, b) => {
        if (order === 'ASC') {
          return a.localeCompare(b);
        } else {
          return b.localeCompare(a);
        }
      });

      cg.columns = new Set(sortedColumns);
    });
  }

  getColumns(columnGroups: ColumnGroup[]) {
    const rowsColumns = this.getRowColumns();
    const valueColumn = this.getValueColumn();

    const newCols = columnGroups.flatMap(columnGroup => {
      return [...columnGroup.columns].map(columnValue => {
        const fieldPath = this.getColumnGroupFieldPath(columnGroup, columnValue);
        const field = new Field(fieldPath);

        return new Column({
          ...valueColumn,
          field: field,
          id: fieldPath,
          headerConfig: {
            displayName: columnValue + ''
          }
        });
      });
    });

    return [...rowsColumns, ...newCols];
  }

  getColumnHeaders(columnGroups: ColumnGroup[]) {
    const numberOfRowColumns = this.getRowColumns().length;

    const columnHeaders = [{ displayName: '', colSpan: numberOfRowColumns }];

    columnGroups.forEach(({ fields, columns }) => {
      const displayName = fields.map(field => field.value).join(' | ');

      columnHeaders.push({
        displayName: displayName,
        colSpan: columns.size
      });
    });

    return columnHeaders;
  }

  renderBody() {
    const flattenedData = this.flattenData(this.props.visual.data.rows);

    const { rows, columnGroups } = this.getData(flattenedData);
    const columnsHeaders = this.getColumnHeaders(columnGroups);
    const columns = this.getColumns(columnGroups);

    const visualClone = Object.assign({}, this.props.visual);
    Object.setPrototypeOf(visualClone, VisualEngine.prototype);
    visualClone.columns = columns;
    visualClone.sortable = false;

    visualClone.generateProcessedData(rows, this.context.globalFilters, this.context.filters);

    return (
      <DataTablev2
        visual={
          {
            ...visualClone,
            columnsHeaders,
            displayCategories: this.getGroupCategories().length > 0
          } as IDataTableProps['visual']
        }
      />
    );
  }
}

export default TransposeTable;
