import React from 'react';
import ContentLoader from 'react-content-loader';

const Table: React.FC = props => {
  return (
    <ContentLoader width="100%" height="120" {...props}>
      <rect rx="2" ry="2" y="11.6666667%" width="30%" height="10" />
      <rect rx="2" ry="2" x="42.5%" y="11.6666667%" width="3%" height="10" />
      <rect rx="2" ry="2" x="55%" y="11.6666667%" width="4%" height="10" />
      <rect rx="2" ry="2" x="71%" y="11.6666667%" width="16%" height="10" />
      <rect rx="2" ry="2" x="93.5%" y="11.6666667%" width="5%" height="10" />
      <rect y="32.9166667%" width="100%" height="0.5" />

      <rect rx="2" ry="2" y="45%" width="20%" height="10" />
      <rect rx="2" ry="2" x="40%" y="45%" width="8%" height="10" />
      <rect rx="2" ry="2" x="54.5%" y="45%" width="5%" height="10" />
      <rect rx="2" ry="2" x="72.5%" y="45%" width="13%" height="10" />
      <rect rx="2" ry="2" x="94.5%" y="45%" width="3%" height="10" />
      <rect y="66.25%" width="100%" height="0.5" />

      <rect rx="2" ry="2" y="78.3333333%" width="25%" height="10" />
      <rect rx="2" ry="2" x="40.5%" y="78.3333333%" width="7%" height="10" />
      <rect rx="2" ry="2" x="53%" y="78.3333333%" width="8%" height="10" />
      <rect rx="2" ry="2" x="70%" y="78.3333333%" width="18%" height="10" />
      <rect rx="2" ry="2" x="92%" y="78.3333333%" width="8%" height="10" />
    </ContentLoader>
  );
};

export default Table;
