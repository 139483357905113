import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { TaskLookup, TaskType } from '../../../common/types/entity/Task';
import EntityRef from '../../../common/types/entity/EntityRef';
import { Status } from '@/modules/ERP/Contacts/types';

export class SearchCriteria {
  constructor(
    public search: string = '',
    public types: TaskType[] = [],
    public statuses: Status[] = [],
    public assignees: EntityRef[] = [],
    public holdingSetIds: number[] = [],
    public unassigned: boolean = false,
    public uncategorized: boolean = false,
    public refGuid?: string,
    public refId?: number,
    public refName?: string
  ) {}
}

export default (filters: SearchCriteria): Promise<TaskLookup[]> => {
  return requestApi({
    service: 'collaboration',
    method: 'post',
    url: `/api/task/search`,
    data: filters
  }).then((response: AxiosResponse<TaskLookup[]> | undefined) => response?.data!);
};
