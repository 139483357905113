import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Empty, Flex, Menu, message, Popover, Row, Typography } from 'antd';
import { Text } from '@/common/components/Typography/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActivityType, EntityLinkType, EntityLinkTypeMenu, LinkableObject, LinkableObjectList, LinkLookup } from '@/common/types/entity/Link';
import { withSmallSuspense } from '@/common/suspense';
import { useLinksData } from '@/modules/ERP/Contacts/Hooks/useLinksData';
import { existingListObjectsSelector, linkableObjectsSelector, linkObjectSelector } from '@/modules/ERP/Common/Recoil/links.selector';
import { useRecoilCallback, useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from 'recoil';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { ViewAll } from './ViewAll';
import { filterObjectByName, sortEntityType, translateEntityObjectName } from '@/modules/ERP/Common/utils';
import { createLink } from '@/modules/ERP/Contacts/Api/createLink';
import { UUID } from '@/common/types/types';
import { entityLinksSelector } from '@/modules/ERP/Common/Recoil/erp.selectors';
import { deleteLink } from '@/modules/ERP/Contacts/Api/deleteLink';
import { entityActivitySelector } from '@/modules/ERP/Contacts/Recoil/contacts.selectors';
import { AssetLinkRedirect } from '@/modules/ERP/Asset/AssetDetails/Links';
import { DocumentLinkRedirect } from '../Documents/Links';
import { ContactLinkRedirect } from '../Contacts/Links';
import { TaskLinkRedirect } from '../Tasks/Links';
import { NoteLinkRedirect } from '../Notes/Links';
import { HoldingSetLinkRedirect } from '../HoldingSets/Links';
import { ContactLookup, ContactType, NoteDTO, TaskDTO } from '../Contacts/types';
import { Asset } from '@/api/getAsset';
import { ValidatedDocument } from '../Documents/types';
import { ArrowRightOutlined } from '@ant-design/icons';
import { usePermission } from '@/common/hooks/usePermission';
import { Permissions } from '@/utils/security';
import { LoadMore } from '@/common/components/SearchOverlay';
import { HoldingSet } from '@/modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';
import { selectedObjectsState } from './Recoil/links.atoms';
import { Collapse, SearchInput } from 'ui-sesame-components';

import './style.css';

interface EntityLinksProps {
  defaultSelectedType?: EntityLinkType;
  entityId: Primitive;
  type: EntityLinkType;
}

interface LinkObjectNameProps {
  object: LinkableObject;
  onClick: (obj: LinkableObject) => void;
  checked: boolean;
  disabled: boolean;
}

export const LinkObjectName: React.FunctionComponent<LinkObjectNameProps> = React.memo(({ object, checked, onClick, disabled }) => {
  const _onClick = useCallback(() => {
    onClick(object);
  }, [onClick, object]);

  const translatedEntityObjectName = translateEntityObjectName(object);

  return translatedEntityObjectName ? (
    <div className="linkable-object-name-div">
      <Checkbox disabled={disabled} checked={checked} onClick={_onClick} />
      <Text onClick={!disabled ? _onClick : undefined} type="secondary">
        {translatedEntityObjectName}
      </Text>
    </div>
  ) : null;
});

export const LinkPopoverContent: React.FunctionComponent<EntityLinksProps> = withSmallSuspense(
  React.memo(({ defaultSelectedType, entityId, type }) => {
    const defaultType = (defaultSelectedType as unknown as EntityLinkTypeMenu) ?? EntityLinkTypeMenu.PORTFOLIO;

    const entityLinks = useRecoilValue(entityLinksSelector([type, entityId]));

    const [selectedEntityType, setSelectedEntityType] = useState<EntityLinkTypeMenu>(defaultType);
    const [selectedMenuKey, setSelectedMenuKey] = useState<string>(defaultType);

    const _setSelectedEntityType = useCallback(
      (entityType: EntityLinkTypeMenu) => {
        return () => {
          setSelectedEntityType(entityType);
        };
      },
      [setSelectedEntityType]
    );

    const stopPropagation = useCallback((e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
    }, []);

    const handleMenuSelect = (key: string) => {
      setSelectedMenuKey(key);
    };

    const menuItems = useMemo(() => {
      return Object.values(EntityLinkTypeMenu)
        .filter(entity => entity !== EntityLinkTypeMenu.OWNER)
        .sort(sortEntityType)
        .map(type => {
          const linksToValidate = entityLinks.filter(link => (link.type as unknown as EntityLinkTypeMenu) === type);
          let keyTranslated = type.toLowerCase();
          if (type === EntityLinkTypeMenu.CONTACT_PERSON) {
            keyTranslated = 'contact';
          }
          if (type === EntityLinkTypeMenu.CONTACT_COMPANY) {
            keyTranslated = 'companie';
          }

          if (type === EntityLinkTypeMenu.VEHICLE) {
            keyTranslated = 'legalEntitie';
          }

          return {
            key: type,
            label: (
              <LinksValidator
                links={linksToValidate}
                type={type}
                render={links => (
                  <div className="entity-links-category-count-container">
                    <Typography.Text type="secondary">
                      <FormattedMessage id={'generic.' + keyTranslated + 's'} />
                    </Typography.Text>
                    <Typography.Text strong type="secondary">
                      {links.length}
                    </Typography.Text>
                  </div>
                )}
              />
            ),
            onClick: _setSelectedEntityType(type)
          };
        });
    }, [_setSelectedEntityType, entityLinks]);

    const defaultSelectedKeys = useMemo(() => {
      return [defaultType];
    }, [defaultType]);

    return (
      <div onClick={stopPropagation} className="entity-links-management-popover-container">
        <Menu defaultSelectedKeys={defaultSelectedKeys} items={menuItems} className="entity-linkable-objects-entity-list" onSelect={key => handleMenuSelect(key.key)} />
        <LinkableObjectsList entityId={entityId} type={type} selectedType={selectedEntityType} selectedMenuKey={selectedMenuKey} />
      </div>
    );
  })
);

interface LinkableObjectsListProps {
  selectedType: EntityLinkTypeMenu;
  entityId: Primitive;
  type: EntityLinkType;
  selectedMenuKey: string;
}

export const LinkableObjectsList: React.FunctionComponent<LinkableObjectsListProps> = withSmallSuspense(
  React.memo(({ selectedType, entityId, type, selectedMenuKey }) => {
    const translator = useIntl();
    const [slice, setSlice] = useState(50);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>();

    const linkableObjects = useRecoilValue(linkableObjectsSelector({ type: selectedType }));

    const entityLinks = useRecoilValue(entityLinksSelector([type, entityId]));

    useEffect(() => {
      setSearchValue(undefined);
    }, [selectedMenuKey]);

    const defaultLinks = useMemo(() => {
      const links = entityLinks.filter(link => link.type === selectedType);

      const defaultLinkableObjects = links.map(link => {
        return linkableObjects.find(linkableObject => String(linkableObject.id) === link.entityId);
      });

      return defaultLinkableObjects.filter(link => link !== undefined) as LinkableObjectList;
    }, [linkableObjects, entityLinks, selectedType]);

    const [selectedObjects, setSelectedObjects] = useRecoilState(selectedObjectsState);

    useEffect(() => {
      const defaultLinksIds = new Set(defaultLinks.map(link => `${link._entityType}-${link.id}`));
      const selectedObjectsIds = new Set(selectedObjects.map(obj => `${obj._entityType}-${obj.id}`));
      if (defaultLinks.length > 0 && !defaultLinksIds.isSubsetOf(selectedObjectsIds)) {
        setSelectedObjects([...selectedObjects, ...defaultLinks]);
      }
    }, [defaultLinks, setSelectedObjects]);

    const hasElementFromAnyMenuItemBeenChecked = (entityLinks, selectedObjects): boolean => {
      const entityIds = new Set(entityLinks.map(link => link.entityId));

      for (const obj of selectedObjects) {
        if (!entityIds.has(obj.id)) {
          return false;
        }
      }

      return true;
    };

    const onClick = useCallback(
      (object: LinkableObject) => {
        setSelectedObjects(prevObjects => {
          const originalLength = prevObjects.length;

          const newObjects = prevObjects.filter(obj => {
            const isSameObject = object.id === obj.id && object._entityType === obj._entityType;

            return !isSameObject;
          });

          if (originalLength !== newObjects.length) {
            return newObjects;
          }

          return newObjects.concat(object);
        });
      },
      [setSelectedObjects]
    );

    const onSearchChange = useCallback(
      (ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(ev.target.value);
      },
      [setSearchValue]
    );

    const slicedData = useMemo(() => {
      return linkableObjects.slice(0, slice);
    }, [slice, linkableObjects]);

    const filteredObjects = useMemo<LinkableObjectList>(() => {
      if (!searchValue) {
        return slicedData;
      }

      return linkableObjects.filter(object => filterObjectByName(object, searchValue));
    }, [searchValue, linkableObjects, slice]);

    const linksToCreate = useMemo(() => {
      return selectedObjects.filter(obj => {
        return !defaultLinks.find(item => item._entityType === obj._entityType && item.id === obj.id);
      });
    }, [selectedObjects, defaultLinks]);

    const linksToDelete = useMemo(() => {
      const linksToDelete: Array<LinkLookup> = [];

      const entityLinkFilter = (object: LinkableObject, link: LinkLookup) =>
        link.entityId === String(object.id) && !linksToDelete.find(linkToDelete => linkToDelete.id === link.id);

      defaultLinks
        .filter(object => {
          const foundSelectedObject = selectedObjects.find(item => item.id === object.id && item._entityType === object._entityType);

          return !foundSelectedObject;
        })
        .forEach(object => {
          const existingLinks = entityLinks.filter(link => entityLinkFilter(object, link));

          linksToDelete.push(...existingLinks);
        });

      return linksToDelete;
    }, [entityLinks, selectedObjects]);

    const saveLinks = useRecoilCallback(
      ({ snapshot, refresh }) =>
        async () => {
          setLoading(true);

          try {
            const _linkObjectRight = await snapshot.getPromise(linkObjectSelector({ type, id: String(entityId) }));

            const linkObjectRight = {
              // @ts-ignore
              ..._linkObjectRight,
              _entityType: type
            };

            await Promise.all(
              linksToDelete.map(async link => {
                const _linkObjectLeft = await snapshot.getPromise(
                  linkObjectSelector({
                    type: link.type,
                    id: link.entityId
                  })
                );

                const linkObjectLeft = {
                  // @ts-ignore
                  ..._linkObjectLeft,
                  _entityType: link.type
                };

                return deleteLink(
                  type,
                  entityId as UUID,
                  link.id,
                  translateEntityObjectName(linkObjectRight as LinkableObject)!,
                  translateEntityObjectName(linkObjectLeft as LinkableObject) as string
                );
              })
            );

            await Promise.all(
              linksToCreate.map(async obj => {
                return createLink(type, entityId as UUID, {
                  type: obj._entityType,
                  entityId: obj.id as UUID
                });
              })
            );

            message.success(translator.formatMessage({ id: 'generic.successfullySavedLinks' }));
          } catch (err) {
            message.error(
              translator.formatMessage({
                id: 'generic.somethingWentWrongWhenSavingLinks'
              })
            );
          } finally {
            setTimeout(() => {
              refresh(entityLinksSelector([type, entityId]));
              refresh(linkableObjectsSelector({ type: selectedType }));
              refresh(existingListObjectsSelector([type, entityId, selectedType]));
              refresh(selectedObjectsState);
              refresh(
                entityActivitySelector([
                  type === EntityLinkType.CONTACT_PERSON || type === EntityLinkType.CONTACT_COMPANY ? ActivityType.CONTACT : (type as unknown as ActivityType),
                  String(entityId)
                ])
              );
              setLoading(false);
            }, 1000);
          }
        },
      [linksToCreate, linksToDelete, selectedType, type, entityId, setLoading, translator]
    );

    const showLoadMore = !searchValue && slice < linkableObjects.length;

    return (
      <div className="linkable-objects-list-container-wrapper">
        <div className="linkable-objects-list-container">
          <SearchInput
            debounce
            value={searchValue}
            style={{ marginBottom: 12 }}
            placeholder={translator.formatMessage({ id: 'generic.search' })}
            size="small"
            onChange={onSearchChange}
          />
          {filteredObjects.length <= 0 ? (
            <Empty description={<Text type="secondary">No data</Text>} />
          ) : (
            filteredObjects.map(object => {
              const currentLink = entityLinks.find(link => link.entityId === object.id);
              return (
                <LinkObjectName
                  key={object.id}
                  checked={selectedObjects.some(obj => obj.id === object.id && obj._entityType === obj._entityType)}
                  onClick={onClick}
                  disabled={currentLink ? !currentLink.editable : false}
                  object={object}
                />
              );
            })
          )}
          {showLoadMore && <LoadMore slice={slice} setSlice={setSlice} />}
        </div>
        {(!hasElementFromAnyMenuItemBeenChecked(entityLinks, selectedObjects) || linksToDelete.length > 0) && (
          <Button onClick={saveLinks} loading={loading} type="primary" size="small" className="entity-linkable-objects-list-save-button">
            Save
          </Button>
        )}
      </div>
    );
  })
);

const NoLinksAvailable: React.FunctionComponent<EntityLinksProps> = React.memo(({ entityId, type }) => {
  const trigger = useMemo(() => ['click'], []);

  const showManageLinks = usePermission([Permissions.LINK_CONTRIBUTOR]);
  const translatedEntityType = type === EntityLinkType.CONTACT_COMPANY ? 'company' : type === EntityLinkType.CONTACT_PERSON ? 'contact' : type;
  return (
    <Flex style={{ height: '100%', textWrap: 'wrap' }} align="center" justify="center">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage id="erp.itemsLinkedToEntityTypeWillAppearHere" />} />
    </Flex>
  );
});

const EntityLinks: React.FC<EntityLinksProps> = withSmallSuspense(
  React.memo(({ entityId, type }) => {
    const { defaultActiveKeys, categorizedLinks, availableCategories, retrieveCategoryTranslationId } = useLinksData(entityId, type);

    return availableCategories.length <= 0 ? (
      <NoLinksAvailable entityId={entityId} type={type} />
    ) : (
      <Collapse defaultActiveKey={defaultActiveKeys} ghost>
        {[...availableCategories].sort(sortEntityType).map(category => {
          return (
            <LinksRedirectsPanel
              key={category}
              entityId={entityId}
              type={type}
              panelCategory={category}
              links={categorizedLinks[category]!}
              translationKey={retrieveCategoryTranslationId(category)}
            />
          );
        })}
      </Collapse>
    );
  })
);

interface LinksRedirectsPanelProps {
  panelCategory: EntityLinkTypeMenu;
  entityId: Primitive;
  type: EntityLinkType;
  links: Array<LinkLookup>;
  translationKey: string;
}

const LinksRedirectsPanel: React.FC<LinksRedirectsPanelProps> = withSmallSuspense(
  React.memo(({ links, entityId, type, panelCategory, translationKey, ...props }) => {
    const translator = useIntl();
    const [popoverOpen, setPopoverOpen] = useState(false);

    const validatedLinks = useRecoilValue(
      linkableObjectsSelector({
        type: panelCategory,
        params: {
          ids: links
            // temp => we should remove in db entity with undefined
            .filter(link => link.entityId !== 'undefined')
            .map(l => l.entityId)
            .join(',')
        }
      })
    );

    const invalidateCache = useRecoilRefresher_UNSTABLE(
      linkableObjectsSelector({
        type: panelCategory,
        params: {
          ids: links
            // temp => we should remove in db entity with undefined
            .filter(link => link.entityId !== 'undefined')
            .map(l => l.entityId)
            .join(',')
        }
      })
    );

    useEffect(() => {
      invalidateCache();
    }, [invalidateCache]);

    if (!validatedLinks) {
      return null;
    }

    return (
      <Collapse
        {...props}
        ghost
        expandIconPosition="end"
        destroyInactivePanel
        size="large"
        items={[
          {
            label: (
              <Text strong>
                <FormattedMessage id={translationKey} /> ({validatedLinks.length})
              </Text>
            ),
            children: (
              <>
                {validatedLinks.slice(0, 6).map(link => (
                  <LinkRedirect key={link.id} link={link} type={panelCategory} entityViewedId={String(entityId)} />
                ))}
                {validatedLinks.length > 6 && (
                  <Row justify="end">
                    <Popover
                      open={popoverOpen}
                      onOpenChange={setPopoverOpen}
                      trigger="click"
                      showArrow={false}
                      placement="bottomLeft"
                      destroyTooltipOnHide
                      content={<LinkList closePopover={() => setPopoverOpen(false)} entityId={String(entityId)} links={validatedLinks} type={panelCategory} />}
                    >
                      <Button type="link">
                        {translator.formatMessage({ id: 'generic.viewAll' })}
                        <ArrowRightOutlined />
                      </Button>
                    </Popover>
                  </Row>
                )}
              </>
            )
          }
        ]}
        className="updated-activity-collapse"
      />
    );
  })
);

type LinkListProps = {
  links: LinkableObjectList;
  type: EntityLinkTypeMenu;
  entityId: string;
  closePopover: () => void;
};

const LinkList: React.FC<LinkListProps> = ({ links, type, entityId, closePopover }) => {
  const [linkNameFilter, setLinkNameFilter] = useState('');

  const filteredLinks = links.filter(link => {
    const nameFilter = linkNameFilter.toLowerCase().trim();
    if ('name' in link) {
      return link.name.toLowerCase().includes(nameFilter);
    }

    if ('title' in link) {
      return link.title.toLowerCase().includes(nameFilter);
    }

    if (link.type === ContactType.COMPANY) {
      return link.company?.toLowerCase().includes(nameFilter);
    } else {
      return `${link.firstName} ${link.lastName}`.toLowerCase().includes(nameFilter);
    }
  });

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Escape') {
      closePopover();
    }
  };

  return (
    <div>
      <div>
        <SearchInput onChange={e => setLinkNameFilter(e.currentTarget.value)} allowClear onKeyDown={onKeyDown} />
      </div>
      <div>
        {filteredLinks.map(link => (
          <div key={link.id} onClick={closePopover}>
            <LinkRedirect key={link.id} link={link} type={type} entityViewedId={String(entityId)} />
          </div>
        ))}
      </div>
    </div>
  );
};

type LinksValidatorProps = {
  links: LinkLookup[];
  type: EntityLinkTypeMenu;
  render: (links: LinkableObjectList) => React.ReactElement | null;
};

const LinksValidator: React.FC<LinksValidatorProps> = withSmallSuspense(
  React.memo(({ render, links, type }) => {
    const validatedLinks = useRecoilValue(
      linkableObjectsSelector({
        type,
        params: { ids: links.map(l => l.entityId).join(',') }
      })
    );

    if (!links.length) {
      return render([]);
    }

    return render(validatedLinks);
  })
);

type LinkRedirectProps = {
  type: EntityLinkTypeMenu;
  link: LinkableObject;
  entityViewedId: string;
};

const LinkRedirect: React.FC<LinkRedirectProps> = React.memo(({ type, link, entityViewedId }) => {
  switch (type) {
    case EntityLinkTypeMenu.ASSET:
      return <AssetLinkRedirect asset={link as Asset} />;
    case EntityLinkTypeMenu.DOCUMENT:
      return <DocumentLinkRedirect document={link as ValidatedDocument} />;
    case EntityLinkTypeMenu.CONTACT_PERSON:
      return <ContactLinkRedirect contact={link as ContactLookup} />;
    case EntityLinkTypeMenu.CONTACT_COMPANY:
      return <ContactLinkRedirect contact={link as ContactLookup} />;
    case EntityLinkTypeMenu.TASK:
      return <TaskLinkRedirect task={link as TaskDTO} type={type} entityViewedId={entityViewedId} />;
    case EntityLinkTypeMenu.NOTE:
      return <NoteLinkRedirect note={link as NoteDTO} type={type} entityViewedId={entityViewedId} />;
    case EntityLinkTypeMenu.PORTFOLIO:
    case EntityLinkTypeMenu.VEHICLE:
    case EntityLinkTypeMenu.GROUPING:
      return <HoldingSetLinkRedirect holdingSet={link as HoldingSet} />;

    default:
      return <></>;
  }
});

export { EntityLinks, ViewAll };
