import listContentTags from '@/api/listContentTags';
import { ContentTagCategory } from '@/common/types/entity/ContentTag';
import { TransientCustomReport } from '@/common/types/entity/CustomReport';

export const tagReportAsTemplate = async (report: TransientCustomReport) => {
  const tags = await listContentTags(ContentTagCategory.GENERIC);
  const templateTag = tags.find(tag => tag.name === 'Template');

  if (templateTag) {
    report.contentTags.push(templateTag);
  }
};
