import React from 'react';
import { FileOutlined } from '@ant-design/icons';
import { Text } from '@/common/components/Typography/Text';
import { ActivityLayout } from './CardLayout';
import { ActivityCardTypeText } from './CardTypeText';
import type { ActivityDTO } from '@/modules/ERP/Contacts/types';
import { parseActivityRelatedEntities } from '../utils';

interface ActivityCardDocumentProps {
  activity: ActivityDTO;
  entityId: string;
}

export const ActivityCardDocument: React.FunctionComponent<ActivityCardDocumentProps> = React.memo(({ activity, entityId }) => {
  const { creator, relatedEntity } = parseActivityRelatedEntities(activity, entityId);

  return (
    <ActivityLayout activity={activity} icon={<FileOutlined />}>
      <Text link>{creator}</Text>
      &nbsp;
      <ActivityCardTypeText activityType={activity.type} />
      <Text link>{relatedEntity}</Text>
    </ActivityLayout>
  );
});
