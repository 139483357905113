import { getTableMonths } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/utils/getTableMonths';

export const getMonthByKey = (key: string, locale?: string) => {
  const tableMonths = getTableMonths(locale);
  switch (key) {
    case '01':
      return tableMonths[0].month;
    case '02':
      return tableMonths[1].month;
    case '03':
      return tableMonths[2].month;
    case '04':
      return tableMonths[3].month;
    case '05':
      return tableMonths[4].month;
    case '06':
      return tableMonths[5].month;
    case '07':
      return tableMonths[6].month;
    case '08':
      return tableMonths[7].month;
    case '09':
      return tableMonths[8].month;
    case '10':
      return tableMonths[9].month;
    case '11':
      return tableMonths[10].month;
    case '12':
      return tableMonths[11].month;
    default:
      throw new Error();
  }
};
