import React, { ReactElement, useEffect, useState } from 'react';
import { Spinner } from '@/modules/reporting-v2/core/components/loaders';

interface VisualBodyChartWrapperProps {
  VisualBody: React.ReactNode | ReactElement | null;
}

const VisualBodyChartWrapper: React.FC<VisualBodyChartWrapperProps> = ({ VisualBody }): ReactElement => {
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => setReady(true), 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div className="visual-wrapper">
      {ready ? (
        VisualBody
      ) : (
        <div className="visual-overlay-modal">
          <Spinner className="" />
        </div>
      )}
    </div>
  );
};

export { VisualBodyChartWrapper };
