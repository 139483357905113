import { FieldResolver } from '@/modules/reporting-v2/types/FieldResolver';
import { Margin, Page, RawReportConfig, RawVisual, ReportParamsConfig, VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { concatenateUniqueIndexFields } from '@/modules/reporting-v2/utils/IndexUtils';
import { Condition } from './Condition';
import { Field } from './Field';
import { captureFilterValues, Filter } from './Filter';
import { VisualEngine } from './VisualEngine';
import { createVisual } from './utils';
import { Preferences } from '@/modules/reporting-v2/types/ReportViewerServiceTypes';
import mergePreferences from './mergePreferences';

export const retrieveReportParamsConfig = (reportConfig: RawReportConfig) => {
  if (reportConfig && !reportConfig.config) {
    // excel grid report configuration
    return {
      holdingSetEnabled: true,
      dateEnabled: true,
      consolidationTypeEnabled: true
    } as ReportParamsConfig;
  }

  const reportParamsConfig: ReportParamsConfig = {
    dateEnabled: reportConfig.config.filters?.date,
    endDateEnabled: !!reportConfig.config.filters?.endDate,
    holdingSetEnabled: reportConfig.config.filters?.holdingSet,
    consolidationTypeEnabled: reportConfig.config.filters?.consolidationType,
    multiHoldingSetEnabled:
      (reportConfig.config.filters as unknown as boolean) === false ||
      (reportConfig.config.filters?.holdingSet && reportConfig.pages.flatMap(page => page.components.map(item => item.component)).includes(VisualComponent.DashboardTable))
  };

  return reportParamsConfig;
};

export const createReportConfig = (reportConfig: RawReportConfig, preferences: Preferences): ReportViewerConfig => {
  const reportParamsConfig = retrieveReportParamsConfig(reportConfig);
  const multiEntityReport = reportConfig.config.multiEntity ?? false;

  const globalFilters = [] as Filter[];

  reportConfig.config.globalFilters?.filters?.forEach(filter => {
    const field = new Field(filter.baseColumn?.defaultColumns[0] as string);

    if (field.name) {
      globalFilters.push(new Filter(field, new Condition(filter.operator, captureFilterValues(filter))));
    }
  });

  const rawVisuals = reportConfig.pages.map((page: Page) => page.components).flat(Infinity) as RawVisual[];

  const selectBoxes = rawVisuals.filter(visual => visual.component === VisualComponent.SelectBox);
  const selectBoxFields: string[] = [];

  if (selectBoxes.length) {
    for (const { config } of selectBoxes) {
      const customOptionsFields = Object.keys(config._options || {});
      if (config._list) selectBoxFields.push(config._list);
      else if (customOptionsFields.length) selectBoxFields.push(...customOptionsFields);
    }
  }

  const visuals = rawVisuals.map(rawVisual => {
    const cachedPreferences = preferences[rawVisual.id];

    const visualExist = rawVisual && cachedPreferences;
    const versionsAreMatching = rawVisual.version === cachedPreferences?.version;
    if (visualExist && !versionsAreMatching) {
      const updatedPreferences = mergePreferences(rawVisual.config, cachedPreferences);
      Object.assign(rawVisual, {
        config: {
          ...rawVisual.config,
          ...updatedPreferences,
          styles: rawVisual.config.styles // ignore styles from updatedPreferences
        }
      });
    }

    if (visualExist && versionsAreMatching) {
      Object.assign(rawVisual, {
        config: {
          ...rawVisual.config,
          ...cachedPreferences,
          styles: rawVisual.config.styles // ignore styles from cachedPreferences
        }
      });
    }

    return createVisual({ ...rawVisual, globalFilters, selectBoxFields, colourGroups: reportConfig.colourGroups }, multiEntityReport);
  });

  return new ReportViewerConfig(globalFilters, visuals, reportParamsConfig, multiEntityReport, reportConfig.config.margin, {
    TEMP_LTS_HEADER_FOOTER: reportConfig.config.TEMP_LTS_HEADER_FOOTER,
    TEMP_CUSTOM_HEADER: reportConfig.config.TEMP_CUSTOM_HEADER
  });
};

const defaultReportMargin: Margin = {
  top: 48,
  bottom: 48,
  left: 0,
  right: 0
};

class ReportViewerConfig implements FieldResolver {
  constructor(
    globalFilters: Filter[],
    visuals: VisualEngine[],
    reportParamsConfig: ReportParamsConfig,
    multiEntityReport: boolean,
    margin: Margin | undefined,
    custom: Record<string, any>
  ) {
    this.globalFilters = globalFilters.filter(filter => filter.field.name);
    this.visuals = visuals;
    this.reportParamsConfig = reportParamsConfig;
    this.reportParamsDisabled = Object.keys(reportParamsConfig).every(param => !reportParamsConfig[param]);
    this.multiEntityReport = multiEntityReport;
    this.margin = {
      ...defaultReportMargin,
      ...margin
    };
    this.custom = custom;
  }

  globalFilters: Filter[];
  visuals: VisualEngine[];
  reportParamsConfig: ReportParamsConfig;
  multiEntityReport: boolean;
  reportParamsDisabled?: boolean;
  margin: Margin;
  custom: Record<string, any>;

  getAllUsedFields(): Field[] {
    return concatenateUniqueIndexFields(
      this.globalFilters.flatMap(filter => filter.getAllUsedFields()),
      this.visuals.flatMap(visual => visual.getAllUsedFields())
    );
  }
}

export { ReportViewerConfig };
