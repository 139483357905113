import React from 'react';

type LevelIndicatorsProps = {
  level: number;
  isLastChildRow: boolean;
  parentRowLastChildRow: number[];
};

export const LevelIndicators: React.FC<LevelIndicatorsProps> = ({ level, isLastChildRow, parentRowLastChildRow }) => {
  const indicators = [];

  for (let index = 0; index < level; index++) {
    if (parentRowLastChildRow.includes(index + 1)) {
      continue;
    }

    indicators.push(<span key={index} style={{ '--levelIndicator': index + 1 } as React.CSSProperties} />);
  }

  return <span className={`level-indicators ${isLastChildRow ? 'last-child' : ''}`}>{indicators}</span>;
};
