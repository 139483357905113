import { findConditionalMeasureColumn } from '@/modules/reporting-v2/core/findConditionalMeasureColumn';
import { IDataFrame } from 'data-forge';
import type { ColumnWithCalcExpression } from '@/modules/reporting-v2/types/CalcExpression';
import type { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import type { Column } from '@/modules/reporting-v2/core/Column';

export const transformGroupTotalValues = (dataFrame: IDataFrame<number, FlattenObject>, columns: Column[]) => {
  const columnsWithGroupTotalValues: { index: number; column: Column }[] = [];
  columns.forEach((column, index) => {
    if (column.groupTotalValues) {
      columnsWithGroupTotalValues.push({ index, column });
    }
  });

  if (!columnsWithGroupTotalValues.length) {
    return dataFrame;
  }

  let newDataFrame = dataFrame;
  for (const columnWithGroupTotalValues of columnsWithGroupTotalValues) {
    const sum = dataFrame.getSeries(columnWithGroupTotalValues.column.fieldDataPath).sum();
    newDataFrame = newDataFrame.transformSeries({ [columnWithGroupTotalValues.column.fieldDataPath]: () => sum });
  }

  return newDataFrame;
};

export const transformAbsoluteNumbers = (dataFrame: IDataFrame<number, FlattenObject>, columns: Column[]) => {
  const absoluteColumns = columns.filter(col => col.absolute);

  if (!absoluteColumns.length) {
    return dataFrame;
  }

  const absFunc = (number: number) => Math.abs(number);

  const transform = Object.fromEntries(absoluteColumns.map(col => [col.fieldDataPath, absFunc]));
  return dataFrame.transformSeries(transform);
};

export const doCalcExpressions = (dataFrame: IDataFrame<number, FlattenObject>, columns: Column[], calcAfterNormalise?: boolean): IDataFrame<number, FlattenObject> => {
  const columnsWithCalcExpressions = columns.filter(
    col => col.calcExpression && Boolean(col.calcAfterNormalise) === Boolean(calcAfterNormalise)
  ) as Array<ColumnWithCalcExpression>;

  if (!columnsWithCalcExpressions.length) {
    return dataFrame;
  }

  const dataFrameWithResetIndex = dataFrame.resetIndex();

  return dataFrameWithResetIndex.map(row => {
    const newRow = { ...row };
    for (const col of columnsWithCalcExpressions) {
      const value = col.calcExpression.calc(newRow, columns, col.calcIgnoreFilter);
      newRow[col.fieldDataPath] = value;
    }
    return newRow;
  });
};

export const transformConditionalMeasures = (dataFrame: IDataFrame<number, FlattenObject>, columns: Column[]) => {
  const conditionalMeasureColumns = columns.filter(col => col.conditionalMeasure?.conditions?.length);
  if (!conditionalMeasureColumns.length) {
    return dataFrame;
  }

  const newDf = dataFrame.map(row => {
    const newRow = { ...row };

    for (const conditionalMeasureColumn of conditionalMeasureColumns) {
      const newColumnToUse = findConditionalMeasureColumn(conditionalMeasureColumn, newRow, columns);
      if (!newColumnToUse) {
        continue;
      }

      newRow[conditionalMeasureColumn.fieldDataPath] = row[newColumnToUse.fieldDataPath];
    }

    return newRow;
  });

  return newDf;
};
