import { Flex, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { type ActivityDTO } from '@/modules/ERP/Contacts/types';
import { Text } from '@/common/components/Typography/Text';
import '@/modules/ERP/helpers.css';
import './style.css'

interface UpdatedActivityProps {
    activity: ActivityDTO;
}
const NewCommentActivity: React.FC<UpdatedActivityProps> = ({ activity }) => {
    const translator = useIntl();
    
    const newCommentValue = activity.relatedEntities[0].name;
    return (
        <Flex className='updated-activity__container'>
            <Typography.Paragraph ellipsis={{ rows: 5, expandable: true, symbol: translator.formatMessage({ id: 'generic.seeMore' }) }}>
                <Text type="secondary">{newCommentValue.replace(/\?@([^?]+)\?/g, '@$1')}</Text>
            </Typography.Paragraph>
        </Flex>
    )
}

export default NewCommentActivity;