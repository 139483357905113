import React from 'react';
import views from './views';
import { FormattedMessage } from 'react-intl';
import SiteTreeEntry, { MappedSiteTreeEntry } from '@/common/types/entity/SiteTreeEntry';
import { PageTemplate } from '@/utils/retrievePageTemplate';

const Error50x = React.lazy(() => import('@/modules/App/components/Error50x'));

export const EXCLUDE_HELP_ROUTES = true;

const routeMapper = (entry: SiteTreeEntry): MappedSiteTreeEntry => {
  let exact = true;
  if ([PageTemplate.REPORTS_CENTRE_V3].includes(entry.view as PageTemplate)) {
    exact = false;
  }

  return {
    ...entry,
    label: entry.title,
    component: views[entry.view as keyof typeof views],
    icon: entry.icon,
    exact,
    nav: true,
    shouldSignIn: true,
    children:
      entry.children &&
      entry.children.reduce(
        (accum, child) => ({
          ...accum,
          [child.path]: routeMapper(child)
        }),
        {}
      )
  };
};

const routes: Record<string, MappedSiteTreeEntry> = {
  '50x': {
    id: 999999,
    order: 999999,
    nodes: [],
    title: '',
    children: {},
    externalUrl: null,
    label: <FormattedMessage id={'generic.error'} />,
    path: '/50x',
    component: Error50x,
    view: '',
    icon: '',
    exact: true
  }
};

export const getRoutes = (siteTree: SiteTreeEntry[], excludeHelp = false): Record<string, MappedSiteTreeEntry> => {
  const prefix =
    excludeHelp && window.location.pathname.startsWith('/help')
      ? siteTree.find(({ path }: SiteTreeEntry) => !path.startsWith('/help'))?.path.split('/')[1]
      : window.location.pathname.split('/')[1];

  const values = siteTree
    .filter(({ nodes, view }: SiteTreeEntry) => {
      const prefixLevel2 =
        excludeHelp && window.location.pathname.startsWith('/help')
          ? siteTree.find(({ path }: SiteTreeEntry) => !path.startsWith('/help'))?.path.split('/')[2]
          : window.location.pathname.split('/')[2];

      if (view === 'TOP_LEVEL_NAVIGATION_ITEM' || view === 'TOP_LEVEL_NAVIGATION_ITEM_SIDER_V3') {
        return nodes[0] === prefix && nodes[1] === prefixLevel2;
      }

      const viewsToExclude = ['APP_MENU', 'APP_MENU_V3', 'TOP_LEVEL_NAVIGATION_ITEM', 'TOP_LEVEL_NAVIGATION_ITEM_SIDER_V3'];
      return !viewsToExclude.includes(view) && nodes[0] === prefix;
    })
    .reduce<Record<string, MappedSiteTreeEntry>>((tree, entry) => {
      return {
        ...tree,
        [entry.path]: routeMapper(entry)
      };
    }, routes);

  return values;
};

export default routes;
