import { UserLookup } from '../../types/entity/UserLookup';
import { UserOutlined } from '@ant-design/icons';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export default function ({ user, defaultLabel = <FormattedMessage id={'generic.unassigned'} /> }: { user?: UserLookup; defaultLabel?: string | ReactElement }) {
  return user ? (
    <>
      <UserOutlined /> {user.firstName} {user.lastName}
    </>
  ) : (
    <>
      <UserOutlined /> {defaultLabel}
    </>
  );
}
