import { QuantCatalogueItem, QuantCatalogueItemLookup, QuantCatalogueItemMeta } from '@/common/types/entity/QuantCatalogueItem';
import React from 'react';
import { Condition } from '@/modules/reporting-v2/core/Condition';
import { Field } from '@/modules/reporting-v2/core/Field';
import { Filter } from '@/modules/reporting-v2/core/Filter';
import { ReportingService } from '@/modules/reporting-v2/core/ReportingService';
import { ColumnMetas } from '@/modules/reporting-v2/types/Column';
import { FilterOperator } from '@/types/Filters';
import { ElasticColumnMetas } from './QuantLibrary';
import { QuantUtils } from './QuantUtils';
import { QuantMetricCreationButton } from './Shared/QuantMetricCreationButton';
import { QuantMetricEditionButton } from './Shared/QuantMetricEditionButton';
import { FormatType } from '../../../../common/types/elastic/FormatType';

export class ElasticMetricAdapter {
  private constructor() {}

  public static isQuantItem(record: ElasticColumnMetas | QuantCatalogueItemLookup): record is QuantCatalogueItemLookup {
    return (record as QuantCatalogueItemLookup).elasticPath !== undefined;
  }

  public static isElasticItem(record: ElasticColumnMetas | QuantCatalogueItemLookup): record is ElasticColumnMetas {
    return (record as ElasticColumnMetas).field !== undefined;
  }

  public static renderTableActions(record: ElasticColumnMetas | QuantCatalogueItemLookup) {
    if (this.isQuantItem(record)) {
      return <QuantMetricEditionButton record={record} />;
    } else {
      return <QuantMetricCreationButton record={record} />;
    }
  }

  public static orderByDisplayName(
    recordA: ElasticColumnMetas | QuantCatalogueItemLookup,
    recordB: ElasticColumnMetas | QuantCatalogueItemLookup,
    quantItems: Array<QuantCatalogueItemLookup>
  ) {
    return this.retrieveDisplayName(recordA, quantItems).localeCompare(this.retrieveDisplayName(recordB, quantItems));
  }

  public static orderByField(recordA: ElasticColumnMetas | QuantCatalogueItemLookup, recordB: ElasticColumnMetas | QuantCatalogueItemLookup) {
    return this.retrieveField(recordA).localeCompare(this.retrieveField(recordB));
  }

  public static retrieveIndex(record: ElasticColumnMetas | QuantCatalogueItemLookup) {
    return this.retrieveField(record).split(Field.fieldSeparator).shift()!;
  }

  public static retrieveCategory(record: ElasticColumnMetas | QuantCatalogueItemLookup) {
    return (record as QuantCatalogueItemLookup).category?.name ?? record.category;
  }

  public static retrieveField(record: ElasticColumnMetas | QuantCatalogueItemLookup) {
    return (record as ElasticColumnMetas).field ?? (record as QuantCatalogueItemLookup).elasticPath;
  }

  public static retrieveCodeOrField(record: ElasticColumnMetas | QuantCatalogueItemLookup) {
    return (record as QuantCatalogueItemLookup).code ?? (record as ElasticColumnMetas).field;
  }

  public static retrieveCode(record: ElasticColumnMetas | QuantCatalogueItemLookup): string | undefined {
    return (record as QuantCatalogueItemLookup).code ?? undefined;
  }

  public static retrieveFormat(record: ElasticColumnMetas | QuantCatalogueItem) {
    return (record as ElasticColumnMetas).formatting?.type ?? (record as QuantCatalogueItem).format?.type;
  }

  public static retrieveAggregation(record: ElasticColumnMetas | QuantCatalogueItem) {
    return (record as ElasticColumnMetas).aggregation?.method ?? (record as QuantCatalogueItem).aggregation?.method;
  }

  public static retrieveCurrency(record: ElasticColumnMetas | QuantCatalogueItem, quantItems: Array<QuantCatalogueItemLookup>) {
    return (
      record.currency?.value ??
      (record as ElasticColumnMetas).formatting?.currencyDirectValue ??
      (record as ElasticColumnMetas).currency?.path ??
      QuantUtils.retrieveCurrencyReference((record as QuantCatalogueItem).currency?.reference, quantItems)
    );
  }

  public static transformQuantMetadata(item: QuantCatalogueItemMeta): ColumnMetas {
    const metadata: ColumnMetas = { ...ReportingService.metas[item.elasticPath] };

    metadata.elasticPath = item.elasticPath;
    metadata.category = item.category.name;
    metadata.groupable = item.groupable;
    metadata.aggregation = {
      method: item.aggregation?.method!,
      weights: QuantUtils.retrieveReferenceElasticPath(item.aggregation?.weightsReference),
      reference: QuantUtils.retrieveReferenceElasticPath(item.aggregation?.reference)
    };
    metadata.displayName = item.displayName.value ?? QuantUtils.retrieveDisplayNameFromMetadata(item.displayName.reference);
    metadata.formatting = {
      type: item.format.type as unknown as FormatType,
      decimals: String(item.format.decimals)
    };
    metadata.currency = this.retrieveAdapterCurrency(item);
    metadata.quantFilters = [];

    item.filters?.forEach(filter => {
      const quantItem = Object.values(ReportingService.quantMetas).find(quantItem => quantItem.id === filter?.reference.id);
      if (quantItem) {
        metadata.quantFilters?.push(new Filter(new Field(quantItem.elasticPath), new Condition(FilterOperator.EQUALS, [filter.value])));
      }
    });

    return metadata;
  }

  private static isElasticFieldString(string?: string) {
    return string?.includes(Field.fieldSeparator);
  }

  private static retrieveAdapterCurrency(item: QuantCatalogueItemMeta) {
    let currency;

    if (item.currency?.value) {
      currency = { value: item.currency.value };
    } else if (item.currency?.reference) {
      const currencyRetrieved = QuantUtils.retrieveCurrencyFromMetadata(item.currency.reference);

      if (this.isElasticFieldString(currencyRetrieved)) {
        currency = { path: currencyRetrieved };
      } else {
        currency = { value: currencyRetrieved };
      }
    }
    return currency;
  }

  public static retrieveDisplayName(record: ElasticColumnMetas | QuantCatalogueItemLookup, quantItems: Array<QuantCatalogueItemLookup>): string {
    return (
      (record as QuantCatalogueItemLookup).displayName?.value ??
      QuantUtils.fetchDisplayNameReference((record as QuantCatalogueItemLookup).displayName?.reference, quantItems) ??
      (record as ElasticColumnMetas).displayName ??
      (record as ElasticColumnMetas).field
    );
  }

  public static buildCatalogue(quantItems: Array<QuantCatalogueItemLookup>) {
    const catalogue: any[] = [];

    Object.entries(ReportingService.metas)
      .filter(([, metadata]) => metadata instanceof Object)
      .forEach(([field, metadata]) => {
        const items = quantItems.filter(quantItem => quantItem.elasticPath === field);
        catalogue.push({ ...metadata, field });

        for (const item of items) {
          catalogue.push(item);
        }
      });

    return catalogue;
  }
}
