import { getTheme } from '@/modules/reporting-v2/utils/theme';
import { parseFontStyle } from '@/modules/Styling/utils/parseStyles';
import { HeaderFooterGenerator, MergedReportBrandingStyles } from '@/modules/reporting-v2/types/HeaderFooter';
import { getBrandingStyle } from '../utils';

const headerHeight = 32;
const ratio = 0.8;

const getPaginationContent = (style: MergedReportBrandingStyles, pagination: boolean, paginationAlign: string) => {
  if (style.pagination?.hide) {
    return '';
  }

  const paginationInHeader = style.paginationPosition === 'header' && pagination;
  if (!paginationInHeader) {
    return '';
  }

  const paginationFont = style.pagination?.fontStyle;
  const paginationFontColor = style.pagination?.fontColor?.color;
  const paginationFontStyle = parseFontStyle(paginationFont);
  const paginationFontSize = paginationFont?.fontSize ? paginationFont.fontSize * ratio + 'px' : 'inherit';

  return `<td
    width="50%"
    style="
      text-align:${paginationAlign};
      font-family: ${paginationFont?.fontFamily || 'inherit'};
      font-weight: ${paginationFontStyle.fontWeight || 'inherit'};
      font-style: ${paginationFontStyle.fontStyle || 'inherit'};
      color: ${paginationFontColor};
      font-size: ${paginationFontSize};"
    "
  >
    <span class="pageNumber"></span> / <span class="totalPages"></span>
  </td>`;
};

const getTitleContent = (title: string | undefined, style: MergedReportBrandingStyles, headerTitle: boolean, paginationAlign: string) => {
  if (!headerTitle || style.header?.hideReportTitle) {
    return '';
  }

  return `<td width="50%" style="text-align:${paginationAlign === 'left' ? 'right' : 'left'};"><span class="header-title">${title}</span></td>`;
};

const getHeaderContent = (titleContent: string, paginationContent: string, paginationAlign: string) => {
  const paginationIsLeft = paginationContent && paginationAlign === 'left';
  if (paginationIsLeft) {
    return paginationContent + titleContent;
  }

  return titleContent + paginationContent;
};

export const defaultHeader: HeaderFooterGenerator = ({ title, pageProperties, reportStyle, user }): string => {
  const style = {
    ...getBrandingStyle(user.company.branding),
    ...reportStyle
  } as MergedReportBrandingStyles;
  const { headerTitle = true, pagination = false, paginationAlign = 'right' } = style;

  const hideHeader = style.headerHide || typeof style.headerHide === 'undefined';
  const headerHasNoContent = !headerTitle && (!pagination || style.paginationPosition !== 'header');
  if (hideHeader || headerHasNoContent) {
    return '<style>#header { display: none; }</style>';
  }

  const fontStyle = parseFontStyle(style.headerFontStyle);
  const paginationContent = getPaginationContent(style, pagination, paginationAlign);
  const titleContent = getTitleContent(title, style, headerTitle, paginationAlign);

  const headerContent = getHeaderContent(titleContent, paginationContent, paginationAlign);

  return `
  <style>
    #header {
      padding: 0;
      height: 100%;
      width: 100%;
      -webkit-print-color-adjust: exact;
    }
    #header .header-container {
      width: 100%;
      font-family: Lato, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: ${(style.headerFontStyle?.fontSize || 12) * ratio}px;
      font-weight: ${fontStyle.fontWeight || 'normal'};
      font-style: ${fontStyle.fontStyle || 'normal'};
      line-height: ${headerHeight * ratio}px;
      color: ${style.headerFontColor?.color || '#333333'};
      background: ${style.headerBackground?.color || 'none'};
      border-bottom: ${style.headerBorder?.style || 'none'} ${style.headerBorder?.width}px ${style.headerBorderColor?.color};
      -webkit-print-color-adjust: exact;
    }
    #header table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
    }
    #header table td {
      padding: 0 ${32 * ratio * pageProperties.ratio}px;
    }
  </style>
  <div id="header" class="report-theme-${getTheme()}">
    <div class="header-container">
      <table>
        <tr>
        ${headerContent}
        </tr>
      </table>
    </div>
  </div>
  `;
};
