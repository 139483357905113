import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionOutlined } from '@ant-design/icons';
import React from 'react';

type ActivityCardTypeIconProps = {
  type: string;
};

const ActivityCardTypeIcon: React.FC<ActivityCardTypeIconProps> = ({ type }) => {
  const activityType = type.toLowerCase();

  if (activityType.includes('create')) {
    return <PlusOutlined />;
  }

  if (activityType.includes('update')) {
    return <EditOutlined />;
  }

  if (activityType.includes('delete')) {
    return <DeleteOutlined />;
  }

  return <QuestionOutlined />;
};

export { ActivityCardTypeIcon };
