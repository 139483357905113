import { FieldResolver } from '@/modules/reporting-v2/types/FieldResolver';
import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import { concatenateUniqueIndexFields } from '@/modules/reporting-v2/utils/IndexUtils';
import { Field } from './Field';
import { Filter } from './Filter';
import { RawFilterGroup } from '@/modules/reporting-v2/types/Filter';
import { Column } from '@/modules/reporting-v2/core/Column';

export enum FilterGroupType {
  AND = 'and',
  OR = 'or'
}

class FilterGroup implements FieldResolver {
  id?: string;
  type: FilterGroupType;
  filters: (Filter | FilterGroup)[];

  constructor(type: FilterGroupType, filters: (Filter | FilterGroup)[], id?: string) {
    this.type = type;
    this.filters = filters;
  }

  evaluate(row: FlattenObject): boolean {
    if (this.type === FilterGroupType.OR) {
      return this.filters.some(filter => filter.evaluate(row));
    }

    return this.filters.every(filter => filter.evaluate(row));
  }

  getAllUsedFields(): Field[] {
    const fields = this.filters.map(filter => filter.getAllUsedFields());
    return concatenateUniqueIndexFields(...fields);
  }

  static fromRawFilter(rawFilter: RawFilterGroup, columns: Column[]): FilterGroup {
    const filters = rawFilter.conditions
      .map(rf => {
        const isRawFilterGroup = 'conditions' in rf;
        if (isRawFilterGroup) {
          return FilterGroup.fromRawFilter(rf, columns);
        }

        return Filter.fromRawFilter(rf, columns, undefined);
      })
      .filter(f => f !== undefined) as (Filter | FilterGroup)[];

    return new FilterGroup(rawFilter.type, filters, rawFilter.id);
  }
}

export { FilterGroup };
