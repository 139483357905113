import React, { memo } from 'react';
import cx from 'classnames';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { config } from './config';
import schema from './schema.json';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { LookThroughTypes } from '@/config/lookThroughTypes';
import { findHoldingSetById } from '@/utils/findHoldingSet';
import { RawSingleValueConfig, SingleValueConfig } from '..';
import { EntityParameters, EntityParametersHandler } from '@/common/types/app/EntityParametersHandler';
import { HoldingSet } from 'modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';
import dayjs from 'dayjs';
import { theme } from 'antd';

const NumberedMetadata = memo(({ index, text }: { index: number; text?: string }) => {
  const { token } = theme.useToken();

  return (
    <div style={{ marginBottom: 8 }}>
      <span
        style={{
          width: 16,
          height: 16,
          lineHeight: `16px`,
          fontSize: 13.5,
          verticalAlign: 'middle',
          fontWeight: 600,
          marginRight: 4,
          textAlign: 'center',
          color: '#fff',
          display: 'inline-block',
          backgroundColor: token.colorPrimary,
          borderRadius: 16
        }}
      >
        {index + 1}
      </span>
      {text}
    </div>
  );
});

const hsRowStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '8px'
} as React.CSSProperties;
const hsAdditionalStyles = {
  fontSize: 16,
  fontStyle: 'normal',
  color: '#666'
} as React.CSSProperties;

class SingleValue extends Visual {
  Loader = Loaders.CallOut;

  static configMapper(visualConfig: RawSingleValueConfig) {
    const { fields, ...rest } = visualConfig;
    return {
      ...rest,
      columns: mapVisualConfigColumns(fields)
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as SingleValueConfig;
  }

  getSchema() {
    return schema;
  }

  retrieveConsolidationType(multipleParams: boolean, params: EntityParameters[]) {
    if (multipleParams) {
      return params.map((param, index) => {
        const lookThrough = param.lookThrough === LookThroughTypes.Lookthrough ? 'Look through' : 'Direct';

        return <NumberedMetadata key={param.holdingSetId} index={index} text={lookThrough} />;
      });
    } else {
      const param = params?.[this.props.visual.entityOrdering[0]];

      if (!param) {
        return '';
      }

      if (param?.lookThrough === LookThroughTypes.Lookthrough) {
        return 'Look through';
      } else {
        return 'Direct';
      }
    }
  }

  retrieveEntityValue(multipleParams: boolean, params: EntityParameters[], tree: HoldingSet[]) {
    if (multipleParams) {
      return params.map((param, index) => {
        const entityName = findHoldingSetById(tree, param.holdingSetId)?.name;

        if (!entityName) {
          return null;
        }

        return <NumberedMetadata key={param.holdingSetId} index={index} text={entityName} />;
      });
    } else {
      const param = params?.[this.props.visual.entityOrdering[0]];

      return findHoldingSetById(tree, param?.holdingSetId)?.name ?? '';
    }
  }

  retrieveDateValue(multipleParams: boolean, params: EntityParameters[]) {
    if (multipleParams) {
      return params.map((param, index) => {
        return <NumberedMetadata key={param.holdingSetId} index={index} text={param.date} />;
      });
    } else {
      return params?.[this.props.visual.entityOrdering[0]]?.date ?? '';
    }
  }

  renderHsRow(hsName: string, date: string, consolidationType: string, currency: string) {
    return (
      <div key={hsName} style={hsRowStyles} className="entitylist-hsrow">
        <div>{hsName}</div>
        <div style={hsAdditionalStyles}>
          <span>{date}</span>
          <span>&nbsp;/&nbsp;</span>
          <span>{consolidationType.toUpperCase()}</span>
          <span>&nbsp;/&nbsp;</span>
          <span>{currency}</span>
        </div>
      </div>
    );
  }

  getHsData(param: EntityParameters, tree: HoldingSet[]) {
    const hs = findHoldingSetById(tree, param?.holdingSetId);

    const hsName = hs?.name ?? 'N/A';
    const currency = hs?.reportingCurrency ?? 'N/A';
    const date = param.date ?? 'N/A';
    const consolidationType = param?.lookThrough === LookThroughTypes.Lookthrough ? 'Look through' : 'Direct';

    return { hsName, date, consolidationType, currency };
  }

  retrieveHsList(multipleParams: boolean, params: EntityParameters[], tree: HoldingSet[]) {
    if (!params.length) return '';

    const param = params?.[this.props.visual.entityOrdering[0]];
    const { hsName, date, consolidationType, currency } = this.getHsData(param ?? {}, tree);

    if (multipleParams) {
      return params.map(param => {
        const { hsName, date, consolidationType, currency } = this.getHsData(param, tree);
        return this.renderHsRow(hsName, date, consolidationType, currency);
      });
    }

    return this.renderHsRow(hsName, date, consolidationType, currency);
  }

  renderBody() {
    const visual = this.props.visual as SingleValueConfig;
    const { logo, bgAlign, metadata } = visual;
    const logoURL = this.props.currentUser.company.branding.logoLargeUrl;
    let value;

    if (logo) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            backgroundImage: `url("${logoURL}")`,
            backgroundSize: 'contain',
            backgroundPosition: `${bgAlign} center`,
            backgroundRepeat: 'no-repeat'
          }}
        />
      );
    }

    if (metadata) {
      const multipleParams = this.context.params.p ? this.context.params.p?.length > 1 : false;
      const tree = this.props.currentUser.holdingSetTree;

      const params = EntityParametersHandler.retrieveParamsList(this.props.currentUser, undefined, true);

      switch (metadata) {
        case 'title':
          value = this.context.title;
          break;
        case 'date':
          value = this.retrieveDateValue(multipleParams, params);
          if (visual.dateFormat) {
            value = dayjs(value as string).format(visual.dateFormat);
          }
          break;
        case 'holdingSetName':
          value = this.retrieveEntityValue(multipleParams, params, tree);
          break;
        case 'consolidationType':
          value = this.retrieveConsolidationType(multipleParams, params);
          break;
        case 'hsList':
          value = this.retrieveHsList(multipleParams, params, tree);
          break;
        default:
      }
    } else {
      const defaultColumn = this.props.visual.columns.find(column => column.isDefault);
      if (defaultColumn) {
        const values = this.props.visual.data.totals;
        const columnValue = values[defaultColumn.field.name + defaultColumn.id] || values[defaultColumn.field.name];
        value = VisualEngine.formatCell(columnValue, defaultColumn, values);
      }
    }

    return (
      <span
        key={this.props.visual.id}
        style={{ ...(visual.styles?.title ?? {}), lineHeight: 1.1 }}
        className={cx({
          nowrap: visual.styles?.title?.whiteSpace === 'nowrap'
        })}
      >
        {typeof value === 'undefined' ? visual.emptyValue : value}
      </span>
    );
  }
}

export default SingleValue;
