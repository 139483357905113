import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { DocumentLookup } from '@/common/types/entity/DocumentLookup';

export default (id: number, params: { size: number }): Promise<DocumentLookup[]> =>
  requestApi({
    method: 'get',
    url: `/api/document/search`,
    service: 'DOCUMENT',
    params: { ...params, holdingSetId: id }
  }).then((response: AxiosResponse | undefined) => response?.data);
