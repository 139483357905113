import React, { ReactElement } from 'react';
import { HoldingSetType } from '../types/entity/HoldingSetType';
import { EntityBadge } from './EntityBadge';

export type HoldingSetTypeMappingT<R> = {
  [key in keyof typeof HoldingSetType]: R;
};
export const HoldingSetTypeBadges: Partial<HoldingSetTypeMappingT<ReactElement>> = {
  GROUPING: <EntityBadge symbol="G" tooltip="Grouping" />,
  DYNAMIC_GROUPING: <EntityBadge symbol="G" tooltip="Grouping" />,
  VEHICLE: <EntityBadge symbol="V" tooltip="Vehicle" />,
  PORTFOLIO: <EntityBadge symbol="P" tooltip="Portfolio" />
};
export const UncategorizedBadge = <EntityBadge symbol="?" tooltip="Uncategorized" />;
