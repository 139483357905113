import { requestApi } from '@/core';
import { ApiService } from 'ui-sesame-components';
import { PrivateIssuerDto } from '../types';

export const getPrivateIssuers = async (params?: { name?: string }): Promise<PrivateIssuerDto[]> => {
  return requestApi({
    url: `/api/private-issuers`,
    service: ApiService.ASSET,
    method: 'GET',
    params
  }).then(({ data }) => data);
};
