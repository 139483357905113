import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { Comment, CommentLookup } from '../../../common/types/entity/Conversation';

export default (conversation_id: number, comment: Comment): Promise<CommentLookup> =>
  requestApi({
    service: 'collaboration',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: `/api/conversation/${conversation_id}/comment`,
    data: comment
  }).then((response: AxiosResponse<CommentLookup> | undefined) => response?.data!);
