import { useMemo } from 'react';
import { UserLookup } from '../types/entity/UserLookup';
import ReactDOMServer from 'react-dom/server';
import { UserLabel } from '../user';
import { SmallSpin } from '../suspense';
import * as Quill from 'quill';
import { TaskLookup } from '../types/entity/Task';
import searchTasks, { SearchCriteria } from '../../modules/TMS/api/searchTasks';
import 'quill-mention';
import { debounce } from 'ts-debounce';
import { useRecoilValue } from 'recoil';
import { currentUser } from '../../recoil/users';

const TaskLabel: React.FC<{ task: TaskLookup }> = ({ task }) => {
  return (
    <>
      <span className="task-label-id">#{task.id}</span> {task.title}
    </>
  );
};

export default function (users: UserLookup[]): Quill.StringMap {
  const commenter = useRecoilValue(currentUser);
  return useMemo(() => {
    const commenterName = `${commenter.firstName} ${commenter.lastName}`;
    const searchTasksDebounced = debounce(searchTerm => searchTasks(new SearchCriteria(searchTerm)), 500, { maxWait: 1000 });

    const source = async function (searchTerm: string, renderList: (items: (UserLookup | TaskLookup)[], term: string) => void, mentionChar: string) {
      if (mentionChar === '@') {
        if (searchTerm.length === 0) {
          renderList(
            users.map(user => ({
              value: `${user.firstName} ${user.lastName}`,
              ...user
            })),
            searchTerm
          );
        } else {
          // we expect the number of users to be very small, hence simple/inefficient regexp search should suffice, otherwise a Trie needs to be used
          const regexp = new RegExp(searchTerm, 'i');
          renderList(
            users
              .map(user => ({
                value: `${user.firstName} ${user.lastName}`,
                commenterId: commenter.id,
                commenterName: commenterName,
                ...user
              }))
              .filter(user => user.value.match(regexp)),
            searchTerm
          );
        }
      } else if (mentionChar === '#') {
        const tasks = await searchTasksDebounced(searchTerm);
        renderList(
          tasks.map(task => ({
            value: `${task.id} ${task.title.replace(/(.{25})..+/, '$1…')}`,
            link: `?id=${task.id}`,
            ...task
          })),
          searchTerm
        );
      }
    };

    return {
      mention: {
        allowedChars: /^[a-zA-Z0-9_ ]*$/,
        spaceAfterInsert: true,
        linkTarget: '_self',
        mentionDenotationChars: ['@', '#'],
        dataAttributes: ['id', 'value', 'denotationChar', 'link', 'target', 'disabled', 'commenterId', 'commenterName'],
        renderItem: function (item: UserLookup | TaskLookup, searchTerm: string) {
          if ('login' in item) {
            return ReactDOMServer.renderToStaticMarkup(<UserLabel user={item} />);
          } else {
            return ReactDOMServer.renderToStaticMarkup(<TaskLabel task={item} />);
          }
        },
        renderLoading: function () {
          return ReactDOMServer.renderToStaticMarkup(SmallSpin);
        },
        listItemClass: 'ant-select-dropdown-menu-item',
        source
      }
    };
  }, [commenter, users]);
}
