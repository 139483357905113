import { Roles } from './security';
import type { InternalLoggedUser } from '@/common/auth/api/getInternalUser';

export const hasInternalRole = (internalUser: InternalLoggedUser | null, roles: Roles[]) => {
  if (!internalUser || !internalUser.roles) {
    return false;
  }

  if (internalUser.roles.includes(Roles.SUPER_ADMIN)) {
    return true;
  }

  return roles.some(role => {
    return internalUser.roles.includes(role);
  });
};
