import { requestApi } from '@/core';

export default () =>
  requestApi({
    url: '/api/holdingSets',
    service: 'client'
  }).then(({ data }) => {
    return data.map(({ id, name, type, lookThroughEnabled }) => ({
      id,
      holdingSetId: id,
      name,
      type,
      lookThroughEnabled
    }));
  });
