import { Conversation, ConversationType, Comment } from '../common/types/entity/Conversation';
import getConversation from '../modules/Conversation/api/getConversation';
import createConversation from '../modules/Conversation/api/createConversation';
import { atomFamily, selectorFamily } from 'recoil';
import { currentUser } from './users';
import { AxiosError } from 'axios';

export const conversation = atomFamily<Conversation, { refType: ConversationType; refId: number | string }>({
  key: 'conversation',
  dangerouslyAllowMutability: true,
  default: selectorFamily({
    key: 'conversation/default',
    dangerouslyAllowMutability: true,
    get:
      ({ refType, refId }) =>
      async () => {
        return retrieveConversation(refType, refId);
      }
  })
});

export const comments = atomFamily<
  Comment[],
  {
    refType: ConversationType;
    refId: number | string;
    withoutNewCommentFromOldModule?: boolean;
  }
>({
  key: 'comments',
  dangerouslyAllowMutability: true,
  default: selectorFamily({
    key: 'comments/default',
    dangerouslyAllowMutability: true,
    get:
      ({ refType, refId, withoutNewCommentFromOldModule }) =>
      ({ get }) => {
        const conv = get(conversation({ refType, refId }));
        if (withoutNewCommentFromOldModule) {
          return [...conv.comments];
        }
        return [...conv.comments, new Comment('', get(currentUser))];
      }
  })
});

async function retrieveConversation(refType: ConversationType, refId: number | string): Promise<Conversation> {
  try {
    return await getConversation(refType, refId);
  } catch (error) {
    if ((error as AxiosError).response?.status === 404) {
      return createConversation(new Conversation(refType, refId));
    }
    throw error;
  }
}
