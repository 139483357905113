import { requestApi } from '@/core';
import { PaginatedResponse } from '@/modules/ReportCentre/components/DynamicTable/types';
import { Entity, EntityListFilter } from '../types';

export const getLegalEntities = (params: EntityListFilter, options?: { errors: boolean }): Promise<PaginatedResponse<Entity[]>> => {
  return requestApi({
    ...options,
    url: '/api/external-entities/v2',
    service: 'client',
    method: 'post',
    data: { ...params, withMetadata: true },
    headers: {
      'x-search-with-body': true
    }
    // params
  }).then(({ data }) => data);
};
