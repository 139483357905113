import type { HoldingSet } from '@/modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';
import type { UUID } from '@/common/types/types';
import type { DocumentLookup } from './DocumentLookup';
import type { Contact, ContactLookup, NoteDTO, TaskDTO } from '@/modules/ERP/Contacts/types';
import type { Asset } from '@/api/getAsset';
import type { ValidatedDocument } from '@/modules/ERP/Documents/types';

enum EntityLinkType {
  ASSET = 'ASSET',
  DOCUMENT = 'DOCUMENT',
  CONTACT_PERSON = 'CONTACT_PERSON',
  CONTACT_COMPANY = 'CONTACT_COMPANY',
  TASK = 'TASK',
  NOTE = 'NOTE',
  OWNER = 'OWNER',
  HOLDING_SET = 'HOLDING_SET',
  FLOW = 'FLOW'
}

enum EntityLinkTypeMenu {
  VEHICLE = 'VEHICLE',
  GROUPING = 'GROUPING',
  PORTFOLIO = 'PORTFOLIO',
  ASSET = 'ASSET',
  DOCUMENT = 'DOCUMENT',
  CONTACT_PERSON = 'CONTACT_PERSON',
  CONTACT_COMPANY = 'CONTACT_COMPANY',
  TASK = 'TASK',
  NOTE = 'NOTE',
  OWNER = 'OWNER',
  FLOW = 'FLOW'
}

enum ActivityType {
  ASSET = 'ASSET',
  DOCUMENT = 'DOCUMENT',
  CONTACT = 'CONTACT',
  METADATA_KEY = 'METADATA_KEY',
  TASK = 'TASK',
  NOTE = 'NOTE',
  OWNER = 'OWNER',
  USER = 'USER',
  LINK = 'LINK',
  LIMIT_BREACH_CLASSIFICATION = 'LIMIT_BREACH_CLASSIFICATION',
  HOLDING_SET = 'HOLDING_SET',
  FLOW = 'FLOW'
}

interface LinkLookup {
  editable?: boolean;
  id: UUID;
  type: EntityLinkType;
  entityId: string;
  clientCompanyId: 1;
  info?: string;
}

interface Link extends LinkLookup {
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}

export type LinkableObject = (DocumentLookup | ValidatedDocument | ContactLookup | HoldingSet | Asset | NoteDTO | TaskDTO) & LinkableObjectWithType;

interface LinkableObjectWithType {
  _entityType: EntityLinkType;
  holdingSetType?: EntityLinkTypeMenu;
  type?: EntityLinkTypeMenu;
}

export type LinkableObjectList = Array<LinkableObject>;

export type LinkObject = DocumentLookup | Contact | Asset | NoteDTO | TaskDTO | unknown;

export { EntityLinkType, ActivityType, EntityLinkTypeMenu };
export type { LinkLookup, Link };
