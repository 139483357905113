import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { getEmptyRawColumn } from '@/modules/reporting-v2/utils/getEmptyRawColumn';
import { AxisLabel, Sorting, Orientation, BarChartValueType } from './VisualSpecificProps';
import type RawBarChartConfig from './types';

export const config: RawBarChartConfig = {
  type: 'visual',
  metric: getEmptyRawColumn(),
  group1: getEmptyRawColumn(),
  group2: getEmptyRawColumn(),
  orientation: Orientation.BarChart,
  axisScale: 'LINEAR',
  sorting: Sorting.asc,
  _options: {
    legend: {
      position: 'top',
      alignment: 'center'
    }
  },
  _reportContext: VisualReportContext.NONE,
  legend: true,
  axisLabel: AxisLabel.Both,
  diagonalAxisLabel: true,
  showLabelsOnGraph: false,
  valueType: BarChartValueType.Absolute,
  showPlusBeforeValue: false
};
