import React, { useCallback, useState } from 'react';
import { Text } from '@/common/components/Typography/Text';
import { EntityLinkTypeMenu } from '@/common/types/entity/Link';
import { EditTask } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/EditTask';
import { Row, Tooltip } from 'antd';
import type { TaskDTO } from '@/modules/ERP/Contacts/types';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { siteTreeState } from '@/modules/App/recoil/app.atoms';
import { getTaskUrlInfo } from '@/modules/ERP/Tasks/utils';

interface LinkRedirectProps {
  task: TaskDTO;
  type: EntityLinkTypeMenu;
  entityViewedId: string;
}

const TaskLinkRedirect: React.FC<LinkRedirectProps> = ({ type, task, entityViewedId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const siteTree = useRecoilValue(siteTreeState);

  const onClickLink = useCallback(() => {
    const { pathname, searchParams } = getTaskUrlInfo(String(task.id), siteTree);

    if (!pathname) {
      return setModalOpen(true);
    }

    history.push({ pathname, search: searchParams.toString() });
  }, [siteTree, history, task.id]);

  let title = task.title;
  const hasBigTitle = task.title.length > 85;
  if (hasBigTitle) {
    title = task.title.slice(0, 85) + '...';
  }

  return (
    <Row className="entity-links-single-entity" wrap={false}>
      <Text onClick={onClickLink} link>
        <Tooltip title={task.title} trigger={hasBigTitle ? undefined : []}>
          {title}
        </Tooltip>
      </Text>

      {modalOpen && <EditTask search="" isOpen={modalOpen} setIsOpen={setModalOpen} contactId={entityViewedId} taskId={task.id} pageEntityType={type} pageEntityId={task.id} />}
    </Row>
  );
};

export { TaskLinkRedirect };
