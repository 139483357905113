import datasourceConfigs from '@/modules/reporting-v2/config/datasource';
import { Index } from '@/modules/reporting-v2/types/Index';
import requestApi from '@/core/requestApi';

export default async () => {
  const datasourceConfig = datasourceConfigs.get(Index.metadata);

  return requestApi({
    service: datasourceConfig!.meta!.service,
    url: datasourceConfig!.meta!.uri
  }).then(resp => resp.data);
};
