import { AtomEffect } from 'recoil';

const localStorage = typeof window !== 'undefined' ? window.localStorage : null;
type LocalStorageEffect = (localStorageKey: string) => AtomEffect<any>;

export const localStorageEffect: LocalStorageEffect =
  key =>
  ({
    setSelf, // callbacks to set the value of the atom
    onSet // subscribe to changes in the atom value
  }) => {
    if (localStorage) {
      // if we already have some value in local storage, we set it as the default atom value
      const savedValue = localStorage.getItem(key);
      if (savedValue !== null) {
        setSelf(JSON.parse(savedValue));
      }

      // when the value of the atom changes, we update the value in local storage (or reset it)
      onSet((newValue, _, isReset) => {
        isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
      });
    }
  };
