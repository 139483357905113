import React from 'react';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { config } from './config';
import schema from './schema.json';
import RawTableOfContentConfig, { ListType } from './types';
import { BuilderMode } from '@/modules/report-builder/types';
import type { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import type TableOfContentConfig from './TableOfContentConfig';
import './style.css';

class TableOfContent extends Visual {
  Loader = Loaders.Table;

  static configMapper(visualConfig: RawTableOfContentConfig) {
    return visualConfig;
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as VisualEngine;
  }

  getSchema() {
    return schema;
  }

  getPages() {
    const pages = this.context.reportConfiguration.pages;
    return pages.filter(page => page.mode !== BuilderMode.TEMPLATE);
  }

  getWrapperStyle(): React.CSSProperties {
    const visualStyle = this.props.visual.styles;
    const style: React.CSSProperties = { ...visualStyle?.table?.body };

    style.textAlign = visualStyle?.table?.textAlign;
    style.paddingLeft = '0';

    return style;
  }

  getRowStyle(index: number): {
    wrapper: React.CSSProperties;
    child: React.CSSProperties;
  } {
    const visualStyle = this.props.visual.styles;

    let marginRight;
    let marginLeft;
    if (visualStyle?.others?.pageTitleAlignement === 'right') {
      marginLeft = 'auto';
    }
    if (visualStyle?.others?.pageTitleAlignement === 'center') {
      marginLeft = 'auto';
      marginRight = 'auto';
    }

    const chartColors = visualStyle?.table?.chartColors;

    return {
      wrapper: {
        backgroundColor: chartColors?.[index % chartColors?.length],
        width: '100%'
      },
      child: {
        width: visualStyle?.others?.pageTitleWidth,
        padding: visualStyle?.others?.padding,
        marginRight,
        marginLeft
      }
    };
  }

  renderBody() {
    const visual = this.props.visual as TableOfContentConfig;
    const pages = this.getPages();
    const wrapperStyle = this.getWrapperStyle();

    return (
      <div className={'table-of-content'}>
        <TableOfContentListWrapper style={wrapperStyle} listType={visual.listType}>
          {pages.map((page, index) => {
            const rowStyle = this.getRowStyle(index);

            return (
              <li style={rowStyle.wrapper} key={page.id}>
                <div style={rowStyle.child}>{page.title}</div>
              </li>
            );
          })}
        </TableOfContentListWrapper>
      </div>
    );
  }
}

export default TableOfContent;

type TableOfContentListWrapperProps = React.PropsWithChildren<{
  listType: ListType;
  style: React.CSSProperties;
}>;

export const TableOfContentListWrapper: React.FC<TableOfContentListWrapperProps> = ({ listType, children, style }) => {
  if (listType === 'disc') {
    return (
      <ul style={{ ...style }} className={'disc'}>
        {children}
      </ul>
    );
  }

  if (listType === 'number') {
    return (
      <ol style={style} className={'number'}>
        {children}
      </ol>
    );
  }

  if (listType === 'roman-number') {
    return (
      <ol style={{ ...style }} className={'upper-roman'}>
        {children}
      </ol>
    );
  }

  return <ul style={style}>{children}</ul>;
};
