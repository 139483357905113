import { EntityLinkType, EntityLinkTypeMenu, LinkableObject } from '@/common/types/entity/Link';
import { Reminders, RemindersToTranslationId, Status, Priority } from '@/modules/ERP/Contacts/types';
import { EnumUtils } from '@/utils/EnumUtils';
import { DatePicker, Menu, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TaskSiderItem } from './SiderItem';
import { CaretDownOutlined } from '@ant-design/icons';
import { SelectListUsers } from './SelectListUsers';
import { SelectLinksContent } from './SelectLinks';
import type { TaskFormType } from '../CreateTask';
import { useRecoilValue } from 'recoil';
import { linkableObjectsSelector } from '@/modules/ERP/Common/Recoil/links.selector';

interface TaskSiderProps {
  onChange: (values: TaskFormType, key: string, value: string | Dayjs | { id: number } | null) => void;
  values: TaskFormType;
  setSelectedLinks: React.Dispatch<SetStateAction<Array<LinkableObject>>>;
  selectedLinks: Array<LinkableObject>;
}

const TaskSider: React.FunctionComponent<TaskSiderProps> = React.memo(({ onChange, values, setSelectedLinks, selectedLinks }) => {
  const translator = useIntl();

  const onDateChange = useCallback(
    (date: Dayjs | null) => {
      onChange(values, 'dueDate', date);
    },
    [onChange, values]
  );

  const reminderData = useMemo(() => {
    return Object.values(Reminders).map(reminder => {
      return {
        key: reminder,
        value: reminder,
        label: (
          <Typography.Text type="secondary">
            <FormattedMessage id={RemindersToTranslationId[reminder]} />
          </Typography.Text>
        )
      };
    });
  }, []);

  const typeData = useMemo(() => {
    return Object.values(Priority).map(type => {
      return {
        key: type,
        value: type,
        label: (
          <Typography.Text type="secondary">
            <FormattedMessage id={'generic.' + EnumUtils.toCamelCase(type)} />
          </Typography.Text>
        )
      };
    });
  }, []);

  const statusData = useMemo(() => {
    return Object.values(Status).map(status => {
      return {
        key: status,
        value: status,
        label: (
          <Typography.Text type="secondary">
            <FormattedMessage id={'generic.' + EnumUtils.toCamelCase(status)} />
          </Typography.Text>
        )
      };
    });
  }, []);

  const handleClickReminder = useCallback(
    (e: any) => {
      onChange(values, 'reminder', e.key);
    },
    [onChange, values]
  );

  const handleClickPriority = useCallback(
    (e: any) => {
      onChange(values, 'priority', e.key);
    },
    [onChange, values]
  );

  const handleClickStatus = useCallback(
    (e: any) => {
      onChange(values, 'status', e.key);
    },
    [onChange, values]
  );

  const handleSelectUser = useCallback(
    (value: number) => {
      onChange(values, 'assignee', { id: value });
    },
    [onChange, values]
  );

  const linkableObjects = useRecoilValue(linkableObjectsSelector({ type: EntityLinkTypeMenu.PORTFOLIO }));

  useEffect(() => {
    const setMatchingHoldingSetLink = () => {
      const matchingObject = linkableObjects.find(obj => obj.id === values.holdingSetId);
      if (matchingObject) {
        setSelectedLinks([matchingObject]);
      }
    };
    setMatchingHoldingSetLink();
  }, [linkableObjects, setSelectedLinks, values]);

  return (
    <>
      <TaskSiderItem
        popoverClassName={'task-modal-data-popover'}
        title={translator.formatMessage({ id: 'generic.assignedToUppercase' })}
        clickableText2={
          <div className="task-details-container">
            <SelectListUsers values={values} onChange={handleSelectUser} />
          </div>
        }
      />
      <TaskSiderItem
        popoverClassName={'task-modal-data-popover'}
        title={translator.formatMessage({ id: 'generic.dueDate' })}
        clickableText2={
          <div className="task-details-container">
            <DatePicker
              showTime={true}
              defaultValue={values.dueDate ? dayjs(values.dueDate) : undefined}
              format="MMMM Do, YYYY HH:mm"
              placeholder={translator.formatMessage({ id: 'generic.noDueDate' })}
              variant="borderless"
              onChange={onDateChange}
              className="task-informations-datepicker"
              suffixIcon={<CaretDownOutlined className={'modal-contact-create-clickable-icon'} />}
            />
          </div>
        }
      />
      <TaskSiderItem
        popoverClassName={'task-modal-data-popover'}
        closeOnClick
        title={translator.formatMessage({ id: 'generic.reminder' })}
        content={
          <div className="task-details-container">
            <Menu onClick={handleClickReminder} defaultSelectedKeys={[values.reminder]} items={reminderData} className="entity-linkable-objects-entity-list" />
          </div>
        }
        clickableText={translator.formatMessage({
          id: RemindersToTranslationId[values.reminder]
        })}
      />
      <TaskSiderItem
        popoverClassName={'task-modal-data-popover'}
        closeOnClick
        title={translator.formatMessage({ id: 'generic.priority' })}
        content={
          <div className="task-details-container">
            <Menu onClick={handleClickPriority} defaultSelectedKeys={[values.priority]} items={typeData} className="entity-linkable-objects-entity-list" />
          </div>
        }
        clickableText={translator.formatMessage({
          id: 'generic.' + EnumUtils.toCamelCase(values.priority)
        })}
      />
      <TaskSiderItem
        popoverClassName={'task-modal-data-popover'}
        title={translator.formatMessage({ id: 'generic.status' })}
        closeOnClick
        content={
          <Menu onClick={handleClickStatus} defaultSelectedKeys={[values.status]} items={statusData} className="entity-linkable-objects-entity-list task-details-container" />
        }
        clickableText={translator.formatMessage({
          id: 'generic.' + EnumUtils.toCamelCase(values.status)
        })}
      />
      <TaskSiderItem
        popoverClassName={'task-modal-data-popover'}
        title={translator.formatMessage({ id: 'generic.links' })}
        content={
          <div className="task-details-container">
            <SelectLinksContent selectedLinks={selectedLinks} setSelectedLinks={setSelectedLinks} defaultSelectedType={EntityLinkType.ASSET} />
          </div>
        }
        clickableText={translator.formatMessage({ id: 'generic.xNumberLinks' }, { number: selectedLinks.length })}
      />
    </>
  );
});

export { TaskSider };
