import React, { useCallback, useState } from 'react';
import { Button, Card, Col, Collapse, Divider, Flex, Row, Space, Typography } from 'antd';
import { Text } from '@/common/components/Typography/Text';
import { EditOutlined, RightOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import DOMPurify from 'dompurify';
import { Reminders, RemindersToTranslationId, Status, TaskDTO, Priority } from '@/modules/ERP/Contacts/types';
import { EditTask } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/EditTask';
import { Comments } from '@/modules/ERP/Contacts/ContactDetails/Comments/Comments';
import { ConversationType } from '@/common/types/entity/Conversation';
import { EnumUtils } from '@/utils/EnumUtils';
import { EntityLinkType } from '@/common/types/entity/Link';

interface TaskProps {
  id: string;
  task: TaskDTO;
  entityId: string;
  entityType: EntityLinkType;
  search: string;
}

const Task: React.FunctionComponent<TaskProps> = React.memo(({ id, task, entityId, search, entityType }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const translator = useIntl();
  const handleClickEditTask = useCallback((e: any) => {
    e.stopPropagation();
    setVisibleModal(true);
  }, []);

  const onCollapseToggle = useCallback(
    (keys: string | string[]) => {
      if (keys.length > 0) {
        setIsActive(true);
        return;
      }
      setIsActive(false);
    },
    [setIsActive]
  );

  return (
    <Card className="contact-activity-card task-card">
      <Collapse ghost expandIcon={() => null} onChange={onCollapseToggle}>
        <Collapse.Panel
          header={
            <div>
              <Row justify="space-between" align="middle" gutter={24} wrap={false}>
                <Col style={{ paddingRight: 0, flexBasis: '28px' }}>
                  <RightOutlined className={'contact-activity-title-arrow'} rotate={isActive ? 90 : 0} />
                </Col>
                <Col style={{ paddingLeft: 0, marginRight: 'auto' }} sm={{ span: 6 }} span={8}>
                  <Typography.Paragraph ellipsis={{ rows: 3, expandable: true, symbol: translator.formatMessage({ id: 'generic.seeMore' }) }} style={{ marginBottom: 0 }}>
                    {task.title}
                  </Typography.Paragraph>
                </Col>
                <Col md={{ span: 12 }} span={15}>
                  <Row justify="end">
                    <Space size="large">
                      <TaskId id={task.id} />
                      <TaskStatus status={task.status} />
                      <TaskPriority priority={task.priority} />
                      <TaskDueDate dueDate={task.dueDate} />
                      {isActive && <Button icon={<EditOutlined />} type="link" onClick={handleClickEditTask} />}
                    </Space>
                  </Row>
                </Col>
              </Row>
              <Divider className="task-header-divider" />
              <Text type="secondary">
                <div
                  className="task-card-description"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(task.description)
                  }}
                />
              </Text>
            </div>
          }
          key="1"
        >
          <Flex vertical style={{ padding: '0 16px 12px 16px' }}>
            <Divider className="task-header-divider" style={{ marginTop: 0 }} />
            <Space size="large">
              <TaskReminder reminder={task.reminder} />
            </Space>
            <Divider className="task-header-divider" />
            <Comments refId={Number(task.id)} refType={ConversationType.TASK} />
          </Flex>
        </Collapse.Panel>
      </Collapse>
      {visibleModal && (
        <EditTask search={search} contactId={entityId} taskId={id} isOpen={visibleModal} setIsOpen={setVisibleModal} pageEntityType={entityType} pageEntityId={entityId} />
      )}
    </Card>
  );
});

const TaskId: React.FC<{ id: string }> = ({ id }) => {
  const translator = useIntl();

  return (
    <div style={{ width: 50 }}>
      <Text type="secondary">{translator.formatMessage({ id: 'erp.contacts.single.taskNo' })}</Text>
      <br />
      <Text>#{id}</Text>
    </div>
  );
};

const TaskDueDate: React.FC<{ dueDate: string | undefined }> = ({ dueDate }) => {
  const translator = useIntl();

  let dueDateText: string;

  if (dueDate) {
    const formattedDueDate = new Date(dueDate);
    const date = formattedDueDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
    const time = formattedDueDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
    dueDateText = `${date}, ${time}`;
  } else {
    dueDateText = translator.formatMessage({ id: 'generic.noDueDate' });
  }

  return (
    <div style={{ width: 115 }}>
      <Text type="secondary">{translator.formatMessage({ id: 'generic.dueDate' })}</Text>
      <br />
      <Text>{dueDateText}</Text>
    </div>
  );
};

const TaskReminder: React.FC<{ reminder: Reminders }> = ({ reminder }) => {
  const translator = useIntl();

  return (
    <div>
      <Text type="secondary">{translator.formatMessage({ id: 'generic.reminder' })}</Text>
      <br />
      <Text>
        {translator.formatMessage({
          id: RemindersToTranslationId[reminder]
        })}
      </Text>
    </div>
  );
};

const TaskPriority: React.FC<{ priority: Priority }> = ({ priority }) => {
  const translator = useIntl();

  return (
    <div style={{ width: 56 }}>
      <Text type="secondary">{translator.formatMessage({ id: 'generic.priority' })}</Text>
      <br />
      <Text>
        {translator.formatMessage({
          id: 'generic.' + EnumUtils.toCamelCase(priority)
        })}
      </Text>
    </div>
  );
};

const TaskStatus: React.FC<{ status: Status }> = ({ status }) => {
  const translator = useIntl();

  return (
    <div style={{ width: 70 }}>
      <Text type="secondary">{translator.formatMessage({ id: 'generic.status' })}</Text>
      <br />
      <Text>
        {translator.formatMessage({
          id: 'generic.' + EnumUtils.toCamelCase(status)
        })}
      </Text>
    </div>
  );
};

export { Task };
