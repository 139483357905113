import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedStatsTableConfig } from '..';
import { StatsTableConfigMapped } from './types';
import { Mode } from './VisualSpecificProps';

export default class StatsTableConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as StatsTableConfigMapped));
  }

  getVisualConfigProps(config: StatsTableConfigMapped): ExtendedStatsTableConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      mode: config.mode,
      transpose: config.transpose
    });
  }

  setVisualConfig(config: ExtendedStatsTableConfig) {
    super.setVisualConfig(config);
    this.mode = config.mode;
    this.total = true;
    this.htmlEditableOnly = true;
    this.transpose = config.transpose;
  }

  mode: Mode[];
  transpose: boolean;
}
