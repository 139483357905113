import React, { FC, useCallback, useMemo, useState } from 'react';
import { CreateTaskDialog } from './CreateTaskDialog';
import { CarryOutOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { CreateTaskButtonProps } from './CreateTaskButton';
import searchTasks, { SearchCriteria } from '@/modules/TMS/api/searchTasks';
import getTaskUrl from '@/modules/TMS/getTaskUrl';
import { TaskType } from '@/common/types/entity/Task';
import { Status } from '@/modules/ERP/Contacts/types';

// Improvement 1: Button loading state
// Improvement 2: Dialog show preview of existing task and button "Go to Task"

export interface CreateOrFindTaskButtonProps extends CreateTaskButtonProps {
  types: TaskType[];
}

export const CreateOrFindTaskButton: FC<CreateOrFindTaskButtonProps> = ({ disabled, label, initTask, onDialogVisibleChange, types }) => {
  const [showDialog, setShowDialog] = useState(false);
  const onOpenChange = (visible: boolean) => toggleVisibility(visible);

  const toggleVisibility = useCallback(
    (visible: boolean) => {
      setShowDialog(visible);
      onDialogVisibleChange && onDialogVisibleChange(visible);
    },
    [onDialogVisibleChange]
  );

  const fetchCurrentTasks = useCallback(async () => {
    if (initTask) {
      // Only fetches OPEN tasks.
      return searchTasks({
        ...new SearchCriteria(),
        ...initTask(),
        statuses: [Status.TO_DO],
        types
      });
    }
    return [];
  }, [initTask, types]);

  const handleOnClick = useCallback(async () => {
    const currentTasks = await fetchCurrentTasks();
    if (currentTasks && currentTasks.length > 0) {
      window.location.href = getTaskUrl(currentTasks[0].id, false);
    } else {
      toggleVisibility(true);
    }
  }, [fetchCurrentTasks, toggleVisibility]);

  const createTaskButton = useMemo(() => {
    return <Button disabled={disabled} type="link" size="small" icon={<CarryOutOutlined />} onClick={handleOnClick} />;
  }, [disabled, handleOnClick]);

  if (showDialog) {
    return (
      <CreateTaskDialog task={initTask && initTask()} onOpenChange={onOpenChange} getContainer={getDocumentBody}>
        {createTaskButton}
      </CreateTaskDialog>
    );
  }

  return <Tooltip title={label}>{createTaskButton}</Tooltip>;
};

function getDocumentBody() {
  return window.document.body;
}
