export type HistoricalConfigKey = string;

/**
 * LAST_YEAR is a period of one year from the date (should be calculated) to the selected validated date
 * YEAR_TO_DATE is a period from 1 January to the selected validated date
 * Example: validated date is 2019-07-31,
 * YEAR_TO_DATE in this case is the period from 2019-01-01 to 2019-07-31,
 * LAST_YEAR is the period from 2018-07-31 to 2019-07-31
 */

export enum HistoricalRange {
  LAST_SEVEN_DAYS,
  LAST_MONTH,
  LAST_THREE_MONTHS,
  LAST_SIX_MONTHS,
  YEAR_TO_DATE,
  ONE_YEAR,
  THREE_YEAR,
  FOUR_YEARS,
  INCEPTION,
  SELECTED_DATE,
  MONTH_TO_DATE
}

export enum HistoricalChartDateRangePeriod {
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_MONTH = 'LAST_MONTH',
  LAST_3_MONTH = 'LAST_3_MONTHS',
  LAST_6_MONTH = 'LAST_6_MONTHS',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  LAST_YEAR = 'LAST_YEAR',
  LAST_3_YEARS = 'LAST_3_YEARS',
  LAST_4_YEARS = 'LAST_4_YEARS',
  FROM_INCEPTION = 'FROM_INCEPTION'
}

/*

Possible cases atm (to refacto):

- Historical chart & Scatter Chart:

    FROM_INCEPTION
    LAST_4_YEARS
    LAST_3_YEARS
    LAST_YEAR
    LAST_3_MONTHS
    LAST_MONTH

- Historical monthly tab

    lastThreeYears
    lastTwelveMonths
    currentYear
*/

export const getRange = (range?: string): HistoricalRange => {
  switch (range) {
    case 'FROM_INCEPTION':
      return HistoricalRange.INCEPTION;
    case 'LAST_4_YEARS':
      return HistoricalRange.THREE_YEAR;
    case 'LAST_MONTH':
      return HistoricalRange.LAST_MONTH;
    case 'lastThreeYears':
      return HistoricalRange.THREE_YEAR;
    case 'lastTwelveMonths':
      return HistoricalRange.ONE_YEAR;
    case 'currentYear':
      return HistoricalRange.ONE_YEAR;
    case HistoricalChartDateRangePeriod.LAST_7_DAYS:
      return HistoricalRange.LAST_SEVEN_DAYS;
    case HistoricalChartDateRangePeriod.LAST_3_MONTH:
      return HistoricalRange.LAST_THREE_MONTHS;
    case HistoricalChartDateRangePeriod.LAST_6_MONTH:
      return HistoricalRange.LAST_SIX_MONTHS;
    case HistoricalChartDateRangePeriod.YEAR_TO_DATE:
      return HistoricalRange.YEAR_TO_DATE;
    case HistoricalChartDateRangePeriod.LAST_YEAR:
      return HistoricalRange.ONE_YEAR;
    case HistoricalChartDateRangePeriod.LAST_3_YEARS:
      return HistoricalRange.THREE_YEAR;
    default:
      return HistoricalRange.ONE_YEAR;
  }
};
