import { Form as AntdForm, FormInstance } from 'antd';
import React, { createContext, useContext, useMemo } from 'react';
import Zod from 'zod';
import { ValidatorRule } from 'rc-field-form/lib/interface';
import { useFormValues } from './useFormValues';

/**
 * Adapter to convert Zod Validation to Antd Validation.
 *
 * Uses Antd Form values to parse the Zod Schema and match
 * Antd Rule Field Name with ZodError Path to extract the error message.
 *
 * Eg. Math fieldName `name` with ZodError Path `name`.
 * @param schema Zod Schema. Must be a Zod Object.
 * @returns Antd Validation Rules.
 */
const createRules = (schema: Zod.ZodTypeAny): React.ComponentProps<typeof AntdForm.Item>['rules'] => {
  return [
    ({ getFieldsValue }) => ({
      validator: async rule => {
        const { field } = rule as { field: string };
        const values = getFieldsValue();
        const result = await schema.safeParseAsync(values);
        if (!result.success) {
          const error = result.error.issues.find(({ path }) => path.join('.').toString() === field.toString());
          if (error) {
            return Promise.reject(error);
          }
        }

        return Promise.resolve(undefined);
      }
    })
  ];
};

type SchemaType<T extends object = object> = Zod.ZodType<T>;

type FormProps<T extends object> = React.ComponentProps<typeof AntdForm<T>> & {
  schema?: SchemaType<T>;
};

interface FormContextType<T extends object> {
  schema: SchemaType<T>;
}

/**
 * Custom Form Context. Combines Antd Form Context and Zod Schema.
 */
const FormContext = createContext<FormContextType<object> | undefined>(undefined);

/**
 * Extends Antd Form to add Zod Schema as prop and provide React Context with the schema.
 *
 * Furthermore, it uses the schema to parse the form values and return the parsed values to the onFinish.
 */
const InternalForm = <T extends object>({ schema = Zod.any(), onFinish, onChange, ...rest }: FormProps<T> & { onChange?: (values: T, key: string, value: any) => void }) => {
  const formValuesHook = useFormValues<T>(rest.initialValues || {}, onChange);

  const context = useMemo<FormContextType<T>>(() => ({ schema, formValuesHook }), [schema, formValuesHook]);

  return (
    <FormContext.Provider value={context}>
      <AntdForm
        onValuesChange={(changedValues, allValues) => {
          for (const [key, value] of Object.entries(changedValues)) {
            formValuesHook.updateFormValue(key, value);
          }
        }}
        {...rest}
        onFinish={values => schema.parseAsync(values).then(onFinish) as T}
        initialValues={formValuesHook.formValues}
      />
    </FormContext.Provider>
  );
};

/**
 * Hook to obtain Form Context. It is a combination of Antd Form Context and Zod Schema.
 */
const useFormInstance = <T extends object>(): FormInstance<T> & FormContextType<T> => {
  const antdForm = AntdForm.useFormInstance();
  const formContext = useContext(FormContext as unknown as React.Context<FormContextType<T>>);

  return { ...antdForm, ...formContext };
};

/**
 * Extends Antd Form Item to add rules from Zod Schema generated by `createRules` function.
 */
const FormItem = ({ children, ...rest }: Omit<React.ComponentProps<typeof AntdForm.Item>, 'rules'>) => {
  const form = useFormInstance();
  return (
    <AntdForm.Item {...rest} rules={createRules(form.schema)}>
      {typeof children === 'function' ? children(form) : children}
    </AntdForm.Item>
  );
};

/**
 * Extends Antd Form List to add rules from Zod Schema generated by `createRules` function.
 */
const FormList = (rest: Omit<React.ComponentProps<typeof AntdForm.List>, 'rules'>) => {
  const form = useFormInstance();
  return <AntdForm.List {...rest} rules={createRules(form.schema) as ValidatorRule[]} />;
};

const FormErrorList = AntdForm.ErrorList;

export const Form = Object.assign(InternalForm, {
  ...AntdForm,
  useFormInstance,
  Item: FormItem,
  List: FormList,
  ErrorList: FormErrorList
});
