import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import type { FlagValue, FlagConfigMapped } from './types';
import type ExtendedFlagConfig from './VisualSpecificProps';

export default class FlagConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as FlagConfigMapped));
  }

  getVisualConfigProps(config: FlagConfigMapped): ExtendedFlagConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      label: config.label,
      staticValue: config.staticValue
    });
  }

  setVisualConfig(config: ExtendedFlagConfig) {
    super.setVisualConfig(config);

    this.label = config.label;
    this.staticValue = config.staticValue;
  }

  label: string;
  staticValue?: FlagValue;
}
