import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import type { ValidatedDocument } from '@/modules/ERP/Documents/types';

export default (ids: string): Promise<ValidatedDocument[]> =>
  requestApi({
    method: 'get',
    url: `/api/document/ids/${ids}`,
    service: 'DOCUMENT'
  }).then((response: AxiosResponse | undefined) => response?.data);
