import { LoggedUser } from '@/common/auth/api/login';
import { Permissions } from './security';

export const hasPermission = (currentUser: LoggedUser | null, permissions: string[]) => {
  if (!currentUser || !currentUser.permissions) {
    // the user is not logged in and we are in the process of login the user
    return true;
  }
  return permissions.some(permission => {
    if (permission === Permissions.SUPER_ADMIN) {
      return currentUser.isSuperAdmin;
    }
    return currentUser.permissions.includes(permission);
  });
};
