import { ConfigMappedVisual, RawColumn, RawConfig } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { VisualConfigProps } from '@/modules/reporting-v2/types/VisualEngine';

export type RawGaugeConfig = {
  metric: RawColumn;
  minValue?: number;
  maxValue?: number;
  bands?: Band[];
  gaugeType: GaugeType;
  thickness: number;
  portfolioScore?: boolean; // hidden option to use quintile portfolio score
} & RawConfig;

export type Band = {
  label?: string;
  start: number;
  end: number;
};

export interface ExtendedGaugeConfig extends VisualConfigProps {
  minValue: number;
  maxValue: number;
  bands: Band[];
  gaugeType: GaugeType;
  thickness: number;
  portfolioScore: boolean | undefined;
}

export type GaugeConfigMapped = {
  minValue?: number;
  maxValue?: number;
  bands?: Band[];
  gaugeType: GaugeType;
  thickness: number;
  portfolioScore?: boolean;
} & ConfigMappedVisual;

export enum GaugeType {
  SPEED = 'speedgauge',
  HORIZONTAL = 'horizontalgauge',
  STAR_RATING = 'starrating'
}

export type QuintileScore = {
  holdingSetId: number;
  informationRatioQuarterly: number;
  lastVerifiedDate: string;
  quintileScore: number;
};

export type QuintileScorePayload = {
  holdingSetId: string | number;
  date: string;
};

export default RawGaugeConfig;
