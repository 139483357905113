import React from 'react';
import { Radio } from 'antd';
import { ConsolidationType } from '@/modules/reporting-v2/types/ReportingService';
import { EnumUtils } from '@/utils/EnumUtils';
import { RadioChangeEvent } from 'antd/lib';

interface ConsolidationTypeFieldProps {
  value?: ConsolidationType;
  onChange?: (cs: ConsolidationType) => void;
}

export const ConsolidationTypeField: React.FC<ConsolidationTypeFieldProps> = React.memo(({ value, onChange }) => {
  const handleChange = React.useCallback(
    (e: RadioChangeEvent) => {
      onChange?.(e.target.value);
    },
    [onChange]
  );

  const ConsolidationTypeOptions = React.useMemo(() => {
    return Object.entries(ConsolidationType).map(([key, consolidationType]) => {
      return (
        <Radio.Button key={consolidationType} value={consolidationType}>
          {EnumUtils.toHumanReadable(key)}
        </Radio.Button>
      );
    });
  }, []);

  return (
    <Radio.Group value={value} onChange={handleChange} className="report-scheduling-form-ct" buttonStyle="solid">
      {ConsolidationTypeOptions}
    </Radio.Group>
  );
});
