import { HistoricalConfigKey, HistoricalRange } from '@/modules/reporting-v2/types/HistoricalConfig';
import { arrayAsKey } from '@/modules/reporting-v2/utils/IndexUtils';

class HistoricalConfig {
  private constructor(enabled: boolean, range?: HistoricalRange, sampling?: boolean) {
    this.enabled = enabled;
    this.sampling = sampling;
    this.range = range;
  }

  enabled: boolean;
  sampling?: boolean;
  range?: HistoricalRange;

  key(): HistoricalConfigKey {
    return arrayAsKey(['hsk', this.enabled, this.sampling, this.range]);
  }

  static disabled(): HistoricalConfig {
    return new HistoricalConfig(false);
  }

  static enabled(range: HistoricalRange = HistoricalRange.ONE_YEAR, sampling = true): HistoricalConfig {
    return new HistoricalConfig(true, range, sampling);
  }
}

export { HistoricalConfig };
