import { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dataTableExpensionState } from '../recoil/table.atom';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';

const holdingSetIdPath = 'holdingset.holdingSetId';

const useRowGroup = (
  group: string,
  data: FlattenObject,
  collapsible: boolean,
  visualId: string,
  groupTrace: string,
  maxGroupLevel: number,
  level: number,
  active?: boolean,
  selectedHoldingset?: number,
  onRowSelect?: (holdingSetId: number, userId: string, data: FlattenObject) => void,
  overrideExpansion?: boolean,
  fullRow?: boolean
) => {
  const [dataTableExpension, setDataTableExpension] = useRecoilState(dataTableExpensionState);
  const currentUser = useRecoilValue(currentUserSelector);
  const expand = dataTableExpension[visualId]?.[`${groupTrace}${group}` as const] || fullRow === true;

  const _onExpand = useCallback(
    e => {
      e.stopPropagation();
      if (!expand) {
        setDataTableExpension({
          ...dataTableExpension,
          [visualId]: {
            ...(dataTableExpension[visualId] || {}),
            [`${groupTrace}${group}`]: true
          }
        });
      } else {
        setDataTableExpension({
          ...dataTableExpension,
          [visualId]: {
            ...(dataTableExpension[visualId] || {}),
            [`${groupTrace}${group}`]: false
          }
        });
      }
    },
    [expand, group, setDataTableExpension, dataTableExpension, visualId, groupTrace]
  );

  const rowStyle = {
    display: expand && !overrideExpansion ? 'table-row' : 'none'
  };

  const hasNoChildren = !collapsible && maxGroupLevel === level;

  const _onRowSelect = useCallback(
    (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      e.stopPropagation();

      if (onRowSelect) {
        onRowSelect(data[holdingSetIdPath] as number, currentUser.userId, data);
      }
    },
    [onRowSelect, data, currentUser.userId]
  );

  const tableRowStyles = useMemo(() => {
    return {
      display: (active || active === undefined) && !overrideExpansion ? 'table-row' : 'none',
      background: selectedHoldingset && data[holdingSetIdPath] === selectedHoldingset ? 'lightgray' : undefined
    };
  }, [active, overrideExpansion, selectedHoldingset, data]);

  const className = cx('group-row', {
    active: expand,
    'no-children': hasNoChildren
  });

  return {
    onExpand: _onExpand,
    expand,
    tableRowStyles,
    className,
    rowStyle,
    _onRowSelect,
    _overrideExpansion: expand === false || overrideExpansion
  };
};

export default useRowGroup;
