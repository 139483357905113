import React from 'react';
import { ExpandedState } from '@tanstack/react-table';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import { useExportContext } from '@/modules/AnalyticsReportViewer/contexts/ExportContext';

const initialExpandingState = {};

const useExpandState = (defaultExpanded?: boolean) => {
  const { exporting } = React.useContext(ReportContext);
  const { isExporting: newExporting } = useExportContext();
  const overrideExpanded = exporting === 'pdf' || exporting === 'excel' || newExporting;

  const [expanded, setExpanded] = React.useState<ExpandedState>(defaultExpanded ?? initialExpandingState);

  return [!!overrideExpanded || expanded, setExpanded] as const;
};

export { useExpandState };
