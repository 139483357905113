import React, { createContext, useContext } from 'react';

type DataBarsContextWrapperProps = {
  children: React.ReactElement;
  maxRangeValues: Record<string, number> | undefined;
};

type DataBarsContextValue = Record<string, number> | undefined;

const DataBarsContext = createContext<DataBarsContextValue>(undefined);

const DataBarsContextWrapper: React.FC<DataBarsContextWrapperProps> = ({ children, maxRangeValues }) => {
  return <DataBarsContext.Provider value={maxRangeValues}>{children}</DataBarsContext.Provider>;
};

const useDataBarsContext = () => {
  const dataBarsContextValue = useContext(DataBarsContext);

  return dataBarsContextValue;
};

export { DataBarsContextWrapper, useDataBarsContext };
