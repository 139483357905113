import type { DatasourceConfig } from '@/modules/reporting-v2/types/DatasourceConfig';
import { Index } from '@/modules/reporting-v2/types/Index';
import allocation from './json/allocation.json';
import asset_info_undated from './json/asset_info_undated.json';
import flows from './json/flows.json';
import flows_aggregated from './json/flows_aggregated.json';
import hedges_fx from './json/hedges_fx.json';
import holding_set_aum from './json/holding_set_aum.json';
import holdingset from './json/holdingset.json';
import holdingset_asset from './json/holdingset_asset.json';
import limits from './json/limits.json';
import liquidity from './json/liquidity.json';
import metadata from './json/metadata.json';
import performance from './json/performance.json';
import performance_attribution from './json/performance_attribution.json';
import pnl_asset from './json/pnl_asset.json';
import pnl_fx_asset from './json/pnl_fx_asset.json';
import pnl_trading_asset from './json/pnl_trading_asset.json';
import pnl_holding_set from './json/pnl_holding_set.json';
import risk_asset from './json/risk_asset.json';
import risk_asset_by_asset from './json/risk_asset_by_asset.json';
import risk_asset_by_measure from './json/risk_asset_by_measure.json';
import risk_hs_by_measure from './json/risk_hs_by_measure.json';
import risk_factor from './json/risk_factor.json';
import risk_holding_set from './json/risk_holding_set.json';
import turnover from './json/turnover.json';

const datasourceConfigs: Map<Index, DatasourceConfig> = new Map<Index, DatasourceConfig>([
  [Index.allocation, allocation as DatasourceConfig],
  [Index.asset_info_undated, asset_info_undated as DatasourceConfig],
  [Index.flows_aggregated, flows_aggregated as DatasourceConfig],
  [Index.flows, flows as DatasourceConfig],
  [Index.hedges_fx, hedges_fx as DatasourceConfig],
  [Index.holding_set_aum, holding_set_aum as DatasourceConfig],
  [Index.holdingset_asset, holdingset_asset as DatasourceConfig],
  [Index.holdingset, holdingset as DatasourceConfig],
  [Index.limits, limits as DatasourceConfig],
  [Index.liquidity, liquidity as DatasourceConfig],
  [Index.metadata, metadata as DatasourceConfig],
  [Index.performance_attribution, performance_attribution as DatasourceConfig],
  [Index.performance, performance as DatasourceConfig],
  [Index.pnl_asset, pnl_asset as DatasourceConfig],
  [Index.pnl_fx_asset, pnl_fx_asset as DatasourceConfig],
  [Index.pnl_trading_asset, pnl_trading_asset as DatasourceConfig],
  [Index.pnl_holding_set, pnl_holding_set as DatasourceConfig],
  [Index.risk_asset_by_measure, risk_asset_by_measure as DatasourceConfig],
  [Index.risk_hs_by_measure, risk_hs_by_measure as DatasourceConfig],
  [Index.risk_asset, risk_asset as DatasourceConfig],
  [Index.risk_asset_by_asset, risk_asset_by_asset as DatasourceConfig],
  [Index.risk_factor, risk_factor as DatasourceConfig],
  [Index.risk_holding_set, risk_holding_set as DatasourceConfig],
  [Index.turnover, turnover as DatasourceConfig]
]);

export default datasourceConfigs;
