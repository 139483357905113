import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { ConversationLookup, Conversation } from '../../../common/types/entity/Conversation';
import EntityRef from '../../../common/types/entity/EntityRef';

export default (conversation: Conversation): Promise<Conversation> =>
  requestApi({
    service: 'collaboration',
    method: 'post',
    url: `/api/conversation`,
    data: conversation
  }).then((response: AxiosResponse<ConversationLookup & EntityRef> | undefined) => Conversation.fromLookup(response.data));
