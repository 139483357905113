import React from 'react';
import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { ApartmentOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { config } from './config';
import schema from './schema.json';
import HoldingSetTreeMapper from './HoldingSetTreeMapper';
import { EntityParametersHandler } from '@/common/types/app/EntityParametersHandler';
import { DashboardTableConfig, RawDashboardTableConfig } from '..';
import { Row, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import { FormattedMessage } from 'react-intl';
import { getRecoilState } from '@/core/RecoilExternalStatePortal';
import { holdingSets } from '@/recoil/holdingSets';
import { siteTreeEntryState, siteTreeState } from '@/modules/App/recoil/app.atoms';
import { withRouter } from 'react-router';
import { MeasureRenderTime } from '@/modules/reporting-v2/utils/MeasureRenderTime';
import { findHoldingSetById } from '@/utils/findHoldingSet';
import { LookThroughTypes } from '@/config/lookThroughTypes';
import { LimitBreachContextWrapper } from './LimitBreachContext';
import { RawColumn } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ConfigMappedRawColumn } from '@/modules/reporting-v2/utils/IndexUtils';
import { DataTablev2 } from '@/modules/reporting-v2/core/components/DataTablev2/DataTable';

class DashboardTable extends Visual {
  Loader = Loaders.Table;

  static configMapper(visualConfig: RawDashboardTableConfig) {
    const { columns, ...rest } = visualConfig;
    let group: ConfigMappedRawColumn[] = [];
    if (rest.tableVersion !== 'v2') {
      group = mapVisualConfigColumns({
        columns: ['holdingset.name'],
        defaultColumns: ['holdingset.name'],
        filters: {},
        options: {}
      } as RawColumn);
    }
    return {
      ...rest,
      columns: mapVisualConfigColumns(columns),
      group,
      ownershipDropdown: true
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as DashboardTableConfig;
  }

  getSchema() {
    return schema;
  }

  mapHoldingSets = (rows: (Row | RowGroup)[]): (Row | RowGroup)[] => {
    const holdingSetTree = getRecoilState(holdingSets);

    return HoldingSetTreeMapper.buildRowsFromTree(rows as RowGroup[], holdingSetTree);
  };

  flattenHoldingSets = (rows: (RowGroup | Row)[]): (RowGroup | Row)[] => {
    return rows.flatMap(row => {
      if ('rows' in row) return [row, ...this.flattenHoldingSets(row.rows)];
      return row;
    });
  };

  onHoldingsetSelect = (holdingSetId: number) => {
    const holdingSetTree = getRecoilState(holdingSets);
    const holdingSet = findHoldingSetById(holdingSetTree, holdingSetId);

    EntityParametersHandler.addEntityEntry(this.props.currentUser, this.props.history, {
      holdingSetId,
      lookThrough: holdingSet?.lookThroughEnabled ? LookThroughTypes.Lookthrough : LookThroughTypes.Direct
    });
  };

  getOwnershipHref(): string | undefined {
    const currentSiteTreeEntry = getRecoilState(siteTreeEntryState);
    const siteTree = getRecoilState(siteTreeState);

    const currentExperience = currentSiteTreeEntry?.path.split('/')[1];

    const ownershipEntry = siteTree?.find(entry => {
      const isSameExperience = entry.nodes[0] === currentExperience;
      const isOwnershipEntry = entry.path.includes('ownership');

      return isSameExperience && isOwnershipEntry;
    });

    return ownershipEntry?.path;
  }

  renderTitle() {
    const ownershipHref = this.getOwnershipHref();

    return (
      <span>
        {super.renderTitle()}
        {ownershipHref && (
          <Tooltip title={<FormattedMessage id={'report.dashboardTable.ownershipTree'} />}>
            <Button
              size="small"
              type="primary"
              shape="circle"
              icon={<ApartmentOutlined />}
              style={{ position: 'absolute', marginLeft: 16 }}
              className="no-print"
              href={ownershipHref}
            />
          </Tooltip>
        )}
      </span>
    );
  }

  renderBody() {
    const selectedEntity = EntityParametersHandler.retrieveParamsList(this.props.currentUser)[0];
    const filters = this.context.filters;
    let rows = this.mapHoldingSets(this.props.visual.data.rows);

    if (this.props.visual.crossFilterReceiver) {
      rows = this.flattenHoldingSets(rows);
      if (!filters) {
        rows = rows.filter(row => row.data['holdingset.holdingSetId'] === this.context.params.p?.[this.props.visual.entityOrdering[0]]?.holdingSetId);
      } else {
        rows = rows.filter(row => row.cells.length > 1);
      }
    }

    const visual = {
      ...this.props.visual,
      data: { ...this.props.visual.data, rows },
      sortable: true,
      updateSort: this.props.visual.updateSort.bind(this.props.visual)
    } as IDataTableProps['visual'];

    const isOldTable = this.props.visual.tableVersion === 'v1';

    return (
      <LimitBreachContextWrapper>
        <MeasureRenderTime id={this.props.visual.id} label={`${isOldTable ? 'OLD' : 'NEW'} TABLE: ${this.props.visual.component} / ${this.props.visual.title} `}>
          {isOldTable ? (
            <DataTable visual={visual} selectedHoldingset={selectedEntity?.holdingSetId} onRowSelect={this.onHoldingsetSelect} />
          ) : (
            <DataTablev2
              visual={visual}
              isRowSelected={row => row['holdingset.holdingSetId'] === selectedEntity?.holdingSetId}
              onRowClick={row => this.onHoldingsetSelect(row['holdingset.holdingSetId'] as number)}
            />
          )}
        </MeasureRenderTime>
      </LimitBreachContextWrapper>
    );
  }
}

export default withRouter(DashboardTable);
