import React, { SetStateAction, useCallback, useState } from 'react';
import { Field, FormContext, useFormContext } from '@/common/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Checkbox, Col, Row, Typography } from 'antd';
import { TextInput } from '@/common/components/Input/TextInput';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { useLocation } from 'react-router-dom';
import { useValidation } from '@/modules/Onboarding/hooks/useValidation';
import { FieldsProperties } from '@/modules/Onboarding/Shared/fieldsProperties';
import createAccountSesamePro from '@/api/createAccountSesamePro';

interface SignUpFormProps {
  formValues: Record<string, Primitive>;
  setFormValues: React.Dispatch<SetStateAction<Record<string, Primitive>>>;
  setAccountCreated: React.Dispatch<SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
}

export const SignUpForm: React.FunctionComponent<SignUpFormProps> = React.memo(({ formValues, setFormValues, setAccountCreated, setIsLoading }) => {
  const onChange = useCallback(
    (values, key, value) => {
      const newState = { ...values, [key]: value };

      setFormValues(newState);
    },
    [setFormValues]
  );

  const location = useLocation();
  const context = useFormContext(formValues, onChange, false, false);
  const translator = useIntl();
  const [acceptedConditions, setAcceptedConditions] = useState<boolean>(false);

  const validation = useValidation(
    [
      { name: FieldsProperties.ADMIN_FIRST_NAME, required: true },
      {
        name: FieldsProperties.ADMIN_LAST_NAME,
        required: true
      },
      {
        name: FieldsProperties.ACCESS_CODE,
        required: true
      },
      {
        name: FieldsProperties.ADMIN_EMAIL,
        required: true,
        pattern: '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
      }
    ],
    formValues
  );

  const handleCreateAccount = useCallback(async () => {
    setIsLoading(true);
    await createAccountSesamePro(formValues)
      .then(() => {
        setAccountCreated(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [setIsLoading, setAccountCreated, formValues]);

  const handleClickCheckbox = useCallback(() => {
    setAcceptedConditions(!acceptedConditions);
  }, [acceptedConditions]);

  const privacyPolicyLink = 'https://www.landytech.com/privacy-policy';

  return (
    <FormContext.Provider value={context}>
      <Typography.Title level={3}>{translator.formatMessage({ id: 'onboarding.signUp' })}</Typography.Title>
      <Typography.Text>
        {translator.formatMessage({ id: 'onboarding.haveAnAccountSignIn' })}
        <Typography.Link href={location.pathname}> {translator.formatMessage({ id: 'generic.here' })}</Typography.Link>
      </Typography.Text>
      <Row gutter={[15, 30]} wrap>
        <Col xs={24} md={12} xl={12}>
          <Field withoutColons label={translator.formatMessage({ id: 'generic.firstName' })} property={FieldsProperties.ADMIN_FIRST_NAME}>
            <TextInput placeholder={'John'} />
          </Field>
        </Col>
        <Col xs={24} md={12} xl={12}>
          <Field withoutColons label={translator.formatMessage({ id: 'generic.lastName' })} property={FieldsProperties.ADMIN_LAST_NAME}>
            <TextInput placeholder={'Smith'} />
          </Field>
        </Col>
        <Col xs={24} md={24} xl={24}>
          <Field withoutColons label={translator.formatMessage({ id: 'onboarding.accessCode' })} property={FieldsProperties.ACCESS_CODE}>
            <TextInput placeholder={translator.formatMessage({ id: 'onboarding.accessCode' })} />
          </Field>
        </Col>
        <Col xs={24} md={24} xl={24}>
          <Field withoutColons label={translator.formatMessage({ id: 'generic.email' })} property={FieldsProperties.ADMIN_EMAIL}>
            <TextInput type={'generic.email'} placeholder={'john.smith@gmail.com'} />
          </Field>
        </Col>
        <Col xs={24} md={24} xl={24}>
          <Checkbox onClick={handleClickCheckbox} style={{ marginRight: '5px' }} />
          <FormattedMessage
            id={'onboarding.signUpMessageAgreement'}
            values={{
              link: (
                <a target={'_blank'} rel="noreferrer" href={privacyPolicyLink}>
                  {translator.formatMessage({ id: 'onboarding.privacyPolicy' })}
                </a>
              )
            }}
          />
        </Col>
        <Button disabled={!validation || !acceptedConditions} onClick={handleCreateAccount}>
          {translator.formatMessage({ id: 'generic.continue' })}
        </Button>
      </Row>
    </FormContext.Provider>
  );
});
