import React from 'react';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { SortOrder } from '@/modules/reporting-v2/types/VisualEngine';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import { config } from './config';
import schema from './schema.json';
import RawTopTabConfig from './types';
import TopTabConfig from './TopTabConfig';
import { ReportingService } from '../../ReportingService';
import { DataTablev2 } from '@/modules/reporting-v2/core/components/DataTablev2/DataTable';
import { MeasureRenderTime } from '@/modules/reporting-v2/utils/MeasureRenderTime';
import { VisualError } from '@/modules/reporting-v2/utils/VisualError';

class TopTab extends Visual {
  Loader = Loaders.Table;

  getSchema() {
    return schema;
  }

  static configMapper(visualConfig: RawTopTabConfig, multiEntityFeatures?: boolean) {
    const { columns: _columns, measures, group, ...rest } = visualConfig;

    let columns = mapVisualConfigColumns(_columns);

    if (multiEntityFeatures) {
      const holdingSetNameCode = Object.values(ReportingService.quantMetas).find(meta => meta.elasticPath === 'holdingset.name')?.code!;

      const entityColumn = mapVisualConfigColumns({
        defaultColumns: [holdingSetNameCode],
        columns: [holdingSetNameCode],
        filters: {},
        options: {}
      })[0];

      columns.splice(1, 0, entityColumn);
    }

    const noMeasureSelected = measures.defaultColumns.length === 0;
    if (noMeasureSelected) {
      console.error('[TopTab][' + (rest.title ?? 'untitled') + '] You need to select 1 measure');
    }

    return {
      ...rest,
      columns,
      group: mapVisualConfigColumns(group || []),
      sortable: false,
      sort: {
        field: measures.defaultColumns[0],
        order: visualConfig.sort === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC
      },
      _collapsible: false,
      sampling: !!rest._sampling
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as TopTabConfig;
  }

  renderBody() {
    this.toggleEntityColumnDisplay();

    const visual = this.props.visual;

    if (!visual.sort?.field) {
      return <VisualError />;
    }

    if (visual.tableVersion === 'v1') {
      return (
        <MeasureRenderTime id={visual.id} label={`OLD TABLE: ${visual.component} / ${visual.title} `}>
          <DataTable visual={visual as IDataTableProps['visual']} />
        </MeasureRenderTime>
      );
    }

    return (
      <MeasureRenderTime id={visual.id} label={`NEW TABLE: ${visual.component} / ${visual.title} `}>
        <DataTablev2 visual={visual} />
      </MeasureRenderTime>
    );
  }
}

export default TopTab;
