import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Spin } from 'antd';
import queryString from 'query-string';
import { siteTreeState } from '@/modules/App/recoil/app.atoms';
import { PageTemplate } from '@/utils/retrievePageTemplate';
import { auth0lastLocationUrlKey } from '@/modules/App/containers/App/ImplicitCallback';

const AppRedirect = ({ authRedirect }) => {
  const siteTree = useRecoilValue(siteTreeState);
  const homeExperience = localStorage.getItem('homeExperience');
  const history = useHistory();

  if (authRedirect) {
    localStorage.removeItem(auth0lastLocationUrlKey);
    const authRedirectURL = new URL(authRedirect);

    history.replace({ pathname: authRedirectURL.pathname, search: authRedirectURL.search });
    return;
  }

  let route =
    siteTree &&
    ((homeExperience ? siteTree.find(({ path, view }) => path.startsWith(homeExperience) && [PageTemplate.APP_MENU_V3, PageTemplate.APP_MENU].includes(view)) : undefined) ||
      siteTree.find(({ path, view }) => isExperienceWithDashboard(path, view, true)) ||
      siteTree.find(({ path, view }) => isExperienceWithDashboard(path, view, false)) ||
      siteTree.find(
        ({ path, view }) => [PageTemplate.APP_MENU, PageTemplate.APP_MENU_V3].includes(view) && !path?.toLowerCase().includes('setting') && !path?.toLowerCase().includes('help')
      ) ||
      siteTree.find(({ view, path }) => view === PageTemplate.REDIRECT && path.toLowerCase().includes('sesame-data')) ||
      siteTree.find(({ path }) => !path.startsWith('/help')));

  if (route?.view === PageTemplate.APP_MENU_V3) {
    let firstSubPath = (route.children ?? []).find(item => item.view === PageTemplate.TOP_LEVEL_NAVIGATION_ITEM);
    if (firstSubPath && Array.isArray(firstSubPath.children) && firstSubPath.children.length > 0) {
      route = firstSubPath.children[0];
    }
  }

  const redirect = new URLSearchParams(window.location.search).get('redirect') ?? window.localStorage.getItem('redirect');

  window.localStorage.removeItem('redirect');

  if (redirect) {
    window.location.href = redirect;
    return;
  }

  const baseUrl = redirect || route ? route.path : '/';
  const params = queryString.parse(window.location.search);

  return params.return_to ? <Spin size="small" style={{ margin: 5 }} /> : <Redirect to={baseUrl} />;
};

const isExperienceWithDashboard = (path, view, needsToBeV3) => {
  const isDashboard = path && path.toLowerCase && path.toLowerCase().includes('dashboard');

  return view === (needsToBeV3 ? PageTemplate.APP_MENU_V3 : PageTemplate.APP_MENU) && isDashboard;
};

export default AppRedirect;
