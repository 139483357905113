import React from 'react';
import { Column } from '@/modules/reporting-v2/core/Column';
import { Field } from '@/modules/reporting-v2/core/Field';
import { FieldName } from '@/modules/reporting-v2/types/Field';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import Cell from './Cell';

interface IProps {
  columns: Column[];
  cells: Primitive[];
  isVisualLevelHtmlEditOnly?: boolean;
  level: number;
  data: FlattenObject;
  maxRangeValues?: Record<FieldName, number>;
  style?: { display: string };
  handleColumnSelect: (field: Field, value: Primitive) => void;
  options?: Record<string, any>;
  displayCategories: boolean;
  isLastChildRow: boolean;
  parentRowLastChildRow: number[];
  isDashboardTable: boolean;
  rowId: string;
}

const DataRow: React.FC<IProps> = React.memo(
  ({
    isVisualLevelHtmlEditOnly,
    columns,
    cells,
    level,
    style,
    data,
    maxRangeValues,
    handleColumnSelect,
    options,
    displayCategories,
    isLastChildRow,
    parentRowLastChildRow,
    isDashboardTable,
    rowId
  }) => {
    return (
      <tr style={{ '--groupLevel': level, ...style } as React.CSSProperties}>
        {columns
          .filter(col => col.isDefault)
          .map((column, index) => {
            return (
              <Cell
                rowId={rowId}
                isVisualLevelHtmlEditOnly={isVisualLevelHtmlEditOnly}
                key={column.id}
                cells={cells}
                columns={columns}
                col={column}
                idx={index}
                data={data}
                options={options}
                maxRangeValue={maxRangeValues?.[column.fieldDataPath]}
                handleColumnSelect={handleColumnSelect}
                displayCategories={displayCategories}
                level={level}
                isLastChildRow={isLastChildRow}
                parentRowLastChildRow={parentRowLastChildRow}
                isDashboardTable={isDashboardTable}
              />
            );
          })}
      </tr>
    );
  }
);

export { DataRow };
