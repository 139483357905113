import { getTheme } from '@/modules/reporting-v2/utils/theme';
import { landytechFooter } from './landytech';
import { defaultFooter } from './default';
import { sfFooter } from './sf';
import { HeaderFooterGenerator } from '@/modules/reporting-v2/types/HeaderFooter';
import { ltsFooter } from '@/modules/reporting-v2/views/footer/lts';

const themeFooters: Record<string, HeaderFooterGenerator> = {
  landytech: landytechFooter,
  sf: sfFooter
};

const getFooter: HeaderFooterGenerator = (props): string | undefined => {
  const theme = getTheme();
  let footerRenderFunction = themeFooters[theme] ?? defaultFooter;

  if (props.custom.TEMP_LTS_HEADER_FOOTER) {
    footerRenderFunction = ltsFooter;
  }

  return footerRenderFunction(props);
};

export default getFooter;
