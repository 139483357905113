import { AxiosResponse } from 'axios';
import { requestApi } from '@/core';
import { UUID } from '@/common/types/types';
import { EntityLinkType, Link } from '@/common/types/entity/Link';

export interface LinkPayload {
  type: EntityLinkType;
  entityId: UUID;
  info?: string;
  editable?: boolean;
}

export const createLink = (entityType: EntityLinkType, id: UUID, data: LinkPayload): Promise<Link> => {
  return requestApi({
    service: 'link',
    method: 'post',
    url: `/api/${entityType}/${id}/links`,
    data
  }).then((resp: AxiosResponse<Link> | undefined) => {
    return resp!.data;
  });
};
