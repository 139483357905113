import React from 'react';
import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { config } from './config';
import schema from './schema.json';
import RawAllocationTableConfig from './types';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import { MeasureRenderTime } from '@/modules/reporting-v2/utils/MeasureRenderTime';
import { DataTablev2 } from '@/modules/reporting-v2/core/components/DataTablev2/DataTable';

class AllocationTable extends Visual {
  Loader = Loaders.Table;

  static configMapper(visualConfig: RawAllocationTableConfig) {
    const { columns, group, ...rest } = visualConfig;
    return {
      ...rest,
      columns: mapVisualConfigColumns(columns),
      group: mapVisualConfigColumns(group)
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as VisualEngine;
  }

  getSchema() {
    return schema;
  }

  renderBody() {
    if (this.props.visual.tableVersion === 'v1') {
      return (
        <MeasureRenderTime id={this.props.visual.id} label={`OLD TABLE: ${this.props.visual.component} / ${this.props.visual.title} `}>
          <DataTable visual={this.props.visual as IDataTableProps['visual']} />
        </MeasureRenderTime>
      );
    }

    return (
      <MeasureRenderTime id={this.props.visual.id} label={`NEW TABLE: ${this.props.visual.component} / ${this.props.visual.title} `}>
        <DataTablev2 visual={this.props.visual} />
      </MeasureRenderTime>
    );
  }
}

export default AllocationTable;
