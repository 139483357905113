import type { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import type { IntlShape } from 'react-intl';

export default (translator: IntlShape, ini: Primitive | undefined, end: Primitive | undefined, type: string) => {
  switch (type) {
    case 'EQUALS':
      return translator.formatMessage({ id: 'generic.equalTo' }, { ini });
    case 'DIFFERENT':
      return translator.formatMessage({ id: 'generic.differentFrom' }, { ini });
    case 'GREATER':
      return translator.formatMessage({ id: 'generic.greaterThan' }, { ini });
    case 'GREATER_EQUALS':
      return translator.formatMessage({ id: 'generic.greaterThanOrEqualTo' }, { ini });
    case 'LESS':
      return translator.formatMessage({ id: 'generic.lessThan' }, { ini });
    case 'LESS_EQUALS':
      return translator.formatMessage({ id: 'generic.lessThanOrEqualTo' }, { ini });
    case 'BETWEEN':
      return translator.formatMessage({ id: 'generic.betweenAnd' }, { ini, end });
    case 'NOT_BETWEEN':
      return translator.formatMessage({ id: 'generic.notBetweenAnd' }, { ini, end });
    default:
      return undefined;
  }
};
