import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { UUID } from '@/common/types/types';
import { UserLookup } from '@/common/types/entity/UserLookup';

export default (accountId: UUID): Promise<UserLookup[]> =>
  requestApi({
    service: 'account',
    method: 'get',
    // todo: work on a paginated select box component
    url: `/api/accounts/${accountId}/users?size=10000`
  }).then((response: AxiosResponse | undefined) => response?.data.content);
