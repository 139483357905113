import { requestApi } from '@/core';

export default id => {
  let formattedId = id;

  if (id instanceof Array && id.length) {
    formattedId = id[0];
  }
  if (id instanceof Array && !id.length) {
    formattedId = undefined;
  }

  if (formattedId) {
    return requestApi({
      service: 'validation',
      url: `/api/dates/${formattedId}/checkpoints`
    })
      .then(({ data }) => data)
      .catch(() => []);
  } else {
    return Promise.resolve([]);
  }
};
