import { useCallback, useEffect, useState } from 'react';

class ObjectState {
  public lastPersisted?: Date;
  constructor(public changes: { [key: string]: any }, public lastUpdated: Date) {}

  update(property: string, value: any) {
    this.changes = Object.assign(this.changes, { [property]: value });
    this.lastUpdated = new Date();
  }

  markPersisted() {
    this.resetChanges();
    this.lastPersisted = new Date();
  }

  needToBePersisted(delay: number) {
    return this.hasChanges() && this.persistingIsDue(delay);
  }

  resetChanges() {
    this.changes = {};
  }

  private hasChanges() {
    return Object.keys(this.changes).length > 0 && (!this.lastPersisted || this.lastPersisted < this.lastUpdated);
  }

  private persistingIsDue(delay: number) {
    return new Date().getTime() - this.lastUpdated.getTime() >= delay;
  }
}

export type ObjectChangeHandler<T> = (object: T, property: string, value: any) => void;

export default function <T, R>(
  delay: number,
  persister: (object: T, changes: { [key: string]: any }) => Promise<R>,
  success: (object: R) => void,
  errorHandler: (ex: Error) => void,
  deps: any[]
): [ObjectChangeHandler<T>, boolean, boolean, Error?] {
  const [saving, setSaving] = useState(false);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<Error>();
  const [changes] = useState<Map<T, ObjectState>>(new Map());

  const persist = useCallback(
    (force = false) => {
      if (saving) {
        return;
      }
      const toPersist = Array.from(changes).filter(([object, state]) => state.needToBePersisted(force ? 0 : delay));
      if (toPersist.length > 0) {
        setSaving(true);
        Promise.all(
          toPersist.map(([object, state]) =>
            persister(object, state.changes)
              .then(result => {
                setError(undefined);
                success(result);
              })
              .then(() => state.markPersisted())
              .catch(ex => {
                state.resetChanges();
                setError(ex);
                errorHandler(ex);
                throw ex;
              })
          )
        ).finally(() => {
          setSaving(false);
          setPending(false);
        });
      }
    },
    [saving, changes, delay, persister, success, errorHandler]
  );

  useEffect(() => {
    persist(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persist, ...deps]);

  const onChange = useCallback<ObjectChangeHandler<T>>(
    (object, property, value) => {
      // accumulate attribute changes per form object
      const state = changes.get(object);
      if (!state) {
        changes.set(object, new ObjectState({ [property]: value }, new Date()));
      } else {
        state.update(property, value);
      }
      setPending(true);
    },
    [changes]
  );

  useEffect(() => {
    const id = setInterval(persist, delay);
    return () => clearInterval(id);
  }, [persist, delay]);

  return [onChange, saving, pending, error];
}
