import React from 'react';
import ContentLoader from 'react-content-loader';

const Chart: React.FC = props => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <ContentLoader viewBox="0 0 180 140" width="128" {...props}>
        <rect x="20" y="0" rx="0" ry="0" width="2" height="170" />
        <rect x="20" y="138" rx="0" ry="0" width="360" height="2" />
        <rect x="35" y="40" rx="0" ry="0" width="30" height="100" />
        <rect x="70" y="90" rx="0" ry="0" width="30" height="50" />
        <rect x="105" y="75" rx="0" ry="0" width="30" height="70" />
        <rect x="140" y="10" rx="0" ry="0" width="30" height="130" />
      </ContentLoader>
    </div>
  );
};

export default Chart;
