import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import type RawSelectBoxConfig from './types';

export const config: RawSelectBoxConfig = {
  type: 'filter',
  _groupable: false,
  _additionalFilters: {
    defaultColumns: [],
    columns: [],
    filters: {},
    options: {}
  },
  dataFilter: {
    defaultColumns: [],
    columns: [],
    filters: {},
    options: {}
  },
  _reportContext: VisualReportContext.EMITTER
};
