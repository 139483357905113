export enum FormatType {
  percentage = 'percentage',
  price = 'price',
  amountNoUnit = 'amountNoUnit',
  date = 'date',
  string = 'string',
  bool = 'bool',
  undefined = 'undefined',
  custom = 'custom',
  limits = 'limits',
  list = 'list'
}

export enum NumberFormatType {
  full_number = 'full_number',
  decimal_format = 'decimal_format',
  textual_represention = 'textual_represention',
  abbreviation = 'abbreviation'
}
