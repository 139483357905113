import deepmerge from 'deepmerge';
import getGenericFieldPath, { duplicateColumnSeparator } from '@/utils/getGenericFieldPath';
import { PopupHelper } from '@/utils/PopupHelper';
import { ReportingService } from '@/modules/reporting-v2/core/ReportingService';
import { nanoid } from 'nanoid';
import { RawColumn } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ConfigMappedRawColumn } from './IndexUtils';
import { FormatType } from '../../../common/types/elastic/FormatType';

const getName = (name: string) => {
  if (name === 'displayAs') {
    return 'type';
  }
  if (name) {
    return name;
  }
};

export default (config: RawColumn): ConfigMappedRawColumn[] => {
  return (config.columns || []).reduce<ConfigMappedRawColumn[]>((accum, field) => {
    if (!field) {
      return accum;
    }

    const isDuplicateColumn = field.includes(duplicateColumnSeparator);
    const genericFieldPath = getGenericFieldPath(field, isDuplicateColumn);

    const id = isDuplicateColumn ? field.split(duplicateColumnSeparator)[1] : nanoid(8);
    const defaultColumnIndex = config.defaultColumns.indexOf(field);

    const metas = ReportingService.metas[genericFieldPath] || {};

    const column = {
      ...PopupHelper.GetPopupMetadata(field),
      default: defaultColumnIndex !== -1,
      field: genericFieldPath,
      options: config.options?.[field],
      styling: config.styling?.[field],
      filter: config.filters?.[field]?.filter,
      conditionalCalculation: config.conditionalCalculation?.[field]?.conditionalCalculation ?? config.conditionalCalculation?.[field]?.calc, // [field]?.conditionalCalculation is temporary path, as soon as all report builder v2 configs have been migrated we will use [field]?.calc only
      isDuplicateColumn,
      initialFieldPath: field,
      id
    };

    const { options: columnOptions = {}, ...customMetas } = column;
    const [dataSourceIndex, ...fieldPath] = genericFieldPath.split('.');

    const options = Object.fromEntries(Object.entries(columnOptions).filter(([option]) => !option.startsWith('___')));

    // TO check : metas can be undefined because asset_info_undated.assets.assetSubClass turns into Asset Sub Class in columns... why?
    const newColumn: ConfigMappedRawColumn = deepmerge.all([
      metas || {},
      customMetas || {},
      options || {},
      {
        // custom formatting: located in field options, prefixed with ___
        formatting: Object.fromEntries(
          Object.entries(columnOptions || {})
            .filter(([option]) => option.startsWith('___'))
            .map(([option, value]: [string, any]) => {
              const name = option.replace('___', '');
              return [getName(name), value.defaultColumns ? value.defaultColumns[0] : value];
            })
        )
      }
    ]);

    if (options.count) {
      newColumn.formatting = {
        ...newColumn.formatting,
        type: 'string' as FormatType
      };
    }

    if (options.compounded) {
      newColumn.aggregation = {
        ...newColumn.aggregation,
        method: 'product'
      };
    }

    newColumn.isHoldingSetLevel = (newColumn.summable && ['holdingset', 'holding_set_aum'].includes(dataSourceIndex)) || /^holding_?set.*/gi.test(fieldPath.join('.'));

    return accum.concat(newColumn);
  }, []);
};
