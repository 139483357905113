import { CheckCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';

interface AccountCreatedProps {
  email: Primitive;
}

export const AccountCreated: React.FunctionComponent<AccountCreatedProps> = React.memo(({ email }) => {
  return (
    <>
      <CheckCircleOutlined
        style={{
          fontSize: '32px',
          color: '#3692ff',
          textAlign: 'left',
          marginBottom: '10px'
        }}
      />
      <Typography.Title style={{ marginBottom: '10px' }} level={2}>
        <FormattedMessage id={'onboarding.body.yourAccountHasBeenCreated'} />
      </Typography.Title>
      <Typography.Text style={{ marginBottom: '10px', display: 'block' }}>
        <FormattedMessage id={'onboarding.signup.weHaveSentAnEmailTo'} values={{ email, linebreak: <br /> }} />
      </Typography.Text>
      <Typography.Text style={{ color: 'grey' }}>
        <FormattedMessage
          id={'onboarding.signup.haveYouEnteredTheWrongEmail'}
          values={{
            linebreak: <br />,
            here: (
              <span style={{ cursor: 'pointer', color: '#3692ff' }} onClick={() => window.location.reload()}>
                <FormattedMessage id={'generic.here'} />
              </span>
            )
          }}
        />
      </Typography.Text>
    </>
  );
});
