import { requestApi } from '@/core';
import { ApiService } from 'ui-sesame-components';
import { AssetCompositionDto } from '../types';
import { AssetDtoV2 } from '../../PortfolioV2/types';

export const getAssetCompositionsBySectorByDate = async ({
  id: assetId,
  date
}: {
  id: NonNullable<AssetDtoV2['id']>;
  date: string;
}): Promise<AssetCompositionDto['assetCompositionsBySector']> => {
  return requestApi({
    url: `/api/v2/assets/${assetId}/compositions/by-sector/${date}`,
    service: ApiService.ASSET,
    method: 'GET'
  }).then(({ data }) => data);
};
