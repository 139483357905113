import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { getEmptyRawColumn } from '@/modules/reporting-v2/utils/getEmptyRawColumn';
import type RawTransposeTableConfig from './types';

export const config: RawTransposeTableConfig = {
  type: 'visual',
  columns: getEmptyRawColumn(),
  valueField: getEmptyRawColumn(),
  rowField: getEmptyRawColumn(),
  columnField: getEmptyRawColumn(),
  group: getEmptyRawColumn(),
  groupCategories: getEmptyRawColumn(),
  _collapsible: true,
  subTotal: true,
  total: true,
  _reportContext: VisualReportContext.NONE
};
