const key = '@sesame/theme';
const defaultTheme = 'default';

export const setTheme = (theme?: string) => {
  localStorage.setItem(key, theme || defaultTheme);
};

export const getTheme = (): string => {
  return localStorage.getItem(key) || defaultTheme;
};
