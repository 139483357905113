import { HistoricChartSeriesOptions } from '@/modules/reporting-v2/core/components/Highcharts/types';
import { PointOptionsObject } from 'highcharts';

export type FirstLastPoints = {
  firstPoint: number | null;
  lastPoint: number | null;
};

export const getFirstAndLastPoints = (series: HistoricChartSeriesOptions[]): FirstLastPoints => {
  let firstPoint = null;
  let lastPoint = null;

  for (const serie of series) {
    if (!serie.data?.length) continue;

    const serieFirstPoint = (serie.data[0] as PointOptionsObject).x;
    const serieFirstPointIsSmaller = serieFirstPoint && (firstPoint === null || serieFirstPoint < firstPoint);
    if (serieFirstPointIsSmaller) {
      firstPoint = serieFirstPoint;
    }

    const serieLastPoint = (serie.data[serie.data.length - 1] as PointOptionsObject).x;
    const serieLastPointIsBigger = serieLastPoint && (lastPoint === null || serieLastPoint > lastPoint);
    if (serieLastPointIsBigger) {
      lastPoint = serieLastPoint;
    }
  }

  return { firstPoint, lastPoint };
};
