import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { AvgDailyVol } from './VisualSpecificProps';
import type RawLiquidityTableConfig from './types';
import { getEmptyRawColumn } from '@/modules/reporting-v2/utils/getEmptyRawColumn';

export const config: RawLiquidityTableConfig = {
  type: 'visual',
  rows: getEmptyRawColumn(),
  categories: false,
  avgDailyVol: AvgDailyVol.thirtyDays,
  liquidateDays: [],
  participationRates: [],
  _reportContext: VisualReportContext.NONE
};
