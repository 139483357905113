import React from 'react';
import type { Row, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import type { Table, Row as RowContext } from '@tanstack/react-table';

type LevelIndicatorsProps = {
  level: number;
  isLastChildRow: boolean;
  parentRowLastChildRow: number[];
};

export const LevelIndicators: React.FC<LevelIndicatorsProps> = ({ level, isLastChildRow, parentRowLastChildRow }) => {
  const indicators = [];

  for (let index = 0; index < level; index++) {
    if (parentRowLastChildRow.includes(index + 1)) {
      continue;
    }

    indicators.push(<span key={index} style={{ '--levelIndicator': index + 1 } as React.CSSProperties} />);
  }

  return <span className={`level-indicators ${isLastChildRow ? 'last-child' : ''}`}>{indicators}</span>;
};

// To make it works with sort, we have to use table.getRowModel().
// row.getParentRow() is not updated with sort
export const getIsLastChildRow = (row: RowContext<Row | RowGroup>, table: Table<Row | RowGroup>) => {
  const tableRows = table.getRowModel().rows;
  const rowIndex = tableRows.findIndex(r => r.id === row.id);
  const nextRow = tableRows[rowIndex + 1];

  if (!nextRow) {
    return true;
  }

  return nextRow.depth !== row.depth;
};

export const getParentLastChildRow = (row: RowContext<Row | RowGroup>, table: Table<Row | RowGroup>) => {
  const result: number[] = [];

  let parent = row.getParentRow();
  while (parent) {
    if (getIsLastChildRow(parent, table)) {
      result.unshift(parent.depth);
    }
    parent = parent.getParentRow();
  }

  return result;
};
