import { RawReportConfig, ReportOrientation } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';

export const defaultReportConfig: RawReportConfig = {
  id: 'a_W',
  title: '',
  config: {
    orientation: ReportOrientation.PORTRAIT,
    filters: {
      consolidationType: true,
      holdingSet: true,
      date: true,
      endDate: false
    },
    params: {
      consolidationType: true,
      holdingSet: true,
      date: true,
      endDate: false
    },
    cover: true,
    backCover: true,
    disclaimer: true,
    applyStylesInWebview: 'inherit',
    margin: {
      top: 48,
      bottom: 48,
      left: 0,
      right: 0
    }
  },
  pages: []
};
