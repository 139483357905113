import Highcharts, { PointLabelObject } from 'highcharts';
import { Column } from '@/modules/reporting-v2/core/Column';
import type { BulletOptions, GaugeOptions } from '@/modules/reporting-v2/core/components/Highcharts/types';
import highchartColors from '@/modules/reporting-v2/core/components/Highcharts/colors.json';
import { getTheme } from '@/modules/reporting-v2/utils/theme';
import { HighChartsDataUtils } from '@/modules/reporting-v2/core/HighchartsDataUtils';
import type { StarRatingOptions } from '@/modules/reporting-v2/core/components/StarRating';
import GaugeConfig from './GaugeConfig';
import { GaugeType } from './types';
import { IContext } from '../../ReportContext';

class GaugeUtils {
  static getImprovedBands(visual: GaugeConfig, colors?: string[]) {
    let themeColors = highchartColors[getTheme() as keyof typeof highchartColors];
    if (colors?.length) {
      themeColors = colors;
    }

    return visual.bands.map((band, index) => {
      return {
        labelPosition: (band.end + band.start) / 2,
        label: band.label,
        from: band.start,
        to: band.end,
        color: themeColors[index % themeColors.length]
      };
    });
  }

  private static getSpeedGaugeOptions(visual: GaugeConfig, columns: Column[], context: IContext | undefined, colors: string[] | undefined): GaugeOptions {
    const value = visual.data.totals[columns[0].fieldDataPath];
    const improvedBands = this.getImprovedBands(visual, colors);

    return {
      chart: {
        type: 'gauge'
      },
      pane: {
        center: ['50%', '85%'],
        size: '160%',
        startAngle: -90,
        endAngle: 90,
        background: undefined
      },
      yAxis: [
        {
          min: visual.minValue,
          max: visual.maxValue,
          tickPosition: 'inside',
          tickPositions: improvedBands.map(band => band.labelPosition),
          tickColor: undefined,
          minorTicks: false,
          labels: {
            style: {
              color: visual.styles?.chart?.label?.color || 'white'
            },
            distance: -(visual.thickness / 2),
            rotation: 'auto' as any,
            formatter: function () {
              const plotBand = improvedBands.find(band => band.labelPosition === this.value);
              if (!plotBand) {
                return '';
              }
              return plotBand.label;
            } as Highcharts.AxisLabelsFormatterCallbackFunction
          },
          plotBands: improvedBands.map(band => {
            return {
              from: band.from,
              to: band.to,
              thickness: visual.thickness,
              color: band.color
            };
          })
        },
        {
          min: visual.minValue,
          max: visual.maxValue,
          tickPosition: 'inside',
          tickColor: 'transparent',
          tickPositions: [...improvedBands.map(band => band.from), visual.maxValue],
          minorTicks: false,
          labels: {
            distance: -(visual.thickness + 16 + parseInt((visual.styles?.gauge?.valueLabel?.fontSize as string) ?? '0') * 0.65),
            rotation: 'auto' as any,
            formatter: function (this) {
              return HighChartsDataUtils.formatCell(this.value, columns[0], visual.data.totals, context?.reportConfiguration?.config.numberLocale) as string;
            }
          }
        }
      ],
      tooltip: {
        enabled: false
      },
      plotOptions: {
        gauge: {
          dataLabels: {
            style: {
              textOutline: 'none'
            }
          },
          dial: {
            baseLength: visual.styles?.gauge?.dial?.baseLength ?? '0%',
            rearLength: visual.styles?.gauge?.dial?.rearLength ?? '0%',
            radius: visual.styles?.gauge?.dial?.radius ?? '50%'
          }
        }
      },
      series: [
        {
          dataLabels: {
            formatter: function (this, options) {
              return HighChartsDataUtils.formatCell(this.y, columns[0], visual.data.totals, context?.reportConfiguration?.config.numberLocale);
            },
            borderWidth: 0
          },
          data: [value],
          wrap: false
        }
      ] as Highcharts.SeriesGaugeOptions[]
    };
  }

  private static setHorizontalGaugeStyles(visual: GaugeConfig) {
    visual.styles = {
      ...visual.styles,
      chart: {
        className: 'highchart-bullet',
        borderColor: 'black',
        height: 90,
        borderRadius: 10,
        paddingLeft: 10,
        paddingRight: 10,
        ...visual.styles?.chart
      }
    };
  }

  private static getHorizontalGaugeOptions(visual: GaugeConfig, columns: Column[], context?: IContext): BulletOptions {
    const value = visual.data.totals[columns[0].fieldDataPath];
    const improvedBands = this.getImprovedBands(visual);

    this.setHorizontalGaugeStyles(visual);

    return {
      chart: {
        className: 'highchart-bullet',
        inverted: true,
        type: 'bullet',
        plotBorderColor: 'black',
        plotBorderWidth: 2,
        spacingLeft: 30,
        spacingRight: 30,
        height: 90
      },
      legend: {
        enabled: false
      },
      yAxis: {
        min: visual.minValue,
        max: visual.maxValue,
        labels: {
          allowOverlap: true,
          overflow: 'allow',
          rotation: 0
        },
        title: undefined
      },
      xAxis: {
        tickAmount: undefined,
        labels: {
          enabled: false
        },
        tickPositions: []
      },
      series: [
        {
          type: 'bullet',
          enableMouseTracking: false,
          data: [
            {
              y: value as number,
              target: value as number,
              targetOptions: {
                color: 'orange',
                width: '200%'
              },
              borderColor: 'transparent',
              color: 'transparent',
              dataLabels: {
                color: 'black',
                enabled: true,
                formatter: function (this: PointLabelObject) {
                  const band = improvedBands.find(b => {
                    return this.y && this.y > b.from && this.y < b.to;
                  });

                  const formattedValue = HighChartsDataUtils.formatCell(this.y, visual.columns[0], visual.data.totals, context?.reportConfiguration?.config.numberLocale);

                  if (this.y && band) {
                    return `<span>${formattedValue}</br>${band.label}</span>`;
                  }

                  return `${formattedValue}`;
                }
              }
            }
          ]
        }
      ]
    };
  }

  private static getStarRatingOptions(visual: GaugeConfig, columns: Column[]): StarRatingOptions {
    const value = visual.data.totals[columns[0].fieldDataPath] as number;
    const improvedBands = this.getImprovedBands(visual);
    let rating;
    let numberOfStars = 5;

    if (improvedBands.length === 0) {
      // default rating calculation (based on min/max values and proportions)
      const oneStar = (visual.maxValue - visual.minValue) / 6; // value of 1 star
      const calculatedRating = Math.floor(value / oneStar);

      rating = calculatedRating > 5 ? 5 : calculatedRating;
    } else {
      // rating calculation based on bands ranges
      numberOfStars = improvedBands.length;

      const bandIndex = improvedBands.findIndex((item, index) => {
        const isLastBand = index === numberOfStars - 1;

        // for the last band the rule is different
        if (isLastBand) {
          return value >= item.from;
        }
        return value >= item.from && value < item.to;
      });

      rating = bandIndex + 1;
    }

    return {
      chart: {
        className: 'star-rating',
        type: 'starRating',
        value: value,
        rating: rating,
        numberOfStars: numberOfStars
      }
    };
  }

  static getOptions(visual: GaugeConfig, columns: Column[], context: IContext | undefined, colors?: string[]): GaugeOptions | BulletOptions | StarRatingOptions {
    if (visual.gaugeType === GaugeType.SPEED) {
      return this.getSpeedGaugeOptions(visual, columns, context, colors);
    }

    if (visual.gaugeType === GaugeType.HORIZONTAL) {
      return this.getHorizontalGaugeOptions(visual, columns, context);
    }

    if (visual.gaugeType === GaugeType.STAR_RATING) {
      return this.getStarRatingOptions(visual, columns);
    }

    throw new Error('The type of this Gauge visual is unsupported ');
  }
}

export { GaugeUtils };
