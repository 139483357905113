import type { Column } from '@/modules/reporting-v2/core/Column';
import { useContext } from 'react';
import { DataTableContext } from '../DataTableContext';

const useFixedColumn = (column: Column): number | undefined => {
  const { columnSizes } = useContext(DataTableContext);

  if (!column.styling?.fixed) {
    return undefined;
  }

  const entries = Object.entries(columnSizes ?? {});
  let left = 0;
  for (const [key, width] of entries) {
    if (key === column.fieldDataPath) {
      return left;
    }

    left += width + 8 * 2;
  }

  return left;
};

export { useFixedColumn };
