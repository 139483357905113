import { VisualComponent } from '../types/ReportBuilderTypesUtils';

export default (component: VisualComponent) => {
  switch (component) {
    case VisualComponent.AllocationPie:
    case VisualComponent.BarChart:
    case VisualComponent.HistoricalChart:
    case VisualComponent.ScatterChart:
    case VisualComponent.TreeMap:
    case VisualComponent.Gauge:
      return true;
    default:
      return false;
  }
};
