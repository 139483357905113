import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { TableOfContentConfigMapped, ExtendedTableOfContentConfig, ListType } from './types';

export default class TableOfContentConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as TableOfContentConfigMapped));
  }

  getVisualConfigProps(config: TableOfContentConfigMapped): ExtendedTableOfContentConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      listType: config.listType
    });
  }

  setVisualConfig(config: ExtendedTableOfContentConfig) {
    super.setVisualConfig(config);
    this.listType = config.listType;
  }

  listType: ListType;
}
