import { constSelector, useRecoilValue } from 'recoil';
import { EntityLinkType, EntityLinkTypeMenu, LinkLookup } from '@/common/types/entity/Link';
import { useCallback, useMemo } from 'react';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { entityLinksSelector } from '@/modules/ERP/Common/Recoil/erp.selectors';
import { loggedUserSelector } from '@/common/auth/recoil/user.selector';
import { validatedDocumentsSelector } from '@/modules/ERP/Documents/Recoil/documents.selector';

export const useLinksData = (entityId: Primitive, type: EntityLinkType) => {
  const links = useRecoilValue(entityLinksSelector([type, entityId]));
  const user = useRecoilValue(loggedUserSelector);
  const object: Partial<Record<EntityLinkTypeMenu, LinkLookup[]>> = {};

  const documentLinksIds = useMemo(() => {
    return links
      .filter(link => link.type === EntityLinkType.DOCUMENT)
      .map(link => link.entityId)
      .join(',');
  }, [links]);

  const validatedDocuments = useRecoilValue(documentLinksIds ? validatedDocumentsSelector(documentLinksIds) : constSelector([]));

  const validateDocumentLink = useCallback(
    (link: LinkLookup) => {
      if (link.type === EntityLinkType.DOCUMENT) {
        return validatedDocuments.find(doc => doc.id === link.entityId);
      }

      return true;
    },
    [validatedDocuments]
  );

  const validateHoldingSetLink = useCallback(
    (link: LinkLookup) => {
      if ([EntityLinkTypeMenu.PORTFOLIO, EntityLinkTypeMenu.VEHICLE, EntityLinkTypeMenu.GROUPING].includes(link.type as unknown as EntityLinkTypeMenu)) {
        const linkId = Number(link.entityId);

        if (!user.holdingSetIds.includes(linkId)) {
          return false;
        }
      }

      return true;
    },
    [user]
  );

  const categorizedLinks = useMemo(() => {
    links.forEach(link => {
      const isAccessibleHoldingSet = validateHoldingSetLink(link);
      const isValidatedDocument = validateDocumentLink(link);

      if (!isValidatedDocument) {
        return;
      }

      if (!isAccessibleHoldingSet) {
        return;
      }

      if (link.type in object) {
        object[link.type as unknown as EntityLinkTypeMenu]!.push(link);
      } else {
        object[link.type as unknown as EntityLinkTypeMenu] = [link];
      }
    });

    return object;
  }, [links, validateHoldingSetLink, validateDocumentLink]);

  const availableCategories = useMemo<EntityLinkTypeMenu[]>(() => {
    const linksOrder = Object.values(EntityLinkTypeMenu);
    return Reflect.ownKeys(categorizedLinks).sort((a, b) => linksOrder.indexOf(a as any) - linksOrder.indexOf(b as any)) as EntityLinkTypeMenu[];
  }, [categorizedLinks]);

  const retrieveCategoryTranslationId = useCallback((type: EntityLinkTypeMenu) => {
    switch (type) {
      case EntityLinkTypeMenu.ASSET:
        return 'generic.assets';
      case EntityLinkTypeMenu.CONTACT_COMPANY:
        return 'generic.companies';
      case EntityLinkTypeMenu.CONTACT_PERSON:
        return 'generic.contacts';
      case EntityLinkTypeMenu.OWNER:
        return 'generic.owners';
      case EntityLinkTypeMenu.DOCUMENT:
        return 'generic.documents';
      case EntityLinkTypeMenu.TASK:
        return 'generic.tasks';
      case EntityLinkTypeMenu.NOTE:
        return 'generic.notes';
      case EntityLinkTypeMenu.PORTFOLIO:
        return 'generic.portfolio';
      case EntityLinkTypeMenu.VEHICLE:
        return 'generic.vehicle';
      case EntityLinkTypeMenu.GROUPING:
        return 'generic.grouping';
      case EntityLinkTypeMenu.FLOW:
        return 'generic.flow';
      default:
        throw new Error();
    }
  }, []);

  const defaultActiveKeys = useMemo(() => {
    const keys = [];
    const firstCategory = availableCategories[0];

    if (firstCategory) {
      keys.push(firstCategory);
    }

    return keys;
  }, [availableCategories]);

  return {
    defaultActiveKeys,
    categorizedLinks,
    retrieveCategoryTranslationId,
    availableCategories
  };
};
