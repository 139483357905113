import cx from 'classnames';
import { EntityParametersHandler } from '@/common/types/app/EntityParametersHandler';
import fastSort from 'fast-sort';
import React from 'react';
import datasourceConfigs from '@/modules/reporting-v2/config/datasource';
import { Column } from '@/modules/reporting-v2/core/Column';
import { EditableValue } from '@/modules/reporting-v2/core/components';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { RawColumn } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { Row } from '@/modules/reporting-v2/types/VisualEngine';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import { error } from '@/utils/error';
import { findHoldingSetById } from '@/utils/findHoldingSet';
import { ExcelUtils } from '@/utils/excel';
import { HoldingSet } from 'modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';
import { config } from './config';
import HistoricalMonthlyTabConfig from './HistoricalMonthlyTabConfig';
import schema from './schema.json';
import RawHistoricalMonthlyTabConfig from './types';
import { DataFrequency } from './VisualSpecificProps';
import { historicalCellInRange } from '@/utils/historicalCellInRange';
import NoData from '@/modules/reporting-v2/core/components/NoData';
import { createIntl, createIntlCache, FormattedMessage } from 'react-intl';
import { Field } from '@/modules/reporting-v2/core/Field';
import { HistoricalGroupedTable } from './HistoricalGroupedTable';
import { getRecoilState } from '@/core/RecoilExternalStatePortal';
import { holdingSetValidatedDatesSelector } from '@/common/components/EntitiesSelector/recoil/filters.selector';
import { HistoricalTransposeGroupedTable } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/HistoricalTransposeGroupedTable';
import { IntlShape } from 'react-intl/src/types';
import { retrieveUserLocale } from '@/utils/locale';
import messages from '@/translations/messages';
import { HistoricalTransposeNotGroupedTable } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/HistoricalTransposeNotGroupedTable';
import { quarterlyMonthsKeys } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/const/quarterlyMonthsKeys';
import { getDateValue } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/utils/getDateValue';

type CustomData = Record<string, YearData[]>;

export type YearData = {
  year: string;
  isFull?: boolean;
  months: MonthData[];
};

export type MonthData = {
  month: string;
  row: Row;
  quarter?: string;
  quarterIsFull?: boolean;
};

class HistoricalMonthlyTab extends Visual {
  Loader = Loaders.Table;
  private translator: IntlShape;

  constructor(readonly props) {
    super(props);
    const userLocale = retrieveUserLocale();
    const cache = createIntlCache();
    this.translator = createIntl({ locale: userLocale, messages: messages }, cache);
  }

  static configMapper(visualConfig: RawHistoricalMonthlyTabConfig) {
    const { metric, totalMetric, group, ...rest } = visualConfig;

    const columns = {} as RawColumn;

    columns.defaultColumns = [...new Set(metric.defaultColumns.concat(totalMetric?.defaultColumns || []))];
    columns.columns = [...new Set(metric.columns.concat(totalMetric?.columns || []))];
    columns.filters = {
      ...(metric?.filters || {}),
      ...(totalMetric?.filters || {})
    };
    columns.options = {
      ...(metric?.options || {}),
      ...(totalMetric?.options || {})
    };

    if (!columns.defaultColumns[0]) {
      throw new Error('Missing metric column in visual config');
    }

    const indexNode = new Field(columns.defaultColumns[0]).getIndexNode().getIndex();
    const dateNode = datasourceConfigs.get(indexNode)?.dateField;
    if (!dateNode) {
      throw new Error('Missing date node for index ' + indexNode);
    }
    columns.columns.unshift(dateNode);

    // const hasGroups = group.defaultColumns.length > 0;
    // if (hasGroups) {
    group.columns = [...new Set([dateNode, ...group.columns])];
    group.defaultColumns = [...new Set([dateNode, ...group.defaultColumns])];
    // }

    return {
      ...rest,
      columns: mapVisualConfigColumns(columns),
      totalMetric: totalMetric?.defaultColumns[0],
      group: mapVisualConfigColumns(group),
      sampling: rest._sampling === undefined ? true : rest._sampling,
      dateRange: rest._dateRange || rest.dateRange
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as HistoricalMonthlyTabConfig;
  }

  getSchema() {
    return schema;
  }

  retrieveEntities(): HoldingSet[] {
    const urlParams = EntityParametersHandler.retrieveParamsList(this.props.currentUser, undefined, this.props.multiEntityFeatures);
    const tree = this.props.currentUser.holdingSetTree;

    return this.props.visual.entityOrdering.map(index => findHoldingSetById(tree, urlParams[index]?.holdingSetId)).filter(hset => hset !== undefined) as HoldingSet[];
  }

  getDateFieldPath(): string | undefined {
    return datasourceConfigs.get(this.props.visual.getPrimaryIndexNode()!.getIndex())?.dateField;
  }

  appendMonthlyData(yearData: YearData[], row: Row, dateNode: string, latestValidatedDate: string | undefined): void {
    const rowDate = row.data[dateNode] as string | null | undefined;

    const everyCellsAreNull = row.cells.every(cell => cell === null || cell === undefined);
    if (everyCellsAreNull || !rowDate) {
      return;
    }

    const [year, month, day] = rowDate.split('-');
    const configDateRange = this.getConfig().dateRange as string | undefined;
    if (!historicalCellInRange(year, month, configDateRange, latestValidatedDate)) {
      return;
    }

    const yData = yearData.find(y => y.year === year);
    if (!yData) {
      yearData.push({
        year: year,
        months: [{ month, row }]
      });
      return;
    }

    const monthData = yData.months.find(mData => mData.month === month);
    if (!monthData) {
      yData.months.push({
        month,
        row
      });
      return;
    }

    const _day = (monthData.row.data[dateNode] as string).split('-')[2];
    if (day > _day) {
      monthData.row = row;
    }
    if (day === _day && typeof monthData.row.cells[0] === 'number') {
      const fieldPath = this.props.visual.columns.find(col => col.isDefault)!.fieldDataPath;

      const previousValue = monthData.row.data[fieldPath];
      const currentValue = row.data[fieldPath];
      const safePreviousValue = typeof previousValue === 'number' ? previousValue : 0;
      const safeCurrentValue = typeof currentValue === 'number' ? currentValue : 0;
      const newValue = safePreviousValue + safeCurrentValue;

      monthData.row = {
        ...monthData.row,
        cells: [newValue],
        data: { ...monthData.row.data, [fieldPath]: newValue }
      };
    }
  }

  buildMonthlyData = (entities: HoldingSet[]): CustomData => {
    const { visual } = this.props;

    const dateNode = this.getDateFieldPath();
    if (!dateNode) {
      error('Missing date node for visual ' + visual.component);
    }

    const groupedByMonthAndYear = {} as CustomData;

    for (const entity of entities) {
      const entityRows = visual.data.rowsByEntity.get(entity.id) ?? [];
      const latestValidatedDate = getRecoilState(holdingSetValidatedDatesSelector(entity.id))[0];

      const entityHasData = entityRows.length > 0;
      if (!entityHasData) {
        groupedByMonthAndYear[entity.id] = [];
        continue;
      }

      if (!groupedByMonthAndYear[entity.id]) {
        groupedByMonthAndYear[entity.id] = [];
      }

      for (const row of entityRows) {
        this.appendMonthlyData(groupedByMonthAndYear[entity.id], row, dateNode, latestValidatedDate);
      }
    }

    return groupedByMonthAndYear;
  };

  buildQuarterlyData = (monthlyData: CustomData, entities: HoldingSet[]): CustomData => {
    const quarterlyData = {} as CustomData;
    for (const entity of entities) {
      quarterlyData[entity.id] = [];

      fastSort(monthlyData[entity.id])
        .desc(y => y.year)
        .forEach(year => {
          for (const month of fastSort(year.months).desc(m => m.month)) {
            const monthIsQuarterEnd = quarterlyMonthsKeys.includes(month.month);
            if (!monthIsQuarterEnd) {
              continue;
            }

            let quarterlyDataYear = quarterlyData[entity.id].find(yData => yData.year === year.year);
            if (!quarterlyDataYear) {
              quarterlyDataYear = {
                year: year.year,
                months: [],
                isFull: year.isFull
              };
              quarterlyData[entity.id].push(quarterlyDataYear);
            }

            quarterlyDataYear.months.push({ ...month });
          }
        });
    }

    return quarterlyData;
  };

  buildYearlyData = (monthlyData: CustomData, entities: HoldingSet[]): CustomData => {
    const newYearlyData = {} as CustomData;
    for (const entity of entities) {
      newYearlyData[entity.id] = [];

      fastSort(monthlyData[entity.id])
        .desc(yd => yd.year)
        .forEach(yData => {
          const monthValues = yData.months;
          const lastMonth = fastSort(monthValues).desc(md => md.month)[0];

          newYearlyData[entity.id].push({
            year: yData.year,
            isFull: yData.isFull,
            months: [{ ...lastMonth }]
          });
        });
    }

    return newYearlyData;
  };

  checkIfYearsAreFull = (data: CustomData, entities: HoldingSet[]) => {
    for (const entity of entities) {
      data[entity.id].forEach(year => {
        const yearIsFull = year.months.some(m => m.month === '01');
        year.isFull = yearIsFull;
      });
    }
  };

  buildData = (entities: HoldingSet[]): CustomData => {
    let data = this.buildMonthlyData(entities);
    const visual = this.props.visual as HistoricalMonthlyTabConfig;

    this.checkIfYearsAreFull(data, entities);

    if (visual.dataFrequency === DataFrequency.Quarterly) {
      data = this.buildQuarterlyData(data, entities);
    }

    if (visual.dataFrequency === DataFrequency.Yearly) {
      data = this.buildYearlyData(data, entities);
    }

    return data;
  };

  getTotalMetricColumn = (): Column | undefined => {
    const visual = this.props.visual as HistoricalMonthlyTabConfig;

    const totalMetricCodeOrPath = visual.totalMetric;
    if (!totalMetricCodeOrPath) {
      return undefined;
    }

    return visual.columns.find(column => column.code === totalMetricCodeOrPath || column.fieldDataPath === totalMetricCodeOrPath);
  };

  getTranposedMonthlyTable = (dataGroupedByMonthAndYear: CustomData, visualColumns: Column[], entities: HoldingSet[]) => {
    const visual = this.props.visual as HistoricalMonthlyTabConfig;
    const multipleEntities = entities.length > 1;
    const rows = [] as {
      jsx: React.ReactNode;
      key: React.Key;
      sortData: number;
    }[];
    const dataFrequency = visual.dataFrequency;

    entities.forEach(entity => {
      fastSort(dataGroupedByMonthAndYear[entity.id])
        .desc(year => year.year)
        .forEach(year => {
          fastSort(year.months)
            .desc(month => month.month)
            .forEach(month => {
              const key = `${entity.id}${year.year}${month.month}`;
              const dateValue = getDateValue(month.month, year.year, dataFrequency);
              const TableElement = (
                <tr key={year.year + month.month}>
                  <td style={styles.paddingLeft}>
                    <EditableValue htmlEditOnly metas={{}} field={''} data={{}} key={dateValue} defaultValue={dateValue}>
                      {dataFrequency === DataFrequency.Yearly && year.isFull === false && '*'}
                      {dateValue}
                    </EditableValue>
                  </td>
                  {multipleEntities && <td>{entity.name}</td>}
                  {visualColumns.map(col => {
                    const data = month.row.data;
                    const elasticPath = col.fieldDataPath;
                    const value = data?.[elasticPath];

                    return (
                      <td key={col.id} {...ExcelUtils.getTDAttributes(col, data, value)}>
                        <EditableValue key={value as string} metas={col} defaultValue={value} field={col.fieldDataPath} data={data}>
                          {VisualEngine.formatCell(value, col, data)}
                        </EditableValue>
                      </td>
                    );
                  })}
                </tr>
              );

              rows.push({
                jsx: TableElement,
                key,
                sortData: Number(`${year.year}${month.month}`)
              });
            });
        });
    });
    return (
      <>
        <thead>
          <tr>
            <th>{dataFrequency === DataFrequency.Yearly ? <FormattedMessage id={'generic.year'} /> : <FormattedMessage id={'generic.month'} />}</th>
            {multipleEntities && (
              <th>
                <FormattedMessage id={'generic.entity'} />
              </th>
            )}
            {visualColumns.map(column => (
              <th key={column.id}>{column.headerConfig.displayName}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {fastSort(rows)
            [visual.transposedMonthOrder ?? 'desc'](row => row.sortData)
            .map(row => {
              return <React.Fragment key={row.key}>{row.jsx}</React.Fragment>;
            })}
        </tbody>
      </>
    );
  };

  isTransposedHistoricalTable = (visual: HistoricalMonthlyTabConfig) => {
    return visual.transposeMonthlyTab;
  };

  isGroupedHistoricalTable = (visual: HistoricalMonthlyTabConfig) => {
    return visual.groupByColumns.length > 1;
  };

  renderBody() {
    const visual = this.props.visual as HistoricalMonthlyTabConfig;

    if (this.isTransposedHistoricalTable(visual) && this.isGroupedHistoricalTable(visual)) {
      return <HistoricalTransposeGroupedTable visual={visual} reportLocale={this.context.reportConfiguration.config.numberLocale} />;
    }

    if (!this.isTransposedHistoricalTable(visual)) {
      return <HistoricalGroupedTable visual={visual} reportLocale={this.context.reportConfiguration.config.numberLocale} />;
    }

    const entities = this.retrieveEntities();
    const data = this.buildData(entities);
    const columns = visual.columns.filter(col => col.isDefault);

    const hasNoData = Object.keys(data).length === 0;
    if (hasNoData) {
      return <NoData />;
    }

    const isTableV2 = visual.tableVersion === 'v2';

    if (this.isTransposedHistoricalTable(visual) && isTableV2) {
      return (
        <HistoricalTransposeNotGroupedTable
          visual={this.props.visual}
          dataGroupedByMonthAndYear={data}
          entities={entities}
          reportLocale={this.context.reportConfiguration.config.numberLocale}
        />
      );
    }

    return (
      <div id={`data-table-${this.props.id}`} style={styles.overflow} className={cx('report-data-table', { transpose: this.props.transpose })}>
        <table title={this.props.title} className="zebra">
          {this.getTranposedMonthlyTable(data, columns, entities)}
        </table>
      </div>
    );
  }
}

const styles = {
  overflow: { overflowX: 'auto', overflowY: 'auto' },
  paddingLeft: { paddingLeft: 8 }
} as Record<string, React.CSSProperties>;

export default HistoricalMonthlyTab;
