import { Pagination } from '@/common/types/api/Pagination';
import { SpaceDto, SpaceFilter } from '@/common/types/entity/Space';
import requestApi from '@/core/requestApi';

export const getAccountSpaces = (accountId: string, params?: Partial<SpaceFilter>): Promise<Pagination<SpaceDto>> =>
  requestApi({
    method: 'get',
    url: `/api/accounts/${accountId}/spaces`,
    service: 'account',
    params
  }).then(response => response.data);
