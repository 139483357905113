import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedAllocationTableConfig } from '../index';
import { AllocationTableConfigMapped, CashCommitment } from './types';

export default class AllocationTableConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as AllocationTableConfigMapped));
  }

  getVisualConfigProps(config: AllocationTableConfigMapped): ExtendedAllocationTableConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      categoryHeadersEnabled: config.categoryHeadersEnabled,
      total: config.total,
      subTotal: config.subTotal,
      legendChartColor: config.legendChartColor,
      collapsible: config._collapsible,
      transpose: config.transpose,
      cashCommitment: config.cashCommitment
    });
  }

  setVisualConfig(config: ExtendedAllocationTableConfig) {
    super.setVisualConfig(config);
    this.collapsible = config.collapsible;
    this.total = config.total;
    this.subTotal = config.subTotal;
    this.legendChartColor = config.legendChartColor;
    this.categoryHeadersEnabled = config.categoryHeadersEnabled;
    this.transpose = config.transpose;
    this.sortable = true;
    this.cashCommitment = config.cashCommitment;
  }

  categoryHeadersEnabled: boolean;
  subTotal: boolean;
  legendChartColor: boolean;
  collapsible: boolean;
  transpose: boolean;
  cashCommitment: CashCommitment | undefined;
}
