import React from 'react';
import { Field } from '@/modules/reporting-v2/core/Field';
import { DataTableVisualConfig } from '@/modules/reporting-v2/types/Configs';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import DeepRows from './DeepRows';

interface ITableBodyProps {
  visual: DataTableVisualConfig;
  handleColumnSelect: (field: Field, value: Primitive) => void;
  selectedHoldingset?: number;
  onRowSelect?: (holdingSetId: number, userId: string, data: FlattenObject) => void;
  initialExpand?: boolean;
}

const TableBody: React.FC<ITableBodyProps> = React.memo(({ visual, handleColumnSelect, selectedHoldingset, onRowSelect }) => {
  return (
    <tbody>
      <DeepRows
        rowStyle={{ display: 'table-row' }}
        expand={true}
        handleColumnSelect={handleColumnSelect}
        level={0}
        visual={visual}
        childrenRows={visual.data.rows}
        maxRangeValues={visual.data.maxRangeValues}
        onRowSelect={onRowSelect}
        selectedHoldingset={selectedHoldingset}
      />
    </tbody>
  );
});

export default TableBody;
