import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import serializeWithNulls from '../../../utils/serializeWithNulls';
import { ReportPageTemplate, TransientReportPageTemplate } from '@/common/types/entity/ReportPageTemplate';

export default (id: number, report: Partial<TransientReportPageTemplate>) =>
  requestApi({
    service: 'cms',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'patch',
    url: `/api/page-templates/${id}`,
    data: serializeWithNulls(report)
  }).then((response: AxiosResponse<ReportPageTemplate> | undefined) => response?.data);
