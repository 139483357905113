import TransientDocumentTag from './TransientDocumentTag';
import { DocumentTagLookup, DocumentTagType } from '../../../common/types/entity/DocumentTags';
import { MetadataKeyDto } from '@/common/types/entity/Metadata';
import { LinkLookup } from '@/common/types/entity/Link';

export default class DocumentTag extends TransientDocumentTag implements DocumentTagLookup {
  constructor(
    public readonly id: string,
    name: string,
    type: DocumentTagType,
    allowedValues: string[] = []
  ) {
    super(name, type, allowedValues);
  }

  public equals(other?: TransientDocumentTag | DocumentTagLookup): boolean {
    if (other === undefined) {
      return false;
    }
    if (other.id) {
      return this.id === other.id;
    }
    return super.equals(other);
  }

  public isTransient = () => false;

  public copy = (name?: string) => new DocumentTag(this.id, name !== undefined ? name : this.name, this.type);

  public static from = (metadataKey: MetadataKeyDto) => {
    return new DocumentTag(metadataKey.id, metadataKey.name, metadataKey.format as DocumentTagType, metadataKey.allowedValues);
  };

  public static fromLink = (link: LinkLookup, overrideLinkType?: DocumentTagType) =>
    new DocumentTag(link.id, link.entityId, overrideLinkType ?? (link.type as any as DocumentTagType));
}
