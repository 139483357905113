import { getBranding } from '@/api';
import printPDF from '@/api/printPDF';
import FileSaver from 'file-saver';
import { useCallback, useContext, useMemo } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import qs from 'query-string';
import dayjs from 'dayjs';
import { REPORT_BUILDER_CANVAS_SIZE } from '@/modules/report-builder/hooks/useReportBuilder';
import { reportWidth } from '@/modules/report-builder/recoil/atoms';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import { reportHeightRatio } from '@/modules/reporting-v2/recoil/atoms';
import { RawReportConfig, ReportOrientation } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { waitForDOM } from '@/modules/reporting-v2/utils/getHTML';
import { ExcelUtils } from '@/utils/excel';
import { DEFAULT_REPORT_GRID_COLS } from '@/modules/report-builder/components/ReportGrid';
import type { PrintPayload } from '@/modules/reporting-v2/types/Print';
import { currentUserSelector, userState } from '@/modules/User/recoil/user.atoms';
import { loggedUserSelector, userInformationSelector } from '@/common/auth/recoil/user.selector';

type ExportType = 'pdf' | 'excel';
type RequestResponse = Promise<{
  extension: string;
  content: Blob;
}>;

export enum ReportMediaClassName {
  screen = 'report-media-screen',
  print = 'report-media-print'
}

// https://www.papersizes.org/a-sizes-in-pixels.htm
export const a4PaperSize = {
  [ReportOrientation.LANDSCAPE]: 1123,
  [ReportOrientation.PORTRAIT]: 793
};

export const usePreparePdf = (): ((reportConfig?: RawReportConfig) => Promise<PrintPayload>) => {
  const { reportId, orientation, getHTML, print, setExporting } = useContext(ReportContext);
  const [currentHeightRatio, setHeightRatio] = useRecoilState(reportHeightRatio);
  const [currentWidth, setWidth] = useRecoilState(reportWidth);

  return (reportConfig?: RawReportConfig) =>
    new Promise(async (resolve: (content: any) => void) => {
      setExporting?.('pdf');
      const canvas = REPORT_BUILDER_CANVAS_SIZE[orientation!];
      setHeightRatio(canvas.ratio);
      setWidth(a4PaperSize[orientation!]);

      await waitForDOM();

      const reportContainer = document.querySelector<HTMLElement>(`#report-${reportId}`)!;
      reportContainer.classList.add(ReportMediaClassName.print);
      reportContainer.classList.remove(ReportMediaClassName.screen);

      // auto-width for table wrapper
      Array.from(reportContainer.querySelectorAll<HTMLElement>(".report-data-table, [data-component='CallOut']")).forEach(element => {
        element.closest('.report-visual')?.closest('td')?.classList.add('auto-width');
      });
      Array.from(reportContainer.querySelectorAll<HTMLElement>(`.report-wrapper-table > tbody > tr > td[colspan="${DEFAULT_REPORT_GRID_COLS}"]:not(.auto-width)`)).forEach(td => {
        td.classList.add('auto-width');
      });

      await waitForDOM();

      const maxPageWidth = Math.max(reportContainer.offsetWidth, a4PaperSize[orientation!]);
      if (maxPageWidth > a4PaperSize[orientation!]) {
        setWidth(maxPageWidth);
        await waitForDOM();
      }

      reportContainer.style.minWidth = maxPageWidth + 'px';

      await getHTML!(reportConfig)
        .then(content => {
          const printPayload = print!(content);

          reportContainer.classList.add(ReportMediaClassName.screen);
          reportContainer.classList.remove(ReportMediaClassName.print);

          reportContainer.style.minWidth = '0px';

          setHeightRatio(currentHeightRatio);
          setWidth(currentWidth);
          setExporting?.(false);

          return printPayload;
        })
        .then(resolve)
        .finally(() => {
          setExporting?.(false);
        });
    });
};

const useExport = (title: string, fileName: string, data: RawReportConfig | undefined, setIndicator: (status: boolean) => void): { handleDownload: (type: ExportType) => void } => {
  const currentUser = useRecoilValue(currentUserSelector);
  const userInformation = useRecoilValue(userInformationSelector(currentUser.accountId));
  const logoSrc = useMemo(() => currentUser.company.branding.logoLargeUrl, [currentUser]);

  const user = useRecoilValue(loggedUserSelector);
  const context = useContext(ReportContext);
  const preparePdf = usePreparePdf();

  const setBranding = useRecoilCallback(
    ({ set }) =>
      (branding: any) => {
        set(userState, prevUser => {
          return {
            ...prevUser,
            currentUser: {
              ...prevUser.currentUser,
              company: { ...prevUser.currentUser?.company, branding }
            }
          };
        });
      },
    []
  );

  const handleDownload = useCallback(
    (type: ExportType) => {
      const request = (): RequestResponse | undefined => {
        switch (type) {
          case 'excel': {
            let userName = currentUser.login;
            if (userInformation) {
              userName = `${userInformation.firstName} ${userInformation.lastName}`;
            }
            // we need to be sure that title is safe and doesn't have extra spaces,
            // otherwise exported Excel file will contain an error
            const safeTitle = title.trim();
            const safeReportConfig = data?.title ? { ...data, title: data.title.trim() } : data;

            return ExcelUtils.exportFromVisuals(context.getExcel!(context), ExcelUtils.getCover(currentUser, userName, safeTitle), safeReportConfig).then((file: Blob) => ({
              extension: 'xlsx',
              content: file
            }));
          }
          case 'pdf': {
            const downloadPdf = () =>
              preparePdf(data).then(content =>
                printPDF(content).then(pdf => {
                  return {
                    extension: 'pdf',
                    content: new Blob([pdf], { type: 'application/pdf' })
                  };
                })
              );

            if (logoSrc && dayjs().isAfter(qs.parse(logoSrc).se)) {
              return getBranding(user.accountId).then(setBranding).then(downloadPdf);
            }

            return downloadPdf();
          }
          default:
        }
      };

      setIndicator(true);
      request()
        ?.then(({ content, extension }) => {
          FileSaver.saveAs(content, `${fileName}.${extension}`, {
            autoBom: false
          });
          setIndicator(false);
        })
        .finally(() => {
          context.setExporting?.(false);
        });
    },
    [setIndicator, currentUser, userInformation, context, title, data, logoSrc, preparePdf, user.accountId, setBranding, fileName]
  );

  return { handleDownload };
};

export default useExport;
