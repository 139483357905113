import dayjs from 'dayjs';
import { findHoldingSetById } from '@/utils/findHoldingSet';
import { MergedReportBrandingStyles } from '@/modules/reporting-v2/types/HeaderFooter';
import { rbColorToHexAlpha } from '@/utils/toHexAlpha';
import { parseFontStyle } from '@/modules/Styling/utils/parseStyles';
import type { IParams } from '@/common/types/app/Params';
import type { BuilderType } from '@/modules/report-builder/types';
import type { SesameUser } from '@/common/types/SesameUser';
import { FooterLogoPositionType } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { DATE_FORMAT } from 'ui-sesame-components';

const ratio = 0.8;

export const getPaginationContent = (style: MergedReportBrandingStyles, paginationAlign: string, paginationEnabled: boolean, pageType?: BuilderType, showTotalPages?: boolean) => {
  if (!paginationEnabled || style.pagination?.hide || pageType === 'DISCLAIMER') {
    return '<td class="pagination-content"></td>';
  }

  const showPaginationInFooter = !style.paginationPosition || style.paginationPosition === 'footer';
  if (!showPaginationInFooter) {
    return '<td class="pagination-content"></td>';
  }

  const paginationFont = style.pagination?.fontStyle;
  const paginationFontColor = rbColorToHexAlpha(style.pagination?.fontColor) || 'black';
  const paginationFontStyle = parseFontStyle(paginationFont);
  const paginationFontSize = paginationFont?.fontSize ? paginationFont.fontSize * ratio + 'px' : 'inherit';

  return `<td
    width="15%"
    class="pagination-content"
    style="
      text-align:${paginationAlign};
      font-family: ${paginationFont?.fontFamily || 'inherit'};
      font-weight: ${paginationFontStyle.fontWeight || 'normal'};
      font-style: ${paginationFontStyle.fontStyle || 'normal'};
      color: ${paginationFontColor};
      font-size: ${paginationFontSize};"
  >
    <span class="pageNumber"></span>${showTotalPages !== false ? ' / <span class="totalPages"></span>' : ''}
  </td>`;
};

export const getTitleOrCustomText = (style: MergedReportBrandingStyles, title: string | undefined, displayTitle: boolean) => {
  if (style.footer?.customText) {
    return style.footer.customText;
  }

  if (style.footerCustomText) {
    return style.footerCustomText;
  }

  if (displayTitle) {
    return title;
  }

  return undefined;
};

export const getStyleValueWithInheritance = (reportLevelValue: 'inherit' | any, brandingLevelValue: any) => {
  if (!reportLevelValue || reportLevelValue === 'inherit') {
    return brandingLevelValue;
  }

  return reportLevelValue === 'true';
};

export const getEntityContent = (style: MergedReportBrandingStyles, entityParam: IParams | undefined, user: SesameUser) => {
  if (!entityParam?.p) {
    return [];
  }

  const showEntity = getStyleValueWithInheritance(style.footer?.showEntity, style.footerEntity);
  const showDate = getStyleValueWithInheritance(style.footer?.showDate, style.footerDate);
  const showCurrency = getStyleValueWithInheritance(style.footer?.showCurrency, style.footerCurrency);
  if (!showEntity && !showDate && !showCurrency) {
    return [];
  }

  const content = [];

  for (const entity of entityParam.p) {
    const entityContent = [];
    const hsId = entity.holdingSetId;
    const hs = findHoldingSetById(user.holdingSetTree, hsId);

    if (hs?.name && showEntity) {
      entityContent.push(hs.name);
    }

    if (hs?.name && showDate) {
      const formattedDate = dayjs(entity.date, DATE_FORMAT.DATE).format(style.footer?.dateFormat ?? DATE_FORMAT.DATE);
      entityContent.push(formattedDate);
    }

    if (hs?.reportingCurrency && showCurrency) {
      entityContent.push(hs.reportingCurrency);
    }

    if (entityContent.length > 0) {
      content.push(entityContent.join(' - '));
    }
  }

  return content;
};

export const getMainContent = (
  title: string | undefined,
  style: MergedReportBrandingStyles,
  showFooterTitle: boolean,
  paginationAlign: string,
  entityParam: IParams | undefined,
  user: SesameUser
) => {
  const displayTitle = showFooterTitle && !style.footer?.hideReportTitle;

  const content = [getTitleOrCustomText(style, title, displayTitle), ...getEntityContent(style, entityParam, user)].filter(item => !!item);

  const contentAlign = paginationAlign === 'left' ? 'right' : 'left';
  return `<td class="main-content" width="85%" style="text-align:${contentAlign};"><span class="footer-title">${content.join(' | ')}</span></td>`;
};

export const getFooterContent = (titleContent: string, paginationContent: string, paginationAlign: string) => {
  const paginationIsLeft = paginationContent && paginationAlign === 'left';
  if (paginationIsLeft) {
    return paginationContent + titleContent;
  }

  return titleContent + paginationContent;
};

export const getFontStyles = (style: MergedReportBrandingStyles) => {
  const mergedFontStyles = {
    ...style.footerFontStyle,
    ...style.footer?.fontStyle
  };
  const mergedFontColor = {
    ...style.footerFontColor,
    ...style.footer?.fontColor
  };
  const fontStyle = parseFontStyle(mergedFontStyles);

  return `
    font-family: ${mergedFontStyles.fontFamily}, Lato, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: ${(mergedFontStyles.fontSize || 12) * ratio}px;
    font-weight: ${fontStyle.fontWeight || 'normal'};
    font-style: ${fontStyle.fontStyle || 'normal'};
    color: ${rbColorToHexAlpha(mergedFontColor) || '#333333'};
  `;
};

export const getFooterLogoPosition = (
  reportLevelValue: 'inherit' | FooterLogoPositionType | undefined,
  brandingLevelValue: FooterLogoPositionType | undefined
): FooterLogoPositionType | undefined => {
  if (reportLevelValue === 'inherit' || !reportLevelValue) {
    return brandingLevelValue;
  }
  return reportLevelValue;
};

export const calculateWidth = (logoPosition, logoWidth) => {
  const PAGINATION_DEFAULT_WIDTH = 10;
  if (logoPosition === 'center') {
    return {
      contentWidth: (100 - logoWidth) / 2,
      paginationWidth: (100 - logoWidth) / 2
    };
  }
  return {
    contentWidth: 100 - PAGINATION_DEFAULT_WIDTH - logoWidth,
    paginationWidth: PAGINATION_DEFAULT_WIDTH
  };
};
