import { selector, selectorFamily } from 'recoil';
import getUserHoldingSets from '@/api/getUserHoldingSets';
import { currencyPortfolioByFilterState, custodianByFilterState, legalEntityFilterState, portfolioFilterState } from '@/modules/ERP/Portfolio/data.atom';
import { ListItem } from '@/common/components/SearchOverlay';
import { getEntities } from '@/modules/Onboarding/api/getEntities';
import { EntityList, EntityType } from '@/modules/Onboarding/Shared/types';
import { getCustodiansByIds } from '@/modules/ERP/Common/Api/getCustodianByIds';
import { HoldingSetType } from '@/common/types/entity/HoldingSetType';
import { Institution } from '@/modules/ERP/Common/types';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { HoldingSet } from '@/modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';
import { filterNonEmptyValues } from '@/utils/filterValues';

export type HoldingSetWithCustodian = HoldingSet & { custodian?: Institution };

export const allExistingEntitiesSelector = selectorFamily<EntityList[], Record<string, Primitive>>({
  key: 'all-existing-entities-selector',
  get: params => async () => {
    const { type, ...rest } = params;

    return (await getEntities(rest, type as EntityType)).content;
  }
});

export const userHsetsSelector = selector<HoldingSet[]>({
  key: 'user-hsets-selector',
  get: async () => {
    return await getUserHoldingSets();
  }
});

export const flattenedUserHsets = selector<HoldingSet[]>({
  key: 'user-hsets-flattened-selector',
  get: ({ get }) => {
    const hsets = get(userHsetsSelector);

    const hsetsCopy = [...hsets];

    const entitiesFlattened = [];

    while (hsetsCopy.length) {
      const currentEntity = hsetsCopy.pop()!;

      if (currentEntity.children?.length > 0) {
        hsetsCopy.push(...currentEntity.children);
      }

      entitiesFlattened.push(currentEntity);
    }

    return entitiesFlattened;
  }
});

export const flattenedUserHsetsById = selectorFamily<HoldingSet | undefined, number | undefined>({
  key: 'user-hsets-flattened-by-id-selector',
  get:
    id =>
    ({ get }) => {
      const hsets = get(flattenedUserHsets);
      return hsets.find(entity => entity.id === id);
    }
});

export const flattenedUserHsetsByType = selectorFamily<HoldingSet[], HoldingSetType | undefined>({
  key: 'user-hsets-flattened-by-type-selector',
  get:
    type =>
    ({ get }) => {
      const hsets = get(flattenedUserHsets);

      if (!type) {
        return hsets;
      }

      return hsets.filter(entity => entity.type === type);
    }
});

export const custodianByIdsSelector = selectorFamily<Institution[], number[]>({
  key: 'custodian-by-ids-selector',
  get: ids => async () => {
    return await getCustodiansByIds(ids);
  }
});

export const flattenedUserHsetsWithCustodian = selector<HoldingSetWithCustodian[]>({
  key: 'flattened-user-hsets-with-custodian-selector',
  get: async ({ get }) => {
    const hsets = get(flattenedUserHsets);
    const allHsets = get(allExistingEntitiesSelector({ size: 1e4 }));

    const custodianIds = Array.from(new Set(filterNonEmptyValues(allHsets.map(({ custodianId }) => custodianId))));
    const custodians = get(custodianByIdsSelector(custodianIds));

    return hsets.map(entity => {
      const entityCustodianId = allHsets.find(item => item.holdingSetId === entity.id)?.custodianId;

      if (!entityCustodianId) {
        return entity;
      }

      const custodian = custodians.find(it => it.id === entityCustodianId);

      if (!custodian) {
        return entity;
      }

      return { ...entity, custodian };
    }) as HoldingSetWithCustodian[];
  }
});

export const flattenedUserHsetsWithCustodianByType = selectorFamily<HoldingSetWithCustodian[], HoldingSetType | undefined>({
  key: 'flattened-user-hsets-with-custodian-by-type-selector',
  get:
    type =>
    ({ get }) => {
      const hsetsWithCustodian = get(flattenedUserHsetsWithCustodian);

      if (!type) {
        return hsetsWithCustodian;
      }

      return hsetsWithCustodian.filter(entity => entity.type === type);
    }
});

export const holdingSets = selector<HoldingSet[]>({
  key: 'holdingSets',
  get: async ({ get }) => {
    return get(userHsetsSelector); // TODO: merge with userHsetsSelector
  }
});

export const holdingSetsMap = selector<Map<number, HoldingSet>>({
  key: 'holdingSetsMap',
  get: ({ get }) => {
    const entityFlattener = (_entities: HoldingSet[]): HoldingSet[] => {
      return _entities.flatMap(entity => {
        if (entity.children.length) {
          return [entity, ...entityFlattener(entity.children)];
        }

        return entity;
      });
    };

    const entities = get(holdingSets); // don't replace with userHsetsSelector if holdingSets selector is not completely removed
    const flatEntities = entityFlattener(entities);

    return new Map(flatEntities.map(hs => [hs.id, hs]));
  }
});

export const userEntitiesWithCustodian = selectorFamily<HoldingSetWithCustodian[], HoldingSetType | undefined>({
  key: 'entitiesWithCustodian',
  get:
    type =>
    ({ get }) => {
      return get(flattenedUserHsetsWithCustodianByType(type));
    }
});

export const userEntityWithCustodianById = selectorFamily<HoldingSetWithCustodian | undefined, [number, HoldingSetType | undefined]>({
  key: 'userEntityWithCustodianById',
  get:
    ([id, type]) =>
    async ({ get }) => {
      const entities = get(flattenedUserHsetsWithCustodianByType(type));

      return entities.find((entity: HoldingSetWithCustodian) => entity.id === id);
    }
});

export const userEntitiesWithCustodianTable = selectorFamily<HoldingSetWithCustodian[], HoldingSetType>({
  key: 'userEntitiesWithCustodianTable',
  get:
    type =>
    async ({ get }) => {
      let portfoliosFiltered = get(flattenedUserHsetsWithCustodianByType(type));

      const portfolioCustodianFilter = get(custodianByFilterState);
      const portfolioCurrencyFilter = get(currencyPortfolioByFilterState);
      const filter = get(portfolioFilterState);
      const filterLegalEntity = get(legalEntityFilterState);

      const filterPortfolio = (filter: string, filterPortfolio: string) => {
        return filterPortfolio?.replace(/ /g, String()).toLowerCase().trim().includes(filter.toLowerCase().replace(/ /g, String()));
      };

      if (portfolioCurrencyFilter) {
        portfoliosFiltered = portfoliosFiltered.filter(it => !!it.reportingCurrency).filter(it => filterPortfolio(portfolioCurrencyFilter, it.reportingCurrency));
      }

      if (portfolioCustodianFilter) {
        portfoliosFiltered = portfoliosFiltered
          .filter(it => !!it.custodian?.name)
          .filter(it => filterPortfolio(portfolioCustodianFilter, it.custodian?.name ? it.custodian?.name : ''));
      }

      if (filterLegalEntity) {
        portfoliosFiltered = portfoliosFiltered.filter(it => filterPortfolio(filterLegalEntity, it.name));
      }

      if (filter) {
        portfoliosFiltered = portfoliosFiltered.filter(it => filterPortfolio(filter, it.name));
      }

      return portfoliosFiltered;
    }
});

export const holdingSetsAsLabelSelector = selectorFamily<ListItem[], HoldingSetType | undefined>({
  key: 'holdingSetsAsLabelSelector',
  get:
    type =>
    ({ get }) => {
      const source = get(userEntitiesWithCustodian(type));
      return Array.from(source).map(it => ({
        id: String(it.id),
        label: it.name
      }));
    }
});

export const holdingSetSelector = selectorFamily<HoldingSet | undefined, number | undefined>({
  key: 'single-holding-set-selector',
  get:
    id =>
    ({ get }) => {
      return Number.isInteger(id) ? get(holdingSetsMap).get(id!) : undefined;
    }
});

export const holdingSetDetailsAsLabelSelector = selectorFamily<ListItem, number>({
  key: 'single-holding-set-details-as-label-selector',
  get:
    id =>
    ({ get }) => {
      const holdingSet = get(userEntityWithCustodianById([id, undefined]))!;
      return {
        id: String(holdingSet.id),
        label: holdingSet.name
      };
    }
});
