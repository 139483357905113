import type RawDrilldownTableConfig from './types';

export const config: RawDrilldownTableConfig = {
  type: 'visual',
  columns: {
    columns: [],
    defaultColumns: [],
    options: {},
    filters: {}
  },
  _collapsible: false,
  sortable: true,
  total: false
};
