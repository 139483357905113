import React, { memo } from 'react';
import { FileAddOutlined } from '@ant-design/icons';
import { QuantCatalogueItemLookup } from '@/common/types/entity/QuantCatalogueItem';
import { useRecoilCallback } from 'recoil';
import { QuantManagementEditMode, selectedMetricState } from '../quant-management.atoms';
import { ElasticColumnMetas } from '../QuantLibrary';

interface QuantMetricCreationButtonProps {
  record: ElasticColumnMetas | QuantCatalogueItemLookup;
}

const QuantMetricCreationButton: React.FC<QuantMetricCreationButtonProps> = memo(({ record }) => {
  const onClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(selectedMetricState, [record, QuantManagementEditMode.ELASTIC_MAPPING]);
      },
    [record]
  );

  return <FileAddOutlined onClick={onClick} style={{ fontSize: 20 }} />;
});

export { QuantMetricCreationButton };
