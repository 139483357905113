import { AxisScale, BarChartValueType, Orientation } from './VisualSpecificProps';
import { Column } from '@/modules/reporting-v2/core/Column';
import { EntityParametersHandler } from '@/common/types/app/EntityParametersHandler';
import { findHoldingSetById } from '@/utils/findHoldingSet';
import { HighChartsDataUtils } from '@/modules/reporting-v2/core/HighchartsDataUtils';
import { HoldingSet } from 'modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';
import { Chart, PointOptionsObject } from 'highcharts';
import { SortOrder } from '@/modules/reporting-v2/types/VisualEngine';
import BarChartConfig from './BarchartConfig';
import deepmerge from 'deepmerge';
import type { BarChartOptions, BarChartSeriesOptions, ExtendedRowGroup } from '@/modules/reporting-v2/core/components/Highcharts/types';
import type Highcharts from 'highcharts/highstock';
import { FormatType } from '@/common/types/elastic/FormatType';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import { getRecoilState } from '@/core/RecoilExternalStatePortal';
import { IContext } from '../../ReportContext';

class BarChartUtils {
  private static retrieveEntities(visual: BarChartConfig, multiEntityFeatures?: boolean) {
    const currentUser = getRecoilState(currentUserSelector);
    const tree = currentUser.holdingSetTree;
    const urlParams = EntityParametersHandler.retrieveParamsList(currentUser, undefined, multiEntityFeatures);

    return visual.entityOrdering.map(index => findHoldingSetById(tree, urlParams[index]?.holdingSetId)!).filter(Boolean);
  }

  private static setColorFormatting = function (chart: Chart) {
    const seriesWithColorFormatting = chart.series.filter(serie => serie.userOptions.custom?.colorFormatting);

    if (!seriesWithColorFormatting.length) {
      return;
    }

    for (const serie of seriesWithColorFormatting) {
      if (!serie.data.length) {
        continue;
      }

      for (const data of serie.data) {
        if (data.y && data.y > 0) {
          (data as any).graphic.css({
            color: '#008B02'
          });
        } else {
          (data as any).graphic.css({
            color: '#B80000'
          });
        }
      }
    }
  };

  static getMaxValue(series: BarChartSeriesOptions[]): number | undefined {
    let maxValue: number | undefined;
    const data = series[0]?.data;

    if (!data) {
      return;
    }

    (data as PointOptionsObject[]).forEach(dataItem => {
      if (!dataItem.y) {
        return;
      }
      if (!maxValue) {
        maxValue = dataItem.y;
        return;
      }
      if (dataItem.y > maxValue) {
        maxValue = dataItem.y;
      }
    });

    return maxValue;
  }

  static getFormattedValue(
    value: string | number,
    maxValue: number,
    valueType: BarChartValueType,
    showPlusBeforePercentValue: boolean,
    formatter: (value?: number | string) => string
  ): string {
    const percentValue = Math.round((Number(value) / maxValue) * 100);
    const shouldShowPlus = showPlusBeforePercentValue && value >= 0;

    if (valueType === BarChartValueType.Both) {
      return `${formatter(value)} | ${shouldShowPlus ? '+' : ''}${percentValue}%`;
    }
    if (valueType === BarChartValueType.Percent) {
      return `${shouldShowPlus ? '+' : ''}${percentValue}%`;
    }
    return String(value);
  }

  static getOptions(visual: BarChartConfig, columns: Column[], rows: ExtendedRowGroup[], multiEntityFeatures: boolean | undefined, context: IContext): BarChartOptions {
    const generateSerie = (column: Column, index: number, type: 'bar' | 'column', deepGroup: boolean, rows: ExtendedRowGroup[], entity: HoldingSet, showEntityName: boolean) => {
      const columnHasColorFormatting = !!column.formatting?.color;

      if (deepGroup) {
        const groups = [...new Set(rows.flatMap(row => row.group as string))];

        return groups.flatMap(group => {
          const groupRows = rows.filter(row => row.group === group);
          const groupColors = [...new Set(groupRows.flatMap(row => row.color))];
          const groupColor = groupColors.length === 1 ? groupColors[0] : undefined;

          return {
            type,
            custom: {
              colorFormatting: columnHasColorFormatting
            },
            color: groupColor,
            name: showEntityName ? `${entity.name} - ${group}` : group,
            data: groupRows.flatMap(row => {
              return {
                color: row.color,
                y: row.cells[index] as number,
                name: row.parent?.group as string,
                custom: { column, data: row.data }
              };
            })
          };
        });
      } else {
        let data: { y: number; name?: string; custom: any }[];

        if (visual.mergeSeriesData) {
          data = [{ y: 0, name: '', custom: { column: column, data: rows[0].data } }];
          rows.forEach(row => {
            data[0].y += (row.cells[index] as number | undefined) ?? 0;
          });
        } else {
          data = rows.map(row => {
            return {
              color: row.color,
              y: row.cells[index] as number,
              name: row.group as string,
              custom: { column, data: row.data }
            };
          });
        }

        return {
          type,
          custom: {
            colorFormatting: columnHasColorFormatting
          },
          name: showEntityName ? `${entity.name} - ${column.headerConfig.displayName}` : column.headerConfig.displayName,
          data
        };
      }
    };

    const sortSeriesByBar = () => {
      const valuesAreStacked = series.every(seriesItem => seriesItem.data?.length === 1);
      if (valuesAreStacked) {
        const newSeries: BarChartSeriesOptions[] = [];
        visual.sortByBar?.forEach(group => {
          const seriesItem = series.find(s => s.name === group);
          if (seriesItem) {
            newSeries.push(seriesItem);
          }
        });

        for (const s of series) {
          const seriesItemAlreadyAdded = newSeries.some(ns => ns.name === s.name);
          if (!seriesItemAlreadyAdded) {
            newSeries.push(s);
          }
        }
        return newSeries;
      }

      return series.map(seriesItem => {
        const data = seriesItem.data as PointOptionsObject[];
        return {
          ...seriesItem,
          data: visual
            .sortByBar!.flatMap(name => {
              const barIndex = data.findIndex(item => item.name?.toString().toLocaleLowerCase() === name?.toString().toLocaleLowerCase());
              if (barIndex !== -1) {
                return data.splice(barIndex, 1);
              }
              return [];
            })
            .concat(data)
        };
      });
    };

    function formatter(value?: number | string): string {
      // This is the axis of the chart. Needs global configuration. Needs to use the first column.
      const compactColumnFormat = deepmerge(columns[0], {
        formatting: {
          ...columns[0].formatting,
          compact: true,
          forceDecimals: true
        }
      });
      return HighChartsDataUtils.formatCell(value, compactColumnFormat, rows[0].data, context.reportConfiguration?.config.numberLocale) as string;
    }

    function pointBreakColumn(this: Highcharts.Axis, e: any) {
      const point = e.point,
        brk = e.brk,
        shapeArgs = point.shapeArgs,
        x = shapeArgs.x,
        y = (this as any).translate(brk.from, 0, 1, 0, 1),
        w = shapeArgs.width,
        key = ['brk', brk.from, brk.to],
        path = ['M', x, y, 'L', x + w * 0.25, y + 4, 'L', x + w * 0.75, y - 4, 'L', x + w, y];

      if (!point[key.toString()]) {
        point[key.toString()] = this.chart.renderer
          .path(path)
          .attr({
            'stroke-width': 2,
            stroke: point.series.options.borderColor
          })
          .add(point.graphic.parentGroup);
      } else {
        point[key.toString()].attr({
          d: path
        });
      }
    }

    const getBreaksPoints = (series: BarChartSeriesOptions[]) => {
      let data: number[] = [];
      if (series.length === 1) data = series[0].data?.map(d => (d as PointOptionsObject)?.y || 0) || [];

      if (visual.sort?.order === SortOrder.DESC) {
        data.reverse();
      } else if (visual.sort?.order !== SortOrder.ASC) {
        data.sort((a, b) => a - b);
      }

      const max = data[data.length - 1];
      const twentyPercentValue = max * 0.2;
      const breaks: Highcharts.YAxisBreaksOptions[] = [];

      for (let i = 0; i < data.length - 1; i++) {
        const value = data[i];
        const nextValue = data[i + 1];
        if (nextValue - value > twentyPercentValue) {
          breaks.push({
            from: value + max / 33,
            to: nextValue - max / 33,
            breakSize: max / 50
          });
        }
      }

      return breaks;
    };

    const groupSeries = (series: BarChartSeriesOptions[]) => {
      if (series.length > 1) {
        return series;
      }

      const serie = series[0];
      if (!serie.data) {
        return series;
      }

      const dataToShow = serie.data?.splice(0, visual.showTopSeries);
      const tempValues = {
        ...(serie.data as PointOptionsObject[])[0],
        name: 'Others',
        id: 'Others',
        y: 0
      };
      for (const d of serie.data as PointOptionsObject[]) {
        tempValues.y += d?.y ?? 0;
      }
      tempValues.custom!.data[tempValues.custom!.column.fieldDataPath] = tempValues.y;

      serie.data = [...dataToShow, tempValues];

      return [serie];
    };

    const getAxisScaleOptions = (axisScale: AxisScale): Highcharts.YAxisOptions => {
      switch (axisScale) {
        case AxisScale.Break: {
          const breakPoints = getBreaksPoints(series);
          return {
            endOnTick: false,
            lineWidth: 2,
            breaks: breakPoints,
            tickInterval: 0.01,
            plotLines: breakPoints.map(breakPoint => ({
              value: breakPoint.from,
              label: {
                text: `${formatter(breakPoint.from)} - ${formatter(breakPoint.to)}`
              },
              color: '#ccd6eb',
              width: 2
            })),
            events: {
              pointBreak: pointBreakColumn
            }
          };
        }
        case AxisScale.Logarithmic:
          return { type: 'logarithmic' };
        default:
          return {};
      }
    };

    const type = visual.orientation === Orientation.BarChart ? 'bar' : 'column';
    const deepGroup = visual.groupByColumns.filter(group => group.isDefault).length > 1;
    const hasColorFormatting = columns.some(column => !!column.formatting?.color);
    const entities = this.retrieveEntities(visual, multiEntityFeatures);

    let series = entities.flatMap(entity => {
      return columns.flatMap<BarChartSeriesOptions>((column, index) => {
        return generateSerie(
          column,
          index,
          type,
          deepGroup,
          (visual.data.rowsByEntity.get(entity.id) as ExtendedRowGroup[]) ?? [],
          entity,
          entities.length > 1
        ) as BarChartSeriesOptions;
      });
    });

    if (series.length > 0 && visual.sortByBar?.length) {
      series = sortSeriesByBar();
    }

    if (visual.showTopSeries) {
      series = groupSeries(series);
    }

    if (visual.chartOptions?.legend?.enabled === true) {
      visual.chartOptions?.legend?.align && delete visual.chartOptions.legend.align;
      visual.chartOptions?.legend?.verticalAlign && delete visual.chartOptions.legend.verticalAlign;
    }

    const maxValue = BarChartUtils.getMaxValue(series);

    const hasGroups = visual.groupByColumns.filter(col => col.isDefault).length > 0;
    const hasMultipleMeasures = columns.length > 1;
    const hasMultipleEntities = entities.length > 1;
    const isUniqueGroup = !hasGroups && hasMultipleMeasures && !hasMultipleEntities;

    return {
      chart: {
        events: {
          render: function (this) {
            const chart = this;
            if (hasColorFormatting) {
              BarChartUtils.setColorFormatting(chart);
            }
          }
        }
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 450
            },
            chartOptions: {
              xAxis: {
                labels: {
                  rotation: visual.diagonalAxisLabel ? -45 : undefined,
                  autoRotation: visual.diagonalAxisLabel ? [-45] : undefined
                }
              }
            }
          }
        ]
      },
      yAxis: {
        min: visual.minValue,
        max: visual.maxValue,
        startOnTick: false,
        endOnTick: false,
        title: undefined,
        tickInterval: visual.yAxisInterval,
        labels: {
          enabled: visual.axisLabel === 'BOTH' || visual.axisLabel === 'VERTICAL',
          formatter: function () {
            // This is the axis of the chart. Needs global configuration. Needs to use the first column.
            const compactColumnFormat = deepmerge(columns[0], {
              formatting: { compact: true }
            });
            return HighChartsDataUtils.formatCell(this.value, compactColumnFormat, rows[0].data, context.reportConfiguration?.config.numberLocale) as string;
          }
        },
        ...getAxisScaleOptions(visual.axisScale)
      },
      xAxis: {
        crosshair: true,
        type: 'category',
        labels: {
          enabled: visual.axisLabel === 'BOTH' || visual.axisLabel === 'HORIZONTAL',
          autoRotation: visual.diagonalAxisLabel ? [-45] : undefined
        }
      },
      legend: HighChartsDataUtils.getLegendFromPosition(visual.legendPosition),
      tooltip: { headerFormat: undefined },
      plotOptions: {
        bar: {
          colorByPoint: visual.colorByPoint,
          groupPadding: isUniqueGroup ? 0 : 0.2
        },
        column: {
          colorByPoint: visual.colorByPoint,
          groupPadding: isUniqueGroup ? 0 : 0.2
        },
        series: {
          stacking: visual.stacking ? 'normal' : undefined,
          showInLegend: visual.legend,
          dataLabels: {
            enabled: visual.showLabelsOnGraph ?? true,
            color: '#666666',
            style: {
              fontWeight: 'normal'
            },
            align: 'center',
            formatter: function (this: Highcharts.PointLabelObject) {
              if (!this.y || !maxValue || !visual.valueType || visual.valueType === BarChartValueType.Absolute || columns[0].formatting?.type === FormatType.percentage) {
                const formattedValue = formatter(this.y);

                const shouldShowPlus = visual.showPlusBeforeValue && this.y && this.y >= 0;

                return shouldShowPlus ? `+${formattedValue}` : formattedValue;
              }
              return BarChartUtils.getFormattedValue(this.y, maxValue, visual.valueType, visual.showPlusBeforeValue, formatter);
            }
          }
        }
      },
      series
    };
  }
}

export { BarChartUtils };
