import { EntityParametersHandler } from '@/common/types/app/EntityParametersHandler';
import { FormatType } from '@/common/types/elastic/FormatType';
import currencyFormatter from 'currency-formatter';
import dayjs from 'dayjs';
import queryString from 'query-string';
import React, { ReactElement } from 'react';
import { Column } from '@/modules/reporting-v2/core/Column';
import { Filter } from '@/modules/reporting-v2/core/Filter';
import { IContext } from '@/modules/reporting-v2/core/ReportContext';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawReportConfig, VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { IVisualDataStructure } from '@/modules/reporting-v2/types/ReportViewerServiceTypes';
import { RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import { FilterOperatorDisplayValues } from '@/types/Filters';
import * as xlsx from 'xlsx';
import { CellAddress, Sheet, WorkBook } from 'xlsx';
import { LookThroughTypes } from '@/config/lookThroughTypes';
import { customFormattingType } from '@/modules/reporting-v2/core/formatTypes';
import { DateRange } from '@/modules/reporting-v2/types/DateRange';
import { ConsolidationType } from '@/modules/reporting-v2/types/ReportingService';
import { findHoldingSet } from './findHoldingSet';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { TextImageConfig } from '@/modules/reporting-v2/core/visuals';
import { SesameUser } from '@/common/types/SesameUser';
import GroupByColumn from '@/modules/reporting-v2/core/GroupByColumn';
import { DATE_FORMAT, DateFormatter } from 'ui-sesame-components';

type ExcelFormatSupplier = (data: any, options: any, customCurrency?: currencyFormatter.Currency) => () => string;
type ExcelFormatMapping = {
  [key in keyof typeof FormatType]: ExcelFormatSupplier | undefined;
};
type ExcelTypeMappingT = { [key in keyof typeof FormatType]: string };

const ExcelTypeMapping: Partial<ExcelTypeMappingT> = {
  percentage: 'n',
  price: 'n',
  amountNoUnit: 'n',
  date: 'd',
  undefined: 's',
  string: 's',
  bool: 'b'
};

const percentageValueSuffix = '%';

enum ExcelAttributes {
  VALUE = 'v',
  FORMAT = 'z',
  TYPE = 't'
}

export enum ExcelLayout {
  SINGLE_TAB_SINGLE_COLUMN,
  SINGLE_TAB_MULTI_COLUMN,
  TAB_PER_PAGE,
  TAB_PER_VISUAL
}

export interface Cover {
  source: URL;
  title?: string;
  createdByLogin: string;
  createdByName: string;
  createdDate: Date;
  company: string;
  filterDate?: Array<string>;
  filterHoldingSet?: Array<string>;
  filterConsolidationType?: Array<string>;
}

export class ExcelVisual {
  constructor(readonly visual: VisualEngine, readonly table: HTMLTableElement, readonly context: IContext) {}
}

class FieldFilter {
  constructor(readonly name: string, readonly operator: string, readonly operand: any) {}
}

const DATE_FORMAT_EXCEL = DATE_FORMAT.DATE;
const EXCEL_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export class ExcelUtils {
  static formatAriaTable(table: HTMLTableElement, columns: Column[], data: IVisualDataStructure, component: VisualComponent, groupByColumns: GroupByColumn[]) {
    const visibleColumns = columns.filter(column => column.isDefault);

    Array.from(table.querySelectorAll('tbody > tr')).forEach((tr, rowIndex) => {
      let tableCells;
      if (component === VisualComponent.BarChart && groupByColumns.length === 0) {
        tableCells = tr.querySelectorAll('td');
      } else {
        tableCells = tr.querySelectorAll('td, th');
      }
      Array.from(tableCells).forEach((td, cellIndex) => {
        const row = data.rows[rowIndex];
        let rowData, cells;

        const column = visibleColumns[Math.min(cellIndex, visibleColumns.length - 1)];

        if (column && row) {
          const level = (row as RowGroup).level;

          if (level === 1) {
            // "deep group"
            cells = ((row as RowGroup).rows as any[]) // iterate over groups
              // get columns for each group
              .map(child => child.total.columns.slice(1))
              // transpose(pivot) group to columns
              .reduce((prev, next) => next.map((_item: any, i: number) => (prev[i] || []).concat(next[i])), [])
              // flatten grouped columns
              .flatMap((r: any) => r);
            rowData = (row as RowGroup).rows[0].data;
          } else if (level === 0) {
            // First level of a groupby (non-grouped rows have no level property).
            switch (component) {
              case VisualComponent.AllocationPie:
                cells = [...row.cells];
                break;
              case VisualComponent.HistoricalChart: // HistoricalChart requires quite specific treatment. We need to preserve order of columns that excel will generate, that can be done by looking at the html table order
                const firstChildRow = (row as RowGroup).rows?.[0];

                if ((firstChildRow as RowGroup)?.level == null) {
                  cells = [...row.cells];
                } else {
                  const tableColumns = Array.from(table.querySelector('thead > tr')?.childNodes || [])
                    .slice(1)
                    .map(node => node.textContent);
                  const tableCells = tableColumns.flatMap(col => (row as RowGroup).rows.find(childRow => (childRow as RowGroup).group === col)?.cells.slice(1));

                  cells = [row.cells[0], ...tableCells.map(cell => cell || 'N/A')];
                }
                break;
              default:
                cells = [(row as RowGroup).group, ...row.cells];
            }

            rowData = row.data;
          } else {
            cells = row.cells;
            rowData = row.data;
          }

          const value = column.formatting?.type === 'date' ? DateFormatter({ value: dayjs(cells?.[cellIndex]) }) : cells?.[cellIndex];
          const attrs = ExcelUtils.getTDAttributes(column, rowData, value);

          Object.entries(attrs)
            .filter(([, attribute]) => typeof attribute !== 'undefined')
            .forEach(([key, attribute]) => td.setAttribute(key, attribute));
        }
      });
    });
  }

  static getEmptyCover = (title?: string): Cover => {
    return {
      title: title,
      createdByLogin: '',
      company: '',
      createdByName: '',
      createdDate: new Date(),
      source: new URL(window.location.href),
      filterDate: undefined,
      filterHoldingSet: undefined,
      filterConsolidationType: undefined
    };
  };

  static getCover = (currentUser: SesameUser, userName: string, title?: string, multiEntityFeatures?: boolean): Cover => {
    const params = EntityParametersHandler.retrieveParamsList(currentUser, undefined, multiEntityFeatures);

    const entities = params.map(param => {
      return findHoldingSet(currentUser.holdingSetTree, hset => hset.id === param.holdingSetId)!.name;
    });

    const dates = params.map(param => {
      if (param.date) {
        return param.date;
      } else if (param.range) {
        return DateRange.displayRange(param.range.to, param.range.from);
      } else {
        return 'N/A';
      }
    });

    const consolidationTypes = params.map(param => {
      return param.lookThrough === LookThroughTypes.Lookthrough ? ConsolidationType.LOOK_THROUGH : ConsolidationType.DIRECT;
    }) as Array<string>;

    return {
      title: title,
      createdByLogin: currentUser.login,
      company: currentUser.company.name,
      createdByName: userName,
      createdDate: new Date(),
      source: new URL(window.location.href),
      filterDate: dates,
      filterHoldingSet: entities,
      filterConsolidationType: consolidationTypes
    };
  };

  static exportFromVisuals = async (visuals: ExcelVisual[], cover?: Cover, reportConfig?: RawReportConfig, layout?: ExcelLayout) => {
    if (layout === undefined) {
      const params = queryString.parse(window.location.search, {
        parseNumbers: true
      });
      layout = params['excelLayout'] === undefined ? ExcelLayout.TAB_PER_VISUAL : (params['excelLayout'] as ExcelLayout);
    }

    switch (layout) {
      case ExcelLayout.TAB_PER_VISUAL:
        return ExcelUtils.exportFromTablesTabPerEachTable(visuals, cover);
      case ExcelLayout.TAB_PER_PAGE:
        return ExcelUtils.exportFromTablesMultiColumns(visuals, reportConfig!, false, cover);
      case ExcelLayout.SINGLE_TAB_MULTI_COLUMN:
        return ExcelUtils.exportFromTablesMultiColumns(visuals, reportConfig!, true, cover);
      case ExcelLayout.SINGLE_TAB_SINGLE_COLUMN:
        return ExcelUtils.exportFromTablesSingleTabSingleColumn(visuals, cover);
    }
  };

  static async exportFromVisual(excelVisual: ExcelVisual, cover?: Cover): Promise<Blob> {
    const table = excelVisual.table;
    const wb = await ExcelUtils.createWorkBook(cover);

    if (cover) {
      ExcelUtils.populateLocalFilters(wb, [excelVisual]);
    }

    const sheet = ExcelUtils.convertToSheet(table);

    xlsx.utils.book_append_sheet(wb, sheet, excelVisual.visual.title ? ExcelUtils.formatTabName(excelVisual.visual.title) : `Sheet1`);

    return ExcelUtils.exportWorkBook(wb);
  }

  private static async exportFromTablesSingleTabSingleColumn(visuals: ExcelVisual[], cover?: Cover): Promise<Blob> {
    const wb = await ExcelUtils.createWorkBook(cover);
    ExcelUtils.populateLocalFilters(wb, visuals);
    const sheet = ExcelUtils.createNewSheet();

    let rowOffset = 0;
    visuals.forEach(visual => {
      ExcelUtils.copyVisualToSheet(visual, sheet, { r: rowOffset, c: 0 });
      rowOffset = xlsx.utils.decode_range(sheet['!ref']!).e.r + 2;
    });

    xlsx.utils.book_append_sheet(wb, sheet, cover?.title);

    return ExcelUtils.exportWorkBook(wb);
  }

  private static async exportFromTablesTabPerEachTable(excelVisuals: ExcelVisual[], cover?: Cover): Promise<Blob> {
    const wb = await ExcelUtils.createWorkBook(cover);

    ExcelUtils.populateLocalFilters(wb, excelVisuals);

    excelVisuals.forEach((excelVisual, index) => {
      if (excelVisual.visual.component === VisualComponent.TextImage && (excelVisual.visual as TextImageConfig).src) {
        return;
      }

      const sheet = ExcelUtils.convertToSheet(excelVisual.table);
      try {
        xlsx.utils.book_append_sheet(wb, sheet, ExcelUtils.makeSheetName(excelVisual, wb, index));
      } catch (err) {
        console.error('Error appending sheet ', ExcelUtils.makeSheetName(excelVisual, wb, index), err);
      }
    });

    return ExcelUtils.exportWorkBook(wb);
  }

  private static async exportFromTablesMultiColumns(visuals: ExcelVisual[], reportConfig: RawReportConfig, singlePage: boolean, cover?: Cover): Promise<Blob> {
    const wb = await ExcelUtils.createWorkBook(cover);
    ExcelUtils.populateLocalFilters(wb, visuals);

    const visualsMap = new Map<string, ExcelVisual>(visuals.map(excelVisual => [excelVisual.visual.id, excelVisual]));
    let sheet = ExcelUtils.createNewSheet();
    let rowOffset = 0;
    let cellOffset = 0;
    for (const page of reportConfig.pages) {
      rowOffset = singlePage ? rowOffset : 0;
      for (const component of page.components) {
        const visual = visualsMap.get(component.id);
        if (visual) {
          const copied = ExcelUtils.copyVisualToSheet(visual, sheet, {
            r: rowOffset,
            c: cellOffset
          });
          cellOffset = xlsx.utils.decode_range(copied['!ref']!).e.c + 2;
        }
        rowOffset = xlsx.utils.decode_range(sheet['!ref']!).e.r + 2;
      }
      if (!singlePage) {
        xlsx.utils.book_append_sheet(wb, sheet, page.title);
        sheet = ExcelUtils.createNewSheet();
      }
    }

    if (singlePage) {
      xlsx.utils.book_append_sheet(wb, sheet, cover?.title);
    }

    return ExcelUtils.exportWorkBook(wb);
  }

  private static makeSheetName(excelVisual: ExcelVisual, wb: xlsx.WorkBook, index: number) {
    if (excelVisual.visual.title) {
      const sheetName = ExcelUtils.formatTabName(excelVisual.visual.title);
      if (wb.SheetNames.map(name => name.toLowerCase()).includes(sheetName.toLocaleLowerCase())) {
        return `${ExcelUtils.formatTabName(excelVisual.visual.title, 26 - index.toString().length)}(${index})`;
      } else {
        return sheetName;
      }
    } else {
      return `Sheet${index + 1}`;
    }
  }

  static getExcelFormat(formatting: any, data: any) {
    if (!formatting) {
      return undefined;
    }
    const { type, ...options } = formatting;

    if (options?.currency && options?.field) {
      if (!options.currency.includes('.')) {
        options.currency = options.field.replace(/\.[^.]+$/i, `.${options.currency}`);
      }
    }

    return ExcelUtils.formatSuppliers[type as FormatType] ? ExcelUtils.formatSuppliers[type as FormatType]!(data, options)() : undefined;
  }

  static getExcelType(formatting: any) {
    if (!formatting) {
      return 's';
    }
    const { type } = formatting;

    return ExcelTypeMapping[type as FormatType] || 's';
  }

  static getTableDataAttributes(format?: string, type?: string, value?: any) {
    if (React.isValidElement(value)) {
      return {};
    }

    const v = String(value);
    const valueIsCorrect = v !== 'NaN' && v !== 'null';
    let formattedValue = value;

    if (typeof value === 'string') {
      formattedValue = formattedValue.trim();
    }

    if (typeof value === 'boolean') {
      formattedValue = String(value);
    }

    const dataAttributes = {
      [ExcelAttributes.FORMAT]: format,
      [ExcelAttributes.TYPE]: type,
      [ExcelAttributes.VALUE]: valueIsCorrect ? formattedValue : undefined
    } as Partial<Record<ExcelAttributes, string>>;

    if ((!valueIsCorrect || formattedValue === undefined) && dataAttributes[ExcelAttributes.TYPE] === ExcelTypeMapping.date) {
      dataAttributes[ExcelAttributes.TYPE] = ExcelTypeMapping.string;
    }

    return Object.entries(dataAttributes)
      .filter(([key]) => key !== undefined)
      .reduce((a, [key, attribute]) => Object.assign(a, { [key]: attribute }), {});
  }

  static getTDAttributes(column: Column, data: FlattenObject, _value?: Primitive): { [key in keyof typeof ExcelAttributes]: string } | Record<string, never> {
    const formatting = {
      ...column.formatting,
      currency: column.formatting?.currency?.name,
      field: column.field.name
    };

    let value = _value;
    let format = ExcelUtils.getExcelFormat(formatting, data);
    let type = ExcelUtils.getExcelType(formatting);

    if (column.formatting?.type === customFormattingType) {
      // Tries to guess formatting type for custom-created values (e.g statstable cells, historicalMonthlyTab...)
      const { fmtType, fmt, value: newValue } = this.getCustomCellFormatType(value, formatting, data);

      format = fmt;
      type = fmtType!;
      value = newValue;
    }

    return ExcelUtils.getTableDataAttributes(format, type, value);
  }

  private static getCustomCellFormatType(_value: any, formatting: any, data: any) {
    let value = _value;

    if (React.isValidElement(value)) {
      const textContent = (value as ReactElement)?.props?.children;

      if (textContent) {
        value = textContent;
      }
    }

    value = String(value).trim();

    if (ExcelUtils.formatSuppliers.percentage && value.endsWith(percentageValueSuffix)) {
      return {
        fmt: ExcelUtils.formatSuppliers.percentage(formatting, data)(),
        fmtType: ExcelTypeMapping.percentage,
        value
      };
    } else if (ExcelUtils.formatSuppliers.price) {
      const customCurrency = currencyFormatter.findCurrency(value[value.length - 1]);

      if (customCurrency !== undefined) {
        return {
          fmt: ExcelUtils.formatSuppliers.price(formatting, data, customCurrency)(),
          fmtType: ExcelTypeMapping.price,
          value
        };
      }
    }
    return { fmtType: ExcelTypeMapping.string, value: _value };
  }

  private static formatSuppliers: Partial<ExcelFormatMapping> = {
    percentage: () => () => {
      return '0.00%';
    },
    price: (data, options, customCurrency?: currencyFormatter.Currency) => () => {
      const code = data?.[options.currency];

      if (code) {
        const currency = customCurrency ?? currencyFormatter.findCurrency(code);
        const decimalsVal = ExcelUtils.decimals(Number(options.decimals) || 0);

        if (currency) {
          let formatWithCurrency = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency.code
          }).format(0);

          // change value format to make it work for Excel
          formatWithCurrency = formatWithCurrency.replace(/0(.[0]*)?/gi, `#,##0${decimalsVal ? '.' + decimalsVal : ''}`);

          // wrap currency with "" to avoid any errors in Excel file
          const currencyString = formatWithCurrency.split('#,##0')[0].trim(); // ex. 'SEK #,##0.00' -> 'SEK'
          formatWithCurrency = formatWithCurrency.replace(currencyString, `"${currencyString}"`);

          return formatWithCurrency;
        }
      }
      return `#,##0.00`;
    },
    amountNoUnit: (_, options) => () => {
      const decimalsVal = ExcelUtils.decimals(Number(options.decimals) || 0);
      return `#,##0${decimalsVal ? '.' + decimalsVal : ''}`;
    },
    date: (_, options) => () => {
      return options.format || DATE_FORMAT_EXCEL;
    },
    bool: undefined,
    string: () => () => '@',
    undefined: undefined
  };

  private static decimals = (length: number) => [...new Array(length)].map(() => '0').join('');

  private static createWorkBook = async (cover?: Cover): Promise<WorkBook> => {
    let workbook: WorkBook;
    if (cover) {
      workbook = await fetch('/template.xlsx')
        .then(res => res.arrayBuffer())
        .then(ab => xlsx.read(ab, { type: 'array' }));
      ExcelUtils.populateCover(workbook, cover);

      workbook.Props = {
        Author: cover?.createdByName,
        LastAuthor: cover?.createdByName,
        Title: cover?.title,
        Company: cover?.company,
        Application: 'Sesame',
        AppVersion: '2.0',
        CreatedDate: cover?.createdDate,
        ModifiedDate: cover?.createdDate
      };
    } else {
      workbook = xlsx.utils.book_new();
    }

    return workbook;
  };

  private static populateCover = (workbook: WorkBook, cover: Cover) => {
    const coverSheet = workbook.Sheets['Cover'];
    if (workbook?.Workbook?.Names) {
      Object.entries(cover).forEach(([k, v]) => {
        if (v) {
          const cellRef = ExcelUtils.getNamedCellRef(workbook, k);
          if (Array.isArray(v) && cellRef) {
            let prevCharCode: string | null = null;

            v.forEach(value => {
              const matches = /(?<row>[a-zA-Z]+)(?<cell>\d+)/.exec(cellRef);

              if (matches?.groups) {
                const row = String.fromCharCode((prevCharCode ?? matches.groups.row).charCodeAt(0) + 1);
                const cell = matches.groups.cell;

                prevCharCode = row;

                coverSheet[`${row}${cell}`] = ExcelUtils.createCell(value);
              }
            });
          } else if (cellRef) {
            coverSheet[cellRef] = ExcelUtils.createCell(v);
          }
        }
      });
    }
  };

  private static populateLocalFilters = (workbook: WorkBook, visuals: ExcelVisual[]) => {
    const coverSheet = workbook.Sheets['Cover'];
    const cellRef = ExcelUtils.getNamedCellRef(workbook, 'localFilters');
    if (cellRef) {
      const pointer = xlsx.utils.decode_cell(cellRef);
      pointer.r++;
      pointer.c++;
      visuals.forEach(excelVisual => {
        const filters = ExcelUtils.getFilters(excelVisual);
        if (excelVisual.visual.title && filters.length > 0) {
          ExcelUtils.printLnAt(coverSheet, pointer, [excelVisual.visual.title]);
          pointer.c++;
          filters.forEach(filter => {
            ExcelUtils.printLnAt(coverSheet, pointer, [filter.name, filter.operator, filter.operand]);
          });
          pointer.c--;
        }
      });
    }
  };

  private static printLnAt = (sheet: Sheet, address: CellAddress, data: any[]) => {
    const originalCell = address.c;
    data.forEach(cell => {
      sheet[xlsx.utils.encode_cell(address)] = ExcelUtils.createCell(cell);
      address.c++;
    });
    address.c = originalCell;
    address.r++;
  };

  private static getFilters = (excelVisual: ExcelVisual): FieldFilter[] => {
    const filters: FieldFilter[] = [];

    excelVisual.context.globalFilters.forEach(filter => {
      filters.push(new FieldFilter(filter.field.name, FilterOperatorDisplayValues[filter.condition.operator], ExcelUtils.inferOperand(excelVisual, filter)));
    });

    return filters;
  };

  private static inferOperand = (excelVisual: ExcelVisual, filter: Filter): string => {
    if (filter.condition.getAllUsedFields().length) {
      return filter.condition
        .getAllUsedFields()
        .map(field => excelVisual.visual.columns.find(column => column.field.name === field.name)?.headerConfig.displayName || field.name)
        .join(', ');
    } else {
      return filter.condition.values.join(', ');
    }
  };

  private static createCell(value: string | number | Date | URL) {
    if (value instanceof Date) {
      return { t: 'd', z: DATE_FORMAT_EXCEL, v: value };
    } else {
      return { t: 's', v: value instanceof URL ? String(value) : value };
    }
  }

  private static getNamedCellRef = (workbook: WorkBook, cellName: string) => {
    const index = workbook.Workbook!.Names!.findIndex(elt => {
      return elt.Name === cellName;
    });
    if (index !== -1) {
      return workbook.Workbook?.Names![index].Ref.split('!')[1].replaceAll(/\$/g, '');
    }
  };

  private static createNewSheet = (): Sheet => {
    return {
      '!ref': xlsx.utils.encode_range({ s: { c: 0, r: 0 }, e: { c: 0, r: 0 } })
    };
  };

  private static convertToSheet = (table: HTMLTableElement) => {
    return xlsx.utils.table_to_sheet(table, {
      raw: false,
      dateNF: 'dd-mm-YYYY;@',
      cellDates: false
    });
  };

  private static copyVisualToSheet = (excelVisual: ExcelVisual, destination: Sheet, offset: CellAddress) => {
    const tableSheet = ExcelUtils.convertToSheet(excelVisual.table);
    xlsx.utils.sheet_add_aoa(destination, [[excelVisual.visual.title || '']], {
      origin: offset
    });
    ExcelUtils.copySheetToSheet(tableSheet, destination, {
      r: offset.r + 1,
      c: offset.c
    });
    return tableSheet;
  };

  private static copySheetToSheet = (source: Sheet, destination: Sheet, offset: CellAddress) => {
    let copiedCells = 0;
    Object.entries(source).forEach(([address, value]) => {
      if (!address.startsWith('!')) {
        const ref = xlsx.utils.decode_cell(address);
        ref.c += offset.c;
        ref.r += offset.r;
        destination[xlsx.utils.encode_cell(ref)] = value;
        copiedCells++;
      }
    });
    if (copiedCells > 0 && destination['!ref'] && source['!ref']) {
      const destinationRange = xlsx.utils.decode_range(destination['!ref']);
      const sourceRange = xlsx.utils.decode_range(source['!ref']);
      destination['!ref'] = xlsx.utils.encode_range({
        ...destinationRange,
        e: {
          c: Math.max(destinationRange.e.c, offset.c + (sourceRange.e.c - sourceRange.s.c)),
          r: Math.max(destinationRange.e.r, offset.r + (sourceRange.e.r - sourceRange.s.r))
        }
      });
    }
  };

  private static formatTabName = (label: string, length?: number) => {
    // max length for tab name is 31
    //: \ / ? * [ ] are invalid characters for tabs

    const limitLength = 28;
    const searchValue = new RegExp(`(.{${length && length <= limitLength ? length : limitLength}})..+`);
    return (
      label
        .replace(searchValue, `$1...`)
        // eslint-disable-next-line
        .replaceAll(/[\:\\\/\?\*\[\]]/g, '_')
    );
  };

  private static exportWorkBook = (wb: WorkBook) => {
    if (Object.entries(wb.Sheets).length === 0) {
      xlsx.utils.book_append_sheet(wb, []);
    }

    // needed to unhide rows, possibly aa bug in the excel library
    Object.values(wb.Sheets).forEach(sheet => (sheet['!rows'] = []));

    const excelBuffer = xlsx.write(wb, {
      bookType: 'xlsx',
      type: 'array'
    });

    return new Blob([excelBuffer], { type: EXCEL_MIME_TYPE });
  };
}
