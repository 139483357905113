import routes from '@/config/routes';
import { AxiosError } from 'axios';

export const shouldRedirectTo50x = (error: AxiosError): boolean => {
  return Boolean(window.location.pathname !== routes['50x'].path && (error.message === 'Network Error' || (error.response && [503, 502].includes(error.response.status))));
};

export const redirectTo50xOnRequestError = () => {
  window.location.href = `${routes['50x'].path}?next=${window.location.pathname + window.location.search}`;
};
