import { MetadataEntityType } from '@/common/types/entity/Metadata';

export enum MetadataKeyId {
  ASSET_PICTURE_KEY_ID = '00000000-0000-0000-0000-000000000002',
  CONTACT_PICTURE_KEY_ID = '00000000-0000-0000-0000-000000000004'
}

export type MetadataObjectSelectorParameters = {
  keyId: MetadataKeyId;
  entityType: MetadataEntityType;
  entityId: string;
};

// to remove when merging with admin-new
export type AuditableEntity = {
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
};

// to remove when merging with admin-new
export enum InstitutionType {
  PLATFORM = 'PLATFORM',
  FAMILY_OFFICE = 'FAMILY_OFFICE',
  TRUST_COMPANY = 'TRUST_COMPANY',
  WEALTH_MANAGER = 'WEALTH_MANAGER',
  FUND_MANAGER = 'FUND_MANAGER',
  CUSTODIAN = 'CUSTODIAN',
  AUDITOR = 'AUDITOR',
  PRIME_BROKER = 'PRIME_BROKER',
  DATA_PROVIDER = 'DATA_PROVIDER',
  CONSULTANT = 'CONSULTANT'
}

// to remove when merging with admin-new
export type Institution = {
  id: number;

  name: string;
  etlFolder: string;

  street: string;
  city: string;
  postalCode: string;
  country: string;
  additionalInformation: string;
  postOfficeBox: string;

  about: string;
  investmentPhilosophy: string;
  fax: string;
  phone: string;
  email: string;
  website: string;

  ceo: string;
  cio: string;
  primaryContact: string;

  inceptionDate?: string;

  lei: string;

  types: InstitutionType[];
} & AuditableEntity;

// to remove when merging with admin-new
export interface TableParams {
  sort?: string;
  page?: number;
  size?: number;
}

// to remove when merging with admin-new
export type InstitutionFilter = TableParams & {
  id?: number;
  type?: InstitutionType;
  name?: string;
  etlFolder?: string;
  country?: string;
  isInternalUser?: boolean;
};

// to remove when merging with admin-new
export type Cancelleable = {
  signal?: AbortSignal;
};


export type AssetV2Filter = {
  private?: boolean;
  alphaVantageExchangeCode?: string;
  alphaVantageTicker?: string;
  assetType?: string;
  barraLocalId?: string;
  barraOneTicker?: string;
  bloombergCode?: string;
  bloombergUniqueId?: string;
  createdBy?: string;
  createdDate?: string;
  currency?: string;
  disabled?: boolean;
  eodExchangeCode?: string;
  eodTicker?: string;
  figi?: string;
  hidden?: boolean;
  holdingSetId?: number;
  ids?: string[];
  isin?: string;
  nameEqual?: string;
  nameIn?: string[];
  nameLike?: string;
  namePrefix?: string;
  pmsEnabled?: boolean;
  ric?: string;
  riskCountry?: string;
  search?: string;
};

export type metadatePropertiesValue = {
  label?: string;
  value?: string
};

export type MetadataKey = {
  id: string;
  name: string;
  entityType: string;
  format: string;
  origin: string;
  visibleInSummary: boolean;
  visibleInReport: boolean;
  readOnly: boolean;
  securityPolicy: string;
  allowedValues?: string[];
  colourMap?: { [key: string]: string };
  decimalNumber?: number;
  securityIds?: string[];
  value?: string;
};