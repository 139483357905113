import { requestApi } from '@/core';

interface InstitutionType {
  name: string;
  id: string;
}

export const getInstitutions = (): Promise<InstitutionType[]> =>
  requestApi({
    method: 'GET',
    service: 'client',
    url: `/api/companies`
  }).then(({ data }: any) => data.content);
