import React, { FC } from 'react';
import { Select } from 'antd';
import { EnumUtils } from '@/utils/EnumUtils';
import getParent from '../../../utils/getParent';
import { Status } from '@/modules/ERP/Contacts/types';
import { CaretDownOutlined } from '@ant-design/icons';

const { Option } = Select;

export interface StatusSelectProps {
  defaultValue?: Status;
  value?: Status;
  onChange?: (status: Status) => void;
  className?: string;
}

export const StatusSelect: FC<StatusSelectProps> = props => {
  return (
    <Select {...props} popupMatchSelectWidth={false} getPopupContainer={getParent} suffixIcon={<CaretDownOutlined className={'modal-contact-create-clickable-icon'} />}>
      {Object.keys(Status).map(type => (
        <Option key={type}>{EnumUtils.toHumanReadable(type)}</Option>
      ))}
    </Select>
  );
};
