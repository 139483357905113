import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { getEmptyRawColumn } from '@/modules/reporting-v2/utils/getEmptyRawColumn';
import type RawSingleValueConfig from './types';

export const config: RawSingleValueConfig = {
  type: 'visual',
  fields: getEmptyRawColumn(),
  logo: false,
  bgAlign: 'center',
  _reportContext: VisualReportContext.NONE,
  emptyValue: 'N/A'
};
