import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedCalloutConfig } from '..';
import { CallOutConfigMapped } from './types';
import { Orientation } from './VisualSpecificProps';

export default class CalloutConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as CallOutConfigMapped));
  }

  getVisualConfigProps(config: CallOutConfigMapped): ExtendedCalloutConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      orientation: config.orientation
    });
  }

  setVisualConfig(config: ExtendedCalloutConfig) {
    super.setVisualConfig(config);
    this.orientation = config.orientation;
    this.total = true;
  }

  orientation: Orientation;
}
