import { requestApi } from '@/core';
import { UUID } from '@/common/types/types';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { MetadataKeyId } from '@/modules/ERP/Common/types';
import { MetadataEntityType } from '@/common/types/entity/Metadata';

export interface MetadataObject {
  value: UUID;
  entityId: string;
  keyId: MetadataKeyId;
}

export const getObjectMetadata = async (entityType: MetadataEntityType, entityId: Primitive): Promise<Array<MetadataObject>> => {
  const resp = await requestApi({
    method: 'get',
    service: 'metadata',
    url: `/api/metadata/values/${entityType}/${entityId}`
  });
  return resp!.data;
};
