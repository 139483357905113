import { EntityLinkType } from '@/common/types/entity/Link';

export const resolveEntityType = (entityType: EntityLinkType) => {
  if (entityType === EntityLinkType.CONTACT_COMPANY) {
    return 'company';
  } else if (entityType === EntityLinkType.CONTACT_PERSON) {
    return 'contact';
  } else if (entityType === EntityLinkType.ASSET) {
    return 'asset';
  } else if (entityType === EntityLinkType.TASK) {
    return 'task';
  } else if (entityType === EntityLinkType.HOLDING_SET) {
    return 'holdingSet';
  } else if (entityType === EntityLinkType.NOTE) {
    return 'note';
  }
  return '';
};
