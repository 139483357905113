import { Column } from '@/modules/reporting-v2/core/Column';
import React, { createContext, useCallback, useContext } from 'react';

type CategoryContextWrapperProps = {
  children: React.ReactElement;
  defaultColumns: Column[];
  displayCategories: boolean;
};

type GetCategoryClassFunction = (index: number) => string | undefined;

const CategoryContext = createContext<GetCategoryClassFunction>(idx => undefined);

const CategoryContextWrapper: React.FC<CategoryContextWrapperProps> = ({ children, defaultColumns, displayCategories }) => {
  const getCategoryClass = useCallback(
    (columnIndex: number) => {
      if (!displayCategories) {
        return undefined;
      }

      const column = defaultColumns[columnIndex];

      const categoryClasses: string[] = [];

      const currentCategory = column.headerConfig.category;
      const previousColumn = defaultColumns[columnIndex - 1];
      const previousColumnCategory = previousColumn?.headerConfig.category;
      const nextColumn = defaultColumns[columnIndex + 1];
      const nextColumnCategory = nextColumn?.headerConfig.category;

      if (previousColumn && previousColumnCategory !== currentCategory) {
        categoryClasses.push('category-start');
      }

      if (nextColumn && nextColumnCategory !== currentCategory) {
        categoryClasses.push('category-end');
      }

      return categoryClasses.join(' ');
    },
    [defaultColumns, displayCategories]
  );

  return <CategoryContext.Provider value={getCategoryClass}>{children}</CategoryContext.Provider>;
};

const useCategoryContext = () => {
  const getCategoryClass = useContext(CategoryContext);

  return getCategoryClass;
};

export { CategoryContextWrapper, useCategoryContext };
