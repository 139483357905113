import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { ReportPageTemplate, TransientReportPageTemplate } from '@/common/types/entity/ReportPageTemplate';

export default (template: TransientReportPageTemplate) =>
  requestApi({
    service: 'cms',
    method: 'post',
    url: `/api/page-templates`,
    data: template
  }).then((response: AxiosResponse<ReportPageTemplate> | undefined) => response?.data);
