import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';

export class DrilldownTableConfig extends VisualEngine {
  constructor(rawVisual: any, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as any));
  }

  getVisualConfigProps(config: any): any {
    return Object.assign(super.getVisualConfigProps(config), {
      collapsible: config._collapsible,
      idField: config.idField,
      parentIdField: config.parentIdField,
      levelField: config.levelField,
      total: config.total
    });
  }

  setVisualConfig(config: any) {
    super.setVisualConfig(config);
    this.subTotal = true;
    this.collapsible = config.collapsible;
    this.idField = config.idField;
    this.parentIdField = config.parentIdField;
    this.levelField = config.levelField;
    this.total = config.total;
  }

  idField: string;
  parentIdField: string;
  levelField: string;
  collapsible: boolean;
  subTotal: boolean;
  total: boolean;
}
