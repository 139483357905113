import React, { useMemo } from 'react';
import format from '@/modules/reporting-v2/core/format';
import { Column, ColumnFormatting } from '@/modules/reporting-v2/core/Column';
import { Field } from '@/modules/reporting-v2/core/Field';
import { FormatType } from '@/common/types/elastic/FormatType';
import { colors, labels } from '@/modules/reporting-v2/core/visuals/LimitsCallOuts';
import { Dict } from '@/modules/reporting-v2/types/Common';

interface ILimitStatusProps {
  valueStatus: string;
}

export const LimitStatus: React.FC<ILimitStatusProps> = React.memo(({ valueStatus }) => {
  const FormattedStatus = useMemo(
    () =>
      format(
        new Column({
          field: new Field('limits'),
          formatting: new ColumnFormatting(undefined, FormatType.limits)
        }),
        valueStatus,
        {}
      ),
    [valueStatus]
  );
  const colorStyle = useMemo(() => ({ color: (colors as Dict<string>)[valueStatus] }), [valueStatus]);

  return (
    <div>
      <span style={colorStyle}>{(labels as Dict<string>)[valueStatus]}</span>
      {FormattedStatus}
    </div>
  );
});
