import React from 'react';

class HeaderConfig {
  constructor(displayName?: string, category?: string, measureAsName?: string) {
    this.displayName = displayName;
    this.category = category;
    this.measureAsName = measureAsName;
  }

  displayName?: string | React.ReactElement;
  category?: string;
  measureAsName?: string;
}

export { HeaderConfig };
