import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useValidation } from '@/modules/Onboarding/hooks/useValidation';
import { useFormContext } from '@/common/form';
import { Priority, Reminders, Status, TaskCreationPayload } from '@/modules/ERP/Contacts/types';
import { Button, Flex, message, Modal, Row, Typography } from 'antd';
import { TextInput } from '@/common/components/Input/TextInput';
import { contactsTasksSelector, contactTaskByIdSelector, filteredEntityActivitySelector, taskListTableSelector } from '@/modules/ERP/Contacts/Recoil/contacts.selectors';
import { constSelector, useRecoilCallback, useRecoilValue } from 'recoil';
import createTask from '@/modules/TMS/api/createTask';
import { createLink } from '@/modules/ERP/Contacts/Api/createLink';
import { ActivityType, EntityLinkType, LinkLookup, LinkableObject } from '@/common/types/entity/Link';
import { UUID } from '@/common/types/types';
import { TaskSider } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/Form/Sider';
import dayjs, { Dayjs } from 'dayjs';
import ReactQuill from 'react-quill';
import { existingListObjectsSelector, linkObjectSelector, multiplelinkObjectSelector } from '@/modules/ERP/Common/Recoil/links.selector';
import { withSmallSuspense } from '@/common/suspense';
import { Text } from '@/common/components/Typography/Text';
import { userListMentionQuill } from '@/modules/ERP/Common/utils';
import { entityLinksSelector } from '@/modules/ERP/Common/Recoil/erp.selectors';
import { loggedUserSelector, usersForCurrentUsers } from '@/common/auth/recoil/user.selector';
import { disableLink } from '@/modules/ERP/Contacts/Api/disableLink';
import { Form } from '@/common/form/form';
import { string } from '@/common/form/schema';
import { usePermission } from '@/common/hooks/usePermission';
import { Permissions } from '@/utils/security';
import { z } from 'zod';
import { TaskType } from '@/common/types/entity/Task';
import useQueryParameters from '@/modules/TMS/hooks/useQueryParameters';
import { deleteLink } from '../../../Api/deleteLink';
import 'react-quill/dist/quill.snow.css';

interface CreateTaskProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  search?: string;
  objects?: { id: UUID; type: EntityLinkType }[];
  currentTaskValues?: TaskFormType;
  onSave?: (form: TaskFormType) => Promise<void>;
  setDeletionOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export type TaskFormType = {
  title: string;
  description: string;
  reminder: Reminders;
  status: Status;
  priority: Priority;
  dueDate: null | dayjs.Dayjs;
  assignee: any;
  type?: TaskType;
  holdingSetId?: number;
  createdDate?: Date;
  createdBy?: string;
  lastModifiedDate?: Date;
  lastModifiedBy?: string;
};

const defaultSchemaTask: TaskFormType = {
  title: '',
  description: '',
  reminder: Reminders.AT_DUE_TIME,
  status: Status.TO_DO,
  priority: Priority.MODERATE,
  dueDate: null,
  assignee: null
};

export const descriptionMaxLength = 2000;

const CreateTask: React.FunctionComponent<CreateTaskProps> = withSmallSuspense(
  React.memo(({ isOpen, setIsOpen, objects, search = '', currentTaskValues, onSave, setDeletionOpen }) => {
    const _pageObject = useRecoilValue(objects?.length ? multiplelinkObjectSelector(objects) : constSelector(null));
    const [providedTaskID] = useQueryParameters();
    const entityLinks = useRecoilValue(entityLinksSelector([EntityLinkType.TASK, providedTaskID]));

    const links = useRecoilValue(currentTaskValues ? existingListObjectsSelector([EntityLinkType.TASK, providedTaskID]) : constSelector([]));
    const pageObjects = useMemo(() => {
      if (_pageObject?.length) {
        return _pageObject.map((obj, index) => ({ id: objects![index].id, ...obj, _entityType: objects![index].type }));
      }

      return null;
    }, [_pageObject, objects]);

    const [selectedLinks, setSelectedLinks] = useState<Array<LinkableObject>>(currentTaskValues ? links : pageObjects !== null ? (pageObjects as Array<LinkableObject>) : []);

    useEffect(() => {
      if (!currentTaskValues && pageObjects !== null) {
        setSelectedLinks(pageObjects as Array<LinkableObject>);
      }
    }, [pageObjects, currentTaskValues]);

    const linksToDelete = useMemo(() => {
      const linksToDelete: Array<LinkLookup> = [];
      links
        ?.filter(object => {
          const foundSelectedObject = selectedLinks.find(item => item.id === object.id && item._entityType === object._entityType);
          return !foundSelectedObject;
        })
        .forEach(object => {
          const existingLinks = entityLinks.filter(link => link.entityId === String(object.id) && !linksToDelete.find(linkToDelete => linkToDelete.id === link.id));
          linksToDelete.push(...existingLinks);
        });
      return linksToDelete;
    }, [entityLinks, links, selectedLinks]);

    const translator = useIntl();
    const [form, setForms] = useState(currentTaskValues ? { ...currentTaskValues } : { ...defaultSchemaTask });
    const [isUserInputValid, setIsUserInputValid] = useState(true);
    const maxAmountofCharactersAttained = form.description.length >= descriptionMaxLength;

    const [confirmLoading, setConfirmLoading] = useState(false);
    const onChange = useCallback(
      (
        values: TaskFormType,
        key: string,
        value:
          | string
          | Dayjs
          | {
              id: number;
            }
          | null
      ) => {
        const newState = { ...values, [key]: value };
        key === 'title' ? setForms(previousForm => ({ ...previousForm, title: newState.title })) : setForms(newState);

        if (key === 'description' && typeof value === 'string') {
          if (value.length >= descriptionMaxLength) {
            value = value.substring(0, descriptionMaxLength);
          }
          newState.description = value;
        }

        if (value !== undefined) {
          const updatedValues = { ...values, [key]: value };
          const validation = CreateTaskSchema.safeParse(updatedValues);
          if (validation.success) {
            setIsUserInputValid(true);
          } else {
            setIsUserInputValid(false);
          }
        }
      },
      [setForms]
    );

    const validation = useValidation(
      [
        {
          name: 'title',
          required: true
        },
        {
          name: 'description',
          required: true
        }
      ],
      form
    );

    const handleDescriptionChange = useCallback((content: string) => onChange(form, 'description', content.substring(0, descriptionMaxLength)), [form, onChange]);

    const handleChangeQuill = useCallback(
      (content: string, delta, source, editor) => {
        const textContent = editor.getText().substring(0, descriptionMaxLength);
        handleDescriptionChange(textContent);
      },
      [handleDescriptionChange]
    );

    const formContext = useFormContext(form, onChange, false, false);

    const onClickSave = useRecoilCallback(
      ({ refresh }) =>
        async () => {
          setConfirmLoading(true);
          if (currentTaskValues) {
            onSave &&
              (await onSave(form as TaskFormType).then(() => {
                linksToDelete.map(link => {
                  return deleteLink(EntityLinkType.TASK, link.entityId as UUID, link.id);
                });
                selectedLinks.map(obj => {
                  return createLink(EntityLinkType.TASK, providedTaskID, {
                    type: obj._entityType,
                    entityId: obj.id as UUID
                  });
                });
              }));
            setTimeout(() => {
              setConfirmLoading(false);
              setIsOpen(false);
            }, 1000);
          } else {
            createTask(form as TaskCreationPayload)
              .then(async responseTask => {
                try {
                  await Promise.all(
                    selectedLinks.map(obj => {
                      createLink(EntityLinkType.TASK, responseTask.id, {
                        type: obj._entityType,
                        entityId: obj.id as UUID
                      });
                    })
                  );

                  if (responseTask.status === Status.REMOVED) {
                    await disableLink(EntityLinkType.TASK, responseTask.id);
                  }

                  message.success(translator.formatMessage({ id: 'generic.successfullyAdded' }));
                  setIsOpen(false);
                  setForms({ ...defaultSchemaTask });
                  setTimeout(() => {
                    refresh(contactTaskByIdSelector(responseTask.id));
                    refresh(
                      linkObjectSelector({
                        type: EntityLinkType.TASK,
                        id: String(responseTask.id)
                      })
                    );
                    refresh(existingListObjectsSelector([EntityLinkType.TASK, responseTask.id]));
                    setTimeout(() => {
                      if (objects?.length) {
                        objects.forEach(({ type, id }) => {
                          refresh(entityLinksSelector([type, id]));
                          refresh(contactsTasksSelector([{ search }, type, id, true]));
                        });
                      }
                      refresh(taskListTableSelector);
                    }, 1000);
                  }, 1000);
                  setSelectedLinks([]);
                } catch (err) {
                  message.error(
                    translator.formatMessage({
                      id: 'generic.somethingWentWrongWhenSavingLinks'
                    })
                  );
                }
              })
              .catch(error => {
                message.error(error);
              })
              .finally(() => {
                setConfirmLoading(false);
              });
          }
          setTimeout(() => {
            if (objects) {
              if (objects[0].type === EntityLinkType.CONTACT_COMPANY || objects[0].type === EntityLinkType.CONTACT_PERSON) {
                refresh(filteredEntityActivitySelector([ActivityType.CONTACT, providedTaskID!.toString()]));
              } else {
                refresh(filteredEntityActivitySelector([objects[0].type as unknown as ActivityType, providedTaskID!.toString()]));
              }
            }
          }, 1000);
        },
      [form, selectedLinks, objects]
    );

    const loggedUser = useRecoilValue(loggedUserSelector);
    const users = useRecoilValue(usersForCurrentUsers(loggedUser.accountId));
    const quillRef = useRef(null);

    useEffect(() => {
      if (quillRef.current) {
        const quill = quillRef.current.getEditor();
        quill.on('text-change', (delta, oldDelta, source) => {
          if (source === 'user') {
            const currentText = quill.getText();
            if (currentText.length > descriptionMaxLength) {
              const trimmedText = currentText.substring(0, descriptionMaxLength);
              quill.root.innerHTML = trimmedText;
            }
          }
        });
      }
    }, [form.description]);

    const [mention, setMention] = useState({});
    useEffect(() => {
      setMention({
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        defaultMenuOrientation: 'top',
        source: (searchTerm: any, renderList: any) => userListMentionQuill(searchTerm, renderList, users)
      });
    }, [users]);

    const modules = useMemo(() => {
      return {
        toolbar: {
          container: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ size: ['small', false, 'large', 'huge'] }, { color: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
            ['link']
          ]
        },
        clipboard: { matchVisual: false },
        mention: mention
      };
    }, [mention]);
    const mustBeprovided = translator.formatMessage({ id: 'erp.mustBeProvided' });
    const mustNotContainOnlyWhiteSpaces = translator.formatMessage({ id: 'erp.mustNotContainOnlyWhiteSpaces' });
    const CreateTaskSchema = z.object({
      title: string({ required_error: translator.formatMessage({ id: 'generic.title' }) + ' ' + mustBeprovided })
        .noOnlyWhitespace({ message: translator.formatMessage({ id: 'generic.title' }) + ' ' + mustNotContainOnlyWhiteSpaces })
        .safeString()
        .simpleNameWithNumbers()
        .min(1)
    });
    const isTaskContributor = usePermission([Permissions.TASK_CONTRIBUTOR]);
    const handleCancel = () => {
      setIsOpen(false);
      !currentTaskValues && setForms({ ...defaultSchemaTask });
    };
    return (
      <Modal
        onOk={onClickSave}
        width={'850px'}
        className={'modal-contact-create'}
        open={isOpen}
        onCancel={handleCancel}
        okText={currentTaskValues ? translator.formatMessage({ id: 'generic.save' }) : translator.formatMessage({ id: 'generic.saveTask' })}
        okButtonProps={{ disabled: !validation || !isUserInputValid }}
        destroyOnClose
        confirmLoading={confirmLoading}
        zIndex={1000}
      >
        {currentTaskValues ? (
          <Typography.Title level={4}>{translator.formatMessage({ id: 'generic.editTask' })}</Typography.Title>
        ) : (
          <Typography.Title level={4}>{translator.formatMessage({ id: 'generic.createTask' })}</Typography.Title>
        )}
        <Form schema={CreateTaskSchema} initialValues={formContext?.object} layout="vertical" onChange={onChange}>
          <Form.Item
            name="title"
            label={translator.formatMessage({ id: 'generic.title' })}
            colon={false}
            className={'modal-contact-create-title-field'}
            style={{ width: '100%' }}
            required={currentTaskValues ? false : true}
          >
            <TextInput maxLength={100} style={{ borderRadius: '5px' }} placeholder={translator.formatMessage({ id: 'generic.title' })} />
          </Form.Item>
          <Row style={{ width: '100%', display: 'block' }}>
            <Form.Item label={translator.formatMessage({ id: 'generic.description' })} colon={false} style={{ width: '100%' }} required={currentTaskValues ? false : true}>
              <ReactQuill
                ref={quillRef}
                placeholder={`${translator.formatMessage({ id: 'generic.enterTaskDescription' })}, ${translator.formatMessage({ id: 'generic.@ToMentionSpaceMember' })}.`}
                modules={modules}
                onChange={handleChangeQuill}
                defaultValue={currentTaskValues?.description}
                onKeyDown={event => {
                  if (maxAmountofCharactersAttained && event.key !== 'Backspace') {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Row justify="end">
              <Text type={form.description?.replace(/<[^>]*>?/gm, '').length >= descriptionMaxLength ? 'danger' : undefined}>
                {form.description?.replace(/<[^>]*>?/gm, '').length ?? 0} / {descriptionMaxLength}
              </Text>
            </Row>
          </Row>
        </Form>
        <Flex gap={'middle'}>
          <TaskSider setSelectedLinks={setSelectedLinks} selectedLinks={selectedLinks} onChange={onChange} values={form} />
        </Flex>
        <div style={{ position: 'absolute', bottom: '30px', zIndex: 99 }}>
          {currentTaskValues && isTaskContributor && (
            <Button danger onClick={() => setDeletionOpen && setDeletionOpen(true)}>
              <FormattedMessage id="generic.deleteTask" />
            </Button>
          )}
        </div>
      </Modal>
    );
  })
);

export { CreateTask };
