import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { App } from '@/modules/App';
import { SmallSpin } from '@/common/suspense';
import { ModulePath } from '@/common/types/Path';
import currentVersion from '../current-version.json';
import { RefreshModal } from '@/common/components/RefreshModal';
import getRemoteVersion from '@/api/getRemoteVersion';
import { IntlWrapper } from '@/core/IntlWrapper';
import { OnboardingRoutes } from '@/modules/Onboarding/Shared/routes';
import { SignUp } from '@/modules/Onboarding/Pages/SignUp/SignUp';
import { RecoilExternalStatePortal } from '@/core/RecoilExternalStatePortal';
import AppErrorBoundary from '@/modules/App/components/AppErrorBoundary';
import { ConfigProvider, ThemeConfig } from 'antd';
import { authConfig } from '@/config/authConfig';
import { CustomAuth0Provider } from '@/core/Auth0Utils';
import { AppProvider, CacheProvider, ThemeProvider } from 'ui-sesame-components';
import requestApi from './requestApi';
import 'ui-sesame-components/dist/assets/style.css';
import './styles.css';

const ReportBuilder = React.lazy(() => import('@/modules/report-builder'));
const ReportPrint = React.lazy(() => import('@/modules/Report/containers/ReportPrint'));

const MainProvider = () => {
  const [refreshModalOpen, setRefreshModalOpen] = useState(false);

  useEffect(() => {
    // poll to check if a new remote version is available
    const version = JSON.stringify(currentVersion);

    setInterval(() => {
      getRemoteVersion().then(newVersion => {
        const remoteVersion = JSON.stringify(newVersion);
        if (remoteVersion !== version) {
          setRefreshModalOpen(true);
        }
      });
    }, 300 * 1000); // 5mns
  }, []);

  return (
    <AppProvider>
      <CacheProvider requestApi={requestApi}>
        <ThemeProvider>
          <RecoilRoot>
            <IntlWrapper>
              <ConfigProvider theme={themeConfig}>
                <Router>
                  <CustomAuth0Provider clientId={authConfig.clientId} domain={authConfig.domain} authorizationParams={authConfig.authorizationParams}>
                    <Suspense fallback={SmallSpin}>
                      <AppErrorBoundary>
                        <Switch>
                          <Route path={ModulePath.REPORT_PRINT} exact component={ReportPrint} />
                          <Route path={OnboardingRoutes.SIGN_UP} component={SignUp} />
                          <Route path={ModulePath.REPORT_BUILDER} component={ReportBuilder} />
                          <App />
                        </Switch>
                      </AppErrorBoundary>
                      {refreshModalOpen && <RefreshModal isOpen={refreshModalOpen} setParentOpen={setRefreshModalOpen} />}
                    </Suspense>
                  </CustomAuth0Provider>
                </Router>
                <RecoilExternalStatePortal />
              </ConfigProvider>
            </IntlWrapper>
          </RecoilRoot>
        </ThemeProvider>
      </CacheProvider>
    </AppProvider>
  );
};

// todo: use only sesame-component theme config
const themeConfig: ThemeConfig = {
  hashed: false,
  cssVar: true,
  token: {
    blue: '#326BFF',
    colorPrimary: '#326BFF',
    colorLink: '#326BFF',
    colorTextBase: '#262626',
    colorTextHeading: '#001423',
    fontFamily: 'Lato, sans-serif',
    fontSizeSM: 12,
    lineHeightSM: 20 / 12, // ~= 1.67
    fontSize: 14,
    lineHeight: 22 / 14,
    fontSizeLG: 16,
    lineHeightLG: 24 / 16,
    fontWeightStrong: 700,
    fontSizeHeading1: 38,
    lineHeightHeading1: 46 / 38,
    fontSizeHeading2: 30,
    lineHeightHeading2: 38 / 30,
    fontSizeHeading3: 24,
    lineHeightHeading3: 32 / 24,
    fontSizeHeading4: 20,
    lineHeightHeading4: 28 / 20,
    fontSizeHeading5: 16,
    lineHeightHeading5: 24 / 16
  },
  components: {
    Menu: {
      subMenuItemBorderRadius: 8,
      subMenuItemBg: 'white'
    },
    Card: {
      borderRadiusLG: 12
    }
  }
};

export default MainProvider;
