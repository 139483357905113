import React from 'react';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { config } from './config';
import schema from './schema.json';
import { Column } from '@/modules/reporting-v2/core/Column';
import { Field } from '@/modules/reporting-v2/core/Field';
import { EntityParametersHandler } from '@/common/types/app/EntityParametersHandler';
import { findHoldingSetById } from '@/utils/findHoldingSet';
import { nanoid } from 'nanoid';
import { Tooltip } from 'antd';
import { customFormattingType } from '@/modules/reporting-v2/core/formatTypes';
import RawStatsTableConfig from './types';
import StatsTableConfig from './StatsTableConfig';
import { RawColumn } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { Row } from '@/modules/reporting-v2/types/VisualEngine';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import { MemoizedTable } from '@/modules/reporting-v2/core/components/MemoizedTable';
import { Mode } from '@/modules/reporting-v2/core/visuals/StatsTable/VisualSpecificProps';
import { MemoizedDataTablev2 } from '@/modules/reporting-v2/core/components/DataTablev2/DataTable';

type HeaderName = {
  label: string;
  customMode: boolean;
  shareClass: boolean;
};

type PathMap = {
  [newColumnFieldPath: string]: {
    [rowId: string]: string;
  };
};
/**
 *  Creating of the PathMap gives us an opportunity to get initial Column fieldDataPath by using rowId and actual column fieldDataPath
 *  and passing through table component getInitialDataPath() callback. This callback will be used in new table component to update initial
 *  data object when editing cell values.
 *
 *  Example of PathMap object:
 *
 *  const pathMap: PathMap = {
 *   'false/213/benchmark': {
 *     '9zAbD': 'allocation.allocations.marketValueRc',
 *     'Vof0w': 'performance.benchmark.alphaMonthly'
 *   },
 *   'false/213/holdingSet': {
 *     '9zAbD': 'allocation.allocations.marketValueRc',
 *     'Vof0w': 'performance.benchmark.alphaMonthly'
 *   }
 * }
 * */

class StatsTableField {
  static columnFieldSeparator = '/';
  field: string;

  constructor(field: string) {
    this.field = field;
  }

  static swapFieldNode(field: string, newNode: string) {
    const [index, , ...rest] = field.split(Field.fieldSeparator);

    return [index, newNode, ...rest].join(Field.fieldSeparator);
  }

  getNode() {
    return this.field.split(Field.fieldSeparator)[1];
  }

  getLeaf() {
    return this.field.split(Field.fieldSeparator).pop();
  }
}

class StatsTable extends Visual {
  static AllocationHoldingSetMetricsField = 'allocation.holdingSetMetrics';

  static modesMapping = {
    entity: { label: 'Entity', name: Mode.entity, field: 'holdingSet' },
    benchmark: { label: 'Benchmark', name: Mode.benchmark, field: 'benchmark' },
    shareClass: {
      label: 'ShareClass/Asset',
      name: Mode.shareClass,
      field: 'shareClass',
      assetField: 'asset_info_undated.assets.assetName'
    }
  };

  Loader = Loaders.Table;
  modeIsNotSelected = (this.props.visual as StatsTableConfig).mode.length === 0;

  static configMapper(visualConfig: RawStatsTableConfig) {
    const { columns, ...rest } = visualConfig;
    return {
      ...rest,
      columns: this.resolveColumns(columns, rest.mode)
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as StatsTableConfig;
  }

  getSchema() {
    return schema;
  }

  static resolveColumns(columns: RawColumn, modes: Mode[]) {
    let resolvedColumns = columns;
    if (modes.includes(Mode.shareClass)) {
      resolvedColumns = {
        ...resolvedColumns,
        defaultColumns: [StatsTable.modesMapping.shareClass.assetField, ...resolvedColumns.defaultColumns],
        columns: [StatsTable.modesMapping.shareClass.assetField, ...resolvedColumns.columns]
      };
    }
    return mapVisualConfigColumns(resolvedColumns);
  }

  getStatsVisual() {
    const { data, columns, pathMap } = this.modeIsNotSelected ? this.getStatsDataForModeNotSelected() : this.getStatsData();
    const getInitialDataPath = (newColumnPath: string, rowId: string) => {
      return pathMap[newColumnPath]?.[rowId];
    };

    return {
      ...this.props.visual,
      columns,
      data,
      getInitialDataPath,
      total: false,
      htmlEditableOnly: true
    };
  }

  formatValue(column: Column, value: Primitive, data = {}) {
    return VisualEngine.formatCell(value, column, data, false, undefined, this.context.reportConfiguration.config.numberLocale);
  }

  getStatsData() {
    const data = {
      rows: [],
      totals: {},
      totalsByEntity: new Map(),
      rowsByEntity: new Map()
    } as VisualEngine['data'];
    const tableColumns = this.getColumns();
    let pathMap: PathMap = {};

    const visualColumns = this.props.visual.columns;

    const fields = visualColumns
      .filter(column => column.field.getElasticPath() !== StatsTable.modesMapping.shareClass.assetField && column.isDefault && !column.mode)
      .map(col => new StatsTableField(col.fieldDataPath));

    const nodes = new Map();
    const leaves = new Set<string>();

    fields.forEach(field => {
      leaves.add(field.getLeaf()!);
      const node = field.getNode();

      if (nodes.has(node)) {
        nodes.get(node).add(field);
      } else {
        nodes.set(node, new Set().add(field));
      }
    });

    const dataColumns = new Map<string, Map<'modes' | 'shareClass' | 'default', Column>>();

    leaves.forEach(leaf => {
      const columns = visualColumns.filter(col => col.isDefault && !col.mode && col.fieldDataPath.endsWith(leaf))!;
      if (columns.length) {
        dataColumns.set(leaf, new Map([['default', columns[0]]]));

        columns.forEach(column => {
          const fieldMode = this.getFieldModeType(column.fieldDataPath);
          dataColumns.get(leaf)?.set(fieldMode, column);
        });
      }
    });
    dataColumns.forEach((dataColumn, key) => {
      const rowId = nanoid(5);
      const row = {
        __id__: rowId,
        cells: [dataColumn.get('default')!.headerConfig.displayName]
      } as Row;

      for (let i = 1; i < tableColumns.length; i++) {
        let dColumn = dataColumn.get(this.getFieldModeType(tableColumns[i].fieldDataPath));

        if (!dColumn) {
          dColumn = dataColumn.get('default')!;
        }

        const path = tableColumns[i].fieldDataPath;
        if (pathMap[path]) {
          pathMap = {
            ...pathMap,
            [path]: {
              ...pathMap[path],
              [rowId]: (dColumn as Column).fieldDataPath
            }
          };
        } else {
          pathMap[path] = {
            [rowId]: (dColumn as Column).fieldDataPath
          };
        }

        this.buildRow(tableColumns[i], dColumn, row);
      }

      data.rows.push(row);
    });

    return { data, columns: tableColumns, pathMap };
  }

  getStatsDataForModeNotSelected() {
    let pathMap: PathMap = {};
    const data = {
      rows: [],
      totals: {},
      totalsByEntity: new Map(),
      rowsByEntity: new Map()
    } as VisualEngine['data'];
    const tableColumns = this.getColumns();

    const visualColumns = this.props.visual.columns;

    visualColumns.forEach(dataColumn => {
      const rowId = nanoid(5);
      const row = {
        __id__: rowId,
        cells: [dataColumn.headerConfig.displayName]
      } as Row;

      for (let i = 1; i < tableColumns.length; i++) {
        const path = tableColumns[i].fieldDataPath;

        if (pathMap[path]) {
          pathMap = {
            ...pathMap,
            [path]: {
              ...pathMap[path],
              [rowId]: (dataColumn as Column).fieldDataPath
            }
          };
        } else {
          pathMap[path] = {
            [rowId]: (dataColumn as Column).fieldDataPath
          };
        }

        this.buildRow(tableColumns[i], dataColumn, row);
      }

      data.rows.push(row);
    });

    return { data, columns: tableColumns, pathMap };
  }

  buildRow(tableColumn: Column, dataColumn: Column, row: Row) {
    const splitField = tableColumn.fieldDataPath.split(StatsTableField.columnFieldSeparator);
    const newNode = splitField.pop(); // yes

    /**
     * If user set a value for "displayMode" in column options, that means that measure
     * was calculated only on benchmark or entity level.
     * * In this case we should display value only in Entity column only
     * */
    const isBenchmarkColumn = newNode === 'benchmark';

    if (isBenchmarkColumn && dataColumn.displayMode) {
      row.cells.push('' as Primitive);
      return;
    }

    const entityId = Number(splitField.pop()); // whoever is reviewing this - you did not see this, thanks
    const shareClassMode = JSON.parse(splitField.pop()!); // yes, and?
    const isShareClassColumn = dataColumn.fieldDataPath.toLowerCase().includes(StatsTable.modesMapping.shareClass.field.toLowerCase());
    const isAllocationHoldingSetMetricsField = dataColumn.fieldDataPath.toLowerCase().includes(StatsTable.AllocationHoldingSetMetricsField.toLowerCase());
    const data = this.props.visual.data.totalsByEntity.get(entityId);
    let field = StatsTableField.swapFieldNode(dataColumn.fieldDataPath, newNode!);
    const fieldPathIs2Level = dataColumn.fieldDataPath.split(Field.fieldSeparator).length === 2;

    if (!row.data) {
      row.data = data ?? {};
    }

    if (shareClassMode && isShareClassColumn) {
      const holdingSetIdField = this.props.visual.getPrimaryIndexNode()!.getEntityIdField();

      this.props.visual.data.rows.forEach(visualRow => {
        if (visualRow.data[holdingSetIdField!] === entityId) {
          row.cells.push(this.formatValue(dataColumn, visualRow.data[dataColumn.fieldDataPath], visualRow.data) as Primitive);
        }
      });

      return;
    }

    if (fieldPathIs2Level) {
      row.cells.push(this.formatValue(dataColumn, data?.[dataColumn.fieldDataPath], data) as Primitive);
      return;
    }

    let value = null;

    if (tableColumn.mode) {
      const col = this.props.visual.columns.find(column => column.isDefault && column.mode === tableColumn.mode && column.field.name === dataColumn.field.name);

      if (col) {
        value = this.formatValue(dataColumn, data?.[col.fieldDataPath], data);
      }
    } else {
      if (isAllocationHoldingSetMetricsField) {
        value = this.formatValue(dataColumn, data?.[dataColumn.fieldDataPath], data);
      } else {
        if (data && !(field in data) && !shareClassMode && !isShareClassColumn) {
          const dataColumnNode = dataColumn.getFieldNode();

          field = StatsTableField.swapFieldNode(field, dataColumnNode);
        }

        value = this.formatValue(dataColumn, data?.[field], data);
      }
    }

    row.cells.push(value as Primitive);
  }

  getFieldModeType(field: string): 'modes' | 'shareClass' {
    return field.toLowerCase().includes(StatsTable.modesMapping.benchmark.field.toLowerCase()) || field.toLowerCase().includes(StatsTable.modesMapping.entity.field.toLowerCase())
      ? 'modes'
      : 'shareClass';
  }

  getHeaderNames = () => {
    const names = [] as HeaderName[];
    const visual = this.props.visual as StatsTableConfig;

    if (visual.mode.includes(StatsTable.modesMapping.entity.name)) {
      const object = {
        label: StatsTable.modesMapping.entity.label,
        customMode: false,
        shareClass: false
      };

      names.push(object);
    }

    if (visual.mode.includes(StatsTable.modesMapping.benchmark.name)) {
      const object = {
        label: StatsTable.modesMapping.benchmark.label,
        customMode: false,
        shareClass: false
      };

      names.push(object);
    }

    if (visual.mode.includes(StatsTable.modesMapping.shareClass.name)) {
      visual.data.rows
        .filter(row => row.data[StatsTable.modesMapping.shareClass.assetField])
        .forEach(row => {
          const object = {
            label: row.data[StatsTable.modesMapping.shareClass.assetField] as string,
            customMode: false,
            shareClass: true
          };

          names.push(object);
        });
    }

    /** in case the mode was not selected, we should add a column to display all values */
    if (visual.mode.length === 0) {
      names.push({ label: '', customMode: false, shareClass: false });
    }

    this.props.visual.columns
      .filter(col => col.mode)
      .forEach(col => {
        const object = {
          label: col.mode!,
          customMode: true,
          shareClass: false
        };

        if (!names.some(name => name.label === object.label)) {
          names.push(object);
        }
      });

    return names;
  };

  getColumns() {
    const entities = this.getVisualEntities();

    const columns = [
      new Column({
        field: new Field(String()),
        isDefault: true,
        headerConfig: { displayName: undefined },
        formatting: { type: customFormattingType } as any
      })
    ];

    for (const { label: name, customMode, shareClass } of this.getHeaderNames()) {
      entities.forEach(entity => {
        const singleEntity = this.props.visual.entityOrdering.length <= 1;
        const displayName = singleEntity ? name : `${entity?.name} - ${name}`;
        const key = this.retrieveKeyFromLabel(name);
        const fieldName = `${shareClass}${StatsTableField.columnFieldSeparator}${entity?.id}${StatsTableField.columnFieldSeparator}${key && StatsTable.modesMapping[key].field}`;
        const tooltipTitle = singleEntity ? name : `${entity?.name} - ${name}`;

        const column = new Column({
          field: new Field(fieldName),
          isDefault: true,
          headerConfig: {
            displayName: <Tooltip title={tooltipTitle}>{displayName}</Tooltip>
          },
          formatting: { type: customFormattingType } as any,
          mode: customMode ? name : undefined
        });

        columns.push(column);
      });
    }

    return columns;
  }

  retrieveKeyFromLabel(name: string): Mode | undefined {
    if (name === StatsTable.modesMapping.entity.label) {
      return StatsTable.modesMapping.entity.name;
    }

    if (name === StatsTable.modesMapping.benchmark.label) {
      return StatsTable.modesMapping.benchmark.name;
    }

    return undefined;
  }

  getVisualEntities() {
    const urlParams = EntityParametersHandler.retrieveParamsList(this.props.currentUser, undefined, this.props.multiEntityFeatures);
    const tree = this.props.currentUser.holdingSetTree;

    return this.props.visual.entityOrdering.map(index => findHoldingSetById(tree, urlParams[index]?.holdingSetId)).filter(Boolean);
  }

  renderBody() {
    const visual = this.getStatsVisual() as IDataTableProps['visual'];
    if (this.props.visual.tableVersion === 'v1') {
      return <MemoizedTable visual={visual} />;
    }

    return <MemoizedDataTablev2 visual={visual} getInitialDataPath={visual.getInitialDataPath} />;
  }
}

export default StatsTable;
