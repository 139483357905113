import React, { useCallback, useEffect, useState } from 'react';
import routes from '@/config/routes';
import { SecurityUtils } from '@/utils/SecurityUtils';
import { SesameGif } from '@/common/SesameGif';
import { getSiteTree } from '@/api';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { siteTreeState } from '@/modules/App/recoil/app.atoms';
import { getMappedSiteTree } from '@/modules/App/getMappedSiteTree';
import { userState } from '@/modules/User/recoil/user.atoms';
import { getCurrentUser } from '@/modules/User/getCurrentUser';
import { checkpointsSelector, fullAccountSelector, loggedUserSelector } from '@/common/auth/recoil/user.selector';
import { withSmallSuspense } from '@/common/suspense';
import getInternalUser from '@/common/auth/api/getInternalUser';
import { isLandytechLogin } from '@/common/recoil/security.selectors';
import { handleDeletedSpace } from '@/utils/handleDeletedSpace';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { userHsetsSelector } from '@/recoil/holdingSets';
import { useAuth0 } from '@auth0/auth0-react';
import { LoggedUser } from '@/common/auth/api/login';

const screenCenterPositioning: React.CSSProperties = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)'
};

const landyTechAccountId = 'cafebabe-cafe-babe-cafe-babecafebabe';

const AuthProvider = withSmallSuspense(({ children }) => {
  const { isAuthenticated: isAuth0Authenticated } = useAuth0();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [user, setUser] = useRecoilState(userState);
  const setRecoilSiteTree = useSetRecoilState(siteTreeState);
  const loggedUser = useRecoilValue(loggedUserSelector) as LoggedUser | { failed: true };

  const dispatchSignIn = useCallback(() => {
    setUser(prevState => {
      return {
        ...prevState,
        authenticated: true
      };
    });
  }, [setUser]);

  const dispatchSetCurrentUser = useRecoilCallback(
    ({ snapshot }) =>
      async (loggedUser: LoggedUser) => {
        const [account, holdingSetTree, verifiedDates] = await Promise.all([
          snapshot.getPromise(fullAccountSelector(loggedUser.accountId)),
          snapshot.getPromise(userHsetsSelector),
          snapshot.getPromise(checkpointsSelector)
        ]);

        const user = await getCurrentUser(loggedUser, account, holdingSetTree, verifiedDates);

        handleDeletedSpace(user);
        let internalUser = null;

        if (isLandytechLogin(user.login) && !user.acting && 'id' in account && account.id === landyTechAccountId) {
          internalUser = await getInternalUser();
        }

        setUser(prevState => {
          return {
            ...prevState,
            currentUser: user,
            internalUser
          };
        });
      },
    [setUser]
  );

  const dispatchSetSiteTree = useCallback(
    (data: unknown) => {
      setRecoilSiteTree(getMappedSiteTree(data));
    },
    [setRecoilSiteTree]
  );

  const verifyAuth = async () => {
    const authenticated = isAuth0Authenticated;

    if (!authenticated) {
      await SecurityUtils.resetAuthentication();
    }

    if (authenticated !== isAuthenticated) {
      setIsAuthenticated(authenticated);

      initiateSession();
    }
  };

  const initiateSession = useCallback(() => {
    dispatchSignIn();

    getSiteTree().then(data => {
      dispatchSetSiteTree(data);
      dispatchSetCurrentUser(loggedUser as LoggedUser);
    });
  }, [dispatchSignIn, dispatchSetSiteTree, dispatchSetCurrentUser, loggedUser]);

  useEffect(() => {
    if (loggedUser && !('failed' in loggedUser)) {
      verifyAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  const renderContent = useCallback(() => {
    if ('failed' in loggedUser && window.location.pathname !== routes['50x'].path) {
      return (
        <div style={screenCenterPositioning}>
          <SesameGif />
          <div>
            <Typography.Title level={5} style={{ fontWeight: 400 }}>
              <FormattedMessage id="generic.login.somethingWentWrongDuringLoginProcess" />
            </Typography.Title>
          </div>
        </div>
      );
    }
    if (window.location.pathname === routes['50x'].path) {
      return children;
    }

    if (!isAuthenticated || (isAuthenticated && !user.currentUser)) {
      return <SesameGif style={screenCenterPositioning} />;
    } else {
      return children;
    }
  }, [loggedUser, user, children, isAuthenticated]);

  return renderContent();
});

export default AuthProvider;
