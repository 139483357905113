import { Dispatch, SetStateAction, createContext, useContext, useMemo, useState } from 'react';

type ExportContextWrapperProps = {
  children: React.ReactNode;
  defaultExportValue?: Exporting;
};

export type Exporting = 'pdf' | 'excel' | false;

type ExportContextValue = {
  isExporting: Exporting;
  setExporting: Dispatch<SetStateAction<Exporting>>;
};

export const ExportContext = createContext<ExportContextValue>({
  isExporting: false,
  setExporting: () => undefined
});

const useExportContext = () => {
  const filtersContext = useContext(ExportContext);

  return filtersContext;
};

const ExportContextWrapper: React.FC<ExportContextWrapperProps> = ({ children, defaultExportValue }) => {
  const [isExporting, setExporting] = useState<Exporting>(defaultExportValue ?? false);

  const contextValue = useMemo<ExportContextValue>(() => {
    return {
      isExporting,
      setExporting
    };
  }, [isExporting, setExporting]);

  return <ExportContext.Provider value={contextValue}>{children}</ExportContext.Provider>;
};

export { ExportContextWrapper, useExportContext };
