import { MetadataEntityType, MetadataValueDto } from '@/common/types/entity/Metadata';
import { UUID } from '@/common/types/types';
import { requestApi } from '@/core';

export default (entityType: MetadataEntityType, entityIds: string[], keyIds: UUID[]): Promise<Record<string, MetadataValueDto[]>> =>
  requestApi({
    method: 'POST',
    url: `/api/metadata/values/${entityType}/bulkGet`,
    data: {
      keyIds: keyIds,
      entityIds: entityIds
    },
    service: 'metadata'
  }).then(response => {
    return response.data;
  });
