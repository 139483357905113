import { DocumentStatus } from '../../../common/types/entity/DocumentStatus';
import { assetsFromValidatedHoldingSetsSelector } from '../api/getAssets';
import { DocumentTagType, SystemTag } from '../../../common/types/entity/DocumentTags';
import { userHsetsSelector } from '@/recoil/holdingSets';
import { RecoilValueReadOnly } from 'recoil';

export interface ViewConfig {
  title: string;
  tag: {
    custom: boolean;
    name?: string;
    type?: DocumentTagType;
  };
  status: DocumentStatus;
  tagTree: {
    display: boolean;
    entitiesSupplier?: RecoilValueReadOnly<any[]>;
  };
  documentsTable: {
    columns: {
      entity: boolean;
      asset: boolean;
    };
  };
}

export enum ViewTypeConfigTitle {
  ALL = 'All',
  RECENT_DOCUMENTS = 'Recent',
  ENTITY = 'By Entity',
  ASSET = 'By Asset',
  PROPERTIES = 'By Properties',
  RECYCLE_BIN = 'Recycle Bin',
  INBOX = 'Inbox'
}

export const InboxViewTypeConfig: ViewConfig = {
  title: ViewTypeConfigTitle.INBOX,
  status: DocumentStatus.CREATED,
  tag: {
    custom: false,
    name: SystemTag.Inbox
  },
  tagTree: {
    display: false
  },
};

export const ViewTypeConfig: ViewConfig[] = [
  // ALL
  {
    title: ViewTypeConfigTitle.ALL,
    tag: {
      custom: false,
      name: SystemTag.All
    },
    status: DocumentStatus.CREATED,
    tagTree: {
      display: false
    },
    documentsTable: {
      columns: {
        entity: true,
        asset: true
      }
    }
  },
  {
    title: ViewTypeConfigTitle.RECENT_DOCUMENTS,
    tag: {
      custom: false,
      name: SystemTag.RecentDocuments
    },
    status: DocumentStatus.CREATED,
    tagTree: {
      display: false
    },
    documentsTable: {
      columns: {
        entity: true,
        asset: true
      }
    }
  },
  // By Entity
  {
    title: ViewTypeConfigTitle.ENTITY,
    status: DocumentStatus.CREATED,
    tag: {
      custom: false,
      name: SystemTag.Entity,
      type: DocumentTagType.HOLDING_SET
    },
    tagTree: {
      display: true,
      entitiesSupplier: userHsetsSelector
    },
    documentsTable: {
      columns: {
        entity: false,
        asset: true
      }
    }
  },
  // By Asset
  {
    title: ViewTypeConfigTitle.ASSET,
    status: DocumentStatus.CREATED,
    tag: {
      custom: false,
      name: SystemTag.Asset,
      type: DocumentTagType.ASSET
    },
    tagTree: {
      display: true,
      entitiesSupplier: assetsFromValidatedHoldingSetsSelector
    },
    documentsTable: {
      columns: {
        entity: true,
        asset: false
      }
    }
  },
  // By Categories
  {
    title: ViewTypeConfigTitle.PROPERTIES,
    status: DocumentStatus.CREATED,
    tag: {
      custom: false
    },
    tagTree: {
      display: true
    },
    documentsTable: {
      columns: {
        entity: false,
        asset: false
      }
    }
  },
  // Recycle Bin
  {
    title: ViewTypeConfigTitle.RECYCLE_BIN,
    status: DocumentStatus.REFUSED,
    tag: {
      custom: false,
      name: SystemTag.RecycleBin
    },
    tagTree: {
      display: false
    },
    documentsTable: {
      columns: {
        entity: true,
        asset: true
      }
    }
  }
];

export const DEFAULT = ViewTypeConfig[1];
export const CUSTOM = ViewTypeConfig.find(cfg => cfg.tag.custom);
