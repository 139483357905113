import { VisualConfigProps } from '@/modules/reporting-v2/types/VisualEngine';

export default interface ExtendedLimitsTableConfig extends VisualConfigProps {
  category: Category[];
  status: Status[];
  groupBy: LimitsGroupBy[];
  decimalFormattingForNumber?: DecimalFormattingForNumber;
  decimalFormattingForMonetary?: DecimalFormattingForMonetary;
  decimalFormattingForPercentage?: DecimalFormattingForPercentage;
  collapsible: boolean;
  sortable: boolean;
  subTotal: boolean;
  hideLimitRule?: boolean;
  hideCategoryInLimitRule?: boolean;
}

export enum Category {
  ALLOCATION = 'Asset Allocations',
  HOLDING = 'Holdings',
  FLOW = 'Flows',
  PERFORMANCE = 'Performance',
  RISK = 'Risks',
  CONCENTRATION = 'Concentration',
  VALIDATION = 'Validation',
  UCITS = 'UCITS',
  PRP = 'PRP',
  ISDA = 'ISDA'
}

export enum Status {
  OK = 'OK',
  ALERT = 'Alert',
  INVALID = 'Invalid',
  FLOW = 'Flows'
}

export enum LimitsGroupBy {
  STATUS = 'status',
  CATEGORY = 'category',
  DATE = 'date',
  LIMIT_NAME = 'limitName'
}

export type DecimalFormattingForNumber = 0 | 1 | 2 | 3;
export type DecimalFormattingForMonetary = 0 | 1 | 2 | 3;
export type DecimalFormattingForPercentage = 0 | 1 | 2 | 3;
