import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import AuthProvider from '@/core/AuthProvider';
import { Layout, message } from 'antd';
import Helmet from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import routes, { getRoutes } from '@/config/routes';
import AppRedirect from './AppRedirect';
import ReportViewerService from '@/modules/reporting-v2/core/ReportViewerService';
import adapter from '@/modules/report-builder/utils/adapter';
import { FormattedMessage } from 'react-intl';
import { AuthSessionStorageManager } from '@/common/auth/storage/AuthSessionStorageManager';
import { constSelector, useRecoilValue, useSetRecoilState } from 'recoil';
import { isErpRoute, isRedirect, PageTemplate } from '@/utils/retrievePageTemplate';
import NoHoldingSets from '@/modules/App/containers/App/NoHoldingSets';
import { siteTreeEntryState, siteTreeState } from '@/modules/App/recoil/app.atoms';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import { SupportFlowWrapper } from '@/common/components/SupportFlowInfo/SupportFlowInfo';
import { webPageByIdSelector } from '@/modules/App/recoil/app.selectors';
import { spaceSelector } from '@/common/auth/recoil/user.selector';
import { SmallSpin } from '@/common/suspense';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { HubSpot } from '@/common/components/HubSpot/HubSpot';
import { ImplicitCallback } from '@/modules/App/containers/App/ImplicitCallback';
import cn from 'classnames';
import { NetworkError } from '@/common/auth/api/NetworkError';
import { useRootPath } from '@/modules/App/hooks/useRootPath';
import { ModulePath } from '@/common/types/Path';

import './style.css';

const Sider = React.lazy(() => import('@/modules/App/components/Sider'));
const CustomPage = React.lazy(() => import('@/modules/CustomPage'));
const AcceptCookies = React.lazy(() => import('@/modules/App/components/AcceptCookies'));
const Error404 = React.lazy(() => import('@/modules/App/components/Error404'));
const Header = React.lazy(() => import('@/modules/App/components/Header'));
const NewReportPrint = React.lazy(() => import('@/modules/AnalyticsReportViewer/print'));

const RouteComponentWrapper = ({ route: _route, noHoldingSets, ...props }) => {
  const route = useMemo(() => {
    const routeChildrenKeys = Object.keys(_route.children ?? {});

    if (!_route.component && routeChildrenKeys.length === 1) {
      return _route.children[routeChildrenKeys[0]];
    }

    return _route;
  }, [_route]);

  const { component, label } = route;
  const [routeSet, setRouteSet] = useState(false);
  /** extendedRoute is a route extended by configuration fetched from the server */
  const [extendedRoute, setExtendedRoute] = useState(null);
  const [ready, setReady] = useState(false);
  const Component = useRef();
  const setSiteTreeEntry = useSetRecoilState(siteTreeEntryState);
  const webPage = useRecoilValue(extendedRoute ? constSelector(null) : webPageByIdSelector(route.webPageId));

  useEffect(() => {
    // how is that even possible? 🤨
    if (ready && component && Component.current?.toString() === '() => null') {
      setReady(false);
      setRouteSet(false);
      setExtendedRoute(null);
    }

    if (ready) {
      return;
    }

    if (typeof component === 'string') {
      const comps = import.meta.glob('../../../**/*.ts');
      const match = comps[`../../../${component}/module.ts`];

      if (!match) {
        message.error(`Module ${component} couldn't be found.`);
        return;
      }

      /* @vite-ignore */
      match?.().then(({ default: _Component }) => {
        Component.current = _Component;
        setReady(true);
      });
      return;
    }

    Component.current = component;
    if (!component) {
      Component.current = () => null;
    }
    setReady(true);
  }, [ready, setReady, component, Component]);

  useEffect(() => {
    if (extendedRoute || !webPage) {
      return;
    }

    const parsedConfiguration = webPage.configuration ? adapter(JSON.parse(webPage.configuration)) : undefined;

    setExtendedRoute({
      ...route,
      data: parsedConfiguration
    });
  }, [extendedRoute, route, webPage]);

  useEffect(() => {
    if (extendedRoute) {
      setSiteTreeEntry(extendedRoute);
      setRouteSet(true);
    }
  }, [extendedRoute, route, setSiteTreeEntry]);

  const componentIsReady = extendedRoute && routeSet && ready;

  if (componentIsReady && noHoldingSets && !isErpRoute(route.view) && !isRedirect(route.view)) {
    return <NoHoldingSets formattedMessageId={'generic.youCurrentlyDoNotHaveAccessToAnyEntity'} />;
  }

  return componentIsReady ? (
    <>
      {label && <Helmet title={`Sesame - ${label}`} />}
      <Component.current {...props} route={extendedRoute} />
    </>
  ) : null;
};

const SecuredRoute = withAuthenticationRequired(props => {
  return <Route {...props} />;
});

const appRoute = (name, route, noHoldingSets) => {
  const { path, shouldSignIn, exact = false, children } = route;

  const RouteComponent = shouldSignIn ? SecuredRoute : Route;

  return [
    <RouteComponent
      key={name}
      exact={exact}
      path={path}
      render={props => {
        return <RouteComponentWrapper {...props} route={route} noHoldingSets={noHoldingSets} />;
      }}
    />
  ].concat(children && Object.entries(children).map(([name, route]) => appRoute(name, route, noHoldingSets)));
};

const App = () => {
  const [ready, setReady] = useState(false);
  const currentUser = useRecoilValue(currentUserSelector);
  const space = useRecoilValue(ready ? spaceSelector : constSelector(null));
  const siteTree = useRecoilValue(siteTreeState);
  const currentSiteEntry = useRecoilValue(siteTreeEntryState);
  const { user } = useAuth0();
  const rootPath = useRootPath();

  const siteTreeRoutes = useMemo(() => {
    return rootPath ? siteTree?.filter(item => item.path.startsWith(rootPath) && item.view !== PageTemplate.APP_MENU_V3) : [];
  }, [rootPath, siteTree]);

  const enableSider = useMemo(() => {
    if (currentSiteEntry?.view === PageTemplate.NOTIFICATIONS) {
      return false;
    }

    if (!siteTreeRoutes) {
      return false;
    }

    if (![PageTemplate.TOP_LEVEL_NAVIGATION_ITEM, PageTemplate.TOP_LEVEL_NAVIGATION_ITEM_SIDER_V3].includes(siteTreeRoutes[0]?.view)) {
      return true;
    }

    return siteTreeRoutes[0].children.length > 1;
  }, [siteTreeRoutes, currentSiteEntry]);

  const blankMainContainer = useMemo(
    () =>
      [
        PageTemplate.REPORTS_CENTRE_V3,
        PageTemplate.DOCUMENT_MANAGEMENT_SYSTEM,
        PageTemplate.CUSTODIAL_DATA_API_TOKEN,
        PageTemplate.CUSTODIAL_DATA_LOGS,
        PageTemplate.CUSTODIAL_DATA_ENTITY_LIST,
        PageTemplate.CUSTODIAL_DATA_DASHBOARD,
        PageTemplate.CUSTODIAL_DATA_API_DOCS,
        PageTemplate.CONTACTS_LIST,
        PageTemplate.CONTACTS_LIST_COMPANIES,
        PageTemplate.TASK_MANAGEMENT_SYSTEM,
        PageTemplate.NOTE_MANAGEMENT_SYSTEM,
        PageTemplate.PORTFOLIO_VIEW,
        PageTemplate.ASSET_VIEW,
        PageTemplate.PORTFOLIO_VIEW_LEGAL_ENTITY,
        PageTemplate.LEGAL_ENTITY_V2_VIEW,
        PageTemplate.CUSTODIED_PORTFOLIO_V2_VIEW,
        PageTemplate.USER_PORTFOLIO_V2_VIEW,
        PageTemplate.ASSET_V2_VIEW,
        PageTemplate.REVIEW_BOARD_V2_VIEW,
        PageTemplate.MESSAGES
      ].includes(currentSiteEntry?.view),
    [currentSiteEntry?.view]
  );
  const erpContainerStyles = useMemo(
    () =>
      [
        PageTemplate.CONTACTS_LIST,
        PageTemplate.CONTACTS_LIST_COMPANIES,
        PageTemplate.TASK_MANAGEMENT_SYSTEM,
        PageTemplate.NOTE_MANAGEMENT_SYSTEM,
        PageTemplate.PORTFOLIO_VIEW,
        PageTemplate.ASSET_VIEW,
        PageTemplate.PORTFOLIO_VIEW_LEGAL_ENTITY,
        PageTemplate.LEGAL_ENTITY_V2_VIEW,
        PageTemplate.CUSTODIED_PORTFOLIO_V2_VIEW,
        PageTemplate.USER_PORTFOLIO_V2_VIEW,
        PageTemplate.ASSET_V2_VIEW,
        PageTemplate.MESSAGES,
        PageTemplate.REVIEW_BOARD_V2_VIEW
      ].includes(currentSiteEntry?.view),
    [currentSiteEntry?.view]
  );

  const mainContainerStyles = useMemo(() => {
    const obj = {};
    if (currentSiteEntry?.view === PageTemplate.NOTIFICATIONS) {
      obj.overflowY = 'scroll';
    }
    if (erpContainerStyles) {
      obj.borderRadius = '12px';
      obj.backgroundColor = '#ffffff';
    }
    return obj;
  }, [currentSiteEntry, erpContainerStyles]);

  const email = user?.email;

  useEffect(() => {
    window._hsq = window._hsq = window._hsq || [];

    window._hsq.push([
      'identify',
      {
        email
      }
    ]);
  }, []);

  useEffect(() => {
    if (!ready) {
      /* readPersisted must be called first, before all the acting/impersonation stuff */
      /* persistUser must be called after the load of local storage persisted headers */

      AuthSessionStorageManager.readPersisted(email);
      AuthSessionStorageManager.readSessionInitiation();

      AuthSessionStorageManager.persistUser(email);
      setReady(true);
    }
  }, [ready, setReady, email]);

  if (!ready) {
    return null;
  }

  const noHoldingSets = currentUser?.holdingSetIds?.length <= 0;

  const isSuperAdmin = currentUser?.isSuperAdmin;
  const hasNoLicensesAvailable = space instanceof NetworkError && space.props?.statusCode === 403;
  const hasNoSpacesAvailable = space === null;

  if (hasNoLicensesAvailable && currentUser?.availableSpaceIds?.length > 0) {
    const formattedMessage = <FormattedMessage id={'generic.noLicensesAvailable'} />;

    return <NoHoldingSets formattedMessage={formattedMessage} />;
  }

  if (hasNoSpacesAvailable) {
    let formattedMessage = undefined;

    if (isSuperAdmin) {
      const baseAdminUrl = import.meta.env.VITE_BASE_ADMIN_URL;
      const linkUrl = `${baseAdminUrl}account/spaces?${AuthSessionStorageManager.buildUrlSearchParams()}`;

      formattedMessage = (
        <FormattedMessage
          id="generic.createOrAddYourselfASpace"
          values={{
            link: (
              <a href={linkUrl} target="_blank" rel="noreferrer">
                <FormattedMessage id="generic.adminRedirectLabel" />
              </a>
            )
          }}
        />
      );
    } else {
      formattedMessage = <FormattedMessage id={'generic.askAdminToAddYouToASpace'} />;
    }

    return <NoHoldingSets formattedMessage={formattedMessage} />;
  }

  const isActing = AuthSessionStorageManager.isActing();

  return (
    <AuthProvider>
      <Switch>
        <Route path={routes['50x'].path} component={routes['50x'].component} />
        <Route path={ModulePath.NEW_REPORT_PRINT} exact component={NewReportPrint} />
        <Route path="/:type/">
          <SupportFlowWrapper>
            <Layout style={{ height: '100vh' }}>
              {/* <UpgradeModal closable={false} visible={upgradeModalVisible} onOk={() => setUpgradeModalVisible(false)} /> */}
              <Layout className="sesame-secondary-bg">
                <Header />
                <Layout id="main-container" className={cn({ blank: blankMainContainer })}>
                  {enableSider && <Sider routes={siteTreeRoutes} />}
                  <Layout.Content style={mainContainerStyles}>
                    <Suspense fallback={SmallSpin}>
                      <ReportViewerService>
                        <Switch>
                          {Object.entries(getRoutes(siteTree ?? [], false)).map(([name, route]) => appRoute(name, route, noHoldingSets))}
                          <Route path="/:params*/custom-page/:id" render={() => <CustomPage gridToolbar={false} />} />
                          <Route path={new URL(import.meta.env.VITE_AUTH_REDIRECT_URI).pathname} component={ImplicitCallback} />
                          <Route component={Error404} />
                        </Switch>
                      </ReportViewerService>
                    </Suspense>
                  </Layout.Content>
                </Layout>
              </Layout>
              {!isActing && <AcceptCookies />}
              {ready && !isActing && <HubSpot />}
            </Layout>
          </SupportFlowWrapper>
        </Route>
        <Route exact path="/" component={AppRedirect} />
      </Switch>
    </AuthProvider>
  );
};

export default withAuthenticationRequired(App);
