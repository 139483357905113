import React from 'react';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { config } from './config';
import schema from './schema.json';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { Column, ColumnFormatting } from '@/modules/reporting-v2/core/Column';
import { Field } from '@/modules/reporting-v2/core/Field';
import LimitsCallOutsConfig from './LimitsCallOutsConfig';
import RawLimitsCallOutsConfig from './types';
import { Category } from './VisualSpecificProps';
import { FormatType } from '../../../../../common/types/elastic/FormatType';

export const colors = {
  OK: '#59b38f',
  INVALID: '#bfbfbf',
  ALERT: '#f5222d',
  WARNING: '#FFBF00'
};

export const labels = {
  OK: 'Compliant',
  INVALID: 'Not Applicable',
  ALERT: 'Not Compliant',
  WARNING: 'Warning'
};

type Status = keyof typeof colors;

class LimitsCallOuts extends Visual {
  Loader = Loaders.CallOut;

  getConfig() {
    return Visual.merge(config, super.getConfig()) as LimitsCallOutsConfig;
  }

  getSchema() {
    return schema;
  }

  renderInteractivity() {
    return super.renderInteractivity().slice(1);
  }

  renderTitle() {
    return null;
  }

  static configMapper(visualConfig: RawLimitsCallOutsConfig) {
    return {
      ...visualConfig,
      columns: mapVisualConfigColumns({
        columns: ['limits.*', 'limits.limits.name'],
        defaultColumns: ['limits.limits.name'],
        filters: {},
        options: {}
      })
    };
  }

  getCategoryStatus(category: Category): Status {
    const data = this.props.visual.data.rows.filter(row => {
      if (!row.data['limits.limits.category']) return false;
      return (row.data['limits.limits.category'] as string).toLowerCase().trim() === category?.toLowerCase().trim();
    });

    let status = 'INVALID' as Status;

    if (data.find(row => row.data['limits.limits.valueStatus'] === 'ALERT')) {
      status = 'ALERT';
    } else if (data.find(row => row.data['limits.limits.valueStatus'] === 'WARNING')) {
      status = 'WARNING';
    } else if (data.find(row => row.data['limits.limits.valueStatus'] === 'OK')) {
      status = 'OK';
    }

    return status;
  }

  renderBody() {
    const column = new Column({
      field: new Field('limits.limits.valueStatus'),
      formatting: new ColumnFormatting(undefined, FormatType.limits) // todo: check with this is possible
    });

    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          alignContent: 'space-between',
          justifyContent: 'center'
        }}
      >
        {(this.props.visual as LimitsCallOutsConfig).category.map(category => {
          const status = this.getCategoryStatus(category);

          return (
            <div
              key={category}
              style={{
                minWidth: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: colors[status],
                marginRight: '2rem',
                fontWeight: 500
              }}
            >
              <div style={{ fontSize: '1.15rem' }}>
                {(this.getSchema() as any).fields['group_fields-data'].fields.category.options[category] || category}
                <span
                  style={{
                    marginLeft: '0.5rem',
                    verticalAlign: '-0.1rem'
                  }}
                >
                  {VisualEngine.formatCell(status, column)}
                </span>
              </div>
              <div style={{ fontSize: '0.85rem' }}>{labels[status]}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default LimitsCallOuts;
