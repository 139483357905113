import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { PerformanceTableMapped } from './types';
import ExtendedPerformanceTableConfig, { Frequencies } from './VisualSpecificProps';

export default class PerformanceTableConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as PerformanceTableMapped));
  }

  getVisualConfigProps(config: PerformanceTableMapped): ExtendedPerformanceTableConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      frequencies: config.frequencies,
      transpose: config.transpose
    });
  }

  setVisualConfig(config: ExtendedPerformanceTableConfig) {
    super.setVisualConfig(config);
    this.frequencies = config.frequencies;
    this.transpose = config.transpose;
  }

  frequencies: Frequencies;
  transpose: boolean;
}
