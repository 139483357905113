import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { config } from './config';
import schema from './schema.json';
import RawAssetAllocationInfoConfig from './types';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';

class AssetAllocationInfo extends Visual {
  Loader = Loaders.Table;

  static configMapper(visualConfig: RawAssetAllocationInfoConfig) {
    const { rows, ...rest } = visualConfig;
    return {
      ...rest,
      columns: mapVisualConfigColumns(rows)
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as VisualEngine;
  }

  getSchema() {
    return schema;
  }

  renderBody() {
    return <DataTable visual={this.props.visual as IDataTableProps['visual']} />;
  }
}

export default AssetAllocationInfo;
