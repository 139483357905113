import { IParams } from '@/common/types/app/Params';
import React from 'react';
import { Field } from '@/modules/reporting-v2/core/Field';
import { Filter } from '@/modules/reporting-v2/core/Filter';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { RawReportConfig, ReportOrientation } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { InitProps, Preferences, VisualID } from '@/modules/reporting-v2/types/ReportViewerServiceTypes';
import { ExcelVisual } from '@/utils/excel';
import { Dict } from '@/modules/reporting-v2/types/Common';
import { DateRange } from '@/modules/reporting-v2/types/DateRange';
import type { HtmlPrintObject, PrintPayload } from '@/modules/reporting-v2/types/Print';
import { ReportViewerConfig } from './ReportViewerConfig';
import { FilterOperator } from '@/types/Filters';
import SiteTreeEntry from '@/common/types/entity/SiteTreeEntry';

export interface IContext {
  exporting: false | 'pdf' | 'excel';
  setExporting?: (exporting: false | 'pdf' | 'excel') => void;
  ready: boolean;
  loading: boolean;
  filters: Filter[];
  params?: IParams;
  globalFilters: Filter[];
  lastUpdated: Dict<number>;
  reportId?: string;
  visuals?: Map<VisualID, VisualEngine>;
  preferences?: Preferences;
  editMode?: string;
  orientation?: ReportOrientation;
  title?: string;
  siteTreeEntry?: SiteTreeEntry;
  setParams?: (params: IParams) => void;
  broadcastFiltersChange?: (crossFilters: Filter[]) => void;
  refreshVisuals?: (id?: string) => void;
  triggerVisualRefetch?: (visualId: string | Array<string>, holdingSetId?: number, dateRange?: DateRange) => Promise<void>;
  clearLoadingVisuals?: () => void;
  init?: (props: InitProps) => Promise<void>;
  removeFilter?: (field: Field, value?: Primitive) => void;
  addFilter?: (
    filters: { field: Field; value: Primitive | Primitive[] }[],
    selectBoxId?: string,
    isDefault?: boolean,
    additionalFieldsToFilter?: string[],
    filterOperator?: FilterOperator
  ) => void;
  replaceFilter?: (filters: { field: Field; value: Primitive | Primitive[] }[], selectBoxId?: string, additionalFieldsToFilter?: string[], filterOperator?: FilterOperator) => void;
  toggleFilter?: (field: Field, value: Primitive) => void;
  getHTML?: (reportConfig?: RawReportConfig) => Promise<HtmlPrintObject>;
  getExcel?: (context: IContext) => ExcelVisual[];
  print?: (content: HtmlPrintObject, container?: HTMLElement) => PrintPayload;
  activateEditMode?: (id: VisualID) => void;
  deactivateEditMode?: () => void;
  updatePreferences?: (id: VisualID, preferences: Preferences, visualVersion?: string) => Promise<number | undefined>;
  setPage?: (pageId: string) => void;
  setVisualsLoading?: (visuals: Array<VisualEngine>) => void;
  setReady?: (ready: boolean) => void;
  multiHoldingSetEnabled?: boolean;
  loadingVisuals: Map<VisualID, boolean>;
  reportConfiguration?: RawReportConfig;
  getReportConfiguration?: () => ReportViewerConfig | undefined;
  filteredPages: string[];
  visualsHaveNoData: string[];
  updateVisualsHasNoData?: (id: string, hasNoData: boolean) => void;
  clearVisualsHaveNoData?: () => void;
}

const ReportContext = React.createContext<IContext>({
  exporting: false,
  ready: false,
  loading: false,
  filters: [],
  globalFilters: [],
  lastUpdated: {},
  loadingVisuals: new Map(),
  filteredPages: [],
  visualsHaveNoData: []
});

const MultiEntityFeatureContext = React.createContext<boolean | undefined>(undefined);

export { MultiEntityFeatureContext };
export default ReportContext;
