import type { UUID } from '@/common/types/types';
import { requestApi } from '@/core';
export interface InternalLoggedUser {
  login: string;
  userId: UUID;
  token: string;
  expiresAt: string;
  qaRoleExtendedForTest: boolean;
  roles: string[];
}

export default (): Promise<InternalLoggedUser> => {
  return requestApi({
    url: 'internal/api/login',
    service: 'gateway',
    method: 'get'
  }).then(resp => {
    return resp?.data;
  });
};
