import type { PageTemplate } from '@/utils/retrievePageTemplate';

const views: Partial<Record<PageTemplate, string>> = {
  MESSAGES: 'Messages',
  RISKMETRICS: 'RiskMetrics',
  OWNERSHIP: 'Ownership',
  CHARTS: 'Charts',
  SINGLE_REPORT: 'Report',
  REPORTS_CENTRE_V3: 'ReportCentre',
  TERMS_AND_CONDITIONS: 'Legals',
  REDIRECT: 'Redirect',
  ASSET_VIEW: 'ERP/Asset/AssetDetails',
  PORTFOLIO_VIEW: 'ERP/Portfolio',
  PORTFOLIO_VIEW_LEGAL_ENTITY: 'ERP/Portfolio',
  LEGAL_ENTITY_V2_VIEW: 'ERP/PortfolioV2',
  ASSET_V2_VIEW: 'ERP/AssetV2',
  DOCUMENT_MANAGEMENT_SYSTEM: 'DMS',
  NOTIFICATIONS: 'Notifications',
  TASK_MANAGEMENT_SYSTEM: 'TMS',
  NOTE_MANAGEMENT_SYSTEM: 'NMS',
  CONTACTS_LIST: 'ERP/Contacts',
  CONTACTS_LIST_COMPANIES: 'ERP/Contacts',
  ONBOARDING_ADD_ASSETS: 'Onboarding',
  ONBOARDING_OWNERSHIP_MAP: 'Onboarding/Pages/OwnershipMap',
  CUSTODIAL_DATA_API_TOKEN: 'CustodialData/Pages/ApiTokens',
  CUSTODIAL_DATA_DASHBOARD: 'CustodialData/Pages/Dashboard',
  CUSTODIAL_DATA_API_DOCS: 'CustodialData/Pages/Docs',
  CUSTODIAL_DATA_ENTITY_LIST: 'CustodialData/Pages/EntityList',
  CUSTODIAL_DATA_LOGS: 'CustodialData/Pages/Logs',
  ANALYTICS_REPORT: 'AnalyticsReportViewer',
  CUSTODIED_PORTFOLIO_V2_VIEW: 'ERP/CustodiedPortfolio',
  USER_PORTFOLIO_V2_VIEW: 'ERP/UserPortfolio',
  REVIEW_BOARD_V2_VIEW: 'ERP/ReviewBoardV2'
};

export default views;
