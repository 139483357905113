import { requestApi } from '@/core';
import type { AxiosResponse } from 'axios';
import type { QuintileScore, QuintileScorePayload } from '@/modules/reporting-v2/core/visuals/Gauge/types';

export const getQuintileScore = (data: QuintileScorePayload): Promise<null | QuintileScore> => {
  return requestApi({
    method: 'get',
    url: 'api/stats/quintile-score',
    service: 'holdingset',
    errors: false,
    params: {
      ...data
    }
  }).then((response: AxiosResponse | undefined) => response?.data);
};
