import Cookie from 'js-cookie';
import { AuthHeader } from './AuthSessionStorageManager';

export type CookieDataType = {
  [AuthHeader.ACCOUNT_ID]: string;
  [AuthHeader.SPACE_ID]?: string;
};

const userInfoStorageKey = 'last-auth0-user-email-info';

const domain = import.meta.env['VITE_AUTH_COOKIE_DOMAIN'];

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AuthCookieStorageManager {
  const setCookie = (key: string, value: string) => {
    Cookie.set(key, value, { secure: true, domain, expires: 7 });
  };
  export const setUserInfo = (email: string) => setCookie(userInfoStorageKey, email);
  export const getUserInfo = () => Cookie.get(userInfoStorageKey);
  export const clear = () => {
    const lastUser = AuthCookieStorageManager.getUserInfo();
    if (lastUser) {
      Cookie.remove(lastUser);
    }
  };
  export const set = (email: string, data: CookieDataType) => {
    setCookie(`${email}-${domain}`, JSON.stringify(data));
  };

  export const get = (email: string): CookieDataType | undefined => {
    const data = Cookie.get(`${email}-${domain}`);
    if (!data) {
      return undefined;
    }
    return JSON.parse(data);
  };
}
