import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { ComponentTemplateLookup } from '../../../common/types/entity/ComponentTemplate';

export default (): Promise<ComponentTemplateLookup[]> =>
  requestApi({
    service: 'cms',
    method: 'get',
    url: `/api/custom-components`
  }).then((response: AxiosResponse<ComponentTemplateLookup[]> | undefined) => response?.data!);
