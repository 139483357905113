import { selectorFamily } from 'recoil';
import { UserLocalizedText } from '@/common/types/app/User';
import { getUserLocaleText } from '@/api/getUserLocaleText';
import { retrieveUserLocale } from '@/utils/locale';

const userLocaleTextSelector = selectorFamily<UserLocalizedText, string>({
  key: 'user-localized-text-selector',
  get:
    (userLocale: string = retrieveUserLocale()) =>
    () => {
      return getUserLocaleText(userLocale);
    }
});

export { userLocaleTextSelector };
