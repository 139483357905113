import { ExternalAccountLookup } from '@/common/types/entity/Account';
import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';

export default (): Promise<Array<ExternalAccountLookup>> => {
  return requestApi({
    url: `/api/accounts`,
    service: 'account',
    method: 'get'
  }).then((response: AxiosResponse<Array<ExternalAccountLookup>> | undefined) => {
    return response!.data;
  });
};
