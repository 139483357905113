import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedHistoricalMonthlyTabConfig } from '..';
import { HistoricalMonthlyTabConfigMapped } from './types';
import { DataFrequency, DateRange } from './VisualSpecificProps';
import { SortOrder } from '@/modules/reporting-v2/types/VisualEngine';

export default class HistoricalMonthlyTabConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as HistoricalMonthlyTabConfigMapped));
  }

  getVisualConfigProps(config: HistoricalMonthlyTabConfigMapped): ExtendedHistoricalMonthlyTabConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      transposeMonthlyTab: config.transposeMonthlyTab,
      dateRange: config.dateRange,
      totalMetric: config.totalMetric,
      dataFrequency: config._dataFrequency,
      transposedMonthOrder: config.transposedMonthOrder,
      subTotal: config.subTotal,
      showGrandTotal: config.showGrandTotal
    });
  }

  setVisualConfig(config: ExtendedHistoricalMonthlyTabConfig) {
    super.setVisualConfig(config);
    this.totalMetric = config.totalMetric;
    this.transposeMonthlyTab = config.transposeMonthlyTab;
    this.dateRange = config.dateRange;
    this.dataFrequency = config.dataFrequency;
    this.transposedMonthOrder = config.transposedMonthOrder;
    this.subTotal = config.subTotal;
    this.showGrandTotal = config.showGrandTotal;
  }

  transposeMonthlyTab: boolean;
  dateRange: keyof DateRange;
  totalMetric: string | undefined;
  dataFrequency: DataFrequency;
  transposedMonthOrder?: SortOrder;
  subTotal?: boolean;
  showGrandTotal?: boolean;
  collapsible?: boolean;
}
