import { HistoricChartOptions } from '@/modules/reporting-v2/core/components/Highcharts/types';
import { HighChartsDataUtils } from '@/modules/reporting-v2/core/HighchartsDataUtils';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedHistoricalChartConfig } from '..';
import { HistoricalChartAxis, HistoricalChartConfigMapped } from './types';
import { DataPointsFrequency, DateRange, Orientation, Serie, TickRate } from './VisualSpecificProps';

export default class HistoricalChartConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as HistoricalChartConfigMapped));
  }

  getVisualConfigProps(config: HistoricalChartConfigMapped): ExtendedHistoricalChartConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      dateRange: config.dateRange,
      startDate: config.startDate,
      dateFormat: config.dateFormat,
      orientation: config.orientation,
      compareValues: config._compareValues,
      compareBase: config.compareBase,
      alignAxisToCenter: config.alignAxisToCenter,
      connectGaps: config.connectGaps,
      tickRate: config.tickRate,
      chartOptions: {
        ...config._options,
        legend: {
          ...(config._options?.legend as object),
          enabled: config.legend
        }
      },
      datapointsFrequency: config.datapointsFrequency,
      series: config.series,
      isEntityBenchmark: config.isEntityBenchmark,
      stacked: config._isStacked,
      stockTools: {
        enabled: config._enableStockTools
      },
      showTopSeries: config.showTopSeries,
      diagonalAxisLabel: config.diagonalAxisLabel,
      yAxisInterval: config.yAxisInterval,
      minValue: config.minValue,
      maxValue: config.maxValue,
      showMarkers: config.showMarkers,
      showValueInsideBars: config.showValueInsideBars,
      xAxis: config.xAxis,
      yAxis: config.yAxis,
      customSort: config.customSort
    });
  }

  setVisualConfig(config: ExtendedHistoricalChartConfig) {
    super.setVisualConfig(config);
    this.dateRange = config.dateRange;
    this.startDate = config.startDate;
    this.dateFormat = config.dateFormat;
    this.orientation = config.orientation;
    this.compareValues = config.compareValues;
    this.compareBase = config.compareBase;
    this.alignAxisToCenter = config.alignAxisToCenter;
    this.connectGaps = config.connectGaps;
    this.tickRate = config.tickRate;
    this.datapointsFrequency = config.datapointsFrequency;
    this.series = config.series;
    this.chartOptions = HighChartsDataUtils.getOptionsFromGoogle<HistoricChartOptions>(config.chartOptions as object);
    this.crossFilterEmitter = false;
    this.stacked = config.stacked;
    this.stockTools = {
      enabled: config.stockTools?.enabled
    };
    this.showTopSeries = config.showTopSeries;
    this.diagonalAxisLabel = config.diagonalAxisLabel;
    this.yAxisInterval = config.yAxisInterval;
    this.minValue = config.minValue;
    this.maxValue = config.maxValue;
    this.showMarkers = config.showMarkers;
    this.showValueInsideBars = config.showValueInsideBars;
    this.xAxis = config.xAxis;
    this.yAxis = config.yAxis;
    this.customSort = config.customSort;
  }

  dateRange: DateRange;
  startDate?: string;
  dateFormat?: string;
  orientation: Orientation;
  tickRate: TickRate;
  compareValues: boolean;
  compareBase: '0' | '100' | undefined;
  alignAxisToCenter?: boolean;
  connectGaps: boolean;
  datapointsFrequency?: DataPointsFrequency;
  chartOptions?: HistoricChartOptions;
  series?: Serie;
  isEntityBenchmark?: boolean;
  stacked?: boolean;
  stockTools?: {
    enabled?: boolean;
  };
  showTopSeries?: number;
  diagonalAxisLabel: boolean;
  yAxisInterval?: number;
  minValue?: number;
  maxValue?: number;
  showMarkers?: boolean;
  showValueInsideBars?: boolean;
  xAxis?: HistoricalChartAxis;
  yAxis?: HistoricalChartAxis;
  customSort?: string[];
}
