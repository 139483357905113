import React from 'react';
import { rbColorToHexAlpha, toHexAlpha } from '@/utils/toHexAlpha';
import { removeEmpty } from '@/utils/removeEmpty';
import type { RawReportStylesConfig } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import type { StylesConfig } from '@/modules/reporting-v2/types/VisualEngine';
import type { RBFont } from '@/modules/report-builder/components/Forms/Visual/Schema/FontWrapper';
import type { RBBorder } from '@/modules/report-builder/components/Forms/Visual/Schema/BorderWrapper';
import type { RBColor } from '@/modules/report-builder/components/Forms/Visual/Schema/ColorPickerWrapper';

export const parseFontStyle = (fontStyle?: RBFont): React.CSSProperties => {
  if (!fontStyle?.fontStyle) return {};
  switch (fontStyle.fontStyle) {
    case 'bold':
    case 'normal':
      return { fontWeight: fontStyle.fontStyle };
    default:
      return { fontStyle: fontStyle.fontStyle };
  }
};

const rbBorderToBorder = (border?: RBBorder, borderColor?: RBColor): React.CSSProperties => {
  if (!border || (!border.style && !border.width)) {
    return {};
  }

  return {
    borderStyle: border.style,
    borderWidth: border.width ? border.width + 'px' : undefined,
    borderColor: rbColorToHexAlpha(borderColor)
  };
};

export const parseStyles = (config: RawReportStylesConfig | undefined): StylesConfig => {
  if (!config) {
    return {};
  }

  const table: StylesConfig['table'] = {
    body: undefined,
    header: undefined,
    footer: undefined,
    textAlign: config.table?.textAlign,
    firstColumnTextAlign: config.table?.firstColumnTextAlign,
    lastColumnTextAlign: config.table?.lastColumnTextAlign,
    chartColors: config.table?.colorScheme ? Object.values(config.table.colorScheme).map(({ color }) => color!) : undefined,
    emptyContent: config.table?.emptyContent
  };

  const tableHasBodyStyles = config.table?.bodyFontColor || config.table?.bodyFontStyle || config.table?.bodyBackgroundColor || config.table?.bodyAlternateBackgroundColor;
  if (tableHasBodyStyles) {
    table.body = {
      color: rbColorToHexAlpha(config.table?.bodyFontColor),
      fontFamily: config.table?.bodyFontStyle?.fontFamily,
      fontSize: config.table?.bodyFontStyle?.fontSize ? config.table?.bodyFontStyle?.fontSize + 'px' : undefined,
      ...parseFontStyle(config.table?.bodyFontStyle),
      backgroundColor: rbColorToHexAlpha(config.table?.bodyBackgroundColor)
    };
    if (config.table?.bodyAlternateBackgroundColor) {
      table.body.alternateRow = {
        backgroundColor: rbColorToHexAlpha(config.table?.bodyAlternateBackgroundColor)
      };
    }
  }

  const tableHasHeaderStyles =
    config.table?.headerFontColor || config.table?.headerFontStyle || config.table?.headerBackgroundColor || config.table?.headerBorder || config.table?.hideHeaderBoxShadow;
  if (tableHasHeaderStyles) {
    table.header = {
      color: rbColorToHexAlpha(config.table?.headerFontColor),
      fontFamily: config.table?.headerFontStyle?.fontFamily,
      fontSize: config.table?.headerFontStyle?.fontSize ? config.table?.headerFontStyle?.fontSize + 'px' : undefined,
      ...parseFontStyle(config.table?.headerFontStyle),
      backgroundColor: rbColorToHexAlpha(config.table?.headerBackgroundColor),
      ...rbBorderToBorder(config.table?.headerBorder, config.table?.headerBorderColor),
      ...(config.table?.hideHeaderBoxShadow ? { boxShadow: 'unset !important' } : {})
    };
  }

  const tableGroupStyles = config.table?.groups && config.table.groups.length > 0;
  if (tableGroupStyles) {
    table.groups = [];
    config.table!.groups!.forEach(group => {
      table.groups!.push({
        backgroundColor: rbColorToHexAlpha(group.backgroundColor),
        color: rbColorToHexAlpha(group.color),
        fontFamily: group.fontStyle?.fontFamily,
        fontSize: group.fontStyle?.fontSize ? group.fontStyle?.fontSize + 'px' : undefined,
        ...parseFontStyle(group.fontStyle)
      });
    });
  }

  const tableHasFooterStyles = config.table?.footerFontColor || config.table?.footerFontStyle || config.table?.footerBackgroundColor;
  if (tableHasFooterStyles) {
    table.footer = {
      color: rbColorToHexAlpha(config.table?.footerFontColor),
      fontFamily: config.table?.footerFontStyle?.fontFamily,
      fontSize: config.table?.footerFontStyle?.fontSize ? config.table?.footerFontStyle?.fontSize + 'px' : undefined,
      ...parseFontStyle(config.table?.footerFontStyle),
      backgroundColor: rbColorToHexAlpha(config.table?.footerBackgroundColor)
    };
  }

  const callOut: StylesConfig['callOut'] = {};
  if (config.callOutLabel) {
    callOut.label = {
      color: rbColorToHexAlpha(config.callOutLabel?.fontColor),
      fontFamily: config.callOutLabel?.fontStyle?.fontFamily,
      fontSize: config.callOutLabel?.fontStyle?.fontSize ? config.callOutLabel?.fontStyle?.fontSize + 'px' : undefined,
      ...parseFontStyle(config.callOutLabel?.fontStyle)
    };
  }
  if (config.callOutValue) {
    callOut.value = {
      color: rbColorToHexAlpha(config.callOutValue?.fontColor),
      fontFamily: config.callOutValue?.fontStyle?.fontFamily,
      fontSize: config.callOutValue?.fontStyle?.fontSize ? config.callOutValue?.fontStyle?.fontSize + 'px' : undefined,
      ...parseFontStyle(config.callOutValue?.fontStyle)
    };
  }

  const styles: StylesConfig = {
    cover: {
      color: rbColorToHexAlpha(config.cover?.fontColor),
      fontFamily: config.cover?.fontStyle?.fontFamily,
      ...parseFontStyle(config.cover?.fontStyle),
      backgroundColor: config.cover?.backgroundEnabled ? rbColorToHexAlpha(config.cover?.backgroundColor) : undefined
    },
    title: {
      color: rbColorToHexAlpha(config.title?.fontColor),
      fontFamily: config.title?.fontStyle?.fontFamily,
      fontSize: config.title?.fontStyle?.fontSize ? config.title?.fontStyle?.fontSize + 'px' : undefined,
      ...parseFontStyle(config.title?.fontStyle),
      textAlign: config.title?.align,
      whiteSpace: config.title?.nowrap ? 'nowrap' : undefined
    },
    container: {
      backgroundColor: config.container?.backgroundEnabled ? rbColorToHexAlpha(config.container?.backgroundColor) : undefined,
      ...(config.container?.borderEnabled && config.container?.borderColor
        ? {
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: rbColorToHexAlpha(config.container?.borderColor)
          }
        : undefined),
      borderRadius: config.container?.borderRadius || undefined,
      padding: config.container?.padding || undefined,
      margin: config.container?.margin || undefined,
      color: rbColorToHexAlpha(config.container?.fontColor),
      fontFamily: config.container?.fontStyle?.fontFamily,
      fontSize: config.container?.fontStyle?.fontSize ? config.container?.fontStyle?.fontSize + 'px' : undefined,
      ...parseFontStyle(config.container?.fontStyle)
    },
    wrapper: {
      boxShadow: config.wrapper?.shadowEnabled ? '' : undefined,
      backgroundColor: config.wrapper?.backgroundEnabled ? rbColorToHexAlpha(config.wrapper?.backgroundColor) : undefined,
      justifyContent: config.wrapper?.verticalAlign,
      ...(config.wrapper?.borderEnabled && config.wrapper?.borderColor
        ? {
            borderStyle: config.wrapper.borderStyle?.style || 'solid',
            borderWidth: config.wrapper.borderStyle?.width || 1,
            borderColor: rbColorToHexAlpha(config.wrapper?.borderColor)
          }
        : undefined)
    },
    chart: {
      chartColors: config.chart?.colorScheme ? Object.values(config.chart.colorScheme).map(({ color }) => color!) : undefined,
      plotShadow: config.chart?.plotShadow,
      backgroundColor: config.chart?.backgroundEnabled ? rbColorToHexAlpha(config.chart?.backgroundColor) : undefined,
      ...(config.chart?.borderEnabled && config.chart?.borderColor
        ? {
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: rbColorToHexAlpha(config.chart?.borderColor)
          }
        : undefined),
      gridLineColor: rbColorToHexAlpha(config.chart?.gridLineColor),
      legend: {
        color: config.legend?.fontColor?.color ? toHexAlpha(config.legend?.fontColor?.color, (config.legend?.fontColor?.opacity ?? 100) / 100) : undefined,
        fontFamily: config.legend?.fontStyle?.fontFamily,
        fontSize: config.legend?.fontStyle?.fontSize ? config.legend?.fontStyle?.fontSize + 'px' : undefined,
        ...parseFontStyle(config.legend?.fontStyle)
      },
      label: {
        color: rbColorToHexAlpha(config.label?.fontColor),
        fontFamily: config.label?.fontStyle?.fontFamily,
        fontSize: config.label?.fontStyle?.fontSize ? config.label?.fontStyle?.fontSize + 'px' : undefined,
        ...parseFontStyle(config.label?.fontStyle)
      },
      dataLabel: {
        color: rbColorToHexAlpha(config.dataLabel?.fontColor),
        fontFamily: config.dataLabel?.fontStyle?.fontFamily,
        fontSize: config.dataLabel?.fontStyle?.fontSize ? config.dataLabel?.fontStyle?.fontSize + 'px' : undefined,
        ...parseFontStyle(config.dataLabel?.fontStyle)
      },
      connectorColor: rbColorToHexAlpha(config?.chart?.connectorColor),
      xAxis: config.chart?.xAxis,
      yAxis: config.chart?.yAxis
    },
    table,
    gauge: {
      valueLabel: {
        color: rbColorToHexAlpha(config.gaugeValueLabel?.fontColor),
        fontFamily: config.gaugeValueLabel?.fontStyle?.fontFamily,
        fontSize: config.gaugeValueLabel?.fontStyle?.fontSize ? config.gaugeValueLabel?.fontStyle?.fontSize + 'px' : undefined,
        ...parseFontStyle(config.gaugeValueLabel?.fontStyle)
      },
      dial: {
        backgroundColor: rbColorToHexAlpha(config.gaugeDial?.backgroundColor),
        borderColor: rbColorToHexAlpha(config.gaugeDial?.borderColor),
        borderWith: config.gaugeDial?.borderWith,
        baseLength: config.gaugeDial?.baseLength,
        baseWidth: config.gaugeDial?.baseWidth,
        radius: config.gaugeDial?.radius,
        rearLength: config.gaugeDial?.rearLength,
        topWidth: config.gaugeDial?.topWidth
      },
      pivot: {
        backgroundColor: rbColorToHexAlpha(config.gaugePivot?.backgroundColor),
        borderColor: rbColorToHexAlpha(config.gaugePivot?.borderColor),
        borderWith: config.gaugePivot?.borderWith,
        radius: config.gaugePivot?.radius
      }
    },
    callOut,
    colorScheme: {
      linkToVisualColorScheme: config.colorScheme?.linkToVisualColorScheme
    },
    others: config.others
  };
  return removeEmpty(styles);
};
