import { useCallback } from 'react';
import { AssetFlow, FormValues } from '@/modules/Onboarding/Shared/types';

interface FormValidationProps {
  name: string;
  required: boolean;
  pattern?: string;
  schema?: FormValidationProps[];
  onlyFor?: AssetFlow;
}

const useValidation = (fieldsProperties: Array<FormValidationProps>, formValues: FormValues, flow?: AssetFlow) => {
  const schemaResolver: (schema: FormValidationProps[], fieldName: string) => boolean = useCallback(
    (schema: FormValidationProps[], fieldName: string) => {
      const values = formValues[fieldName] as FormValues[];
      if (values) {
        schema.forEach(schema => {
          if (schema.required) {
            return values.forEach(value => {
              return !!value[schema.name];
            });
          }
        });
      }
      return true;
    },
    [formValues]
  );
  const resolver: (field: FormValidationProps) => boolean = useCallback(
    (field: FormValidationProps) => {
      if (field.onlyFor) {
        if (field.onlyFor !== flow) {
          return true;
        }
      }
      if (field.schema) {
        return schemaResolver(field.schema, field.name);
      }
      if (field.pattern) {
        const regex = new RegExp(field.pattern);
        if (formValues[field.name]) {
          return regex.test(formValues[field.name] as string);
        }
        return false;
      }
      if (field.required) {
        return !!formValues[field.name];
      }

      return true;
    },
    [flow, schemaResolver, formValues]
  );

  return fieldsProperties.every(resolver);
};

export { useValidation };
