import TransientDocumentTagValue from './TransientDocumentTagValue';
import TransientDocumentTag from './TransientDocumentTag';
import DocumentTag from './DocumentTag';
import { DocumentTagType, DocumentTagValueLookup } from '../../../common/types/entity/DocumentTags';

export default class DocumentTagValue<T> extends TransientDocumentTagValue<T> {
  constructor(
    public readonly id: string,
    tag: TransientDocumentTag,
    type: DocumentTagType,
    value: T,
    color?: string
  ) {
    super(tag, type, value, color);
  }

  public isTransient = () => false;
  public equals(other?: TransientDocumentTagValue<any> | DocumentTagValueLookup<any>): boolean {
    if (other === undefined) {
      return false;
    }
    if (other instanceof DocumentTagValue) {
      return this.id === other.id;
    }
    return super.equals(other);
  }

  public copy = (value: T, color?: string) => new DocumentTagValue<T>(this.id, this.tag, this.type, value, color);

  public static fromTagValue<S>(lookup: DocumentTagValueLookup<S>) {
    let val = lookup.value;
    if (lookup.tag.type === DocumentTagType.NUMBER) {
      val = parseFloat(lookup.value as unknown as string) as unknown as S;
    } else if (lookup.tag.type === DocumentTagType.DATE) {
      val = new Date(lookup.value as unknown as string) as unknown as S;
    }
    return new DocumentTagValue<S>(lookup.id, new DocumentTag(lookup.tag.id, lookup.tag.name, lookup.tag.type), lookup.type, val, lookup.color);
  }

  public static from<S>(fromTag: DocumentTag, fromValue: string) {
    return new DocumentTagValue<S>(`${fromTag.id}-${fromValue}`, fromTag, fromTag.type, fromValue as S);
  }

  public static fromTransient<S>(fromTransientTag: TransientDocumentTag, fromValue: string) {
    return new DocumentTagValue<S>(`${fromTransientTag.id}-${fromValue}`, fromTransientTag, fromTransientTag.type, fromValue as S);
  }

  public static fromValue<S>(fromTag: DocumentTag, fromId: string, value: S) {
    return new DocumentTagValue<S>(fromId, fromTag, fromTag.type, value);
  }
}
