import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedDashboardTableConfig } from '..';
import { DashboardTableConfigMapped } from './types';

export default class DashboardTableConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as DashboardTableConfigMapped));
    this.collapsible = true;
  }

  getVisualConfigProps(config: DashboardTableConfigMapped): ExtendedDashboardTableConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      categoryHeadersEnabled: config.categoryHeadersEnabled,
      ownershipDropdown: config.ownershipDropdown
    });
  }

  setVisualConfig(config: ExtendedDashboardTableConfig) {
    super.setVisualConfig(config);
    this.categoryHeadersEnabled = config.categoryHeadersEnabled;
    this.subTotal = true;
    this.ownershipDropdown = config.ownershipDropdown;
  }

  subTotal: boolean;
  categoryHeadersEnabled: boolean;
  collapsible: boolean;
  ownershipDropdown?: boolean;
}
