import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import ElasticResponse from '@/common/types/elastic/ElasticResponse';
import getHoldingSetsUnvalidated from '@/api/getHoldingSetsUnvalidated';
import getUserHoldingSets from '@/api/getUserHoldingSets';
import { HoldingSet } from '@/modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';
import { selector } from 'recoil';

interface AssetInfoLookup {
  assetId: number;
  assetName: string;
  assetCategory?: string;
}

interface AssetInfoUndatedLookup {
  holdingSetId: number;
  assets?: AssetInfoLookup[];
}

export const assetsFromValidatedHoldingSetsSelector = selector<{ id: number; name: string; assetCategory?: string }[]>({
  key: 'get-assets-from-validated-holding-sets-seletor',
  get: async () => {
    return await getAssetsFromValidatedHoldingSets();
  }
});

export const getAssetsFromValidatedHoldingSets = async () => {
  const hsets = await getUserHoldingSets();

  const holdingSetIds: number[] = [];

  const parseHoldingSetIds = (hsetList: HoldingSet[]) => {
    hsetList.forEach(entity => {
      holdingSetIds.push(entity.id);

      if (entity.children?.length > 0) {
        parseHoldingSetIds(entity.children);
      }
    });
  };

  parseHoldingSetIds(hsets);

  return requestApi({
    method: 'post',
    service: 'gateway',
    data: {
      holdingSetIds,
      source: {
        includes: ['assets.assetId', 'assets.assetName', 'assets.assetCategory', 'holdingSetId']
      }
    },
    url: `/api/elastic/v2/stream/asset_info_undated?v=1.0`
  })
    .then((response: AxiosResponse<ElasticResponse<AssetInfoUndatedLookup>>) => response?.data?.hits.hits)
    .then(holdingSetHits => {
      const assetMap = new Map<number, { id: number; name: string; assetCategory?: string }>();
      holdingSetHits!.forEach(holdingSetHit => {
        holdingSetHit._source.assets?.forEach(assetInfo => {
          assetMap.set(assetInfo.assetId, {
            id: assetInfo.assetId,
            name: assetInfo.assetName,
            assetCategory: assetInfo.assetCategory
          });
        });
      });
      return Array.from(assetMap.values());
    });
};

export default async () => {
  // I use unvalidated holdingsets here to get flattened HSs
  const holdingSetIds = (await getHoldingSetsUnvalidated()).map(hs => hs.id);

  return requestApi({
    method: 'post',
    service: 'gateway',
    data: {
      holdingSetIds,
      source: {
        includes: ['assets.assetId', 'assets.assetName', 'holdingSetId']
      }
    },
    url: `/api/elastic/v2/stream/asset_info_undated?v=1.0`
  })
    .then((response: AxiosResponse<ElasticResponse<AssetInfoUndatedLookup>> | undefined) => response?.data?.hits.hits)
    .then(holdingSetHits => {
      const assetMap = new Map<number, { id: number; name: string }>();
      holdingSetHits!.forEach(holdingSetHit => {
        holdingSetHit._source.assets?.forEach(assetInfo => {
          assetMap.set(assetInfo.assetId, {
            id: assetInfo.assetId,
            name: assetInfo.assetName
          });
        });
      });
      return Array.from(assetMap.values());
    });
};
