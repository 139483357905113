import React from 'react';
import _img from '@/modules/App/images/sesagif.gif';
import { useIntl } from 'react-intl';

const sesameGifStyles: React.CSSProperties = { width: '30%' };

type SesameGifProps = {
  style?: React.CSSProperties;
};

const SesameGif: React.FC<SesameGifProps> = ({ style }) => {
  const translator = useIntl();
  const styles = React.useMemo(() => ({ ...sesameGifStyles, ...(style || {}) }), [style]);

  return <img style={styles} alt={translator.formatMessage({ id: 'generic.sesameByLandytech' })} src={_img} />;
};

export default SesameGif;
