import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Select, SelectProps } from 'ui-sesame-components';

export enum InboxStatusFilterOptions {
  ALL,
  NEW,
  UPDATED
}

export const InboxStatusSelect = React.memo(({ popupMinWidth = 175, ...rest }: Omit<SelectProps, 'options'>) => {
  const translator = useIntl();

  const statusOptions = useMemo(() => {
    const statusFilterOptionsObject = Object.keys(InboxStatusFilterOptions)
      .filter(key => !isNaN(Number(key))) // Filter out string keys to get only numeric keys
      .reduce((acc, key) => {
        acc.push({
          label: InboxStatusFilterOptions[key as unknown as number],
          value: key
        });
        return acc;
      }, [] as Array<{ label: string; value: string }>);
    return statusFilterOptionsObject;
  }, []);

  return <Select popupMinWidth={popupMinWidth} prefix={translator.formatMessage({ id: 'generic.status' })} variant="round" options={statusOptions} allowClear={false} {...rest} />;
});
