import React from 'react';
import ContentLoader from 'react-content-loader';

const CallOuts: React.FC = props => {
  return (
    <ContentLoader width="100%" height="40" {...props}>
      <rect rx="2" ry="2" x="50%" y="10%" width="64" height="30%" transform="translate(-32, 0)" />
      <rect rx="2" ry="2" x="50%" y="70%" width="128" height="20%" transform="translate(-64, 0)" />
    </ContentLoader>
  );
};

export default CallOuts;
