import React from 'react';
import { CommentOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Text } from '@/common/components/Typography/Text';
import { ActivityLayout } from './CardLayout';
import { ActivityCardTypeText } from './CardTypeText';
import { ActivityActionType, type ActivityDTO } from '@/modules/ERP/Contacts/types';
import { parseActivityRelatedEntities } from '../utils';

interface ActivityCardTaskProps {
  activity: ActivityDTO;
  entityId: string;
}

export const ActivityCardTask: React.FunctionComponent<ActivityCardTaskProps> = React.memo(({ activity, entityId }) => {
  const { creator, relatedEntity } = parseActivityRelatedEntities(activity, entityId);
  let icon = <FileDoneOutlined />;
  if (activity.type === ActivityActionType.CREATE_COMMENT_TASK) {
    icon = <CommentOutlined />;
  }

  return (
    <ActivityLayout activity={activity} icon={icon}>
      {activity.type !== ActivityActionType.REMINDER_TASK && <Text link>{creator}</Text>}
      &nbsp;
      <ActivityCardTypeText activityType={activity.type} />
      <Text link>{relatedEntity}</Text>
    </ActivityLayout>
  );
});
