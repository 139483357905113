import { getTheme } from '@/modules/reporting-v2/utils/theme';
import type { HeaderFooterGenerator, MergedReportBrandingStyles } from '@/modules/reporting-v2/types/HeaderFooter';
import { getFontStyles } from './utils';
import { getBrandingStyle } from '../utils';

const ratio = 0.955;

export const ltsFooter: HeaderFooterGenerator = ({ pageProperties, reportStyle, user }): string => {
  const style = {
    ...getBrandingStyle(user.company.branding),
    ...reportStyle
  } as MergedReportBrandingStyles;

  return `
  <style>
    img {
      height: 18px;
      margin-right: 8px;
    }
    .img-wrapper {
      width: 100%;
    }
    img {
      display: block;
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin: 0 auto;
    }
    #footer {
      padding: 0;
      height: 100%;
      width: 100%;
      position: relative;
      -webkit-print-color-adjust: exact;
    }
    #footer .footer-container {
      width: 100%;
      height: 100%;
      ${getFontStyles(style)}
      margin-left: ${32 * ratio * pageProperties.ratio}px;
      -webkit-print-color-adjust: exact;
    }
    #footer table {
      width: 100%;
      height: 100%;
      border-collapse: collapse;
      border-spacing: 0;
    }

    td.main-content  {
      width: calc(100% - 40px);
    }

    td.pagination-content {
      width: ${50 * pageProperties.ratio * ratio}px;
      height: 100%;
      background: black;
      text-align: center;
    }

    .pageNumber {
      display: block;
      text-align: center;
      border-radius: 50%;
      border: 1px solid white;
      color: white;
      margin: 0 auto;
      font-size: 14px;
      line-height: ${36 * pageProperties.ratio * ratio}px;
      width: ${36 * pageProperties.ratio * ratio}px;
      height: ${36 * pageProperties.ratio * ratio}px;
    }
  </style>
  <div id="footer" class="report-theme-${getTheme()}">
    <div class="footer-container">
      <table>
        <tr>
          <td class="main-content">
          <span>
            ${style.footer?.customText ?? ''}
          </span></td>
          <td class="pagination-content">
            <span class="pageNumber"></span>
          </td>
        </tr>
      </table>
    </div>
  </div>
  `;
};
