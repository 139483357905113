import React, { memo } from 'react';
import { ShareClass } from '@/modules/reporting-v2/core/components/DataTable/components/Limits/LimitsTypes';
import { LimitStatus } from '@/modules/reporting-v2/core/components/DataTable/components/Limits/LimitStatus';

export const LimitShareClass: React.FC<ShareClass> = memo(({ valueStatus, currentValueStr, description }) => {
  return (
    <>
      <div>{description}</div>
      <div>{currentValueStr}</div>
      <div>
        <LimitStatus valueStatus={valueStatus} />
      </div>
    </>
  );
});
