import { VisualConfigProps } from '@/modules/reporting-v2/types/VisualEngine';

export default interface ExtendedPerformanceTableConfig extends VisualConfigProps {
  frequencies: Frequencies;
  transpose: boolean;
}

export enum DataFrequency {
  // Monthly = "monthly",
  Quarterly = 'quarterly',
  Yearly = 'yearly',
  QTD = 'qtd',
  YTD = 'ytd',
  ITD = 'itd'
}

export enum DateRange {
  Current = 'current',
  OneYear = 'oneYear',
  LastFullYear = 'lastFullYear',
  YTD = 'ytd',
  ITD = 'itd'
  // LastLastFullYear = "lastLastFullYear"
}

export type Frequencies = Array<{
  frequency: DataFrequency;
  dateRange: DateRange;
}>;
