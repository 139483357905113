import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import type { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import type { ExtendedTextImageConfig } from '..';
import type { BackgroundSize, TextImageConfigMapped } from './types';

export default class TextImageConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as TextImageConfigMapped));
  }

  getVisualConfigProps(config: TextImageConfigMapped): ExtendedTextImageConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      text: config.text,
      src: config.src,
      bgAlign: config.bgAlign,
      bgVerticalAlign: config.bgVerticalAlign,
      bgSize: config.bgSize
    });
  }

  setVisualConfig(config: ExtendedTextImageConfig) {
    super.setVisualConfig(config);
    this.text = config.text;
    this.src = config.src;
    this.bgAlign = config.bgAlign;
    this.bgSize = config.bgSize;
    this.bgVerticalAlign = config.bgVerticalAlign;
    this.total = true;
  }

  text: string;
  src: string;
  bgAlign: string;
  bgVerticalAlign: string;
  bgSize?: BackgroundSize;
}
