import { requestApi } from '@/core';
import { NoteCreationPayload, NoteDTO } from '@/modules/ERP/Contacts/types';
import { AxiosResponse } from 'axios';
import { sendHubspotCustomEvent } from '@/common/components/HubSpot/HubSpot';

export const createNote = (note: NoteCreationPayload): Promise<NoteDTO> => {
  return requestApi({
    service: 'collaboration',
    method: 'post',
    data: note,
    url: `/api/notes`
  }).then((resp: AxiosResponse<NoteDTO> | undefined) => {
    sendHubspotCustomEvent('pe45390240_note_created', { notename: resp?.data?.title });

    return resp!.data;
  });
};
