import EntityLookup from '../../../common/types/entity/EntityLookup';
import TransientDocumentTag from './TransientDocumentTag';
import { BasicTagType, DocumentTagType, DocumentTagValueLookup } from '../../../common/types/entity/DocumentTags';
import Params from './Params';
import EntityRef from '../../../common/types/entity/EntityRef';

export default class TransientDocumentTagValue<T> {
  readonly id: string | null = null; // very important to have this value defined, otherwise patching of tags does not work with the API
  constructor(
    public readonly tag: TransientDocumentTag,
    public readonly type: DocumentTagType,
    public value: any,
    public color?: string
  ) {}

  public isTransient = () => true;

  public equals(other?: TransientDocumentTagValue<any> | DocumentTagValueLookup<any>): boolean {
    return other !== undefined && this.type === other.tag.type && (((this.value as any)?.id && (this.value as any)?.id === other.value?.id) || this.value === other.value);
  }

  public toString(): string {
    return (this.value as unknown as EntityLookup)?.name || String(this.value);
  }

  public compareTo(other?: TransientDocumentTagValue<T>): number {
    if (other === undefined || other.value === undefined) {
      return -1;
    } else if (this.type === DocumentTagType.TEXT || this.type === DocumentTagType.DATE || this.type === DocumentTagType.JSON) {
      return (this.value as unknown as string).localeCompare(other.value as unknown as string);
    } else if (this.type === DocumentTagType.NUMBER) {
      return (this.value as unknown as number) - (other.value as unknown as number);
    } else if (this.type === DocumentTagType.HOLDING_SET || this.type === DocumentTagType.ASSET) {
      return (this.value as unknown as EntityLookup).name?.localeCompare((other.value as unknown as EntityLookup).name);
    } else {
      throw new Error('Unknown type');
    }
  }

  toParams(): Map<string, any> {
    return new Map<string, any>(
      Object.entries({
        [Params.tag_type]: this.type,
        [Params.tag_value_value]: (this.value as any).id || this.value
      })
    );
  }

  static fromParams = (params: Map<string, any>, tag: TransientDocumentTag): undefined | TransientDocumentTagValue<EntityRef | BasicTagType> => {
    if (params.has(Params.holding_set)) {
      return new TransientDocumentTagValue<EntityRef>(tag, DocumentTagType.HOLDING_SET, { id: params.get(Params.holding_set) } as EntityLookup);
    }
    if (params.has(Params.asset)) {
      return new TransientDocumentTagValue<EntityRef>(tag, DocumentTagType.ASSET, { id: params.get(Params.asset) } as EntityLookup);
    } else if (params.has(Params.tag_value_value) && params.has(Params.tag_type)) {
      const type = params.get(Params.tag_type);
      if (type === DocumentTagType.HOLDING_SET || type === DocumentTagType.ASSET) {
        return new TransientDocumentTagValue<EntityLookup>(tag, params.get(Params.tag_type), { id: params.get(Params.tag_value_value) } as EntityLookup);
      } else {
        return new TransientDocumentTagValue<BasicTagType>(tag, params.get(Params.tag_type), params.get(Params.tag_value_value));
      }
    }
  };

  public static from<S>(fromTransientTag: TransientDocumentTag, fromValue: string) {
    return new TransientDocumentTagValue<S>(fromTransientTag, fromTransientTag.type, fromValue as S, fromTransientTag.type);
  }
}
