import { atom } from 'recoil';

const riskCountryByFilterState = atom<string>({
  key: 'risk-country-by-filter-state',
  default: undefined
});

const currencyByFilterState = atom<string>({
  key: 'currency-by-filter-state',
  default: undefined
});

const categoryByFilterState = atom<string>({
  key: 'category-by-filter-state',
  default: undefined
});

const assetTypeByFilterState = atom<string>({
  key: 'asset-type-by-filter-state',
  default: undefined
});

const parentPortfolioFilterState = atom<string>({
  key: 'parent-portfolio-filter-state',
  default: undefined
});

const assetsFilterState = atom<string>({
  key: 'assets-list-state',
  default: undefined
});

export { assetsFilterState, riskCountryByFilterState, currencyByFilterState, parentPortfolioFilterState, categoryByFilterState, assetTypeByFilterState };
