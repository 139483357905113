import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { AuthSessionStorageManager } from '@/common/auth/storage/AuthSessionStorageManager';

export default (id: number, settings: Object): Promise<number | void> => {
  if (AuthSessionStorageManager.isActing()) {
    return Promise.resolve();
  }

  return requestApi({
    service: 'cms',
    method: 'post',
    url: `/api/sitetree/${id}/preferences`,
    headers: {
      'content-type': 'application/json'
    },
    data: settings
  }).then((response: AxiosResponse<number> | undefined) => response?.data!);
};
