import { Nullable } from '@/common/types/types';
import { nanoid } from 'nanoid';
import { RawReportConfig } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ColumnsShape } from './Visual/Schema/Columns/ColumnsWrapper';
import * as schemas from '@/modules/reporting-v2/core/visuals/schemas';
import { tabDelimiter } from './Visual/Schema/types';

enum ValidationType {
  COLUMNS = 'columns',
  IMAGE = 'image',
  REQUIRED = 'required'
}

export class ConfigurableFormValidator {
  private constructor() {}

  public static validateConfiguration<Values extends Record<string, any>>(schema: Record<string, any>, values: Values) {
    const errors: Record<string, FormValidationError> = {};

    const schemaKeys = Object.keys(schema).filter(key => schema[key].validationType);

    for (const key of schemaKeys) {
      const value = values[key];

      switch (schema[key].validationType as ValidationType) {
        case ValidationType.REQUIRED:
          if (!value?.toString().trim()) {
            Object.assign(errors, {
              [key]: new FormValidationError(key, schema[key].validationType).buildErrorMessage()
            });
          }
          break;

        case ValidationType.COLUMNS:
          if (!this.validateColumns(value)) {
            Object.assign(errors, {
              [key]: new FormValidationError(key, schema[key].validationType).buildErrorMessage()
            });
          }
          break;
        case ValidationType.IMAGE:
          if (!this.validateImage(value)) {
            Object.assign(errors, {
              [key]: new FormValidationError(key, schema[key].validationType).buildErrorMessage()
            });
          }
          break;
        default:
          break;
      }
    }

    return errors;
  }

  private static validateColumns(value: Nullable<ColumnsShape>) {
    if (!value || !value.defaultColumns?.length) return false;
    return true;
  }

  private static validateImage(value: string) {
    if (!value) {
      return false;
    }
    return true;
  }
}

export class RBConfigurationValidator {
  private constructor() {}

  public static validateReportConfiguration(configuration: RawReportConfig) {
    const reportErrors: Record<string, Record<string, FormValidationError>> = {};
    for (const page of configuration.pages) {
      for (const visualConfig of page.components) {
        const schema: Record<string, any> = schemas[visualConfig.component].fields;
        const fields: Record<string, any> = Object.fromEntries(
          Object.entries(schemas[visualConfig.component].fields).flatMap(([key, value]) => {
            return key.startsWith(tabDelimiter) ? Object.entries(schema[key].fields) : [key, value];
          })
        );

        const errors = ConfigurableFormValidator.validateConfiguration(fields, visualConfig.config);
        if (Object.keys(errors).length) {
          Object.assign(reportErrors, {
            [visualConfig.id]: { ...reportErrors[visualConfig.id], ...errors }
          });
        }
      }
    }

    return reportErrors;
  }
}

export class FormValidationError {
  public message: string;

  public readonly errorId: string;
  public readonly key: string;

  private readonly validationType: ValidationType;

  public constructor(key: string, validationType: ValidationType) {
    this.errorId = nanoid();
    this.key = key;
    this.validationType = validationType;
  }

  public buildErrorMessage() {
    switch (this.validationType) {
      case ValidationType.REQUIRED:
        this.message = `Required value for field ${this.key}`;
        break;
      case ValidationType.COLUMNS:
        this.message = `Invalid amount of measures selected in field ${this.key}.`;
        break;
      case ValidationType.IMAGE:
        this.message = `An image must be uploaded in the TextImage visual.`;
        break;
    }

    return this;
  }
}
