import { requestApi } from '@/core';
import { DocumentInbox } from '../types/DocumentInbox';

export default (): Promise<DocumentInbox> =>
  requestApi({
    method: 'GET',
    url: `/api/document-inbox`,
    service: 'DOCUMENT'
  }).then(response => {
    return response.data;
  }).catch(e => {
    // don't need to do anything here
  });
