import React, { useContext } from 'react';
import { Row, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import { OverrideSummability } from '@/modules/reporting-v2/core/Column';
import { ReportingService } from '@/modules/reporting-v2/core/ReportingService';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { forceCurrency } from '@/modules/reporting-v2/core/forceCurrency';
import { EditCellPopover } from '@/modules/reporting-v2/core/components/DataTablev2/components/cell/EditPopover';
import { flexRender, type HeaderContext, type HeaderGroup } from '@tanstack/react-table';
import type { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { useCategoryContext } from '@/modules/reporting-v2/core/components/DataTablev2/context/categoryContext';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import { useIntl } from 'react-intl';

type FooterContentProps = {
  footerContext: HeaderContext<Row | RowGroup, Primitive>;
  totalData: FlattenObject;
  columnIndex: number;
};

export const FooterContent: React.FC<FooterContentProps> = ({ footerContext, totalData, columnIndex }) => {
  const column = footerContext.column.columnDef.meta!.column;
  const { editMode, reportConfiguration } = useContext(ReportContext);
  const translator = useIntl();

  const isFirstColumn = columnIndex === 0;
  if (isFirstColumn) {
    return <span>{translator.formatMessage({ id: 'generic.total' })}</span>;
  }

  let value: Primitive = undefined;
  if (column.fieldDataPath in totalData) {
    value = totalData[column.fieldDataPath];
  }

  const metas = ReportingService.metas[column.code ?? column.field.name];
  const hideTotalLevel = column.hideAtGroupLevels?.includes('total');
  const hasAggregationMethod = metas?.aggregation?.method;
  const overrideSummability = column.overrideSummability === OverrideSummability.SUMMABLE;
  const hasCalculationWithCalcOnGroup = column.calcExpression && column.calcOnGroup;
  const hide = hideTotalLevel || (!hasAggregationMethod && !overrideSummability && !hasCalculationWithCalcOnGroup);

  if (hide) {
    return null;
  }

  const formattedValue = VisualEngine.formatCell(value, column, totalData, undefined, forceCurrency(column), reportConfiguration?.config.numberLocale);

  const onUpdateData = (value: unknown) => {
    footerContext.table.options.meta!.updateTotalData(footerContext.column.id, value, columnIndex);
  };

  return (
    <div>
      {formattedValue}
      {!!editMode && <EditCellPopover initialValue={value} column={footerContext.column.columnDef.meta?.column} columnIndex={columnIndex} onUpdateData={onUpdateData} />}
    </div>
  );
};

type FooterProps = {
  footerGroup: HeaderGroup<Row | RowGroup>;
};

export const Footer: React.FC<FooterProps> = ({ footerGroup }) => {
  const getCategoryClass = useCategoryContext();

  return (
    <tr key={footerGroup.id} className={'total'}>
      {footerGroup.headers.map((header, index) => {
        return (
          <td key={header.id} colSpan={header.colSpan > 1 ? header.colSpan : undefined} className={getCategoryClass(index)}>
            {header.isPlaceholder ? null : <div>{flexRender(header.column.columnDef.footer, header.getContext())}</div>}
          </td>
        );
      })}
    </tr>
  );
};
