import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import type { Band, GaugeType, ExtendedGaugeConfig, GaugeConfigMapped } from './types';

export default class GaugeConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as GaugeConfigMapped));
  }

  getVisualConfigProps(config: GaugeConfigMapped): ExtendedGaugeConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      minValue: config.minValue || 0,
      maxValue: config.maxValue || 1,
      bands: config.bands || [],
      gaugeType: config.gaugeType,
      thickness: config.thickness,
      portfolioScore: config.portfolioScore
    });
  }
  setVisualConfig(config: ExtendedGaugeConfig) {
    super.setVisualConfig(config);
    this.minValue = config.minValue;
    this.maxValue = config.maxValue;
    this.bands = config.bands;
    this.gaugeType = config.gaugeType;
    this.thickness = config.thickness;
    this.portfolioScore = config.portfolioScore;

    this.total = true;
  }
  gaugeType: GaugeType;
  bands: Band[];
  minValue: number;
  maxValue: number;
  thickness: number;
  portfolioScore: boolean | undefined;
}
