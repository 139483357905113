import { VisualConfigProps } from '@/modules/reporting-v2/types/VisualEngine';

export default interface ExtendedCalloutConfig extends VisualConfigProps {
  orientation: Orientation;
}

export enum Orientation {
  horizontal = 'vorizontal',
  vertical = 'vertical'
}
