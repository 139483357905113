import { Index } from '@/modules/reporting-v2/types/Index';
import { IndexNodeName } from '@/modules/reporting-v2/types/IndexNode';
import { getIndex } from '@/modules/reporting-v2/utils/IndexUtils';
import { Field } from './Field';
import { holdingSetIdProperty, ReportingService } from './ReportingService';
import { FieldName } from '../types/Field';

class IndexNode {
  constructor(node: IndexNodeName) {
    this.node = node;
  }

  node: IndexNodeName;

  public getIndex(): Index {
    return getIndex(this.node);
  }

  public getEntityIdField(): FieldName | undefined {
    const index = this.getIndex();

    if (ReportingService.rawMetas[index]?.__config?.holdingSetEnabled) {
      return `${index}${Field.fieldSeparator}${holdingSetIdProperty}`;
    }
  }

  public getOtherRequiredFields(): Field[] {
    const index = this.getIndex();
    if (index !== Index.allocation) {
      return [];
    }

    const requiredFields = [];
    if (this.node === 'allocation.allocations') {
      requiredFields.push(new Field('allocation.allocations.restrictMapping'));
    }

    return requiredFields;
  }

  public findCommonForeignKeys(foreignKeys: Record<string, boolean>): Set<string> {
    const set = new Set<string>();

    const path = this.node.split(Field.fieldSeparator);
    const foundKeys = new Map<string, boolean>();

    while (path.length) {
      const metadata = path.reduce((acc, val) => acc?.[val], ReportingService.rawMetas);

      for (const field in metadata) {
        const isCommonMappingKey = foreignKeys[field] === true && metadata[field].foreignKey;

        if (isCommonMappingKey && !foundKeys.has(field)) {
          foundKeys.set(field, true);

          set.add(path.concat(field).join(Field.fieldSeparator));
        }
      }

      path.pop();
    }
    return set;
  }

  public getForeignKeys(): Record<string, boolean> {
    const path = this.node.split(Field.fieldSeparator);
    const fields: Record<string, boolean> = {};

    while (path.length) {
      const metadata = path.reduce((acc, val) => acc?.[val], ReportingService.rawMetas);

      for (const field in metadata) {
        if (metadata[field].foreignKey === true) {
          Reflect.set(fields, field, true);
        }
      }

      path.pop();
    }

    return fields;
  }

  public isRootNode(): boolean {
    return this.node.split(Field.fieldSeparator).length === 1;
  }
}

export { IndexNode };
