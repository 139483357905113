import { HoldingSetType } from '@/common/types/entity/HoldingSetType';
import { selector, selectorFamily } from 'recoil';
import { holdingSets, holdingSetsMap } from '@/recoil/holdingSets';
import { AssetLookup, getAssets } from '../api/getAssets';
import { Show } from '@/modules/Onboarding/Shared/types';
import { getContacts } from '@/modules/ERP/Contacts/Api/getContacts';
import { ContactType } from '@/modules/ERP/Contacts/types';
import { RecentEntityModule } from '@/modules/GlobalSearch/api/getRecentEntities';

export type SearchQueryReturnData = AssetLookup & { type: HoldingSetType };

export enum SearchV2Category {
  LEGAL_ENTITY = 'Legal Entity',
  PORTFOLIOS = 'Portfolio',
  GROUPING = 'Grouping',
  ASSETS = 'Asset',
  CONTACT = 'Contact',
  COMPANY = 'Company'
}

export type SearchQueryReturn<T = SearchQueryReturnData> = {
  key: string;
  title: string;
  description?: string;
  group: string;
  content?: string;
  data: T;
  searchCategory: SearchV2Category;
};

export enum RecentEntityType {
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  DOCUMENT = 'DOCUMENT',
  PORTFOLIO = 'PORTFOLIO',
  GROUPING = 'GROUPING',
  ASSET = 'ASSET',
  CONTACT = 'CONTACT',
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
  MEASURE = 'MEASURE'
}

export type RecentEntity = {
  entityType: RecentEntityType;
  entityId: string;
  sesameModule?: RecentEntityModule;
  date?: Date;
};

export const assetSelector = selectorFamily({
  key: 'GlobalSearchAssetSelector',
  get: (holdingSetIds: number[]) => async () => {
    return getAssets({ holdingSetIds });
  }
});
export const SearchQuery = selector<SearchQueryReturn<SearchQueryReturnData>[]>({
  key: 'GlobalSearchQuery',
  get: async ({ get }) => {
    const holdingSetMap = get(holdingSetsMap);
    const assets = get(assetSelector(Array.from(holdingSetMap.keys())));

    const contacts = await getContacts({ show: Show.ACTIVE, size: 999999 });

    const hsets = get(holdingSets);

    const portfolios = hsets.filter(portfolio => portfolio.type === HoldingSetType.PORTFOLIO);

    const legalEntities = hsets.filter(legalEntity => legalEntity.type === HoldingSetType.VEHICLE);

    const groupingEntities = hsets.filter(grouping => grouping.type === HoldingSetType.GROUPING);

    const companies = contacts.filter((entity: any) => entity.type === ContactType.COMPANY);
    const persons = contacts.filter((entity: any) => entity.type === ContactType.PERSON);

    const mappedAssets = assets.map(asset => {
      const holdingSet = holdingSetMap.get(asset.holdingSetId)!;
      return {
        searchCategory: SearchV2Category.ASSETS,
        key: String(asset.id),
        title: asset.name,
        content: holdingSet?.name,
        group: asset.category,
        data: {
          ...asset,
          type: holdingSet?.type,
          recentType: RecentEntityType.ASSET,
          category: SearchV2Category.ASSETS,
          group: asset.category,
          withGroup: true
        }
      };
    });

    const mappedPersons = persons.map(person => {
      return {
        searchCategory: SearchV2Category.CONTACT,
        key: person.id,
        title: person.firstName + ' ' + person.lastName,
        content: person.email,
        data: {
          ...person,
          type: person.type,
          name: person.firstName + ' ' + person.lastName,
          recentType: RecentEntityType.CONTACT,
          category: SearchV2Category.CONTACT
        }
      };
    });

    const mappedCompanies = companies.map((company: any) => {
      return {
        searchCategory: SearchV2Category.COMPANY,
        key: String(company.id),
        title: company.company,
        content: company.email,
        data: {
          ...company,
          type: company.type,
          name: company.company,
          recentType: RecentEntityType.COMPANY,
          category: SearchV2Category.COMPANY
        }
      };
    });

    const mappedPortfolios = portfolios.map((portfolio: any) => {
      return {
        searchCategory: SearchV2Category.PORTFOLIOS,
        key: String(portfolio.id),
        title: portfolio.name,
        content: portfolio.name,
        data: {
          ...portfolio,
          type: portfolio.type,
          recentType: RecentEntityType.PORTFOLIO,
          category: SearchV2Category.PORTFOLIOS
        }
      };
    });

    const mappedLegalEntities = legalEntities.map((legalEntity: any) => {
      return {
        searchCategory: SearchV2Category.LEGAL_ENTITY,
        key: String(legalEntity.id),
        title: legalEntity.name,
        content: legalEntity.name,
        data: {
          ...legalEntity,
          type: legalEntity.type,
          recentType: RecentEntityType.LEGAL_ENTITY,
          category: SearchV2Category.LEGAL_ENTITY
        }
      };
    });

    const mappedGroupings = groupingEntities.map((groupingEntity: any) => {
      return {
        searchCategory: SearchV2Category.GROUPING,
        key: String(groupingEntity.id),
        title: groupingEntity.name,
        content: groupingEntity.name,
        data: {
          ...groupingEntity,
          type: groupingEntity.type,
          recentType: RecentEntityType.GROUPING,
          category: SearchV2Category.GROUPING
        }
      };
    });
    return [...mappedLegalEntities, ...mappedPortfolios, ...mappedAssets, ...mappedPersons, ...mappedCompanies, ...mappedGroupings];
  }
});
