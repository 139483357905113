import React from 'react';
import { Comment } from '../../../common/types/entity/Conversation';
import { ReactQuillAdapted } from '../../../common/ReactQuillAdapted';
import { UserLabel } from '../../../common/user';
import { Row } from 'antd';
import CommentTimestamp from './CommentTimestamp';

export interface ReadonlyCommentProps {
  comment: Comment;
}

export default function ({ comment }: ReadonlyCommentProps) {
  return (
    <div className="comment">
      {comment.id && (
        <Row className="comment-user" justify="space-between">
          <span>
            <UserLabel user={comment?.user} />:
          </span>
          <span>
            <CommentTimestamp comment={comment} />
          </span>
        </Row>
      )}
      <ReactQuillAdapted readOnly={true} theme="bubble" value={comment.content} />
    </div>
  );
}
