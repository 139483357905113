import { getTheme } from '@/modules/reporting-v2/utils/theme';
import _default from './default.css?inline';
import facelift from './facelift.css?inline';
import landytech from './landytech.css?inline';
import sf from './sf.css?inline';

const getThemeStyle = () => {
  const theme = getTheme();

  if (theme === 'facelift') {
    return facelift;
  }

  if (theme === 'landytech') {
    return landytech;
  }

  if (theme === 'sf') {
    return sf;
  }

  return _default;
};

export { getThemeStyle };
