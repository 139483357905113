import { TreemapChartOptions } from '@/modules/reporting-v2/core/components/Highcharts/types';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { TreeMapMapped } from './types';

export default class TreeMapConfig extends VisualEngine {
  chartOptions?: TreemapChartOptions;
  drilldown?: boolean;

  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as TreeMapMapped));
  }

  getVisualConfigProps(config: TreeMapMapped): any {
    return Object.assign(super.getVisualConfigProps(config), {
      chartOptions: config._options
    });
  }
  setVisualConfig(config: any) {
    super.setVisualConfig(config);
    this.drilldown = true;
  }
}
