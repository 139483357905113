import { requestApi } from '@/core';
import { Contact } from '@/modules/ERP/Contacts/types';
import { AxiosResponse } from 'axios';
import { UUID } from '@/common/types/types';

export const getContactDetails = (id: UUID): Promise<Contact | null> => {
  return requestApi({
    service: 'contact',
    method: 'get',
    url: `/api/contacts/${id}`,
    errors: false
  })
    .then((resp: AxiosResponse<Contact> | undefined) => {
      return resp!.data;
    })
    .catch(() => {
      return null;
    });
};
