import type { RBBorder } from '@/modules/report-builder/components/Forms/Visual/Schema/BorderWrapper';
import type { RBColor } from '@/modules/report-builder/components/Forms/Visual/Schema/ColorPickerWrapper';
import type { RBFont } from '@/modules/report-builder/components/Forms/Visual/Schema/FontWrapper';
import type { RBTextAlign } from '@/modules/report-builder/components/Forms/Visual/Schema/TextAlign';
import type { BuilderMode, BuilderType } from '@/modules/report-builder/types';
import type { Filter } from '@/modules/reporting-v2/core/Filter';
import type { ConfigMappedRawColumn } from '@/modules/reporting-v2/utils/IndexUtils';
import type { FilterOperator } from '@/types/Filters';
import type { FieldName } from './Field';
import type { RawFilter } from './Filter';
import type { SortOrder } from './VisualEngine';
import type { Align, VisualReportContext } from './VisualUtils';
import type ReactGridLayout from 'react-grid-layout';
import { ColourGroup } from '@/modules/reporting-v2/core/ReportingService';

export enum VisualComponent {
  AllocationPie = 'AllocationPie',
  AllocationTable = 'AllocationTable',
  AssetAllocationInfo = 'AssetAllocationInfo',
  BarChart = 'BarChart',
  CallOut = 'CallOut',
  CustomTable = 'CustomTable',
  DashboardTable = 'DashboardTable',
  DateSlicer = 'DateSlicer',
  DrilldownTable = 'DrilldownTable',
  Flag = 'Flag',
  FlowsTable = 'FlowsTable',
  Gauge = 'Gauge',
  GhostVisual = 'GhostVisual',
  HistoricalChart = 'HistoricalChart',
  HistoricalMonthlyTab = 'HistoricalMonthlyTab',
  HoldingSetInfo = 'HoldingSetInfo',
  LimitsCallOuts = 'LimitsCallOuts',
  LimitsTable = 'LimitsTable',
  LiquidityTable = 'LiquidityTable',
  PerformanceTable = 'PerformanceTable',
  ScatterChart = 'ScatterChart',
  SelectBox = 'SelectBox',
  SingleValue = 'SingleValue',
  StatsTable = 'StatsTable',
  TableOfContent = 'TableOfContent',
  TextImage = 'TextImage',
  TopTab = 'TopTab',
  TransposeTable = 'TransposeTable',
  TreeMap = 'TreeMap'
}

export interface RawReportParamsConfig {
  date: boolean;
  endDate?: boolean;
  holdingSet: boolean;
  consolidationType: boolean;
}

export interface ReportParamsConfig extends Record<string, boolean> {
  dateEnabled: boolean;
  endDateEnabled: boolean;
  holdingSetEnabled: boolean;
  consolidationTypeEnabled: boolean;
  multiHoldingSetEnabled: boolean;
}

export interface ConfigMappedVisual {
  title?: string;
  columns: ConfigMappedRawColumn[];
  group?: ConfigMappedRawColumn[];
  dateRange?: string;
  startDate?: string;
  sampling?: boolean;
  categories?: boolean;
  sortable?: boolean;
  sort?: { field: FieldName; order: SortOrder };
  styles?: RawReportStylesConfig;
  _options?: Record<string, any>;
  _reportContext: VisualReportContext;
  _hiddenTitle?: boolean;
  _attrs?: Record<string, any>;
  _align?: Align;
  reportContextFields?: RawColumn;
  _improvedLeftJoin?: boolean;
  isHistorical?: boolean;
  hideInPdfIfNoData?: boolean;
  dateField?: string;
}

export type RawColumn = {
  columns: string[];
  defaultColumns: string[];
  filters: {
    [key: string]: {
      filter: {
        operator: FilterOperator;
        value: string[];
      };
    };
  };
  options: Record<string, any>;
  conditionalCalculation?: Record<string, any>;
  styling?: Record<string, any>;
};

export enum ReportOrientation {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape'
}

export enum FooterLogoPositionType {
  left = 'left',
  center = 'center',
  right = 'right'
}

export interface GlobalReportConfig {
  multiEntity?: boolean;
  filters: RawReportParamsConfig;
  params?: RawReportParamsConfig | Record<string, never>;
  orientation: ReportOrientation;
  pagination?: boolean;
  globalFilters?: { filters?: RawFilter[] };
  stylesCode?: string;
  styles?: RawReportStylesConfig;
  customStyling?: boolean;
  theme?: string;
  cover?: boolean;
  coverSelection?: number;
  disclaimer?: boolean;
  disclaimerSelection?: number;
  margin?: Margin;
  endCover?: boolean; // todo: check if still used, remove if not
  backCover?: boolean;
  backCoverSelection?: number;
  numberLocale?: string;
  hidePagesWithNoData?: boolean;
  hideVisualSettings?: boolean;
  applyStylesInWebview?: 'inherit' | 'true' | 'false';
  header?: {
    reportingPeriod?: ReportingPeriodConfig;
  };
  TEMP_LTS_HEADER_FOOTER?: boolean;
  TEMP_CUSTOM_HEADER?: string;
}

export type ReportingPeriodConfig = {
  range?: 'MTD' | 'QTD' | 'YTD' | 'FROM_INCEPTION';
  customFrom?: string;
  customTo?: string;
  hideProducedDate?: boolean;
  hideReportingRange?: boolean;
};

export type RawConfig = {
  _reportContext?: VisualReportContext;
  title?: string;
  _hiddenTitle?: boolean;
  columns?: RawColumn;
  group?: RawColumn;
  styles?: RawReportStylesConfig;
  entity?: Array<number>;
  multiEntityFlag?: boolean;
  _attrs?: Record<string, any>;
  _align?: Align;
  tableVersion?: string;
  [key: string]: any;
};

export type RawVisual = {
  id: string;
  component: VisualComponent;
  config: RawConfig;
  colourGroups?: ColourGroup[];
  globalFilters?: Filter[];
  selectBoxFields?: string[];
  version?: string;
};

export type Page = {
  id: string;
  title: string;
  titleStyle?: {
    fontStyle?: RBFont;
    fontColor?: RBColor;
  };
  components: RawVisual[];
  layout: ReactGridLayout.Layout[];
  filters?: RawFilter[];
  filtersHistoricalRange?: string;
  printOnly?: boolean;
  mode?: BuilderMode;
  orientation?: ReportOrientation; // used for cover(&+) page orientation
  type?: BuilderType;
};

export type RawReportConfig = {
  version?: string;
  id: string;
  title?: string;
  config: GlobalReportConfig;
  pages: Page[];
  templateType?: string;
  colourGroups?: ColourGroup[];
};

export type Margin = {
  top?: string | number;
  bottom?: string | number;
  left?: string | number;
  right?: string | number;
};

export type RawVisualStylesChartAxis = {
  labels?: {
    whiteSpace?: string;
    textOverflow?: string;
    width?: number;
  };
};

export type RBVerticalAlign = 'flex-start' | 'center' | 'flex-end';

export type RawVisualStylesConfig = {
  title?: {
    fontStyle?: RBFont;
    fontColor?: RBColor;
    align?: RBTextAlign;
    nowrap?: boolean;
  };
  wrapper?: {
    shadowEnabled?: boolean;
    backgroundEnabled?: boolean;
    backgroundColor?: RBColor;
    borderEnabled?: boolean;
    borderColor?: RBColor;
    borderStyle?: RBBorder;
    verticalAlign?: RBVerticalAlign;
  };
  container?: {
    backgroundEnabled?: boolean;
    backgroundColor?: RBColor;
    borderEnabled?: boolean;
    borderColor?: RBColor;
    borderStyle?: RBBorder;
    borderRadius?: string;
    padding?: string;
    margin?: string;
    fontStyle?: RBFont;
    fontColor?: RBColor;
  };
  chart?: {
    plotShadow?: boolean;
    backgroundEnabled?: boolean;
    backgroundColor?: RBColor;
    borderEnabled?: boolean;
    borderColor?: RBColor;
    connectorColor?: RBColor;
    colorScheme?: RBColor[];
    gridLineColor?: RBColor;
    xAxis?: RawVisualStylesChartAxis;
    yAxis?: RawVisualStylesChartAxis;
  };
  gaugeValueLabel?: {
    fontStyle?: RBFont;
    fontColor?: RBColor;
  };
  gaugeDial?: {
    backgroundColor?: RBColor;
    borderWith?: string;
    borderColor?: RBColor;
    radius?: string;
    baseWidth?: string;
    baseLength?: string;
    topWidth?: string;
    rearLength?: string;
  };
  gaugePivot?: {
    backgroundColor?: RBColor;
    borderWith?: string;
    borderColor?: RBColor;
    radius?: string;
  };
  label?: {
    fontStyle?: RBFont;
    fontColor?: RBColor;
  };
  dataLabel?: {
    fontStyle?: RBFont;
    fontColor?: RBColor;
  };
  legend?: {
    fontStyle?: RBFont;
    fontColor?: RBColor;
  };
  table?: {
    headerFontStyle?: RBFont;
    headerFontColor?: RBColor;
    headerBackgroundColor?: RBColor;
    headerBorder?: RBBorder;
    headerBorderColor?: RBColor;
    hideHeaderBoxShadow?: boolean;
    bodyFontStyle?: RBFont;
    bodyFontColor?: RBColor;
    bodyBackgroundColor?: RBColor;
    bodyAlternateBackgroundColor?: RBColor;
    footerFontStyle?: RBFont;
    footerFontColor?: RBColor;
    footerBackgroundColor?: RBColor;
    textAlign?: RBTextAlign;
    firstColumnTextAlign?: RBTextAlign;
    lastColumnTextAlign?: RBTextAlign;
    colorScheme?: RBColor[];
    groups?: Array<{
      backgroundColor?: RBColor;
      color?: RBColor;
      fontStyle?: RBFont;
    }>;
    emptyContent?: string;
  };
  callOutValue?: {
    fontStyle?: RBFont;
    fontColor?: RBColor;
  };
  callOutLabel?: {
    fontStyle?: RBFont;
    fontColor?: RBColor;
  };
  others?: {
    hideSortableIcons?: boolean;
  };
  colorScheme?: {
    linkToVisualColorScheme?: string;
  };
};

export type RawReportStylesConfig = RawVisualStylesConfig & {
  css?: string;
  cover?: {
    fontStyle?: RBFont;
    fontColor?: RBColor;
    backgroundEnabled?: boolean;
    backgroundColor?: RBColor;
  };
  pagination?: {
    hide?: boolean;
    fontStyle?: RBFont;
    fontColor?: RBColor;
  };
  header?: {
    hideReportTitle?: boolean;
  };
  footer?: {
    hideReportTitle?: boolean;
    customText?: string;
    fontStyle?: RBFont;
    fontColor?: RBColor;
    showEntity?: 'inherit' | 'true' | 'false';
    showDate?: 'inherit' | 'true' | 'false';
    showCurrency?: 'inherit' | 'true' | 'false';
    logoPosition?: 'inherit' | FooterLogoPositionType;
    logoWidth?: number;
    dateFormat?: string;
  };
};
