import React, { Profiler, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type MeasureRenderTimeProps = {
  id: string;
  label: string;
  children: React.ReactNode;
};

/**
 * This component will measure render time and display it in console
 * Add &profiler=true to URL to enable it
 * */

export const MeasureRenderTime = ({ id, label, children }: MeasureRenderTimeProps) => {
  const { search } = useLocation();
  const isProfilerMode = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('profiler') === 'true';
  }, [search]);

  const onRender = (id, phase, actualDuration) => {
    console.group(`${label}`);
    console.log(`ID: ${id}`);
    console.log(`Phase: ${phase}`);
    console.log(`Load time: ${actualDuration}`);
    console.groupEnd();
  };

  return !isProfilerMode ? (
    children
  ) : (
    <Profiler id={id} onRender={onRender}>
      {children}
    </Profiler>
  );
};
