import React, { useCallback } from 'react';
import { Text } from '@/common/components/Typography/Text';
import type { Asset } from '@/api/getAsset';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { siteTreeState } from '@/modules/App/recoil/app.atoms';
import { getAssetUrlInfo } from './utils';

interface LinkRedirectProps {
  asset: Asset;
}

const AssetLinkRedirect: React.FC<LinkRedirectProps> = ({ asset }) => {
  const history = useHistory();
  const siteTree = useRecoilValue(siteTreeState);

  const onClickLink = useCallback(() => {
    const { pathname, searchParams } = getAssetUrlInfo(String(asset.id), siteTree);

    history.push({ pathname, search: searchParams.toString() });
  }, [history, asset.id]);

  return (
    <div className="entity-links-single-entity">
      <Text onClick={onClickLink} link>
        {asset.name}
      </Text>
    </div>
  );
};

export { AssetLinkRedirect };
