import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useValidation } from '@/modules/Onboarding/hooks/useValidation';
import { Field, FormContext, useFormContext } from '@/common/form';
import { NoteDTO } from '@/modules/ERP/Contacts/types';
import { Button, message, Modal, Row, Typography } from 'antd';
import { TextInput } from '@/common/components/Input/TextInput';
import { contactNoteByIdSelector, contactsNotesSelector } from '@/modules/ERP/Contacts/Recoil/contacts.selectors';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { createLink } from '@/modules/ERP/Contacts/Api/createLink';
import { EntityLinkType, EntityLinkTypeMenu, LinkableObject, LinkLookup } from '@/common/types/entity/Link';
import { UUID } from '@/common/types/types';
import { TaskSiderItem } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/Form/SiderItem';
import { Dayjs } from 'dayjs';
import ReactQuill from 'react-quill';
import { SelectLinksContent } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/Form/SelectLinks';
import { existingListObjectsSelector, linkObjectSelector } from '@/modules/ERP/Common/Recoil/links.selector';
import { withSmallSuspense } from '@/common/suspense';
import { deleteLink } from '@/modules/ERP/Contacts/Api/deleteLink';
import { entityLinksSelector } from '@/modules/ERP/Common/Recoil/erp.selectors';
import { patchNote } from '@/modules/ERP/Contacts/Api/patchNote';
import { deleteNote } from '@/modules/ERP/Contacts/Api/deleteNote';
import { Text } from '@/common/components/Typography/Text';
import { descriptionMaxLength } from '../Tasks/CreateTask';
import { translateEntityObjectName, userListMentionQuill } from '@/modules/ERP/Common/utils';
import { handleHtmlContent } from '@/utils/handleHtmlContent';
import { loggedUserSelector, usersForCurrentUsers } from '@/common/auth/recoil/user.selector';

interface EditNoteProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  contactId: UUID;
  noteId: string;
  pageEntityType: EntityLinkTypeMenu;
  pageEntityId: string;
}

const EditNote: React.FunctionComponent<EditNoteProps> = withSmallSuspense(
  React.memo(({ contactId, isOpen, setIsOpen, noteId, pageEntityId, pageEntityType }) => {
    const translator = useIntl();
    const currentNote = useRecoilValue(contactNoteByIdSelector(noteId));
    const links = useRecoilValue(existingListObjectsSelector([EntityLinkType.NOTE, noteId]));
    const entityLinks = useRecoilValue(entityLinksSelector([EntityLinkType.NOTE, noteId]));

    const [form, setForms] = useState({
      title: currentNote[0].title,
      content: currentNote[0].content,
      date: currentNote[0].date,
      author: currentNote[0].author
    });

    const [originalsLinks] = useState<Array<LinkableObject>>(links);
    const [selectedLinks, setSelectedLinks] = useState<Array<LinkableObject>>(links);

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isDeletionOpen, setDeletionOpen] = useState(false);

    const onChange = useCallback(
      (values: any, key: string, value: string | Dayjs | { id: number }) => {
        const newState = { ...values, [key]: value };

        setForms(newState);
      },
      [setForms]
    );

    const validation = useValidation(
      [
        {
          name: 'title',
          required: true
        },
        {
          name: 'content',
          required: true
        }
      ],
      form
    );

    const handleContentChange = useCallback((content: string) => onChange(form, 'content', content), [form, onChange]);

    const handleChangeQuill = useCallback((content: string) => handleHtmlContent(handleContentChange)(content), [handleContentChange]);

    const formContext = useFormContext(form, onChange, false, false);

    const linksToCreate = useMemo(() => {
      return selectedLinks.filter(obj => {
        return !originalsLinks.find(item => item._entityType === obj._entityType && item.id === obj.id);
      });
    }, [selectedLinks, originalsLinks]);

    const linksToDelete = useMemo(() => {
      const linksToDelete: Array<LinkLookup> = [];

      originalsLinks
        .filter(object => {
          const foundSelectedObject = selectedLinks.find(item => item.id === object.id && item._entityType === object._entityType);

          return !foundSelectedObject;
        })
        .forEach(object => {
          const existingLinks = entityLinks.filter(
            link => link.type === object._entityType && link.entityId === String(object.id) && !linksToDelete.find(linkToDelete => linkToDelete.id === link.id)
          );

          linksToDelete.push(...existingLinks);
        });

      return linksToDelete;
    }, [originalsLinks, selectedLinks, entityLinks]);

    const onClickSave = useRecoilCallback(
      ({ refresh, snapshot }) =>
        () => {
          const release = snapshot.retain();

          setConfirmLoading(true);
          patchNote(noteId, form as Partial<NoteDTO>)
            .then(async responseNote => {
              try {
                const pageEntity = await snapshot.getPromise(
                  linkObjectSelector({
                    type: pageEntityType,
                    id: pageEntityId
                  })
                );
                await Promise.all(
                  linksToDelete.map(obj => {
                    deleteLink(EntityLinkType.NOTE, responseNote.id, obj.id, translateEntityObjectName(pageEntity as LinkableObject)!, responseNote.title);
                  })
                );

                await Promise.all(
                  linksToCreate.map(obj => {
                    createLink(EntityLinkType.NOTE, responseNote.id, {
                      type: obj._entityType,
                      entityId: obj.id as UUID
                    });
                  })
                );

                message.success(translator.formatMessage({ id: 'generic.successfullyUpdated' }));
                setIsOpen(false);
                setTimeout(() => {
                  refresh(contactsNotesSelector(['', pageEntityType as unknown as EntityLinkType, contactId]));
                  refresh(contactNoteByIdSelector(noteId));
                  refresh(existingListObjectsSelector([EntityLinkType.NOTE, noteId]));
                  if (pageEntityType && pageEntityId) {
                    refresh(
                      linkObjectSelector({
                        type: EntityLinkType.NOTE,
                        id: noteId
                      })
                    );
                  }
                }, 1000);
              } catch (err) {
                message.error(
                  translator.formatMessage({
                    id: 'generic.somethingWentWrongWhenSavingLinks'
                  })
                );
              }
            })
            .catch(error => {
              message.error(error);
            })
            .finally(() => {
              release();
              setConfirmLoading(false);
            });
        },
      [form, linksToCreate, linksToDelete, pageEntityId, pageEntityType]
    );

    const handleDeleteNote = useCallback(() => {
      setDeletionOpen(true);
    }, []);

    const confirmDeleteNote = useRecoilCallback(
      ({ refresh }) =>
        () => {
          deleteNote(noteId, currentNote[0].title)
            .then(() => {
              message.success(translator.formatMessage({ id: 'generic.successfullyDeleted' }));
            })
            .finally(() => {
              setDeletionOpen(false);
              setIsOpen(false);
              setTimeout(() => {
                refresh(contactsNotesSelector(['', pageEntityType as unknown as EntityLinkType, contactId]));
                refresh(contactNoteByIdSelector(noteId));
                refresh(existingListObjectsSelector([EntityLinkType.NOTE, noteId]));
              }, 1000);
            });
        },
      [noteId]
    );

    const loggedUser = useRecoilValue(loggedUserSelector);
    const users = useRecoilValue(usersForCurrentUsers(loggedUser.accountId));

    const [mention, setMention] = useState({});
    useEffect(() => {
      setMention({
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        defaultMenuOrientation: 'top',
        source: (searchTerm: any, renderList: any) => userListMentionQuill(searchTerm, renderList, users)
      });
    }, [users]);

    const modules = useMemo(() => {
      return {
        toolbar: {
          container: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ size: ['small', false, 'large', 'huge'] }, { color: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
            ['link']
          ]
        },
        clipboard: { matchVisual: false },
        mention: mention
      };
    }, [mention]);

    return (
      <Modal
        footer={[
          <Button style={{ display: 'block', float: 'left' }} key="delete" onClick={handleDeleteNote}>
            <FormattedMessage id={'generic.deleteNote'} />
          </Button>,
          <Button key="cancel" type="ghost" onClick={() => setIsOpen(false)}>
            <FormattedMessage id={'generic.cancel'} />
          </Button>,
          <Button key="save" type="primary" onClick={onClickSave}>
            <FormattedMessage id={'generic.saveNote'} />
          </Button>
        ]}
        width={'600px'}
        className={'modal-contact-create modal-contact-edit'}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        okText={translator.formatMessage({ id: 'saveNote' })}
        okButtonProps={{ disabled: !validation }}
        destroyOnClose
        confirmLoading={confirmLoading}
      >
        <Typography.Title level={4}>{translator.formatMessage({ id: 'generic.editNote' })}</Typography.Title>
        <FormContext.Provider value={formContext}>
          <Row style={{ width: '100%' }}>
            <Field className={'modal-contact-create-title-field'} withoutColons property="title" label={translator.formatMessage({ id: 'generic.title' })}>
              <TextInput maxLength={100} style={{ borderRadius: '5px' }} placeholder={translator.formatMessage({ id: 'generic.title' })} />
            </Field>
          </Row>
          <Row style={{ width: '100%', display: 'block' }}>
            <label>{translator.formatMessage({ id: 'generic.description' })}</label>
            <ReactQuill
              placeholder={translator.formatMessage({
                id: 'generic.enterNoteDescription'
              })}
              modules={modules}
              onChange={handleChangeQuill}
              defaultValue={currentNote[0].content}
            />
            <Row justify="end">
              <Text type={form.content?.replace(/<[^>]*>?/gm, '').length ? 'danger' : undefined}>
                {form.content?.replace(/<[^>]*>?/gm, '').length ?? 0} / {descriptionMaxLength}
              </Text>
            </Row>
          </Row>
        </FormContext.Provider>
        <Row>
          <EditNoteSider setSelectedLinks={setSelectedLinks} selectedLinks={selectedLinks} />
        </Row>
        <Modal
          onOk={confirmDeleteNote}
          onCancel={() => setDeletionOpen(false)}
          open={isDeletionOpen}
          title={
            <>
              <ExclamationCircleOutlined style={{ margin: '5px', color: '#FAAD14', fontSize: '16px' }} />
              <FormattedMessage id={'generic.warning'} />
            </>
          }
        >
          <FormattedMessage id={'generic.notes.messageNoteRemoval'} />
        </Modal>
      </Modal>
    );
  })
);

interface EditNoteSiderProps {
  setSelectedLinks: React.Dispatch<SetStateAction<Array<LinkableObject>>>;
  selectedLinks: Array<LinkableObject>;
}

const EditNoteSider: React.FunctionComponent<EditNoteSiderProps> = React.memo(({ setSelectedLinks, selectedLinks }) => {
  const translator = useIntl();

  return (
    <TaskSiderItem
      title={translator.formatMessage({ id: 'generic.links' })}
      content={<SelectLinksContent selectedLinks={selectedLinks} setSelectedLinks={setSelectedLinks} defaultSelectedType={EntityLinkType.ASSET} />}
      clickableText={translator.formatMessage({ id: 'generic.xNumberLinks' }, { number: selectedLinks.length })}
    />
  );
});

export { EditNote };
