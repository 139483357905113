import { AggregationMethod, QuantCatalogueItemMeta } from '@/common/types/entity/QuantCatalogueItem';
import requestApi from '@/core/requestApi';
import { DataFrame } from 'data-forge';
import dayjs from 'dayjs';
import listQuantCatalogueItemsMetas from '@/modules/report-builder/api/listQuantCatalogueItemsMetas';
import { QuantUtils } from '@/modules/report-builder/components/Quant/QuantUtils';
import { ColumnMetas } from '@/modules/reporting-v2/types/Column';
import { Dict } from '@/modules/reporting-v2/types/Common';
import { DateRange } from '@/modules/reporting-v2/types/DateRange';
import { FieldName } from '@/modules/reporting-v2/types/Field';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { Index } from '@/modules/reporting-v2/types/Index';
import { IndexNodeName } from '@/modules/reporting-v2/types/IndexNode';
import { ConsolidationType, ElasticCallKey, ElasticRequestPayload, ReferenceNodeKey, ReportParams, RowKey } from '@/modules/reporting-v2/types/ReportingService';
import { addToInnerInnerMap, addToInnerMap, addValuesToInnerArray, groupBy, uniqueValues, uniqueValuesForKey } from '@/modules/reporting-v2/utils/CollectionUtils';
import { arrayAsKey, concatenateUniqueIndexFields } from '@/modules/reporting-v2/utils/IndexUtils';
import { getNativeFormattedDate } from '@/utils/getNativeDate';
import { PopupHelper } from '@/utils/PopupHelper';
import { EntityParameters } from '@/common/types/app/EntityParametersHandler';
import { LookThroughTypes } from '@/config/lookThroughTypes';
import { error } from '@/utils/error';
import datasourceConfigs from '../config/datasource';
import reportQueryStringParams from '../config/reportQueryStringParams';
import { HistoricalChartDateRangePeriod, HistoricalRange } from '../types/HistoricalConfig';
import { ReportParamsConfig, VisualComponent } from '../types/ReportBuilderTypesUtils';
import { Field } from './Field';
import { Filter } from './Filter';
import { HistoricalConfig } from './HistoricalConfig';
import { IndexNode } from './IndexNode';
import { computeReferences } from './utils';
import { VisualEngine } from './VisualEngine';
import { FormatType } from '@/common/types/elastic/FormatType';
import { getRecoilState } from '@/core/RecoilExternalStatePortal';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import retrieveMetadata from '@/api/retrieveMetadata';
import retrieveCustomMetadata from '@/api/retrieveCustomMetadata';
import { ElasticMetricAdapter } from '@/modules/report-builder/components/Quant/ElasticMetricAdapter';
import { UUID } from '@/common/types/types';
import { DashboardTableConfig } from '@/modules/reporting-v2/core/visuals';
import { findHoldingSetById } from '@/utils/findHoldingSet';
import { DATE_FORMAT } from 'ui-sesame-components';

export const holdingSetIdProperty = 'holdingSetId';

export const defaultReportParamConfig: ReportParamsConfig = {
  dateEnabled: false,
  holdingSetEnabled: true,
  consolidationTypeEnabled: true,
  endDateEnabled: false,
  multiHoldingSetEnabled: false
};

const getByPathSlice = <T>(data: Record<string, T>, listProp: string) => {
  const slice = listProp.split(Field.fieldSeparator);

  let __deepListWorkaround = undefined;

  const value = slice.reduce((acc, val) => {
    if (Array.isArray(acc)) {
      __deepListWorkaround = true;
      return acc;
    }

    return (acc as unknown as Record<string, T>)?.[val];
  }, data[slice.shift()!]);

  return { value, __deepListWorkaround };
};

const flatObject = (data: Record<string, any>, index: Index, parentNode: string[], rootProp: Record<string, Object>): FlattenObject => {
  let flattenObject: FlattenObject = {};
  for (const prop of Object.keys(data)) {
    const key = `${index}.${parentNode.concat(prop).join(Field.fieldSeparator)}`;

    if (typeof data[prop] === 'object' && JSON.stringify(data[prop]) !== '{}' && typeof data[prop].foreignKey === 'undefined' && typeof data[prop].primaryKey === 'undefined') {
      flattenObject = Object.assign(flattenObject, flatObject({ ...rootProp, ...data[prop] }, index, parentNode.concat(prop), rootProp));
    } else {
      flattenObject[key] = data[prop];
    }
  }

  return flattenObject;
};

type ElasticDataHit = Record<string, Primitive | Array<FlattenObject> | Record<string, Primitive | Array<FlattenObject>>>;

type DataKeyFieldWithReferenceData = [FieldName[], Map<RowKey, FlattenObject>, Index];

class ReferenceWithCallKey {
  constructor(reference: Reference, mergedCallKey: ElasticCallKey) {
    this.reference = reference;
    this.mergedCallKey = mergedCallKey;
  }

  reference: Reference;
  mergedCallKey: ElasticCallKey;

  key(): ReferenceNodeKey {
    return arrayAsKey([this.reference.key(), this.mergedCallKey]);
  }
}

class Reference {
  constructor(primaryVisualNode: IndexNode, referenceNode: IndexNode) {
    this.primaryVisualNode = primaryVisualNode;
    this.referenceNode = referenceNode;
  }

  primaryVisualNode: IndexNode;
  referenceNode: IndexNode;

  key(): ReferenceNodeKey {
    return arrayAsKey([this.primaryVisualNode.node, this.referenceNode.node]);
  }
}

export interface ElasticEntityParameter extends Omit<EntityParameters, 'lookThrough'> {
  consolidationType: ConsolidationType;
}

export enum ColourGroupType {
  ASSET_TYPE = 'ASSET_TYPE',
  ASSET_CATEGORY = 'ASSET_CATEGORY',
  REGION = 'REGION',
  SECTOR = 'SECTOR'
}

export interface ColourGroup {
  id: UUID;
  type: ColourGroupType;
  name: string;
  colour: string;
}

export interface GroupByColourItem {
  measure: string;
  colour: ColourGroup['colour'];
  value: ColourGroup['name'];
}

class ElasticCall {
  constructor(index: Index, historicalConfig: HistoricalConfig, fields: Field[], params: Array<ElasticEntityParameter>) {
    this.index = index;
    this.historicalConfig = historicalConfig;
    this.fields = fields;
    this.params = params;
  }

  index: Index;
  historicalConfig: HistoricalConfig;
  fields: Field[];
  params: Array<ElasticEntityParameter>;

  key(): ElasticCallKey {
    return ElasticCall.buildElasticCallKey(this.index, this.historicalConfig);
  }

  static buildElasticCallKey(index: Index, historicalConfig: HistoricalConfig): ElasticCallKey {
    const dateEnabled = ReportingService.rawMetas[index].__config.dateEnabled;
    return arrayAsKey([index, !dateEnabled ? HistoricalConfig.disabled().key() : historicalConfig.key()]);
  }
}

class ReportingService {
  public static metas: Record<string, ColumnMetas>;
  public static rawMetas: Record<Index | string, any> = {} as Record<Index, any>;
  public static quantMetas: Record<string, QuantCatalogueItemMeta>;
  public static referenceCache: Map<string, Map<string, Array<Field>>> = new Map();

  static async warmUp() {
    if (ReportingService.metas) {
      return Promise.resolve();
    }

    const metas: Array<Promise<QuantCatalogueItemMeta[] | FlattenObject>> = [listQuantCatalogueItemsMetas(), retrieveMetadata(), retrieveCustomMetadata()];

    const [quantCatalogue, elasticMetadata, customMetadata] = await Promise.all(metas);

    const flattenedMetadata = Object.keys(elasticMetadata)
      .concat([Index.metadata])
      .map(index => {
        let indexMetadata: any;

        if (index === Index.metadata) {
          indexMetadata = customMetadata;
        } else {
          indexMetadata = // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            elasticMetadata[index];
        }

        Reflect.set(ReportingService.rawMetas, index, indexMetadata);

        const rootProp: Record<string, object> = {};

        if (indexMetadata?.__config?.dateEnabled) {
          rootProp.date = {};
        }

        if (indexMetadata?.__config?.consolidationTypeEnabled) {
          rootProp.consolidationType = {};
        }

        if (indexMetadata?.__config?.holdingSetEnabled) {
          rootProp.holdingSetId = {};
        }

        reportQueryStringParams.forEach(queryStringParam => {
          if (indexMetadata?.__config?.[queryStringParam.enabled]) {
            rootProp[queryStringParam.field] = {};
          }
        });

        return flatObject(indexMetadata, index as Index, [], rootProp);
      });

    ReportingService.quantMetas = QuantUtils.generatePersistentMetadata(quantCatalogue as QuantCatalogueItemMeta[]);

    const metadata = Object.assign({}, ...flattenedMetadata);

    const popupsMetadata = Object.fromEntries(PopupHelper.PopupFields.map(field => [field, PopupHelper.GetPopupMetadata(field)]));
    const metadataWithColumns = Object.assign(metadata, popupsMetadata) as Record<string, ColumnMetas>;

    ReportingService.metas = metadataWithColumns;
    Object.entries(ReportingService.quantMetas).forEach(([key, value]) => {
      ReportingService.metas[key] = ElasticMetricAdapter.transformQuantMetadata(value);
    });
  }

  async refetchVisualData(visuals: VisualEngine[], reportParamConfig: ReportParamsConfig, reportParams: ReportParams, globalFilters: Filter[], crossFilters: Filter[]) {
    const elasticCalls = this.retrieveElasticCalls(visuals, reportParams);
    const [mergedElasticCalls, originalCallKeyToMergedCallKey] = this.mergeSimilarElasticCalls(elasticCalls);
    const fetchedData = await this.fetchData(mergedElasticCalls, reportParams);
    const flatten = this.flatten(mergedElasticCalls, fetchedData);
    const referenceNodes = this.retrieveReferences(visuals, originalCallKeyToMergedCallKey);
    const referenceDataMap = this.buildReferenceDataMap(referenceNodes, flatten);

    for (const visual of visuals) {
      const data = this.buildVisualData(
        visual,
        flatten,
        referenceDataMap,
        originalCallKeyToMergedCallKey,
        visual.entityOrdering.map(rank => reportParams.holdingSets[rank].holdingSetId)
      );

      delete visual.initialDataframe;

      visual.generateProcessedData(data, globalFilters, crossFilters);
    }
  }

  async buildReportData(reportParamConfig: ReportParamsConfig, reportParams: ReportParams, visualConfigs: VisualEngine[]): Promise<Map<string, FlattenObject[]>> {
    const elasticCalls = this.retrieveElasticCalls(visualConfigs, reportParams);
    const [mergedElasticCalls, originalCallKeyToMergedCallKey] = this.mergeSimilarElasticCalls(elasticCalls);
    const fetchedData = await this.fetchData(mergedElasticCalls, reportParams);
    const flatten = this.flatten(mergedElasticCalls, fetchedData);
    const referenceNodes = this.retrieveReferences(visualConfigs, originalCallKeyToMergedCallKey);
    const referenceDataMap = this.buildReferenceDataMap(referenceNodes, flatten);

    const visualsData = new Map();

    for (const visualConfiguration of visualConfigs) {
      const data = this.buildVisualData(
        visualConfiguration,
        flatten,
        referenceDataMap,
        originalCallKeyToMergedCallKey,
        visualConfiguration.entityOrdering.filter(index => index in reportParams.holdingSets).map(rank => reportParams.holdingSets[rank].holdingSetId),
        reportParamConfig.multiHoldingSetEnabled
      );
      visualsData.set(visualConfiguration.id, data);
    }

    return visualsData;
  }

  /**
   * This will create calls based on visuals' configuration
   */
  retrieveElasticCalls(visuals: readonly VisualEngine[], params: ReportParams): ElasticCall[] {
    const currentUser = getRecoilState(currentUserSelector);
    const verifiedDates = currentUser.verifiedDates;

    return visuals.flatMap(visual => {
      let mappedVisualHoldingSets: Array<EntityParameters> = [];

      const isDashboardTable = visual instanceof DashboardTableConfig;
      if (isDashboardTable) {
        mappedVisualHoldingSets = params.holdingSets!;
      } else {
        const visualHoldingSets = !visual.multiEntity ? [params.holdingSets[visual.entityOrdering[0]]] : visual.entityOrdering.map(val => params.holdingSets[val]);

        mappedVisualHoldingSets = visualHoldingSets.map(entityParams => {
          const newParam = { ...entityParams };

          if (visual.historicalConfig.enabled) {
            const oldDate = newParam.date;

            delete newParam.date;

            if (!newParam.range) {
              const lastVerifiedDate = verifiedDates[newParam.holdingSetId];
              let to = oldDate ?? lastVerifiedDate ?? getNativeFormattedDate();

              if (to !== lastVerifiedDate && dayjs(to).isAfter(lastVerifiedDate)) {
                to = lastVerifiedDate;
              }

              const from = ReportingService.calculateFromDate(to, visual.historicalConfig.range ?? HistoricalRange.LAST_THREE_MONTHS);

              newParam.range = new DateRange({ from, to });
            }
          }

          if (newParam.date) {
            const lastVerifiedDate = verifiedDates[newParam.holdingSetId];
            if (newParam.date !== lastVerifiedDate && dayjs(newParam.date).isAfter(lastVerifiedDate)) {
              newParam.date = lastVerifiedDate;
            }
          }

          return newParam;
        });
      }

      const indices = uniqueValues(visual.getAllUsedFields().map(field => field.getIndex())).filter(indice => datasourceConfigs.has(indice));
      const fields = visual.getFieldsToRetrieve();

      mappedVisualHoldingSets = this.appendHoldingSetParamChildren(visual, params, mappedVisualHoldingSets);

      return indices.map(index => {
        const indexDateEnabled = ReportingService.rawMetas[index].__config.dateEnabled;
        const visualHistoricalConfig = indexDateEnabled ? visual.historicalConfig : HistoricalConfig.disabled();

        return new ElasticCall(
          index,
          visualHistoricalConfig,
          fields.filter(field => field.getIndex() === index),
          mappedVisualHoldingSets.map(param => {
            return this.processIndiceParameters(index, param, visualHistoricalConfig, verifiedDates);
          })
        );
      });
    });
  }

  private appendHoldingSetParamChildren(visual: VisualEngine, params: ReportParams, mappedVisualHoldingSets: EntityParameters[]) {
    if (visual.component === VisualComponent.DashboardTable && visual.crossFilterReceiver) {
      const holdingSetTree = getRecoilState(currentUserSelector).holdingSetTree;
      const holdingSet = holdingSetTree.find(holdingSetItem => holdingSetItem.id === params.holdingSets[visual.entityOrdering[0] ?? 0].holdingSetId);
      if (holdingSet) {
        mappedVisualHoldingSets = mappedVisualHoldingSets.concat(
          holdingSet.childrenIds.map(holdingSetId => ({
            holdingSetId
          }))
        );
      }
    }
    return mappedVisualHoldingSets;
  }

  /**
   * This will merge calls that are one the same index and same parameters based on some merging rules
   * Moreover, we also need to return a mapping from original ElasticCallKey to merged one, so we will be able to know where to find data for each field when
   * data is received from merged calls
   * @param elasticCalls
   */
  mergeSimilarElasticCalls(elasticCalls: ElasticCall[]): [Map<ElasticCallKey, ElasticCall>, Map<ElasticCallKey, ElasticCallKey>] {
    const mergedElasticCalls = new Map();
    const originalCallKeyToMergedCallKey = new Map();

    const elasticCallsByIndexAndHistoricalConfig = groupBy<ElasticCall, string, ElasticCall>(elasticCalls, elasticCall =>
      arrayAsKey([elasticCall.index, elasticCall.historicalConfig.enabled, elasticCall.historicalConfig.range])
    );

    for (const calls of elasticCallsByIndexAndHistoricalConfig.values()) {
      const historicalConfigEnabledGroup = calls[0].historicalConfig.enabled;

      const retainedParams: Array<ElasticEntityParameter> = [];

      if (historicalConfigEnabledGroup) {
        retainedParams.push(...Array.from(this.mergeHistoricalParams(calls).values()));
      } else {
        retainedParams.push(...Array.from(this.mergeNonHistoricalParams(calls).values()));
      }

      const retainedFields = calls.map(it => it.fields).reduce((prev, curr) => concatenateUniqueIndexFields(prev, curr), []);

      let retainedHistoricalConfig = calls.reduce((config, call) => {
        if ((!config.enabled && call.historicalConfig.enabled) || (!config.sampling && call.historicalConfig.sampling)) {
          return call.historicalConfig;
        }

        return config;
      }, HistoricalConfig.disabled());

      const callIsFlow = calls[0].index === Index.flows;
      const callIsLimit = calls[0].index === Index.limits;
      const callIsPerformance = calls[0].index === Index.performance;
      if (retainedHistoricalConfig.enabled) {
        retainedHistoricalConfig = HistoricalConfig.enabled(retainedHistoricalConfig.range, !(callIsFlow || callIsLimit || callIsPerformance));
      }

      const mergedCall = new ElasticCall(calls[0].index, retainedHistoricalConfig, retainedFields, retainedParams);

      mergedElasticCalls.set(mergedCall.key(), mergedCall);

      for (const call of calls) {
        originalCallKeyToMergedCallKey.set(call.key(), mergedCall.key());
      }
    }

    return [mergedElasticCalls, originalCallKeyToMergedCallKey];
  }

  private mergeNonHistoricalParams(calls: ElasticCall[]) {
    const paramsMap = new Map<string, ElasticEntityParameter>();

    for (const { params } of calls) {
      params.forEach(param => {
        const key = arrayAsKey([param.holdingSetId, param.consolidationType, param.date]);
        const existingParam = paramsMap.get(key);

        if (!existingParam) {
          paramsMap.set(key, param);
        }
      });
    }

    return paramsMap;
  }

  /**
   *
   * @param calls
   * @private
   * The idea is that given a list of elastic calls that are historical enabled, we want to minimize the amount of holdingsets it is calling for. E.g -> [{holdingSetId: 28, range: "2020-04-23|2020-04-26"}, {holdingSetId: 28, range: "2020-04-20|2020-04-26"}]
   * can be turned into [{holdingSetId: 28, range: "2020-04-20|2020-04-26"}] only, as the from is earlier in the second param, we merge them. There can only be one holdingSetId|lookThrough combination at a time per historical params, all historical configs will be
   * merged to be optimized.
   */
  private mergeHistoricalParams(calls: ElasticCall[]) {
    const mergedParams = new Map<string, ElasticEntityParameter>();

    for (const { params } of calls) {
      params.forEach(param => {
        const key = arrayAsKey([param.holdingSetId, param.consolidationType]);

        const existingEntityParam = mergedParams.get(key);

        if (existingEntityParam && param.range) {
          if (param.range.from! < existingEntityParam.range!.from! || param.range.from! === undefined) {
            existingEntityParam.range!.from = param.range.from;
          }

          if (param.range.to! > existingEntityParam.range!.to!) {
            existingEntityParam.range!.to = existingEntityParam.range!.to;
          }
        } else {
          mergedParams.set(key, param);
        }
      });
    }

    return mergedParams;
  }

  private processIndiceParameters(indice: Index, param: EntityParameters, historicalConfig: HistoricalConfig, verifiedDates: Record<number, string>) {
    const dateEnabled = ReportingService.rawMetas[indice].__config.dateEnabled;
    const lookThroughEnabled = ReportingService.rawMetas[indice].__config.consolidationTypeEnabled;

    const newParam = {
      holdingSetId: param.holdingSetId
    } as ElasticEntityParameter;

    if (dateEnabled) {
      if (historicalConfig.enabled && param.range) {
        newParam.range = new DateRange({
          from: param.range.from,
          to: param.range.to
        });
      } else if (param.date) {
        newParam.date = param.date;
      } else {
        newParam.date = verifiedDates[newParam.holdingSetId];
      }
    }

    if (lookThroughEnabled) {
      if (param.lookThrough) {
        newParam.consolidationType = param.lookThrough === LookThroughTypes.Lookthrough ? ConsolidationType.LOOK_THROUGH : ConsolidationType.DIRECT;
      } else {
        const holdingSetTree = getRecoilState(currentUserSelector).holdingSetTree;
        const holdingSet = findHoldingSetById(holdingSetTree, param.holdingSetId);

        newParam.consolidationType = holdingSet?.lookThroughEnabled ? ConsolidationType.LOOK_THROUGH : ConsolidationType.DIRECT;
      }
    }

    return newParam;
  }

  /**
   * This is responsible to call elastic and fetch the data based on what we already have in the cache
   * @param reportParamConfig
   * @param elasticCalls
   * @param reportParams
   */
  fetchData(elasticCalls: Map<ElasticCallKey, ElasticCall>, reportParams: ReportParams): Promise<Map<ElasticCallKey, ElasticDataHit[]>> {
    const elasticCallsRequests: Set<Promise<[ElasticCallKey, ElasticDataHit[]]>> = new Set();

    for (const elasticCall of elasticCalls.values()) {
      if (!datasourceConfigs.has(elasticCall.index)) return Promise.reject(`Missing datasource config for index ${elasticCall.index}`);

      const payload = {
        holdingSets: elasticCall.params,
        source: {
          includes: elasticCall.fields.map(field => field.elasticFieldName())
        }
      } as ElasticRequestPayload;

      if (elasticCall.historicalConfig.enabled) {
        payload.sampling = elasticCall.historicalConfig.sampling;
      }

      const paramsConfig = ReportingService.rawMetas[elasticCall.index].__config;

      reportQueryStringParams.forEach(queryStringParam => {
        if (paramsConfig[queryStringParam.enabled] && reportParams[queryStringParam.param]) {
          payload[queryStringParam.param] = reportParams[queryStringParam.param];
        }
      });

      const datasourceConfig = datasourceConfigs.get(elasticCall.index)!;

      elasticCallsRequests.add(
        requestApi({
          method: 'post',
          url: datasourceConfig?.uri + '?v=1.0',
          service: datasourceConfig?.service,
          data: payload
        }).then(response => {
          if (response === undefined) error(`Undefined response from ${datasourceConfig?.uri + '?v=1.0'}`);
          const data = response.data['hits'] !== undefined ? response.data.hits.hits.map((hit: { _source: Dict<Primitive | Array<Primitive>> }) => hit._source) : [response.data];

          return [elasticCall.key(), data];
        })
      );
    }

    return Promise.all(elasticCallsRequests).then(data => new Map(data));
  }

  /**
   * Here the idea is to transform a JSON tree object (Object) to a FlattenObject for each node
   * @param elasticCalls
   * @param elasticDataPerCall the retrieve data from elastic
   */
  flatten<T extends Record<string, unknown> = ElasticDataHit>(
    elasticCalls: Map<ElasticCallKey, ElasticCall>,
    elasticDataPerCall: Map<ElasticCallKey, T[]>
  ): Map<ElasticCallKey, Map<IndexNodeName, Array<FlattenObject>>> {
    const flatObject = (data: Record<string, any>, index: Index, parentNode: string[] = []): FlattenObject => {
      const fieldSeparator = Field.fieldSeparator;
      let flattenObject: FlattenObject = {};
      for (const prop of Object.keys(data)) {
        if (typeof data[prop] === 'object' && data[prop] !== null && !Array.isArray(data[prop])) {
          flattenObject = Object.assign(flattenObject, flatObject(data[prop], index, parentNode.concat(prop)));
        } else {
          const key = `${index}.${parentNode.concat(prop).join(fieldSeparator)}`;
          flattenObject[key] = data[prop];
        }
      }

      return flattenObject;
    };

    const dataMap = new Map<ElasticCallKey, Map<IndexNodeName, Array<FlattenObject>>>();

    type DataSets = { values: Set<string>; nodes: Set<string> };

    const indexedNodes = new Map<Index, DataSets>();

    for (const val of elasticCalls.values()) {
      const nodes = new Set<string>();
      const values = new Set<string>();

      for (const value of val.fields) {
        const field = value;
        values.add(field.stripIndex());

        const node = field.stripEdges();

        node && nodes.add(node);
      }

      const existingIndexedNodes = indexedNodes.get(val.index);
      if (existingIndexedNodes) {
        values.forEach(val => existingIndexedNodes.values.add(val));
        nodes.forEach(node => existingIndexedNodes.nodes.add(node));
      } else {
        indexedNodes.set(val.index, { nodes, values });
      }
    }

    elasticDataPerCall.forEach((data, elasticCallKey) => {
      const index = elasticCalls.get(elasticCallKey)?.index ?? error(`Missing call for key ${elasticCallKey} !`);
      const nodeDataMap = new Map<string, FlattenObject[]>();

      const propertiesMap = indexedNodes.get(index)!;

      data.forEach((data: Record<string, any>) => {
        const rootData = {};

        propertiesMap.values.forEach(property => {
          if (property in data) {
            Object.assign(rootData, {
              [`${index}.${property}`]: data[property]
            });
          }
        });

        addValuesToInnerArray(nodeDataMap, index, [rootData]);

        propertiesMap.nodes.forEach(listProp => {
          let value = data[listProp];
          let __deepListWorkaround: true | undefined = undefined;

          if (value === undefined) {
            const resolvedData = getByPathSlice(data, listProp);

            value = resolvedData.value;
            __deepListWorkaround = resolvedData.__deepListWorkaround;
          }

          if (value === undefined) {
            return;
          }

          const key = `${index}.${listProp}`;

          if (Array.isArray(value)) {
            const listPath = __deepListWorkaround ? listProp.split(Field.fieldSeparator).shift()! : listProp;

            addValuesToInnerArray(
              nodeDataMap,
              key,
              value.map((item: Record<string, unknown>) => Object.assign(flatObject(item, index, [listPath]), rootData))
            );
          } else {
            addValuesToInnerArray(nodeDataMap, key, [Object.assign(flatObject(value, index, [listProp]), rootData)]);
          }
        });
      });

      dataMap.set(elasticCallKey, nodeDataMap);
    });

    return dataMap;
  }

  private retrieveVisualReferences(visual: VisualEngine, originalCallKeyToMergedCallKey: Map<ElasticCallKey, ElasticCallKey>, primaryIndexNode: IndexNode): ReferenceWithCallKey[] {
    return visual
      .getReferenceIndexNodes(primaryIndexNode)
      .map(
        referenceNode =>
          new ReferenceWithCallKey(
            new Reference(primaryIndexNode, referenceNode),
            originalCallKeyToMergedCallKey.get(ElasticCall.buildElasticCallKey(referenceNode.getIndex(), visual.historicalConfig)) ?? error('Missing original call key mapping')
          )
      );
  }

  /**
   * This method returns all visuals' references based on merged calls
   * @param visuals
   * @param originalCallKeyToMergedCallKey
   */
  retrieveReferences(visuals: readonly VisualEngine[], originalCallKeyToMergedCallKey: Map<ElasticCallKey, ElasticCallKey>): ReferenceWithCallKey[] {
    const referenceNodes = visuals.flatMap(visual => {
      const primaryIndexNode = visual.getPrimaryIndexNode();
      const secondaryIndexNode = visual.getSecondaryIndexNode();

      if (!primaryIndexNode) {
        return [];
      }

      const primaryReferences = this.retrieveVisualReferences(visual, originalCallKeyToMergedCallKey, primaryIndexNode);

      if (!secondaryIndexNode) return primaryReferences;

      const secondaryReferences = this.retrieveVisualReferences(visual, originalCallKeyToMergedCallKey, secondaryIndexNode);

      return [...primaryReferences, ...secondaryReferences];
    });

    return uniqueValuesForKey(referenceNodes, referenceWithCallKey => referenceWithCallKey.key());
  }

  /**
   * From elastic flatten data build reference maps
   * @param references
   * @param flattenData
   */
  buildReferenceDataMap(
    references: readonly ReferenceWithCallKey[],
    flattenData: Map<ElasticCallKey, Map<IndexNodeName, readonly FlattenObject[]>>
  ): Map<ElasticCallKey, Map<ReferenceNodeKey, Map<RowKey, FlattenObject>>> {
    const referenceKeyDataMap: Map<ElasticCallKey, Map<string, Map<string, Map<RowKey, FlattenObject>>>> = new Map();
    const referenceDataMaps: Map<ElasticCallKey, Map<ReferenceNodeKey, Map<RowKey, FlattenObject>>> = new Map();

    for (const referenceWithCallKey of references) {
      const referenceNodeData = flattenData.get(referenceWithCallKey.mergedCallKey)?.get(referenceWithCallKey.reference.referenceNode.node) ?? [];

      const referenceKeyFields = computeReferences(referenceWithCallKey.reference.primaryVisualNode, referenceWithCallKey.reference.referenceNode);

      let referenceDataMap: Map<RowKey, FlattenObject>;

      const referenceKey = arrayAsKey(referenceKeyFields.map(field => field.name));

      if (
        referenceKeyDataMap.has(referenceWithCallKey.mergedCallKey) &&
        referenceKeyDataMap.get(referenceWithCallKey.mergedCallKey)!.has(referenceWithCallKey.reference.referenceNode.node) &&
        referenceKeyDataMap.get(referenceWithCallKey.mergedCallKey)!.get(referenceWithCallKey.reference.referenceNode.node)!.has(referenceKey)
      ) {
        referenceDataMap = referenceKeyDataMap.get(referenceWithCallKey.mergedCallKey)!.get(referenceWithCallKey.reference.referenceNode.node)!.get(referenceKey)!;
      } else {
        referenceDataMap = new Map();

        const toAggregate = new Map<string, FlattenObject[]>();
        referenceNodeData.forEach(entry => {
          const key = arrayAsKey(referenceKeyFields.map(keyColumn => entry[keyColumn.name]));

          if (referenceDataMap.has(key)) {
            const hasDataToAggregateForThisKey = Array.isArray(toAggregate.get(key));
            if (hasDataToAggregateForThisKey) {
              toAggregate.get(key)!.push(entry);
            } else {
              toAggregate.set(key, [referenceDataMap.get(key)!, entry]);
            }
          } else {
            referenceDataMap.set(key, entry);
          }
        });

        toAggregate.forEach((values, key) => {
          referenceDataMap.set(key, this.aggregateRows(values));
        });

        addToInnerInnerMap(referenceKeyDataMap, referenceWithCallKey.mergedCallKey, referenceWithCallKey.reference.referenceNode.node, referenceKey, referenceDataMap);
      }

      addToInnerMap(referenceDataMaps, referenceWithCallKey.mergedCallKey, referenceWithCallKey.reference.key(), referenceDataMap);
    }

    return referenceDataMaps;
  }

  aggregateRows(flattenObjects: FlattenObject[]) {
    const dataFrame = new DataFrame(flattenObjects);

    const aggregate = (field: string) => {
      const values = dataFrame.deflate(item => item[field]);

      const metas = ReportingService.metas[field];
      switch (metas?.aggregation?.method) {
        case AggregationMethod.sum: {
          return values.sum();
        }
        case AggregationMethod.avg: {
          return values.average();
        }
        case AggregationMethod.weightedAvg: {
          return dataFrame.aggregate(0, (accum, value) => accum + (value[field] as number) * (value[metas?.aggregation?.weights!] as number));
        }
        default:
          return values.first();
      }
    };

    const aggregatedRow = {} as FlattenObject;

    Object.keys(flattenObjects[0]).forEach(key => {
      aggregatedRow[key] = aggregate(key);
    });

    return aggregatedRow;
  }

  buildVisualData(
    visual: VisualEngine,
    flattenData: Map<ElasticCallKey, Map<IndexNodeName, readonly FlattenObject[]>>,
    referenceDataMap: Map<ElasticCallKey, Map<ReferenceNodeKey, Map<RowKey, FlattenObject>>>,
    originalCallKeyToMergedCallKey: Map<ElasticCallKey, ElasticCallKey>,
    holdingSetIds: Array<number>,
    skipEntityProcessing = false
  ): Array<FlattenObject> {
    const primaryIndexNode = visual.getPrimaryIndexNode();

    if (!primaryIndexNode) {
      return [];
    }

    const buildData = (indexNode: IndexNode, secondary = false, primaryNodeData: FlattenObject[] = []) => {
      const nodeCall = ElasticCall.buildElasticCallKey(indexNode.getIndex(), visual.historicalConfig);
      const nodeMergedCall = originalCallKeyToMergedCallKey.get(nodeCall)!;

      const nodeDataKeyFieldsWithReferenceData: Array<DataKeyFieldWithReferenceData> = visual
        .getReferenceIndexNodes(indexNode)
        .filter(referenceIndexNode => !secondary || referenceIndexNode.node !== primaryIndexNode.node)
        .map(referenceIndexNode => {
          const nodeKeyFields = computeReferences(referenceIndexNode, indexNode).map(field => field.name);
          const referenceNodeCall = ElasticCall.buildElasticCallKey(referenceIndexNode.getIndex(), visual.historicalConfig);
          const referenceNodeMergedCall = originalCallKeyToMergedCallKey.get(referenceNodeCall)!;
          const reference = new Reference(indexNode, referenceIndexNode);
          const referenceIndexNodeData = referenceDataMap.get(referenceNodeMergedCall)!.get(reference.key())!;

          return [nodeKeyFields, referenceIndexNodeData, referenceIndexNode.getIndex()];
        });

      let nodeData = flattenData.get(nodeMergedCall)?.get(indexNode.node);
      if (!nodeData) return [];

      const data: Array<FlattenObject> = [];

      const entityIdField = indexNode.getEntityIdField();

      if (secondary) {
        nodeData = this.removeAlreadyUsedRows(primaryIndexNode, primaryNodeData, indexNode, nodeData);
      }

      if (entityIdField && !skipEntityProcessing) {
        this.enrichRowWithEntityProcessing(nodeData, data, nodeDataKeyFieldsWithReferenceData, skipEntityProcessing, holdingSetIds, entityIdField, primaryIndexNode);
      } else {
        this.enrichRowWithoutEntityProcessing(nodeData, data, nodeDataKeyFieldsWithReferenceData, primaryIndexNode);
      }

      return data;
    };

    const data = buildData(primaryIndexNode);

    if (visual.improvedLeftJoin) {
      const secondaryIndexNode = visual.getSecondaryIndexNode();
      if (secondaryIndexNode) {
        data.push(...buildData(secondaryIndexNode, true, data));
      }
    }

    this.handleArrayValues(visual, data);

    return data;
  }

  private handleArrayValues = (visual: VisualEngine, data: FlattenObject[]) => {
    const arrayKeys: string[] = visual.columns.filter(col => col.formatting?.type === FormatType.list).map(col => col.fieldDataPath);

    arrayKeys.forEach(key => {
      const newData: FlattenObject[] = [];

      for (const row of data) {
        const value = row[key];
        if (!Array.isArray(value)) {
          continue;
        }

        if (value.length === 1) {
          row[key] = value[0];
          continue;
        }

        if (value.length > 1) {
          for (let i = 0; i < value.length; i++) {
            const element = value[i];

            if (i === value.length - 1) {
              row[key] = element;
              continue;
            }

            const newRow = { ...row, [key]: element };
            newData.push(newRow);
          }
        }
      }

      data.push(...newData);
    });
  };

  private removeAlreadyUsedRows(
    primaryIndexNode: IndexNode,
    primaryNodeData: readonly FlattenObject[],
    secondaryIndexNode: IndexNode,
    secondaryNodeData: readonly FlattenObject[]
  ): FlattenObject[] {
    const secondaryReferences = computeReferences(primaryIndexNode, secondaryIndexNode);
    const primaryReferences = computeReferences(secondaryIndexNode, primaryIndexNode);

    return secondaryNodeData.filter(secondaryRow => {
      return !primaryNodeData.find(primaryRow => {
        return primaryReferences.every((reference, index) => {
          return primaryRow[reference.name] === secondaryRow[secondaryReferences[index]?.name];
        });
      });
    });
  }

  private enrichRowWithEntityProcessing(
    primaryNodeData: readonly FlattenObject[],
    data: Array<FlattenObject>,
    primaryNodeDataKeyFieldsWithReferenceData: Array<DataKeyFieldWithReferenceData>,
    skipEntityProcessing: boolean,
    holdingSetIds: Array<number>,
    entityIdField: string | undefined,
    primaryIndexNode: IndexNode
  ) {
    const skipRow = (row: FlattenObject) => !skipEntityProcessing && entityIdField && !holdingSetIds.includes(row[entityIdField]! as number);

    for (const primaryRow of primaryNodeData) {
      if (skipRow(primaryRow)) {
        continue;
      }

      data.push(this.enrichRow(primaryRow, primaryNodeDataKeyFieldsWithReferenceData, primaryIndexNode));
    }
  }

  private enrichRowWithoutEntityProcessing(
    primaryNodeData: readonly FlattenObject[],
    data: Array<FlattenObject>,
    primaryNodeDataKeyFieldsWithReferenceData: Array<DataKeyFieldWithReferenceData>,
    primaryIndexNode: IndexNode
  ) {
    for (const value of primaryNodeData) {
      data.push(this.enrichRow(value, primaryNodeDataKeyFieldsWithReferenceData, primaryIndexNode));
    }
  }

  private enrichRow(primaryRow: Dict<Primitive>, primaryNodeDataKeyFieldsWithReferenceData: Array<DataKeyFieldWithReferenceData>, primaryIndexNode: IndexNode) {
    const enrichedRow = { ...primaryRow } as FlattenObject;

    const primaryKeyIsAllocation = primaryIndexNode.getIndex() === Index.allocation;

    let restrictMapping = false;
    if (primaryKeyIsAllocation) {
      restrictMapping = primaryRow[primaryIndexNode.node + '.restrictMapping'] as boolean;
    }

    for (const value of primaryNodeDataKeyFieldsWithReferenceData) {
      const [primaryKeyFields, referenceIndexNodeData, referenceIndex] = value;

      const referenceIsAssetInfo = referenceIndex === Index.asset_info_undated;
      if (restrictMapping && !referenceIsAssetInfo) {
        continue;
      }

      const keyFromPrimary = arrayAsKey(primaryKeyFields.map(keyField => primaryRow[keyField]));
      const referenceRow = referenceIndexNodeData.get(keyFromPrimary);

      Object.assign(enrichedRow, referenceRow);
    }

    return enrichedRow;
  }

  static calculateFromDate(date: string | number, range: HistoricalRange | HistoricalChartDateRangePeriod): string | undefined {
    const endDate = dayjs(date);
    switch (range) {
      case HistoricalChartDateRangePeriod.LAST_4_YEARS:
      case HistoricalRange.FOUR_YEARS:
        return endDate.clone().subtract(4, 'years').format(DATE_FORMAT.DATE);
      case HistoricalChartDateRangePeriod.LAST_3_YEARS:
      case HistoricalRange.THREE_YEAR:
        return endDate.clone().subtract(3, 'years').format(DATE_FORMAT.DATE);
      case HistoricalChartDateRangePeriod.LAST_YEAR:
      case HistoricalRange.ONE_YEAR:
        return endDate.clone().subtract(1, 'years').format(DATE_FORMAT.DATE);
      case HistoricalChartDateRangePeriod.YEAR_TO_DATE:
      case HistoricalRange.YEAR_TO_DATE:
        return endDate.clone().startOf('year').format(DATE_FORMAT.DATE);
      case HistoricalChartDateRangePeriod.LAST_6_MONTH:
      case HistoricalRange.LAST_SIX_MONTHS:
        return endDate.clone().subtract(6, 'months').format(DATE_FORMAT.DATE);
      case HistoricalChartDateRangePeriod.LAST_3_MONTH:
      case HistoricalRange.LAST_THREE_MONTHS:
        return endDate.clone().subtract(3, 'months').format(DATE_FORMAT.DATE);
      case HistoricalChartDateRangePeriod.LAST_MONTH:
      case HistoricalRange.LAST_MONTH:
        return endDate.clone().subtract(1, 'months').format(DATE_FORMAT.DATE);
      case HistoricalChartDateRangePeriod.LAST_7_DAYS:
      case HistoricalRange.LAST_SEVEN_DAYS:
        return endDate.clone().subtract(7, 'days').format(DATE_FORMAT.DATE);
      case HistoricalRange.MONTH_TO_DATE:
        return endDate.clone().startOf('month').format(DATE_FORMAT.DATE);
      case HistoricalChartDateRangePeriod.FROM_INCEPTION:
      case HistoricalRange.INCEPTION:
        return undefined;
      default:
        return endDate.clone().subtract(3, 'years').format(DATE_FORMAT.DATE);
    }
  }
}

export { ReportingService, ElasticCall, Reference, ReferenceWithCallKey };
