import React, { FC, PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { FormContext } from './useFormContext';

import './style.css';

export interface FieldProps {
  className?: string;
  label?: string;
  property: string;
  onChange?: (value: any) => void;
  controlled?: boolean;
  valueProperty?: string;
  required?: boolean;
  help?: string;
  withoutColons?: boolean;
}

export const Field: FC<PropsWithChildren<FieldProps>> = ({
  className,
  label,
  children,
  property,
  onChange,
  valueProperty = 'value',
  controlled = true,
  required = false,
  help,
  withoutColons = false
}) => {
  if (React.Children.count(children) > 1) {
    throw new Error('Only one children is supported');
  }

  if (!React.isValidElement(children)) {
    throw new Error('Children is not a valid React element');
  }

  const form = useContext(FormContext);
  const formObject = form?.object;
  const bidirectional = form?.bidirectional;
  const mutateOriginalObject = form?.mutateOriginalObject;

  const [value, setValue] = useState(formObject ? formObject[property] : undefined);

  const updateValue = useCallback(
    (value: unknown) => {
      if (formObject) {
        let obj = formObject;

        if (mutateOriginalObject) {
          obj[property] = value;
        }

        onChange?.(value);
        form?.onChange?.(obj, property, value);
      }
      if (controlled) {
        setValue(value);
      }
    },
    [controlled, formObject, property, setValue, onChange, mutateOriginalObject, form]
  );

  // handle form to field changes
  const val = formObject ? formObject[property] : undefined;
  useEffect(() => {
    if (bidirectional && val) {
      setValue(val);
    }
  }, [val, bidirectional]);

  // handle object changes
  useEffect(() => {
    if (formObject) {
      setValue(formObject[property]);
    }
  }, [formObject, setValue, property]);

  // inject "value" and "onChange" for the actual field
  const child = useMemo(() => {
    const onChange = (obj: React.ChangeEvent | any) => {
      if (obj?.target) {
        updateValue(obj.target.value ?? obj.target.checked);
        return;
      }
      updateValue(obj);
    };

    return React.cloneElement(children as ReactElement, {
      [valueProperty]: value,
      onChange
    });
  }, [children, value, valueProperty, updateValue]);

  return form && formObject ? (
    <span className={cx('field-holder', className)}>
      {label && (
        <label className="label">
          {required && <span className="field-required-asterix">*&nbsp;</span>}
          {label}
          {help && (
            <Tooltip title={help} showArrow={false}>
              {' '}
              <QuestionCircleFilled style={{ fontSize: 12, color: 'rgba(0,0,0,0.3)' }} />
            </Tooltip>
          )}
          {!withoutColons && ':'}
        </label>
      )}
      <span className="field-container">{child}</span>
    </span>
  ) : null;
};
