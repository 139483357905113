import dayjs from 'dayjs';
import { cellDateRange } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/VisualSpecificProps';

export const historicalCellInRange = (year: string, month: string, range: string | undefined, latestValidatedDate: string | undefined): boolean => {
  const cellDate = dayjs(new Date(Number(year), Number(month) - 1));
  const currentDate = dayjs(latestValidatedDate);

  switch (range) {
    case cellDateRange.lastThreeYears: {
      const lastThreeYears = dayjs(latestValidatedDate).subtract(3, 'years');
      return cellDate >= lastThreeYears && cellDate <= currentDate;
    }
    case cellDateRange.lastTwelveMonths: {
      const lastTwelveMonths = dayjs(latestValidatedDate).subtract(12, 'months');
      return cellDate >= lastTwelveMonths && cellDate <= currentDate;
    }
    case cellDateRange.currentYear:
      return Number(year) === currentDate.get('year');
    default:
      // if no config range the cell should appear
      return true;
  }
};
