import React from 'react';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import { Field } from '@/modules/reporting-v2/core/Field';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import { config } from './config';
import schema from './schema.json';
import { flatToHierarchy } from './utils/FlatToHierarchy';
import RawDrilldownTableConfig from './types';
import { DrilldownTableConfig } from './DrilldownTableConfig';
import { DataTablev2 } from '@/modules/reporting-v2/core/components/DataTablev2/DataTable';

class DrilldownTable extends Visual {
  Loader = Loaders.Table;

  static configMapper(visualConfig: RawDrilldownTableConfig) {
    const { columns, ...rest } = visualConfig;
    return {
      ...rest,
      columns: mapVisualConfigColumns(columns)
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as DrilldownTableConfig;
  }

  getSchema() {
    return schema;
  }

  renderBody() {
    const visual = this.props.visual as DrilldownTableConfig;

    // TODO : Refacto this for performance
    const defaultColumns = visual.columns.filter(col => col.isDefault);
    const rows = flatToHierarchy(visual.data.rows, defaultColumns, {
      idField: new Field(visual.idField).getElasticPath(),
      parentIdField: new Field(visual.parentIdField).getElasticPath(),
      levelField: new Field(visual.levelField).getElasticPath()
    });
    const totals = visual.getTotals(rows, visual.columns);

    if (this.props.visual.tableVersion === 'v1') {
      return (
        <DataTable
          visual={
            {
              ...this.props.visual,
              data: {
                rows,
                totals,
                maxRangeValues: visual.data.maxRangeValues,
                totalsByEntity: new Map(),
                rowsByEntity: new Map()
              }
            } as IDataTableProps['visual']
          }
        />
      );
    }

    return (
      <DataTablev2
        visual={
          {
            ...this.props.visual,
            data: {
              rows,
              totals,
              maxRangeValues: visual.data.maxRangeValues,
              totalsByEntity: new Map(),
              rowsByEntity: new Map()
            }
          } as IDataTableProps['visual']
        }
      />
    );
  }
}

export default DrilldownTable;
