import React, { createContext, useContext, useMemo } from 'react';
import type { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';

type OwnershipDropdownContextWrapperProps = {
  children: React.ReactElement;
  visual: VisualEngine;
};

type OwnershipDropdownContextValue = boolean;

const OwnershipDropdownContext = createContext<OwnershipDropdownContextValue>(false);

const OwnershipDropdownContextWrapper: React.FC<OwnershipDropdownContextWrapperProps> = ({ children, visual }) => {
  const ownershipDropdown = useMemo(() => {
    return 'ownershipDropdown' in visual && visual.ownershipDropdown === true;
  }, [visual]);

  return <OwnershipDropdownContext.Provider value={ownershipDropdown}>{children}</OwnershipDropdownContext.Provider>;
};

const useOwnershipDropdownContext = () => {
  const ownershipDropdownContextValue = useContext(OwnershipDropdownContext);

  return ownershipDropdownContextValue;
};

export { OwnershipDropdownContextWrapper, useOwnershipDropdownContext };
