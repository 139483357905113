import { requestApi } from '@/core';
import { DocumentInbox } from '../types/DocumentInbox';

export default (data: Partial<DocumentInbox>): Promise<DocumentInbox> =>
  requestApi({
    method: 'POST',
    url: `/api/document-inbox`,
    service: 'DOCUMENT',
    data,
  }).then(response => {
    return response.data;
  });
