export const groupBy = <T extends object>(itemsToGroup: T[], groupFn: (item: T) => string): Record<string, T[]> => {
  const groups = {} as Record<string, T[]>;

  itemsToGroup.forEach(item => {
    const groupValue = groupFn(item);

    if (groupValue in groups) {
      groups[groupValue].push(item);
    } else {
      groups[groupValue] = [item];
    }
  });

  return groups;
};
