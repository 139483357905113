import { createContext, useEffect, useState } from 'react';

export interface FormContextProps<T> {
  object?: any;
  bidirectional: boolean;
  mutateOriginalObject: boolean;
  onChange?: (object: T, property: string, value: any) => void;
}

export const FormContext = createContext<FormContextProps<any> | null>(null);

export type FormChangeHandler<T> = (object: T, property: string, value: any) => void;

export function useFormContext<T>(object?: T, onChange?: FormChangeHandler<T>, bidirectional = false, mutateOriginalObject = true) {
  const [context, setContext] = useState<FormContextProps<T> | null>({
    object,
    bidirectional,
    onChange,
    mutateOriginalObject
  });
  useEffect(() => {
    setContext({
      object,
      bidirectional,
      mutateOriginalObject,
      onChange
    });
  }, [object, bidirectional, onChange, mutateOriginalObject]);
  return context;
}
