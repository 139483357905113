import { Branding, ParsedBrandingHeaderFooter } from '@/common/types/app/User';

export const getBrandingStyle = (branding: Branding): ParsedBrandingHeaderFooter => {
  try {
    const newHeaderFooterStyle: ParsedBrandingHeaderFooter = JSON.parse(branding?.footer);

    const hasHeaderFooterStyle = typeof newHeaderFooterStyle === 'object' && newHeaderFooterStyle.customHeaderFooter;

    if (hasHeaderFooterStyle) {
      return newHeaderFooterStyle;
    }
  } catch (e) {}

  return {};
};
