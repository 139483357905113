import { getColourGroups, getSiteTreeEntryUserPreferences, updateSiteTreeEntryPreferences } from '@/api';
import { EntityParameters, EntityParametersHandler } from '@/common/types/app/EntityParametersHandler';
import { IParams } from '@/common/types/app/Params';
import memoize from 'fast-memoize';
import React from 'react';
import { REPORT_BUILDER_CANVAS_SIZE } from '@/modules/report-builder/hooks/useReportBuilder';
import { Condition } from '@/modules/reporting-v2/core/Condition';
import { Field } from '@/modules/reporting-v2/core/Field';
import { Filter } from '@/modules/reporting-v2/core/Filter';
import { ColourGroup, ReportingService } from '@/modules/reporting-v2/core/ReportingService';
import { createReportConfig } from '@/modules/reporting-v2/core/ReportViewerConfig';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { DashboardTableConfig } from '@/modules/reporting-v2/core/visuals';
import { a4PaperSize } from '@/modules/reporting-v2/hooks/useExport';
import { Dict } from '@/modules/reporting-v2/types/Common';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { Page, RawConfig, RawReportConfig, ReportOrientation, VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ReportParams } from '@/modules/reporting-v2/types/ReportingService';
import { InitProps, IProps, IState, Preferences, VisualID } from '@/modules/reporting-v2/types/ReportViewerServiceTypes';
import { getHTML } from '@/modules/reporting-v2/utils';
import { setTheme } from '@/modules/reporting-v2/utils/theme';
import * as views from '@/modules/reporting-v2/views';
import { FilterOperator } from '@/types/Filters';
import replacer from '@/utils/jsonStringifyReplacer';
import { ExcelVisual } from '@/utils/excel';
import { error } from '@/utils/error';
import ReportContext, { IContext, MultiEntityFeatureContext } from './ReportContext';
import { ParametersHandler } from '@/common/types/app/ParametersHandler';
import { DateRange } from '@/modules/reporting-v2/types/DateRange';
import { createIntl, createIntlCache, IntlShape } from 'react-intl';
import { retrieveUserLocale } from '@/utils/locale';
import messages from '@/translations/messages';
import type { HtmlPrintObject, PrintPayload, PrintPayloadPage } from '@/modules/reporting-v2/types/Print';
import type { PageProperties } from '@/modules/reporting-v2/types/HeaderFooter';
import { IDataFrame } from 'data-forge';
import { RawFilter } from '@/modules/reporting-v2/types/Filter';
import GhostVisualUtils from '@/modules/reporting-v2/core/visuals/GhostVisual/GhostUtils';
import { useRecoilValue } from 'recoil';
import { siteTreeEntryState } from '@/modules/App/recoil/app.atoms';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import type { BuilderType } from '@/modules/report-builder/types';
import { flushSync } from 'react-dom';

export class ReportViewerService extends React.Component<IProps, IState> {
  private ReportService?: ReportingService;
  private visualCount?: number;
  protected reportId?: string;
  protected orientation?: ReportOrientation;
  private translator: IntlShape;
  private reportConfiguration: RawReportConfig;
  private colourGroups: ColourGroup[];

  constructor(readonly props: IProps) {
    super(props);

    this.state = {
      exporting: false,
      ready: false,
      loading: true,
      params: {},
      filters: [],
      preferences: {},
      visuals: new Map(),
      lastUpdated: {},
      loadingVisuals: new Map<VisualID, boolean>(),
      filteredPages: [],
      visualsHaveNoData: [],
      hiddenPages: []
    };
    const userLocale = retrieveUserLocale();
    const cache = createIntlCache();
    this.translator = createIntl({ locale: userLocale, messages: messages }, cache);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.visuals.size !== this.state.visuals.size && this.state.visuals.size === this.visualCount) this.setState({ ready: true });

    const didEntityChange =
      prevState.params?.p?.length !== this.state.params?.p?.length ||
      EntityParametersHandler.encodeHoldingSetIds(prevState.params?.p) !== EntityParametersHandler.encodeHoldingSetIds(this.state.params?.p);
    const didAddOrRemoveEntity = prevState.params?.p?.length !== this.state.params?.p?.length;

    // Excluding holdingsetids to only look at filters changes
    const cb = (param: EntityParameters) => ({
      ...param,
      holdingSetId: undefined
    });
    const oldFilters = { ...prevState.params, p: prevState.params?.p?.map(cb) };
    const newFilters = {
      ...this.state.params,
      p: this.state.params?.p?.map(cb)
    };
    const didFiltersChange = JSON.stringify(oldFilters) !== JSON.stringify(newFilters);

    const wasAlreadyLoaded = !this.state.loading && this.state.ready;

    if (didEntityChange && wasAlreadyLoaded) {
      this.setState(prev => ({ filters: prev.filters.filter(f => f.isDefault) }));
      this.fetchData(true);
    } else if (didFiltersChange && wasAlreadyLoaded) {
      this.setState(prev => ({
        filters: prev.filters.filter(f => f.selectBoxId)
      }));
      this.fetchData();
    }

    if (prevProps.siteTreeEntry?.id && prevProps.siteTreeEntry?.id !== this.props.siteTreeEntry?.id) {
      this.resetFilters();
    }
  }

  private reprocessVisualsOrdering = () => {
    this.state.visuals.forEach(visual => {
      visual.reprocessOrdering(this.state.params?.p);
    });
  };

  componentDidMount(): void {
    this.setReady(true);
  }

  /**
   * @param props Will be passed by the src/reporting/core/Report component
   */
  private init = async (props: InitProps) => {
    if (!this.ReportService) {
      this.ReportService = new ReportingService();
    }

    const branding = this.props.user.company.branding;
    let brandingTheme;

    try {
      brandingTheme = branding.styles && JSON.parse(branding.styles)?.theme;
    } catch {}

    setTheme(props.config.config.theme ?? brandingTheme);

    this.reportId = props.config.id;
    this.orientation = props.config.config.orientation;
    this.visualCount = props.config.pages.flatMap(page => page.components).length;

    this.reportConfiguration = { ...props.config };

    if (!props.isWebpage) {
      if (!this.colourGroups) {
        this.colourGroups = await getColourGroups(this.props.user.accountId);
      }
      this.reportConfiguration.colourGroups = this.colourGroups;
    }

    const fetchedPreferences = await this.fetchPreferences();
    const preferences = Object.values(fetchedPreferences || {}).reduce((acc, visual) => Object.assign(acc, visual), {});

    flushSync(() => {
      this.setState(prev => ({
        styles: props.config.config.styles,
        reportConfiguration: createReportConfig(this.reportConfiguration, preferences || {}),
        orientation: props.config.config.orientation,
        params: prev.params ?? props.params,
        currentPage: props.page ?? props.currentPage,
        title: props.config.title,
        filters: []
      }));
    });

    await this.fetchData();
  };

  /**
   * @param holdingSetId
   * @param dateRange
   * If we want to use this function for retrieving data on dateRange change, we need to pass holdingSetId and dateRange as arguments
   * Without these arguments it won't work properly on the Dashboard page
   */
  public triggerVisualRefetch = async (visualIds: string | Array<string>, holdingSetId?: number, dateRange?: DateRange) => {
    const visuals = Array.isArray(visualIds) ? visualIds.map(id => this.state.visuals.get(id)!) : [this.state.visuals.get(visualIds)!];
    const globalFilters = this.state.reportConfiguration!.globalFilters;
    const reportParamConfig = this.state.reportConfiguration!.reportParamsConfig;
    const visualConfigs = this.state.reportConfiguration!.visuals;

    /**
     * If the report is a dashboard, restart the default data fetching process.
     */
    const isDashboard = reportParamConfig.multiHoldingSetEnabled && !reportParamConfig.holdingSetEnabled && visualConfigs.some(visual => visual instanceof DashboardTableConfig);
    if (isDashboard) {
      await this.fetchData();
      return;
    }

    const reportParams = this.mapRawElasticParams();

    /** If reportParamConfig.dateEnabled is true, the dateRange value will be taken into account inside retrieveElasticCalls() function */
    if (dateRange) {
      reportParamConfig.dateEnabled = true;
    }

    const filters = this.state.filters;

    try {
      await this.ReportService!.refetchVisualData(visuals, reportParamConfig, reportParams, globalFilters, filters);
    } catch (err) {
      // error handling?
    } finally {
      this.setState(prev => {
        const newLoadingVisuals = new Map(prev.loadingVisuals);

        visuals.forEach(visual => {
          this.refreshVisuals(visual.id);
          newLoadingVisuals.delete(visual.id);
        });

        return { loadingVisuals: newLoadingVisuals };
      });
    }
  };

  /**
   * @throws Only to be called when this.state.reportConfiguration has been mapped, otherwise undefined reference error
   */
  public fetchData = async (wasAlreadyLoaded = false) => {
    const reportParamConfig = this.state.reportConfiguration!.reportParamsConfig;

    const reportParams = this.mapRawElasticParams();
    const globalFilters = this.state.reportConfiguration!.globalFilters;

    const visualConfigs = this.state.reportConfiguration!.visuals;

    const isDashboard = reportParamConfig.multiHoldingSetEnabled && !reportParamConfig.holdingSetEnabled && visualConfigs.some(visual => visual instanceof DashboardTableConfig);
    const dashboardTable = isDashboard && visualConfigs.find(visual => visual instanceof DashboardTableConfig);
    const visualsWithoutDashboard = visualConfigs.filter(visual => !(visual instanceof DashboardTableConfig));

    const visualsData = await this.ReportService!.buildReportData(reportParamConfig, reportParams, visualsWithoutDashboard);
    if (dashboardTable && !wasAlreadyLoaded) {
      const dashboardParams = this.mapRawDashboardElasticParams();
      const visualsDataForDashboard = await this.ReportService!.buildReportData(reportParamConfig, dashboardParams, [dashboardTable]);
      visualsData.set(dashboardTable.id, visualsDataForDashboard.get(dashboardTable.id)!);
    }

    const visuals = new Map();

    for (const visualConfiguration of wasAlreadyLoaded ? visualsWithoutDashboard : visualConfigs) {
      this.generateVisualData(visualConfiguration, visualsData, globalFilters);
      visuals.set(visualConfiguration.id, visualConfiguration);
    }
    if (wasAlreadyLoaded && dashboardTable) {
      // keep the dashboard table to prevent refetch data (dashboard table doesnt change)
      visuals.set(dashboardTable.id, this.state.visuals.get(dashboardTable.id));
    }

    this.setState({ visuals, loading: false, loadingVisuals: new Map() });
  };

  private generateVisualData(visualConfiguration: VisualEngine, visualsData: Map<string, FlattenObject[]>, globalFilters: Filter[]): void {
    visualConfiguration.initialDataframe = undefined;

    if (!visualConfiguration.columns?.length) {
      return;
    }

    const data = visualsData.get(visualConfiguration.id);
    if (!data) {
      error(this.translator.formatMessage({ id: 'report.visual.missingDataForVisualWithId' }, { id: visualConfiguration.id }));
    }

    const visualIsDashboard = visualConfiguration instanceof DashboardTableConfig;
    visualConfiguration.generateProcessedData(data, globalFilters, visualIsDashboard ? [] : this.state.filters);
  }

  /**
   * @summary Retrieves user preferences for visuals settings
   */
  public fetchPreferences = async () => {
    if (this.props.siteTreeEntry) {
      const preferences = await getSiteTreeEntryUserPreferences(this.props.siteTreeEntry.id);

      this.setState({
        preferences
      });

      return preferences;
    }
  };

  private activateEditMode = (id: VisualID) => {
    this.setState({ editMode: id });
  };

  private deactivateEditMode = () => {
    this.setState({ editMode: undefined });
  };

  public cleanPreferences = (preferences: Preferences | undefined, reportVisualIds: string[] | undefined): Preferences => {
    if (!preferences || !reportVisualIds) {
      return {};
    }

    const visualsPreferences: Record<VisualID, RawConfig> = Object.values(preferences).reduce((prev, curr) => ({ ...prev, ...curr }), {});

    Object.keys(visualsPreferences).forEach(visualId => {
      if (!reportVisualIds?.includes(visualId)) {
        delete visualsPreferences[visualId];
      }
    });

    return visualsPreferences;
  };

  /**
   * @param id The ID of the visual
   * @param preferences The new preferences to save for that visual (its report viewer configuration, modified columns etc)
   */
  public updatePreferences = async (id: VisualID, preferences: Preferences, visualVersion?: string) => {
    const siteTreeEntry = this.props.siteTreeEntry;

    const reportVisualIds = this.state.reportConfiguration?.visuals.map(visual => visual.id);
    const visualsPreferences = this.cleanPreferences(this.state.preferences, reportVisualIds);

    const newPreferences = {
      visuals: {
        ...visualsPreferences,
        ...{ [id]: { ...preferences, version: visualVersion } }
      }
    };

    this.setState({
      preferences: newPreferences
    });

    // this prevents error on Preview Page
    if (!siteTreeEntry || !siteTreeEntry.id) {
      return undefined;
    }

    return updateSiteTreeEntryPreferences(siteTreeEntry.id, newPreferences);
  };

  public broadcastFiltersChange = (filters: Filter[]) => {
    for (const visual of this.state.visuals.values()) {
      if (visual.crossFilterReceiver) {
        visual.updateData(filters, this.state.reportConfiguration!.globalFilters);
      }
    }

    this.setFilters(filters);
  };

  private getNewFilters(
    fieldValues: { field: Field; value: Primitive | Primitive[] }[],
    selectBoxId?: string,
    isDefault?: boolean,
    additionalFieldsToFilter?: string[],
    filterOperator?: FilterOperator
  ) {
    const filters: Filter[] = [];
    fieldValues.forEach(fieldValue => {
      const hasFilter = this.state.filters.find(filter => filter.field.getElasticPath() === fieldValue.field.getElasticPath());
      if (!hasFilter) {
        filters.push(
          new Filter(
            fieldValue.field,
            new Condition(filterOperator ?? FilterOperator.EQUALS, ([] as Primitive[]).concat(fieldValue.value)),
            selectBoxId,
            isDefault,
            undefined,
            additionalFieldsToFilter
          )
        );
      }
    });

    return filters;
  }

  public addFilter = (
    fieldValues: { field: Field; value: Primitive | Primitive[] }[],
    selectBoxId?: string,
    isDefault?: boolean,
    additionalFieldsToFilter?: string[],
    filterOperator?: FilterOperator
  ) => {
    let filters = this.state.filters.map(filter => {
      const filteredFieldValue = fieldValues.filter(fieldVal => fieldVal.field.getElasticPath() === filter.field.getElasticPath());
      if (!filteredFieldValue.length) return filter;

      const values = filteredFieldValue.flatMap(fv => fv.value);
      filter.condition.values = values as Primitive[];

      return filter;
    });

    filters = [...filters, ...this.getNewFilters(fieldValues, selectBoxId, isDefault, additionalFieldsToFilter, filterOperator)];

    this.broadcastFiltersChange(filters);
  };

  public replaceFilter = (
    fieldValues: { field: Field; value: Primitive | Primitive[] }[],
    selectBoxId?: string,
    additionalFieldsToFilter?: string[],
    filterOperator?: FilterOperator
  ) => {
    let filters = this.state.filters.map(filter => {
      for (const fieldValue of fieldValues) {
        if (filter.field.getElasticPath() === fieldValue.field.getElasticPath()) {
          return new Filter(
            fieldValue.field,
            new Condition(filterOperator ?? FilterOperator.EQUALS, ([] as Primitive[]).concat(fieldValue.value)),
            selectBoxId,
            undefined,
            undefined,
            additionalFieldsToFilter
          );
        }
      }
      return filter;
    });

    filters = [...filters, ...this.getNewFilters(fieldValues, selectBoxId, undefined, additionalFieldsToFilter, filterOperator)];

    this.broadcastFiltersChange(filters);
  };

  public removeFilter = (_field: Field | [[Field, any]], _value?: Primitive) => {
    let field: Field;
    let value: Primitive | undefined = _value;

    if (Array.isArray(_field)) {
      field = _field[0][0];
      value = _field[0][1];
    } else {
      field = _field;
    }
    if (value === undefined) {
      const filters = this.state.filters.filter(filter => filter.field.getElasticPath() !== field.getElasticPath());
      this.broadcastFiltersChange(filters);
    } else {
      const filters = this.state.filters.flatMap(filter => {
        if (filter.field.getElasticPath() === field.getElasticPath()) {
          filter.condition.values.splice(filter.condition.values.indexOf(value)!, 1);
          if (!filter.condition.values.length) return [];
        }
        return filter;
      });

      this.broadcastFiltersChange(filters);
    }
  };

  public toggleFilter = (field: Field, value: Primitive) => {
    let hasFilter = false;
    const filters = this.state.filters.flatMap(filter => {
      if (filter.field.getElasticPath() === field.getElasticPath()) {
        hasFilter = true;
        const valueIndex = filter.condition.values.indexOf(value);
        if (valueIndex !== -1) {
          filter.condition.values.splice(valueIndex, 1);
          if (!filter.condition.values.length) return [];
        } else {
          filter.condition.values.push(value);
        }
      }
      return filter;
    });

    if (!hasFilter) {
      filters.push(new Filter(field, new Condition(FilterOperator.EQUALS, [value])));
    }

    this.broadcastFiltersChange(filters);
  };

  private resetFilters = () => this.setState({ filters: [] });

  /**
   * @returns ReportParams mapped for our v2 elastic calls (allUserHoldingSetIds, to, from, date, holdingSetId, ConsolidationType)
   */
  private mapRawElasticParams = (): ReportParams => {
    const elasticParams: Partial<ReportParams> = ParametersHandler.retrieveParams();

    elasticParams.holdingSets = EntityParametersHandler.retrieveParamsList(this.props.user, undefined, this.state.reportConfiguration?.multiEntityReport);

    return elasticParams as ReportParams;
  };

  /**
   * @returns ReportParams with all holdingSetIds for the dashboard table in dashboard page
   */
  private mapRawDashboardElasticParams = (): ReportParams => {
    const elasticParams: Partial<ReportParams> = ParametersHandler.retrieveParams();

    elasticParams.holdingSets = this.props.user.holdingSetIds.map(holdingSetId => ({ holdingSetId }));

    return elasticParams as ReportParams;
  };

  /**
   * @param filters The new cross filters to be set
   * @summary Will update the state of report viewer service cross filters (e.g cross filter on "Cash" asset Type)
   */
  private setFilters = (filters: Filter[]) => this.setState({ filters });
  /**
   * @param params The new params to be set
   * @summary Will update the state of report viewer service params (e.g date, holdingSetId or consolidation type)
   */
  public setParams = (params: IParams) => this.setState({ params });

  /**
   * @summary Hacky trick to rerender all of the visuals, use with caution
   * @param id ID of the visual to refresh. If undefined, will refresh every visual
   */
  private refreshVisuals = (id?: string) => {
    if (id !== undefined) {
      this.setState(prev => ({
        lastUpdated: { ...prev.lastUpdated, [id]: Date.now() }
      }));
    } else {
      const lastUpdated: Dict<number> = {};

      for (const visual of this.state.visuals.values()) lastUpdated[visual.id] = Date.now();

      this.setState({ lastUpdated });
    }
  };

  /**
   * @param ctx Context to be serialized for memoize
   * @see l84 Destructure the properties that can't be tracked by reference and won't change (e.g function/objects, unless needed for rerender), and stringify the rest
   */
  private static getMemoizedContext = memoize((ctx: IContext) => ctx, {
    //@ts-expect-error Serializer fn isn't generic..
    serializer: (ctx: IContext) => {
      const {
        init,
        broadcastFiltersChange,
        setParams,
        globalFilters,
        params,
        updatePreferences,
        filters,
        addFilter,
        removeFilter,
        toggleFilter,
        activateEditMode,
        deactivateEditMode,
        triggerVisualRefetch,
        setReady,
        ...restCtx
      } = ctx;

      return JSON.stringify(restCtx, replacer); // replacer is needed to add support for native es6 maps. atm, we only check map keys diff for low overhead (easy to change)
    }
  });

  getExcel = (context: IContext): ExcelVisual[] => {
    const excelVisuals: ExcelVisual[] = [];
    for (const visual of this.state.visuals.values()) {
      const excelVisual = visual.getExcel(context);
      if (excelVisual) {
        excelVisuals.push(excelVisual);
      }
    }
    return excelVisuals;
  };

  getHTML = (reportConfig?: RawReportConfig) => {
    return getHTML(this.reportId!, this.state.visuals, reportConfig);
  };

  getPageProperties = (container: HTMLElement, content: string) => {
    const orientation = (/data-orientation=["']([^["']+)/gi.exec(content)?.[1] ?? this.orientation!) as ReportOrientation;
    const paperWidth = a4PaperSize[orientation];
    const width = Math.max(paperWidth, container.offsetWidth);
    return {
      ratio: paperWidth / width,
      width,
      height: Math.floor(width * REPORT_BUILDER_CANVAS_SIZE[orientation].ratio),
      orientation: this.orientation
    };
  };

  print = (content: HtmlPrintObject, container: HTMLElement = document.getElementById(`report-${this.reportId!}`)!): PrintPayload => {
    let withQuillStyleSheet = false;

    for (const visual of this.state.visuals.values()) {
      if (visual.component === VisualComponent.TextImage) {
        withQuillStyleSheet = true;
      }
    }

    const pagesToPrint: PrintPayloadPage[] = [];

    if (content.cover) {
      this.addPageToPrint(pagesToPrint, content.cover, false, withQuillStyleSheet, this.getPageProperties(container, content.cover), 'COVER');
    }

    if (content.content) {
      this.addPageToPrint(pagesToPrint, content.content, true, withQuillStyleSheet, this.getPageProperties(container, content.content));
    }

    if (content.disclaimer) {
      this.addPageToPrint(pagesToPrint, content.disclaimer, true, withQuillStyleSheet, this.getPageProperties(container, content.disclaimer), 'DISCLAIMER');
    }
    if (content.backCover) {
      this.addPageToPrint(pagesToPrint, content.backCover, false, withQuillStyleSheet, this.getPageProperties(container, content.backCover), 'BACK_COVER');
    }

    // window.open(URL.createObjectURL(new Blob([pagesToPrint[1].html], { type: 'text/html' })), '_blank');

    return { pages: pagesToPrint };
  };

  private getMarginWithRatio = (margin: string | number | undefined, ratio = 1) => {
    if (typeof margin === 'undefined') {
      return undefined;
    }

    let numMargin = margin;
    if (typeof numMargin === 'string') {
      numMargin = parseInt(numMargin);
    }

    return numMargin * (1 / ratio);
  };

  private addPageToPrint = (
    pagesToPrint: PrintPayloadPage[],
    htmlContent: string,
    isContent: boolean,
    withQuillStyleSheet: boolean,
    pageProperties: PageProperties,
    pageType?: BuilderType
  ) => {
    const pageToPrint: PrintPayloadPage = {
      html: views.layout({
        withQuillStyleSheet,
        content: htmlContent,
        pageStyle: pageProperties,
        customStyle: !isContent ? this.state.styles?.css : undefined
      }),
      pageType: pageType
    };

    if (isContent) {
      pageToPrint.headerHtml = views.header({
        title: this.state.title,
        pageProperties,
        reportStyle: this.state.styles,
        custom: this.state.reportConfiguration!.custom,
        user: this.props.user
      });
      pageToPrint.footerHtml = views.footer({
        title: this.state.title,
        pageProperties,
        reportStyle: this.state.styles,
        entityParam: this.state.params,
        pageType,
        custom: this.state.reportConfiguration!.custom,
        user: this.props.user
      });
    }

    const margin = this.state.reportConfiguration?.margin;
    if (margin && (isContent || pageType === 'BACK_COVER')) {
      pageToPrint.margin = {
        top: this.getMarginWithRatio(margin.top, pageProperties.ratio),
        bottom: this.getMarginWithRatio(margin.bottom, pageProperties.ratio),
        left: this.getMarginWithRatio(margin.left, pageProperties.ratio),
        right: this.getMarginWithRatio(margin.right, pageProperties.ratio)
      };
    }

    pagesToPrint.push(pageToPrint);
  };

  private getFilteredPages = (): string[] => {
    const pages: Page[] = this.reportConfiguration?.pages;

    if (!pages) {
      return [];
    }

    return pages.reduce((acc, page) => {
      const pageFilters = page.filters as RawFilter[];

      if (!pageFilters) {
        return acc;
      }

      const helperVisual = this.state.reportConfiguration?.visuals.find(visual => {
        return visual.id === GhostVisualUtils.createVisualId(page.id);
      });

      if (!helperVisual || !helperVisual.data) {
        return acc;
      }

      const filters: Filter[] = [];

      pageFilters.forEach((filter: RawFilter) => {
        const fieldColumn =
          filter.compareWith?.column?.defaultColumns && filter.compareWith?.column?.defaultColumns.length > 0
            ? filter.compareWith?.column?.defaultColumns[0]
            : filter.baseColumn?.defaultColumns[0];

        const field = new Field(fieldColumn as string);

        if (field.name && filter.compareWith?.value) {
          filters.push(new Filter(field, new Condition(filter.operator, filter.compareWith?.value)));
        }
      });

      const data: IDataFrame<number, FlattenObject> = helperVisual.getDataFrame(helperVisual.data.rows);

      let showPage = false;

      for (const row of data) {
        if (showPage) {
          break;
        }
        for (const filter of filters) {
          if (filter.evaluate(row)) {
            showPage = true;
          }
        }
      }

      return !showPage ? [...acc, page.id] : acc;
    }, [] as string[]);
  };

  public updateVisualsHasNoData = (id: string, hasNoData: boolean) => {
    this.setState(prevState => {
      const visualsHaveNoData = hasNoData ? [...prevState.visualsHaveNoData, id] : prevState.visualsHaveNoData.filter(visualId => visualId !== id);

      return {
        visualsHaveNoData: [...new Set(visualsHaveNoData)]
      };
    });
  };

  public clearVisualsHaveNoData = () => {
    this.setState({
      visualsHaveNoData: []
    });
  };

  public getReportConfiguration = () => {
    return this.state.reportConfiguration;
  };

  public setVisualsLoading = <T extends VisualEngine>(visuals: Array<T>) => {
    const loadingVisuals: Map<VisualID, boolean> = new Map();

    for (const visual of visuals) {
      loadingVisuals.set(visual.id, true);
    }

    this.setState({ loadingVisuals });
  };

  public clearLoadingVisuals = () => {
    this.setState({ loadingVisuals: new Map() });
  };

  public setReady = (ready: boolean) => this.setState({ ready });
  public setCurrentPage = (pageId: string) => {
    this.setState({ currentPage: pageId });
  };

  public setExporting = (exporting: false | 'pdf' | 'excel') => this.setState({ exporting });

  render() {
    const ctx = ReportViewerService.getMemoizedContext({
      exporting: this.state.exporting,
      setExporting: this.setExporting,
      reportId: this.reportId,
      orientation: this.orientation,
      visuals: this.state.visuals,
      siteTreeEntry: this.props.siteTreeEntry,
      preferences: this.state.preferences,
      ready: this.state.ready,
      loading: this.state.loading,
      filters: this.state.filters,
      title: this.state.title,
      params: this.state.params,
      lastUpdated: this.state.lastUpdated,
      multiHoldingSetEnabled: this.state.reportConfiguration?.reportParamsConfig?.multiHoldingSetEnabled,
      loadingVisuals: this.state.loadingVisuals,
      setVisualsLoading: this.setVisualsLoading,
      clearLoadingVisuals: this.clearLoadingVisuals,
      globalFilters: this.state.reportConfiguration?.globalFilters || [],
      editMode: this.state.editMode,
      init: this.init,
      broadcastFiltersChange: this.broadcastFiltersChange,
      setParams: this.setParams,
      addFilter: this.addFilter,
      removeFilter: this.removeFilter,
      triggerVisualRefetch: this.triggerVisualRefetch,
      replaceFilter: this.replaceFilter,
      toggleFilter: this.toggleFilter,
      refreshVisuals: this.refreshVisuals,
      getHTML: this.getHTML,
      print: this.print,
      getExcel: this.getExcel,
      activateEditMode: this.activateEditMode,
      deactivateEditMode: this.deactivateEditMode,
      updatePreferences: this.updatePreferences,
      setReady: this.setReady,
      setPage: this.setCurrentPage,
      reportConfiguration: this.reportConfiguration,
      getReportConfiguration: this.getReportConfiguration,
      filteredPages: this.getFilteredPages(),
      visualsHaveNoData: this.state.visualsHaveNoData,
      updateVisualsHasNoData: this.updateVisualsHasNoData,
      clearVisualsHaveNoData: this.clearVisualsHaveNoData
    });

    return (
      <ReportContext.Provider value={ctx}>
        <MultiEntityFeatureContext.Provider value={this.state.reportConfiguration?.multiEntityReport}>{this.props.children}</MultiEntityFeatureContext.Provider>
      </ReportContext.Provider>
    );
  }
}

export default ({ children }: { children: React.ReactNode }) => {
  const siteTreeEntry = useRecoilValue(siteTreeEntryState);
  const currentUser = useRecoilValue(currentUserSelector);

  return (
    <ReportViewerService user={currentUser} siteTreeEntry={siteTreeEntry}>
      {children}
    </ReportViewerService>
  );
};
