import React from 'react';
import type { Column } from '@/modules/reporting-v2/core/Column';
import type { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { CategoryContextWrapper } from '@/modules/reporting-v2/core/components/DataTablev2/context/categoryContext';
import { AssetLinkContextWrapper } from '@/modules/reporting-v2/core/components/DataTablev2/context/assetLinkContext';
import { LegendChartColorContextWrapper } from '@/modules/reporting-v2/core/components/DataTablev2/context/legendChartColorContext';
import { DataBarsContextWrapper } from '@/modules/reporting-v2/core/components/DataTablev2/context/dataBarsContext';
import { LevelIndicatorsContextWrapper } from '@/modules/reporting-v2/core/components/DataTablev2/context/levelIndicatorContext';
import { OwnershipDropdownContextWrapper } from '@/modules/reporting-v2/core/components/DataTablev2/context/ownershipDropdownContext';

type TableContextProviderProps = {
  children: React.ReactElement;
  defaultColumns: Column[];
  displayCategories: boolean;
  visual: VisualEngine;
};

const TableContextProvider: React.FC<TableContextProviderProps> = ({ children, defaultColumns, displayCategories, visual }) => {
  return (
    <CategoryContextWrapper defaultColumns={defaultColumns} displayCategories={displayCategories}>
      <AssetLinkContextWrapper defaultColumns={defaultColumns}>
        <LevelIndicatorsContextWrapper visual={visual}>
          <OwnershipDropdownContextWrapper visual={visual}>
            <DataBarsContextWrapper maxRangeValues={visual.data.maxRangeValues}>
              <LegendChartColorContextWrapper visual={visual}>{children}</LegendChartColorContextWrapper>
            </DataBarsContextWrapper>
          </OwnershipDropdownContextWrapper>
        </LevelIndicatorsContextWrapper>
      </AssetLinkContextWrapper>
    </CategoryContextWrapper>
  );
};

export { TableContextProvider };
