import { ArithmeticOperator } from '@/modules/reporting-v2/types/CalcExpression';
import { FieldResolver } from '@/modules/reporting-v2/types/FieldResolver';
import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import simpleCalculation from '@/simpleCalculation';
import { Field } from './Field';
import { Column } from './Column';
import { Filter } from './Filter';
import { message } from 'antd';
import { ReportingService } from './ReportingService';

export class CalcExpression implements FieldResolver {
  constructor(expressions: Array<string | number>) {
    this.expressions = expressions.map((value, index) => {
      if (index % 2 === 0) {
        return typeof value === 'string' ? new Field(this.getColumnCalcField(value)) : value;
      }
      return value as ArithmeticOperator;
    });
  }

  expressions: (ArithmeticOperator | number | Field)[];

  getAllUsedFields() {
    return this.expressions.filter((expression): expression is Field => expression instanceof Field);
  }

  // TEMP : to remove, it doesn't work yet, just bypassing ui-sesame-admin $ addition
  private getColumnCalcField = (field: string) => {
    if (field.includes('$')) return field.replace('$', '');
    else return field;
  };

  filterColumn(row: FlattenObject, column: Column): boolean {
    const filters: Filter[] = [];

    if (column.conditionalRowDisplay.length) {
      filters.push(...column.conditionalRowDisplay);
    }

    if (column.conditionalValueDisplay.length) {
      filters.push(...column.conditionalValueDisplay);
    }

    return filters.every(filter => {
      return filter.evaluate(row);
    });
  }

  calc(row: FlattenObject, columns: Column[], bypassFilters = false) {
    const propMap = new Map();
    let lastPropId = 0;
    const formula = this.expressions
      .map((value: ArithmeticOperator | number | Field) => {
        if (!(value instanceof Field)) {
          return value;
        }

        lastPropId++;
        const key = `prop${lastPropId}`;

        if (bypassFilters) {
          propMap.set(key, row[value.name]);
          return key;
        }

        const filterColumn = columns.find(column => column.fieldDataPath === value.name);
        if (filterColumn) {
          propMap.set(key, this.filterColumn(row, filterColumn) ? row[value.name] : 0);
          return key;
        }

        const columnMeta = ReportingService.metas[value.name];
        if (!columnMeta) {
          message.error(
            `Field ${value.name} is being used in conditional calculations but it does not exist anymore. 0 will be used for its value instead. Please update the configuration.`
          );
          propMap.set(key, 0);
        }
        if (columnMeta) {
          propMap.set(key, row[value.name] ?? 0);
        }

        return key;
      })
      .join('');

    return simpleCalculation.evaluate(formula, key => propMap.get(key));
  }
}
