import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { ActivityType } from '@/common/types/entity/Link';
import { ActivityDTO } from '@/modules/ERP/Contacts/types';

export const getActivity = (entityType: ActivityType, entityId: string): Promise<Array<ActivityDTO>> => {
  return requestApi({
    service: 'activity',
    method: 'get',
    url: `/api/activity/${entityType}/${entityId}`
  }).then((resp: AxiosResponse<any> | undefined) => {
    return resp!.data.content;
  });
};
