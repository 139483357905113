import React from 'react';
import { Column } from '@/modules/reporting-v2/core/Column';
import { EditableValue } from '@/modules/reporting-v2/core/components';
import { EntityParametersHandler } from '../../../../../common/types/app/EntityParametersHandler';
import { ExcelUtils } from '@/utils/excel';
import { findHoldingSetById } from '@/utils/findHoldingSet';
import { HoldingSet } from 'modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import { Orientation } from './VisualSpecificProps';
import { ReportingService } from '@/modules/reporting-v2/core/ReportingService';
import { Tooltip } from 'antd';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import CalloutConfig from './CallOutConfig';
import { config } from './config';
import RawCallOutConfig from './types';
import schema from './schema.json';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import './style.css';

const styles = {
  callOutContainer: (vertical: boolean) => ({
    marignTop: vertical ? 16 : 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: vertical ? 'stretch' : 'flex-start',
    flex: 1,
    lineHeight: 1.25
  }),
  callOutWrapper: (vertical: boolean) => ({
    display: 'flex',
    margin: vertical ? '-8px 0' : '0 -24px',
    flexDirection: vertical ? 'column' : 'row',
    textAlign: 'center'
  }),
  callOutItem: (vertical: boolean) => ({
    display: 'flex',
    padding: vertical ? '8px 0' : '0 24px',
    flexDirection: 'column',
    alignItems: 'center'
  }),
  value: (withColor: boolean) => ({
    color: !withColor && 'inherit'
  })
};

class CallOut extends Visual {
  Loader = Loaders.CallOut;

  static configMapper(visualConfig: RawCallOutConfig) {
    const { fields, orientation, ...rest } = visualConfig;
    return {
      ...rest,
      orientation,
      columns: mapVisualConfigColumns(fields)
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as CalloutConfig;
  }

  getSchema() {
    return schema;
  }

  renderMultiHoldingSetExportableData = (holdingSetTree: HoldingSet[]) => {
    return (column: Column) => {
      return Array.from(this.props.visual.data.totalsByEntity.entries()).map(([id, values]) => {
        const attrs = ExcelUtils.getTDAttributes(column, values, values[column.fieldDataPath]);
        const entity = findHoldingSetById(holdingSetTree, id);

        return (
          <tr key={`${column.id}${entity?.id}`}>
            <td>{ReportingService.metas[column.code ?? column.field.name]?.displayName}</td>
            <td>{entity?.name}</td>
            <td {...attrs}>{values[column.fieldDataPath]}</td>
          </tr>
        );
      });
    };
  };

  renderDashboardExportableData = () => {
    const values = this.props.visual.data.totals;
    return (column: Column) => {
      const attrs = ExcelUtils.getTDAttributes(column, values, values[column.fieldDataPath]);
      return (
        <tr key={column.id}>
          <td>{ReportingService.metas[column.code ?? column.field.name]?.displayName}</td>
          <td {...attrs}>{values[column.fieldDataPath]}</td>
        </tr>
      );
    };
  };

  renderExportableData = (holdingSetTree: HoldingSet[]) => {
    return (
      <table style={{ display: 'none' }}>
        <tbody>
          {this.props.visual.columns
            .filter(col => col.isDefault)
            .map(col => {
              const exportableData = this.context.multiHoldingSetEnabled ? this.renderDashboardExportableData() : this.renderMultiHoldingSetExportableData(holdingSetTree);
              return <React.Fragment key={col.id}>{exportableData(col)}</React.Fragment>;
            })}
        </tbody>
      </table>
    );
  };

  renderInteractivity() {
    return super.renderInteractivity();
  }

  renderDashboardCallouts(vertical: boolean, multipleMeasures?: boolean, multiHoldingSetEnabled?: boolean, multiEntityFeatures?: boolean) {
    let values = this.props.visual.data.totals;
    let entity: HoldingSet | undefined;

    const isMultipleMeasuresCallout = multipleMeasures && !multiHoldingSetEnabled;

    if (isMultipleMeasuresCallout) {
      const param = EntityParametersHandler.retrieveParamsList(this.props.currentUser, undefined, this.props.multiEntityFeatures)[this.props.visual.entityOrdering[0]]
        ?.holdingSetId;
      const tree = this.props.currentUser.holdingSetTree;

      entity = findHoldingSetById(tree, param);
      values = this.props.visual.data.totalsByEntity.get(param) ?? {};
    }

    return (column: Column, _: number, __: Column[]) => {
      const color = column?.formatting?.color; // to refractor, weird formatting type
      const colorKeysLength = Object.keys(color || {}).length;
      const totalValue = values[column.field.getElasticPath() + column.id] || values[column.field.getElasticPath()];

      let withColor = false;

      if (!color || (color && !colorKeysLength)) {
        withColor = true;
      }

      if (color && typeof color !== 'string' && !color.conditions?.length && colorKeysLength === 1) {
        withColor = true;
      }

      return (
        <React.Fragment key={`${column.id}${totalValue}`}>
          <div style={styles.callOutItem(vertical) as React.CSSProperties}>
            <span className="callout-value" style={styles.value(withColor) as React.CSSProperties}>
              {totalValue == undefined ? ( // eslint-disable-line
                <span style={undefinedValueStyles}>N/A</span>
              ) : (
                <EditableValue key={column.id} metas={column} defaultValue={totalValue} field={column.fieldDataPath} data={values} htmlEditOnly={true}>
                  {VisualEngine.formatCell(totalValue, column, values, false, undefined, this.context.reportConfiguration.config.numberLocale)}
                </EditableValue>
              )}
            </span>
            <span className="callout-label">
              <strong>{column.headerConfig.displayName || ReportingService.metas[column.code ?? column.field.name].displayName}</strong>
            </span>
            {isMultipleMeasuresCallout && multiEntityFeatures && entity && (
              <span className="callout-label max-lines">
                <Tooltip title={entity.name}>{entity.name}</Tooltip>
              </span>
            )}
          </div>
        </React.Fragment>
      );
    };
  }

  renderMultiHoldingSetCallouts(tree: HoldingSet[], vertical: boolean) {
    return (column: Column, _: number, __: Column[]) => {
      return Array.from(this.props.visual.data.totalsByEntity.entries()).map(([id, values]) => {
        const entity = findHoldingSetById(tree, id);
        const color = column?.formatting?.color; // to refactor, weird formatting type
        const colorKeysLength = Object.keys(color || {}).length;
        const totalValue = values[column.field.getElasticPath() + column.id] || values[column.field.getElasticPath()];

        let withColor = false;

        if (!color || (color && !colorKeysLength)) {
          withColor = true;
        }

        if (color && typeof color !== 'string' && !color.conditions?.length && colorKeysLength === 1) {
          withColor = true;
        }

        return (
          <React.Fragment key={`${column.id}${totalValue}${id}`}>
            <div style={styles.callOutItem(vertical) as React.CSSProperties}>
              <span className="callout-value" style={styles.value(withColor) as React.CSSProperties}>
                {totalValue == undefined ? ( // eslint-disable-line
                  <span style={undefinedValueStyles}>N/A</span>
                ) : (
                  <EditableValue key={column.id} metas={column} defaultValue={totalValue} field={column.fieldDataPath} data={values} htmlEditOnly={true}>
                    {VisualEngine.formatCell(totalValue, column, values, false, undefined, this.context.reportConfiguration.config.numberLocale)}
                  </EditableValue>
                )}
              </span>
              <span className="callout-label">
                <strong>{column.headerConfig.displayName || ReportingService.metas[column.code ?? column.field.name].displayName}</strong>
              </span>

              <span className="callout-label max-lines">
                <Tooltip title={entity?.name}>{entity?.name}</Tooltip>
              </span>
            </div>
          </React.Fragment>
        );
      });
    };
  }

  renderBody() {
    const { orientation } = this.props.visual as CalloutConfig;
    const vertical = orientation === Orientation.vertical;
    const holdingSetTree = this.props.currentUser.holdingSetTree;
    const fields = this.props.visual.columns.filter(col => col.isDefault);
    const multipleMeasures = fields.length > 1;

    return (
      <React.Fragment key={this.props.visual.dataRefId}>
        <div style={styles.callOutContainer(vertical) as React.CSSProperties}>
          <div style={styles.callOutWrapper(vertical) as React.CSSProperties}>
            {fields.flatMap(
              !this.props.multiEntityFeatures || this.context.multiHoldingSetEnabled || fields.length > 1
                ? this.renderDashboardCallouts(vertical, multipleMeasures, this.context.multiHoldingSetEnabled, this.props.multiEntityFeatures)
                : this.renderMultiHoldingSetCallouts(holdingSetTree, vertical)
            )}
          </div>
        </div>
        {this.renderExportableData(holdingSetTree)}
      </React.Fragment>
    );
  }
}

const undefinedValueStyles = {
  color: '#999999',
  fontWeight: 'normal'
};
export default CallOut;
