import { requestApi } from '@/core';
import { UUID } from '@/common/types/types';
import { EntityLinkType, Link } from '@/common/types/entity/Link';

export const enableLink = async (entityType: EntityLinkType, entityId: UUID): Promise<Link> => {
  const resp = await requestApi({
    service: 'link',
    method: 'patch',
    url: `/api/${entityType}/${entityId}/links/enable`
  });
  return resp.data;
};
