import { Button, Layout, Result, Row } from 'antd';
import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { ErrorBoundary as EB } from 'react-error-boundary';
import { FormattedMessage, useIntl } from 'react-intl';
import img from './500.png';

interface ErrorBoundaryProps {
  propagateError?: boolean;
}

const ErrorPage = ({ error }: { error?: Error }) => {
  let homeLink = window.localStorage.getItem('homeExperience');
  if (!homeLink) {
    homeLink = '/';
  }
  return (
    <Layout
      style={{
        minHeight: '100%',
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        zIndex: 999999
      }}
    >
      <Row className="ant-layout-content" align="middle" justify="center">
        <Result
          title={<FormattedMessage id={'generic.error.internalServerError'} />}
          icon={<img style={{ width: '50%' }} src={img} alt={'error500'} />}
          subTitle={<FormattedMessage id={'generic.error.internalServerErrorMessage'} />}
          extra={[
            <a href={homeLink} key="key">
              <Button type="primary">
                <FormattedMessage id={'error.takeMeHome'} />
              </Button>
            </a>
          ]}
        />
      </Row>
    </Layout>
  );
};

const AppErrorBoundary: FC<PropsWithChildren<ErrorBoundaryProps>> = ({ children, propagateError }) => {
  const translator = useIntl();
  const [error, setError] = useState<Error | undefined>(undefined);
  const handleError = useCallback(
    (error: Error) => {
      setError(error);
      if (propagateError) {
        throw error;
      }
      console.error(translator.formatMessage({ id: 'generic.uncaughtError' }) + ':', error);
    },
    [propagateError, translator]
  );

  const errorPage = useMemo(() => <ErrorPage error={error} />, [error]);

  return (
    <EB onError={handleError} fallback={errorPage}>
      {children}
    </EB>
  );
};

export default AppErrorBoundary;
