import { atom, selector } from 'recoil';
import { SesameUser } from '@/common/types/SesameUser';
import { InternalLoggedUser } from '@/common/auth/api/getInternalUser';

export type UserState = {
  authenticated: undefined | boolean;
  currentHoldingSet: number | undefined;
  currentUser: null | SesameUser;
  internalUser: null | InternalLoggedUser;
};

export const userState = atom<UserState>({
  key: 'user-atom',
  default: {
    authenticated: undefined,
    currentHoldingSet: undefined,
    currentUser: null,
    internalUser: null
  },
  dangerouslyAllowMutability: true
});

export const currentUserSelector = selector({
  key: 'current-user-selector',
  get: ({ get }) => {
    return get(userState).currentUser!;
  },
  dangerouslyAllowMutability: true
});

export const internalUserSelector = selector({
  key: 'internal-user-selector',
  get: ({ get }) => {
    return get(userState).internalUser;
  }
});
