import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { HoldingSet } from '@/modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';

const getItemChildrenIds = (item: HoldingSet) => {
  let children: Array<number> = [];
  if (item.children?.length) {
    item.children.forEach(child => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      children = children.concat(child.id, getItemChildrenIds(child));
    });
  }
  return children.flat(Infinity);
};

export const mapHoldingSets = (items: Array<HoldingSet>, parent = [0]): Array<HoldingSet> =>
  items.map((item, index) => {
    return {
      ...item,
      performanceBenchmarks: Array.isArray(item.performanceBenchmarks) ? item.performanceBenchmarks : [],
      key: parent.concat(index).join('.'),
      children: mapHoldingSets(item.children, parent.concat(index)),
      childrenIds: getItemChildrenIds(item)
    };
  });

export default (): Promise<HoldingSet[]> =>
  requestApi({
    service: 'client',
    method: 'get',
    url: `/api/holdingSets/tree`,
    errors: false
  }).then((response: AxiosResponse | undefined) => {
    return mapHoldingSets(response!.data);
  });
