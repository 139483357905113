import { FieldName } from '@/modules/reporting-v2/types/Field';
import { Index } from '@/modules/reporting-v2/types/Index';
import { getIndex, elasticFieldName, getIndexNodeName } from '@/modules/reporting-v2/utils/IndexUtils';
import getGenericFieldPath, { duplicateColumnSeparator } from '@/utils/getGenericFieldPath';
import { IndexNode } from './IndexNode';
import { ReportingService } from './ReportingService';

class Field {
  public static fieldSeparator = '.';

  constructor(name: FieldName) {
    this.name = name;
  }

  name: FieldName;

  public static fromPath(path: string) {
    return new Field(path);
  }

  public stripEdges() {
    return this.getElasticPath().split(Field.fieldSeparator).slice(1, -1).join(Field.fieldSeparator);
  }

  public stripIndex() {
    return this.getElasticPath().split(Field.fieldSeparator).slice(1).join(Field.fieldSeparator);
  }

  public getLeafPath(): string {
    return this.getElasticPath().split(Field.fieldSeparator).pop()!;
  }

  public getIndex(): Index {
    return getIndex(this.getElasticPath());
  }

  public elasticFieldName(): Index {
    return elasticFieldName(this.getElasticPath());
  }

  public isQuantItem(fieldName: string): boolean {
    return !!ReportingService.quantMetas?.[fieldName];
  }

  public getElasticPath(): string {
    const fieldName = getGenericFieldPath(this.name, this.name.includes(duplicateColumnSeparator));

    if (this.isQuantItem(fieldName)) {
      return ReportingService.quantMetas[fieldName].elasticPath;
    }

    return fieldName;
  }

  public getFieldDataPath(): string {
    const elasticPath = this.getElasticPath();

    const isDuplicatedField = this.name.includes(duplicateColumnSeparator);

    if (isDuplicatedField) {
      const id = this.name.split(duplicateColumnSeparator)[1];
      return elasticPath + id;
    }

    return elasticPath;
  }

  public getIndexNode(): IndexNode {
    return new IndexNode(getIndexNodeName(this.getElasticPath()));
  }
}

export { Field };
