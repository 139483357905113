import React, { useMemo } from 'react';
import { Col, Flex, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { RemindersToTranslationId, type ActivityDTO, Reminders } from '@/modules/ERP/Contacts/types';
import { Text } from '@/common/components/Typography/Text';
import '@/modules/ERP/helpers.css';
import './style.css'

interface UpdatedActivityProps {
  activity: ActivityDTO;
}

interface NestedEntry {
  key: string;
  value: string;
  previousValue?: string;
}

const excludedKeys = ['spaceId', 'locale', 'accountId', 'login', 'internal', 'id', 'lastModifiedDate'];

const formatDate = (dateString: string) => {
  const dateObject = new Date(dateString);
  return `${dateObject.getDate().toString().padStart(2, '0')}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getFullYear()} ${dateObject.getHours().toString().padStart(2, '0')}:${dateObject.getMinutes().toString().padStart(2, '0')}`;
};

const renderNestedEntry = ({ key, value, previousValue }: NestedEntry) => {
  return (
    <div key={key}>
      {renderValue(key, value, previousValue)}
    </div>
  );
};

const renderValue = (key: string, updatedValue: string | null, previousValue: string | null | undefined) => {
  const translator = useIntl();
  if (!updatedValue || updatedValue === '') {
    return `${translator.formatMessage({ id: `generic.noPrevious` })} ${translator.formatMessage({ id: `generic.${key}` }).toLowerCase()} ${translator.formatMessage({ id: `generic.value` })}`;
  } else if (typeof updatedValue === 'object') {
    const updatedEntries = Object.entries(updatedValue).filter(([nestedKey]) => !excludedKeys.includes(nestedKey));
    const previousEntries = previousValue && typeof previousValue === 'object' ? Object.entries(previousValue) : [];
    const mergedEntries = updatedEntries.map(([nestedKey, nestedValue]) => {
      const previousNestedValue = previousEntries.find(([key]) => key === nestedKey)?.[1];
      return renderNestedEntry({ key: nestedKey, value: nestedValue, previousValue: previousNestedValue });
    });

    return <>{mergedEntries}</>;
  } else if (!excludedKeys.includes(key)) {
    return (['lastModifiedDate', 'reminderDate', 'dueDate'].includes(key))
      ? formatDate(updatedValue as string)
      : updatedValue;
  }
};

const UpdatedActivity: React.FC<UpdatedActivityProps> = ({ activity }) => {
  const memoizedValues = useMemo(() => {
    const updatedValues = JSON.parse(activity.updatedValue.replace(/<[^>]*>?/gm, ''));
    const previousValues = JSON.parse(activity.previousValue.replace(/<[^>]*>?/gm, ''));
    return { updatedValues, previousValues };
  }, [activity.updatedValue, activity.previousValue]);
  const mergedKeys = Array.from(new Set([...Object.keys(memoizedValues.updatedValues), ...Object.keys(memoizedValues.previousValues)]));
  const lastModifiedByIndex = mergedKeys.indexOf('lastModifiedBy');
  if (lastModifiedByIndex !== -1) {
    mergedKeys.splice(lastModifiedByIndex, 1);
    mergedKeys.push("lastModifiedBy");
  }
  const translator = useIntl();
  const renderedActivities = mergedKeys.filter(key => !excludedKeys.includes(key)).map((key) => {

    return (
      <Flex key={key} className='updated-activity__container' style={{marginTop: 12}}>
        <Col span={12}>
          <div>
            {<Text type="secondary" className="mr-10" style={{ fontSize: '0.7rem' }}><FormattedMessage id={`generic.${key}`} />:</Text>}
            <Typography.Paragraph ellipsis={{ rows: 5, expandable: true, symbol: translator.formatMessage({ id: 'generic.seeMore' }) }}>

              {key === 'reminder' ? translator.formatMessage({
                id: RemindersToTranslationId[renderValue(key, memoizedValues.updatedValues[key], memoizedValues.previousValues[key]) as Reminders]
              }) : renderValue(key, memoizedValues.updatedValues[key], memoizedValues.previousValues[key])}
            </Typography.Paragraph>
          </div>
        </Col>
        <Col span={12}>
          <div style={{ opacity: 0.4 }}>
            <Text>
              {<Text type="secondary" className="mr-10" style={{ fontSize: '0.7rem' }}><FormattedMessage id={`generic.${key}`} />:</Text>}
              <Typography.Paragraph ellipsis={{ rows: 3, expandable: true, symbol: translator.formatMessage({ id: 'generic.seeMore' }) }}>
                {key === 'reminder' ? translator.formatMessage({
                  id: RemindersToTranslationId[renderValue(key, memoizedValues.previousValues[key], undefined) as Reminders]
                }) : renderValue(key, memoizedValues.previousValues[key], undefined)}
              </Typography.Paragraph>
            </Text>
          </div>
        </Col>
      </Flex>
    )
  });

  return <>{renderedActivities}</>;
};

export default UpdatedActivity;
