import { DiffValue, deepDiffMapper } from '@/utils/deepDiffMapper';

const mergePreferences = (initialVisualConfig: any, preferenceVisualConfig: any) => {
  if (!preferenceVisualConfig) {
    return preferenceVisualConfig;
  }

  const { version, ...preferenceWithoutVersion } = preferenceVisualConfig;

  const diff = deepDiffMapper(initialVisualConfig, preferenceWithoutVersion);

  for (const [key, value] of Object.entries(diff)) {
    const valueIsObject = typeof value === 'object';
    if (!valueIsObject) {
      continue;
    }

    const valueIsArrayOfString = '0' in diff[key] && typeof diff[key][0].data === 'string';
    if (valueIsArrayOfString) {
      continue;
    }

    const isRawColumnsObject = 'columns' in value && 'defaultColumns' in value;
    if (isRawColumnsObject) {
      const preferenceHasRawColumnObject = 'defaultColumns' in preferenceWithoutVersion[key] && 'columns' in preferenceWithoutVersion[key];
      if (!preferenceHasRawColumnObject) {
        preferenceWithoutVersion[key] = initialVisualConfig[key];
        continue;
      }

      const preferencesColumns = preferenceWithoutVersion[key].columns;
      const visualColumns = initialVisualConfig[key].columns;

      const preferencesAreOld = preferencesColumns.some((col: string) => col.includes('.'));
      if (preferencesAreOld) {
        preferenceWithoutVersion[key] = initialVisualConfig[key];
        continue;
      }

      visualColumns.forEach((column: string) => {
        if (!preferencesColumns.includes(column)) {
          preferencesColumns.push(column);
        }
      });

      preferencesColumns.forEach((column: string) => {
        if (!visualColumns.includes(column)) {
          const preferenceColumnsWithoutRemovedColumns = preferenceWithoutVersion[key].columns.filter((col: string) => col !== column);
          preferenceWithoutVersion[key].columns = preferenceColumnsWithoutRemovedColumns;
          const preferenceDefaultColumnsWithoutRemovedColumns = preferenceWithoutVersion[key].defaultColumns.filter((col: string) => col !== column);
          preferenceWithoutVersion[key].defaultColumns = preferenceDefaultColumnsWithoutRemovedColumns;
        }
      });

      preferenceWithoutVersion[key].options = initialVisualConfig[key].options;
      preferenceWithoutVersion[key].filters = initialVisualConfig[key].filters;
      preferenceWithoutVersion[key].styling = initialVisualConfig[key].styling;
      preferenceWithoutVersion[key].conditionalCalculation = initialVisualConfig[key].conditionalCalculation;

      continue;
    }

    const isDiffValue = 'type' in value && 'data' in value;
    if (isDiffValue && value.type === DiffValue.VALUE_CREATED) {
      delete preferenceWithoutVersion[key];
    }

    if (!isDiffValue && !Array.isArray(initialVisualConfig[key])) {
      preferenceWithoutVersion[key] = mergePreferences(initialVisualConfig[key], preferenceWithoutVersion[key]);
    }

    if (!isDiffValue && Array.isArray(initialVisualConfig[key])) {
      preferenceWithoutVersion[key].forEach((_: any, index: number) => {
        preferenceWithoutVersion[key][index] = mergePreferences(initialVisualConfig[key][index], preferenceWithoutVersion[key][index]);
      });
    }
  }

  return preferenceWithoutVersion;
};

export default mergePreferences;
