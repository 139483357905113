import React from 'react';
import { Button, Input, Popover, Space } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import { getColumnShouldShowSubtotal } from '@/modules/reporting-v2/core/components/DataTablev2/hooks/useCreateTableColumns';
import { castValue } from '@/modules/reporting-v2/utils/castValue';
import { FormatType } from '@/common/types/elastic/FormatType';
import type { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import type { Column } from '@/modules/reporting-v2/core/Column';

type EditCellPopoverProps = {
  initialValue: Primitive | JSX.Element;
  onUpdateData: (value: string | number, formattedValue: string | number, initialValue?: JSX.Element) => void;
  formattingType?: FormatType;
  columnIndex: number;
  level?: number;
  isFooterCell?: boolean;
  isGroupRow?: boolean;
  column?: Column;
};

export const EditCellPopover: React.FC<EditCellPopoverProps> = ({ initialValue, onUpdateData, formattingType, columnIndex, level, isFooterCell, isGroupRow, column }) => {
  const isFirstCell = columnIndex === 0;
  const canEdit = !isGroupRow || (column && getColumnShouldShowSubtotal(column, level));
  if (!canEdit && !isFirstCell) {
    return null;
  }

  if (isFirstCell && isFooterCell) {
    return null;
  }

  return (
    <Popover trigger={'hover'} content={<EditPopoverContent formattingType={formattingType} initialValue={initialValue} onUpdateData={onUpdateData} />}>
      <EditOutlined className="edit-popover-icon" />
    </Popover>
  );
};

const getInitialValue = (initialValue: JSX.Element | Primitive) => {
  if (typeof initialValue === 'object') {
    // display primitive value in popup instead of [object Object]
    return initialValue?.props?.children;
  }
  return initialValue;
};

type EditPopoverContentProps = Pick<EditCellPopoverProps, 'initialValue' | 'formattingType' | 'onUpdateData'>;

const EditPopoverContent: React.FC<EditPopoverContentProps> = ({ initialValue, onUpdateData, formattingType }) => {
  const [value, setValue] = React.useState<Primitive>(() => getInitialValue(initialValue));

  const updateData = () => {
    const formattedValue = castValue(value, formattingType);

    if (typeof initialValue === 'object') {
      onUpdateData(value as string | number, formattedValue as string | number, initialValue ?? undefined);
    } else {
      onUpdateData(value as string | number, formattedValue as string | number);
    }
  };

  // prevent event to propagate and call "onSelectRow" for ex.
  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Space size="small" onClick={stopPropagation}>
      <Input value={(value as string | number) || ''} onChange={e => setValue(e.target.value)} />
      <Button size="small" type="primary" icon={<CheckOutlined />} onClick={updateData} />
    </Space>
  );
};
