import { requestApi } from '@/core';
import { EntitySearch, EntityType } from '@/modules/Onboarding/Shared/types';

// to remove when merging into one project with ui-sesame-admin-new
export const getEntities = (params: EntitySearch, holdingSetType?: EntityType): Promise<any> => {
  return requestApi({
    method: 'GET',
    service: 'client',
    url: `/api/entities?holdingSetType=${holdingSetType ? holdingSetType : ''}`,
    params
  }).then(response => response.data);
};
