import { selector, selectorFamily } from 'recoil';
import { Asset } from '@/api/getAsset';
import { AssetDetailsID } from '@/modules/ERP/Asset/AssetDetails/types';
import {
  assetsFilterState,
  assetTypeByFilterState,
  categoryByFilterState,
  currencyByFilterState,
  parentPortfolioFilterState,
  riskCountryByFilterState
} from '@/modules/ERP/Asset/recoil/asset.atoms';
import { AssetLookup, getAssets as _getAssets } from '@/modules/GlobalSearch/api/getAssets';
import { ListItem } from '@/common/components/SearchOverlay';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import { userEntitiesSelector } from '@/modules/Onboarding/recoil/onboarding.selectors';
import { assetSelector } from '@/modules/GlobalSearch/recoil/search';

export const assetIdSelector = selector<number>({
  key: 'asset-id-selector',
  get: ({ get }) => {
    const assets = get(assetsDetailsListSelector);

    return assets[0].id;
  }
});

export const assetDetailsSelector = selectorFamily<AssetLookup, AssetDetailsID>({
  key: 'erp-asset-details-selector',
  get:
    assetId =>
    async ({ get }) => {
      const assetsList = get(assetsDetailsListSelector);

      if (assetId === null) {
        return assetsList[0] as unknown as AssetLookup;
      }

      return assetsList.find(asset => asset.id === Number(assetId))! as unknown as AssetLookup;
    }
});

export const assetListItemSelector = selectorFamily<ListItem, AssetDetailsID>({
  key: 'erp-asset-list-item-selector',
  get:
    assetId =>
    ({ get }) => {
      const asset = get(assetDetailsSelector(assetId));
      return {
        id: String(asset.id),
        label: asset.name
      };
    }
});

export const assetsDetailsListSelector = selector<Asset[]>({
  key: 'erp-asset-details-list-selector',
  get: async ({ get }) => {
    const holdingSetsList = get(userEntitiesSelector(null));

    return get(assetSelector(holdingSetsList.map(hset => hset.id))) as unknown as Asset[];
  }
});

export const assetListItemsSelector = selector<ListItem[]>({
  key: 'erp-asset-list-items-selector',
  get: ({ get }) => {
    const assets = get(assetsDetailsListSelector);
    return assets.map(it => ({
      id: String(it.id),
      label: it.name
    }));
  }
});

export const assetsListTableSelector = selector<Array<Asset>>({
  key: 'asset-list-table-selector',
  get: ({ get }) => {
    let assets = get(assetsDetailsListSelector);
    const holdingSetTree = get(currentUserSelector).holdingSetTree;

    const assetsFilter = get(assetsFilterState);
    const assetCurrencyFilter = get(currencyByFilterState);
    const assetCategoryFilter = get(categoryByFilterState);
    const assetTypeFilter = get(assetTypeByFilterState);
    const assetRiskCountryFilter = get(riskCountryByFilterState);
    const assetParentPortfolioFilter = get(parentPortfolioFilterState);

    const filterAsset = (filter: string, filterAsset: string) => {
      return filterAsset?.replace(/ /g, String()).toLowerCase().trim().includes(filter.toLowerCase().replace(/ /g, String()));
    };

    if (assetsFilter) {
      assets = assets.filter(asset => filterAsset(assetsFilter, asset.name));
    }

    if (assetCategoryFilter) {
      assets = assets.filter(asset => filterAsset(assetCategoryFilter, asset.category!));
    }

    if (assetTypeFilter) {
      assets = assets.filter(asset => filterAsset(assetTypeFilter, asset.assetType!));
    }

    if (assetCurrencyFilter) {
      assets = assets.filter(asset => !!asset.currency).filter(asset => filterAsset(assetCurrencyFilter, asset.currency));
    }

    if (assetRiskCountryFilter) {
      assets = assets.filter(asset => !!asset.riskCountry).filter(asset => filterAsset(assetRiskCountryFilter, asset.riskCountry));
    }

    if (assetParentPortfolioFilter) {
      assets = assets
        .filter(asset => !!(asset as unknown as AssetLookup).holdingSetId)
        .filter(asset => filterAsset(assetParentPortfolioFilter, holdingSetTree.find(hs => hs.id === (asset as unknown as AssetLookup).holdingSetId)?.name ?? ''));
    }

    return assets;
  }
});
