import React from 'react';
import { Link } from 'react-router-dom';
import { IMappedHyperLink } from '@/modules/reporting-v2/types/Column';
import { TagsTwoTone } from '@ant-design/icons';
import { Popover, Divider } from 'antd';
import stopEventPropagation from '@/utils/stopEventPropagation';
import { GenericIcon } from '@/common/components/Icon/GenericIcon';
import './styles.css';

interface IProps {
  hyperlinks: IMappedHyperLink[];
}

const Hyperlinks: React.FC<IProps> = React.memo(({ hyperlinks }) => {
  if (!hyperlinks.length) return null;

  return (
    <span className="no-print">
      <Popover content={<List hyperlinks={hyperlinks} />} trigger="click" className="antd-popover-popups-dashboard">
        <TagsTwoTone onClick={stopEventPropagation} />
      </Popover>
    </span>
  );
});

interface IListProps {
  hyperlinks: IMappedHyperLink[];
}

const List: React.FC<IListProps> = React.memo(({ hyperlinks }) => {
  return (
    <div>
      {hyperlinks.map((link, idx, list) => {
        return (
          <Link to={link.url} key={link.url}>
            {link.icon && <GenericIcon icon={link.icon} />} &nbsp;
            <span>{link.displayName || link.url}</span>
            {idx !== list.length - 1 && <Divider />}
          </Link>
        );
      })}
    </div>
  );
});

export default Hyperlinks;
