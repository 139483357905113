import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { config } from './config';
import schema from './schema.json';
import { nanoid } from 'nanoid';
import { Column, ColumnFormatting } from '@/modules/reporting-v2/core/Column';
import { Field } from '@/modules/reporting-v2/core/Field';
import memoize from 'fast-memoize';
import LiquidityTableConfig from './LiquidityTableConfig';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import RawLiquidityTableConfig, { LiquidityTableConfigMapped } from './types';
import { RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import { FormatType } from '@/common/types/elastic/FormatType';
import { ParticipationRates } from './VisualSpecificProps';
import { DataTablev2 } from '@/modules/reporting-v2/core/components/DataTablev2/DataTable';

const daysWords = {
  60: 'sixty',
  Infinity: 'gtOneEighty',
  1: 'one',
  90: 'ninety',
  7: 'seven',
  180: 'oneEighty',
  10: 'ten',
  3: 'three',
  5: 'five',
  30: 'thirty',
  20: 'twenty',
  120: 'oneTwenty'
};

const avgNumbers = {
  thirtyDays: 30,
  oneYear: 365
};

const getLiquidityProportionData = memoize((visual: LiquidityTableConfig): { rows: Partial<RowGroup>[]; columns: Column[] } => {
  const participationRateKey = visual.columns.find(col => col.field.name.endsWith('PR'))?.field?.name;
  const averageDailyVolumeKey = visual.columns.find(col => col.field.name.endsWith('ADV'))?.field?.name;
  const daysKey = visual.columns.find(col => col.field.name.endsWith('Days'))?.field?.name;
  const daysNameKey = visual.columns.find(col => col.field.name.endsWith('daysName'))?.field?.name;
  const valueKey = visual.columns.find(col => col.field.name.endsWith('value'))?.field?.name;

  if (!participationRateKey || !valueKey || !averageDailyVolumeKey || !daysKey || !daysNameKey) {
    throw new Error('Invalid liquidity table configuration: missing column. \nRequired columns are rates, average daily volume, days, days name and value');
  }

  const rowsWithADV = visual.data.rows.filter(row => avgNumbers[visual.avgDailyVol] === row.data[averageDailyVolumeKey]);

  const rates = [
    ...new Set(
      rowsWithADV
        .map(row => row.data[participationRateKey] as number)
        .filter(rate => visual.participationRates.includes((rate * 100).toString() as ParticipationRates))
        .sort((a, b) => a - b)
    )
  ];

  const daysName = rowsWithADV.map(row => {
    return {
      name: row.data[daysNameKey] as string,
      days: row.data[daysKey]
    };
  });
  const columns = [
    new Column({
      field: new Field('risk_holding_set.rate'),
      isDefault: true,
      headerConfig: { displayName: 'Rate' },
      formatting: new ColumnFormatting(undefined, FormatType.percentage, undefined, '0')
    }),
    ...visual.liquidateDays.map(days => {
      return new Column({
        field: new Field('risk_holding_set.days'),
        isDefault: true,
        headerConfig: {
          displayName: daysName.find(dname => dname.days?.toString() === days)?.name
        },
        formatting: new ColumnFormatting(undefined, FormatType.percentage, undefined, '0')
      });
    })
  ].filter(Boolean);

  const rows = rates.map(rate => {
    const prRows = rowsWithADV.filter(row => row.data[participationRateKey] === rate);

    return {
      __id__: nanoid(5),
      cells: [
        rate,
        ...visual.liquidateDays.map(days => {
          const dayRow = prRows.find(row => String(row.data[daysKey]) === days);
          if (!dayRow) return '';
          return dayRow.data[valueKey];
        })
      ],
      data: prRows[0]?.data
    };
  });

  return {
    rows: rows,
    columns: columns
  };
});

const getDefaultLiquidityData = memoize((visual: LiquidityTableConfig): { rows: Partial<RowGroup>[]; columns: Column[] } => {
  const participationRateKey = visual.columns.find(col => col.field.name.endsWith('PR'))?.field?.name;

  if (!participationRateKey) {
    throw new Error('Invalid liquidity table configuration: missing rates column.');
  }

  const { liquidateDays } = visual;

  const defaultColumns = visual.columns.filter(column => column?.isDefault);

  const advKey = participationRateKey.split('.').slice(0, -1).join('.');
  const rates = visual.data.rows
    .map(row => row.data[participationRateKey] as number)
    .filter(rate => visual.participationRates.includes((rate * 100).toString() as ParticipationRates))
    .sort((a, b) => a - b);

  const columns = [
    new Column({
      field: new Field('risk_holding_set.rate'),
      isDefault: true,
      headerConfig: { displayName: 'Rate' },
      formatting: new ColumnFormatting(undefined, FormatType.percentage, undefined, '0')
    }),
    ...liquidateDays.map(days => {
      return defaultColumns.find(column => {
        return daysWords[days] === column.field.getElasticPath().split('.').pop();
      });
    })
  ].filter(Boolean) as Column[];

  const rows = rates.map(rate => {
    const row = visual.data.rows.find(row => row.data[participationRateKey] === rate);
    return {
      __id__: nanoid(5),
      cells: [
        rate,
        ...liquidateDays.map(day => {
          return row?.data[`${advKey}.${daysWords[day]}`];
        })
      ],
      data: row?.data
    };
  });

  return {
    columns,
    rows
  };
});

const getLiquidityTableVisual = memoize((visual: LiquidityTableConfig) => {
  const isLiquidityProportionList = visual.columns[0].field.getElasticPath().includes('holdingSetLiquidityProportionList');

  let columns;
  let rows;

  if (isLiquidityProportionList) {
    const liquidityProportionData = getLiquidityProportionData(visual);

    rows = liquidityProportionData.rows;
    columns = liquidityProportionData.columns;
  } else {
    const defaultLiquidityData = getDefaultLiquidityData(visual);

    rows = defaultLiquidityData.rows;
    columns = defaultLiquidityData.columns;
  }

  return {
    columns,
    groupByColumns: [],
    data: {
      ...visual.data,
      rows
    }
  } as IDataTableProps['visual'];
});

class LiquidityTable extends Visual {
  Loader = Loaders.Table;

  static configMapper(visualConfig: RawLiquidityTableConfig) {
    const { rows, ...rest } = visualConfig;
    const columns = mapVisualConfigColumns(rows);
    return {
      ...rest,
      columns,
      sampling: !!rest._sampling
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as LiquidityTableConfig;
  }

  getSchema() {
    return schema;
  }

  renderBody() {
    const visual = getLiquidityTableVisual(this.props.visual as LiquidityTableConfig);

    if (this.props.visual.tableVersion === 'v1') {
      return <DataTable visual={visual} />;
    }

    return <DataTablev2 visual={visual} />;
  }
}

export default LiquidityTable;
