import React from 'react';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import datasourceConfigs from '@/modules/reporting-v2/config/datasource';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { config } from './config';
import schema from './schema.json';
import HighCharts from '@/modules/reporting-v2/core/components/Highcharts';
import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import { Field } from '@/modules/reporting-v2/core/Field';
import { HistoricalChartConfig, RawHistoricalChartConfig } from '..';
import { HistoricalChartConfigMapped } from './types';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import { Index } from '@/modules/reporting-v2/types/Index';
import { Serie } from './VisualSpecificProps';
import { ConfigMappedRawColumn } from '@/modules/reporting-v2/utils/IndexUtils';
import fastSort from 'fast-sort';
import { FormatType } from '@/common/types/elastic/FormatType';

class HistoricalChart extends Visual {
  Loader = Loaders.Chart;
  renderUntilReady = true;

  static configMapper(visualConfig: RawHistoricalChartConfig): HistoricalChartConfigMapped {
    const { vAxis1, vAxis2, group, legendPosition, ...rest } = visualConfig;
    const isEntityBenchmark =
      vAxis1.defaultColumns.filter(field => {
        const fieldPath = new Field(field).getElasticPath();
        // if ReportingService.quantMetas doesn't contain this field we will still have code here instead of fieldPath
        const fieldPathKeys = fieldPath.split('.');

        return typeof fieldPathKeys[1] === 'string' ? ['holdingset', 'benchmark'].includes(fieldPathKeys[1].toLowerCase()) : false;
      }).length === vAxis1.defaultColumns.length;

    let columns: ConfigMappedRawColumn[] = [];
    const series: Serie = {
      axis0: {},
      axis1: {}
    };

    columns = columns.concat(
      mapVisualConfigColumns(vAxis1).map(column => {
        if (column.default) {
          series.axis0[column.field!] = {
            type: column.type || 'line',
            stackedBar: column.stackedBar,
            dataApproximation: column.dataApproximation || 'close'
          };
        }
        return Object.assign(column, { targetAxis: 0 });
      })
    );

    columns = columns.concat(
      mapVisualConfigColumns(vAxis2).map(column => {
        if (column.default) {
          series.axis1[column.field!] = {
            type: column.type || 'line',
            dataApproximation: column.dataApproximation || 'close'
          };
        }
        return Object.assign(column, { targetAxis: 1 });
      })
    );

    const indexNode =
      columns
        .map(col => new Field(col.field!).getElasticPath())
        .find(fieldPath => {
          const [index, ...rest] = fieldPath.split('.');
          const isRootNode = rest.length === 1;

          return !isRootNode && datasourceConfigs.get(index as Index)?.dateField && datasourceConfigs.get(index as Index)?.notPrimaryNodeUnlessSingle;
        })
        ?.split('.')?.[0] || new Field(columns[0].field!).getElasticPath().split('.')[0];

    const dateNode = datasourceConfigs.get(indexNode as Index)?.dateField;

    if (!dateNode) {
      throw new Error();
    }

    if (isEntityBenchmark) {
      columns = columns.concat([
        { field: 'holdingset.name', visible: true, default: false },
        { field: 'holdingset.benchmarkName', visible: true, default: false }
      ]);
    }

    columns.unshift({
      field: dateNode,
      default: true,
      displayName: 'Date',
      visible: true,
      formatting: { type: FormatType.date } // highcharts needs raw date
    });

    const newGroup = {
      ...group,
      defaultColumns: [dateNode, ...group.defaultColumns.slice(0, 1)],
      columns: [dateNode, ...group.columns]
    };

    const newOptions = {
      ...rest._options,
      legend: {
        ...(rest._options.legend as {}),
        position: legendPosition
      }
    };

    return {
      ...rest,
      columns,
      group: mapVisualConfigColumns(newGroup),
      series,
      sampling: rest._sampling === undefined ? true : rest._sampling,
      dateRange: rest._dateRange || rest.dateRange,
      isEntityBenchmark,
      tickRate: rest.tickRate,
      _reportContext: rest._reportContext,
      legend: rest.legend,
      diagonalAxisLabel: rest.diagonalAxisLabel,
      _options: newOptions,
      dateField: dateNode
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as HistoricalChartConfig;
  }

  getSchema() {
    return schema;
  }

  renderChartTable(_visual: VisualEngine) {
    const sortedRows = fastSort(_visual.data.rows).desc(row => 'group' in row && row.group);

    return (
      <DataTable
        visual={
          {
            ..._visual,
            data: {
              ..._visual.data,
              rows: sortedRows
            },
            collapsible: true,
            subTotal: true
          } as IDataTableProps['visual']
        }
      />
    );
  }

  renderBody() {
    return <HighCharts fullSize={this.state.zoomIn} visual={this.props.visual} />;
  }
}

export default HistoricalChart;
