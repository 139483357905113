import { ContentTagLookup } from '@/common/types/entity/ContentTag';
import { QuantCatalogueItemLookup, QuantCatalogueItemMeta, QuantCatalogueItemRef, ReferenceableQuantProperty } from '@/common/types/entity/QuantCatalogueItem';
import { ReportingService } from '@/modules/reporting-v2/core/ReportingService';
import { QuantGroup, QuantSubCategory, QuantTree } from '../Forms/Visual/Schema/Columns/QuantItems/types';
import { ElasticMetricAdapter } from './ElasticMetricAdapter';
import { ElasticColumnMetas } from './QuantLibrary';

export class QuantUtils {
  private constructor() {}

  public static generateUniqueKey(field: ElasticColumnMetas | QuantCatalogueItemLookup | QuantGroup): string | number {
    if (this.isQuantGroup(field)) {
      return field.group!;
    }
    if (ElasticMetricAdapter.isQuantItem(field)) {
      return field.id;
    }
    return field.field;
  }

  public static filterSelectionByDisplayNameCodeAndField(searchValue: string, quantItems: Array<QuantCatalogueItemLookup>) {
    return (item: QuantCatalogueItemLookup) => {
      const displayName = ElasticMetricAdapter.retrieveDisplayName(item, quantItems);
      const field = ElasticMetricAdapter.retrieveField(item);
      const code = ElasticMetricAdapter.retrieveCode(item);

      return [displayName, field, code].some(string => string && string.toLowerCase().includes(searchValue));
    };
  }

  public static isQuantGroup(record: unknown): record is QuantGroup {
    return (record as QuantGroup).group !== undefined && (record as QuantCatalogueItemLookup).elasticPath === undefined;
  }

  public static generatePersistentMetadata(metas: Array<QuantCatalogueItemMeta>): Record<string, QuantCatalogueItemMeta> {
    const quantObject: Record<string, QuantCatalogueItemMeta> = {};

    for (const obj of metas) {
      quantObject[obj.code] = obj;
    }

    return quantObject;
  }

  public static buildCategorizedTree(categories: Array<ContentTagLookup>, quantItems: Array<QuantCatalogueItemLookup>): QuantTree {
    const fieldsByCategory = categories.reduce((acc, category) => {
      const fields = quantItems.filter(qt => qt.category.id === category.id);

      if (!fields.length) {
        return acc;
      }

      return acc.set(category.name, fields);
    }, new Map<string, QuantCatalogueItemLookup[]>());

    return categories
      .filter(category => fieldsByCategory.has(category.name))
      .map(category => {
        const categoryFields = fieldsByCategory.get(category.name)!;

        const subCategoriesByField = categoryFields.reduce<Array<ContentTagLookup>>((acc, field) => {
          if (!acc.find(subc => subc.id === field.subCategory.id)) {
            acc.push(field.subCategory);
          }
          return acc;
        }, []);

        const subCategories: Array<QuantSubCategory> = subCategoriesByField.map(subCategory => {
          const fields = categoryFields.reduce<Array<QuantGroup>>((acc, field) => {
            if (field.subCategory.id !== subCategory.id) {
              return acc;
            }

            const fieldGroup = field.group?.trim();

            if (!fieldGroup) {
              acc.push(field);
              return acc;
            }

            const group = acc.find(group => group.group === field.group);

            if (!group) {
              acc.push({ group: field.group, fields: [field] });
              return acc;
            } else {
              group.fields!.push(field);
              return acc;
            }
          }, []);

          return { ...subCategory, fields };
        });

        return { ...category, subCategories };
      });
  }

  public static retrieveMetadataFromId(id: number) {
    return Object.values(ReportingService.quantMetas).find(quantMeta => quantMeta.id === id);
  }

  public static retrieveDisplayNameFromMetadata(ref?: QuantCatalogueItemRef) {
    if (!ref) {
      return undefined;
    }

    const quantItems = Object.values(ReportingService.quantMetas);

    const queue = [ref];

    while (queue.length) {
      const next = queue.pop()!;

      const quantItem = quantItems.find(item => item.id === next.id)!; // ASSERT_NOT_NULL : if this is undefined, it is because the reference is pointing at a quant metric that does not exist anymore - Library update is required.

      if (quantItem[ReferenceableQuantProperty.displayName]?.value) {
        return quantItem[ReferenceableQuantProperty.displayName]?.value;
      } else {
        const ref = quantItem[ReferenceableQuantProperty.displayName]?.reference;

        if (ref) {
          queue.push(ref);
        }
      }
    }
  }

  public static memoCurrencyFromRef: Record<number | string, string | undefined> = {};

  public static retrieveCurrencyFromMetadata(ref?: QuantCatalogueItemRef) {
    if (!ref) {
      return undefined;
    }

    const currencyInMemo = this.memoCurrencyFromRef[ref.id];
    if (currencyInMemo) {
      return currencyInMemo;
    }

    const quantItems = Object.values(ReportingService.quantMetas);

    const queue = [ref];
    while (queue.length) {
      const next = queue.pop()!;

      const quantItem = quantItems.find(item => item.id === next.id)!; // ASSERT_NOT_NULL : if this is undefined, it is because the reference is pointing at a quant metric that does not exist anymore - Library update is required.

      const currency = quantItem[ReferenceableQuantProperty.currency]?.value;
      if (currency) {
        this.memoCurrencyFromRef[ref.id] = currency;
        return currency;
      } else {
        const newRef = quantItem[ReferenceableQuantProperty.currency]?.reference;

        if (newRef) {
          queue.push(newRef);
        } else {
          this.memoCurrencyFromRef[ref.id] = quantItem.elasticPath;
          return quantItem.elasticPath;
        }
      }
    }
  }

  public static retrieveReferenceElasticPath(ref?: QuantCatalogueItemRef) {
    if (!ref) {
      return undefined;
    }

    return Object.values(ReportingService.quantMetas).find(item => item.id === ref.id)?.elasticPath;
  }

  public static fetchDisplayNameReference(ref: QuantCatalogueItemRef | undefined, quantItems: Array<QuantCatalogueItemLookup>) {
    if (ref === undefined) {
      return undefined;
    }

    const referenceQueue = [ref];

    while (referenceQueue.length) {
      const currentRef = referenceQueue.shift()!;

      const quantRefDisplayName = quantItems.find(item => item.id === currentRef.id)?.displayName;

      if (quantRefDisplayName?.reference) {
        referenceQueue.push(quantRefDisplayName.reference);
      } else {
        return quantRefDisplayName?.value;
      }
    }
  }

  public static retrieveCurrencyReference(ref: QuantCatalogueItemRef | undefined, quantItems: Array<QuantCatalogueItemLookup>) {
    if (ref === undefined) {
      return undefined;
    }

    const item = quantItems.find(item => item.id === ref.id);

    if (item === undefined) {
      return undefined;
    }

    return item.displayName.value ?? this.fetchDisplayNameReference(item.displayName.reference, quantItems);
  }
}
