import React, { useEffect } from 'react';
import { withSmallSuspense } from '@/common/suspense';
import { useAuth0 } from '@auth0/auth0-react';
import Helmet from 'react-helmet';
import { constSelector, useRecoilValue } from 'recoil';
import { hubspotTokenSelector } from '@/common/components/HubSpot/recoil/hubspot.selectors';
import { isDevelopmentEnvironment } from '@/utils/isDevelopmentEnvironment';

window.hsConversationsSettings = {
  loadImmediately: false
};

export const sendHubspotCustomEvent = (eventName: string, properties?: Record<string, unknown>) => {
  window._hsq.push([
    'trackCustomBehavioralEvent',
    {
      name: eventName,
      properties: properties ?? {}
    }
  ]);
};

export const HubSpot: React.FunctionComponent = withSmallSuspense(() => {
  const email = useAuth0().user?.email;

  const hubspotToken = useRecoilValue(email ? hubspotTokenSelector : constSelector(undefined));

  useEffect(() => {
    if (email && hubspotToken) {
      window.hsConversationsOnReady = [
        () => {
          window.hsConversationsSettings.identificationEmail = email;
          window.hsConversationsSettings.identificationToken = hubspotToken;

          window.HubSpotConversations.widget.load();
        }
      ];
    }
  }, [email, hubspotToken]);

  return (
    <Helmet>
      {isDevelopmentEnvironment ? (
        <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/45390240.js"></script>
      ) : (
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/20215302.js" crossorigin="anonymous"></script>
      )}
    </Helmet>
  );
});
