import type SiteTreeEntry from '@/common/types/entity/SiteTreeEntry';
import { findRoute } from '@/modules/ERP/Common/utils';

export const noteDetailsParamKey = 'id';
export const getNoteUrlInfo = (noteId: string, siteTree: SiteTreeEntry[]) => {
  const searchParams = new URLSearchParams({
    [noteDetailsParamKey]: noteId
  });

  const pathname = findRoute(siteTree, 'NOTE_MANAGEMENT_SYSTEM') ? findRoute(siteTree, 'NOTE_MANAGEMENT_SYSTEM').path : undefined;

  return { pathname, searchParams };
};
