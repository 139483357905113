import { retrieveUserLocale } from '@/utils/locale';
import { IntlProvider } from 'react-intl';
import { withSmallSuspense } from '@/common/suspense';
import { useRecoilValue } from 'recoil';
import { userLocaleTextSelector } from '@/common/recoil/locale.selector';

const _IntlWrapper: React.FunctionComponent = ({ children }) => {
  const userLocale = retrieveUserLocale();
  const messages = useRecoilValue(userLocaleTextSelector(userLocale));

  return (
    <IntlProvider locale={userLocale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

const IntlWrapper = withSmallSuspense(_IntlWrapper);

export { IntlWrapper };
