import type { VisualConfigProps } from '@/modules/reporting-v2/types/VisualEngine';
import { LegendPosition } from '@/modules/reporting-v2/core/components/Highcharts/types';

export default interface ExtendedBarChartConfig extends VisualConfigProps {
  axisLabel: AxisLabel;
  axisScale: AxisScale;
  chartOptions?: Record<string, string | Record<string, string> | {}>;
  colorFormatting?: boolean;
  diagonalAxisLabel: boolean;
  legend: boolean;
  maxValue?: number;
  mergeSeriesData?: boolean;
  minValue?: number;
  orientation: Orientation;
  legendPosition?: LegendPosition;
  showLabelsOnGraph: boolean;
  showPlusBeforeValue: boolean;
  showTopSeries?: number;
  sortByBar?: string[];
  sorting?: Sorting;
  stacking?: boolean;
  valueType: BarChartValueType;
  xAxisInterval?: number;
  yAxisInterval?: number;
  colorByPoint?: boolean;
}

export enum AxisScale {
  Linear = 'LINEAR',
  Logarithmic = 'LOGARITHMIC',
  Break = 'BREAK'
}

export enum AxisLabel {
  Both = 'BOTH',
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL',
  None = 'NONE'
}

export enum Orientation {
  BarChart = 'BarChart',
  ColumnChart = 'ColumnChart'
}

export enum Sorting {
  asc = 'asc',
  desc = 'desc'
}

export enum BarChartValueType {
  Absolute = 'ABSOLUTE',
  Percent = 'PERCENT',
  Both = 'BOTH'
}
