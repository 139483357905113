import React from 'react';
import { Table } from '@/modules/reporting-v2/core/components/DataTablev2/components/table/Table';
import { MeasureRenderTime } from '@/modules/reporting-v2/utils/MeasureRenderTime';
import type { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import type { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';

const TransposeTable = React.lazy(async () => ({ default: (await import('@/modules/reporting-v2/core/components/DataTablev2/components/table/TransposeTable')).TransposeTable }));

type DataTableProps = {
  visual: VisualEngine;
  onRowClick?: (rowData: FlattenObject) => void;
  isRowSelected?: (rowData: FlattenObject) => boolean;
  shouldUpdateDataframe?: boolean;
  defaultExpanded?: boolean;
  getInitialDataPath?: (columnId: string, rowId: string) => string;
};

const DataTablev2: React.FC<DataTableProps> = ({ visual, onRowClick, isRowSelected, shouldUpdateDataframe, defaultExpanded, getInitialDataPath }) => {
  const isTransposeTable = 'transpose' in visual && visual.transpose;
  if (isTransposeTable) {
    return <TransposeTable visual={visual} />;
  }

  return (
    <Table
      visual={visual}
      onRowClick={onRowClick}
      isRowSelected={isRowSelected}
      shouldUpdateDataframe={shouldUpdateDataframe}
      defaultExpanded={defaultExpanded}
      getInitialDataPath={getInitialDataPath}
    />
  );
};

export const MemoizedDataTablev2: React.FC<DataTableProps> = React.memo(
  ({ visual, ...rest }) => (
    <MeasureRenderTime id={visual.id} label={`NEW TABLE: ${visual.component} / ${visual.title} `}>
      <DataTablev2 visual={visual} {...rest} />
    </MeasureRenderTime>
  ),
  (prevProps, nextProps) => prevProps.visual.updateDataRefId === nextProps.visual.updateDataRefId
);

export { DataTablev2 };
