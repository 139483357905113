import React from 'react';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import HighCharts from '@/modules/reporting-v2/core/components/Highcharts';
import { error } from '@/utils/error';
import datasourceConfigs from '@/modules/reporting-v2/config/datasource';
import { Field } from '@/modules/reporting-v2/core/Field';
import { Index } from '@/modules/reporting-v2/types/Index';
import { FormatType } from '@/common/types/elastic/FormatType';
import { config } from './config';
import schema from './schema.json';
import RawScatterChartConfig from './types';
import ScatterChartConfig from './ScatterChartConfig';
import { DateRange, Serie } from './VisualSpecificProps';

class ScatterChart extends Visual {
  Loader = Loaders.Chart;
  renderUntilReady = true;

  static configMapper(visualConfig: RawScatterChartConfig) {
    const { group, series, ...config } = visualConfig;
    let columns = [] as any[];

    const getDateField = () => {
      const indexNode =
        columns
          .map(col => new Field(col.field!).getElasticPath())
          .find(fieldPath => {
            const [index, ...rest] = fieldPath.split('.');
            const isRootNode = rest.length === 1;

            return !isRootNode && datasourceConfigs.get(index as Index)?.dateField && datasourceConfigs.get(index as Index)?.notPrimaryNodeUnlessSingle;
          })
          ?.split('.')?.[0] || new Field(columns[0].field!).getElasticPath().split('.')[0];

      return datasourceConfigs.get(indexNode as Index)?.dateField;
    };

    const simplifiedSeries: Serie[] = [];

    series.forEach(serie => {
      if (serie.x?.defaultColumns?.length) {
        columns = columns.concat(mapVisualConfigColumns(serie.x));
      } else {
        error('You need to select one field for hozirontal axis');
      }

      if (serie.y?.defaultColumns?.length) {
        columns = columns.concat(mapVisualConfigColumns(serie.y));
      } else {
        error('You need to select one field for vertical axis');
      }

      simplifiedSeries.push({
        x: serie.x.defaultColumns[0],
        y: serie.y.defaultColumns[0]
      });
    });

    const isHistorical = config._dateRange !== DateRange.SELECTED_DATE;
    if (isHistorical) {
      const dateNode = getDateField();

      if (!dateNode) {
        error('Unable to find a date field');
      }

      columns.push({
        field: dateNode,
        default: true,
        displayName: 'Date',
        visible: true,
        formatting: { type: FormatType.undefined }
      });
    }

    return {
      ...config,
      dateRange: config._dateRange || config.dateRange,
      sampling: config._sampling === undefined ? true : config._sampling,
      columns,
      group: group ? mapVisualConfigColumns(group) : [],
      series: simplifiedSeries,
      chartOptions: {
        ...config.chartOptions,
        trendlines: config.trendline && { 0: { type: 'linear', lineWidth: 3 } }
      }
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as ScatterChartConfig;
  }

  getSchema() {
    return schema;
  }

  renderBody() {
    return <HighCharts fullSize={this.state.zoomIn} visual={this.props.visual} />;
  }
}

export default ScatterChart;
