import EntityRef from './EntityRef';
import { ContentTag, ContentTagLookup, ContentTagRef } from './ContentTag';
import { UUID } from '../types';

export interface ComponentTemplate {
  name: string;
  type: string;
  code: string;
  contentTag: ContentTag;
  configuration: string;
  description?: string;
  accountId?: UUID;

  createdDate: Date;
  createdBy: string;
  lastModifiedDate: Date;
  lastModifiedBy: string;
}

export interface ComponentTemplateRef extends EntityRef {}

export interface ComponentTemplateLookup extends ComponentTemplateRef {
  accountId?: UUID | null; // if accountId is null: component template; else: custom component
  name: string;
  type: string;
  code: string;
  contentTag: ContentTagLookup;
  lastModifiedDate: string;
}

export interface ComponentTemplateLookupExtended extends ComponentTemplateLookup {
  configuration: string;
}

export class TransientComponentTemplate {
  constructor(
    public name: string,
    public type: string,
    public code: string,
    public contentTag: ContentTagRef,
    public configuration: string,
    public description?: string,
    public accountId?: UUID
  ) {}
}

export class PersistentComponentTemplate implements ComponentTemplate, ComponentTemplateRef {
  constructor(
    public id: number,
    public name: string,
    public type: string,
    public code: string,
    public contentTag: ContentTag,
    public configuration: string,
    public createdDate: Date,
    public createdBy: string,
    public lastModifiedDate: Date,
    public lastModifiedBy: string,

    public description?: string,
    public accountId?: UUID
  ) {}

  public static fromComponentTemplate(template: ComponentTemplate & ComponentTemplateRef) {
    return new PersistentComponentTemplate(
      template.id,
      template.name,
      template.type,
      template.code,
      template.contentTag,
      template.configuration,
      template.createdDate,
      template.createdBy,
      template.lastModifiedDate,
      template.lastModifiedBy,
      template.description,
      template.accountId
    );
  }
}
