import React from 'react';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';

const useTotalDataState = (visual: VisualEngine) => {
  const [totalData, setTotalData] = React.useState(visual.data.totals);

  React.useEffect(() => {
    setTotalData(visual.data.totals);
  }, [visual.data.totals]);

  return [totalData, setTotalData] as const;
};

export { useTotalDataState };
