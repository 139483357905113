import { DEFAULT_REPORT_GRID_COLS } from '@/modules/report-builder/components/ReportGrid';
import { Dict } from '@/modules/reporting-v2/types/Common';
import { RawReportConfig, RawReportParamsConfig, RawVisual, ReportOrientation } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import * as configs from '@/modules/reporting-v2/core/visuals/configs';
import { REPORT_BUILDER_CANVAS_SIZE } from '@/modules/report-builder/hooks/useReportBuilder';
import { nanoid } from 'nanoid';
import { RawFilter } from '@/modules/reporting-v2/types/Filter';
import { ConditionalColorObject } from '@/modules/reporting-v2/core/Column';
import { FilterGroupType } from '@/modules/reporting-v2/core/FilterGroup';
import { FilterOperator } from '@/types/Filters';

type OldConditionalColorObject = {
  highlight?: string;
  conditions: OldColorCondition[];
};

type OldColorCondition = {
  condition: FilterOperator;
  threshold: string;
  value: string;
  id: string;
};

type AdaptableConfigType = { [key: string]: any } & RawReportConfig;

export const REPORT_BUILDER_CONFIG_ADAPTER_VERSION = '1.0.0';

const falsyEmptyObject = (value: object | false) => (value && !Object.keys(value).length ? false : value);

const newLayoutItem = (id: string, rowIndex: number, colIndex: number, rows: number, cols: number, maxRows: number, firstHeight?: number): ReactGridLayout.Layout => {
  let height, y;
  if (firstHeight) {
    if (!rowIndex) {
      height = firstHeight;
      y = 0;
    } else {
      height = Math.floor((maxRows - 1) / (rows - 1));
      y = (rowIndex - 1) * height + firstHeight;
    }
  } else {
    height = Math.floor(maxRows / rows);
    y = rowIndex * height;
  }

  if (rowIndex === rows - 1) {
    height = maxRows - y;
  }

  return {
    i: id,
    x: colIndex * (DEFAULT_REPORT_GRID_COLS / cols),
    y,
    w: Math.floor(DEFAULT_REPORT_GRID_COLS / cols),
    h: height
  };
};

const serializeRawVisual = (component: RawVisual) => ({
  id: component.id,
  component: component.component,
  config: { ...component.config, _attrs: {} },
  globalFilters: component.globalFilters,
  selectBoxFields: component.selectBoxFields
});

const adaptV0 = (config: AdaptableConfigType): AdaptableConfigType => {
  const canvas = REPORT_BUILDER_CANVAS_SIZE[config.orientation || ReportOrientation.PORTRAIT];
  return {
    version: REPORT_BUILDER_CONFIG_ADAPTER_VERSION,
    id: config.id,
    title: config.name,
    pages: (config.pages as unknown as [Dict<any>]).map(page => {
      const layout: ReactGridLayout.Layout[] = [];
      const nonInReportVisuals: RawVisual[] = [];
      const rawVisualRows: RawVisual[][] = (page.grid as any[]).reduce((accum, row: any[]) => {
        const visuals = row.flat(Infinity).filter((component: RawVisual) => {
          if (configs[component.component].type !== 'visual') {
            nonInReportVisuals.push(component);
            return false;
          }
          return true;
        });

        if (visuals.length) {
          return accum.concat([visuals]);
        }
        return accum;
      }, []);

      const startsWithCallOut = rawVisualRows[0]?.length === 1 && rawVisualRows[0][0].component.includes('CallOut');

      const components = rawVisualRows.flatMap((row, rowIndex) =>
        row.map((component, colIndex) => {
          layout.push(newLayoutItem(component.id, rowIndex, colIndex, rawVisualRows.length, row.length, canvas.rows, startsWithCallOut ? 1 : undefined));
          return serializeRawVisual(component);
        })
      );

      nonInReportVisuals.forEach((component, colIndex) => {
        layout.push(newLayoutItem(component.id, 0, colIndex, 1, nonInReportVisuals.length, 1));
        components.push(serializeRawVisual(component));
      });

      return {
        id: page.id,
        title: page.name,
        printOnly: page.printOnly,
        components,
        layout
      };
    }),
    config: {
      filters: falsyEmptyObject(config.params ?? config.filters),
      orientation: config.orientation,
      pagination: config.pagination,
      globalFilters: config.globalFilters,
      styles: { css: config.stylesCode }
    }
  } as RawReportConfig;
};

const adaptV1 = (config: AdaptableConfigType): AdaptableConfigType => {
  return {
    ...config,
    config: {
      ...config.config,
      filters: falsyEmptyObject(config.config.params ?? config.config.filters) as RawReportParamsConfig
    },
    pages: config.pages.map(page => ({
      ...page,
      layout: page.layout.map(item => {
        const maxPrevItemY = Math.max(
          ...page.layout
            .filter(siblingItem => {
              return siblingItem.y < item.y && siblingItem.x >= item.x && siblingItem.x + siblingItem.w <= item.x + item.w;
            })
            .map(siblingItem => siblingItem.y + siblingItem.h)
        );
        return {
          ...item,
          y: ~~(item.y < maxPrevItemY ? maxPrevItemY : item.y),
          h: Math.ceil(item.h)
        };
      })
    }))
  };
};

const adaptColorConditions = (config: AdaptableConfigType): AdaptableConfigType => {
  const checkContainColorCondition = (conf: Record<string, any> | string | number | null) => {
    if (typeof conf !== 'object' || !conf || Array.isArray(conf)) {
      return;
    }

    Object.entries(conf).forEach(([key, value]) => {
      const isColorCondition = key === '___color';
      if (isColorCondition && isOldColorCondition(value)) {
        conf[key] = transformOldColorCondition(value);
      }

      const valueIsObject = typeof value === 'object' && value !== null && !Array.isArray(value);
      if (!isColorCondition && valueIsObject) {
        checkContainColorCondition(value);
      }

      const valueIsArrayOfObject = typeof value === 'object' && value !== null && Array.isArray(value);
      if (!isColorCondition && valueIsArrayOfObject) {
        value.forEach(val => checkContainColorCondition(val));
      }
    });
  };

  config.pages.forEach(page => {
    page.components.forEach(component => {
      checkContainColorCondition(component);
    });
  });

  return config;
};

const adapter = (config: AdaptableConfigType): RawReportConfig => {
  let adaptedConfig: AdaptableConfigType = config;
  if (!adaptedConfig.version) {
    adaptedConfig = adaptV0(adaptedConfig);
  }

  // auto-correct visual positions
  if (adaptedConfig.version === REPORT_BUILDER_CONFIG_ADAPTER_VERSION) {
    adaptedConfig = adaptV1(adaptedConfig);
  }

  adaptedConfig = adaptColorConditions(adaptedConfig);

  return adaptedConfig;
};

const isOldColorCondition = (colorCondition: ConditionalColorObject | OldConditionalColorObject | undefined): colorCondition is OldConditionalColorObject => {
  if (!colorCondition) {
    return false;
  }

  if (!colorCondition.conditions || colorCondition.conditions.length === 0) {
    return false;
  }

  return 'threshold' in colorCondition.conditions[0];
};

const transformOldColorCondition = (oldColorCondition: OldConditionalColorObject): ConditionalColorObject => {
  return {
    highlight: oldColorCondition.highlight,
    conditions: oldColorCondition.conditions.map(condition => transformOldCondition(condition))
  };
};

const transformOldCondition = (oldColorCondition: OldColorCondition) => {
  const newFilter: RawFilter = {
    operator: oldColorCondition.condition,
    baseColumn: undefined,
    id: nanoid(10),
    compareWith: {
      value: [oldColorCondition.threshold]
    }
  };
  return {
    id: oldColorCondition.id,
    value: oldColorCondition.value,
    condition: { conditions: [newFilter], type: FilterGroupType.AND }
  };
};

export default adapter;

export { isOldColorCondition, transformOldColorCondition };
