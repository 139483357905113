import React from 'react';
import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import HighCharts from '@/modules/reporting-v2/core/components/Highcharts';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import { config } from './config';
import schema from './schema.json';
import GaugeConfig from './GaugeConfig';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import RawGaugeConfig, { GaugeType } from './types';
import { QuintileScore } from '@/modules/reporting-v2/core/visuals/Gauge/QuintileScore';
import { StarRatingWrapper } from '@/modules/reporting-v2/core/components/StarRating';

class Gauge extends Visual {
  Loader = Loaders.Chart;
  renderUntilReady = true;

  static configMapper(visualConfig: RawGaugeConfig) {
    const { metric, ...rest } = visualConfig;

    return {
      ...rest,
      columns: mapVisualConfigColumns(metric)
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as GaugeConfig;
  }

  getSchema() {
    return schema;
  }

  renderChartTable(_visual: VisualEngine) {
    return <DataTable visual={_visual as IDataTableProps['visual']} />;
  }

  shouldUseQuintileScore(visual: GaugeConfig) {
    return visual.portfolioScore === true;
  }

  renderBody() {
    const visual = this.props.visual as GaugeConfig;

    if (this.shouldUseQuintileScore(visual)) {
      return <QuintileScore visualThickness={visual.thickness} />;
    }

    const noDefaultColumns = !visual.columns.some(column => column.isDefault);
    if (noDefaultColumns) {
      return null;
    }

    const isStarGauge = visual.gaugeType === GaugeType.STAR_RATING;
    if (isStarGauge) {
      return <StarRatingWrapper visual={this.props.visual as GaugeConfig} />;
    }

    return <HighCharts fullSize={this.state.zoomIn} visual={this.props.visual} />;
  }
}

export default Gauge;
