import dayjs from 'dayjs';
import { DATE_FORMAT } from 'ui-sesame-components';

// we keep it lightweight as there is no need to use moment when we want one specific format
export const isDate = (date: string) => {
  return dayjs(date, DATE_FORMAT.DATE, true).isValid();
};

export default (date: string) => {
  const newDate = date.split('-'); // yyyy-mm-dd
  return [newDate[2], newDate[1], newDate[0]].join('-'); // current app wanted format: dd-mm-yyyy
};
