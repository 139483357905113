import { Dict } from '@/modules/reporting-v2/types/Common';
import { VisualConfigProps } from '@/modules/reporting-v2/types/VisualEngine';

export default interface ExtendedScatterChartConfig extends VisualConfigProps {
  trendline: boolean;
  legend: boolean;
  axisLabel: boolean;
  dateRange: DateRange;
  series: Serie[];
  rankingField?: string;
  rankingType?: RankingType;
  rankingNumber?: number;
  chartOptions?: Dict<string | Dict<string>>;
  showEmptyValues: boolean;
  showGroupChildren: boolean;
}

export enum DateRange {
  SELECTED_DATE = 'SELECTED_DATE',
  FROM_INCEPTION = 'FROM_INCEPTION',
  LAST_YEAR = 'LAST_YEAR',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_MONTH = 'LAST_MONTH'
}

export interface Serie {
  x: any;
  y: any;
}

export enum RankingType {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM'
}
