import { DocumentTagLookup, DocumentTagType, SystemTag } from '../../../common/types/entity/DocumentTags';
import Params from './Params';

export default class TransientDocumentTag {
  readonly id: string | null = null; // very important to have this value defined, otherwise patching of tags does not work with the API
  constructor(
    public name: string,
    public readonly type: DocumentTagType,
    public allowedValues: string[] = []
  ) {}

  public isTransient = () => true;
  public equals(other?: TransientDocumentTag | DocumentTagLookup): boolean {
    return other !== undefined && this.type === other.type && this.name === other.name;
  }

  toParams(): Map<string, any> {
    return new Map<string, any>(
      Object.entries({
        [Params.tag_name]: this.name,
        [Params.tag_type]: this.type
      })
    );
  }

  static fromParams = (params: Map<string, any>): undefined | TransientDocumentTag => {
    if (params.has(Params.holding_set)) {
      return new TransientDocumentTag(SystemTag.Entity, DocumentTagType.HOLDING_SET);
    } else if (params.has(Params.asset)) {
      return new TransientDocumentTag(SystemTag.Asset, DocumentTagType.ASSET);
    } else if (params.has(Params.tag_name) && params.has(Params.tag_type)) {
      return new TransientDocumentTag(params.get(Params.tag_name), params.get(Params.tag_type));
    }
  };
}
