import React, { useCallback, useMemo, useState } from 'react';
import { Filter, FilterRow } from '@/modules/ERP/Common/FilterRow';
import { CalendarOutlined } from '@ant-design/icons';
import { Button, Empty, Row } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { withSmallSuspense } from '@/common/suspense';
import { periodFilters } from '@/modules/ERP/Contacts/ContactDetails/Tabs/TabsFilters';
import { useRecoilValue } from 'recoil';
import { contactsNotesSelector } from '@/modules/ERP/Contacts/Recoil/contacts.selectors';
import { Note } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Notes/Note';
import { CreateNote } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Notes/CreateNote';
import { Text } from '@/common/components/Typography/Text';
import { UUID } from '@/common/types/types';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { EntityLinkType } from '@/common/types/entity/Link';
import { usePermission } from '@/common/hooks/usePermission';
import { Permissions } from '@/utils/security';
import { EmptyNotes } from './EmptyNotes';
import { SearchInput } from 'ui-sesame-components';

interface NotesProps {
  entityId: Primitive;
  entityType: EntityLinkType;
}

export const notesDateFilterNodeId = 'notesDateFilter';

const Notes: React.FunctionComponent<NotesProps> = withSmallSuspense(
  React.memo(({ entityId, entityType }) => {
    const translator = useIntl();
    const [visibleModal, setVisibleModal] = useState(false);
    const [searchThroughTasks, setSearchThroughTasks] = useState('');
    const handleSearchThroughTasks = useCallback((event: any) => {
      setSearchThroughTasks(event.target.value);
    }, []);

    const filters = useMemo(() => {
      const activityDateOptions = periodFilters(translator);
      const dateKey = 'generic.date';

      return [
        {
          key: dateKey,
          filter: <Filter recoilNodeId={notesDateFilterNodeId} title={translator.formatMessage({ id: dateKey })} icon={<CalendarOutlined />} options={activityDateOptions} />
        }
      ];
    }, [translator]);

    const showCreateNoteButton = usePermission([Permissions.NOTE_CONTRIBUTOR]);

    const notes = useRecoilValue(contactsNotesSelector(['', entityType, entityId as string]));

    if (notes.length === 0) {
      return (
        <EmptyNotes entityType={entityType}>
          {showCreateNoteButton && (
            <>
              <Button type="primary" onClick={() => setVisibleModal(true)} className="empty-state-modal-button">
                {translator.formatMessage({ id: 'generic.createNote' })}
              </Button>
              <CreateNote search={searchThroughTasks} entityId={entityId as string} isOpen={visibleModal} setIsOpen={setVisibleModal} entityType={entityType} />
            </>
          )}
        </EmptyNotes>
      );
    }

    return (
      <>
        <Row className="contact-activity-filter-row-button note-filters">
          <SearchInput
            placeholder={translator.formatMessage({ id: 'generic.search' })}
            className="contact-activity-search-bar"
            allowClear
            addonAfter={null}
            onChange={handleSearchThroughTasks}
          />
          <FilterRow withPrefix={false} filters={filters} />
          {showCreateNoteButton && (
            <Button type="primary" className={'create-button-documents-tabs'} onClick={() => setVisibleModal(true)}>
              {translator.formatMessage({ id: 'generic.createNote' })}
            </Button>
          )}
        </Row>
        <CreateNote search={searchThroughTasks} entityId={entityId as string} isOpen={visibleModal} setIsOpen={setVisibleModal} entityType={entityType} />
        <NotesList search={searchThroughTasks} entityType={entityType} entityId={entityId as string} />
      </>
    );
  })
);

interface NotesListProps {
  entityId: UUID;
  entityType: EntityLinkType;
  search: string;
}

const NotesList: React.FunctionComponent<NotesListProps> = withSmallSuspense(
  React.memo(({ entityType, entityId, search }) => {
    const notes = useRecoilValue(contactsNotesSelector([search, entityType, entityId]));

    return (
      <>
        {notes.length !== 0 &&
          notes.map(note => {
            return <Note entityId={entityId} key={note.id} note={note} entityType={entityType} />;
          })}
        {notes.length === 0 && (
          <Empty
            description={
              <Text type="secondary">
                <FormattedMessage id="generic.noNotesAvailable" />
              </Text>
            }
          />
        )}
      </>
    );
  })
);

export { Notes };
