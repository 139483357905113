import DocumentTag from '@/modules/DMS/types/DocumentTag';
import { nanoid } from 'nanoid';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import { TreeDataNode } from 'antd';

export namespace MetadataTreeParser {
  const separator = '#';

  export const buildValueNode = (parent: DocumentTag, value: string): DataNode => {
    return { key: `${parent.id}${separator}${value}${separator}${nanoid(5)}`, title: value, tag: parent };
  };

  export const parseNodeValue = (node: EventDataNode<TreeDataNode>) => {
    const [parentKeyId, metadataValue] = String(node.key).split(separator);

    return { parentKeyId, metadataValue };
  };

  export const parseNodeKey = (node: EventDataNode<TreeDataNode>) => {
    const keyId = node.key;

    return { keyId };
  };

  export const isLeaf = (node: EventDataNode<TreeDataNode>) => {
    return String(node.key).includes(separator);
  };
}
