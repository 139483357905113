import { getSingleAssetLink } from '@/modules/Ownership/components/Links';
import buildHyperLinks from '@/modules/reporting-v2/core/components/DataTable/buildHyperLinks';
import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import { Column } from '@/modules/reporting-v2/core/Column';
import SiteTreeEntry from '@/common/types/entity/SiteTreeEntry';

export const useAssetLink = (column: Column, data: FlattenObject, siteTree: SiteTreeEntry[] | null): string | undefined => {
  if (!column.assetNameColumn) {
    return undefined;
  }
  const getCustomLink = () => {
    const links = buildHyperLinks(column.hyperlinks, data);

    return links[0]?.url;
  };
  const customLink = getCustomLink();
  const singleAssetLink = getSingleAssetLink(data, siteTree);

  return customLink || singleAssetLink;
};
