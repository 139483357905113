import { getQuintileScore } from '@/modules/reporting-v2/core/visuals/Gauge/QuintileScore/getQuintileScore';
import { selectorFamily } from 'recoil';
import type { QuintileScore, QuintileScorePayload } from '@/modules/reporting-v2/core/visuals/Gauge/types';

export const quintileScoreSelector = selectorFamily<QuintileScore | null, Readonly<Partial<QuintileScorePayload>>>({
  key: 'quintile-score-selector',
  get: quintileScorePayload => async () => {
    if (!quintileScorePayload.date || !quintileScorePayload.holdingSetId) {
      return null;
    }

    try {
      return await getQuintileScore(quintileScorePayload as QuintileScorePayload);
    } catch (error) {
      return null;
    }
  }
});
