import { AxiosResponse } from 'axios';
import { requestApi } from '@/core';
import type { PrintPayload } from '@/modules/reporting-v2/types/Print';

export default (data: PrintPayload) =>
  requestApi({
    service: 'pdf',
    method: 'post',
    url: '/api/print/html',
    headers: {
      'Content-Type': 'application/json'
    },
    responseType: 'arraybuffer',
    data
  }).then((response: AxiosResponse<ArrayBuffer> | undefined) => response?.data!);
