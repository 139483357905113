import type { RBColor } from '@/modules/report-builder/components/Forms/Visual/Schema/ColorPickerWrapper';

function shortHexColorToHexColor(shortHexColor: string) {
  let hexColor = '';
  for (const character of shortHexColor) {
    hexColor += `${character}${character}`;
  }
  return hexColor;
}

function opacityToHex(opacity: number) {
  const percentageOpacity = Math.min(Math.max(opacity || 1, 0), 1);
  const decimalOpacity = Math.round(percentageOpacity * 255);
  const hexOpacity = decimalOpacity.toString(16);
  return hexOpacity;
}

/**
 * @param hex #ff2200 | #f20
 * @param opacity 0.80 (0 - 1)
 * @returns #ff2200CC
 */
export function toHexAlpha(hex: string, opacity = 1): string | undefined {
  if (opacity > 1) {
    console.error('Opacity can not be greater than 1');
    return undefined;
  }

  const _hex = hex.replace('#', '');
  const isShortHexColor = _hex.length === 3;
  const isLongHexColor = _hex.length === 6;

  if (!isShortHexColor && !isLongHexColor) {
    console.error('HEX value is not valid.');
    return undefined;
  }

  const hexColor = isShortHexColor ? shortHexColorToHexColor(_hex) : _hex;
  const hexOpacity = opacityToHex(opacity);

  return `#${hexColor}${hexOpacity}`;
}

export const rbColorToHexAlpha = (rbColor: RBColor | undefined) => {
  if (!rbColor || !rbColor.color) {
    return undefined;
  }

  const opacity = rbColor.opacity || 100;

  return toHexAlpha(rbColor.color, opacity / 100);
};
