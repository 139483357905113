import React from 'react';
import { FooterContent } from '@/modules/reporting-v2/core/components/DataTablev2/components/Footer';
import { nanoid } from 'nanoid';
import { CellContent } from '@/modules/reporting-v2/core/components/DataTablev2/components/cell/Cell';
import type { Column as VisualColumn } from '@/modules/reporting-v2/core/Column';
import type { ColumnDef } from '@tanstack/react-table';
import type { Row, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import type { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';

export const getColumnShouldShowSubtotal = (column: VisualColumn, level?: number) => {
  if (column.hideSubtotal) {
    return false;
  }

  if (level !== undefined && column.hideAtGroupLevels?.includes(String(level))) {
    return false;
  }

  return true;
};

const getColumnDef = (visualColumn: VisualColumn, columnIndex: number, totalData: FlattenObject): ColumnDef<RowGroup | Row, Primitive> => {
  const isFirstColumn = columnIndex === 0;

  return {
    id: visualColumn.fieldDataPath || 'custom-' + nanoid(6),
    accessorFn: row => {
      const isRowGroup = 'group' in row;
      if (isFirstColumn && isRowGroup) {
        return row.group;
      }

      if (isRowGroup && !getColumnShouldShowSubtotal(visualColumn, row.level)) {
        return null;
      }

      if (row.cells) {
        // old analytics stats table is still using cells to display data
        return row.cells[columnIndex];
      }

      return row.data[visualColumn.fieldDataPath];
    },
    header: () => visualColumn.headerConfig.displayName,
    cell: props => <CellContent {...props} index={columnIndex} />,
    footer: footerContext => <FooterContent footerContext={footerContext} totalData={totalData} columnIndex={columnIndex} />,
    meta: {
      column: visualColumn
    },
    sortUndefined: -1
  };
};

export const useCreateTableColumns = (defaultVisualColumns: VisualColumn[], totalData: FlattenObject): ColumnDef<RowGroup | Row, Primitive>[] => {
  const columns = React.useMemo(() => {
    return defaultVisualColumns.map((vColumn, columnIndex) => {
      return getColumnDef(vColumn, columnIndex, totalData);
    });
  }, [defaultVisualColumns, totalData]);

  return columns;
};
