import React, { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useValidation } from '@/modules/Onboarding/hooks/useValidation';
import { Field, FormContext, useFormContext } from '@/common/form';
import { Status, TaskDTO } from '@/modules/ERP/Contacts/types';
import { Button, message, Modal, Row, Typography } from 'antd';
import { TextInput } from '@/common/components/Input/TextInput';
import { contactsTasksSelector, contactTaskByIdSelector } from '@/modules/ERP/Contacts/Recoil/contacts.selectors';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { createLink } from '@/modules/ERP/Contacts/Api/createLink';
import { EntityLinkType, EntityLinkTypeMenu, LinkableObject, LinkLookup } from '@/common/types/entity/Link';
import { UUID } from '@/common/types/types';
import { TaskSider } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/Form/Sider';
import dayjs, { Dayjs } from 'dayjs';
import ReactQuill from 'react-quill';

import { existingListObjectsSelector, linkObjectSelector, linkableObjectsSelector } from '@/modules/ERP/Common/Recoil/links.selector';
import { withSmallSuspense } from '@/common/suspense';
import { patchTask } from '@/modules/ERP/Contacts/Api/patchTask';
import { deleteLink } from '@/modules/ERP/Contacts/Api/deleteLink';
import { entityLinksSelector } from '@/modules/ERP/Common/Recoil/erp.selectors';
import { Text } from '@/common/components/Typography/Text';
import { descriptionMaxLength, TaskFormType } from './CreateTask';
import { userListMentionQuill } from '@/modules/ERP/Common/utils';
import { handleHtmlContent } from '@/utils/handleHtmlContent';
import { loggedUserSelector, usersForCurrentUsers } from '@/common/auth/recoil/user.selector';
import { disableLink } from '@/modules/ERP/Contacts/Api/disableLink';
import { enableLink } from '@/modules/ERP/Contacts/Api/enableLink';
import { deleteTask } from '@/modules/TMS/api/deleteTask';

interface EditTaskProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  contactId: UUID;
  taskId: string;
  search: string;
  pageEntityType: EntityLinkTypeMenu;
  pageEntityId: string;
}

const EditTask: React.FunctionComponent<EditTaskProps> = withSmallSuspense(
  React.memo(({ isOpen, setIsOpen, taskId, search, pageEntityId, pageEntityType }) => {
    const translator = useIntl();
    const currentTask = useRecoilValue(contactTaskByIdSelector(taskId));
    const links = useRecoilValue(existingListObjectsSelector([EntityLinkType.TASK, taskId]));

    const entityLinks = useRecoilValue(entityLinksSelector([EntityLinkType.TASK, taskId]));

    const [form, setForms] = useState<TaskFormType>({
      title: currentTask.title,
      description: currentTask.description,
      reminder: currentTask.reminder,
      status: currentTask.status,
      priority: currentTask.priority,
      dueDate: currentTask.dueDate ? dayjs(currentTask.dueDate) : null,
      assignee: currentTask.assignee ? { id: currentTask.assignee.id } : null
    });

    const initialTaskStatus = useRef<Status>(currentTask.status);

    const [originalsLinks] = useState<Array<LinkableObject>>(links);
    const [selectedLinks, setSelectedLinks] = useState<Array<LinkableObject>>(links);

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isDeletionOpen, setDeletionOpen] = useState(false);

    const onChange = useCallback(
      (
        values: any,
        key: string,
        value:
          | string
          | Dayjs
          | {
              id: number;
            }
          | null
      ) => {
        const newState = { ...values, [key]: value };

        setForms(newState);
      },
      [setForms]
    );

    const validation = useValidation(
      [
        {
          name: 'title',
          required: true
        },
        {
          name: 'description',
          required: true
        }
      ],
      form
    );

    const handleDescriptionChange = useCallback((content: string) => onChange(form, 'description', content.substring(0, descriptionMaxLength)), [form, onChange]);

    const handleChangeQuill = useCallback((content: string) => handleHtmlContent(handleDescriptionChange)(content), [handleDescriptionChange]);

    const formContext = useFormContext(form, onChange, false, false);

    const linksToCreate = useMemo(() => {
      return selectedLinks.filter(obj => {
        return !originalsLinks.find(item => item._entityType === obj._entityType && item.id === obj.id);
      });
    }, [selectedLinks, originalsLinks]);

    const linksToDelete = useMemo(() => {
      const linksToDelete: Array<LinkLookup> = [];

      originalsLinks
        .filter(object => {
          const foundSelectedObject = selectedLinks.find(item => item.id === object.id && item._entityType === object._entityType);

          return !foundSelectedObject;
        })
        .forEach(object => {
          const existingLinks = entityLinks.filter(
            link => link.type === object._entityType && link.entityId === String(object.id) && !linksToDelete.find(linkToDelete => linkToDelete.id === link.id)
          );

          linksToDelete.push(...existingLinks);
        });

      return linksToDelete;
    }, [originalsLinks, selectedLinks, entityLinks]);

    const enableOrDisableLink = useCallback(async (status: Status, id: string) => {
      if (status === Status.REMOVED) {
        await disableLink(EntityLinkType.TASK, id);
      }
      if (initialTaskStatus.current === Status.REMOVED && initialTaskStatus.current !== status) {
        await enableLink(EntityLinkType.TASK, id);
      }
    }, []);

    const onClickSave = useRecoilCallback(
      ({ refresh, snapshot }) =>
        () => {
          const release = snapshot.retain();
          setConfirmLoading(true);
          patchTask(taskId, form as unknown as Partial<TaskDTO>)
            .then(async responseTask => {
              try {
                const linkObject = await snapshot.getPromise(
                  linkObjectSelector({
                    type: pageEntityType,
                    id: pageEntityId
                  })
                );

                await Promise.all(
                  linksToDelete.map(obj => {
                    deleteLink(EntityLinkType.TASK, responseTask.id, obj.id);
                  })
                );

                await Promise.all(
                  linksToCreate.map(obj => {
                    createLink(EntityLinkType.TASK, responseTask.id, {
                      type: obj._entityType,
                      entityId: obj.id as UUID
                    });
                  })
                );

                await enableOrDisableLink(responseTask.status, responseTask.id);

                message.success(translator.formatMessage({ id: 'generic.successfullyUpdated' }));
                setIsOpen(false);
                setTimeout(() => {
                  refresh(contactTaskByIdSelector(taskId));
                  refresh(existingListObjectsSelector([EntityLinkType.TASK, taskId]));
                  if (pageEntityType && pageEntityId) {
                    refresh(contactsTasksSelector([{ search }, pageEntityType, pageEntityId, true]));
                    refresh(
                      linkObjectSelector({
                        type: EntityLinkType.TASK,
                        id: String(taskId)
                      })
                    );
                  }
                }, 1000);
              } catch (err) {
                message.error(
                  translator.formatMessage({
                    id: 'generic.somethingWentWrongWhenSavingLinks'
                  })
                );
              }
            })
            .catch(error => {
              message.error(error);
            })
            .finally(() => {
              setConfirmLoading(false);
              release();
            });
        },
      [form, linksToCreate, linksToDelete, pageEntityId, pageEntityType]
    );

    const handleDeleteTask = useCallback(() => {
      setDeletionOpen(true);
    }, []);

    const DbdeleteTask = useRecoilCallback(
      ({ refresh }) =>
        () => {
          deleteTask(String(taskId))
            .then(() => {
              disableLink(EntityLinkType.TASK, taskId);
              message.success(translator.formatMessage({ id: 'generic.successfullyDeleted' }));
            })
            .finally(() => {
              setDeletionOpen(false);
              setIsOpen(false);
              setTimeout(() => {
                if (pageEntityType && pageEntityId) {
                  refresh(contactsTasksSelector([{ search }, pageEntityType, pageEntityId, true]));
                  refresh(
                    linkObjectSelector({
                      type: EntityLinkType.TASK,
                      id: String(taskId)
                    })
                  );
                  refresh(linkableObjectsSelector({ type: pageEntityType }));
                }
                refresh(contactTaskByIdSelector(taskId));
                refresh(existingListObjectsSelector([EntityLinkType.TASK, taskId]));
              }, 1000);
            });
        },
      [taskId]
    );

    const loggedUser = useRecoilValue(loggedUserSelector);
    const users = useRecoilValue(usersForCurrentUsers(loggedUser.accountId));

    const [mention, setMention] = useState({});
    useEffect(() => {
      setMention({
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        defaultMenuOrientation: 'top',
        source: (searchTerm: any, renderList: any) => userListMentionQuill(searchTerm, renderList, users)
      });
    }, [users]);

    const modules = useMemo(() => {
      return {
        toolbar: {
          container: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ size: ['small', false, 'large', 'huge'] }, { color: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
            ['link']
          ]
        },
        clipboard: { matchVisual: false },
        mention: mention
      };
    }, [mention]);

    return (
      <Modal
        footer={[
          <Button style={{ display: 'block', float: 'left' }} key="delete" onClick={handleDeleteTask}>
            <FormattedMessage id={'generic.deleteTask'} />
          </Button>,
          <Button key="cancel" type="ghost" onClick={() => setIsOpen(false)}>
            <FormattedMessage id={'generic.cancel'} />
          </Button>,
          <Button disabled={!validation} key="save" type="primary" onClick={onClickSave}>
            <FormattedMessage id={'generic.saveTask'} />
          </Button>
        ]}
        width={'850px'}
        className={'modal-contact-create modal-contact-edit'}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        okText={translator.formatMessage({ id: 'generic.saveTask' })}
        okButtonProps={{ disabled: !validation }}
        destroyOnClose
        confirmLoading={confirmLoading}
      >
        <Typography.Title level={4}>{translator.formatMessage({ id: 'generic.editTask' })}</Typography.Title>
        <FormContext.Provider value={formContext}>
          <Row>
            <Field className={'modal-contact-create-title-field'} withoutColons property="title" label={translator.formatMessage({ id: 'generic.title' })}>
              <TextInput style={{ borderRadius: '5px' }} maxLength={100} placeholder={translator.formatMessage({ id: 'generic.title' })} />
            </Field>
          </Row>
          <Row style={{ width: '100%', display: 'block' }}>
            <label>{translator.formatMessage({ id: 'generic.description' })}</label>
            <ReactQuill
              placeholder={translator.formatMessage({
                id: 'generic.enterTaskDescription'
              })}
              modules={modules}
              onChange={handleChangeQuill}
              defaultValue={currentTask.description}
            />
            <Row justify="end">
              <Text type={form.description?.replace(/<[^>]*>?/gm, '').length > descriptionMaxLength ? 'danger' : undefined}>
                {form.description.replace(/<[^>]*>?/gm, '').length ?? 0} / {descriptionMaxLength}
              </Text>
            </Row>
          </Row>
        </FormContext.Provider>
        <Row>
          <TaskSider setSelectedLinks={setSelectedLinks} selectedLinks={selectedLinks} onChange={onChange} values={form} />
        </Row>
        <Modal
          onOk={DbdeleteTask}
          onCancel={() => setDeletionOpen(false)}
          open={isDeletionOpen}
          title={
            <>
              <ExclamationCircleOutlined style={{ margin: '5px', color: '#FAAD14', fontSize: '16px' }} />
              <FormattedMessage id={'generic.warning'} />
            </>
          }
        >
          <FormattedMessage id={'generic.tasks.messageTaskRemoval'} />
        </Modal>
      </Modal>
    );
  })
);

export { EditTask };
