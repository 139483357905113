import { atom } from 'recoil';
import { RawReportConfig } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { Nullable } from '@/common/types/types';
import { defaultReportConfig } from '../fixtures/default-config';
import { FormValidationError } from '@/modules/report-builder/components/Forms/ConfigurableFormValidator';
import { Dict } from '@/modules/reporting-v2/types/Common';
import { ComponentTemplateLookup } from '@/common/types/entity/ComponentTemplate';
import { BuilderMode } from '@/modules/report-builder/types';

export const targetPageState = atom<string | null>({
  key: 'report-builder-target-page',
  default: null
});

export const tabState = atom<string>({
  key: 'report-builder-side-panel-tab-state',
  default: 'global'
});

export const RBConfigurationState = atom<RawReportConfig>({
  key: 'report-builder-grid-layout',
  default: defaultReportConfig
});

export const selectedVisualState = atom<Nullable<string>>({
  key: 'report-builder-selected-visual-state',
  default: undefined
});

export const errorsState = atom<Dict<Dict<FormValidationError>>>({
  key: 'report-builder-form-errors',
  default: {}
});

export const snapIndexState = atom<number | null>({
  key: 'report-builder-snap-index-state',
  default: null
});

export const reportWidth = atom<number>({
  key: 'report-wrapper-width',
  default: 0
});

export const componentTemplatesState = atom<ComponentTemplateLookup[]>({
  key: 'component-templates',
  default: []
});

export const activePageState = atom<null | string>({
  key: 'report-builder-active-page',
  default: null
});

export const builderMode = atom<BuilderMode | undefined>({
  key: 'report-builder-mode',
  default: undefined
});
