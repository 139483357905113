import { atom, selector } from 'recoil';
import type SiteTreeEntry from '@/common/types/entity/SiteTreeEntry';
import { localStorageEffect } from '@/modules/App/recoil/localStorageEffect';

export const siteTreeState = atom<SiteTreeEntry[] | null>({
  key: 'site-tree-atom',
  default: null,
  dangerouslyAllowMutability: true
});

export const currentSiteTreeExperienceEntriesSelector = selector<SiteTreeEntry[] | undefined>({
  key: 'site-tree-current-experience-atom',
  get: ({ get }) => {
    const siteTree = get(siteTreeState);
    const currentExperience = get(siteTreeEntryState)?.nodes[0];

    return siteTree?.filter(entry => entry.nodes[0] === currentExperience);
  }
});

export const siteTreeEntryState = atom<SiteTreeEntry | null>({
  key: 'site-tree-entry-atom',
  default: null,
  dangerouslyAllowMutability: true
});

export const holdingSetTree = atom<any | null>({
  key: 'holding-set-tree-atom',
  default: null,
  dangerouslyAllowMutability: true
});

export const siderIsCollapsed = atom<boolean>({
  key: 'sider-is-collapsed',
  default: true,
  effects: [localStorageEffect('siderIsCollapsed')]
});
