import { LinkLookup, LinkableObjectList } from '@/common/types/entity/Link';
import { atom } from 'recoil';

export const selectedObjectsState = atom<LinkableObjectList>({
  key: 'selectedObjectsState',
  default: []
});

export const linksToDeleteState = atom<LinkLookup[]>({
  key: 'linksToDeleteState',
  default: []
});

export const linksToCreateState = atom<LinkableObjectList>({
  key: 'linksToCreateState',
  default: []
});
