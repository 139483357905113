import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Form } from 'antd';
import { ConfigurableSchema } from '@/modules/report-builder/components/Forms/Visual/Schema';
import { useIntl } from 'react-intl';
import { VisualFormContextProvider } from '@/modules/report-builder/components/Forms/Visual/VisualFormContext';
import '@/modules/report-builder/style.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
};

const filterObjectProps = (data, condition) => {
  return Object.fromEntries(
    Object.entries(data)
      .filter(([name, content]) => {
        return condition([name, content]);
      })
      .map(([name, content]) => {
        return content instanceof Object ? [name, filterObjectProps(content, condition)] : [name, content];
      })
  );
};

const VisualOptions = ({ values, schema, onSubmit, onReset, isFetching = false }) => {
  const [formValues, setFormValues] = useState(values);
  const translator = useIntl();
  const schemaFields = filterObjectProps(schema.fields, ([name, json]) => {
    let isReportViewerEditable = true;
    if (json instanceof Object && json.reportBuilderOnly) {
      // add "reportBuilderOnly": true to schema field you don't want to be available in Report Viewer
      isReportViewerEditable = false;
    }
    return !name.startsWith('_') && isReportViewerEditable;
  });

  const handleChange = newValues => {
    setFormValues(newValues);
  };

  return (
    <VisualFormContextProvider visualFormValue={formValues}>
      <Form
        layout="horizontal"
        {...formItemLayout}
        className="report-form"
        onFinish={() => {
          if (onSubmit) {
            onSubmit(formValues);
          }
        }}
        onReset={() => {
          onReset && onReset();
        }}
      >
        <ConfigurableSchema values={formValues} onChange={handleChange} schema={schemaFields} isReportViewerForm={true} />
        <Row justify="start" style={{ marginLeft: 10, marginBottom: 10 }}>
          <Button htmlType="reset" type="primary" danger disabled={isFetching}>
            {translator.formatMessage({ id: 'generic.reset' })}
          </Button>
          &nbsp;
          <Button htmlType="submit" type="primary" loading={isFetching}>
            {translator.formatMessage({ id: 'generic.save' })}
          </Button>
        </Row>
      </Form>
    </VisualFormContextProvider>
  );
};

VisualOptions.propTypes = {
  schema: PropTypes.shape({
    fields: PropTypes.object.isRequired
  }).isRequired,
  values: PropTypes.object,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  config: PropTypes.object,
  fields: PropTypes.array
};

export default VisualOptions;
