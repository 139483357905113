import React, { useCallback, useEffect, useState } from 'react';
import { FolderOpenTwoTone } from '@ant-design/icons';
import { Popover, Divider, Button, Empty, Typography } from 'antd';
import stopEventPropagation from '@/utils/stopEventPropagation';
import searchDocumentsByHoldingSetId from '@/modules/DMS/api/searchDocumentsByHoldingSetId';
import { DocumentLookup } from '@/common/types/entity/DocumentLookup';
import { getDocumentUrl } from '@/modules/DMS/api/getDocumentUrl';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import type { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import './styles.css';

interface IDocumentsProps {
  holdingSetId: number;
}

type DocumentsWrapperProps = {
  data: FlattenObject;
};

export const DocumentsWrapper: React.FC<DocumentsWrapperProps> = ({ data }) => {
  return <Documents holdingSetId={data['holdingset.holdingSetId'] as number} />;
};

const Documents: React.FC<IDocumentsProps> = React.memo(({ holdingSetId }) => {
  const [documents, setDocuments] = useState<DocumentLookup[]>([]);
  const [hasClicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    if (hasClicked) {
      searchDocumentsByHoldingSetId(holdingSetId, { size: 20 }).then(setDocuments);
    }
  }, [holdingSetId, hasClicked]);

  const onClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    stopEventPropagation(e);
    setClicked(true);
  }, []);

  return (
    <div onClick={onClick} style={{ textAlign: 'center' }}>
      <Popover
        content={!documents.length ? <Empty /> : <List documents={documents} holdingSetId={holdingSetId} />}
        placement="topRight"
        overlayClassName="dashboard-documents-popover-overlay"
        className="antd-popover-popups-dashboard"
        trigger="click"
      >
        <FolderOpenTwoTone twoToneColor="grey" style={{ verticalAlign: -5 }} />
      </Popover>
    </div>
  );
});

interface IListProps {
  documents: DocumentLookup[];
  holdingSetId: number;
}

const List: React.FC<IListProps> = React.memo(({ documents }) => {
  const translator = useIntl();
  return (
    <div onClick={stopEventPropagation}>
      <Typography.Title level={4}>{translator.formatMessage({ id: 'generic.documents' })}</Typography.Title>

      {documents.map((doc, idx, list) => {
        const download = async (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          const url = await getDocumentUrl(doc.id);
          window.open(url);
        };

        return (
          <div key={doc.id}>
            <div>
              <strong>
                <u>{doc.name}</u>
              </strong>
            </div>
            {doc.lastModifiedDate && (
              <div>
                {translator.formatMessage({ id: 'generic.lastModified' })}: {dayjs(doc.lastModifiedDate).calendar()}
              </div>
            )}
            <br />
            <Button onClick={download} size="small" type="primary">
              {translator.formatMessage({ id: 'generic.download' })}
            </Button>

            {idx !== list.length - 1 && <Divider />}
          </div>
        );
      })}
    </div>
  );
});

export default Documents;
