import { selectorFamily } from 'recoil';
import { getNotes, getNotesByIds } from '@/modules/ERP/Contacts/Api/getNotes';
import type { NoteDTO } from '@/modules/ERP/Contacts/types';
import { EntitySearch } from '@/modules/Onboarding/Shared/types';
import { ListItem } from '@/common/components/SearchOverlay';

const notesSelector = selectorFamily<NoteDTO[], EntitySearch>({
  key: 'notes-selector',
  get:
    ({ ids }) =>
    async () => {
      if (ids) {
        return getNotesByIds(ids);
      }

      return getNotes();
    }
});

const noteListItemSelector = selectorFamily<ListItem, string>({
  key: 'note-list-item-selector',
  get:
    id =>
    ({ get }) => {
      const note = get(notesSelector({ ids: id }))[0];
      return {
        id: note.id,
        label: note.title
      };
    }
});

export { notesSelector, noteListItemSelector };
