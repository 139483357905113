import React from 'react';
import { Card, Collapse, Row } from 'antd';
import { Text } from '@/common/components/Typography/Text';
import { CalendarOutlined } from '@ant-design/icons';
import UpdatedActivity from './UpdatedActivity';
import { useIntl } from 'react-intl';
import DeletedCommentActivity from './DeletedCommentActivity';
import { ActivityActionType, ActivityDTO } from '../../Contacts/types';
import NewCommentActivity from './NewCommentActivity';

type ActivityLayoutProps = {
  activity: ActivityDTO;
  children: React.ReactNode;
  icon: React.ReactNode;
};

const ActivityLayout: React.FC<ActivityLayoutProps> = ({ activity, children, icon }) => {
  const formatDate = new Date(activity.timestamp);
  const translator = useIntl();
  const hasUpdatedValue = activity.updatedValue && Object.keys(JSON.parse(activity.updatedValue)).length !== 0;
  const hasPreviousValue = activity.previousValue && Object.keys(JSON.parse(activity.previousValue)).length !== 0;
  const hasNewCommentValue = activity.type === ActivityActionType.CREATE_COMMENT_TASK;
  const hasDeletedCommentValue = activity.type === ActivityActionType.DELETE_COMMENT_TASK;

  if (!activity.updatedValue || (activity.updatedValue && Object.keys(JSON.parse(activity.updatedValue)).length !== 0)) {
    return (
      <Row wrap={false}>
        <div className="contact-activity-icon-container">{icon}</div>
        <Card className="contact-activity-card">
          <Row align="middle" justify="space-between" wrap={false}>
            <div>{children}</div>
            <div className="contact-activity-date">
              <Text type="secondary" style={{ margin: '5px' }}>
                <CalendarOutlined />
              </Text>
              <Text>
                {formatDate.toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric'
                })}
                ,{' '}
                {formatDate.toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false
                })}
              </Text>
            </div>
          </Row>
          <Row>
            {hasUpdatedValue && hasPreviousValue && (
              <Collapse
                ghost
                items={[{ label: `${translator.formatMessage({ id: 'generic.update' })}`, children: <UpdatedActivity activity={activity} /> }]}
                className="updated-activity-collapse"
                style={{ width: '100%' }}
              />
            )}
            {hasDeletedCommentValue && (
              <Collapse
                ghost
                items={[{ label: `${translator.formatMessage({ id: 'generic.viewDeletedComment' })}`, children: <DeletedCommentActivity activity={activity} /> }]}
                className="updated-activity-collapse"
                style={{ width: '100%' }}
              />
            )}
            {hasNewCommentValue && (
              <Collapse
                ghost
                items={[{ label: `${translator.formatMessage({ id: 'generic.viewNewComment' })}`, children: <NewCommentActivity activity={activity} /> }]}
                className="updated-activity-collapse"
                style={{ width: '100%' }}
              />
            )}
          </Row>
        </Card>
      </Row>
    );
  }
};

export { ActivityLayout };
