import objectPath from 'object-path';

export enum Direction {
  ASC = 'ascend',
  DESC = 'descend'
}

export class Order {
  constructor(
    readonly direction: Direction,
    readonly property: string
  ) {}

  public equals(other: Order): boolean {
    return this.direction === other.direction && this.property === other.property;
  }
}

export class Sort {
  private constructor(readonly orders: Order[]) {}

  static readonly UNSORTED = new Sort([]);

  static by(direction: Direction, ...properties: string[]): Sort {
    return new Sort(properties.map(prop => new Order(direction, prop)));
  }

  public equals(other: Sort) {
    // TODO: ignore order
    return this.orders.length === other.orders.length && this.orders.every((value, index) => value.equals(other.orders[index]));
  }
}

export const naturalSort =
  (property: string, { format = (value: any) => value, reverse = false } = {}) =>
  (a: any, b: any) => {
    const va = format(objectPath.get(a, property));
    const vb = format(objectPath.get(b, property));
    return (va !== '' && vb !== '' && !isNaN(va) && !isNaN(vb) ? va - vb : va !== undefined ? va.toString().localeCompare(vb) : 1) * (reverse ? -1 : 1);
  };
