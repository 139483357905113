import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { Orientation } from './VisualSpecificProps';
import type RawCallOutConfig from './types';
import { getEmptyRawColumn } from '@/modules/reporting-v2/utils/getEmptyRawColumn';

export const config: RawCallOutConfig = {
  type: 'visual',
  dateRange: 'SELECTED_DATE',
  fields: getEmptyRawColumn(),
  orientation: Orientation.horizontal,
  _reportContext: VisualReportContext.NONE
};
