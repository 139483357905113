import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import type RawStatsTableConfig from './types';

export const config: RawStatsTableConfig = {
  type: 'visual',
  _reportContext: VisualReportContext.NONE,
  columns: {
    defaultColumns: [],
    columns: [],
    options: {},
    filters: {}
  },
  mode: []
};
