import { HoldingSet } from '@/modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';

export function findHoldingSet(tree: HoldingSet[], condition: (hs: HoldingSet) => boolean): HoldingSet | undefined {
  for (const hs of tree) {
    if (condition(hs)) {
      return hs;
    }
    if (hs.children && hs.children.length > 0) {
      const childHs = findHoldingSet(hs.children, condition);
      if (childHs) {
        return childHs;
      }
    }
  }
  return undefined;
}

export function findHoldingSetById(tree: HoldingSet[], id: number) {
  return findHoldingSet(tree, hs => hs.id === id);
}

export function findHoldingSetByName(tree: HoldingSet[], name: string) {
  return findHoldingSet(tree, hs => hs.name === name);
}
