import React from 'react';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { config } from './config';
import schema from './schema.json';
import FlagConfig from './FlagConfig';
import RawFlagConfig, { FlagValue } from './types';
import { CheckCircleFilled, ExclamationCircleFilled, WarningFilled } from '@ant-design/icons';

class Flag extends Visual {
  Loader = Loaders.CallOut;

  renderInteractivity() {
    return super.renderInteractivity().slice(2);
  }

  getSchema() {
    return schema;
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as FlagConfig;
  }

  static configMapper(visualConfig: RawFlagConfig) {
    return {
      ...visualConfig,
      columns: []
    };
  }

  getFlagIcon(staticValue: FlagValue | undefined) {
    switch (staticValue) {
      case 'FLAGGED':
        return <ExclamationCircleFilled />;
      case 'WARNING':
        return <WarningFilled />;
      default:
        return <CheckCircleFilled />;
    }
  }

  getFlagStatusText(staticValue: FlagValue | undefined) {
    switch (staticValue) {
      case 'FLAGGED':
        return 'Flagged';
      case 'WARNING':
        return 'Warning';
      default:
        return 'Passed';
    }
  }

  renderBody() {
    const { label, staticValue } = this.props.visual as FlagConfig;

    const statusIcon = this.getFlagIcon(staticValue);
    const statusText = this.getFlagStatusText(staticValue);

    return (
      <div className="flag-visual-container visual-container">
        <strong>{label}: </strong>
        <span>{statusText}</span>
        <span className={`flag-icon flag-status-${staticValue}`}>{statusIcon}</span>
      </div>
    );
  }
}

export default Flag;
