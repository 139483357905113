import { AxiosResponse } from 'axios';
import { requestApi } from '@/core';
import { UUID } from '@/common/types/types';
import { TaskDTO } from '@/modules/ERP/Contacts/types';

export const patchTask = (taskId: UUID, data: Partial<TaskDTO>): Promise<TaskDTO> => {
  return requestApi({
    service: 'collaboration',
    method: 'patch',
    url: `/api/task/${taskId}`,
    data
  }).then((resp: AxiosResponse<TaskDTO> | undefined) => {
    return resp!.data;
  });
};
