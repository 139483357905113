import { requestApi } from '@/core';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';

export default (form: Record<string, Primitive>): Promise<any> =>
  requestApi({
    method: 'post',
    service: 'client',
    url: `/public/api/saasClientCompanies?accessCode=${form.accessCode}`,
    headers: {
      'content-type': 'application/json'
    },
    data: form
  }).then((response: any) => response?.data!);
