export enum ModulePath {
  REPORT_BUILDER = '/builder',
  ONBOARDING = '/onboarding',
  REPORT_PRINT = '/report/print',
  NEW_REPORT_PRINT = '/report-analytics/print',
  QUANT_LIB = '/builder/quant',
  CONTENT_TAGS_LIBRARY = '/builder/content-tags',
  COMPONENT_LIB = '/builder/library',
  BUILDER_EDIT = '/builder/edit/:entityType/:id',
  BUILDER_PREVIEW = '/builder/preview',
  SHEET_EDITOR = '/builder/spreadsheet/:id?',
  SHEET_PREVIEW = '/builder/spreadsheet/preview/:id?',
  SHEET_EDITOR_BETA = '/builder/spreadsheet/beta/:id?',
  SHEET_PREVIEW_BETA = '/builder/spreadsheet/beta/preview/:id?'
}
