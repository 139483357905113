import queryString from 'query-string';
import { EntityParametersHandler } from './EntityParametersHandler';

export class ParametersHandler {
  private constructor() {}
  private static readonly separator = '_';

  public static retrieveParams(params?: Record<string, string>): Record<string, number[]> {
    const qs =
      params ??
      queryString.parse(window.location.search.substring(1), {
        arrayFormat: 'comma'
      });
    const newParams = Object.fromEntries(
      Object.entries(qs)
        .filter(([key]) => key !== EntityParametersHandler.parametersKey)
        .map(([param, value]) => {
          return [param, (typeof value === 'string' ? value.split(this.separator) : value)?.map(Number) ?? []];
        })
    );

    return newParams;
  }
}
