import { requestApi } from '@/core';
import type { ContactLookup } from '@/modules/ERP/Contacts/types';
import type { AxiosResponse } from 'axios';
import type { EntitySearch } from '@/modules/Onboarding/Shared/types';

interface ContactsResponse {
  content: Array<ContactLookup>;
}

export const getContacts = (params?: EntitySearch, ids?: Array<string> | string): Promise<Array<ContactLookup>> => {
  return requestApi({
    service: 'contact',
    method: 'get',
    url: `/api/contacts?ids=${ids ? ids : ''}`,
    params: {
      ...params,
      size: 99999
    }
  }).then((resp: AxiosResponse<ContactsResponse> | undefined) => {
    return resp!.data.content;
  });
};

export const getContactsEnriched = (params?: EntitySearch, ids?: Array<string> | string): Promise<Array<ContactLookup>> => {
  return requestApi({
    service: 'contact',
    method: 'post',
    url: `/api/contacts/enriched`,
    params: {
      size: 99999
    },
    data: {
      ...params,
      ...(ids?.length ? { ids } : undefined),
    }
  }).then((resp: AxiosResponse<ContactsResponse> | undefined) => {
    return resp!.data.content;
  });
};
