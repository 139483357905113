import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { getEmptyRawColumn } from '@/modules/reporting-v2/utils/getEmptyRawColumn';
import type RawTreeMapConfig from './types';

export const config: RawTreeMapConfig = {
  type: 'visual',
  breakdown: getEmptyRawColumn(),
  field: getEmptyRawColumn(),
  total: false,
  subTotal: false,
  chartOnly: false,
  _options: {},
  _reportContext: VisualReportContext.NONE
};
