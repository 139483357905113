import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { getColumns } from '@/modules/reporting-v2/utils/IndexUtils';
import { ExtendedSelectBoxConfig } from '..';
import { SelectBoxConfigMapped } from './types';

export default class SelectBoxConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as SelectBoxConfigMapped));
  }

  getVisualConfigProps(config: SelectBoxConfigMapped): ExtendedSelectBoxConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      field: config._list,
      additionalFieldsToFilter: config.additionalFieldsToFilter,
      dataFilter: config.dataFilter ? getColumns(config.dataFilter) : [],
      measureToFilterOn: config.measureToFilterOn ? getColumns(config.measureToFilterOn) : [],
      pushQueryParams: config.pushQueryParams,
      allowUndefinedValues: config.allowUndefinedValues,
      groupable: config.groupable,
      label: config._label,
      options: config._options,
      defaultValues: config._default,
      multiple: config._multiple,
      readonly: config._readOnly,
      includeInPrint: config.print,
      placeholder: config._placeholder,
      customOptions: config._customOptions,
      disableEmptyValue: config.disableEmptyValue
    });
  }

  setVisualConfig(config: ExtendedSelectBoxConfig) {
    super.setVisualConfig(config);
    this.field = config.field;
    this.groupable = config.groupable;
    this.label = config.label;
    this.options = config.options;
    this.defaultValues = config.defaultValues;
    this.multiple = config.multiple;
    this.readonly = config.readonly;
    this.includeInPrint = config.includeInPrint;
    this.placeholder = config.placeholder;
    this.dataFilter = config.dataFilter;
    this.measureToFilterOn = config.measureToFilterOn;
    this.additionalFieldsToFilter = config.additionalFieldsToFilter;
    this.customOptions = config.customOptions;
    this.disableEmptyValue = config.disableEmptyValue;
    this.allowUndefinedValues = config.allowUndefinedValues;
    this.pushQueryParams = config.pushQueryParams;
  }

  field: string;
  groupable: boolean;
  label: string;
  options: Record<string, string>;
  defaultValues: string[];
  multiple: boolean;
  readonly: boolean;
  includeInPrint?: boolean;
  placeholder?: string;
  additionalFieldsToFilter: string[];
  customOptions: Record<string, Record<string, string | number>>;
  disableEmptyValue?: boolean;
  pushQueryParams?: boolean;
  allowUndefinedValues?: boolean;
}
