import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ReactElement } from 'react';

export enum ReportVisualGroup {
  CHARTS = 'Charts',
  CALLOUTS = 'Callouts',
  TABLES = 'Tables',
  CONTROLLERS = 'Controllers',
  LIMITS = 'Limits',
  OTHERS = 'Others',
  GHOST = 'Ghost'
}

export enum BuilderMode {
  REPORT = 'report',
  TEMPLATE = 'template'
}

export type BuilderType = 'DISCLAIMER' | 'BACK_COVER' | 'COVER';

export type VisualBuilder = {
  group: Array<ReportVisualGroup>;
  icon: React.ReactNode;
  title: string;
  preview?: React.FC<{
    builder: VisualBuilder;
    visual: RawVisual;
    placeholder: ReactElement;
  }>;
};

export type QuantUsages = {
  class?: string;
  ids?: number[];
};
