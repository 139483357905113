/* eslint-disable @typescript-eslint/no-namespace */
import { AuthHeader, AuthSessionStorageManager } from '@/common/auth/storage/AuthSessionStorageManager';
import queryString from 'query-string';
import { auth0Utils } from '@/core/Auth0Utils';

export namespace SecurityUtils {
  export const retrieveAuth0AccessToken = async () => {
    return localStorage.getItem(AuthHeader.TOKEN) || AuthSessionStorageManager.getHeader(AuthHeader.TOKEN) || (await auth0Utils.getToken!());
  };

  export const resetAuthentication = async () => {
    await auth0Utils.logout!();
    window.location.reload();
  };

  export const getUrlWithInternal = (url: string) => {
    const urlObject = queryString.parseUrl(url);
    urlObject.query['internal'] = 'true';
    return queryString.stringifyUrl(urlObject);
  };

  export const isInternalApp = () => {
    return window.location.search.includes('internal=true');
  };
}
