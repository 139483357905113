import React from 'react';
import { Text } from '@/common/components/Typography/Text';
import { FormattedMessage } from 'react-intl';
import { RightOutlined } from '@ant-design/icons';

import './style.css';

interface ViewAllProps {
  onClick?: () => void;
}

const ViewAll: React.FunctionComponent<ViewAllProps> = React.memo(({ onClick }) => {
  return (
    <Text onClick={onClick} className="links-activity-view-all-text" link>
      <FormattedMessage id="generic.viewAll" />
      &nbsp;
      <RightOutlined />
    </Text>
  );
});

export { ViewAll };
