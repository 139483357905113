import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import serializeWithNulls from '../../../utils/serializeWithNulls';
import { CommentLookup } from '../../../common/types/entity/Conversation';

export default (conversation_id: number, comment_id: number, attrs: { [key: string]: any }): Promise<CommentLookup> =>
  requestApi({
    service: 'collaboration',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'patch',
    url: `/api/conversation/${conversation_id}/comment/${comment_id}`,
    data: serializeWithNulls(attrs)
  }).then((response: AxiosResponse<CommentLookup> | undefined) => response?.data!);
