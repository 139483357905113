import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { EntityLinkType, LinkLookup } from '@/common/types/entity/Link';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';

export const getLinks = async (entityType: EntityLinkType, id: Primitive): Promise<Array<LinkLookup>> => {
  return requestApi({
    service: 'link',
    method: 'get',
    url: `/api/${entityType}/${id}/links`
  }).then((resp: AxiosResponse<Array<LinkLookup>> | undefined) => {
    return resp!.data;
  });
};
