import { Column } from '@/modules/reporting-v2/core/Column';
import { Row, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';

export const flatToHierarchy = (
  rows: (Row | RowGroup)[],
  columns: Column[],
  config: {
    idField: string;
    parentIdField: string;
    levelField: string;
  }
) => {
  const tree: (Row | RowGroup)[] = [];
  const childOf: any = {};
  const { idField, parentIdField, levelField } = config;

  rows.forEach(row => {
    const id = row.data[idField] as string;
    const parentId = row.data[parentIdField] as string;
    const level = row.data[levelField];
    const group = row.cells[0];
    const cells = columns.map(col => {
      return row.data[col.fieldDataPath];
    });

    childOf[id] = childOf[id] || [];
    (row as RowGroup).rows = childOf[id];
    (row as RowGroup).level = level as number;
    (row as RowGroup).group = group;
    row.cells = cells;

    parentId ? (childOf[parentId] = childOf[parentId] || []).push(row) : tree.push(row);
  });

  tree.forEach(row => {
    (row as RowGroup).maxRangeValues = Object.fromEntries(
      columns
        .filter(column => column.dataBars)
        .map(column => [column.fieldDataPath, Math.max(...(row as RowGroup).rows.map(child => Math.abs(child.data[column.fieldDataPath] as number)))])
    );
  });

  return tree;
};
