import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { getEmptyRawColumn } from '@/modules/reporting-v2/utils/getEmptyRawColumn';
import RawAllocationTableConfig from './types';

export const config: RawAllocationTableConfig = {
  type: 'visual',
  columns: getEmptyRawColumn(),
  group: getEmptyRawColumn(),
  _collapsible: true,
  subTotal: true,
  total: true,
  _reportContext: VisualReportContext.NONE
};
