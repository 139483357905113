import { useEffect, useState } from 'react';

export function useFormValues<T extends object>(
  initialObject: T,
  onChange?: (object: T, property: string, value: any) => void
) {
  const [formValues, setFormValues] = useState<T>(initialObject);

  useEffect(() => {
    setFormValues(initialObject);
  }, []); 

  const updateFormValue = (property: string, value: any) => {
    const updatedValues = { ...formValues, [property]: value };
    setFormValues(updatedValues);
    onChange && onChange(updatedValues, property, value);
  };

  return { formValues, updateFormValue };
}