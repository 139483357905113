import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import type { UUID } from '@/common/types/types';
import type { FullAccount } from '@/common/types/entity/Account';
import { NetworkError } from '@/common/auth/api/NetworkError';

export default async (accountId: UUID): Promise<FullAccount | NetworkError> => {
  return requestApi({
    url: `/api/accounts/${accountId}/full`,
    service: 'account',
    method: 'get',
    errors: false
  })
    .then((response: AxiosResponse<FullAccount | NetworkError> | undefined) => {
      return response!.data;
    })
    .catch(err => {
      return new NetworkError({ statusCode: err.response.data.status });
    });
};
