import { CSSProperties } from 'react';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { DATE_FORMAT } from 'ui-sesame-components';
import { Status } from '@/modules/ERP/Contacts/types';
import { SearchCriteria } from '@/modules/TMS/api/searchTasks';

dayjs.extend(calendar);

export const FlexNoWrap: CSSProperties = { flexWrap: 'nowrap' };

export const CalendarDateTimeFormatSimple = {
  sameDay: 'HH:mm',
  lastDay: DATE_FORMAT.DATE,
  lastWeek: DATE_FORMAT.DATE,
  sameElse: DATE_FORMAT.DATE
};

type SortValue = number | string;
type SortDirection = 'asc' | 'desc';

export class Sort<T = SortValue> {
  constructor(public key: T, public direction: SortDirection = 'desc', public group: boolean = true) {}
}

export enum SortByField {
  TYPE = 'TYPE',
  STATUS = 'STATUS',
  CREATED = 'CREATED',
  MODIFIED = 'MODIFIED',
  ASSIGNEE = 'ASSIGNEE',
  ENTITY = 'ENTITY',
  REFERENCE = 'REFERENCE'
}

export class TaskListParameters {
  constructor(public criteria: SearchCriteria = new SearchCriteria('', [], [Status.TO_DO]), public sort: Sort<SortByField> = new Sort<SortByField>(SortByField.CREATED)) {}
}
