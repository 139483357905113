import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import type { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { getTheme } from '@/modules/reporting-v2/utils/theme';
import chartColors from '@/modules/reporting-v2/core/visuals/AllocationPie/colors.json';
import chartContrastColors from '@/modules/reporting-v2/core/components/Highcharts/contrastColors.json';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import { parseChartStyles } from '@/modules/Styling/utils/parseChartStyles';

type LegendChartColorContextWrapperProps = {
  children: React.ReactElement;
  visual: VisualEngine;
};

type LegendChartColorContextValue = {
  useLegendChartColor: boolean;
  getLegendChartColor: (group: Primitive, rowIndex: number) => Record<string, never> | { color?: string; printColor?: string };
};

const LegendChartColorContext = createContext<LegendChartColorContextValue>({
  useLegendChartColor: false,
  getLegendChartColor: (_, __) => ({})
});

const LegendChartColorContextWrapper: React.FC<LegendChartColorContextWrapperProps> = ({ children, visual }) => {
  const reportContext = useContext(ReportContext);
  const theme = getTheme();
  const colors = (chartColors as Record<string, string[]>)[theme];

  const useLegendChartColor = useMemo(() => {
    return 'legendChartColor' in visual && !!visual.legendChartColor;
  }, [visual]);

  const getLegendChartColor = useCallback(
    (group: Primitive, rowIndex: number) => {
      if (visual.styles?.colorScheme?.linkToVisualColorScheme) {
        const chartVisual = reportContext.visuals!.get(visual.styles.colorScheme.linkToVisualColorScheme)!;

        if (!chartVisual) {
          return {};
        }
        const printChartColors = parseChartStyles(chartVisual.styles!).colors;
        const chartGroupIndex = chartVisual.data.rows.findIndex(row => 'group' in row && row.group === group);

        return {
          color: colors[chartGroupIndex % colors.length],
          printColor: printChartColors?.[chartGroupIndex % printChartColors.length]
        };
      } else {
        const contrastColors = (chartContrastColors as Record<string, string[]>)[theme];
        return {
          color: colors[rowIndex % colors.length],
          printColor: contrastColors[rowIndex % contrastColors.length]
        };
      }
    },
    [colors, reportContext.visuals, theme, visual.styles?.colorScheme?.linkToVisualColorScheme]
  );

  const contextValue = useMemo(() => {
    return {
      useLegendChartColor,
      getLegendChartColor
    };
  }, [useLegendChartColor, getLegendChartColor]);

  return <LegendChartColorContext.Provider value={contextValue}>{children}</LegendChartColorContext.Provider>;
};

const useLegendChartColorContext = () => {
  const LegendChartColorContextValue = useContext(LegendChartColorContext);

  return LegendChartColorContextValue;
};

export { LegendChartColorContextWrapper, useLegendChartColorContext };
