import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { RouteComponentProps } from 'react-router-dom';
import { FilterButton } from '@/modules/reporting-v2/core/components';
import Visual, { initialState } from '@/modules/reporting-v2/core/visuals/Visual/index';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import { Field } from '@/modules/reporting-v2/core/Field';
import { Filter } from '@/modules/reporting-v2/core/Filter';
import { Condition } from '@/modules/reporting-v2/core/Condition';
import datasourceConfigs from '@/modules/reporting-v2/config/datasource';
import { FilterOperator } from '@/types/Filters';
import { IVisualCoreProps } from '@/modules/reporting-v2/types/VisualCoreTypes';
import { RawColumn } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { DateRange } from '@/modules/reporting-v2/core/visuals/DateSlicer/VisualSpecificProps';
import { config } from './config';
import schema from './schema.json';
import { getDates } from './getDates';
import DateSlicerConfig from './DateSlicerConfig';
import RawDateSlicerConfig from './types';
import './style.css';
import { DATE_FORMAT } from 'ui-sesame-components';

export type RangePickerValue = [number | undefined, number | undefined];

class DateSlicer extends Visual {
  format = DATE_FORMAT.DATE;
  initialDateData = [undefined, undefined] as RangePickerValue;

  constructor(props: RouteComponentProps & IVisualCoreProps) {
    super(props);

    this.state = {
      ...initialState,
      range: [undefined, undefined]
    };
  }

  componentDidUpdate() {
    const entityDate = this.retrieveEntityDate();
    if (entityDate && this.state.paramsDate !== entityDate) {
      this.onPreLoad();
    }
  }

  static configMapper(visualConfig: RawDateSlicerConfig) {
    const { startDate, endDate, ...rest } = visualConfig;
    const columns = {
      defaultColumns: [],
      columns: [],
      filters: {},
      options: {}
    } as RawColumn;

    if (startDate?.defaultColumns.length) {
      columns.defaultColumns.push(startDate.defaultColumns[0]);
      columns.columns.push(startDate.columns[0]);
    }

    if (endDate?.defaultColumns.length) {
      columns.defaultColumns.push(endDate.defaultColumns[0]);
      columns.columns.push(endDate.columns[0]);
    }

    return {
      ...rest,
      columns: mapVisualConfigColumns(columns),
      startDateField: startDate?.defaultColumns[0],
      endDateField: endDate?.defaultColumns[0]
    };
  }

  renderInteractivity() {
    return [];
  }

  onPreLoad() {
    const visual = this.props.visual as DateSlicerConfig;
    const startDateField = visual.startDateField;
    const endDateField = visual.endDateField;

    // Hardcoded dates in the config, they take priority over any other date
    const hardFromDate = visual.fromDate;
    const hardToDate = visual.toDate;
    const entityDate = this.retrieveEntityDate();

    const range = getDates(visual.data.rows, hardFromDate, hardToDate, startDateField, endDateField, visual.dateRange, entityDate) as RangePickerValue;

    this.setState({ range, paramsDate: entityDate });
    this.initialDateData = range as RangePickerValue;
    this.setContextDateFilter(range);
  }

  retrieveEntityDate() {
    const entityParam = this.context.params.p?.[this.props.visual.entityOrdering[0]];

    if (!entityParam) {
      return null;
    }

    let entityDate = entityParam.date;
    if (!entityDate) {
      const verifiedDates = this.props.currentUser.verifiedDates;
      entityDate = verifiedDates[entityParam.holdingSetId];
    }

    return entityDate;
  }

  handleDateChange = (values: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    const range: RangePickerValue = values ? [values[0]?.valueOf(), values[1]?.valueOf()] : this.initialDateData;

    this.setState({ range });
    this.setContextDateFilter(range);
    this.context.refreshVisuals();
  };

  setContextDateFilter = (range = this.state.range) => {
    // in case "from inception" is selected as a Date Range, we should leave the start date unselected
    const startDate = range && range[0] === undefined && range[1] ? new Date(0) : range?.[0];
    const greaterThanDate = dayjs(startDate).format(DATE_FORMAT.DATE);
    const lesserThanDate = dayjs(range?.[1]).format(DATE_FORMAT.DATE);
    const futureDates = (this.props.visual as DateSlicerConfig).dateRange === DateRange.FUTURE_DATES;
    const filters = [] as Filter[];

    [
      ...new Set(
        [...this.context.visuals.values()]
          .filter(visual => this.props.visual.id !== visual.id && visual.crossFilterReceiver)
          .map(visual => {
            const config = datasourceConfigs.get(visual.getPrimaryIndexNode()!.getIndex());

            return futureDates ? config.futureDateField || config.dateField : config.dateField;
          })
      )
    ].forEach(field => {
      if (field) {
        filters.push(
          new Filter(new Field(field), new Condition(FilterOperator.GREATER_OR_EQUAL, [greaterThanDate]), undefined, undefined, true),
          new Filter(new Field(field), new Condition(FilterOperator.LESS_OR_EQUAL, [lesserThanDate]), undefined, undefined, true)
        );
      }
    });
    const newFilters = this.context.filters.filter((filter: Filter) => !filters.find(filter2 => filter2.field.getElasticPath() === filter.field.getElasticPath())).concat(filters);

    this.context.broadcastFiltersChange(newFilters);
    this.context.refreshVisuals();
  };

  disablePreviousDates = (date: dayjs.Dayjs | null) => {
    if (!this.initialDateData.length || !date || this.initialDateData[0] === undefined) {
      return false;
    }
    return date.isBefore(this.initialDateData[0]);
  };

  getSchema() {
    return schema;
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as DateSlicerConfig;
  }

  render() {
    if (!this.state.range?.length || !this.initialDateData.length) {
      return null;
    }

    const dayjsStartDate = this.state.range[0] ? dayjs(this.state.range[0]) : null;
    const dayjsEndDate = dayjs(this.state.range[1]);

    return (
      <FilterButton label={'Dates'} values={[!dayjsStartDate ? '' : dayjsStartDate.format(this.format), dayjsEndDate.format(this.format)]} childrenInButton={true}>
        <DatePicker.RangePicker
          variant="borderless"
          className="date-slicer"
          allowClear={true}
          format={this.format}
          disabledDate={this.disablePreviousDates}
          onChange={this.handleDateChange}
          separator="-"
          allowEmpty={[true, false]}
          value={[dayjsStartDate, dayjsEndDate]}
        />
      </FilterButton>
    );
  }
}

export default DateSlicer;
