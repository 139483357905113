import { Dropdown } from 'antd';
import { Text } from '@/common/components/Typography/Text';
import { useRecoilValue } from 'recoil';
import { holdingSetsMap } from '@/recoil/holdingSets';
import { EntityParametersHandler } from '@/common/types/app/EntityParametersHandler';
import { LookThroughTypes } from '@/config/lookThroughTypes';
import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useMemo } from 'react';
import { currentSiteTreeExperienceEntriesSelector } from '@/modules/App/recoil/app.atoms';
import { PageTemplate } from '@/utils/retrievePageTemplate';
import { ConsolidationType } from '@/modules/reporting-v2/types/ReportingService';

export const getSingleAssetLink = (data, siteTree) => {
  let assetView;
  const queue = [...siteTree];

  while (queue.length) {
    const entry = queue.pop();

    if (
      window.location.pathname.split('/')[1] === entry.nodes?.[0] &&
      entry.view === 'SINGLE_REPORT' &&
      entry.title.toLowerCase().includes('single') &&
      entry.title.toLowerCase().includes('asset')
    ) {
      assetView = entry;
      break;
    } else {
      if (entry.children) {
        queue.push(...entry.children);
      }
    }
  }

  if (assetView) {
    const hsetId = data[Object.keys(data).find(key => key.endsWith('holdingSetId'))];

    const assetId = data['asset_info_undated.assets.assetId'];

    const searchParams = new URLSearchParams({
      assetIds: String(assetId),
      p: String(hsetId)
    });

    return assetView.path + '?' + searchParams.toString();
  }
};

export default ({ dataDogActionsPrefix, trackDatadogActions = false, children, data, ...props }) => {
  const experienceEntries = useRecoilValue(currentSiteTreeExperienceEntriesSelector);
  const hsetsMap = useRecoilValue(holdingSetsMap);

  const traverseTree = useCallback((tree = [], callback) => {
    const queue = [...tree];

    let foundEntry = undefined;

    while (queue.length) {
      const entry = queue.pop();

      if (callback(entry)) {
        foundEntry = entry;
        break;
      }

      if (entry.children?.length > 0) {
        queue.push(...entry.children);
      }
    }

    return foundEntry;
  }, []);

  const dynamicLinksItems = useMemo(() => {
    const supportedTypes = { VEHICLE: 'vehicle', GROUPING: 'grouping', PORTFOLIO: 'portfolio', ASSET: 'asset' };

    const pages = [];

    switch (data?.type) {
      case supportedTypes.ASSET:
        const singleAssetViewPage = traverseTree(experienceEntries, entry => {
          const path = entry.path.toLowerCase();

          return entry.view === PageTemplate.SINGLE_REPORT && path.includes('single') && path.includes('asset') && path.includes('view');
        });

        const assetDocumentsPage = traverseTree(experienceEntries, entry => {
          return entry.view === PageTemplate.DOCUMENT_MANAGEMENT_SYSTEM;
        });

        if (singleAssetViewPage) {
          pages.push({
            ...singleAssetViewPage,
            linkPageTitle: 'Single Asset View',
            pageParams: {
              assetIds: data.assetId,
              p: data.holdingSetId
            }
          });
        }

        if (assetDocumentsPage) {
          pages.push({ ...assetDocumentsPage, linkPageTitle: 'Asset Documents', pageParams: { tt: 'ASSET', tn: 'Asset', v: 'By Asset', tvv: data.assetId, fi: false } });
        }

        break;
      case supportedTypes.GROUPING:
        const groupingReportCentrePage = traverseTree(experienceEntries, entry => {
          return entry.view === PageTemplate.REPORTS_CENTRE_V3;
        });

        const groupingDocumentsPage = traverseTree(experienceEntries, entry => {
          return entry.view === PageTemplate.DOCUMENT_MANAGEMENT_SYSTEM;
        });

        const groupingTasksPage = traverseTree(experienceEntries, entry => {
          return entry.view === PageTemplate.TASK_MANAGEMENT_SYSTEM;
        });

        const groupingOwnershipPage = traverseTree(experienceEntries, entry => {
          return entry.view === PageTemplate.OWNERSHIP;
        });

        if (groupingDocumentsPage) {
          pages.push({
            ...groupingDocumentsPage,
            linkPageTitle: 'Documents',
            pageParams: {
              tt: 'HOLDING_SET',
              tn: 'Entity',
              v: 'By Entity',
              tvv: data.holdingSetId,
              fi: false
            }
          });
        }

        if (groupingReportCentrePage) {
          pages.push({ ...groupingReportCentrePage, linkPageTitle: 'Report Centre', pageParams: {} });
        }

        if (groupingTasksPage) {
          pages.push({ ...groupingTasksPage, linkPageTitle: 'Tasks', pageParams: {} });
        }

        if (groupingOwnershipPage) {
          pages.push({ ...groupingOwnershipPage, linkPageTitle: 'Ownership', pageParams: { holdingSetId: data.holdingSetId } });
        }

        break;
      case supportedTypes.PORTFOLIO:
        const portfolioPositionsPnlPage = traverseTree(experienceEntries, entry => {
          const path = entry.path.toLowerCase();
          return entry.view === PageTemplate.SINGLE_REPORT && path.includes('position') && path.includes('pnl');
        });

        const portfolioHistoricalFlowsPage = traverseTree(experienceEntries, entry => {
          const path = entry.path.toLowerCase();
          return entry.view === PageTemplate.SINGLE_REPORT && path.includes('historical') && path.includes('flow');
        });

        const portfolioDocumentsPage = traverseTree(experienceEntries, entry => {
          return entry.view === PageTemplate.DOCUMENT_MANAGEMENT_SYSTEM;
        });

        if (portfolioPositionsPnlPage) {
          pages.push({
            ...portfolioPositionsPnlPage,
            linkPageTitle: 'Positions',
            pageParams: {
              p: data.holdingSetId,
              date: data.date
            }
          });
        }

        if (portfolioHistoricalFlowsPage) {
          pages.push({
            ...portfolioHistoricalFlowsPage,
            linkPageTitle: 'Historical Flows',
            pageParams: {
              p: data.holdingSetId,
              date: data.date
            }
          });
        }

        if (portfolioDocumentsPage) {
          pages.push({
            ...portfolioDocumentsPage,
            linkPageTitle: 'Documents',
            pageParams: {
              tt: 'HOLDING_SET',
              tn: 'Entity',
              v: 'By Entity',
              tvv: data.holdingSetId,
              fi: false
            }
          });
        }

        break;
      case supportedTypes.VEHICLE:
        const vehicleExposureOverviewPage = traverseTree(experienceEntries, entry => {
          const path = entry.path.toLowerCase();

          return entry.view === PageTemplate.SINGLE_REPORT && path.includes('exposure') && path.includes('overview');
        });

        const vehiclePerformanceOverviewPage = traverseTree(experienceEntries, entry => {
          const path = entry.path.toLowerCase();

          return entry.view === PageTemplate.SINGLE_REPORT && path.includes('performance') && path.includes('overview');
        });

        const vehicleLimitMonitorPage = traverseTree(experienceEntries, entry => {
          const path = entry.path.toLowerCase();

          return entry.view === PageTemplate.SINGLE_REPORT && path.includes('monitor');
        });

        const vehicleHistoricalFlowPage = traverseTree(experienceEntries, entry => {
          const path = entry.path.toLowerCase();

          return entry.view === PageTemplate.SINGLE_REPORT && path.includes('historical') && path.includes('flow');
        });

        const vehicleDocumentsPage = traverseTree(experienceEntries, entry => {
          return entry.view === PageTemplate.DOCUMENT_MANAGEMENT_SYSTEM;
        });

        if (vehicleExposureOverviewPage) {
          pages.push({
            ...vehicleExposureOverviewPage,
            linkPageTitle: 'Exposure Overview',
            pageParams: {
              p: data.holdingSetId,
              date: data.date
            }
          });
        }

        if (vehiclePerformanceOverviewPage) {
          pages.push({
            ...vehiclePerformanceOverviewPage,
            linkPageTitle: 'Performance Overview',
            pageParams: {
              p: data.holdingSetId,
              date: data.date
            }
          });
        }

        if (vehicleLimitMonitorPage) {
          pages.push({
            ...vehicleLimitMonitorPage,
            pageTitle: 'Compliance',
            pageParams: {
              p: data.holdingSetId,
              date: data.date
            }
          });
        }

        if (vehicleHistoricalFlowPage) {
          pages.push({
            ...vehicleHistoricalFlowPage,
            pageTitle: 'Historical Flows',
            pageParams: {
              p: data.holdingSetId,
              date: data.date
            }
          });
        }

        if (vehicleDocumentsPage) {
          pages.push({
            ...vehicleDocumentsPage,
            pageTitle: 'Documents',
            pageParams: {
              tt: 'HOLDING_SET',
              tn: 'Entity',
              v: 'By Entity',
              tvv: data.holdingSetId,
              fi: false
            }
          });
        }
        break;
      default:
        break;
    }

    return pages;
  }, [experienceEntries, traverseTree, data]);

  const menu = useMemo(() => {
    return dynamicLinksItems.map(item => {
      return {
        key: item.id,
        label: (
          <Text
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (trackDatadogActions) {
                const action = dataDogActionsPrefix ? `${dataDogActionsPrefix}${item.title}` : item.title;
                datadogRum.addAction(action);
              }

              const hsetParam = item.pageParams.p ?? item.pageParams.holdingSetId;

              let appendLookThrough = false;

              if (hsetParam) {
                const hset = hsetsMap.get(hsetParam);

                appendLookThrough = hset?.lookThroughEnabled;
              }

              let url = item.path;

              const query = new URLSearchParams();

              Object.entries(item.pageParams).forEach(([key, value]) => {
                if (value) {
                  if (appendLookThrough) {
                    if (key === 'p') {
                      query.set(key, `${value}${EntityParametersHandler.serializeLookThrough(LookThroughTypes.Lookthrough)}`);
                      return;
                    } else if (key === 'holdingSetId') {
                      query.set(key, value);
                      query.set('consolidationType', ConsolidationType.LOOK_THROUGH);
                      return;
                    }
                  }

                  query.set(key, value);
                }
              });

              if (query) {
                url += `?${query.toString()}`;
              }

              window.open(url);
            }}
          >
            {item.linkPageTitle ?? item.title}
          </Text>
        )
      };
    });
  }, [dynamicLinksItems, trackDatadogActions, hsetsMap, dataDogActionsPrefix]);

  return (
    <Dropdown {...props} menu={{ items: menu }}>
      {children}
    </Dropdown>
  );
};
