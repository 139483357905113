import React from 'react';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import queryString from 'query-string';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { FilterButton } from '@/modules/reporting-v2/core/components';
import { Field } from '@/modules/reporting-v2/core/Field';
import GroupByColumn from '@/modules/reporting-v2/core/GroupByColumn';
import { VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import SelectList from '@/common/components/SelectList';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import { Filter } from '@/modules/reporting-v2/core/Filter';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import reportQueryStringParams from '@/modules/reporting-v2/config/reportQueryStringParams';
import { ParametersHandler } from '@/common/types/app/ParametersHandler';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import RawSelectBoxConfig, { SelectBoxConfigMapped } from './types';
import { config } from './config';
import schema from './schema.json';
import SelectBoxConfig from './SelectBoxConfig';
import { FilterOperator } from '@/types/Filters';
import type { IVisualCoreState } from '@/modules/reporting-v2/types/VisualCoreTypes';

type Options = Record<string, Record<string, string | number>> | Record<string, string>;

class SelectBox extends Visual {
  private group: string | undefined;
  private values: string[] | undefined; // used for assetIds single asset page

  renderInteractivity() {
    return [];
  }

  getSchema() {
    return schema;
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as SelectBoxConfig;
  }

  getVisual() {
    return this.props.visual as SelectBoxConfig;
  }

  renderTitle() {
    return null;
  }

  canSelectBoxPushParams() {
    if (this.getVisual().pushQueryParams) {
      return true;
    }

    return Boolean(['single-asset-view', 'stress-test-and-crisis-scenario'].find(name => window.location.pathname.includes(name)));
  }

  useOldCustomOptions() {
    const visual = this.getVisual();
    return visual.options && Object.keys(visual.options).length;
  }

  useNewCustomOptions() {
    const visual = this.getVisual();
    return visual.customOptions && Object.keys(visual.customOptions).length;
  }

  static configMapper(visualConfig: RawSelectBoxConfig): SelectBoxConfigMapped {
    const updatedConfig = {
      ...visualConfig,
      dataFilter: [],
      additionalFieldsToFilter: [],
      columns: [],
      groupable: visualConfig._groupable
    } as SelectBoxConfigMapped;

    if (visualConfig._additionalFilters) {
      updatedConfig.additionalFieldsToFilter.push(...(visualConfig._additionalFilters?.defaultColumns || []));
    }

    if (visualConfig.dataFilter) {
      updatedConfig.dataFilter = mapVisualConfigColumns(visualConfig.dataFilter);
    }

    if (visualConfig.measureToFilterOn) {
      updatedConfig.measureToFilterOn = mapVisualConfigColumns(visualConfig.measureToFilterOn);
    }

    if (visualConfig._customOptions && Object.keys(visualConfig._customOptions).length) {
      const fieldsSet: Set<string> = new Set();
      Object.entries(visualConfig._customOptions).forEach(([_, fields]) => {
        Object.entries(fields).forEach(([fieldName, _]) => {
          fieldsSet.add(fieldName);
        });
      });

      return {
        ...updatedConfig,
        columns: [...fieldsSet].map(col => ({ field: col, default: true }))
      };
    } else if (visualConfig._list) {
      const field = new Field(visualConfig._list);
      const columns = [{ field: visualConfig._list, default: true }];

      reportQueryStringParams.forEach(queryStringParam => {
        if (queryStringParam.field in field.getIndexNode().getForeignKeys()) {
          columns.push({
            field: `${field.getIndexNode().node}.${queryStringParam.field}`,
            default: false
          });
        }
      });

      return {
        ...updatedConfig,
        columns
      };
    } else if (visualConfig._groupable) {
      const fields = Object.keys(visualConfig._options ?? {});
      return {
        ...updatedConfig,
        groupable: true,
        columns: fields.length
          ? fields.map(field => ({ field, default: true }))
          : [
              {
                field: visualConfig._list,
                default: true
              }
            ]
      };
    }

    return updatedConfig;
  }

  displayErrors() {
    const visual = this.getVisual();

    if (visual.groupable && visual.field) {
      message.error('[SelectBox] Option groupable can only be used with custom option');
    }

    if (!visual.multiple && visual.defaultValues?.length > 1) {
      message.error("[SelectBox] Multiple default values are defined but the visual doesn't allow it");
    }
  }

  filterRowsWithDataFilter = () => {
    const dataFilters = this.props.visual.dataFilter;
    return this.props.visual.data.rows.filter(row => dataFilters?.every(df => df.conditionalValueDisplay.every(filter => filter.evaluate(row.data))));
  };

  getAdditionalFieldsToFilter = () => {
    const visual = this.getVisual();
    return visual.additionalFieldsToFilter?.length ? visual.additionalFieldsToFilter.concat(this.getMeasureToFilterOn()) : undefined;
  };

  getFilterOperator = () => {
    if (this.getVisual().allowUndefinedValues) {
      return FilterOperator.EQUALS_OR_UNDEFINED;
    }

    return FilterOperator.EQUALS;
  };

  changeFilters = (filters: { field: Field; value: Primitive | Primitive[] }[]) => {
    const visual = this.getVisual();
    const additionalFieldsToFilter = this.getAdditionalFieldsToFilter();

    const filterOperator = this.getFilterOperator();

    visual.multiple
      ? this.context.addFilter(filters, visual.id, undefined, additionalFieldsToFilter, filterOperator)
      : this.context.replaceFilter(filters, visual.id, additionalFieldsToFilter, filterOperator);
  };

  removeGroupByFilter = (visual: VisualEngine) => {
    const existingSelectBoxGroupBy = visual.groupByColumns.findIndex(col => col.selectBoxGroupById === this.props.visual.id);

    visual.groupByColumns.splice(existingSelectBoxGroupBy, 1);

    for (const col of visual.groupByColumns) {
      if (col.wasDefault) {
        col.wasDefault = false;
        col.isDefault = true;
      }
    }

    this.group = undefined;
  };

  setGroupByFilter = (_value: string, visual: VisualEngine) => {
    let value = _value;
    if (this.useNewCustomOptions()) value = Object.keys(JSON.parse(value))[0];

    const field = new Field(value);
    const newGroupColumn = new GroupByColumn(field.getFieldDataPath(), field, true, undefined, this.props.visual.id);

    this.group = value;

    const existingSelectBoxGroupBy = visual.groupByColumns.findIndex(col => col.selectBoxGroupById === this.props.visual.id);
    if (existingSelectBoxGroupBy !== -1) {
      visual.groupByColumns.splice(existingSelectBoxGroupBy, 1, newGroupColumn);
    } else {
      const isHistoricalChart = visual.component === VisualComponent.HistoricalChart;
      for (const col of isHistoricalChart ? visual.groupByColumns.slice(1) : visual.groupByColumns) {
        if (col.isDefault) {
          col.wasDefault = true;
          col.isDefault = false;
        }
      }

      if (isHistoricalChart) {
        visual.groupByColumns.splice(1, 0, newGroupColumn);
      } else {
        visual.groupByColumns.unshift(newGroupColumn);
      }
    }
  };

  handleGroupByChange = (value?: string) => {
    for (const visual of this.context.visuals.values()) {
      if (!visual.crossFilterReceiver) continue;

      if (value) {
        this.setGroupByFilter(value, visual);
      } else {
        this.removeGroupByFilter(visual);
      }

      visual.updateData(this.context.filters, this.context.globalFilters);
      this.context.refreshVisuals(visual.id);
    }

    this.context.refreshVisuals(this.props.visual.id);
  };

  handleChange = (value?: string[]) => {
    const visual = this.getVisual();

    if (visual.groupable) {
      return this.handleGroupByChange(value?.[0]);
    }

    if (!value?.length) {
      this.context.broadcastFiltersChange(this.context.filters.filter((filter: Filter) => filter.selectBoxId !== visual.id));
      this.context.refreshVisuals(this.props.visual.id);
      return;
    }

    if (this.useNewCustomOptions()) {
      let filters = [] as { field: Field; value: Primitive | Primitive[] }[];
      if (visual.multiple) {
        value.forEach(val => {
          const newFilters = Object.entries(JSON.parse(val)).map(([fieldKey, value]) => {
            return {
              field: new Field(fieldKey),
              value: value as Primitive | Primitive[]
            };
          });
          filters = [...filters, ...newFilters];
        });
      } else {
        filters = Object.entries(JSON.parse(value[0])).map(([fieldKey, value]) => {
          return {
            field: new Field(fieldKey),
            value: value as Primitive | Primitive[]
          };
        });
      }

      this.changeFilters(filters);
    } else {
      this.changeFilters([{ field: new Field(this.getMeasureToFilterOn()), value }]);

      if (this.canSelectBoxPushParams()) {
        const field = new Field(visual.field);
        const newParams: Record<string, string> = {};

        reportQueryStringParams.forEach(queryStringParam => {
          const paramPath = field.getIndexNode().node + '.' + queryStringParam.field;
          const row = visual.data.rows.find(row => value?.includes(row.cells[0] as string));

          if (row?.data[paramPath]) {
            newParams[queryStringParam.param] = row.data[paramPath]!.toString();
          }
        });

        if (Object.keys(newParams).length) {
          const qs = queryString.parse(window.location.search.substring(1));
          this.props.history.push({
            search: queryString.stringify({ ...qs, ...newParams })
          });
          this.context.setParams({
            ...this.context.params,
            ...ParametersHandler.retrieveParams(newParams)
          });
        }
      }
    }

    this.context.refreshVisuals(this.props.visual.id);
  };

  getMeasureToFilterOn(): string | undefined {
    const visual = this.getVisual();
    return visual.measureToFilterOn?.[0]?.code ?? visual.field;
  }

  onPreLoad() {
    this.displayErrors();

    const visual = this.getVisual();
    const measureToFilterOn = this.getMeasureToFilterOn();
    const field = measureToFilterOn ? new Field(measureToFilterOn) : undefined;

    if (visual.defaultValues?.length) {
      if (visual.groupable) {
        this.handleGroupByChange(visual.defaultValues[0]);
      } else if (this.useNewCustomOptions()) {
        const options = this.getOptions();

        const values = visual.defaultValues.filter(defaultValue => options[defaultValue]).map(defaultValue => JSON.stringify(options[defaultValue]));
        this.handleChange(values);
      } else if (field?.name) {
        this.context.addFilter(
          [
            {
              field,
              value: visual.defaultValues
            }
          ],
          visual.id,
          true,
          this.getAdditionalFieldsToFilter(),
          this.getFilterOperator()
        );
        this.context.refreshVisuals(visual.id);
      }
    }

    if (!field?.name) {
      return;
    }

    this.updateFilterFromQueryParams();
  }

  updateFilterFromQueryParams = (params = this.context.params) => {
    const visual = this.getVisual();
    const measureToFilterOn = this.getMeasureToFilterOn();
    if (!measureToFilterOn) return;
    const field = new Field(measureToFilterOn);

    reportQueryStringParams.forEach(queryStringParam => {
      const paramPath = field.getIndexNode().node + '.' + queryStringParam.field;
      const value = params?.[queryStringParam.param]?.[0];

      let row = null;

      if (value) {
        row = visual.data.rows.find(row => row.data[paramPath] === value);
      } else if (this.canSelectBoxPushParams()) {
        // todo: cleanup option selectbox select first option by default
        row = visual.data.rows[0];
        if (row && !row.data[paramPath]) {
          row = null;
        }
      }

      if (row) {
        setTimeout(() => {
          this.context.replaceFilter(
            [
              {
                field: new Field(measureToFilterOn),
                value: row!.data[field.getElasticPath()]
              }
            ],
            visual.id,
            undefined,
            this.getFilterOperator()
          );
          this.context.refreshVisuals(visual.id);
        }, 0);
      }
    });
  };

  getValues = (options: Options): string[] => {
    if (this.group) return [this.group];

    const visual = this.getVisual();

    if (!this.useNewCustomOptions()) {
      return (this.context.filters.find((filter: Filter) => filter.selectBoxId === visual.id)?.condition.values ?? []) as string[];
    }

    const filter = this.context.filters.find((filter: Filter) => filter.selectBoxId === visual.id);
    if (!filter) return [];

    const filterField = filter.field.name;
    const filterValues = filter.condition.values;
    const values = [];
    for (const [label, fields] of Object.entries(options)) {
      if (fields[filterField] && filterValues.includes(fields[filterField])) {
        values.push(label);
      }
    }

    return values;
  };

  getDataOptions = (): Record<string, string> => {
    let rows = this.props.visual.data.rows;

    if (this.props.visual.dataFilter) rows = this.filterRowsWithDataFilter();

    return Object.fromEntries([...new Set(rows.map(row => row.cells[0]))].map(value => [value, value]));
  };

  getItems = (options: Options) => {
    if (this.useOldCustomOptions()) {
      return Object.entries(options).map(([value, displayText]) => ({
        label: displayText,
        value: value
      }));
    }

    if (this.useNewCustomOptions()) {
      return Object.entries(options).map(([displayText, value]) => ({
        label: displayText,
        value: JSON.stringify(value)
      }));
    }

    return Object.entries(options).map(([value, displayText]) => ({
      label: displayText,
      value: value
    }));
  };

  getOptions = (): Options => {
    const visual = this.getVisual();

    if (this.useOldCustomOptions()) return visual.options;
    if (this.useNewCustomOptions()) return visual.customOptions;

    return this.getDataOptions();
  };

  componentDidUpdate(previousProps: typeof this.props, prevState: IVisualCoreState): void {
    if (this.props.location.search !== previousProps.location.search) {
      const newParams: Record<string, string> = {};
      [...new URLSearchParams(this.props.location.search).entries()].forEach(([key, value]) => {
        newParams[key] = value;
      });
      const newContextParams = {
        ...this.context.params,
        ...ParametersHandler.retrieveParams(newParams)
      };
      this.context.setParams(newContextParams);
      this.updateFilterFromQueryParams(newContextParams);
    }
  }

  renderBody() {
    const visual = this.getVisual();
    const schema = this.getSchema() as any;

    const options = this.getOptions();
    const items = this.getItems(options);
    const values = this.getValues(options);

    let selectListValue = values;
    let displayValues = values;

    if (this.useNewCustomOptions()) {
      selectListValue = items.filter(item => values.includes(item.label)).map(item => item.value);
    }
    if (this.useOldCustomOptions()) {
      displayValues = items.filter(item => values.includes(item.value)).map(item => item.label);
    }

    return (
      <FilterButton label={visual.label || schema.fields['group_fields-data'].fields._list.options[visual.field] || ''} values={displayValues} readonly={visual.readonly}>
        <SelectList
          disableEmptyValue={visual.disableEmptyValue}
          value={selectListValue}
          multiple={visual.multiple && !visual.groupable}
          placeholder={visual.placeholder || 'Search'}
          items={items}
          onChange={this.handleChange}
        />
      </FilterButton>
    );
  }
}

export default withRouter(SelectBox as React.ComponentType<any>);
