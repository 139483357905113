import { useCallback } from 'react';
import useGlobalListener from './useGlobalListener';

export default function (onEscape: () => void) {
  const listener = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onEscape();
      }
    },
    [onEscape]
  ) as EventListener;
  useGlobalListener('keydown', listener);
}
