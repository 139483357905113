import * as styles from './styles';

export default (data: Record<string, unknown>): string => `<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0">  
  ${styles.common(data)}
  ${styles.main()}
  ${data.withQuillStyleSheet ? styles.quill : ''}
  <style>${data.customStyle}</style>
</head>

<body>
  ${data.content}
</body>
</html>`;
