import { VisualConfigProps } from '@/modules/reporting-v2/types/VisualEngine';

export default interface ExtendedDateSlicerConfig extends VisualConfigProps {
  startDateField?: string;
  endDateField?: string;
  fromDate?: string;
  toDate?: string;
  dateRange?: DateRange;
}

export enum DateRange {
  LAST_3_YEARS = 'Last 3 years',
  LAST_YEAR = 'Last 12 months',
  LAST_3_MONTHS = 'Last 3 months',
  LAST_MONTH = 'Last month',
  FUTURE_DATES = 'FUTURE_DATES'
}
