import { Text } from '@/common/components/Typography/Text';
import React from 'react';
import { useIntl } from 'react-intl';
import retrievePageTemplate, { PageTemplate } from '@/utils/retrievePageTemplate';

type ActivityCardTypeTextProps = {
  activityType: string;
};

const ActivityCardTypeText: React.FC<ActivityCardTypeTextProps> = ({ activityType }) => {
  const translator = useIntl();
  const createdCommentState = activityType === 'CREATE_COMMENT_TASK'

  let actionText: string;
  switch (activityType.split('_')[0].toLowerCase()) {
    case 'create':
      if (createdCommentState) {
        actionText = translator.formatMessage({ id: 'generic.commentedOnThisTask' });
      } else {
        actionText = translator.formatMessage({ id: 'generic.created' });
      }
      break;
    case 'delete':
      actionText = translator.formatMessage({ id: 'generic.deleted' });
      break;
    case 'reminder':
      actionText = translator.formatMessage({ id: 'erp.activity.reminderHasBeenSentFor' });
      break;
    case 'update':
    default:
      actionText = translator.formatMessage({ id: 'generic.updated' });
      break;
  }

  const pageTemplate = retrievePageTemplate();

  let type = activityType.split('_')[1].toLowerCase();

  if (pageTemplate === PageTemplate.CONTACTS_LIST_COMPANIES && activityType.split('_')[1].toLowerCase() === 'contact') {
    type = 'company';
  }

  return (
    <Text>
      {activityType.split('_')[0].toLowerCase() === 'reminder' ? actionText : actionText.toLowerCase()}{' '}
      {!createdCommentState &&
        translator
          .formatMessage({
            id: 'generic.' + type
          })
          .toLowerCase()}{' '}
    </Text>
  );
};

export { ActivityCardTypeText };
