import { nanoid } from 'nanoid';
import type { RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import type { HoldingSet } from 'modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';
import type { HoldingSetLookup } from '@/common/types/entity/HoldingSetLookup';

const holdingSetIdPath = 'holdingset.holdingSetId';

export default class HoldingSetTreeMapper {
  private static generateEmptyRow(holdingSet: HoldingSet | HoldingSetLookup, level: number): RowGroup {
    return {
      level,
      __id__: nanoid(10),
      group: holdingSet.name,
      cells: [holdingSet.name],
      data: { [holdingSetIdPath]: holdingSet.id },
      rows: []
    };
  }

  /**
   * @description Generate empty rows for entities with no associated rows
   */
  private static getEmptyRows(rows: Array<RowGroup>, tree: HoldingSet[] | HoldingSetLookup[], level = 0) {
    const emptyRows: Array<RowGroup> = [];

    tree.forEach(holdingSet => {
      const row = rows.find(row => row.data[holdingSetIdPath] === holdingSet.id);
      if (!row) {
        emptyRows.push(this.generateEmptyRow(holdingSet, level));
      }
    });

    return emptyRows;
  }

  public static buildRowsFromTree(rows: Array<RowGroup>, tree: HoldingSet[] | HoldingSetLookup[], level = 0) {
    const rowTree: RowGroup[] = [];

    const emptyRows = this.getEmptyRows(rows, tree, level);
    if (emptyRows.length > 0) {
      rows.push(...emptyRows);
    }

    for (const row of rows) {
      const holdingSet = tree.find(hs => hs.id === row.data[holdingSetIdPath]);
      if (!holdingSet) {
        continue;
      }

      if (holdingSet.children) {
        row.rows = this.buildRowsFromTree(rows, holdingSet.children, level + 1);
      }

      row.level = level;
      rowTree.push(row);
    }

    return rowTree;
  }
}
