import { Cancelleable, Institution, InstitutionFilter, InstitutionType } from '@/modules/ERP/Common/types';
import { Pagination } from '@/common/types/api/Pagination';
import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { filterNullValues } from '@/utils/filterValues';

export const getCustodiansByIds = async (ids: number[], isInternalUser?: boolean): Promise<Institution[]> => {
  return filterNullValues(await Promise.all(filterNullValues(ids).map(id => getCustodianById(id, isInternalUser))));
};

export const getCustodianById = async (id: number, isInternalUser?: boolean): Promise<Institution> => {
  return searchInstitution({
    id,
    type: InstitutionType.CUSTODIAN,
    isInternalUser
  }).then(({ content }) => content[0]);
};

export const searchInstitution = (params: InstitutionFilter, options?: Cancelleable): Promise<Pagination<Institution>> => {
  const { isInternalUser, ...filter } = params;
  return requestApi({
    url: isInternalUser ? `/internal/api/companies` : `/api/companies`,
    service: 'client',
    params: filter,
    signal: options?.signal
  }).then((response: AxiosResponse<Pagination<Institution>> | undefined) => response!.data);
};
