export enum Index {
  allocation = 'allocation',
  asset_info_undated = 'asset_info_undated',
  flows = 'flows',
  flows_aggregated = 'flows_aggregated',
  hedges_fx = 'hedges_fx',
  holding_set_aum = 'holding_set_aum',
  holdingset = 'holdingset',
  holdingset_asset = 'holdingset_asset',
  limits = 'limits',
  liquidity = 'liquidity',
  metadata = 'metadata',
  performance = 'performance',
  performance_attribution = 'performance_attribution',
  pnl_asset = 'pnl_asset',
  pnl_fx_asset = 'pnl_fx_asset',
  pnl_trading_asset = 'pnl_trading_asset',
  pnl_holding_set = 'pnl_holding_set',
  risk_asset = 'risk_asset',
  risk_asset_by_asset = 'risk_asset_by_asset',
  risk_asset_by_measure = 'risk_asset_by_measure',
  risk_hs_by_measure = 'risk_hs_by_measure',
  risk_factor = 'risk_factor',
  risk_holding_set = 'risk_holding_set',
  turnover = 'turnover'
}
