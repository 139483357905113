import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';

export const castValue = (value: Primitive, type = 'string'): Primitive | undefined => {
  switch (type) {
    case 'limits':
    case 'string':
    case 'date':
    case undefined:
      return value;
    case 'amountNoUnit':
    case 'price':
      return parseFloat((value as string).replace(/[^0-9.-]+/g, ''));
    case 'percentage':
      return parseFloat(value as string) / 100;
    default:
      if (typeof value === 'string' && value.trimEnd().endsWith('%')) {
        return parseFloat(value) / 100;
      }

      if (typeof value === 'string' && Array.from(value.replace(/[^a-zA-Z]+/g, '')).length === 0) {
        return parseFloat(value.replace(/[^0-9.-]+/g, ''));
      }

      return value;
  }
};
