import { UserLookup } from './UserLookup';
import EntityRef from './EntityRef';
import { Limit } from '@/modules/reporting-v2/core/components/DataTable/components/Limits/LimitsTypes';
import { Priority, Reminders, Status } from '@/modules/ERP/Contacts/types';

export enum TaskType {
  GENERAL = 'GENERAL',
  DOCUMENT = 'DOCUMENT',
  LIMIT = 'LIMIT'
}

export interface TaskCreator {
  id: string;
  firstName: string;
  lastName: string;
  login: string;
}

export class GlobalFilters {
  constructor(
    public holdingSetId?: number,
    public date?: Date,
    public consolidationType?: string,
    public reportId?: number
  ) {}
}

export class ValueLocator {
  constructor(
    public row?: string,
    public column?: string
  ) {}
}

export class TaskContext {
  constructor(
    public value?: string,
    public valueLocator?: ValueLocator,
    public visualId?: string,
    public visualName?: string,
    public reportId?: number,
    public reportName?: string,
    public globalFilters?: GlobalFilters,
    public limitDetail?: Limit & {
      rule: string;
    }
  ) {}
}

export interface Task {
  title: string;
  description?: string;
  type: TaskType;
  status: Status;
  assignee?: EntityRef;
  creator?: TaskCreator;
  refUrl?: string;
  refName?: string;
  refGuid?: string;
  refId?: number;
  holdingSetId?: number;
  context?: TaskContext;
  createdDate: Date;
  createdBy?: string;
  lastModifiedDate: Date;
  lastModifiedBy?: string;
  dueDate?: string;
  reminder?: Reminders;
  priority?: Priority;
}

export interface TaskLookup extends EntityRef {
  title: string;
  type: TaskType;
  status: Status;
  refName?: string;
  refGuid?: string;
  refId?: number;
  assignee?: UserLookup;
  creator?: TaskCreator;
  holdingSetId?: number;
  createdDate: Date;
  createdBy: string;
  lastModifiedDate: Date;
  lastModifiedBy: string;
  dueDate?: string;
}

export class TransientTask implements Task {
  constructor(
    public title: string = '',
    public type: TaskType = TaskType.GENERAL,
    public refUrl?: string,
    public refName?: string,
    public refGuid?: string,
    public refId?: number,
    public description: string = '',
    public status: Status = Status.TO_DO,
    public assignee?: EntityRef,
    public creator?: TaskCreator,
    public holdingSetId?: number,
    public context?: TaskContext,
    public createdDate: Date = new Date(),
    public createdBy?: string,
    public lastModifiedDate: Date = new Date(),
    public lastModifiedBy?: string,
    public dueDate?: string,
    public reminder?: Reminders,
    public priority?: Priority
  ) {}
}

export class PersistentTask implements Task, EntityRef {
  constructor(
    public id: number,
    public title: string,
    public status: Status,
    public type: TaskType,
    public createdDate: Date,
    public createdBy: string,
    public lastModifiedDate: Date,
    public lastModifiedBy: string,
    public description?: string,
    public assignee?: UserLookup,
    public creator?: TaskCreator,
    public refUrl?: string,
    public refName?: string,
    public refGuid?: string,
    public refId?: number,
    public dueDate?: string,
    public reminder?: Reminders,
    public priority?: Priority,
    public holdingSetId?: number,
    public context?: TaskContext
  ) {}

  public static fromTask(task: Task & EntityRef) {
    return new PersistentTask(
      task.id,
      task.title,
      task.status,
      task.type,
      task.createdDate,
      task.createdBy!,
      task.lastModifiedDate,
      task.lastModifiedBy!,
      task.description,
      task.assignee as UserLookup,
      task.creator as TaskCreator,
      task.refUrl,
      task.refName,
      task.refGuid,
      task.refId,
      task.dueDate,
      task.reminder,
      task.priority,
      task.holdingSetId,
      task.context
    );
  }
}
