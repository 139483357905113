import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { ContentType } from '@/common/types/entity/ContentType';
import { CustomReport, CustomReportRef } from '../../../common/types/entity/CustomReport';

export const customizeTemplate = (id: string, data: CustomizeTemplateRequest): Promise<CustomReport & CustomReportRef> => {
  return requestApi({
    service: 'cms',
    method: 'post',
    url: `/api/custom-reports/${id}/customise`,
    data
  }).then((response: AxiosResponse<CustomReport & CustomReportRef>) => response?.data);
};
export class CustomizeTemplateRequest {
  constructor(public name: string, public contentType?: ContentType, public configuration?: string, public spaceId?: string) {}
}
