import React, { SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { withSmallSuspense } from '@/common/suspense';
import { useRecoilValue } from 'recoil';
import { linkableObjectsSelector } from '@/modules/ERP/Common/Recoil/links.selector';
import { FormattedMessage, useIntl } from 'react-intl';
import { filterObjectByName } from '@/modules/ERP/Common/utils';
import { EntityLinkType, EntityLinkTypeMenu, LinkableObject, LinkableObjectList } from '@/common/types/entity/Link';
import { Empty, Menu, Spin, Typography } from 'antd';
import { Text } from '@/common/components/Typography/Text';
import { LinkObjectName } from '@/modules/ERP/Common/EntityLinks';
import { LoadMore } from '@/common/components/SearchOverlay';
import { SearchInput } from 'ui-sesame-components';

interface SelectListContentProps {
  defaultSelectedType?: EntityLinkType;
  setSelectedLinks: React.Dispatch<SetStateAction<Array<LinkableObject>>>;
  selectedLinks: Array<LinkableObject>;
}

export const SelectLinksContent: React.FunctionComponent<SelectListContentProps> = withSmallSuspense(
  React.memo(({ defaultSelectedType, selectedLinks, setSelectedLinks }) => {
    const defaultType = (defaultSelectedType as unknown as EntityLinkTypeMenu) ?? EntityLinkType.ASSET;

    const [selectedEntityType, setSelectedEntityType] = useState<EntityLinkTypeMenu>(defaultType);

    const _setSelectedEntityType = useCallback(
      (entityType: EntityLinkTypeMenu) => {
        return () => {
          setSelectedEntityType(entityType);
        };
      },
      [setSelectedEntityType]
    );

    const stopPropagation = useCallback((e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
    }, []);

    const menuItems = useMemo(() => {
      return Object.values(EntityLinkTypeMenu)
        .filter(value => value !== EntityLinkTypeMenu.OWNER)
        .map(type => {
          let keyTranslated = type.toLowerCase();

          const linkOfType = selectedLinks.filter(link => {
            if (link._entityType === EntityLinkType.HOLDING_SET) {
              if (link.type === EntityLinkTypeMenu.PORTFOLIO) {
                return (link.type as unknown as EntityLinkTypeMenu) === type;
              }
              return (link.holdingSetType as unknown as EntityLinkTypeMenu) === type;
            }
            return (link._entityType as unknown as EntityLinkTypeMenu) === type;
          });

          if (type === EntityLinkTypeMenu.CONTACT_PERSON) {
            keyTranslated = 'contact';
          }
          if (type === EntityLinkTypeMenu.CONTACT_COMPANY) {
            keyTranslated = 'companie';
          }
          if (type === EntityLinkTypeMenu.VEHICLE) {
            keyTranslated = 'legalEntitie';
          }

          return {
            key: type,
            label: (
              <div className="entity-links-category-count-container">
                <Typography.Text type="secondary">
                  <FormattedMessage id={'generic.' + keyTranslated + 's'} />
                </Typography.Text>
                <Typography.Text strong type="secondary">
                  {linkOfType.length}
                </Typography.Text>
              </div>
            ),
            onClick: _setSelectedEntityType(type)
          };
        });
    }, [_setSelectedEntityType, selectedLinks]);

    const defaultSelectedKeys = useMemo(() => {
      return [defaultType];
    }, [defaultType]);

    return (
      <div onClick={stopPropagation} className="entity-links-management-popover-container">
        <Menu defaultSelectedKeys={defaultSelectedKeys} items={menuItems} className="entity-linkable-objects-entity-list" />
        <SelectListLink setSelectedLinks={setSelectedLinks} selectedLinks={selectedLinks} selectedType={selectedEntityType} />
      </div>
    );
  })
);

interface SelectLinksProps {
  selectedType: EntityLinkTypeMenu;
  setSelectedLinks: React.Dispatch<SetStateAction<Array<LinkableObject>>>;
  selectedLinks: Array<LinkableObject>;
}

const SelectListLink: React.FunctionComponent<SelectLinksProps> = withSmallSuspense(
  React.memo(({ selectedType, setSelectedLinks, selectedLinks }) => {
    const linkableObjects = useRecoilValue(linkableObjectsSelector({ type: selectedType }));

    const translator = useIntl();
    const [slice, setSlice] = useState(50);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchValue, setSearchValue] = useState<string>();

    const timeout = useRef<NodeJS.Timeout>();

    const onClick = useCallback(
      (object: LinkableObject) => {
        setSelectedLinks(prevObjects => {
          const originalLength = prevObjects.length;

          const newObjects = prevObjects.filter(obj => {
            const isSameObject = object.id === obj.id && object._entityType === obj._entityType;

            return !isSameObject;
          });

          if (originalLength !== newObjects.length) {
            return newObjects;
          }

          return newObjects.concat(object);
        });
      },
      [setSelectedLinks]
    );

    const onSearchChange = useCallback(
      (ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearchLoading(true);

        if (timeout.current) {
          clearTimeout(timeout.current);
        }

        timeout.current = setTimeout(() => {
          setSearchValue(ev.target.value);
          setSearchLoading(false);
        }, 500);
      },
      [setSearchValue]
    );

    const slicedData = useMemo(() => {
      return linkableObjects.slice(0, slice);
    }, [slice, linkableObjects]);

    const filteredObjects = useMemo<LinkableObjectList>(() => {
      if (!searchValue) {
        return slicedData;
      }

      return linkableObjects.filter(object => filterObjectByName(object, searchValue));
    }, [searchValue, linkableObjects, slice]);

    const showLoadMore = !searchValue && slice < linkableObjects.length;

    return (
      <div className="linkable-objects-list-container-wrapper">
        <div className="linkable-objects-list-container">
          <SearchInput
            value={searchValue}
            onChange={onSearchChange}
            className="linkable-object-list-search"
            placeholder={translator.formatMessage({ id: 'generic.search' })}
            size="small"
          />
          <Spin spinning={searchLoading}>
            {filteredObjects.length <= 0 ? (
              <Empty
                description={
                  <Text type="secondary">
                    <FormattedMessage id={'generic.noData'} />
                  </Text>
                }
              />
            ) : (
              filteredObjects.map(object => {
                return (
                  <LinkObjectName
                    key={object.id}
                    checked={selectedLinks.find(obj => obj.id === object.id && obj._entityType === object._entityType) !== undefined}
                    onClick={onClick}
                    object={object}
                    disabled={false}
                  />
                );
              })
            )}
            {showLoadMore && <LoadMore slice={slice} setSlice={setSlice} />}
          </Spin>
        </div>
      </div>
    );
  })
);
