import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedAllocationPieConfig } from '..';
import { AllocationPieConfigMapped, DataLabelValue } from './types';
import { LegendPosition } from '@/modules/reporting-v2/core/components/Highcharts/types';
import { RawFilterGroup } from '@/modules/reporting-v2/types/Filter';

export default class AllocationPieConfig extends VisualEngine {
  chartOnly: boolean;
  chartSize?: number;
  drilldown?: boolean;
  labelCrop?: boolean;
  legendBelowChart?: boolean;
  pieHole?: number;
  showTopSeries?: number;
  sortByGroup: string[] | undefined;
  legendPosition?: LegendPosition;
  filterOnGroup?: RawFilterGroup;
  dataLabelValue?: DataLabelValue;
  dataLabelDistance?: string;

  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as AllocationPieConfigMapped));
  }

  getVisualConfigProps(config: AllocationPieConfigMapped): ExtendedAllocationPieConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      chartOnly: config.chartOnly,
      chartSize: config._chartSize,
      drilldown: config.drilldown,
      labelCrop: config._labelCrop,
      legendBelowChart: config.legendBelowChart,
      pieHole: config._pieHole,
      showTopSeries: config.showTopSeries,
      sorting: config.sorting,
      sortByGroup: config.sortByGroup,
      legendPosition: config.legendPosition,
      filterOnGroup: config.filterOnGroup,
      dataLabelValue: config.dataLabelValue,
      dataLabelDistance: config.dataLabelDistance
    });
  }

  setVisualConfig(config: ExtendedAllocationPieConfig) {
    if (config.sorting) {
      const firstColumn = config.columns.find(col => col.isDefault);
      config.sort = {
        order: config.sorting,
        field: firstColumn!.field.name
      };
    }

    super.setVisualConfig(config);

    const drillDown = config.drilldown || config.columns.filter(col => col.isDefault).length > 2;

    this.chartOnly = config.chartOnly;
    this.chartSize = config.chartSize;
    this.drilldown = drillDown;
    this.labelCrop = config.labelCrop;
    this.legendBelowChart = config.legendBelowChart;
    this.pieHole = config.pieHole;
    this.showTopSeries = config.showTopSeries;
    this.sortByGroup = config.sortByGroup;
    this.legendPosition = config.legendPosition;
    this.filterOnGroup = config.filterOnGroup;
    this.dataLabelValue = config.dataLabelValue;
    this.dataLabelDistance = config.dataLabelDistance;
  }
}
