import { selector, selectorFamily } from 'recoil';
import login, { LoggedUser } from '@/common/auth/api/login';
import { UserInformation } from '@/common/auth/api/getUserInfo';
import getAccounts from '@/common/auth/api/getAccounts';
import getUsersForCurrentUser from '@/api/getUsersForCurrentUser';
import { User } from '@/types';
import getFullAccount from '@/common/auth/api/getFullAccount';
import { ExternalAccountLookup, FullAccount } from '@/common/types/entity/Account';
import { getAllHoldingSetTreeCheckpoints } from '@/api';
import { NetworkError } from '@/common/auth/api/NetworkError';
import type { UUID } from '@/common/types/types';
import type { Space } from '@/common/types/entity/Space';

export const loggedUserSelector = selector<LoggedUser>({
  key: 'logged-user-selector',
  get: () => {
    return login();
  }
});

export const userInformationSelector = selectorFamily<UserInformation | null, UUID>({
  key: 'user-informations-selector',
  get:
    accountId =>
    async ({ get }) => {
      const loggedUser = get(loggedUserSelector);
      if (loggedUser.acting && !loggedUser.impersonating) {
        return null;
      }

      return get(fullAccountUserSelector(accountId)) as UserInformation;
    }
});

export const usersForCurrentUsers = selectorFamily<User[] | null, string>({
  key: 'users-for-current-users-selector',
  get:
    accountId =>
    async ({ get }) => {
      if (get(loggedUserSelector).acting) {
        return null;
      }

      return await getUsersForCurrentUser(accountId);
    }
});

export const userAccountsSelector = selector<Array<ExternalAccountLookup>>({
  key: 'user-accounts-selector',
  get: () => {
    return getAccounts();
  }
});

export const fullAccountSelector = selectorFamily<FullAccount | NetworkError, UUID>({
  key: 'full-account-selector',
  get: accountId => () => {
    return getFullAccount(accountId);
  }
});

const fullAccountUserSelector = selectorFamily<FullAccount['user'], UUID>({
  key: 'full-account-user-selector',
  get:
    accountId =>
    ({ get }) => {
      return (get(fullAccountSelector(accountId)) as FullAccount).user;
    }
});

export const checkpointsSelector = selector({
  key: 'hset-checkpoints-selector',
  get: () => {
    return getAllHoldingSetTreeCheckpoints();
  }
});

export const spaceSelector = selector<Space | NetworkError>({
  key: 'space-selector',
  get: ({ get }) => {
    const user = get(loggedUserSelector);

    if (user?.availableSpaceIds.length <= 0) {
      return null;
    }

    const fullAccountResponse = get(fullAccountSelector(user.accountId));

    if (fullAccountResponse instanceof NetworkError) {
      return fullAccountResponse;
    }

    return fullAccountResponse.currentSpace;
  }
});
