import React from 'react';
import { HeaderGroup, flexRender, SortingState, ColumnSort } from '@tanstack/react-table';
import { Row as RowData, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import { ColumnHeader } from '@/modules/reporting-v2/types/VisualUtils';
import cx from 'classnames';
import { useCategoryContext } from '@/modules/reporting-v2/core/components/DataTablev2/context/categoryContext';
import { Tooltip } from 'antd';

type HeaderProps = {
  headerGroup: HeaderGroup<RowGroup | RowData>;
  sorting: SortingState;
};

export const Header: React.FC<HeaderProps> = ({ headerGroup, sorting }) => {
  const getCategoryClass = useCategoryContext();

  return (
    <tr>
      {headerGroup.headers.map((header, index) => {
        return (
          <th key={header.id} colSpan={header.colSpan > 1 ? header.colSpan : undefined} className={getCategoryClass(index)}>
            <HeaderSortNumber sorting={sorting} id={header.id} />
            {header.isPlaceholder ? null : (
              <Tooltip title={header.column.columnDef.meta?.column.formatting?.tooltipText}>
                <div className="header-content-wrapper" onClick={header.column.getToggleSortingHandler()}>
                  {flexRender(header.column.columnDef.header, header.getContext())}

                  <HeaderSortButton sortable={header.column.getCanSort()} sortDirection={header.column.getIsSorted()} />
                </div>
              </Tooltip>
            )}
          </th>
        );
      })}
    </tr>
  );
};

type HeaderSortButtonProps = {
  sortable: boolean;
  sortDirection: false | 'asc' | 'desc';
};

const HeaderSortButton: React.FC<HeaderSortButtonProps> = ({ sortable, sortDirection }) => {
  if (!sortable) {
    return null;
  }

  return (
    <span className={cx('sort-icon', sortDirection)}>
      <span className="arrow up" />
      <span className="arrow down" />
    </span>
  );
};

type HeaderSortNumberProps = {
  sorting: ColumnSort[];
  id: string;
};

export const HeaderSortNumber: React.FC<HeaderSortNumberProps> = ({ sorting, id }) => {
  if (sorting.length < 2) {
    return '';
  }

  const sortingNumber = sorting.findIndex(sort => sort.id === id) + 1;
  return sortingNumber ? `(${sortingNumber}) ` : '';
};

type HeaderCategoriesProps = {
  displayCategories: boolean;
  columnsHeaders: ColumnHeader[] | undefined;
};

// Ideally, this component should be removed and replaced width
// Tanstack table columnGroups
// We may need to remove this component and use columnGroups in the future
export const HeaderCategories: React.FC<HeaderCategoriesProps> = ({ displayCategories, columnsHeaders }) => {
  if (!displayCategories || !columnsHeaders?.length) {
    return null;
  }

  return (
    <tr className="columns-header">
      {columnsHeaders.map(header => {
        return (
          <th className="category" key={header.displayName} colSpan={header.colSpan}>
            {header.displayName}
          </th>
        );
      })}
    </tr>
  );
};
