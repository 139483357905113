import React from 'react';
import { Column } from '@/modules/reporting-v2/core/Column';
import GroupByColumn from '@/modules/reporting-v2/core/GroupByColumn';
import { HistoricalConfig } from '@/modules/reporting-v2/core/HistoricalConfig';
import { Dict } from './Common';
import { FieldName } from './Field';
import { FlattenObject, Primitive } from './FlattenObject';
import { RawColumn, RawVisualStylesChartAxis } from './ReportBuilderTypesUtils';
import { Align } from './VisualUtils';

export type RowGroup = {
  group: Primitive;
  level: number;
  rows: Array<RowGroup | Row>;
  maxRangeValues?: Record<FieldName, number>;
  __id__: string;
  fullRow?: boolean;
  color?: string;
} & Row;

export type Row = {
  cells: Array<Primitive>;
  data: FlattenObject;
  __id__: string;
  options?: Record<string, any>;
};

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

interface VisualConfigProps {
  title: string;
  groupByColumns?: GroupByColumn[];
  columns: Column[];
  sortable?: boolean;
  sort?: VisualSort;
  crossFilterReceiver?: boolean;
  crossFilterEmitter?: boolean;
  historicalConfig?: HistoricalConfig;
  visualPositionAlignment?: Align;
  hiddenTitle?: boolean;
  attributesDict?: Dict<string | Dict<string>>;
  displayCategories?: boolean;
  styles?: StylesConfig;
  reportContextFields?: RawColumn;
  improvedLeftJoin?: boolean;
  hideInPdfIfNoData?: boolean;
  dateField?: string;
}

export type StylesConfig = {
  title?: React.CSSProperties;
  wrapper?: React.CSSProperties;
  container?: React.CSSProperties;
  chart?: {
    chartColors?: Array<string>;
    plotShadow?: boolean;
    legend?: React.CSSProperties;
    label?: React.CSSProperties;
    dataLabel?: React.CSSProperties;
    connectorColor?: React.CSSProperties['color'];
    className?: string;
    gridLineColor?: React.CSSProperties['color'];
    xAxis?: RawVisualStylesChartAxis;
    yAxis?: RawVisualStylesChartAxis;
  } & React.CSSProperties;
  table?: {
    header?: React.CSSProperties;
    body?: {
      alternateRow?: React.CSSProperties;
    } & React.CSSProperties;
    footer?: React.CSSProperties;
    textAlign?: React.CSSProperties['textAlign'];
    firstColumnTextAlign?: React.CSSProperties['textAlign'];
    lastColumnTextAlign?: React.CSSProperties['textAlign'];
    chartColors?: Array<string>;
    groups?: Array<React.CSSProperties>;
    emptyContent?: string;
  } & React.CSSProperties;
  gauge?: {
    valueLabel?: React.CSSProperties;
    dial?: {
      backgroundColor?: string;
      borderWith?: string;
      borderColor?: string;
      radius?: string;
      baseWidth?: string;
      baseLength?: string;
      topWidth?: string;
      rearLength?: string;
    };
    pivot?: {
      backgroundColor?: string;
      borderWith?: string;
      borderColor?: string;
      radius?: string;
    };
  } & React.CSSProperties;
  cover?: React.CSSProperties;
  callOut?: {
    value?: React.CSSProperties;
    label?: React.CSSProperties;
  };
  colorScheme?: {
    linkToVisualColorScheme?: string;
  };
  others?: Record<string, any>;
};

export type { VisualConfigProps };

export type VisualSort = {
  order: SortOrder | undefined;
  field: FieldName | undefined;
  custom?: string[];
};
