import React, { useMemo } from 'react';
import { Form, Typography } from 'antd';
import { SchemaField } from './SchemaField';
import { FieldType, SchemaFieldShape, tabDelimiter } from './types';
import { Nullable } from '@/common/types/types';
import { ConfigurableFormValidator, FormValidationError } from '../../ConfigurableFormValidator';
import { nanoid } from 'nanoid';
import { SecurityUtils } from '@/utils/SecurityUtils';

interface IConfigurableSchemaProps {
  onChange: (val: unknown) => void;
  schema: Record<string, SchemaFieldShape>;
  values: Record<string, unknown>;
  validationDebounceOffset?: number;
  onErrors?: (errors: Record<string, any>) => unknown;
  errors?: Record<string, FormValidationError>;
  isReportViewerForm?: boolean;
}

const internalOnly: FieldType[] = [FieldType.HYPERLINK];

export const ConfigurableSchema: React.FC<IConfigurableSchemaProps> = ({ onChange, schema, values = {}, onErrors, validationDebounceOffset, errors, isReportViewerForm }) => {
  const internal = useMemo(() => SecurityUtils.isInternalApp(), []);
  const timeoutHandle: React.MutableRefObject<Nullable<NodeJS.Timeout>> = React.useRef();
  const groupsBypassedSchema = React.useMemo(
    () =>
      Object.keys(schema).reduce((acc, key) => {
        if (key.startsWith(tabDelimiter)) {
          return Object.assign(acc, schema[key].fields);
        } else {
          return schema[key];
        }
      }, {}),
    [schema]
  );

  const customOnChange = React.useCallback(
    (key: string, value: any) => {
      let newValues = {};
      if (key.startsWith(tabDelimiter)) {
        newValues = { ...values, ...value };
      } else {
        newValues = { ...values, [key]: value };
      }

      onChange(newValues);

      if (onErrors) {
        if (timeoutHandle.current) clearTimeout(timeoutHandle.current);
        timeoutHandle.current = setTimeout(() => onErrors(ConfigurableFormValidator.validateConfiguration(groupsBypassedSchema, newValues)), validationDebounceOffset ?? 1000);
      }
    },
    [onChange, values, onErrors, validationDebounceOffset, groupsBypassedSchema]
  );

  return (
    <div className="report-builder-form">
      {Object.keys(schema ?? {})
        .filter(key => internal || !internalOnly.includes(schema[key].type))
        .map(key => {
          const fieldId = nanoid();
          const value = key.startsWith(tabDelimiter) ? Object.keys((schema[key] as any).fields).reduce((acc, val) => Object.assign(acc, { [val]: values[val] }), {}) : values[key];
          const labelColSpan = () => {
            if (!schema[key].label) {
              return 0;
            }
            if (schema[key].type === FieldType.GROUP) {
              return 24;
            }
            return 9;
          };

          return (
            <div key={key} hidden={schema[key].type === FieldType.NONE} data-field-type={schema[key].type} style={schema[key].style}>
              <Form.Item
                label={
                  schema[key].label && (
                    <Typography.Text type="secondary" style={{ fontWeight: 'normal', fontSize: 13 }}>
                      {schema[key].label}
                    </Typography.Text>
                  )
                }
                labelCol={{ span: labelColSpan() }}
                wrapperCol={{
                  span: !schema[key].label || schema[key].type === FieldType.GROUP ? 24 : 15
                }}
                labelAlign="left"
                colon={false}
                style={{ textAlign: !schema[key].label ? 'right' : 'initial' }}
              >
                <SchemaField
                  id={fieldId}
                  {...schema[key]}
                  error={errors?.[key]}
                  onChange={customOnChange}
                  _key={key}
                  value={value}
                  isReportViewerForm={isReportViewerForm}
                  // todo: pass form values through context / recoil state
                  formValues={values}
                />
              </Form.Item>
            </div>
          );
        })}
    </div>
  );
};
