import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { MetadataFile } from '@/modules/ERP/Common/Api/getMetadataFile';

export default (contactId: string): Promise<string> =>
  requestApi({
    method: 'get',
    url: `/api/contacts/${contactId}/photo/download`,
    service: 'contact'
  }).then((axiosResponse: AxiosResponse<MetadataFile> | undefined) => {
    const response = axiosResponse?.data;
    return response?.uri + '?' + response?.signature;
  });
