import { PointOptionsObject } from 'highcharts';
import React from 'react';
import datasourceConfigs from '@/modules/reporting-v2/config/datasource';
import { Column } from '@/modules/reporting-v2/core/Column';
import { ExtendedRowGroup, ScatterChartOptions, ScatterChartSeriesOptions } from '@/modules/reporting-v2/core/components/Highcharts/types';
import { HighChartsDataUtils } from '@/modules/reporting-v2/core/HighchartsDataUtils';
import { Row, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import ScatterChartConfig from './ScatterChartConfig';
import { RankingType } from './VisualSpecificProps';
import { IContext } from '../../ReportContext';

class ScatterChartUtils {
  static getOptions = (visual: ScatterChartConfig, columns: Column[], rows: ExtendedRowGroup[], context?: IContext): ScatterChartOptions => {
    const deepGroup = visual.groupByColumns.filter(group => group.isDefault).length > 0;
    const isHistorical = visual.historicalConfig.enabled;

    const enhancedSeries = visual.series.map(serie => {
      return {
        x: visual.columns.find(col => col.code === serie.x),
        y: visual.columns.find(col => col.code === serie.y)
      };
    });

    const getGroupsFromRows = () => {
      return [
        ...new Set(
          rows.flatMap(row => {
            return row.group as string;
          })
        )
      ];
    };

    const getDateFieldPath = () => {
      for (const column of columns) {
        const index = column.field.getIndex();
        const dateField = datasourceConfigs.get(index)?.dateField;
        if (dateField) {
          return dateField;
        }
      }
    };

    function pointFormatter(this: PointOptionsObject) {
      const formattedXValue = HighChartsDataUtils.formatCell(this.x, enhancedSeries[0].x!, this.custom?.data, context?.reportConfiguration?.config.numberLocale) as string;
      const formattedYValue = HighChartsDataUtils.formatCell(this.y, enhancedSeries[0].y!, this.custom?.data, context?.reportConfiguration?.config.numberLocale) as string;

      if (isHistorical) {
        const date = new Date(this.custom?.data[getDateFieldPath()!]);
        return `Date: ${date.toLocaleDateString()}<br>x: ${formattedXValue}<br>y: ${formattedYValue}`;
      }
      return `x: ${formattedXValue}<br>y: ${formattedYValue}`;
    }

    const filterRows = <T extends (Row | RowGroup | ExtendedRowGroup)[]>(rowsToFilter: T): T => {
      return rowsToFilter.filter(row => {
        const firstCellHasValue = row.cells[0] !== null && row.cells[0] !== undefined;
        const secondCellHasValue = row.cells[1] !== null && row.cells[1] !== undefined;
        return firstCellHasValue && secondCellHasValue;
      }) as T;
    };

    const getMappedSeries = (): ScatterChartSeriesOptions[] => {
      if (deepGroup) {
        const groups = getGroupsFromRows();

        return groups.flatMap<ScatterChartSeriesOptions>(group => {
          let groupRows = rows.filter(row => row.group === group) as (Row | RowGroup)[];
          if (visual.showGroupChildren) {
            groupRows = groupRows.flatMap(groupRow => (groupRow as RowGroup).rows);
          }

          if (!visual.showEmptyValues) {
            groupRows = filterRows(groupRows);
          }

          return enhancedSeries.map(serie => {
            return {
              type: 'scatter',
              id: `${group}-${serie.x?.code}-${serie.y?.code}`,
              name: `${group}`,
              data: groupRows.map(row => {
                return {
                  x: (row.data[serie.x!.fieldDataPath] as number) || 0,
                  y: (row.data[serie.y!.fieldDataPath] as number) || 0,
                  custom: { data: row.data }
                };
              })
            };
          });
        });
      }

      let filteredRows = rows;
      if (!visual.showEmptyValues) {
        filteredRows = filterRows(rows);
      }

      return enhancedSeries.map(serie => {
        if (!serie.x || !serie.y) {
          return { type: 'scatter' };
        }

        return {
          type: 'scatter',
          id: `${serie.x.code}-${serie.y.code}`,
          name: `${serie.x.headerConfig.displayName} vs ${serie.y.headerConfig.displayName}`,
          data: filteredRows.map(row => {
            return {
              x: (row.data[serie.x!.fieldDataPath] as number) || 0,
              y: (row.data[serie.y!.fieldDataPath] as number) || 0,
              custom: { data: row.data }
            };
          })
        };
      });
    };

    const filterTopRows = (rowGroups: ExtendedRowGroup[]): ExtendedRowGroup[] => {
      const rankingFieldPath = visual.columns.find(col => col.code === visual.rankingField)?.fieldDataPath;
      if (!rankingFieldPath) {
        return rowGroups;
      }

      let values: number[] = [];
      if (deepGroup) {
        values = rowGroups.flatMap(rowGroup => {
          return rowGroup.rows.map(row => {
            return row.data[rankingFieldPath] as number;
          });
        });
      }
      if (!deepGroup) {
        values = rows.map(row => {
          return row.data[rankingFieldPath] as number;
        });
      }

      values.sort((a, b) => b - a);

      const topValue = values[visual.rankingNumber! - 1];
      const bottomValue = values[values.length - 1 - (visual.rankingNumber! - 1)];

      if (!deepGroup) {
        if (visual.rankingType === RankingType.BOTTOM) {
          return rows.filter(row => (row.data[rankingFieldPath] as number) <= bottomValue);
        }
        return rows.filter(row => (row.data[rankingFieldPath] as number) >= topValue);
      }

      const filteredRowGroups = rowGroups.map(rowGroup => {
        if (visual.rankingType === RankingType.BOTTOM) {
          rowGroup.rows = rowGroup.rows.filter(row => (row.data[rankingFieldPath] as number) <= bottomValue);
        } else {
          rowGroup.rows = rowGroup.rows.filter(row => (row.data[rankingFieldPath] as number) >= topValue);
        }
        return rowGroup;
      });

      return filteredRowGroups.filter(rowGroup => rowGroup.rows.length > 0);
    };

    const addTrendLines = (series: ScatterChartSeriesOptions[]) => {
      const getTrendLineSerie = (linkedTo: string) => {
        return {
          type: 'trendline',
          linkedTo,
          enableMouseTracking: false,
          label: {
            formatter: function () {
              return 'This';
            }
          }
        } as ScatterChartSeriesOptions;
      };

      if (deepGroup) {
        enhancedSeries.forEach(serie => {
          getGroupsFromRows().forEach(group => {
            series.push(getTrendLineSerie(`${group}-${serie.x?.code}-${serie.y?.code}`));
          });
        });
      }

      enhancedSeries.forEach(serie => {
        series.push(getTrendLineSerie(`${serie.x?.code}-${serie.y?.code}`));
      });
    };

    const getDisplayName = (column: Column | undefined) => {
      let displayName = column?.headerConfig.displayName;
      if (React.isValidElement<any>(displayName)) {
        displayName = displayName.props?.children;
      }
      return displayName as string;
    };

    if (visual.rankingField && visual.rankingNumber) {
      rows = filterTopRows(rows);
    }

    const mappedSeries = getMappedSeries();

    if (visual.trendline) {
      addTrendLines(mappedSeries);
    }

    return {
      chart: {
        zoomType: 'xy'
      },
      legend: {
        enabled: visual.legend
      },
      yAxis: {
        title: {
          text: visual.axisLabel ? getDisplayName(enhancedSeries[0].y) : undefined
        },
        labels: {
          formatter() {
            const compactColumnFormat = {
              ...columns[0],
              formatting: { compact: true, ...columns[0].formatting }
            } as Column;
            return HighChartsDataUtils.formatCell(this.value, compactColumnFormat, rows[0].data, context?.reportConfiguration?.config.numberLocale) as string;
          }
        }
      },
      xAxis: {
        title: {
          text: visual.axisLabel ? getDisplayName(enhancedSeries[0].x) : undefined
        },
        labels: {
          formatter() {
            const compactColumnFormat = {
              ...columns[1],
              formatting: { compact: true, ...columns[1].formatting }
            } as Column;
            return HighChartsDataUtils.formatCell(this.value, compactColumnFormat, rows[0].data, context?.reportConfiguration?.config.numberLocale) as string;
          }
        }
      },
      plotOptions: { series: { turboThreshold: 9999 } },
      tooltip: {
        headerFormat: '<b>{series.name}</b><br>',
        pointFormatter: pointFormatter
      },
      series: mappedSeries
    };
  };
}

export { ScatterChartUtils };
