import { RawVisual, VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import fastSort from 'fast-sort';
import { Index } from '@/modules/reporting-v2/types/Index';
import datasourceConfigs from '@/modules/reporting-v2/config/datasource';
import reportQueryStringParams from '@/modules/reporting-v2/config/reportQueryStringParams';
import { ReportingService } from './ReportingService';
import * as Visuals from './visuals';
import { Field } from './Field';
import { IndexNode } from './IndexNode';
import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import { Column } from '@/modules/reporting-v2/core/Column';
import GroupByColumn from '@/modules/reporting-v2/core/GroupByColumn';

const UnknownVisualError = Error;

export const createVisual = (rawVisual: RawVisual, multiEntityReport?: boolean) => {
  const component = rawVisual.component;
  switch (component) {
    case VisualComponent.AllocationPie:
      return new Visuals.AllocationPieConfig(rawVisual, multiEntityReport);

    case VisualComponent.AllocationTable:
      return new Visuals.AllocationTableConfig(rawVisual, multiEntityReport);

    case VisualComponent.AssetAllocationInfo:
      return new Visuals.AssetAllocationInfoConfig(rawVisual, multiEntityReport);

    case VisualComponent.BarChart:
      return new Visuals.BarChartConfig(rawVisual, multiEntityReport);

    case VisualComponent.CallOut:
      return new Visuals.CallOutConfig(rawVisual, multiEntityReport);

    case VisualComponent.DashboardTable:
      return new Visuals.DashboardTableConfig(rawVisual, multiEntityReport);

    case VisualComponent.DateSlicer:
      return new Visuals.DateSlicerConfig(rawVisual, multiEntityReport);

    case VisualComponent.Flag:
      return new Visuals.FlagConfig(rawVisual, multiEntityReport);

    case VisualComponent.FlowsTable:
      return new Visuals.FlowsTableConfig(rawVisual, multiEntityReport);

    case VisualComponent.Gauge:
      return new Visuals.GaugeConfig(rawVisual, multiEntityReport);

    case VisualComponent.HistoricalChart:
      return new Visuals.HistoricalChartConfig(rawVisual, multiEntityReport);

    case VisualComponent.HistoricalMonthlyTab:
      return new Visuals.HistoricalMonthlyTabConfig(rawVisual, multiEntityReport);

    case VisualComponent.HoldingSetInfo:
      return new Visuals.HoldingSetInfoConfig(rawVisual, multiEntityReport);

    case VisualComponent.LimitsCallOuts:
      return new Visuals.LimitsCallOutsConfig(rawVisual, multiEntityReport);

    case VisualComponent.LimitsTable:
      return new Visuals.LimitsTableConfig(rawVisual, multiEntityReport);

    case VisualComponent.LiquidityTable:
      return new Visuals.LiquidityTableConfig(rawVisual, multiEntityReport);

    case VisualComponent.CustomTable:
      return new Visuals.CustomTableConfig(rawVisual, multiEntityReport);

    case VisualComponent.PerformanceTable:
      return new Visuals.PerformanceTableConfig(rawVisual, multiEntityReport);

    case VisualComponent.ScatterChart:
      return new Visuals.ScatterChartConfig(rawVisual, multiEntityReport);

    case VisualComponent.SelectBox:
      return new Visuals.SelectBoxConfig(rawVisual, multiEntityReport);

    case VisualComponent.StatsTable:
      return new Visuals.StatsTableConfig(rawVisual, multiEntityReport);

    case VisualComponent.TableOfContent:
      return new Visuals.TableOfContentConfig(rawVisual, multiEntityReport);

    case VisualComponent.TextImage:
      return new Visuals.TextImageConfig(rawVisual, multiEntityReport);

    case VisualComponent.TopTab:
      return new Visuals.TopTabConfig(rawVisual, multiEntityReport);

    case VisualComponent.TreeMap:
      return new Visuals.TreeMapConfig(rawVisual, multiEntityReport);

    case VisualComponent.DrilldownTable:
      return new Visuals.DrilldownTableConfig(rawVisual, multiEntityReport);

    case VisualComponent.TransposeTable:
      return new Visuals.TransposeTableConfig(rawVisual, multiEntityReport);

    case VisualComponent.SingleValue:
      return new Visuals.SingleValueConfig(rawVisual, multiEntityReport);

    case VisualComponent.GhostVisual:
      return new Visuals.GhostVisualConfig(rawVisual, multiEntityReport);

    default:
      throw new UnknownVisualError('Unsupported visual');
  }
};

export const computeReferences = (primaryNode: IndexNode, referenceNode: IndexNode): Field[] => {
  const sortedCommonFieldsFromCache = ReportingService.referenceCache.get(primaryNode.node)?.get(referenceNode.node);

  if (sortedCommonFieldsFromCache) {
    return sortedCommonFieldsFromCache;
  }

  const primaryNodeForeignKeys = primaryNode.getForeignKeys();
  const commonForeignKeys = referenceNode.findCommonForeignKeys(primaryNodeForeignKeys);

  addDatasourceConfigurationFields(primaryNode.getIndex(), referenceNode.getIndex(), commonForeignKeys);

  const fields: Array<Field> = [];

  commonForeignKeys.forEach(key => {
    fields.push(Field.fromPath(key));
  });

  const sortedCommonFields = fastSort(fields).asc(field => field.getLeafPath());

  const existingReferenceNodesCache = ReportingService.referenceCache.get(primaryNode.node);

  if (existingReferenceNodesCache) {
    existingReferenceNodesCache.set(referenceNode.node, sortedCommonFields);
  } else {
    ReportingService.referenceCache.set(primaryNode.node, new Map().set(referenceNode.node, sortedCommonFields));
  }

  return sortedCommonFields;
};

const addDatasourceConfigurationFields = (primaryIndex: Index, referenceIndex: Index, set: Set<string>) => {
  const primaryIndexConfig = ReportingService.rawMetas[primaryIndex].__config;

  const referenceIndexConfig = ReportingService.rawMetas[referenceIndex].__config;

  if (primaryIndexConfig.dateEnabled && referenceIndexConfig.dateEnabled) {
    const referenceIndexDateField = datasourceConfigs.get(referenceIndex)?.dateField;
    if (referenceIndexDateField) {
      set.add(referenceIndexDateField);
    } else {
      throw new Error(`Missing date field in datasource config for index ${referenceIndex}`);
    }
  }

  if (primaryIndexConfig.consolidationTypeEnabled && referenceIndexConfig.consolidationTypeEnabled) {
    set.add(`${referenceIndex}.consolidationType`);
  }

  if (primaryIndexConfig.holdingSetEnabled && referenceIndexConfig.holdingSetEnabled) {
    set.add(`${referenceIndex}.holdingSetId`);
  }

  reportQueryStringParams.forEach(queryStringParam => {
    if (primaryIndexConfig[queryStringParam.enabled] && referenceIndexConfig[queryStringParam.enabled]) {
      set.add(`${referenceIndex}.${queryStringParam.field}`);
    }
  });
};

export const getConditionalColor = (column: Column | GroupByColumn, data: FlattenObject) => {
  const colorFormatting = column.formatting?.color;
  if (!colorFormatting || typeof colorFormatting === 'string') {
    return undefined;
  }

  const validCondition = colorFormatting.conditions?.find(({ condition }) => {
    const conditionIsString = typeof condition === 'string';
    if (conditionIsString) {
      return false;
    }

    const conditionIsFilterGroup = 'evaluate' in condition;
    if (!conditionIsFilterGroup) {
      return false;
    }

    return condition.evaluate(data);
  });

  return validCondition?.value;
};
