import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import ExtendedTransposeTableConfig from './VisualSpecificProps';
import { TransposeTableConfigMapped } from './types';
import { Column } from '@/modules/reporting-v2/core/Column';

export default class TransposeTableConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as TransposeTableConfigMapped));
  }

  getVisualConfigProps(config: TransposeTableConfigMapped): ExtendedTransposeTableConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      valueField: config.valueField,
      rowField: config.rowField,
      columnField: config.columnField,
      groupCategories: config.groupCategories,
      categoryHeadersEnabled: config.categoryHeadersEnabled,
      total: config.total,
      subTotal: config.subTotal,
      collapsible: config._collapsible,
      transpose: config.transpose,
      sortColumns: config.sortColumns
    });
  }

  setVisualConfig(config: ExtendedTransposeTableConfig) {
    super.setVisualConfig(config);
    this.valueField = config.valueField;
    this.rowField = config.rowField;
    this.columnField = config.columnField;
    this.groupCategories = config.groupCategories;
    this.collapsible = config.collapsible;
    this.total = config.total;
    this.subTotal = config.subTotal;
    this.categoryHeadersEnabled = config.categoryHeadersEnabled;
    this.transpose = config.transpose;
    this.sortable = true;
    this.htmlEditableOnly = true;
    this.sortColumns = config.sortColumns;
  }

  categoryHeadersEnabled: boolean;
  total: boolean;
  subTotal: boolean;
  collapsible: boolean;
  transpose: boolean;
  valueField: Column[];
  rowField: Column[];
  columnField: Column[];
  groupCategories: Column[];
  sortColumns?: 'ASC' | 'DESC';
}
