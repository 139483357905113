import React from 'react';
import { FieldType } from '@/modules/report-builder/components/Forms/Visual/Schema/types';
import { withSmallSuspense } from '@/common/suspense';
import type { FormValidationError } from '@/modules/report-builder/components/Forms/ConfigurableFormValidator';
import type { Serie } from '@/modules/reporting-v2/core/visuals/ScatterChart/VisualSpecificProps';
import type { CustomColumn } from '@/modules/report-builder/components/Forms/Visual/Schema/CustomColumnsWrapper';

const ColumnsWrapper = React.lazy(async () => ({ default: (await import('./Columns/ColumnsWrapper')).ColumnsWrapper }));
const CheckboxWrapper = React.lazy(async () => ({ default: (await import('./CheckboxWrapper')).CheckboxWrapper }));
const DatePickerWrapper = React.lazy(async () => ({ default: (await import('./DatePickerWrapper')).DatePickerWrapper }));
const DictionaryWrapper = React.lazy(async () => ({ default: (await import('./DictionaryWrapper')).DictionaryWrapper }));
const InputNumberWrapper = React.lazy(async () => ({ default: (await import('./InputNumberWrapper')).InputNumberWrapper }));
const InputTextAreaWrapper = React.lazy(async () => ({ default: (await import('./InputTextAreaWrapper')).InputTextAreaWrapper }));
const InputWrapper = React.lazy(async () => ({ default: (await import('./InputWrapper')).InputWrapper }));
const SelectWrapper = React.lazy(async () => ({ default: (await import('./SelectWrapper')).SelectWrapper }));
const UploaderWrapper = React.lazy(async () => ({ default: (await import('./UploaderWrapper')).UploaderWrapper }));
const WysiwygTextAreaWrapper = React.lazy(async () => ({ default: (await import('./WysiwigTextAreaWrapper')).WysiwygTextAreaWrapper }));
const CheckboxGroupWrapper = React.lazy(async () => ({ default: (await import('./CheckboxGroupWrapper')).CheckboxGroupWrapper }));
const RadioGroupWrapper = React.lazy(async () => ({ default: (await import('./RadioGroupWrapper')).RadioGroupWrapper }));
const CustomCSSWrapper = React.lazy(async () => ({ default: (await import('./CustomCSSWrapper')).CustomCSSWrapper }));
const SwitchWrapper = React.lazy(async () => ({ default: (await import('./SwitchWrapper')).SwitchWrapper }));
const HyperlinkWrapper = React.lazy(async () => ({ default: (await import('./HyperlinkWrapper')).HyperlinkWrapper }));
const ArrayWrapper = React.lazy(async () => ({ default: (await import('./ArrayWrapper')).ArrayWrapper }));
const GroupWrapper = React.lazy(async () => ({ default: (await import('./GroupWrapper')).GroupWrapper }));
const VisualColumnsSelectWrapper = React.lazy(async () => ({ default: (await import('./Columns/VisualColumnsSelectWrapper')).VisualColumnsSelectWrapper }));
const CalcWrapper = React.lazy(async () => ({ default: (await import('./CalcWrapper')).CalcWrapper }));
const GlobalFiltersWrapper = React.lazy(async () => ({ default: (await import('./GlobalFiltersWrapper')).GlobalFiltersWrapper }));
const FontWrapper = React.lazy(async () => ({ default: (await import('./FontWrapper')).FontWrapper }));
const BorderWrapper = React.lazy(async () => ({ default: (await import('./BorderWrapper')).BorderWrapper }));
const ConditionalColorSelect = React.lazy(async () => ({ default: (await import('./ConditionalSelectWrapper')).ConditionalColorSelect }));
const ConditionalMeasureSelect = React.lazy(async () => ({ default: (await import('./ConditionalSelectWrapper')).ConditionalMeasureSelect }));
const ColorPickerWrapper = React.lazy(async () => ({ default: (await import('./ColorPickerWrapper')).ColorPickerWrapper }));
const MultiColorPickerWrapper = React.lazy(async () => ({ default: (await import('./MultiColorPickerWrapper')).MultiColorPickerWrapper }));
const ColumnsOrVisualColumnsSelectWrapper = React.lazy(async () => ({
  default: (await import('./Columns/ColumnsOrVisualColumnsSelectWrapper')).ColumnsOrVisualColumnsSelectWrapper
}));
const TextAlign = React.lazy(async () => ({ default: (await import('./TextAlign')).TextAlign }));
const SeriesWrapper = React.lazy(async () => ({ default: (await import('./SeriesWrapper')).SeriesWrapper }));
const CustomColumnsWrapper = React.lazy(async () => ({ default: (await import('./CustomColumnsWrapper')).CustomColumnsWrapper }));
const VisualSelectorWrapper = React.lazy(async () => ({ default: (await import('./VisualSelectorWrapper')).VisualSelectorWrapper }));
const PageTemplateWrapper = React.lazy(async () => ({ default: (await import('./PageTemplateWrapper')).PageTemplateWrapper }));
const FilterGroupWrapper = React.lazy(async () => ({ default: (await import('@/modules/report-builder/components/Forms/Visual/Schema/FilterGroupWrapper')).FilterGroupWrapper }));
const TableVersionInput = React.lazy(async () => ({ default: (await import('@/modules/report-builder/components/Forms/Visual/Schema/TableVersionInput')).TableVersionInput }));

interface ISchemaFieldProps {
  id?: string;
  type: FieldType;
  onChange: (key: string, value: unknown) => void;
  _key: string;
  value: unknown;
  mode?: any;
  options?: Record<string, string>;
  limit?: number;
  format?: string;
  fields?: any;
  conditions?: Record<string, string>;
  title?: string;
  error?: FormValidationError;
  isReportViewerForm?: boolean;
  formValues: Record<string, any>;
  allowEmpty?: boolean;
}

export const SchemaField: React.FC<ISchemaFieldProps> = withSmallSuspense(
  React.memo(({ type, onChange, _key, value, formValues, options, fields, title, error, mode, isReportViewerForm, allowEmpty, ...rest }) => {
    switch (type) {
      case FieldType.GLOBAL_FILTERS:
        return <GlobalFiltersWrapper _key={_key} onChange={onChange} value={value} schema={fields} />;

      case FieldType.DATE:
        return <DatePickerWrapper _key={_key} value={value} onChange={onChange} format={rest.format} />;

      case FieldType.CALC:
        return <CalcWrapper _key={_key} onChange={onChange} value={value as (string | number | undefined)[]} calcOnGroup={formValues['calcOnGroup'] ?? false} />;

      case FieldType.VISUAL_COLUMNS:
        return <VisualColumnsSelectWrapper _key={_key} value={value} onChange={onChange} isReportViewerForm={isReportViewerForm} />;

      case FieldType.GROUP:
        return <GroupWrapper _key={_key} value={value} onChange={onChange} schema={fields} title={title} isReportViewerForm={isReportViewerForm} {...rest} />;

      case FieldType.ARRAY:
        return <ArrayWrapper _key={_key} value={value} onChange={onChange} schema={fields} isReportViewerForm={isReportViewerForm} />;

      case FieldType.HYPERLINK:
        return <HyperlinkWrapper value={value} onChange={onChange} _key={_key} />;

      case FieldType.NUMBER:
        return <InputNumberWrapper _key={_key} value={value} onChange={onChange} {...rest} />;

      case FieldType.COLUMNS:
        return <ColumnsWrapper value={value} onChange={onChange} _key={_key} error={error} isReportViewerForm={isReportViewerForm} mode={mode} {...rest} />;

      case FieldType.BOOLEAN:
        return <CheckboxWrapper value={value} onChange={onChange} _key={_key} />;

      case FieldType.MARKDOWN:
        return <InputTextAreaWrapper value={value} onChange={onChange} _key={_key} />;

      case FieldType.UPLOAD:
        return <UploaderWrapper value={value} onChange={onChange} _key={_key} />;

      case FieldType.TABLE_VERSION:
        return <TableVersionInput value={value} onChange={onChange} _key={_key} />;

      case FieldType.DICT:
        return (
          // @ts-ignore TODO : Migrate to TS
          <DictionaryWrapper value={value} onChange={onChange} _key={_key} />
        );

      case FieldType.SELECT:
        return <SelectWrapper value={value} onChange={onChange} options={options!} _key={_key} mode={mode} {...rest} />;

      case FieldType.WYSIWYG_MARKDOWN:
        return <WysiwygTextAreaWrapper value={value} onChange={onChange} _key={_key} />;

      case FieldType.CHECKBOX_GROUP:
        return <CheckboxGroupWrapper value={value} onChange={onChange} _key={_key} options={options!} />;

      case FieldType.RADIO_GROUP:
        return <RadioGroupWrapper value={value} onChange={onChange} _key={_key} options={options!} />;

      case FieldType.CUSTOM_CSS:
        return <CustomCSSWrapper value={value} onChange={onChange} _key={_key} />;

      case FieldType.SWITCH:
        return <SwitchWrapper value={value} onChange={onChange} _key={_key} options={options} {...rest} />;

      case FieldType.COLORPICKER:
        return <ColorPickerWrapper value={value as any} onChange={onChange as any} _key={_key} {...rest} />;

      case FieldType.MULTICOLORPICKER:
        return <MultiColorPickerWrapper value={value as any} onChange={onChange as any} _key={_key} {...rest} />;

      case FieldType.TEXT_ALIGN:
        return <TextAlign value={value as any} onChange={onChange as any} _key={_key} allowEmpty={allowEmpty} {...rest} />;

      case FieldType.CONDITIONAL_COLOR_SELECT:
        return <ConditionalColorSelect value={value as any} options={options!} onChange={onChange as any} _key={_key} {...rest} />;

      case FieldType.CONDITIONAL_MEASURE_SELECT:
        return <ConditionalMeasureSelect value={value as any} options={options!} onChange={onChange as any} _key={_key} {...rest} />;

      case FieldType.FILTER_GROUP:
        return <FilterGroupWrapper value={value as any} onChange={onChange as any} _key={_key} {...rest} />;

      case FieldType.FONT:
        return <FontWrapper value={value as any} onChange={onChange as any} _key={_key} {...rest} />;

      case FieldType.BORDER:
        return <BorderWrapper value={value as any} onChange={onChange as any} _key={_key} {...rest} />;

      case FieldType.COLUMNS_OR_VISUAL_COLUMNS:
        return <ColumnsOrVisualColumnsSelectWrapper value={value as any} onChange={onChange as any} _key={_key} mode={mode} {...rest} />;

      case FieldType.SERIES:
        return <SeriesWrapper value={value as Serie[] | undefined} onChange={onChange} _key={_key} isReportViewerForm={isReportViewerForm} {...rest} />;

      case FieldType.CUSTOMCOLUMNS:
        return <CustomColumnsWrapper value={value as CustomColumn[] | undefined} onChange={onChange} _key={_key} isReportViewerForm={isReportViewerForm} {...rest} />;

      case FieldType.VISUAL_SELECTOR:
        return <VisualSelectorWrapper _key={_key} value={value} onChange={onChange} {...rest} />;

      case FieldType.PAGE_TEMPLATE:
        return <PageTemplateWrapper _key={_key} value={value as number | undefined} onChange={onChange} {...rest} />;

      case FieldType.NONE:
        return null;

      default:
        return <InputWrapper _key={_key} onChange={onChange} value={value} {...rest} />;
    }
  })
);
