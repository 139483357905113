import React, { memo, useCallback, useMemo } from 'react';
import { message, Row } from 'antd';
import { Drawer } from 'ui-sesame-components';
import { DrawerProps } from 'antd/lib/drawer';
import { EntityParameters } from '@/common/types/app/EntityParametersHandler';
import { findHoldingSet } from '@/utils/findHoldingSet';
import { Text } from '@/common/components/Typography/Text';
import { EnumUtils } from '@/utils/EnumUtils';
import { ConsolidationType } from '@/modules/reporting-v2/types/ReportingService';

import './style.css';
import { LookThroughTypes } from '../../../../../config/lookThroughTypes';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';

interface EntitiesDrawerProps extends DrawerProps {
  entityOrdering: Array<number>;
  params: Array<EntityParameters>;
  historicalEnabled: boolean;
  onChange: (id: number) => void;
}

const EntitiesDrawer: React.FunctionComponent<EntitiesDrawerProps> = ({ entityOrdering, params, onChange, historicalEnabled, ...drawerProps }) => {
  const currentUser = useRecoilValue(currentUserSelector);
  const tree = useMemo(() => currentUser.holdingSetTree, [currentUser]);
  const allEntities = useMemo(() => {
    return params.map((param: EntityParameters) => findHoldingSet(tree, hset => hset.id === param.holdingSetId)!);
  }, [params, tree]).filter(val => val !== undefined);
  const visualParams = useMemo(() => entityOrdering.map(order => params[order]).filter(val => val !== undefined), [entityOrdering, params]);

  return (
    <Drawer {...drawerProps}>
      {allEntities.map((entity, index) => (
        <EntityItem
          key={entity.id}
          index={index}
          historicalEnabled={historicalEnabled}
          param={params.find(param => param.holdingSetId === entity.id)!}
          active={visualParams.some(param => param.holdingSetId === entity.id)}
          name={entity.name}
          paramsLength={params.length}
          onChange={onChange}
        />
      ))}
    </Drawer>
  );
};

interface EntityItemProps {
  active: boolean;
  name: string;
  param: EntityParameters;
  paramsLength: number;
  index: number;
  onChange: EntitiesDrawerProps['onChange'];
  historicalEnabled: boolean;
}

const EntityItem: React.FunctionComponent<EntityItemProps> = memo(({ active, historicalEnabled, name, paramsLength, onChange, index, param }) => {
  const translator = useIntl();
  const onClick = useCallback(() => {
    if (active && paramsLength <= 1) {
      message.error(translator.formatMessage({ id: 'report.visual.youCannotDeselectTheLastEntity' }));
    } else {
      onChange(param.holdingSetId);
    }
  }, [param.holdingSetId, onChange, active, paramsLength, translator]);

  const styles = useMemo(() => {
    return {
      backgroundColor: active ? '#f0f7ff' : '#f4f4f4',
      minWidth: 350,
      border: active ? '1px dashed var(--ant-color-primary)' : undefined,
      padding: 12,
      marginTop: index === 0 ? 0 : 12,
      cursor: 'pointer'
    };
  }, [index, active]);

  const currentUser = useRecoilValue(currentUserSelector);
  const date = useMemo(() => {
    return param.date ?? currentUser.verifiedDates[param.holdingSetId];
  }, [param.holdingSetId, param.date, currentUser]);

  return (
    <div className={active ? undefined : 'entity-drawer-inactive'} style={styles} onClick={onClick}>
      <Row>
        <Text black strong>
          {name}
        </Text>
      </Row>
      {param.lookThrough && (
        <Row justify="space-between">
          <Text type="secondary">{EnumUtils.toHumanReadable(param.lookThrough === LookThroughTypes.Lookthrough ? ConsolidationType.LOOK_THROUGH : ConsolidationType.DIRECT)}</Text>
          {!historicalEnabled && date ? <Text type="secondary">{date}</Text> : null}
        </Row>
      )}
    </div>
  );
});

export { EntitiesDrawer };
