import { atom } from 'recoil';

export enum QuantManagementEditMode {
  ELASTIC_MAPPING,
  QUANT_EDITION
}

const searchFilterState = atom<string>({
  key: 'quant-management-search-filter',
  default: ''
});

const indexFilterState = atom<string[]>({
  key: 'quant-management-index-filter',
  default: []
});

const selectedMetricState = atom<[any, QuantManagementEditMode] | undefined>({
  key: 'quant-management-metric-creation-state',
  default: undefined
});

const showOrphansState = atom<boolean>({
  key: 'quant-management-defective-fields-state',
  default: false
});

const selectGridMeasuresOnlyState = atom<boolean>({
  key: 'quant-management-show-excel-measures-only-state',
  default: false
});

export { selectedMetricState, showOrphansState, searchFilterState, indexFilterState, selectGridMeasuresOnlyState };
