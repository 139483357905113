import { hasInternalRole } from '@/utils/hasInternalRole';
import { Roles } from '@/utils/security';
import { internalUserSelector } from './user.atoms';
import { selector } from 'recoil';

type InternalRolesListing = Record<InternalRoleClasses, Roles[]>;
export type InternalRolesAccess = Record<InternalRoleClasses, boolean>;

export enum InternalRoleClasses {
  Internal = 'Internal',
  InternalClientAdmin = 'InternalClientAdmin',
  InternalClientOperator = 'InternalClientOperator',
  InternalClientOperatorProductOperator = 'InternalClientOperatorProductOperator',
  InternalClientOrProductOperator = 'InternalClientOrProductOperator',
  InternalClientOrServiceOperator = 'InternalClientOrServiceOperator',
  InternalClientOrServiceOperatorOrProductOperator = 'InternalClientOrServiceOperatorOrProductOperator',
  InternalClientOrServiceOperatorOrProductOperatorOrQAOperator = 'InternalClientOrServiceOperatorOrProductOperatorOrQAOperator',
  InternalPipelineOperator = 'InternalPipelineOperator',
  InternalProductOperator = 'InternalProductOperator',
  InternalSaleOperator = 'InternalSaleOperator',
  InternalServiceOperator = 'InternalServiceOperator',
  InternalServiceOperatorProductOperator = 'InternalServiceOperatorProductOperator',
  InternalSuperAdmin = 'InternalSuperAdmin',
  InternalSupportOperator = 'InternalSupportOperator'
}

const internalRolesListing: InternalRolesListing = {
  [InternalRoleClasses.Internal]: [
    Roles.INTERNAL,
    Roles.CLIENT_ADMIN,
    Roles.CLIENT_OPERATOR,
    Roles.PIPELINE_OPERATOR,
    Roles.PRODUCT_OPERATOR,
    Roles.QA_OPERATOR,
    Roles.SALE_OPERATOR,
    Roles.SERVICE_OPERATOR,
    Roles.SUPPORT_OPERATOR
  ],
  [InternalRoleClasses.InternalClientAdmin]: [Roles.CLIENT_ADMIN],
  [InternalRoleClasses.InternalClientOperator]: [Roles.CLIENT_ADMIN, Roles.CLIENT_OPERATOR, Roles.SUPPORT_OPERATOR],
  [InternalRoleClasses.InternalClientOperatorProductOperator]: [Roles.CLIENT_ADMIN, Roles.CLIENT_OPERATOR, Roles.PRODUCT_OPERATOR],
  [InternalRoleClasses.InternalClientOrProductOperator]: [Roles.CLIENT_ADMIN, Roles.CLIENT_OPERATOR, Roles.PRODUCT_OPERATOR, Roles.SUPPORT_OPERATOR],
  [InternalRoleClasses.InternalClientOrServiceOperator]: [Roles.CLIENT_ADMIN, Roles.CLIENT_OPERATOR, Roles.SERVICE_OPERATOR],
  [InternalRoleClasses.InternalClientOrServiceOperatorOrProductOperator]: [Roles.CLIENT_ADMIN, Roles.CLIENT_OPERATOR, Roles.PRODUCT_OPERATOR, Roles.SERVICE_OPERATOR],
  [InternalRoleClasses.InternalClientOrServiceOperatorOrProductOperatorOrQAOperator]: [
    Roles.CLIENT_ADMIN,
    Roles.CLIENT_OPERATOR,
    Roles.PRODUCT_OPERATOR,
    Roles.SERVICE_OPERATOR,
    Roles.QA_OPERATOR
  ],
  [InternalRoleClasses.InternalPipelineOperator]: [Roles.PIPELINE_OPERATOR],
  [InternalRoleClasses.InternalProductOperator]: [Roles.CLIENT_ADMIN, Roles.PRODUCT_OPERATOR],
  [InternalRoleClasses.InternalSaleOperator]: [Roles.SALE_OPERATOR],
  [InternalRoleClasses.InternalServiceOperator]: [Roles.SERVICE_OPERATOR],
  [InternalRoleClasses.InternalServiceOperatorProductOperator]: [Roles.SERVICE_OPERATOR, Roles.PRODUCT_OPERATOR],
  [InternalRoleClasses.InternalSuperAdmin]: [Roles.SUPER_ADMIN],
  [InternalRoleClasses.InternalSupportOperator]: [Roles.SUPPORT_OPERATOR]
};

export const internalSecuritySelector = selector<InternalRolesAccess | false>({
  key: 'internal-security-selector',
  get: ({ get }) => {
    const internalUser = get(internalUserSelector);
    if (!internalUser) {
      return false;
    }

    const internalRolesAccess = {} as InternalRolesAccess;
    for (const roleClass in InternalRoleClasses) {
      internalRolesAccess[roleClass as InternalRoleClasses] = hasInternalRole(internalUser, internalRolesListing[roleClass as InternalRoleClasses]);
    }

    return internalRolesAccess;
  }
});
