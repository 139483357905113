import queryString from 'query-string';

enum Params {
  view = 'v',
  filter_inverse = 'fi',
  tag_id = 'ti',
  tag_name = 'tn',
  tag_type = 'tt',
  tag_value_value = 'tvv',
  asset = 'a',
  holding_set = 'hs'
}

export function toSearchString(params: Map<string, any>): string {
  return Array.from(params.entries())
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

export function getParams(): Map<string, string | number | boolean> {
  // @ts-ignore
  return new Map(
    Object.entries(
      queryString.parse(window.location.search, {
        parseBooleans: true,
        parseNumbers: true
      })
    )
  );
}

export default Params;
