export default (_: string, value: any) => {
  if (value instanceof Map) {
    if (!value.size) {
      return {
        dataType: 'Map',
        value: 0x0 // Arbitrary value, could be anything doesn't matter we don't wanna rerender
      };
    } else {
      const ids = [];

      for (const item of value.values()) if (item.dataRefId) ids.push(item.dataRefId);

      return {
        dataType: 'Map',
        value: ids.join()
      };
    }
  } else {
    return value;
  }
};
