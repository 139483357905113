import React, { useCallback } from 'react';
import { useReactTable, getCoreRowModel, getExpandedRowModel, getSortedRowModel, SortingState, OnChangeFn, Updater } from '@tanstack/react-table';
import { Row } from '@/modules/reporting-v2/core/components/DataTablev2/components/Row';
import { useCreateTableColumns } from '@/modules/reporting-v2/core/components/DataTablev2/hooks/useCreateTableColumns';
import { Header, HeaderCategories } from '@/modules/reporting-v2/core/components/DataTablev2/components/Header';
import { Footer } from '@/modules/reporting-v2/core/components/DataTablev2/components/Footer';
import { css } from 'styled-components';
import { TableContextProvider } from '@/modules/reporting-v2/core/components/DataTablev2/context/provider';
import { useExpandState } from '@/modules/reporting-v2/core/components/DataTablev2/hooks/useExpandState';
import { useDataState } from '@/modules/reporting-v2/core/components/DataTablev2/hooks/useDataState';
import { useTotalDataState } from '@/modules/reporting-v2/core/components/DataTablev2/hooks/useTotalDataState';
import VisualContext from '@/modules/reporting-v2/core/visuals/Visual/VisualContext';
import { TableWrapper } from '@/modules/reporting-v2/core/components/DataTablev2/components/table/TableWrapper';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import { SortOrder, type Row as Row_, type RowGroup, type VisualSort } from '@/modules/reporting-v2/types/VisualEngine';
import { customFormattingType } from '@/modules/reporting-v2/core/formatTypes';
import type { StyledObject, Styles } from 'styled-components/dist/types';
import type { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import type { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import type { Column } from '@/modules/reporting-v2/core/Column';
import type { TableSortItem } from '@/modules/AnalyticsReportViewer/components/Visuals/AllocationTable/types';

// todo: update with a normalized way to handle sorting across tables
const getInitialSortingState = (visualSort: VisualSort | VisualSort[] | TableSortItem[] | undefined, defaultVisualColumns: Column[]): SortingState => {
  if (Array.isArray(visualSort)) {
    return visualSort
      .filter(tableSortItem => tableSortItem.field)
      .map(tableSortItem => {
        let isDesc = false;
        if ('direction' in tableSortItem) {
          isDesc = tableSortItem.direction === SortOrder.DESC;
        }
        if ('order' in tableSortItem) {
          isDesc = tableSortItem.order === SortOrder.DESC;
        }
        return {
          id: tableSortItem.field!,
          desc: isDesc
        };
      });
  }

  if (!visualSort || !visualSort.field) {
    return [];
  }

  const tableColumnId = defaultVisualColumns.find(column => column.code === visualSort.field)?.fieldDataPath;

  return [{ id: tableColumnId ?? visualSort.field, desc: visualSort.order === SortOrder.DESC }];
};

type TableProps = {
  visual: VisualEngine;
  onRowClick?: (rowData: FlattenObject) => void;
  isRowSelected?: (rowData: FlattenObject) => boolean;
  shouldUpdateDataframe?: boolean;
  defaultExpanded?: boolean;
  getInitialDataPath?: (columnId: string, rowId: string) => string;
};

const Table: React.FC<TableProps> = ({ visual, onRowClick, isRowSelected, shouldUpdateDataframe, defaultExpanded, getInitialDataPath }) => {
  const { updatePreferences } = React.useContext(ReportContext);
  const { onValueChange } = React.useContext(VisualContext);
  const defaultVisualColumns = React.useMemo(() => visual.columns.filter(column => column.isDefault), [visual.columns]);
  const defaultGroupByColumns = React.useMemo(() => {
    return visual.groupByColumns.filter(groupColumn => groupColumn.isDefault);
  }, [visual.groupByColumns]);

  const [expanded, setExpanded] = useExpandState(defaultExpanded);
  const [totalData, setTotalData] = useTotalDataState(visual);
  const [data, setData] = useDataState(visual, defaultGroupByColumns);
  const [sorting, setSorting] = React.useState<SortingState>(() => getInitialSortingState(visual.sort, defaultVisualColumns));

  const columns = useCreateTableColumns(defaultVisualColumns, totalData);

  const getSubRows = React.useCallback((row: Row_ | RowGroup) => {
    return 'rows' in row ? row.rows : [];
  }, []);

  const onSort = useCallback(
    (updater: (prevSorting: SortingState) => SortingState) => {
      const newSorting = updater(sorting);
      const newConfig = { ...visual.rawConfig };

      if (!newSorting.length) {
        sorting.forEach(sort => {
          const code = visual.columns.find(column => column.fieldDataPath === sort.id)?.code;
          if (code && newConfig.columns) {
            const { _sort: omit, ...rest } = newConfig.columns.options[code] ?? {};
            newConfig.columns.options[code] = rest;
          }
        });
      }

      newSorting.forEach(sort => {
        const code = visual.columns.find(column => column.fieldDataPath === sort.id)?.code;
        if (code && newConfig.columns) {
          newConfig.columns!.options[code] = {
            ...newConfig.columns!.options[code],
            _sort: sort.desc ? SortOrder.DESC : SortOrder.ASC
          };
        }
      });

      updatePreferences?.(visual.id, newConfig, visual.version);
      setSorting(newSorting);
    },
    [sorting, updatePreferences, visual.columns, visual.id, visual.rawConfig, visual.version]
  ) as OnChangeFn<SortingState>;

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
      sorting
    },
    onSortingChange: onSort,
    onExpandedChange: setExpanded,
    getSubRows: getSubRows,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    isMultiSortEvent: () => {
      return true;
    },
    meta: {
      updateData: (rowId, columnId, value, columnIndex, data, formattedValue, objectValue) => {
        if (shouldUpdateDataframe) {
          onValueChange?.(data, value, columnId, rowId);
        }

        const isFirstColumn = columnIndex === 0;

        const updateDeepRow = (row: Row_ | RowGroup): Row_ | RowGroup => {
          const isRowGroup = 'group' in row;

          if (isRowGroup && row.__id__ !== rowId) {
            return {
              ...row,
              rows: row.rows.map(updateDeepRow)
            };
          }

          if (!isRowGroup && row.__id__ !== rowId) {
            return row;
          }

          if (isRowGroup && isFirstColumn) {
            return { ...row, group: value as Primitive };
          }

          const newCells = row.cells.map((cell, index) => {
            if (index === columnIndex) {
              // todo: maybe we can have format function here and use it instead of jsx element changing
              return objectValue && React.isValidElement(objectValue) ? React.cloneElement(objectValue, { children: value }) : value;
            }
            return cell;
          });

          const dataPath = typeof getInitialDataPath === 'function' ? getInitialDataPath(columnId, row.__id__) : columnId;

          const columnFormatting = columns.find(column => column.id)?.meta?.column?.formatting?.type;
          const updatedData = dataPath
            ? {
                ...row.data,
                // if table has custom formatting type (like Stats table for ex.) we should use formatted value
                [dataPath]: columnFormatting === customFormattingType ? formattedValue : value
              }
            : row.data;

          return {
            ...row,
            cells: newCells,
            data: updatedData
          } as RowGroup | Row_;
        };

        setData(old => old.map(row => updateDeepRow(row)));
      },
      updateTotalData: (columnId, value) => {
        setTotalData(old => {
          return {
            ...old,
            [columnId]: value
          } as FlattenObject;
        });
      }
    }
  });

  return (
    <>
      <TableColumnStyles defaultColumns={defaultVisualColumns} />
      <TableContextProvider visual={visual} defaultColumns={defaultVisualColumns} displayCategories={visual.displayCategories}>
        <TableWrapper visual={visual} rowClickable={!!onRowClick}>
          <thead>
            <HeaderCategories displayCategories={visual.displayCategories} columnsHeaders={visual.columnsHeaders} />
            {table.getHeaderGroups().map(headerGroup => {
              return <Header key={headerGroup.id} headerGroup={headerGroup} sorting={sorting} />;
            })}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return <Row isRowSelected={isRowSelected} onRowClick={onRowClick} key={row.id} row={row} />;
            })}
          </tbody>
          {visual.total && (
            <tfoot>
              {table.getFooterGroups().map(footerGroup => {
                return <Footer key={footerGroup.id} footerGroup={footerGroup} />;
              })}
            </tfoot>
          )}
        </TableWrapper>
      </TableContextProvider>
    </>
  );
};

export { Table };

type TableStylesProps = {
  defaultColumns: Column[];
};

const TableColumnStyles: React.FC<TableStylesProps> = React.memo(({ defaultColumns }) => {
  const cssObject: Styles<object> = {};

  const getColumnStyleSelector = (columnIndex: number, extraSelector?: string) => {
    return `.report-data-table table tbody tr td:nth-child(${columnIndex}) ${extraSelector || ''}, .report-data-table table thead tr th:nth-child(${columnIndex}) ${
      extraSelector || ''
    }, .report-data-table table tfoot tr td:nth-child(${columnIndex}) ${extraSelector || ''}`;
  };

  const getOverflowStyle = (overflowType: string): StyledObject<object> | undefined => {
    if (overflowType === 'CROP') {
      return {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      };
    }

    if (overflowType === 'WRAP') {
      return {
        textWrap: 'wrap'
      };
    }

    return undefined;
  };

  const getFixedColumnStyle = (columnIndex: number): StyledObject<object> => {
    if (columnIndex === 0) {
      return {
        left: 0,
        position: 'sticky',
        zIndex: 2
      };
    }

    let leftWidth = 0;
    for (let index = 0; index < columnIndex; index++) {
      const column = defaultColumns[index];
      const columnWidth = column.styling?.width;
      if (!columnWidth) {
        continue;
      }

      leftWidth += parseInt(columnWidth);
    }

    return {
      left: leftWidth + 'px',
      position: 'sticky',
      zIndex: 2
    };
  };

  for (let index = 0; index < defaultColumns.length; index++) {
    const column = defaultColumns[index];
    if (!column.styling?.width) {
      continue;
    }

    let columnStyles = {
      width: column.styling.width,
      maxWidth: column.styling.width,
      minWidth: column.styling.width
    };

    if (column.styling.fixed) {
      columnStyles = {
        ...columnStyles,
        ...getFixedColumnStyle(index)
      };
    }

    cssObject[getColumnStyleSelector(index + 1)] = columnStyles;

    const overflowStyles = getOverflowStyle(column.styling.overflowType);
    if (overflowStyles) {
      cssObject[getColumnStyleSelector(index + 1, '> div')] = overflowStyles;
    }
  }

  return <style>{css(cssObject).join('')}</style>;
});
