import { ContentType } from './ContentType';
import type EntityRef from './EntityRef';
import type { ContentTag, ContentTagLookup, ContentTagRef } from './ContentTag';
import type { ExternalAccount, ExternalAccountLookup } from './Account';
import { Storage } from '@/modules/DMS/types/Storage';

/**
 * This object represents a report for Reports Center.
 * There are two main types of reports:
 *     * Template. A template report is managed by Reports Center system administrators (i.e. not available for editing by client users).
 *     * Custom Report. A report that is created by using a template (normally created by client users from a selected template).
 * The type of report is denoted by "CustomReport.customFor" field, i.e. if it is not null, then that company owns this report (i.e. this report is not available for other companies).
 * Note that "CustomReport.origin" is also populated for custom report type (see the field description below).
 */
export interface CustomReport {
  /**
   * Custom report name.
   */
  name: string;
  /**
   * Reports Builder configuration.
   */
  configuration?: string;
  /**
   * Set of content tags which represent report types.
   */
  contentTags: ContentTag[];
  /**
   * For customized reports (i.e. not templates), this field holds the original report (template) that was used to create this custom report from.
   */
  origin?: CustomReport;
  /**
   * A company which this custom report belongs to.
   */
  holder?: ExternalAccount;
  /**
   * List of companies for which this report (template) is permitted to.
   * "If this field is null, this means the report is available for all companies."
   * Soon we will have a "public" option to know if the report is available for all companies
   */
  permittedAccounts?: ExternalAccountLookup[];

  /**
   * A link (URL) to knowledge base.
   */
  knowledgeBaseUrl?: string;

  /**
   * Content Type
   */
  contentType: ContentType;

  /**
   * Space
   */
  spaceId?: string;
  spreadsheetTemplateStorageLink?: Storage;
  productTemplate?: boolean;

  // Audit fields:
  createdDate: Date;
  createdBy: string;
  lastModifiedDate: Date;
  lastModifiedBy: string;
}

export interface CustomReportRef extends EntityRef {}

export interface CustomReportLookup extends CustomReportRef {
  name: string;
  contentTags: ContentTagLookup[];
  contentType: ContentType;
  lastModifiedDate: Date;
  knowledgeBaseUrl?: string;
  holder?: ExternalAccount;
  companyId?: number;
  customPageId?: string;
  spreadsheetTemplateStorageLink?: Storage;
}

export interface CustomReportLookupExtended extends CustomReportLookup {
  configuration?: string;
}

export interface CustomReportStat {
  accountId: string;
  accountName: string;
  numberOfReports: number;
}

export class TransientCustomReport {
  constructor(
    public name: string,
    public contentTags: ContentTagRef[],
    public contentType: ContentType,
    public configuration?: string,
    public origin?: CustomReportRef,
    public account?: ExternalAccountLookup,
    public permittedAccounts?: ExternalAccountLookup[],
    public knowledgeBaseUrl?: string,
    public spaceId?: string,
    public spreadsheetTemplateStorageLink?: Storage
  ) {}
}

export class PersistentCustomReport implements CustomReport, CustomReportRef {
  constructor(
    public id: number,
    public name: string,
    public contentTags: ContentTag[],
    public contentType: ContentType,
    public createdDate: Date,
    public createdBy: string,
    public lastModifiedDate: Date,
    public lastModifiedBy: string,
    public configuration?: string,
    public origin?: CustomReport,
    public holder?: ExternalAccount,
    public permittedAccounts?: ExternalAccountLookup[],
    public knowledgeBaseUrl?: string,
    public spaceId?: string,
    public spreadsheetTemplateStorageLink?: Storage
  ) {}

  public static fromCustomReport(report: CustomReport & CustomReportRef) {
    return new PersistentCustomReport(
      report.id,
      report.name,
      report.contentTags,
      report.contentType,
      report.createdDate,
      report.createdBy,
      report.lastModifiedDate,
      report.lastModifiedBy,
      report.configuration,
      report.origin,
      report.holder,
      report.permittedAccounts,
      report.knowledgeBaseUrl,
      report.spaceId,
      report.spreadsheetTemplateStorageLink
    );
  }
}
