import React, { ReactElement, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import VisualContext, { IVisualContext } from '@/modules/reporting-v2/core/visuals/Visual/VisualContext';
import { ColumnMetas } from '@/modules/reporting-v2/types/Column';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import stopEventPropagation from '@/utils/stopEventPropagation';
import { castValue } from '@/modules/reporting-v2/utils/castValue';
import { formattedNumberIsZero } from '@/modules/reporting-v2/utils/formattedNumberIsZero';
import classNames from './style.module.css';

interface EditableFieldProps {
  children: ReactNode | string;
  metas: ColumnMetas;
  defaultValue: Primitive;
  field: string;
  data: FlattenObject;
  htmlEditOnly?: boolean;
  rowId?: string;
  styles?: React.CSSProperties;
}

const EditableValue = ({ metas, defaultValue = '', children, field, data, htmlEditOnly, rowId, styles = {} }: EditableFieldProps) => {
  const context: null | IVisualContext = useContext(VisualContext);
  const { editMode } = useContext(ReportContext);
  const [outputRef, setOutputRef] = useState<HTMLElement | null>(null);
  const [inputRef, setInputRef] = useState<HTMLElement | null>(null);
  const [value, setValue] = useState<Primitive>(defaultValue);

  const childValue = (children as ReactElement)?.props?.children ?? children;

  let color: string | undefined = undefined;
  React.Children.map(children, child => {
    // Casting to ReactElement & using optional chaining instead of using React.isValidElement for runtime performance, critical area
    if (!color && (child as ReactElement)?.props?.style?.color) {
      color = (child as ReactElement).props.style.color;
    }
  });

  const setWrapper = useCallback(
    (ref: HTMLElement | null) => {
      if (ref && (!outputRef || !inputRef)) {
        const inputRef = ref.getElementsByClassName(classNames.editInputClassName)[0] as HTMLElement;
        const outputRef = ref.getElementsByClassName('value-output')[0] as HTMLElement;
        const parentTd = ref.parentElement;

        if (outputRef && inputRef) {
          setInputRef(inputRef);
          setOutputRef(outputRef);
          inputRef.innerHTML = outputRef.innerHTML;

          if (parentTd?.getAttribute('v') && !React.isValidElement(value)) {
            parentTd.setAttribute('v', value as string);
          }
        }
      }
    },
    [inputRef, outputRef, value]
  );

  const submit = useCallback(() => {
    if (editMode) {
      if (context && value !== defaultValue && context.onValueChange && !htmlEditOnly) {
        const castedValue = castValue(value, metas.formatting?.type);
        context.onValueChange(data, castedValue, field, rowId);
      }
    }
  }, [context, value, defaultValue, field, htmlEditOnly, metas.formatting, data, editMode, rowId]);

  const handleBlur = useCallback(() => {
    if (editMode) {
      submit();
      setTimeout(() => {
        if (inputRef && outputRef) {
          inputRef.innerHTML = outputRef.innerHTML;
          const parentTd = outputRef?.closest('td');
          parentTd?.setAttribute('v', value?.toString() ?? '');
        }
      }, 100);
    }
  }, [value, inputRef, outputRef, submit, editMode]);

  const setCaretPosition = (element: HTMLElement, cursorPosition?: number) => {
    const selection = window.getSelection();
    const range = document.createRange();
    selection?.removeAllRanges();
    range.selectNodeContents(element);
    range.collapse(false);
    range.setStart(element.childNodes[0], cursorPosition ?? 0);
    range.setEnd(element.childNodes[0], cursorPosition ?? 0);
    selection?.addRange(range);
  };

  const handleInput = useCallback(() => {
    if (editMode) {
      if (inputRef && outputRef) {
        const cursorPosition = document.getSelection()?.focusOffset;

        const newValue = inputRef.textContent?.toString().replace(/[\t\r\n]/gi, '') || '';
        inputRef.textContent = newValue;
        outputRef.textContent = newValue;
        setValue(newValue);

        setCaretPosition(inputRef, cursorPosition);
      }
    }
  }, [inputRef, outputRef, editMode]);

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent): void => {
      if (editMode) {
        if (event.key === 'Enter') {
          submit();
        }
      }
    },
    [submit, editMode]
  );

  const colorStyles = useMemo(() => ({ color, width: '100%' }), [color]);

  return (
    <div
      ref={setWrapper}
      style={{ ...defaultStyles, ...styles }}
      onClick={e => {
        editMode && stopEventPropagation(e);
      }}
    >
      <span contentEditable={Boolean(editMode)} style={colorStyles} className={classNames.editInputClassName} onInput={handleInput} onBlur={handleBlur} onKeyUp={handleKeyUp} />
      <span className="value-output" hidden style={colorStyles}>
        {metas.hideZeroValues && formattedNumberIsZero(childValue.toString()) ? '' : children}
      </span>
    </div>
  );
};

const defaultStyles = {
  display: 'inline-flex',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: '30px'
} as React.CSSProperties;

export default EditableValue;
