import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { HoldingSetLookup } from '@/common/types/entity/HoldingSetLookup';
import { mapHoldingSets } from '@/api/getUserHoldingSets';

export default (): Promise<HoldingSetLookup[]> =>
  requestApi({
    service: 'client',
    method: 'get',
    url: `/api/holdingSets`,
    errors: false
  }).then((response: AxiosResponse | undefined) => {
    return mapHoldingSets(response!.data);
  });
