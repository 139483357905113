import { Platform, PlatformPolyfill } from '@/common/types/Platform';
import React from 'react';

type UndoCallback = Function;
type RedoCallback = Function;

type Navigator = globalThis.Navigator & {
  userAgentData?: { platform: Platform };
};

const useUndoRedo = (undoCb: UndoCallback, redoCb: RedoCallback) => {
  const handler = React.useCallback(
    (ev: KeyboardEvent) => {
      const _navigator = navigator as Navigator;

      let ctrlKey;

      if (_navigator.userAgentData) {
        ctrlKey = _navigator.userAgentData.platform === Platform.MAC_OS ? ev.metaKey : ev.ctrlKey;
      } else {
        ctrlKey = _navigator.platform === PlatformPolyfill.MAC_OS ? ev.metaKey : ev.ctrlKey;
      }

      if (ctrlKey && ev.key === 'z') {
        undoCb();
      } else if (ev.shiftKey && ctrlKey && ev.key === 'Z') {
        redoCb();
      }
    },
    [undoCb, redoCb]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [handler]);
};

export { useUndoRedo };
