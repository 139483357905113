import { useRecoilValue } from 'recoil';
import { siteTreeEntryState } from '@/modules/App/recoil/app.atoms';

export enum PageTemplate {
  MESSAGES = 'MESSAGES',
  RISKMETRICS = 'RISKMETRICS',
  OWNERSHIP = 'OWNERSHIP',
  CHARTS = 'CHARTS',
  SINGLE_REPORT = 'SINGLE_REPORT',
  REPORTS_CENTRE_V3 = 'REPORTS_CENTRE_V3',
  REDIRECT = 'REDIRECT',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  APP_MENU = 'APP_MENU',
  APP_MENU_V3 = 'APP_MENU_V3',
  TOP_LEVEL_NAVIGATION_ITEM = 'TOP_LEVEL_NAVIGATION_ITEM',
  PORTFOLIO_VIEW = 'PORTFOLIO_VIEW',
  PORTFOLIO_VIEW_LEGAL_ENTITY = 'PORTFOLIO_VIEW_LEGAL_ENTITY',
  DOCUMENT_MANAGEMENT_SYSTEM = 'DOCUMENT_MANAGEMENT_SYSTEM',
  NOTIFICATIONS = 'NOTIFICATIONS',
  TASK_MANAGEMENT_SYSTEM = 'TASK_MANAGEMENT_SYSTEM',
  NOTE_MANAGEMENT_SYSTEM = 'NOTE_MANAGEMENT_SYSTEM',
  ASSET_VIEW = 'ASSET_VIEW',
  CONTACTS_LIST = 'CONTACTS_LIST',
  CONTACTS_LIST_COMPANIES = 'CONTACTS_LIST_COMPANIES',
  ONBOARDING_ADD_ASSETS = 'ONBOARDING_ADD_ASSETS',
  ONBOARDING_OWNERSHIP_MAP = 'ONBOARDING_OWNERSHIP_MAP',
  TOP_LEVEL_NAVIGATION_ITEM_SIDER_V3 = 'TOP_LEVEL_NAVIGATION_ITEM_SIDER_V3',
  CUSTODIAL_DATA_API_TOKEN = 'CUSTODIAL_DATA_API_TOKEN',
  CUSTODIAL_DATA_DASHBOARD = 'CUSTODIAL_DATA_DASHBOARD',
  CUSTODIAL_DATA_API_DOCS = 'CUSTODIAL_DATA_API_DOCS',
  CUSTODIAL_DATA_ENTITY_LIST = 'CUSTODIAL_DATA_ENTITY_LIST',
  CUSTODIAL_DATA_LOGS = 'CUSTODIAL_DATA_LOGS',
  ANALYTICS_REPORT = 'ANALYTICS_REPORT',
  LEGAL_ENTITY_V2_VIEW = 'LEGAL_ENTITY_V2_VIEW',
  ASSET_V2_VIEW = 'ASSET_V2_VIEW',
  CUSTODIED_PORTFOLIO_V2_VIEW = 'CUSTODIED_PORTFOLIO_V2_VIEW',
  USER_PORTFOLIO_V2_VIEW = 'USER_PORTFOLIO_V2_VIEW',
  REVIEW_BOARD_V2_VIEW = 'REVIEW_BOARD_V2_VIEW'
}

export const isErpRoute = (route: PageTemplate) => {
  return [
    PageTemplate.CONTACTS_LIST,
    PageTemplate.CONTACTS_LIST,
    PageTemplate.DOCUMENT_MANAGEMENT_SYSTEM,
    PageTemplate.ONBOARDING_ADD_ASSETS,
    PageTemplate.ONBOARDING_OWNERSHIP_MAP,
    PageTemplate.LEGAL_ENTITY_V2_VIEW,
    PageTemplate.ASSET_V2_VIEW,
    PageTemplate.CUSTODIED_PORTFOLIO_V2_VIEW,
    PageTemplate.USER_PORTFOLIO_V2_VIEW,
    PageTemplate.REVIEW_BOARD_V2_VIEW
  ].includes(route);
};

export const isRedirect = (route: PageTemplate) => {
  return route === PageTemplate.REDIRECT;
};

export default (): PageTemplate => {
  const siteTreeEntry = useRecoilValue(siteTreeEntryState);

  return siteTreeEntry?.view as PageTemplate;
};
