import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { TickRate } from '../VisualSpecificProps';
import { FirstLastPoints } from './getFirstAndLastPoints';

dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);

export const buildTicks = (tickRate: TickRate, { firstPoint, lastPoint }: FirstLastPoints): number[] | undefined => {
  if (tickRate === TickRate.auto) {
    return undefined;
  }

  if (!(tickRate in TickRate)) {
    return undefined;
  }

  if (typeof firstPoint !== 'number' || typeof lastPoint !== 'number') {
    return [];
  }

  let cursorDate = dayjs
    .unix(firstPoint / 1000)
    .utc()
    .subtract(1, 'day')
    .startOf('day');
  const lastDate = dayjs
    .unix(lastPoint / 1000)
    .add(1, 'day')
    .utc();

  let rate: dayjs.UnitType;
  switch (tickRate) {
    case TickRate.year_starts:
    case TickRate.year_ends:
      rate = 'year';
      break;
    case TickRate.quarter_starts:
    case TickRate.quarter_ends:
      rate = 'quarter' as dayjs.UnitType;
      break;
    case TickRate.month_starts:
    case TickRate.month_ends:
      rate = 'month';
      break;
    case TickRate.week_starts:
    case TickRate.week_ends:
      rate = 'week' as dayjs.UnitType;
      break;
    default:
      rate = 'day';
  }

  let startOrEnd: 'startOf' | 'endOf';
  switch (tickRate) {
    case TickRate.year_starts:
    case TickRate.quarter_starts:
    case TickRate.month_starts:
    case TickRate.week_starts:
      startOrEnd = 'startOf';
      break;
    default:
      startOrEnd = 'endOf';
  }

  const ticks: number[] = [];
  while (cursorDate.isBefore(lastDate)) {
    const day = cursorDate[startOrEnd](rate).startOf('day');
    const weekDay = startOrEnd === 'startOf' ? getFirstWeekDay(day) : getLastWeekDay(day);
    const newTickValue = weekDay.utc().valueOf();
    if (ticks[ticks.length - 1] !== newTickValue) {
      ticks.push(newTickValue);
    }
    cursorDate = cursorDate.add(1, rate);
  }

  if (startOrEnd === 'endOf') {
    // assert the last point has a tick in the same month(week/quarter/...)
    const lastTick = ticks[ticks.length - 1];
    const lastTickMonth = dayjs
      .unix(lastTick / 1000)
      .utc()
      .get(rate);
    const lastPointMonth = dayjs
      .unix(lastPoint / 1000)
      .utc()
      .get(rate);

    if (lastTickMonth !== lastPointMonth) {
      ticks.push(lastPoint);
    }
  }

  return ticks;
};

const getFirstWeekDay = (date: dayjs.Dayjs): dayjs.Dayjs => {
  if (date.day() === 6) {
    return date.add(2, 'days');
  }
  if (date.day() === 0) {
    return date.add(1, 'days');
  }
  return date;
};

const getLastWeekDay = (date: dayjs.Dayjs): dayjs.Dayjs => {
  if (date.day() === 6) {
    return date.subtract(1, 'days');
  }
  if (date.day() === 0) {
    return date.subtract(2, 'days');
  }
  return date;
};
