import { requestApi } from '@/core';

interface PropertyType {
  name: string;
  displayValue: string;
}

export const getPropertyTypes = (): Promise<PropertyType[]> =>
  requestApi({
    method: 'GET',
    service: 'client',
    url: `/api/onboarding/propertyTypes`
  }).then(({ data }: any) => data);
