import { selector } from 'recoil';
import type { RawReportStylesConfig } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import type { CompanyBrandingRawStyles } from '@/common/types/app/User';
import { sesameUser } from '@/recoil/users';

type ParsedCompanyStyles = {
  styles: RawReportStylesConfig;
  theme: string | undefined;
  applyStylesInWebview: boolean | undefined;
};

const parseCompanyStyles = (styles: CompanyBrandingRawStyles): RawReportStylesConfig => {
  return {
    callOutLabel: {
      fontColor: styles.callOutLabelFontColor,
      fontStyle: styles.callOutLabelFontStyle
    },
    callOutValue: {
      fontColor: styles.callOutValueFontColor,
      fontStyle: styles.callOutValueFontStyle
    },
    others: {
      hideSortableIcons: styles.hideSortableIcons
    },
    wrapper: {
      backgroundEnabled: styles.backgroundColorEnabled,
      backgroundColor: styles.backgroundColor,
      borderEnabled: styles.borderStyleEnabled,
      shadowEnabled: styles.backgroundShadowEnabled,
      borderColor: styles.borderColor,
      borderStyle: styles.borderStyle
    },
    chart: {
      backgroundEnabled: styles.chartBackgroundEnabled,
      backgroundColor: styles.chartBackgroundColor,
      borderColor: styles.chartBorderColor,
      borderEnabled: styles.chartBorderEnabled,
      connectorColor: styles.chartConnectorColor,
      gridLineColor: styles.chartGridLineColor,
      plotShadow: styles.chartPlotShadow,
      colorScheme: styles.chartColorScheme
    },
    title: {
      fontColor: styles.titleFontColor,
      fontStyle: styles.titleFontStyle,
      align: styles.titleAlign,
      nowrap: styles.titleNowrap
    },
    label: {
      fontColor: styles.fontColor,
      fontStyle: styles.fontStyle
    },
    legend: {
      fontColor: styles.legendFontColor,
      fontStyle: styles.legendFontStyle
    },
    table: {
      headerFontStyle: styles.tableHeaderFontStyle,
      headerFontColor: styles.tableHeaderFontColor,
      headerBackgroundColor: styles.tableHeaderBackgroundColor,
      bodyFontStyle: styles.tableRowFontStyle,
      bodyFontColor: styles.tableRowFontColor,
      bodyAlternateBackgroundColor: styles.tableRowAlternateBackgroundColor,
      bodyBackgroundColor: styles.tableRowBackgroundColor,
      firstColumnTextAlign: styles.tableFirstColumnTextAlign,
      footerBackgroundColor: styles.tableFooterBackgroundColor,
      footerFontColor: styles.tableFooterFontColor,
      footerFontStyle: styles.tableFooterFontStyle,
      groups: styles.tableGroups,
      headerBorder: styles.tableHeaderBorder,
      headerBorderColor: styles.tableHeaderBorderColor,
      hideHeaderBoxShadow: styles.tableHideHeaderBoxShadow,
      lastColumnTextAlign: styles.tableLastColumnTextAlign,
      textAlign: styles.tableTextAlign,
      colorScheme: styles.tableColorScheme
    },
    container: {
      backgroundColor: styles.containerBackgroundColor,
      backgroundEnabled: styles.containerBackgroundEnabled,
      borderColor: styles.containerBorderColor,
      borderEnabled: styles.containerBorderEnabled,
      borderRadius: styles.containerBorderRadius,
      borderStyle: styles.containerBorderStyle,
      fontColor: styles.containerFontColor,
      fontStyle: styles.containerFontStyle,
      margin: styles.containerMargin,
      padding: styles.containerPadding
    }
  };
};
export const companyBranding = selector<ParsedCompanyStyles>({
  key: 'companyBranding',
  get: async ({ get }) => {
    const user = get(sesameUser);

    const jsonStyles = JSON.parse(user.company.branding.styles ?? '{}') as CompanyBrandingRawStyles;

    return {
      styles: parseCompanyStyles(jsonStyles),
      theme: jsonStyles.theme,
      applyStylesInWebview: jsonStyles.applyStylesInWebview
    };
  }
});
