import React, { useMemo } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Text } from '@/common/components/Typography/Text';
import { ActivityType } from '@/common/types/entity/Link';
import { ActivityLayout } from './CardLayout';
import type { ActivityDTO } from '@/modules/ERP/Contacts/types';
import { useIntl } from 'react-intl';

interface ActivityCardContactProps {
  activity: ActivityDTO;
  entityId: string;
  entityType: ActivityType;
}

enum LinkActivityType {
  DELETE_LINK = 'DELETE_LINK',
  CREATE_LINK = 'CREATE_LINK'
}

export const ActivityCardLink: React.FunctionComponent<ActivityCardContactProps> = React.memo(({ activity, entityId, entityType }) => {
  const translator = useIntl();

  const text = useMemo(() => {
    const creator = activity.relatedEntities.find(act => act.isCreator)!;
    const nameRight =
      activity.relatedEntities.find(act => act.type !== ActivityType.LINK && !act.isCreator && String(act.id) !== String(entityId))! ??
      activity.relatedEntities.find(act => act.type !== ActivityType.LINK && !act.isCreator);

    const nameLeft = activity.relatedEntities.find(item => String(item.id) === String(entityId))!;

    return {
      creator: creator?.name,
      nameRight: nameRight?.name ? nameRight.name : 'N/A',
      nameLeft: nameLeft?.name ? nameLeft.name : 'N/A'
    };
  }, [activity.relatedEntities, entityId]);

  const action = useMemo(() => {
    if (activity.type === LinkActivityType.CREATE_LINK) {
      return translator.formatMessage({ id: 'erp.hasCreatedLinkBetween' });
    } else {
      return translator.formatMessage({ id: 'erp.hasDeletedLinkBetween' });
    }
  }, [translator, activity.type]);

  return (
    <ActivityLayout activity={activity} icon={<LinkOutlined />}>
      <Text link>{text.creator}</Text>
      &nbsp;
      <Text>{action}</Text>
      &nbsp;
      <Text link>{text.nameLeft}</Text>
      &nbsp;
      {translator.formatMessage({ id: 'generic.and' }).toLowerCase()}
      &nbsp;
      <Text link>{text.nameRight}</Text>
    </ActivityLayout>
  );
});
