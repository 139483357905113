import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedHoldingSetInfoConfig } from '..';
import { HoldingSetInfoConfigMapped } from './types';

export default class HoldingSetInfoConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config));
  }

  getVisualConfigProps(config: HoldingSetInfoConfigMapped): ExtendedHoldingSetInfoConfig {
    return super.getVisualConfigProps(config);
  }

  setVisualConfig(config: ExtendedHoldingSetInfoConfig) {
    super.setVisualConfig(config);
  }
}
