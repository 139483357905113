import TreeMapConfig from './TreeMapConfig';
import type { ChartExtendedPoint, ExtendedRowGroup, TreemapChartOptions } from '@/modules/reporting-v2/core/components/Highcharts/types';
import { Column } from '@/modules/reporting-v2/core/Column';
import { HighChartsDataUtils } from '@/modules/reporting-v2/core/HighchartsDataUtils';
import { RowGroup } from '@/modules/reporting-v2/types/VisualEngine';

class TreeMapUtils {
  static getOptions(visual: TreeMapConfig, columns: Column[], rows: ExtendedRowGroup[]): TreemapChartOptions {
    const data = rows.map(row => {
      return {
        id: row.__id__,
        drilldown: row.__id__,
        name: row.group as string,
        y: row.cells[row.cells.length - 1] as number,
        value: Math.abs(row.cells[row.cells.length - 1] as number),
        custom: {
          data: row.data,
          column: columns[columns.length - 1]
        }
      };
    });

    const drilldownSeries = rows.flatMap(row => {
      return HighChartsDataUtils.getFlattenRows(row, undefined, (row: RowGroup) => {
        return {
          id: row.__id__,
          name: row.group,
          y: row.cells[row.cells.length - 1],
          value: Math.abs(row.cells[row.cells.length - 1] as number),
          custom: {
            data: row.data,
            column: columns[columns.length - 1]
          },
          data: row.rows.map(drilldownRow => {
            return {
              name: (drilldownRow as RowGroup).group || row.group,
              value: Math.abs(drilldownRow.cells[drilldownRow.cells.length - 1] as number),
              y: drilldownRow.cells[drilldownRow.cells.length - 1],
              drilldown: drilldownRow.__id__,
              custom: {
                data: drilldownRow.data,
                column: columns[columns.length - 1]
              }
            };
          })
        };
      });
    });

    return {
      chart: {
        type: 'treemap'
      },
      plotOptions: {
        treemap: {
          color: '#477de8',
          borderColor: 'white',
          dataLabels: {
            enabled: true,
            align: 'center',
            position: 'center',
            formatter() {
              return `
                ${this.key}<br /> <b>${HighChartsDataUtils.formatCell(
                  this.y,
                  (this.point as ChartExtendedPoint).custom.column,
                  (this.point as ChartExtendedPoint).custom.data
                )}</b>`;
            }
          },
          states: {
            hover: {
              borderColor: 'rgb(240, 247, 255)'
            }
          }
        }
      },
      tooltip: {
        pointFormatter: function () {
          const symbol = HighChartsDataUtils.getSymbolFormat(this.series);
          const formattedValue = HighChartsDataUtils.formatCell(this.y, (this as ChartExtendedPoint).custom.column, (this as ChartExtendedPoint).custom.data);
          return `<div>${symbol ?? ''} <b>${this.series.name}</b>: ${formattedValue}</div>`;
        }
      },
      series: [
        {
          name: columns[columns.length - 1].headerConfig.displayName as string,
          data,
          type: 'treemap',
          layoutAlgorithm: 'squarified',
          alternateStartingDirection: true
        }
      ],
      drilldown: {
        activeDataLabelStyle: {
          color: 'white'
        },
        series: drilldownSeries as any
      }
    };
  }
}

export { TreeMapUtils };
