import React, { useCallback, useState } from 'react';
import { Text } from '@/common/components/Typography/Text';
import { EntityLinkTypeMenu } from '@/common/types/entity/Link';
import { EditNote } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Notes/EditNote';
import { Row, Tooltip } from 'antd';
import type { NoteDTO } from '@/modules/ERP/Contacts/types';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { siteTreeState } from '@/modules/App/recoil/app.atoms';
import { getNoteUrlInfo } from '@/modules/ERP/Notes/utils';

interface LinkRedirectProps {
  note: NoteDTO;
  type: EntityLinkTypeMenu;
  entityViewedId: string;
}

const NoteLinkRedirect: React.FC<LinkRedirectProps> = ({ type, note, entityViewedId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const siteTree = useRecoilValue(siteTreeState);

  const onClickLink = useCallback(() => {
    const { pathname, searchParams } = getNoteUrlInfo(String(note.id), siteTree);

    if (!pathname) {
      return setModalOpen(true);
    }

    history.push({ pathname, search: searchParams.toString() });
  }, [history, note.id]);

  let title = note.title;
  const hasBigTitle = note.title.length > 85;
  if (hasBigTitle) {
    title = note.title.slice(0, 85) + '...';
  }

  return (
    <Row className="entity-links-single-entity" wrap={false}>
      <Text onClick={onClickLink} link>
        <Tooltip title={note.title} trigger={hasBigTitle ? undefined : []}>
          {title}
        </Tooltip>
      </Text>

      {modalOpen && <EditNote isOpen={modalOpen} setIsOpen={setModalOpen} contactId={entityViewedId} noteId={note.id} pageEntityType={type} pageEntityId={note.id} />}
    </Row>
  );
};

export { NoteLinkRedirect };
