import { useLocation } from 'react-router';
import { AssetDetailsID } from '@/modules/ERP/Asset/AssetDetails/types';
import { useRecoilValue } from 'recoil';
import { assetIdSelector } from '@/modules/ERP/Asset/AssetDetails/Recoil/assetDetails.selector';
import { isNumeric } from '@/utils/isNumeric';

export const assetDetailsIdParamKey = 'id';
export const useAssetId = (): AssetDetailsID => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const id = params.get(assetDetailsIdParamKey);
  const assetId = useRecoilValue(assetIdSelector);

  if (isNumeric(id)) {
    return Number(id);
  }

  return assetId;
};
