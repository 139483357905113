import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';

export const getLimits = (payload: Record<string, any>): Promise<Record<string, any>> =>
  requestApi({
    method: 'post',
    url: '/api/elastic/v3/stream/limits?v=1.0',
    service: 'gateway',
    data: payload
  }).then((response: AxiosResponse | undefined) => response?.data?.hits?.hits);
