/**
 * Used with translated values instead of adding one more key just for formatting
 * @param string
 */
export const capitalizeFirstLetterOfSecondWord = (string: string): string => {
  const newArr = string.split(' ');
  if (newArr.length <= 1) {
    return string;
  }
  newArr[1] = newArr[1].charAt(0).toUpperCase() + newArr[1].slice(1);
  return newArr.join(' ');
};
