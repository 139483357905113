import { selector, selectorFamily } from 'recoil';
import Elk from 'elkjs';
import { nanoid } from 'nanoid';
import { AssetFlow, AssetNameType, AssetStep, AssetType, AssetTypeCategory, EntityList, EntityTree, EntityType, FormValues, Show } from '@/modules/Onboarding/Shared/types';
import { formsState, stepState } from '@/modules/Onboarding/recoil/onboarding.atoms';
import { getCountries } from '@/modules/Onboarding/api/getCountries';
import { getCurrencies } from '@/modules/Onboarding/api/getCurrencies';
import { getPropertyTypes } from '@/modules/Onboarding/api/getPropertyTypes';
import { getInstitutions } from '@/modules/Onboarding/api/getInstitutions';
import { getAssetTypes } from '@/modules/Onboarding/api/getAssetTypes';
import { getAssetNames } from '@/modules/Onboarding/api/getAssetNames';
import { getTreeView } from '@/modules/Onboarding/api/getTreeView';
import { allExistingEntitiesSelector, flattenedUserHsets, flattenedUserHsetsByType } from '@/recoil/holdingSets';
import { HoldingSetType } from '@/common/types/entity/HoldingSetType';
import { HoldingSet } from '@/modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';

const formStateSelector = selectorFamily<FormValues, AssetFlow>({
  key: 'onboarding-flow-form-values-selector',
  get:
    flow =>
    ({ get }) => {
      const step = get(stepState(flow));
      const flowFormState = get(formsState(flow));

      return flowFormState[step] ?? {};
    },

  set:
    flow =>
    ({ set, get }, newValue) => {
      const step = get(stepState(flow));

      set(formsState(flow), previousState => {
        return {
          ...previousState,
          [step]: newValue as Record<string, AssetStep>
        };
      });
    }
});

export const personalEntitySelector = selector<EntityList | undefined>({
  key: 'onboarding-personal-entity-selector',
  get: async ({ get }) => {
    const allEntities = get(allExistingEntitiesSelector({ show: Show.ALL, entityType: EntityType.PERSONAL, size: 999 }));

    const personalEntity = allEntities.find((entity: EntityList) => entity.name.endsWith('(YOU)'));

    if (personalEntity) {
      return personalEntity;
    }

    return allEntities[0];
  }
});

export const userEntitiesSelector = selectorFamily<HoldingSet[], HoldingSetType[] | null>({
  key: 'onboarding-entities-selector',
  get:
    entityType =>
    async ({ get }) => {
      if (entityType?.length === 1) {
        const allEntities = get(flattenedUserHsetsByType(entityType[0]));

        return allEntities.filter(entity => !entity.name.endsWith('(YOU)'));
      }
      if (entityType?.length && entityType?.length > 1) {
        const allEntities = get(flattenedUserHsetsByType(entityType[0]));

        return allEntities.filter(entity => !entity.name.endsWith('(YOU)') && entityType.includes(entity.type as HoldingSetType));
      }

      return get(flattenedUserHsets);
    }
});

export const entitiesSelector = selectorFamily<EntityList[], EntityType[] | null>({
  key: 'onboarding-entities-selector',
  get:
    entityType =>
    async ({ get }) => {
      if (entityType?.length! > 0) {
        const allEntities = get(allExistingEntitiesSelector({ show: Show.ACTIVE, entityType: entityType.join(', '), size: 9999 }));

        return allEntities.filter((entity: EntityList) => !entity.name.endsWith('(YOU)'));
      }

      return get(allExistingEntitiesSelector({ show: Show.ACTIVE, size: 9999 }));
    }
});

export const currenciesSelector = selector({
  key: 'onboarding-currencies-selector',
  get: async () => {
    return getCurrencies();
  }
});

export const countriesSelector = selector({
  key: 'onboarding-countries-selector',
  get: async () => {
    return getCountries();
  }
});

export const propertyTypesSelector = selector({
  key: 'onboarding-property-types-selector',
  get: async () => {
    return getPropertyTypes();
  }
});

export const institutionsSelector = selector({
  key: 'onboarding-institutions-selector',
  get: async () => {
    return getInstitutions();
  }
});

export const assetTypesSelector = selectorFamily<AssetType[], AssetTypeCategory | null>({
  key: 'onboarding-asset-types-selector',
  get: category => async () => {
    return getAssetTypes(category);
  }
});

export const assetNamesAddon = selectorFamily<AssetNameType[], string>({
  key: 'onboarding-asset-names-selector',
  get: assetType => async () => {
    return getAssetNames(assetType);
  }
});

export const treeviewSelector = selectorFamily<any, number | undefined>({
  key: 'onboarding-treeview-selector',
  get: holdingSetId => async () => {
    const nodeWidth = 100;
    const nodeHeight = 50;
    let nodesFromRecoil: any[] = [];
    let edgesFromRecoil: any[] = [];

    const elk = new Elk({
      defaultLayoutOptions: {
        'elk.algorithm': 'mrtree',
        'elk.spacing.nodeNode': '300'
      }
    });

    if (!holdingSetId) {
      return { edgesFromRecoil, nodesFromRecoil };
    }

    const treeView = await getTreeView(holdingSetId);

    const convertToEdge = (tree: EntityTree, parentId: string, index: number) => {
      return {
        id: 'e-' + tree.id + parentId + nanoid(),
        source: parentId.toString(),
        sourceHandle: 'a',
        target: tree.id.toString(),
        type: 'smoothstep'
      };
    };

    const convertToNode = (tree: EntityTree) => {
      return {
        id: tree.id.toString(),
        data: { name: tree.name, assetType: tree.type },
        type: 'custom',
        position: { x: 0, y: 0 },
        dragHandle: '.no-drag'
      };
    };

    const convertToReactFlow = (tree: EntityTree) => {
      tree.children.forEach((children, index) => {
        if (!children.hidden) {
          const currentNode = convertToNode(children);
          const currentEdge = convertToEdge(children, tree.id ?? 0, index);
          if (!nodesFromRecoil.find(node => node.id === currentNode.id)) {
            nodesFromRecoil = [...nodesFromRecoil, currentNode];
          }
          edgesFromRecoil = [...edgesFromRecoil, currentEdge];
          if (children.children.length) {
            convertToReactFlow(children);
          }
        }
      });
    };

    const getLayoutedElements = async () => {
      const newGraph = await elk.layout({
        id: 'root',
        children: nodesFromRecoil.map((item: any) => ({
          ...item,
          width: nodeWidth,
          height: nodeHeight
        })),
        edges: edgesFromRecoil.map((item: any) => ({
          ...item
        }))
      });

      nodesFromRecoil.forEach((node: any) => {
        const nodeWithPosition = newGraph?.children?.find(n => n.id === node.id);
        // match the react-flow position
        node.position = {
          x: nodeWithPosition!.x!,
          y: nodeWithPosition!.y!
        };
      });
    };

    if (treeView) {
      const firstNode = convertToNode(treeView);
      nodesFromRecoil = [...nodesFromRecoil, firstNode];
      convertToReactFlow(treeView);
      await getLayoutedElements();
    }

    return { edgesFromRecoil, nodesFromRecoil };
  }
});

export { formStateSelector };
