import { isEmptyString } from '@/utils/isEmptyString';
import z, { ZodString, ZodStringCheck } from 'zod';
import ipaddr from 'ipaddr.js';

export const LABEL_MAX_SIZE = 255;

/**
 * Returns undefined if the value is an empty string.
 */
export const stripEmpty = z.literal('').transform(() => undefined);

/**
 * Returns the parsed value if the schema is valid, otherwise returns the original value.
 */
export const preprocessIfValid = (schema: z.ZodTypeAny) => (val: unknown) => {
  const result = schema.safeParse(val);
  if (result.success) return result.data;
  return val;
};

/**
 * Converts empty string to undefined before passing the result to the schema.
 *
 * Useful to properly validate empty strings in required fields.
 * @param schema
 * @returns
 */
export const text = <T extends z.ZodTypeAny>(schema?: T) => {
  return z.preprocess(preprocessIfValid(stripEmpty), schema ?? z.string());
};

/**
 * Regex Schema prevent strings with ONLY whitespace. It fails if the string is only whitespace.
 * @param message Error message to display when the regex fails.
 * @param schema Zod Schema to apply the regex. Must be ZodString.
 */
export const noOnlyWhitespace = <T extends z.ZodString>(message: ErrMessage = { message: 'Must not be blank!' }, schema: T = z.string() as T) => schema.regex(/^$|\S+/gim, message);

/**
 * Regex Schema to prevent strings containing scripts. It fails if the string contains any of the following:
 * - HTML Tags (eg. \<div>, \<script>)
 * - Javascript (eg. onload=)
 * - Javascript (eg. src='javascript:', src="javascript:", src=javascript:)
 * - Javascript (eg. eval())
 * - Javascript (eg. expression())
 * - Javascript (eg. onclick=)
 * - Javascript (eg. onfocus=)
 * @param message Error message to display when the regex fails.
 * @param schema Zod Schema to apply the regex. Must be ZodString.
 */
export const safeString = <T extends z.ZodString>(message: ErrMessage = { message: 'Invalid input' }, schema: T = z.string() as T) =>
  schema
    .regex(/^(?!.*(<\/?(\w*?)\/?>)).*$/gim, message)
    .regex(/^(?!.*(onload\(.*?\)=)).*$/gim, message)
    .regex(/^(?!.*(src\s*=\s*'(.*?)')).*$/gim, message)
    .regex(/^(?!.*(src\s*=\s*"(.*?)")).*$/gim, message)
    .regex(/^(?!.*(src\s*=\s*([^>]+))).*$/gim, message)
    .regex(/^(?!.*(eval\((.*?)\))).*$/gim, message)
    .regex(/^(?!.*(expression\((.*?)\))).*$/gim, message)
    .regex(/^(?!.*(onclick(.*?)=)).*$/gim, message)
    .regex(/^(?!.*(onfocus(.*?)=)).*$/gim, message);

/**
 * Regex Schema that only allows letters (from any language)
 * @param message Error message to display when the regex fails.
 * @param schema Zod Schema to apply the regex. Must be ZodString.
 */
export const simple = <T extends z.ZodString>(message: ErrMessage = { message: 'Only letters allowed' }, schema: T = z.string() as T) =>
  schema.regex(/^(?!.*([^\p{Letter}\p{Mark}])).*$/iu, message);
/**
 * Regex Schema that only allows letters (from any language), whitespaces or ` -. `
 * @param message Error message to display when the regex fails.
 * @param schema Zod Schema to apply the regex. Must be ZodString.
 */
export const simpleName = <T extends z.ZodString>(message: ErrMessage = { message: 'Only letters or -. allowed' }, schema: T = z.string() as T) =>
  schema.regex(/^(?!.*([^\p{Letter}\p{Mark}\s\-.])).*$/iu, message);

/**
 * Regex Schema that only allows numbers, "+" or `-`
 * @param message Error message to display when the regex fails.
 * @param schema Zod Schema to apply the regex. Must be ZodString.
 */
export const phoneNumber = <T extends z.ZodString>(message: ErrMessage = { message: 'Only numbers, spaces, "-", "()" and "+" allowed' }, schema: T = z.string() as T) =>
  schema.regex(/^[\d\s()+-]*$/iu, message);

/**
 * Regex Schema that only allows letters, numbers, spaces, or hyphens
 * @param message Error message to display when the regex fails.
 * @param schema Zod Schema to apply the regex. Must be ZodString.
 */
export const postalCode = <T extends z.ZodString>(message: ErrMessage = { message: 'Only letters, numbers, spaces, or hyphens allowed' }, schema: T = z.string() as T) =>
  schema.regex(/^(?!.*([^\p{Letter}\p{Mark}\d\s-])).*$/iu, message);

/**
 * Regex Schema that only allows letters (from any language), whitespaces, numbers or ` -. `
 * @param message Error message to display when the regex fails.
 * @param schema Zod Schema to apply the regex. Must be ZodString.
 */
export const simpleNameWithNumbers = <T extends z.ZodString>(message: ErrMessage = { message: 'Only letters, numbers or -. allowed' }, schema: T = z.string() as T) =>
  schema.regex(/^(?!.*([^\p{Letter}\p{Mark}\d\s\-.])).*$/iu, message);

/**
 * Regex Schema that only allows letters or ` -_./ `
 * @param message Error message to display when the regex fails.
 * @param schema Zod Schema to apply the regex. Must be ZodString.
 */
export const codeString = <T extends z.ZodString>(
  message: ErrMessage = {
    message: 'Only letters, numbers or -_./ allowed'
  },
  schema: T = z.string() as T
) => schema.regex(/^(?!.*([^a-z0-9_\-./\\])).*$/i, message);
export const numeric = <T extends z.ZodString>(message: ErrMessage = { message: 'Only numbers allowed' }, schema: T = z.string() as T) => schema.regex(/^\d*$/gim, message);

export const idNumberic = <T extends z.ZodNumber>(
  message: ErrMessage = {
    message: 'Must be a whole number and non-negative'
  },
  schema: T = z.number() as T
) => {
  return z.preprocess((value, ctx) => {
    return handleCtxIfError(numeric(message, z.string({ coerce: true })), value, ctx);
  }, schema);
};

export const ipAddress = (
  message: ErrMessage = {
    message: 'Invalid IP Address'
  }
) => {
  return z.custom(value => {
    try {
      return ipaddr.isValid(value as string) || ipaddr.parseCIDR(value as string);
    } catch {
      return false;
    }
  }, message);
};

type ErrMessage = string | { message?: string };

/**
 * Extends ZodString to add custom validation methods for strings.
 */
class CustomString extends ZodString {
  /**
   * Parses values using the ZodString parser and adds the trim check at the end to always trim values out-of-the-box after all validations.
   * @param input
   * @returns
   */
  override _parse(input: z.ParseInput): z.ParseReturnType<string> {
    this._addCheck({ kind: 'trim' });
    return new ZodString({
      ...this._def,
      checks: this._def.checks.concat({ kind: 'trim' })
    })._parse(input);
  }
  static override create({ required_error = 'Must be provided!', ...params }: z.RawCreateParams & { coerce?: true } = {}): CustomString {
    return new CustomString({
      checks: [],
      typeName: z.ZodFirstPartyTypeKind.ZodString,
      coerce: params?.coerce ?? false,
      ...processCreateParams({ ...params, required_error })
    });
  }
  override _addCheck(check: ZodStringCheck) {
    return new CustomString({
      ...this._def,
      checks: [...this._def.checks, check]
    });
  }
  noOnlyWhitespace(message?: ErrMessage) {
    return noOnlyWhitespace(message, new CustomString(this._def)) as CustomString;
  }
  safeString(message?: ErrMessage) {
    return safeString(message, new CustomString(this._def)) as CustomString;
  }
  simple(message?: ErrMessage) {
    return simple(message, new CustomString(this._def)) as CustomString;
  }
  phoneNumber(message?: ErrMessage) {
    return phoneNumber(message, new CustomString(this._def)) as CustomString;
  }
  postalCode(message?: ErrMessage) {
    return postalCode(message, new CustomString(this._def)) as CustomString;
  }
  simpleName(message?: ErrMessage) {
    return simpleName(message, new CustomString(this._def)) as CustomString;
  }
  simpleNameWithNumbers(message?: ErrMessage) {
    return simpleNameWithNumbers(message, new CustomString(this._def)) as CustomString;
  }
}

export const string = CustomString.create;

/**
 * Utlity to validate a string as array and parse to expected values.
 *
 * Eg. an array of numbers from string "1,2,3" outputs to [1,2,3] using `arrayInString(z.array(z.number()))`
 * @param schema Expected Zod Schema Array rules.
 * @param splitter Delimeter that forms the array of values.
 * @returns
 */
export const arrayInString = <T extends Zod.ZodOptional<Zod.ZodArray<Zod.ZodTypeAny>> | Zod.ZodArray<Zod.ZodTypeAny>>(schema: T, splitter: string = ',') => {
  const preprocess = z.preprocess((value, ctx) => {
    if (!value || typeof value !== 'string') {
      return undefined;
    }

    const splitValue = value.split(splitter).filter(it => !isEmptyString(it));
    return handleCtxIfError(schema, splitValue, ctx);
  }, schema);
  return Object.assign(preprocess, {
    /**
     * Transforms the Zod Result as string
     * @param separator Delimeter to join the array of values.
     * @returns Returns a string of values joined by the separator.
     */
    asString(separator: string = splitter) {
      return (this as unknown as typeof preprocess).transform(v => v?.join(separator));
    }
  });
};

// Temporarily `any` until we figure out a better way of handling the lifecycle of DateRangePicker state.
export const dateRange = z.array(z.any()).length(2);

/**
 * Duplicate from Zod codebase. Can be ignored.
 */
function processCreateParams(params: z.RawCreateParams): z.ProcessedCreateParams {
  if (!params) return {};
  const { errorMap, invalid_type_error, required_error, description } = params;
  if (errorMap && (invalid_type_error || required_error)) {
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  }
  if (errorMap) return { errorMap: errorMap, description };
  const customMap: z.ZodErrorMap = (iss, ctx) => {
    if (iss.code !== 'invalid_type') return { message: ctx.defaultError };
    if (typeof ctx.data === 'undefined') {
      return { message: required_error ?? ctx.defaultError };
    }
    return { message: invalid_type_error ?? ctx.defaultError };
  };
  return { errorMap: customMap, description };
}

export function handleCtxIfError(schema: z.ZodTypeAny, value: unknown, ctx: z.RefinementCtx, path?: (string | number)[]) {
  const result = schema.safeParse(value);
  if (result.success) {
    return value;
  }
  return result.error.issues.map(it =>
    ctx.addIssue({
      code: 'custom',
      message: it.message,
      path
    })
  );
}
