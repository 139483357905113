import type RawTextImageConfig from './types';

export const config: RawTextImageConfig = {
  type: 'visual',
  bgAlign: 'center',
  bgVerticalAlign: 'center',
  bgSize: 'contain',
  text: '',
  src: '',
  fields: {
    columns: [],
    defaultColumns: []
  }
};
