import { requestApi } from '@/core';
import { TaskDTO } from '@/modules/ERP/Contacts/types';

export const getTaskById = (id: string): Promise<TaskDTO> => {
  return requestApi({
    service: 'collaboration',
    method: 'get',
    url: `/api/task/${id}`,
    errors: false
  }).then(resp => {
    return resp.data;
  });
};
