import DOMPurify from 'dompurify';
import { htmlContentIsEmpty } from './htmlContentIsEmpty';

export const handleHtmlContent = (onContentChange: (content: string) => void) => (content: string) => {
  const textAreaIsEmpty = htmlContentIsEmpty(content);
  if (textAreaIsEmpty) {
    onContentChange('');
  } else {
    onContentChange(DOMPurify.sanitize(content));
  }
};
