import React from 'react';
import { withSmallSuspense } from '@/common/suspense';
import { StarRating } from '@/modules/reporting-v2/core/components/StarRating';
import { useQuintileScore } from '@/modules/reporting-v2/core/visuals/Gauge/QuintileScore/useQuintileScore';
import { FormattedMessage } from 'react-intl';

type QuintileScoreProps = {
  visualThickness: number;
};

const QuintileScore: React.FC<QuintileScoreProps> = withSmallSuspense(({ visualThickness }) => {
  const quintileScore = useQuintileScore();

  const sizeRatio = visualThickness > 10 ? 1 : visualThickness;

  if (typeof quintileScore !== 'number') {
    return <FormattedMessage id="report.visual.gauge.unableToFindScore" />;
  }

  return <StarRating numberOfStars={5} rating={quintileScore} sizeRatio={sizeRatio} />;
});

export { QuintileScore };
