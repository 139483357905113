import { ConversationType } from '@/common/types/entity/Conversation';
import React, { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { loggedUserSelector, usersForCurrentUsers } from '@/common/auth/recoil/user.selector';
import { useIntl } from 'react-intl';
import { handleHtmlContent } from '@/utils/handleHtmlContent';
import { userListMentionQuill } from '@/modules/ERP/Common/utils';
import { message, Modal } from 'antd';
import patchComment from '@/modules/Conversation/api/patchComment';
import { comments as commentsAtom } from '@/recoil/conversations';
import ReactQuill from 'react-quill';
import 'quill-mention';
import '../style.css';

interface EditCommentProps {
  conversationId: number;
  entityId: number;
  content: string;
  refType: ConversationType;
  refId: number | string;
  setEntityToEdit: React.Dispatch<SetStateAction<number | null>>;
}

export const EditComment: React.FunctionComponent<EditCommentProps> = ({ conversationId, entityId, content, refType, refId, setEntityToEdit }) => {
  const [mention, setMention] = useState({});
  const loggedUser = useRecoilValue(loggedUserSelector);
  const users = useRecoilValue(usersForCurrentUsers(loggedUser.accountId));
  const reference = useRef<string>(content);
  const translator = useIntl();

  const handleContentChange = useCallback((content: string) => (reference.current = content), []);
  const handleChangeQuill = useCallback((content: string) => handleHtmlContent(handleContentChange)(content), [handleContentChange]);

  useEffect(() => {
    setMention({
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@'],
      defaultMenuOrientation: 'top',
      source: (searchTerm: any, renderList: any) => userListMentionQuill(searchTerm, renderList, users)
    });
  }, [users]);

  const handleSaveComment = useRecoilCallback(
    ({ refresh }) =>
      () => {
        if (!reference.current.replace(/<[^>]*>?/gm, '')) {
          message.warning('Please type a comment');
          return;
        }
        return patchComment(conversationId, entityId, {
          content: reference.current
        }).then(() => {
          reference.current = '';
          message.success(translator.formatMessage({ id: 'generic.successfullySaved' }));
          setTimeout(() => {
            refresh(
              commentsAtom({
                refType,
                refId,
                withoutNewCommentFromOldModule: true
              })
            );
          }, 1000);
          setEntityToEdit(null);
        });
      },
    [reference.current, conversationId, entityId, refType]
  );

  return (
    <Modal open={!!entityId} onCancel={() => setEntityToEdit(null)} onOk={handleSaveComment} okText={translator.formatMessage({ id: 'generic.save' })}>
      <ReactQuill modules={{ mention: mention }} value={content} onChange={handleChangeQuill} />
    </Modal>
  );
};
