import { CloseOutlined } from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import React, { useState } from 'react';
import './style.css';
import { SearchInput } from 'ui-sesame-components';

export type Props = {
  id?: string;
  items: { label: any; value: string }[];
  multiple?: boolean;
  placeholder: string;
  onChange: (value?: string[]) => void;
  value: string[];
  disableEmptyValue: boolean | undefined;
};

const SelectList = ({ items, multiple, placeholder, onChange, value = [], disableEmptyValue = false, id }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const allowValueClear = !disableEmptyValue;

  const onClick = (newValue: any) => {
    if (!multiple) {
      const sameValue = value[0] === newValue;
      if (!sameValue) {
        onChange([newValue]);
        return;
      }
      if (allowValueClear) {
        onChange([]);
      }
      return;
    }

    if (value.includes(newValue)) {
      const filteredValue = value.filter(item => item !== newValue);
      if (filteredValue.length || allowValueClear) {
        onChange(filteredValue);
      }
    } else {
      onChange([...value, newValue]);
    }
  };

  const renderItems = (): MenuProps['items'] => {
    return items
      .filter(item => {
        if (typeof item.label !== 'string' || typeof item.value !== 'string') return false;
        return item.label.toLowerCase().includes(searchValue.toLowerCase());
      })
      .map(item => {
        const isItemSelected = value.includes(item.value);
        const showCloseIcon = isItemSelected && (value.length > 1 || allowValueClear);
        return {
          key: item.value,
          className: isItemSelected ? 'ant-menu-item-selected' : '',
          onClick: () => onClick(item.value),
          label: (
            <>
              <span>{item.label}</span>
              {showCloseIcon && <CloseOutlined style={{ marginLeft: 8 }} />}
            </>
          )
        };
      });
  };

  return (
    <>
      <div style={{ paddingBottom: '14px', borderBottom: '1px solid rgba(0,0,0,0.15)' }}>
        <SearchInput id={id} onChange={e => setSearchValue(e.target.value)} placeholder={placeholder} />
      </div>
      <Menu items={renderItems()} className="selectlist" selectable={false} style={{ paddingTop: '14px', borderRight: 'none', maxHeight: 200, overflowY: 'auto' }} />
    </>
  );
};

export default SelectList;
