import React from 'react';
import DOMPurify from 'dompurify';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import Visual, { initialState } from '@/modules/reporting-v2/core/visuals/Visual/index';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { config } from './config';
import schema from './schema.json';
import TextImageConfig from './TextImageConfig';
import RawTextImageConfig from './types';
import { RouteComponentProps } from 'react-router-dom';
import { IVisualCoreProps } from '@/modules/reporting-v2/types/VisualCoreTypes';
import { findAllMeasureCodesInHTMLText } from '@/modules/report-builder/components/Forms/Visual/Schema/WysiwigTextAreaWrapper/findAllMeasureCodesInHTMLText.ts';
import './style.css';

class TextImage extends Visual {
  Loader = Loaders.CallOut;

  constructor(props: RouteComponentProps & IVisualCoreProps) {
    super(props);

    this.state = {
      ...initialState,
      textWithMeasures: ''
    } as any;
  }

  renderInteractivity() {
    return super.renderInteractivity().slice(2);
  }

  getSchema() {
    return schema;
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as TextImageConfig;
  }

  static configMapper(visualConfig: RawTextImageConfig) {
    const { fields, ...rest } = visualConfig;
    const columns = visualConfig.text ? findAllMeasureCodesInHTMLText(visualConfig.text) : [];

    return {
      ...rest,
      columns: mapVisualConfigColumns({
        ...fields,
        columns: columns,
        defaultColumns: columns
      })
    };
  }

  getMappedValues(columns: any[]) {
    const rows = this.props.visual.data.rows;
    const data = rows[0]?.data || [];

    return columns.map(column => {
      const value = data[column.field.getElasticPath() + column.id] || data[column.field.getElasticPath()];

      return {
        code: column.code,
        value,
        formattedValue: VisualEngine.formatCell(value, column, data)
      };
    });
  }

  replaceMeasuresByValues(text: string): string {
    let resultText = text;
    const columns = this.props.visual.columns.filter(col => col.isDefault);
    const valuesMappedByCode = this.getMappedValues(columns);
    const measuresToReplace = findAllMeasureCodesInHTMLText(resultText);

    measuresToReplace.forEach(measureCode => {
      const value = valuesMappedByCode.find(value => value.code === measureCode)?.formattedValue || 'N/A';
      const searchValue = '<span class="ql-label">' + measureCode + '<button></button><span> </span></span>';

      resultText = resultText.replaceAll(searchValue, value as any);
    });

    return resultText;
  }

  async componentDidMount() {
    super.componentDidMount();

    let htmlText = (this.props.visual as TextImageConfig).text;
    if (!htmlText) {
      return;
    }

    this.setState({ ...this.state, textWithMeasures: htmlText });
  }

  renderBody() {
    const { src, bgAlign, bgSize, bgVerticalAlign } = this.props.visual as TextImageConfig;

    const resultText = this.replaceMeasuresByValues((this.state as any).textWithMeasures);

    const hasNoImage = !src || src.length === 0;

    return (
      <>
        {resultText && hasNoImage && (
          <div
            style={{
              height: '100%',
              width: '100%',
              marginBottom: 0
            }}
            className="text-image-content-wrapper"
          >
            <span
              className={'text-image-content'}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(resultText)
              }}
            />
          </div>
        )}
        {src && (
          <div
            style={{
              height: '100%',
              width: '100%',
              backgroundImage: `url("${src}")`,
              backgroundSize: bgSize ?? 'contain',
              backgroundPosition: `${bgAlign} ${bgVerticalAlign}`,
              backgroundRepeat: 'no-repeat'
            }}
          />
        )}
      </>
    );
  }
}

export default TextImage;
