import SiteTreeEntry from '@/common/types/entity/SiteTreeEntry';
import { PageTemplate } from '@/utils/retrievePageTemplate';
import { findRoute } from '@/modules/ERP/Common/utils';
import { EntityType } from '@/modules/Onboarding/Shared/types';

export const getHoldingSetUrlInfo = (holdingSetId: string, holdingSetType: EntityType, siteTree: SiteTreeEntry[]) => {
  const contactIdParameterKey = 'id';
  const searchParams = new URLSearchParams({
    [contactIdParameterKey]: holdingSetId
  });

  const pageTemplate = holdingSetType === EntityType.PORTFOLIO ? PageTemplate.PORTFOLIO_VIEW : PageTemplate.SINGLE_REPORT;

  let pathname = findRoute(siteTree, pageTemplate).path;

  if (holdingSetType !== EntityType.PORTFOLIO) {
    pathname = findRoute(siteTree, PageTemplate.PORTFOLIO_VIEW_LEGAL_ENTITY).path;
  }

  return { pathname, searchParams };
};
