import TransientDocumentTag from './TransientDocumentTag';
import TransientDocumentTagValue from './TransientDocumentTagValue';
import { BasicTagType } from '../../../common/types/entity/DocumentTags';
import Params from './Params';
import EntityRef from '../../../common/types/entity/EntityRef';

export class SearchFilter {
  constructor(
    readonly tag?: TransientDocumentTag,
    readonly value?: TransientDocumentTagValue<BasicTagType | EntityRef>,
    readonly inverse: boolean = false
  ) {}

  isEmpty(): boolean {
    return this.tag === undefined && this.value === undefined;
  }

  toParams(): Map<string, any> {
    const params = new Map<string, any>();
    if (this.tag) {
      this.tag.toParams().forEach((value, key) => params.set(key, value));
      if (this.value) {
        this.value.toParams().forEach((value, key) => params.set(key, value));
      }
      params.set(Params.filter_inverse, this.inverse);
    }
    return params;
  }
  static fromParams = (params: Map<string, any>, tag?: TransientDocumentTag, tagValue?: TransientDocumentTagValue<BasicTagType | EntityRef>): SearchFilter => {
    return new SearchFilter(tag, tagValue, params.get(Params.filter_inverse));
  };
}
