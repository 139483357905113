import { requestApi } from '@/core';
import { ApiService } from 'ui-sesame-components';
import { AssetDtoV2 } from '../../PortfolioV2/types';

export const getAssetById = (assetId: number, processLogo?: number, { errors }: { errors?: boolean } = {}): Promise<AssetDtoV2> => {
  return requestApi({
    errors,
    url: `/api/v2/assets/${assetId}`,
    service: ApiService.ASSET,
    method: 'get',
    params: { processLogo }
  }).then(({ data }) => data);
};
