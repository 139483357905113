import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { ContentTagCategory, ContentTagLookup } from '../common/types/entity/ContentTag';

export default (category: ContentTagCategory): Promise<ContentTagLookup[]> =>
  requestApi({
    service: 'cms',
    method: 'get',
    url: `/api/tags?category=${category}`
  }).then((response: AxiosResponse<ContentTagLookup[]> | undefined) => response?.data!);
