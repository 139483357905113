import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import { duplicateColumnSeparator } from '@/utils/getGenericFieldPath';
import type { Column } from '@/modules/reporting-v2/core/Column';

export const findColumnWithCode = (code: string, columns: Column[]) => {
  const [codeToSearch, idToSearch] = code.split(duplicateColumnSeparator);

  return columns.find(col => {
    if (idToSearch) {
      return col.code === codeToSearch && col.id === idToSearch;
    }

    return col.code === codeToSearch && !col.isDuplicateColumn;
  });
};

export const findConditionalMeasureColumn = (column: Column, data: FlattenObject, columns: Column[]) => {
  const condition = column.conditionalMeasure!.conditions!.find(condition => condition.condition.evaluate(data));
  if (!condition) {
    return undefined;
  }

  return findColumnWithCode(condition.value, columns);
};
