import 'setimmediate';

if (!Object.values) {
  Object.values = (object: Record<string, any>) => {
    const output = [];
    for (const key in object) {
      output.push(object[key]);
    }
    return output;
  };
}

if (!Object.entries) {
  Object.entries = (object: Record<string, any>) => {
    const output: [string, any][] = [];
    for (const key in object) {
      output.push([key, object[key]]);
    }
    return output;
  };
}

if (!Object.fromEntries) {
  Object.fromEntries = (array: any[]) =>
    array.reduce(
      (output, [key, value]) => ({
        ...output,
        [key]: value
      }),
      {}
    );
}

declare global {
  interface Array<T> {
    findLastIndex(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): number;
  }
}
