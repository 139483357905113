import queryString from 'query-string';
import slugify from '@/utils/slugify';
import { Hyperlinks } from '@/modules/reporting-v2/types/Column';
import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import { Field } from '../../Field';

export default (hyperlinks: Hyperlinks, flattenObject: FlattenObject) => {
  const urls = Object.entries(hyperlinks).map(([baseUrl, { params, displayName, icon }]) => {
    const url = {
      url: slugify(baseUrl),
      query: (params || []).reduce((accum, { param, value }) => {
        const valuePath = new Field(value).getElasticPath();
        return { ...accum, [param]: String(flattenObject[valuePath] || value) };
      }, {})
    };
    return { url: queryString.stringifyUrl(url), displayName, icon };
  });

  return urls;
};
