import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import { config } from './config';
import schema from './schema.json';
import React from 'react';
import RawGhostVisualConfig from './types';
import { GhostVisualConfig } from '@/modules/reporting-v2/core/visuals/index';

class GhostVisual extends Visual {
  static configMapper(visualConfig: RawGhostVisualConfig) {
    const { fields, ...rest } = visualConfig;
    return {
      ...rest,
      columns: mapVisualConfigColumns(fields)
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as GhostVisualConfig;
  }

  getSchema() {
    return schema;
  }

  renderTitle() {
    return null;
  }

  renderInteractivity() {
    return [];
  }

  renderBody = () => {
    return <></>;
  };
}

export default GhostVisual;
