export const getLabelsNamesHistoricalSeries = (isEntityBenchmark: boolean, multipleEntities: boolean, groupOrColumnName: string, entityName: string, benchmarkNames: string) => {
  // if it's a benchmark entity and measure was renamed to "Benchmark" we should display "benchmarkNames"
  // "benchmarkNames" now is a name of entity's default benchmark
  if (isEntityBenchmark && groupOrColumnName === 'Benchmark') {
    return benchmarkNames;
  }
  if (entityName && groupOrColumnName === 'Entity') {
    return entityName;
  }
  if (multipleEntities) {
    return `${entityName} - ${groupOrColumnName}`;
  }
  return groupOrColumnName;
};
