import { requestApi } from '@/core';
import { UUID } from '@/common/types/types';
import { EntityLinkType } from '@/common/types/entity/Link';

export const deleteLink = (entityType: EntityLinkType, entityId: UUID, linkId: UUID): Promise<void> => {
  return requestApi({
    service: 'link',
    method: 'delete',
    url: `/api/${entityType}/${entityId}/links/${linkId}`
  }).then(() => Promise.resolve());
};
