import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import ElasticResponse from '../../../common/types/elastic/ElasticResponse';
import assetInfoUndatedDatasourceConfig from '@/modules/reporting-v2/config/datasource/json/asset_info_undated.json';
import EntityLookup from '../../../common/types/entity/EntityLookup';

export interface AssetLookup extends EntityLookup {
  category: string;
  holdingSetId: number;
  riskCountry?: string;
  currency?: string;
  assetType?: string;
  issuerName?: string;
  group?: string;
}

interface AssetInfoLookup {
  assetId: number;
  assetName: string;
  riskCountry?: string;
  assetCurrency?: string;
  assetType?: string;
  issuerName?: string;
  assetCategory: string;
}

interface AssetInfoUndatedLookup {
  holdingSetId: number;
  assets?: AssetInfoLookup[];
}

export const getAssets = async ({ holdingSetIds }: { holdingSetIds: number[] }): Promise<AssetLookup[]> => {
  const res = (await requestApi({
    method: 'post',
    service: assetInfoUndatedDatasourceConfig.service,
    url: `${assetInfoUndatedDatasourceConfig.uri}?v=1.0`,
    data: {
      source: {
        includes: [
          'holdingSetId',
          'assets.assetName',
          'assets.assetCategory',
          'assets.assetId',
          'assets.riskCountry',
          'assets.assetCurrency',
          'assets.issuerName',
          'assets.assetType'
        ]
      },
      holdingSets: holdingSetIds.map(holdingSetId => ({ holdingSetId }))
    }
  })) as AxiosResponse<ElasticResponse<AssetInfoUndatedLookup>> | undefined;
  if (!res || res?.status !== 200) {
    throw Error('Failed to fetch DataQuery');
  }
  const holdingSetHits = res.data.hits.hits;

  const duplicates = new Map<number, boolean>();

  const assetList: Array<AssetLookup> = [];

  holdingSetHits.forEach(holdingSetHit => {
    holdingSetHit._source.assets?.forEach(assetInfo => {
      const assetId = assetInfo.assetId;

      if (!duplicates.has(assetId)) {
        duplicates.set(assetId, true);

        assetList.push({
          id: assetId,
          name: assetInfo.assetName,
          category: assetInfo.assetCategory,
          riskCountry: assetInfo.riskCountry,
          currency: assetInfo.assetCurrency,
          assetType: assetInfo.assetType,
          issuerName: assetInfo.issuerName,
          holdingSetId: holdingSetHit._source.holdingSetId
        });
      }
    });
  });

  return assetList;
};
