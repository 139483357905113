import React, { useContext, useMemo } from 'react';
import { HighChartsDataUtils } from '@/modules/reporting-v2/core/HighchartsDataUtils';
import { GaugeUtils } from '@/modules/reporting-v2/core/visuals/Gauge/GaugeUtils';
import { GaugeConfig } from '@/modules/reporting-v2/core/visuals';
import cx from 'classnames';
import ReportContext from '../../ReportContext';
import './style.css';
import { nanoid } from 'nanoid';

export type StarRatingOptions = {
  chart: {
    className: string;
    type: string;
    value: number;
    rating: number;
    numberOfStars: number;
  };
};

type StarRatingProps = {
  sizeRatio: number;
  numberOfStars: number;
  rating: number;
};

const StarRating = ({ sizeRatio = 1, numberOfStars, rating }: StarRatingProps) => {
  return (
    <div className="star-rating-container" style={{ '--sizeRatio': sizeRatio } as React.CSSProperties}>
      {[...Array(numberOfStars)].map((_, i) => {
        const isChecked = rating >= i + 1;

        return <div key={rating + nanoid()} className={cx('star-rating-star', isChecked ? 'star-rating-star-checked' : undefined)}></div>;
      })}
    </div>
  );
};

const StarRatingWrapper = ({ visual }: { visual: GaugeConfig }) => {
  const context = useContext(ReportContext);
  const columns = useMemo(() => HighChartsDataUtils.getColumns(visual.columns), [visual.columns]);

  const sizeRatio = visual.thickness > 10 ? 1 : visual.thickness;

  const options = GaugeUtils.getOptions(visual, columns, context) as StarRatingOptions;

  return <StarRating numberOfStars={options.chart.numberOfStars} rating={options.chart.rating} sizeRatio={sizeRatio} />;
};

export { StarRating, StarRatingWrapper };
