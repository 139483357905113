import React, { createContext, useContext } from 'react';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';

type LevelIndicatorsContextWrapperProps = {
  children: React.ReactElement;
  visual: VisualEngine;
};

type LevelIndicatorsContextValue = boolean;

const LevelIndicatorsContext = createContext<LevelIndicatorsContextValue>(false);

const LevelIndicatorsContextWrapper: React.FC<LevelIndicatorsContextWrapperProps> = ({ children, visual }) => {
  const isDashboard = visual.component === VisualComponent.DashboardTable;

  return <LevelIndicatorsContext.Provider value={isDashboard}>{children}</LevelIndicatorsContext.Provider>;
};

const useLevelIndicatorsContext = () => {
  const levelIndicatorsContextValue = useContext(LevelIndicatorsContext);

  return levelIndicatorsContextValue;
};

export { LevelIndicatorsContextWrapper, useLevelIndicatorsContext };
