import dayjs from 'dayjs';
import { DataFrequency } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/VisualSpecificProps';
import { getMonthByKey } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/utils/getMonthByKey';

export const getDateValue = (month: string, year: string, dataFrequency: DataFrequency, locale?: string) => {
  const date = dayjs()
    .set('month', parseInt(month) - 1)
    .set('year', parseInt(year));

  if (dataFrequency === DataFrequency.Yearly) {
    return date.format('YYYY');
  }

  if (dataFrequency === DataFrequency.Quarterly) {
    return date.format('[Q]Q YYYY');
  }

  return `${getMonthByKey(month, locale)} ${year}`;
};
