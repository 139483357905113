import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { ComponentTemplate, ComponentTemplateRef, PersistentComponentTemplate } from '../../../common/types/entity/ComponentTemplate';

export default (id: number): Promise<PersistentComponentTemplate> =>
  requestApi({
    service: 'cms',
    method: 'get',
    url: `/api/custom-components/${id}`
  }).then((response: AxiosResponse<ComponentTemplate & ComponentTemplateRef> | undefined) => PersistentComponentTemplate.fromComponentTemplate(response.data));
