import React, { ReactElement } from 'react';
import { Spin, Tooltip } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

export interface AutosaveIndicatorProps {
  saving?: boolean;
  pending?: boolean;
  error?: Error;
  savingIcon?: ReactElement;
  pendingIcon?: ReactElement;
  savedIcon?: ReactElement;
  errorIcon?: ReactElement;
}

export const AutosaveIndicator: React.FC<AutosaveIndicatorProps> = ({
  saving,
  pending,
  error,
  savingIcon = <LoadingOutlined />,
  pendingIcon = <ExclamationCircleOutlined />,
  savedIcon = <CheckCircleOutlined />,
  errorIcon = <WarningOutlined />
}) => {
  const getIcon = () => {
    let icon;

    if (!saving && !pending && !error) {
      icon = savedIcon;
    }

    if (!saving && !pending && error) {
      icon = errorIcon;
    }

    if (saving) {
      icon = savingIcon;
    }

    if (pending) {
      icon = pendingIcon;
    }

    if (icon) {
      return React.cloneElement(icon, { className: getClassName(error) });
    }

    return null;
  };

  const getTitle = () => {
    let title;

    if (!saving && !pending && error) {
      title = error.message;
    }

    if (!saving && !pending && !error) {
      title = <FormattedMessage id={'generic.changesWereSavedAutomatically'} />;
    }

    if (pending) {
      title = <FormattedMessage id={'generic.changesArePendingToBeSaved'} />;
    }

    if (!pending) {
      title = <FormattedMessage id={'generic.changesAreBeingSaved'} />;
    }

    return title;
  };

  return <Spin className="autosave-indicator" size="small" indicator={<Tooltip title={getTitle()}>{getIcon()}</Tooltip>} />;
};

function getClassName(error?: Error) {
  if (error) {
    return 'icon-error';
  }

  return undefined;
}
