import { requestApi } from '@/core';
import { Entity } from '../types';

export const getLegalEntityByHoldingSetId = async ({ holdingSetId, ...params }: { holdingSetId: number; referenceDate?: string }): Promise<Entity> => {
  return requestApi({
    url: `/api/external-entities/v2/${holdingSetId}`,
    service: 'client',
    method: 'get',
    params
  }).then(({ data }) => data);
};
