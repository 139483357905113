export const removeEmpty = <T extends Record<string, any>>(obj: T): T => {
  const newObj: Record<string, any> = {};
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      newObj[key] = obj[key];
    } else if (obj[key] === Object(obj[key])) {
      const newObjKey = removeEmpty(obj[key]);
      if (Object.keys(newObjKey).length > 0) {
        newObj[key] = newObjKey;
      }
    } else if (obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  });
  return newObj as T;
};

export const simpleRemoveEmpty = (obj: Record<string, any>) => {
  const newObj: Record<string, any> = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};
