import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { getEmptyRawColumn } from '@/modules/reporting-v2/utils/getEmptyRawColumn';
import RawGaugeConfig, { GaugeType } from './types';

export const config: RawGaugeConfig = {
  type: 'visual',
  gaugeType: GaugeType.SPEED,
  metric: getEmptyRawColumn(),
  thickness: 100,
  minValue: 0,
  maxValue: 1,
  bands: [],
  _reportContext: VisualReportContext.NONE
};
