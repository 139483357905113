import { useLocation } from 'react-router-dom';
import logoEnterprise from '@/modules/App/images/logo-enterprise.png';
import logoData from '@/modules/App/images/logo-data.png';

export const useDefaultLogoUrl = () => {
  const location = useLocation();
  const experience = location.pathname.split('/')[1];

  return experience.includes('sesame-data') ? logoData : logoEnterprise;
};
