import React from 'react';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';

export interface IVisualContext {
  onValueChange?: (data: FlattenObject, value: Primitive, field: string, rowId?: string) => void;
  id?: string;
}

const VisualContext = React.createContext<IVisualContext>({});

export default VisualContext;
