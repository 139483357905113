import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { Storage } from '../types/Storage';

export default (id: string): Promise<Storage> =>
  requestApi({
    method: 'get',
    url: `/api/document/${id}/download`,
    service: 'DOCUMENT'
  }).then((response: AxiosResponse | undefined) => response?.data);
