import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';

const uniqueValues = <T extends string>(values: Array<T>): Array<T> => {
  return Array.from(new Set(values));
};

const uniqueValuesForKey = <T>(values: Array<T>, key: (val: T) => string): Array<T> => {
  const valuePerKey = new Map();
  for (const value of values) {
    valuePerKey.set(key(value), value);
  }
  return Array.from(valuePerKey.values());
};

const groupBy = <T, U extends string, V>(values: T[], keyFunction: (val: T) => U, valFunction: (val: T) => V = val => val as unknown as V): Map<U, V[]> => {
  const map = new Map<U, V[]>();
  values.forEach(val => {
    const key = keyFunction(val);
    const newVal = valFunction(val);
    if (map.has(key)) map.get(key)!.push(newVal);
    else map.set(key, [newVal]);
  });
  return map;
};

const filterKeys = <K, V>(map: Map<K, V>, filter: (key: K) => boolean): Map<K, V> => {
  const newMap = new Map<K, V>();
  for (const [key, val] of map) {
    if (filter(key)) newMap.set(key, val);
  }
  return newMap;
};

const addValuesToInnerArray = <K1, V>(map: Map<K1, V[]>, key: K1, values: V[]): Map<K1, V[]> => {
  if (map.has(key)) {
    map.get(key)!.push(...values);
  } else {
    map.set(key, values);
  }

  return map;
};

const addToInnerMap = <K1, K2, V>(map: Map<K1, Map<K2, V>>, key: K1, innerKey: K2, value: V): Map<K1, Map<K2, V>> => {
  if (map.has(key)) {
    const currentInnerMap = map.get(key)!;
    currentInnerMap.set(innerKey, value);
  } else {
    const newInnerMap = new Map();
    newInnerMap.set(innerKey, value);
    map.set(key, newInnerMap);
  }

  return map;
};

const addToInnerInnerMap = <K1, K2, K3, V>(map: Map<K1, Map<K2, Map<K3, V>>>, key: K1, innerKey1: K2, innerKey2: K3, value: V): Map<K1, Map<K2, Map<K3, V>>> => {
  if (map.has(key)) {
    const currentInnerMap = map.get(key)!;
    addToInnerMap(currentInnerMap, innerKey1, innerKey2, value);
  } else {
    const newInnerMap = new Map();
    addToInnerMap(newInnerMap, innerKey1, innerKey2, value);
  }

  return map;
};

export { uniqueValues, uniqueValuesForKey, groupBy, filterKeys, addToInnerMap, addToInnerInnerMap, addValuesToInnerArray };
