import React from 'react';
import { RowGroup, Row } from '@/modules/reporting-v2/types/VisualEngine';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import GroupByColumn from '@/modules/reporting-v2/core/GroupByColumn';

// Clear the rows that won't be visible so we can't show them when we toggle expand everything
// memoization prevent rerender on mount
const clearNotVisibleRows = (rows: (Row | RowGroup)[], collapsible: boolean | undefined, maxDepth: number) => {
  if (collapsible !== false || maxDepth < 0) {
    return rows;
  }

  const clearNotVisibleRow = (rows2: (Row | RowGroup)[], depth: number) => {
    if (depth > maxDepth) {
      return [];
    }

    const newRows: (Row | RowGroup)[] = [];

    for (const row of rows2) {
      let updatedRow = row;
      if ('rows' in updatedRow) {
        updatedRow = {
          ...row,
          rows: clearNotVisibleRow(updatedRow.rows, depth + 1)
        };
      }
      newRows.push(updatedRow);
    }

    return newRows;
  };

  return clearNotVisibleRow(rows, 0);
};
const useDataState = (visual: VisualEngine, defaultGroupByColumns: GroupByColumn[]) => {
  const [data, setData] = React.useState<(Row | RowGroup)[]>(() => clearNotVisibleRows(visual.data.rows, visual.collapsible, defaultGroupByColumns.length - 1));

  React.useEffect(() => {
    setData(clearNotVisibleRows(visual.data.rows, visual.collapsible, defaultGroupByColumns.length - 1));
  }, [visual.data.rows, visual.collapsible, defaultGroupByColumns]);

  return [data, setData] as const;
};

export { useDataState };
