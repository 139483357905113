import { selector, selectorFamily } from 'recoil';
import { DocumentLookup } from '@/common/types/entity/DocumentLookup';
import { EntityLinkType } from '@/common/types/entity/Link';
import getDocument from '@/modules/DMS/api/getDocument';
import { DaysPeriodFilter, FilterType } from '@/modules/ERP/Contacts/ContactDetails/Tabs/TabsFilters';
import { entityLinksSelector, erpFilterState } from '@/modules/ERP/Common/Recoil/erp.selectors';
import { compareLastModifiedDate } from '@/modules/ERP/Documents/utils';
import getDocuments from '@/modules/DMS/api/getDocumentsValidated';
import type { UUID } from '@/common/types/types';
import type { NodeIDs, ValidatedDocument } from '../types';
import listDocuments from '@/modules/DMS/api/listDocuments';
import { queryBySlice } from '@/modules/DMS/components/DocumentsTable/getDataSource';
import { message } from 'antd';

const documentSelector = selectorFamily<DocumentLookup, UUID>({
  key: 'document-selector',
  get: documentId => async () => {
    return getDocument(documentId);
  }
});

const documentsSelector = selector<Array<DocumentLookup>>({
  key: 'erp-documents-selector',
  get: () => {
    return listDocuments();
  }
});

const validatedDocumentsSelector = selectorFamily<ValidatedDocument[], string>({
  key: 'validated-documents-selector',
  get: documentIds => async () => {
    const docIds = documentIds.split(',');

    const validatedDocs = await Promise.all(
      queryBySlice<string[], Promise<ValidatedDocument[]>>(
        docIds,
        slicedList => {
          return getDocuments(slicedList.join(','));
        },
        15
      )
    );

    return validatedDocs.reduce((acc, val) => {
      acc.push(...val);
      return acc;
    }, []);
  }
});

const documentsTableDatasourceSelector = selectorFamily<Array<DocumentLookup>, [EntityLinkType, UUID]>({
  key: 'erp-contact-activity-documents-table-datasource-selector',
  get:
    ([entityType, contactId]) =>
    async ({ get }) => {
      const links = get(entityLinksSelector([entityType, contactId]));

      const documentLinks = links.filter(link => {
        return link.type === EntityLinkType.DOCUMENT;
      });
      if (!documentLinks.length) {
        return [];
      }

      const validatedDocumentLinks = get(validatedDocumentsSelector(documentLinks.map(l => l.entityId).join(',')));
      return Promise.all(
        validatedDocumentLinks.map(link => {
          return get(documentSelector(link.id));
        })
      ).catch(error => {
        message.error(error);
        return [];
      });
    }
});

const documentsTableSelector = selectorFamily<Array<DocumentLookup>, { entityType: EntityLinkType; entityId: UUID; nodeIds?: NodeIDs }>({
  key: 'erp-activity-documents-table-selector',
  get:
    ({ entityId, entityType, nodeIds = [] }) =>
    async ({ get }) => {
      const documents = get(documentsTableDatasourceSelector([entityType, entityId]));

      const filterValues = nodeIds
        .map(node => {
          return {
            value: get(erpFilterState(node.key)),
            type: node.filterType
          };
        })
        .filter(filter => filter.value !== undefined);

      if (!filterValues.length) {
        return documents;
      }

      return documents.filter(doc => {
        let keepDocument = true;

        filterValues.forEach(({ value, type }) => {
          if (type === FilterType.DAYS_PERIOD) {
            keepDocument = compareLastModifiedDate(doc.lastModifiedDate, value as DaysPeriodFilter);
          } else {
            throw new Error('Unknown filter type');
          }
        });

        return keepDocument;
      });
    }
});

export { documentsTableSelector, documentSelector, validatedDocumentsSelector, documentsSelector };
