import React, { useState } from 'react';
import { Popover, Typography } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

interface TaskSiderItemProps {
  title: string;
  clickableText?: string;
  clickableText2?: React.ReactNode;
  content?: React.ReactNode;
  popoverClassName?: string;
  hide?: boolean;
  closeOnClick?: boolean;
}

const TaskSiderItem: React.FC<TaskSiderItemProps> = React.memo(({ title, content, clickableText, clickableText2, popoverClassName, hide, closeOnClick }) => {
  const [opened, setOpened] = useState(false);

  const onOpenChange = (openState: boolean) => {
    setOpened(openState);
  };

  const onContentClick = () => {
    if (closeOnClick) {
      setOpened(false);
    }
  };

  return !hide ? (
    <div className={'modal-contact-create-sider'}>
      <Typography.Text className={'modal-contact-create-sider-text'}>{title}</Typography.Text>
      <Popover
        content={<div onClick={onContentClick}>{content}</div>}
        trigger={'hover'}
        open={opened}
        onOpenChange={onOpenChange}
        placement={'bottomRight'}
        overlayClassName={popoverClassName}
        style={{ width: '700px !important' }}
        destroyTooltipOnHide
      >
        {clickableText && (
          <Typography.Text className={'modal-contact-create-clickable-text'}>
            {clickableText}
            <CaretDownOutlined className={'modal-contact-create-clickable-icon'} />
          </Typography.Text>
        )}
      </Popover>
      {clickableText2 && <Typography.Text>{clickableText2}</Typography.Text>}
    </div>
  ) : null;
});

export { TaskSiderItem };
