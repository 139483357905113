import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const useRootPath = () => {
  const location = useLocation();
  const [rootPath, setRootPath] = useState('');
  useEffect(() => {
    const newRootPathState = location.pathname.split('/').slice(0, 3).join('/');
    if (newRootPathState !== rootPath) {
      setRootPath(newRootPathState);
    }
  }, [location.pathname, rootPath]);

  return rootPath;
};
