import { Auth0ContextInterface, Auth0Provider, Auth0ProviderOptions, useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { auth0lastLocationUrlKey } from '@/modules/App/containers/App/ImplicitCallback';

class Auth0Utils {
  public getToken?: Auth0ContextInterface['getAccessTokenSilently'];
  public logout?: Auth0ContextInterface['logout'];

  setTokenGetter(getToken?: Auth0ContextInterface['getAccessTokenSilently']) {
    this.getToken = getToken;
  }

  setLogoutUtil(logout?: Auth0ContextInterface['logout']) {
    this.logout = logout;
  }
}

export const auth0Utils = new Auth0Utils();

/**
 * @description Provides the ability to use auth0 functions outside of react components.
 */

const Auth0UtilsProvider: React.FunctionComponent<React.PropsWithChildren> = React.memo(({ children, ...props }) => {
  const { getAccessTokenSilently, logout, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && window.location.pathname !== new URL(import.meta.env.VITE_AUTH_REDIRECT_URI).pathname) {
      localStorage.setItem(auth0lastLocationUrlKey, window.location.href);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    auth0Utils.setTokenGetter(getAccessTokenSilently);
    auth0Utils.setLogoutUtil(logout);

    return () => {
      auth0Utils.setLogoutUtil();
      auth0Utils.setTokenGetter();
    };
  }, [getAccessTokenSilently, logout]);

  return <>{children}</>;
});

export const CustomAuth0Provider: React.FunctionComponent<Auth0ProviderOptions> = React.memo(({ children, ...props }) => {
  return (
    <Auth0Provider {...props}>
      <Auth0UtilsProvider>{children}</Auth0UtilsProvider>
    </Auth0Provider>
  );
});
