import { GlobalFilters, TaskContext, TransientTask, ValueLocator } from '../../../../common/types/entity/Task';
import queryString, { ParsedQuery } from 'query-string';
import { SesameUser } from '@/common/types/SesameUser';

type ParsedQueryType = string | number | boolean;

export function populateContext(task: TransientTask, siteTreeEntry: any, selectedElement?: Element, currentUser?: SesameUser | null, currentHoldingSet?: number) {
  const params: ParsedQuery<ParsedQueryType> = queryString.parse(window.location.search, {
    parseBooleans: true,
    parseNumbers: true
  });

  populateReference(task, siteTreeEntry);

  if (!task.holdingSetId) {
    task.holdingSetId = getHoldingSet(params, currentUser, currentHoldingSet);
  }

  if (!task.context) {
    task.context = grabContext(params, selectedElement);
  }
}

function populateReference(task: TransientTask, siteTreeEntry: any) {
  if (!task.refUrl) {
    task.refUrl = window.location.pathname + window.location.search;
  }
  if (!task.refName) {
    task.refName = siteTreeEntry.title;
  }
  if (!task.refId) {
    task.refId = siteTreeEntry.id;
  }
}

function getHoldingSet(params: ParsedQuery<ParsedQueryType>, currentUser: any, currentHoldingSet?: any): number | undefined {
  // probably most trusted way to infer holding set by looking into URL
  if (params['holdingSetId']) {
    return Number(params['holdingSetId']);
  }
  // check if the store contains it
  if (currentHoldingSet) {
    return currentHoldingSet.id;
  }
  // fall back to HoldingSetInfo component
  const holdingSetId = document.querySelector('div.holding-set-info')?.getAttribute('data-holding-set-id');
  if (holdingSetId) {
    return parseInt(holdingSetId);
  }
  return undefined;
}

function grabContext(params: ParsedQuery<ParsedQueryType>, selectedElement?: Element): TaskContext {
  const context = new TaskContext();

  if (selectedElement) {
    context.value = getValue(selectedElement);
    populateCustomReportsContext(context, params);
    populateVisualContext(context, selectedElement);
  }

  context.globalFilters = getGlobalFilters(params);

  return context;
}

function populateCustomReportsContext(context: TaskContext, params: ParsedQuery<ParsedQueryType>) {
  // check if we are on a Custom Reports page
  if (params['report']) {
    context.reportId = params['report'] as number;
    const customReportHolder = document.querySelector('main.custom-report-holder');
    if (customReportHolder) {
      context.reportName = customReportHolder.getAttribute('data-report-name') || undefined;
    }
  }
}

function populateVisualContext(context: TaskContext, selectedElement: Element) {
  const visualWrapper = selectedElement.closest('div.visual-wrapper');
  if (visualWrapper) {
    context.visualId = visualWrapper.parentElement!.id;
    context.visualName = (visualWrapper.querySelector('div.visual-title') as HTMLElement)?.innerText;
  }

  if (context.visualId) {
    context.valueLocator = getValueLocator(selectedElement, context.visualId);
  }
}

function getGlobalFilters(params: ParsedQuery<ParsedQueryType>): GlobalFilters | undefined {
  const holdingSetId = params['holdingSetId'] as number;
  const date = params['date'] ? new Date(String(params['date'])) : undefined;
  let paramLookThrough = 'Direct';
  if (params['lookThrough'] === 1) {
    paramLookThrough = 'Look Through';
  }
  const consolidationType = params['lookThrough'] ? paramLookThrough : undefined;
  const reportId = params['report'] as number;
  if (holdingSetId || date || consolidationType || reportId) {
    return new GlobalFilters(holdingSetId, date, consolidationType, reportId);
  }
  return undefined;
}

function getValue(selectedElement: Element): string | undefined {
  if (selectedElement instanceof HTMLElement) {
    return selectedElement.innerText;
  } else if (selectedElement instanceof SVGElement) {
    return selectedElement.textContent || undefined;
  } else {
    return String(selectedElement);
  }
}

function getValueLocator(selectedElement: Element, visualId: string): ValueLocator | undefined {
  if (selectedElement instanceof HTMLElement) {
    const table = selectedElement.closest(`#${visualId} .report-data-table`);
    if (table) {
      const td = selectedElement.closest('td');
      if (td) {
        const index = Array.from(td.parentNode!.children).indexOf(td);
        const thead = table.querySelector('thead');
        let column = undefined;
        if (thead) {
          column = (thead.querySelectorAll('th')[index] as HTMLElement).innerText;
        }
        return new ValueLocator((td.parentNode!.children[0] as HTMLElement).innerText, column);
      }
    }
  }
  return undefined;
}
