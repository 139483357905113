import { requestApi } from '@/core';
import { NoteDTO } from '@/modules/ERP/Contacts/types';
import { AxiosResponse } from 'axios';

export const getNotesByIds = (ids: string): Promise<Array<NoteDTO>> => {
  return requestApi({
    service: 'collaboration',
    method: 'get',
    url: `/api/notes/byIds?ids=${ids}`,
    errors: false
  }).then((resp: AxiosResponse<Array<NoteDTO>> | undefined) => {
    return resp!.data;
  });
};

export const getNotes = (): Promise<Array<NoteDTO>> => {
  return requestApi({
    service: 'collaboration',
    method: 'get',
    url: `/api/notes`,
    errors: false
  }).then((resp: AxiosResponse<{ content: Array<NoteDTO> }> | undefined) => {
    return resp!.data.content;
  });
};
