import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import type RawHistoricalChartConfig from './types';
import { DateRange, Orientation, TickRate } from './VisualSpecificProps';

export const config: RawHistoricalChartConfig = {
  type: 'visual',
  vAxis1: {
    columns: [],
    defaultColumns: [],
    options: {},
    filters: {}
  },
  vAxis2: {
    columns: [],
    defaultColumns: [],
    options: {},
    filters: {}
  },
  group: {
    columns: [],
    defaultColumns: [],
    options: {},
    filters: {}
  },
  _dateRange: DateRange.LAST_YEAR,
  dateRange: DateRange.LAST_YEAR,
  orientation: Orientation.ComboChart,
  _options: {
    legend: {
      alignment: 'center',
      maxLines: 3
    },
    hAxis: {
      slantedText: true,
      format: 'MMM YYYY'
    }
  },
  _compareValues: false,
  tickRate: TickRate.auto,
  connectGaps: true,
  _reportContext: VisualReportContext.NONE,
  _sampling: true,
  legend: true,
  diagonalAxisLabel: false,
  legendPosition: 'bottom',
  xAxis: {},
  yAxis: {}
};
