import React, { useState } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Button, Row, Space } from 'antd';
import { Connection, Links } from '../index';
import { dataSelector, entity } from '../Branch/branch.selector';
import { Minus, Plus } from '@/common/svg';
import { useRecoilValue } from 'recoil';
import { useSearchParams } from '@/common/hooks/useSearchParams';
import { withSmallSuspense } from '@/common/suspense';
import colorAlpha from 'color-alpha';
import colors from '../../config/colors';
import cx from 'classnames';
import styles from './style.module.css';
import { FormattedMessage } from 'react-intl';
import { holdingSetsMap } from '@/recoil/holdingSets';
import { ConsolidationType } from '@/modules/reporting-v2/types/ReportingService';

const EntityData = withSmallSuspense(
  React.memo(({ data }: any) => {
    const searchParams = useSearchParams();
    const holdingSetMap = useRecoilValue(holdingSetsMap);

    const parentEntityId = Number(searchParams.get('holdingSetId'));
    const currentEntityId = data.itemHoldingSetId;

    const childEntity = holdingSetMap.get(currentEntityId);
    const parentEntity = holdingSetMap.get(parentEntityId);

    let consolidationType = searchParams.get('consolidationType');

    const bypassSelectedLookthrough = consolidationType === ConsolidationType.LOOK_THROUGH && parentEntity?.lookThroughEnabled && !childEntity?.lookThroughEnabled;

    if (bypassSelectedLookthrough) {
      consolidationType = ConsolidationType.DIRECT;
    }

    const { verifiedDate, marketValue } = useRecoilValue(
      dataSelector({
        consolidationType,
        holdingSetId: currentEntityId
      })
    );

    return (
      <Space direction="vertical" size={0} className={styles.metas}>
        <div>
          <FormattedMessage
            id="ownership.dateVerified"
            values={{
              verifiedDate: verifiedDate,
              s: chunks => <strong>{chunks}</strong>
            }}
          />
        </div>
        <div>
          <FormattedMessage
            id="ownership.marketValue"
            values={{
              marketValue: marketValue,
              s: chunks => <strong>{chunks}</strong>
            }}
          />
        </div>
      </Space>
    );
  })
);

type ItemProps = {
  childrenCount?: number;
  collapsed?: boolean;
  data: any;
  hasChildren?: boolean;
  node?: HTMLElement | null;
  onCollapse?: () => void;
  parent?: HTMLElement | null;
  search?: string;
  setRef?: any;
};

const Item: React.FC<ItemProps> = ({ data, setRef, node, parent, search, hasChildren, childrenCount, collapsed, onCollapse }) => {
  const [activeOptions, setActiveOptions] = useState(false);

  return (
    <>
      {parent && node && <Connection node={node} parent={parent} color="#bfbfbf" strokeWidth={1} />}
      <div
        ref={setRef}
        className={cx(styles.item, {
          active: activeOptions
        })}
        style={
          !collapsed && hasChildren
            ? ({
                boxShadow: `0 0.5em 0 -0.25em ${colorAlpha(colors[data.type as keyof typeof colors], 0.7)}, 0 0.5em 0 -0.5em #ffffff, 0 1em 0 -0.5em ${colorAlpha(
                  colors[data.type as keyof typeof colors],
                  0.3
                )}`
              } as React.CSSProperties)
            : undefined
        }
      >
        <div
          className={styles.wrapper}
          style={{
            backgroundColor: search && new RegExp(search, 'gi').test(data.name) ? '#ffffb8' : undefined
          }}
        >
          <h4
            className={styles.title}
            style={{
              color: colors[data.type as keyof typeof colors]
            }}
          >
            {data.name}
          </h4>
          {data.type !== entity.ASSET && <EntityData data={data} />}
        </div>
        <div className={styles.actionsWrapper}>
          <Row
            align="middle"
            justify="center"
            style={{
              backgroundColor: colorAlpha(colors[data.type as keyof typeof colors], 0.1),
              color: colors[data.type as keyof typeof colors]
            }}
          >
            {hasChildren && (
              <span className={styles.action}>
                <Button
                  size="small"
                  type="link"
                  onClick={onCollapse}
                  style={{
                    margin: 0,
                    padding: 0,
                    height: 'auto',
                    fontSize: '1em',
                    lineHeight: '1em',
                    color: 'inherit',
                    transition: 'none'
                  }}
                >
                  <Row align="middle">
                    {collapsed ? <Minus width="1em" /> : <Plus width="1em" />}
                    <span
                      style={{
                        padding: '0.2em 0.5em',
                        backgroundColor: colors[data.type as keyof typeof colors],
                        fontSize: '0.9em',
                        color: '#ffffff',
                        borderRadius: '5em'
                      }}
                    >
                      <strong>{childrenCount}</strong>
                    </span>
                  </Row>
                </Button>
              </span>
            )}
            <span className={styles.action}>
              <Links
                datadogActionPrefix={'Ownership Map -'}
                trackDatadogActions={true}
                onOpenChange={setActiveOptions}
                placement="bottom"
                data={{ ...data, ...(data.type === 'asset' ? { assetId: data.itemId } : undefined) }}
              >
                <Button
                  size="small"
                  type="link"
                  icon={<LinkOutlined />}
                  style={{
                    height: 'auto',
                    margin: 0,
                    fontSize: '0.875em',
                    color: colors[data.type as keyof typeof colors],
                    transition: 'none'
                  }}
                />
              </Links>
            </span>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Item;
