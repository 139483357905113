import { SortOrder } from '@/modules/reporting-v2/types/VisualEngine';
import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { getEmptyRawColumn } from '@/modules/reporting-v2/utils/getEmptyRawColumn';
import type RawTopTabConfig from './types';

export const config: RawTopTabConfig = {
  type: 'visual',
  columns: getEmptyRawColumn(),
  measures: getEmptyRawColumn(),
  group: getEmptyRawColumn(),
  _collapsible: false,
  categories: false,
  subTotal: true,
  total: true,
  sort: SortOrder.ASC,
  limit: '10',
  _reportContext: VisualReportContext.NONE
};
