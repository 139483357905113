import { EntityParameters } from '@/common/types/app/EntityParametersHandler';

export enum ConsolidationType {
  DIRECT = 'DIRECT',
  LOOK_THROUGH = 'LOOK_THROUGH_EXPOSURE'
}

/*

ex: Multi holding set on last available date (Main Dashboard/Overview)
{
holdingSets: [ {holdingSetId : 1}, {holdingSetId : 2}]
}

ex: Multi holding set on last available date (Main Dashboard/Overview) before given date
{
holdingSets: [ {holdingSetId : 1}, {holdingSetId : 2}],
date: "2020-01-01"
}

ex: Multi holding set with their own filters
{
holdingSets: [ {
holdingSetId : 1,
date: "2020-01-01",
consolidationType: DIRECT
}, {
holdingSetId : 2,
date: "2020-02-02",
consolidationType: LOOK_THROUGH
}],
}


data class ElasticFilter(
      @NotEmpty
      val holdingSets: Set<HoldingSetFilter> = setOf(),
      val date: LocalDate? = null,
      val range: DateRange? = null,
      val consolidationType: ConsolidationType? = null,
      val assetIds: Set<Long> = setOf(),
      var source: FetchSourceFilter = FetchSourceFilter(),
      val sampling: Boolean = true,
     )

     data class HoldingSetFilter(
      @NotNull
      val holdingSetId: Long,
      val date: LocalDate? = null,
      val range: DateRange? = null,
      val consolidationType: ConsolidationType? = null,
    )

    data class DateRange(
      val from: LocalDate? = null,
      val to: LocalDate,
    )

*/

export type ElasticCallKey = string;
export type RowKey = string;
export type ReferenceNodeKey = string;

export type ReportParams = Record<string, number[] | any> & {
  sampling?: boolean;
  holdingSets: Array<EntityParameters>;
};

export interface ElasticRequestPayload extends ReportParams {
  source: { includes: string[] };
}
