import React, { memo } from 'react';
import { Empty } from 'antd';
import { FormattedMessage } from 'react-intl';
import { withSmallSuspense } from '@/common/suspense';
import { useRecoilValue } from 'recoil';
import { noPermissionsSelector } from '@/modules/App/recoil/app.selectors';
import { useDefaultLogoUrl } from '../../hooks/useDefaultLogoUrl';

const style = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)'
};

const Header = React.lazy(() => import('@/modules/App/components/Header'));

const textStyle = { fontSize: '1rem' };

export default withSmallSuspense(
  memo(({ formattedMessageId, formattedMessage, ...props }) => {
    const noPermissions = useRecoilValue(noPermissionsSelector);
    const defaultLogoUrl = useDefaultLogoUrl();

    return (
      <>
        {noPermissions && <Header />}
        <div {...props} style={style}>
          <Empty
            image={defaultLogoUrl}
            description={
              <span style={textStyle}>
                {formattedMessage}
                {formattedMessageId && <FormattedMessage id={formattedMessageId} />}
              </span>
            }
          ></Empty>
        </div>
      </>
    );
  })
);
