export enum VisualReportContext {
  BOTH = 'BOTH',
  RECEIVER = 'RECEIVER',
  EMITTER = 'EMITTER',
  NONE = 'NONE'
}

export enum Align {
  TOP = 'top',
  BOTTOM = 'bottom',
  MIDDLE = 'middle'
}

export type ColumnHeader = {
  displayName: string;
  colSpan: number;
};
