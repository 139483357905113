import React, { useRef } from 'react';
import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import { requestApi } from '@/core';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import Visual, { initialState } from '@/modules/reporting-v2/core/visuals/Visual/index';
import { config } from './config';
import schema from './schema.json';
import formatTypes from '@/modules/reporting-v2/core/formatTypes';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { Column, ColumnFormatting } from '@/modules/reporting-v2/core/Column';
import { Field } from '@/modules/reporting-v2/core/Field';
import { CreateOrFindTaskButton } from '@/modules/TMS/components/dialog/CreateOrFindTaskButton';
import { TaskType, TransientTask } from '@/common/types/entity/Task';
import dayjs from 'dayjs';
import { populateContext } from '@/modules/TMS/components/snipping/context';
import LimitsTableConfig from './LimitsTableConfig';
import RawLimitsTableConfig from './types';
import { Row, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import { Category, LimitsGroupBy, Status } from './VisualSpecificProps';
import { IVisualCoreProps } from '@/modules/reporting-v2/types/VisualCoreTypes';
import { RouteComponentProps, StaticContext } from 'react-router';
import { FormatType } from '@/common/types/elastic/FormatType';
import { nanoid } from 'nanoid';
import { Status as TaskStatus } from '@/modules/ERP/Contacts/types';
import { useRecoilValue } from 'recoil';
import { userState } from '@/modules/User/recoil/user.atoms';
import { siteTreeEntryState } from '@/modules/App/recoil/app.atoms';
import { DATE_FORMAT } from 'ui-sesame-components';
import { DataTablev2 } from '@/modules/reporting-v2/core/components/DataTablev2/DataTable';

type Operators = {
  name: string;
  displayValue: string;
  requiresEndValue: boolean;
};

type Item = {
  id?: number;
  createdDate: Date;
  description: string;
  measureValue: number;
  currentValue: number;
  currentValueStr: string;
  valueStatus: Status;
};

const firstColumnCellStyle = {
  display: 'inline-block',
  fontSize: '0.8rem',
  fontWeight: 'normal',
  opacity: 0.8
};

const currentValueCode = 'L11';
const nameCode = 'L08';
const valueStatusCode = 'L12';
const categoryCode = 'L09';
const operatorsIniCode = 'L16';
const operatorsEndCode = 'L17';
const operatorsWarningIniCode = 'L14';
const operatorsWarningEndCode = 'L15';
const reviewedValueCode = 'L22';

const codeToPath = {
  [nameCode]: 'limits.limits.name',
  [categoryCode]: 'limits.limits.category',
  [currentValueCode]: 'limits.limits.limitCurrentValue',
  [valueStatusCode]: 'limits.limits.valueStatus',
  limitDate: 'limits.date',
  L21: 'limits.limits.reviewed',
  [reviewedValueCode]: 'limits.limits.reviewedValue',
  L23: 'limits.limits.breachClassification',
  L24: 'limits.limits.breachDetails',
  [operatorsWarningIniCode]: 'limits.limits.operators.warningIni',
  [operatorsWarningEndCode]: 'limits.limits.operators.warningEnd',
  [operatorsIniCode]: 'limits.limits.operators.ini',
  [operatorsEndCode]: 'limits.limits.operators.end',
  L18: 'limits.limits.operators.type'
};

const numValueColumnCodes = [currentValueCode, operatorsIniCode, operatorsEndCode, operatorsWarningIniCode, operatorsWarningEndCode, reviewedValueCode];

const childrenCells = (items: Item[], visual: LimitsTableConfig) =>
  items
    .filter(item => {
      return !visual.status || visual.status.length === 0 || visual.status.find(status => status.toLowerCase().trim() === item['valueStatus'].toLowerCase().trim());
    })
    .map(({ description, currentValueStr, valueStatus }) => [description, currentValueStr, valueStatus]);

const filterByCategoryAndStatus = (visual: LimitsTableConfig) => {
  return visual.data.rows.filter(row => {
    const categoryCheck =
      !visual.category ||
      visual.category.length === 0 ||
      visual.category.find(value => {
        if (!row.data[codeToPath[categoryCode]]) return false;
        return value.toLowerCase().trim() === (row.data[codeToPath[categoryCode]] as string).toLowerCase().trim();
      });
    const statusCheck =
      !visual.status ||
      visual.status.length === 0 ||
      visual.status.find(value => {
        if (!row.data[codeToPath[valueStatusCode]]) return false;
        return value.toLowerCase().trim() === (row.data[codeToPath[valueStatusCode]] as string).toLowerCase().trim();
      });

    return categoryCheck && statusCheck;
  });
};

const getOperators = () =>
  requestApi({
    service: 'limit',
    url: '/api/operator'
  }).then(({ data }: any) => data as Operators[]);

const getItems = (limits: FlattenObject[]) => {
  return Promise.all(
    limits.map(item => {
      const limitHoldingSetId = item['limits.limits.limitHoldingSetId'];
      if (item['limits.limits.enableSaveItems']) {
        return requestApi({
          service: 'limit',
          url: `/api/limits/${limitHoldingSetId}/items`
        }).then(({ data }: any) => [limitHoldingSetId, data.sort(({ valueStatus }: { valueStatus: string }) => (valueStatus === 'ALERT' ? -1 : 0))]);
      }
      return Promise.resolve([limitHoldingSetId, []]);
    })
  ).then((...data) => Object.fromEntries(...data));
};

const RenderCreateTaskAction = ({ limitData }: any) => {
  const ref = useRef(null);
  const { currentUser, currentHoldingSet } = useRecoilValue(userState);
  const siteTreeEntry = useRecoilValue(siteTreeEntryState);

  const {
    'limits.limits.limitHoldingSetId': limitHoldingSetId,
    [codeToPath[valueStatusCode]]: valueStatus,
    'limits.limits.name': name,
    'limits.date': date,
    limitCurrentValue,
    [codeToPath[categoryCode]]: category,
    rule,
    'limits.limits.operators.ini': ini,
    'limits.limits.operators.end': end,
    'limits.limits.operators.warningIni': warningIni,
    'limits.limits.operators.warningEnd': warningEnd,
    'limits.limits.operators.type': type
  } = limitData;

  const taskTitle = `${name} [${dayjs(date).format(DATE_FORMAT.DATE)}]`;

  const task = new TransientTask(taskTitle, TaskType.LIMIT, undefined, name, undefined, limitHoldingSetId, '', TaskStatus.TO_DO, undefined);

  populateContext(task, siteTreeEntry, undefined, currentUser, currentHoldingSet);

  task.context!.limitDetail = {
    rule,
    limitHoldingSetId,
    valueStatus,
    name,
    limitCurrentValue,
    category,
    operators: {
      ini,
      end,
      warningIni,
      warningEnd,
      type
    }
  };

  return (
    <div ref={ref}>
      <CreateOrFindTaskButton initTask={() => task} types={[TaskType.LIMIT]} />
    </div>
  );
};

const formatValue = (visual: LimitsTableConfig, item: Row | RowGroup, value: Primitive, metas: Column, isDescription?: boolean) => {
  let type;
  const newMetas = { ...metas };

  switch (item.data['limits.limits.format']) {
    case 'PERCENTAGE': {
      type = 'percentage';
      newMetas.formatting = {
        ...newMetas.formatting,
        decimals: visual.decimalFormattingForPercentage
      } as any;
      break;
    }
    case 'MONETARY': {
      type = 'price';
      (newMetas as any).currency = { value: (item as any).currency };
      newMetas.formatting = {
        ...newMetas.formatting,
        decimals: visual.decimalFormattingForMonetary
      } as any;
      break;
    }
    default: {
      type = 'amountNoUnit';
      newMetas.formatting = {
        ...newMetas.formatting,
        decimals: visual.decimalFormattingForNumber
      } as any;
    }
  }

  let newValue = value;
  if (type === 'percentage' && isDescription && typeof value === 'number') {
    newValue = value / 100;
  }

  return newValue === null ? '' : (formatTypes[type as keyof typeof formatTypes] as any)(newValue, null, newMetas, newMetas.formatting);
};

const getValueColumn = (visual: LimitsTableConfig) => {
  return visual.columns.find(col => col.code === currentValueCode || col.fieldDataPath === codeToPath[currentValueCode])!;
};

const getFinalCells = (visual: LimitsTableConfig, row: Row | RowGroup, valueColumn: Column, operators: Operators[]): Row['cells'] => {
  const tempRowCells = [...row.cells];
  const defaultColumns = visual.columns.filter(col => col.isDefault);
  const valueColumnIndex = defaultColumns.findIndex(col => col === valueColumn);

  const operator = operators.find(operator => row.data['limits.limits.operators.type'] === operator.name)?.displayValue;
  const rule = `${operator} ${Object.entries(row.data)
    .filter(([key, value]) => key.startsWith('limits.limits.operators') && key !== 'limits.limits.operators.type' && value !== null)
    .map(([, value]) => formatValue(visual, row, value, valueColumn, true))
    .join(', ')}`;
  const category = row.data[codeToPath[categoryCode]];
  const limitCategoryAndRule = [rule];
  if (!visual.hideCategoryInLimitRule) {
    limitCategoryAndRule.unshift(category as string);
  }

  const cells: Row['cells'] = [];

  if (!visual.hideLimitRule) {
    cells.push(
      (
        <span key="1" style={firstColumnCellStyle}>
          {limitCategoryAndRule.join(' - ')}
        </span>
      ) as any
    );
  }

  for (let index = cells.length; index < defaultColumns.length; index++) {
    const column = defaultColumns[index];
    const isActionColumn = column.fieldDataPath.includes('/actions/');
    if (isActionColumn) {
      cells.push(
        (
          <RenderCreateTaskAction
            limitData={{
              ...row.data,
              rule,
              limitCurrentValue: tempRowCells[valueColumnIndex]
            }}
          />
        ) as any
      );
      continue;
    }

    const isNumValue = column.code && numValueColumnCodes.includes(column.code);
    if (!isNumValue) {
      cells.push(tempRowCells[index]);
      continue;
    }

    const isOperatorCol = column.fieldDataPath.includes('limits.operators');
    cells.push(formatValue(visual, row, tempRowCells[index], column, isOperatorCol));
  }

  return cells;
};

const getFinalRows = (visual: LimitsTableConfig, rows: (Row | RowGroup)[], items: any, operators: Operators[], level: number) => {
  const valueColumn = getValueColumn(visual);

  return rows.map(row => {
    const group = row.cells[0];
    const cells = getFinalCells(visual, row, valueColumn, operators);

    return {
      ...row,
      cells: cells,
      rows: childrenCells(items[row.data['limits.limits.limitHoldingSetId'] as string] || [], visual).map((childCells, index) => {
        return {
          __id__: `${row.__id__}/${index}`,
          cells: [...childCells],
          data: items[row.data['limits.limits.limitHoldingSetId'] as string]
        };
      }),
      group,
      level: level
    };
  });
};

const getGroupPath = (group: LimitsGroupBy) => {
  switch (group) {
    case LimitsGroupBy.STATUS:
      return codeToPath[valueStatusCode];
    case LimitsGroupBy.DATE:
      return codeToPath.limitDate;
    case LimitsGroupBy.LIMIT_NAME:
      return codeToPath[nameCode];
    case LimitsGroupBy.CATEGORY:
    default:
      return codeToPath[categoryCode];
  }
};

const getGroupedRows = (
  visual: LimitsTableConfig,
  rows: (Row | RowGroup)[],
  items: unknown,
  operators: Operators[],
  group: LimitsGroupBy | undefined | null,
  groups: LimitsGroupBy[],
  level: number
): RowGroup[] => {
  if (!group) {
    return [...getFinalRows(visual, rows, items, operators, level)];
  }

  const groupPath = getGroupPath(group);
  const groupsSet = [...new Set(rows.map(row => row.data[groupPath]))];

  return groupsSet.map(g => {
    const filteredRows = rows.filter(row => row.data[groupPath] === g);

    let childrenRows = [];
    if (groups.length > 0) {
      const { group, nextGroups } = getGroups(groups);
      childrenRows = [...getGroupedRows(visual, filteredRows, items, operators, group, nextGroups, level + 1)];
    } else {
      childrenRows = [...getFinalRows(visual, filteredRows, items, operators, level + 1)];
    }

    return {
      __id__: nanoid(),
      data: {},
      cells: [],
      rows: childrenRows,
      group: g,
      level: level
    };
  });
};

const getGroups = (groups: LimitsGroupBy[]) => {
  let group = null;
  let nextGroups: LimitsGroupBy[] = [];

  if (groups[0]) {
    group = groups[0];
    nextGroups = groups.slice(1);
  }

  return { group, nextGroups: nextGroups };
};

const getRows = (visual: LimitsTableConfig, items: unknown, operators: Operators[]) => {
  const rows = filterByCategoryAndStatus(visual);

  const { group, nextGroups } = getGroups(visual.groupBy);
  const groupedRows = getGroupedRows(visual, rows, items, operators, group, nextGroups, 0);

  return groupedRows;
};

const applyCustomColumnFormatting = (columns: Column[]) => {
  const statusColumn = columns.find(col => col.code === valueStatusCode);
  if (statusColumn) {
    statusColumn.formatting = ColumnFormatting.fromColumnFormatting(statusColumn.formatting, FormatType.limits);
  }

  numValueColumnCodes.forEach(code => {
    const column = columns.find(col => col.code === code);
    if (column) {
      column.formatting = ColumnFormatting.fromColumnFormatting(column.formatting, FormatType.string);
    }
  });

  const actionsColumn = columns.find(col => col.fieldDataPath.includes('actions'));
  if (actionsColumn) {
    actionsColumn.formatting = new ColumnFormatting(undefined, FormatType.custom);
    actionsColumn.isRowCellEditable = false;
  }
};

const getLimitsTableVisual = (visual: LimitsTableConfig, items: unknown, operators: Operators[]): IDataTableProps['visual'] => {
  if (!items) {
    return visual as IDataTableProps['visual'];
  }

  applyCustomColumnFormatting(visual.columns);

  visual.data = {
    ...visual.data,
    rows: getRows(visual, items, operators)
  };

  return visual as IDataTableProps['visual'];
};

class LimitsTable extends Visual {
  Loader = Loaders.Table;

  constructor(props: RouteComponentProps<{}, StaticContext, unknown> & IVisualCoreProps) {
    super(props);

    this.state = {
      ...initialState,
      items: {},
      operators: [] as Operators[]
    } as any;
  }

  static configMapper(visualConfig: RawLimitsTableConfig) {
    const { category, status, columns, ...rest } = visualConfig;

    let safeColumns = columns;
    const hasNoColumns = !columns.defaultColumns.length;
    if (hasNoColumns) {
      safeColumns = config.columns;
    }

    return {
      ...rest,
      columns: mapVisualConfigColumns({
        columns: ['limits.*', ...safeColumns.columns],
        defaultColumns: safeColumns.defaultColumns,
        filters: safeColumns.filters,
        options: safeColumns.options
      }),
      category: ([] as Category[]).concat(category),
      status
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as LimitsTableConfig;
  }

  getSchema() {
    return schema;
  }

  onPreLoad() {
    getOperators().then(operators => {
      this.setState({ operators } as any); // todo fix state type (-> composition)
    });
  }

  renderTitle() {
    const rows = filterByCategoryAndStatus(this.props.visual as LimitsTableConfig);
    let status = 'INVALID';

    if (rows.find(row => row.data[codeToPath[valueStatusCode]] === 'ALERT')) {
      status = 'ALERT';
    } else if (rows.find(row => row.data[codeToPath[valueStatusCode]] === 'WARNING')) {
      status = 'WARNING';
    } else if (rows.find(row => row.data[codeToPath[valueStatusCode]] === 'OK')) {
      status = 'OK';
    }

    return (
      <span>
        {super.renderTitle()}
        <span style={{ marginLeft: '1rem', position: 'absolute' }}>
          {VisualEngine.formatCell(
            status,
            new Column({
              field: new Field(codeToPath[valueStatusCode]),
              formatting: { type: 'limits' } as any
            })
          )}
        </span>
      </span>
    );
  }

  fetchRowLimits = (_: number, __: number, row: FlattenObject) => {
    if (Object.keys(row).length === 0) {
      return;
    }

    getItems([row]).then(items => {
      this.setState({
        items: { ...(this.state as any).items, ...items }
      } as any);
    });
  };

  getCloneVisual = (visual: VisualEngine) => {
    const visualClone = Object.assign({}, visual);
    Object.setPrototypeOf(visualClone, VisualEngine.prototype);

    // handle sort
    visualClone.sort = visual.sort;
    visualClone.updateSort = visual.updateSort.bind(visual);
    visualClone.updateData = visual.updateData.bind(visual);

    return visualClone;
  };

  renderBody() {
    if (!(this.state as any).operators) {
      return null;
    }

    if (!(this.state as any).operators.length) {
      return this.renderLoader();
    }

    const { items, operators } = this.state as unknown as {
      items: unknown;
      operators: Operators[];
    };

    const visualClone = this.getCloneVisual(this.props.visual);
    const visual = getLimitsTableVisual(visualClone as LimitsTableConfig, items, operators);

    if (this.props.visual.tableVersion === 'v1') {
      return <DataTable onRowSelect={this.fetchRowLimits} visual={visual} />;
    }

    return <DataTablev2 onRowClick={rowData => this.fetchRowLimits(0, 0, rowData)} visual={visual} />;
  }
}

export default LimitsTable;
