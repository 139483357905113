import { HighChartsDataUtils } from '@/modules/reporting-v2/core/HighchartsDataUtils';
import { FormattedTooltipPoint } from '../types';

export const getTooltipString = (points: FormattedTooltipPoint[]) => {
  const pointsRows = points.map(point => {
    const testColorPoint = HighChartsDataUtils.getSymbolFormat(point.series);
    return `<div>${testColorPoint ?? ''} ${point.serieName}: ${point.value}</div>`;
  });

  return pointsRows;
};
