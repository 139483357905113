import React, { CSSProperties, Key, useContext, useMemo } from 'react';
import { Column } from '@/modules/reporting-v2/core/Column';
import { Field } from '@/modules/reporting-v2/core/Field';
import { ReportingService } from '@/modules/reporting-v2/core/ReportingService';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { ExcelUtils } from '@/utils/excel';
import DataBars from './DataBars';
import { EditableValue } from '@/modules/reporting-v2/core/components/index';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import { useFixedColumn } from '../hooks/useFixedColumn';
import { useRecoilValue } from 'recoil';
import { siteTreeState } from '@/modules/App/recoil/app.atoms';
import { useCategoryClass } from '../hooks/useCategoryClass';
import { LevelIndicators } from '@/modules/reporting-v2/core/components/DataTable/components/LevelIndicator';
import { useAssetLink } from '@/modules/reporting-v2/hooks/useAssetLink';
import { AssetLinkWrapper } from '@/modules/reporting-v2/core/AssetLinkWrapper';

interface IProps {
  cells: Primitive[];
  idx: number;
  columns: Column[];
  col: Column;
  isVisualLevelHtmlEditOnly?: boolean;
  data: FlattenObject;
  maxRangeValue?: number;
  handleColumnSelect: (field: Field, value: Primitive) => void;
  options?: Record<string, any>;
  displayCategories: boolean;
  level: number;
  isLastChildRow: boolean;
  parentRowLastChildRow: number[];
  isDashboardTable: boolean;
  rowId: string;
}

const Cell: React.FC<IProps> = React.memo(
  ({
    isVisualLevelHtmlEditOnly,
    cells,
    idx,
    col,
    columns,
    data,
    maxRangeValue,
    handleColumnSelect,
    options,
    displayCategories,
    level,
    isLastChildRow,
    parentRowLastChildRow,
    isDashboardTable,
    rowId
  }) => {
    const context = useContext(ReportContext);
    const columnLeft = useFixedColumn(col);
    const siteTree = useRecoilValue(siteTreeState);
    const categoryClass = useCategoryClass(columns, col, idx, displayCategories);

    const isGroupable = useMemo(() => ReportingService.metas[col.code ?? col.field.name]?.groupable, [col.code, col.field.name]);
    const tdStyles = useMemo(() => {
      const styles: CSSProperties = {};
      if (idx === 0) {
        styles.textAlign = 'left';
      }
      if (col.dataBars) {
        styles.textAlign = 'right';
      }

      if (col.styling?.fixed) {
        styles.position = 'sticky';
        styles.left = columnLeft;
        styles.zIndex = 2;
      }

      return styles;
    }, [idx, col.dataBars, col.styling?.fixed, columnLeft]);

    const excelAttrs = useMemo(() => ExcelUtils.getTDAttributes(col, data, cells[idx]), [col, data, cells, idx]);

    const cellOptions = options ? options[idx] : undefined;

    const assetLink = useAssetLink(col, data, siteTree);

    const wrapStyles = useMemo(() => {
      const styles: React.CSSProperties = {};
      if (col.styling?.width) {
        styles.width = col.styling.width;
      }
      if (col.styling?.overflowType === 'WRAP') {
        styles.whiteSpace = 'normal';
      }

      return styles;
    }, [col.styling]);

    if (cellOptions?.visible === false) {
      return null;
    }

    const cellValue = VisualEngine.formatCell(cells[idx], col, data, false, undefined, context.reportConfiguration?.config.numberLocale);

    const useLevelIndicator = isDashboardTable && idx === 0 && level > 0;

    return (
      <td
        key={col.id}
        {...excelAttrs}
        style={tdStyles}
        onClick={ev => {
          if (isGroupable) {
            handleColumnSelect(col.field, cells[idx]);
          }
        }}
        className={categoryClass}
        {...cellOptions?.props}
      >
        {useLevelIndicator && <LevelIndicators isLastChildRow={isLastChildRow} parentRowLastChildRow={parentRowLastChildRow} level={level} />}
        {col.dataBars && <DataBars value={cells[idx] as number} maxValue={maxRangeValue} />}
        <AssetLinkWrapper assetLink={assetLink}>
          <EditableValue
            htmlEditOnly={isVisualLevelHtmlEditOnly}
            key={cells[idx] as Key}
            metas={col}
            defaultValue={cells[idx]}
            field={col.fieldDataPath}
            data={data}
            styles={wrapStyles}
            rowId={rowId}
          >
            {cellValue}
          </EditableValue>
        </AssetLinkWrapper>
      </td>
    );
  }
);

export default Cell;
