import React from 'react';
import { Typography } from 'antd';
import cx from 'classnames';
import { TextProps } from 'antd/lib/typography/Text';

import './style.css';

export interface SesameTextProps extends TextProps {
  black?: boolean;
  link?: boolean;
}

const Text: React.FC<SesameTextProps> = React.memo(({ children, link = false, black = false, ...props }) => {
  let className = React.useMemo<string>(() => {
    return cx({
      sesame_black_text: black,
      sesame_link_text_styles: link
    });
  }, [black, link]);

  if (props.className) className += ` ${props.className}`;

  return (
    <Typography.Text {...props} className={className}>
      {children}
    </Typography.Text>
  );
});

export { Text };
