import { requestApi } from '@/core';
import { UUID } from '@/common/types/types';
import { MetadataEntityType } from '@/common/types/entity/Metadata';

export interface MetadataFile {
  name: string;
  uri: string;
  signature: string;
}
export const getMetadataFile = async (entityType: MetadataEntityType, fileId: UUID): Promise<MetadataFile> => {
  const resp = await requestApi({
    method: 'get',
    service: 'metadata',
    url: `/api/metadata/values/${entityType}/files/${fileId}/download`
  });
  return resp!.data;
};
