import requestApi from '@/core/requestApi';

export default async () => {
  const resp = await requestApi({
    service: 'gateway',
    method: 'GET',
    url: '/api/elastic/index/meta?q=1691671680247'
  });
  return resp.data;
};
