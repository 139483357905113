import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { getEmptyRawColumn } from '@/modules/reporting-v2/utils/getEmptyRawColumn';
import type RawFlowsTableConfig from './types';
import { DateRange } from '@/modules/reporting-v2/core/visuals/HistoricalChart/VisualSpecificProps';

export const config: RawFlowsTableConfig = {
  type: 'visual',
  columns: getEmptyRawColumn(),
  group: getEmptyRawColumn(),
  dateRange: DateRange.LAST_YEAR,
  _collapsible: true,
  categories: false,
  subTotal: true,
  total: true,
  _reportContext: VisualReportContext.NONE
};
