enum DiffValue {
  VALUE_CREATED = 'created',
  VALUE_UPDATED = 'updated',
  VALUE_DELETED = 'deleted',
  VALUE_UNCHANGED = 'unchanged'
}

const compareValues = function (value1: any, value2: any) {
  if (value1 === value2) {
    return DiffValue.VALUE_UNCHANGED;
  }
  if (isDate(value1) && isDate(value2) && value1.getTime() === value2.getTime()) {
    return DiffValue.VALUE_UNCHANGED;
  }
  if (value1 === undefined) {
    return DiffValue.VALUE_CREATED;
  }
  if (value2 === undefined) {
    return DiffValue.VALUE_DELETED;
  }
  return DiffValue.VALUE_UPDATED;
};

const isFunction = function (x: any): x is Function {
  return typeof x === 'function';
};
const isArray = function (x: any): x is Array<any> {
  return Array.isArray(x);
};
const isDate = function (x: any): x is Date {
  return x?.toString() === '[object Date]';
};
const isObject = function (x: any): x is Object {
  return typeof x === 'object' && !isArray(x);
};
const isValue = function (x: any): x is string | boolean | number {
  return !isObject(x) && !isArray(x);
};

const deepDiffMapper = function (obj1: any, obj2: any) {
  if (isFunction(obj1) || isFunction(obj2)) {
    throw Error('Invalid argument. Function given, object expected.');
  }

  if (isValue(obj1) || isValue(obj2)) {
    return {
      type: compareValues(obj1, obj2),
      data: obj1 === undefined ? obj2 : obj1
    };
  }

  const diff: Record<string, any> = {};
  for (const key in obj1) {
    if (isFunction(obj1[key])) {
      continue;
    }

    let value2 = undefined;
    if (obj2[key] !== undefined) {
      value2 = obj2[key];
    }

    diff[key] = deepDiffMapper(obj1[key], value2);
  }

  for (const key in obj2) {
    if (isFunction(obj2[key]) || diff[key] !== undefined) {
      continue;
    }

    diff[key] = deepDiffMapper(undefined, obj2[key]);
  }

  return diff;
};

export { deepDiffMapper, DiffValue };
