// @ts-nocheck
import { AuthorizationParams } from '@auth0/auth0-react';

const searchparams = new URLSearchParams(window.location.search);

const invitation = searchparams.get('invitation');
const organization = searchparams.get('organization');

export const authConfig: AuthorizationParams = {
  domain: import.meta.env.VITE_AUTH_DOMAIN as string,
  clientId: import.meta.env.VITE_AUTH_CLIENT_ID as string,
  authorizationParams: {
    scope: 'openid offline_access email',
    redirect_uri: import.meta.env.VITE_AUTH_REDIRECT_URI as string,
    audience: import.meta.env.VITE_AUTH_AUDIENCE as string,
    ...(invitation &&
      organization && {
        invitation,
        organization
      })
  }
};
