import { nanoid } from 'nanoid';
import { ColumnConfig } from '@/modules/reporting-v2/types/Column';
import { Dict } from '@/modules/reporting-v2/types/Common';
import { FieldResolver } from '@/modules/reporting-v2/types/FieldResolver';
import { Row, SortOrder } from '@/modules/reporting-v2/types/VisualEngine';
import { HeaderConfig } from '@/modules/reporting-v2/utils/HeaderConfig';
import { concatenateUniqueIndexFields } from '@/modules/reporting-v2/utils/IndexUtils';
import { FilterOperator } from '@/types/Filters';
import { PopupHelper } from '@/utils/PopupHelper';
import { CalcExpression } from './CalcExpression';
import { Field } from './Field';
import { Filter } from './Filter';
import { ReportingService } from './ReportingService';
import { ChartAxis } from './visuals/HistoricalChart/HistoricalChartUtils';
import { FormatType, NumberFormatType } from '@/common/types/elastic/FormatType';
import { RawFilter, RawFilterGroup } from '@/modules/reporting-v2/types/Filter';
import { FilterGroup } from './FilterGroup';
import { DataPointsFrequency } from './visuals/HistoricalChart/VisualSpecificProps';
import { MeasureCondition } from '@/modules/report-builder/components/Forms/Visual/Schema/ConditionalSelectWrapper/common/types';
import type { Primitive } from '@/modules/reporting-v2/types/FlattenObject';

export const assetNameField = 'asset_info_undated.assets.assetName';

export const customColumnIdentifier = '/'; // ex: "/P_VoBLJ4j-cp/"

export enum OverrideSummability {
  SUMMABLE = 'summable',
  NONSUMMABLE = 'nonsummable'
}

export type ConditionalColorObject = {
  highlight?: string;
  conditions?: {
    id?: string;
    value: string;
    threshold?: string;
    condition: FilterGroup | RawFilterGroup | FilterOperator | 'lesserThan' | 'greaterThan' | 'notEqualTo' | 'equalTo';
  }[];
};

export type ConditionalColorObjectMapped = {
  highlight?: string;
  conditions?: {
    id?: string;
    value: string;
    condition: FilterGroup;
  }[];
};

export type ConditionalMeasureObject = {
  conditions?: MeasureCondition[];
};

export type ConditionalMeasureObjectMapped = {
  conditions?: {
    id?: string;
    value: string;
    condition: FilterGroup;
  }[];
};

export type ConditionalColor = string | ConditionalColorObject;

class ColumnFormatting implements FieldResolver {
  constructor(
    currency?: Field,
    type?: FormatType,
    color?: ConditionalColor,
    decimals?: string,
    tooltipText?: string,
    format?: string,
    currencyDirectValue?: string,
    numberFormat?: NumberFormatType
  ) {
    this.currency = currency;
    this.type = type;
    this.decimals = decimals;
    this.tooltipText = tooltipText;
    this.format = format;
    this.currencyDirectValue = currencyDirectValue;
    this.color = color;
    this.numberFormat = numberFormat;
  }

  currency?: Field;
  type?: FormatType;
  color?: ConditionalColor;
  decimals?: string;
  tooltipText?: string;
  format?: string;
  currencyDirectValue?: string;
  compact?: boolean;
  forceDecimals?: boolean;
  numberFormat?: NumberFormatType;

  getAllUsedFields(): Field[] {
    return concatenateUniqueIndexFields([this.currency].filter(item => item !== undefined) as Field[]);
  }

  public static fromColumnFormatting(formatting: ColumnFormatting | undefined, type: FormatType) {
    return new ColumnFormatting(formatting?.currency, type, formatting?.color, formatting?.decimals, formatting?.tooltipText, formatting?.format, formatting?.currencyDirectValue);
  }

  public static buildConditionalColor(color: ConditionalColor | undefined, initialField: Field, columns: any[]): ConditionalColor | undefined {
    if (!color) {
      return undefined;
    }

    if (typeof color === 'string') {
      return color;
    }

    if (!color.conditions) {
      return color;
    }

    const isNewCondition = typeof color.conditions[0]?.condition === 'object';
    if (!isNewCondition) {
      return color;
    }

    for (const colorCondition of color.conditions) {
      const { type, conditions } = colorCondition.condition as RawFilterGroup;
      const filters = conditions.map((condition: RawFilter | RawFilterGroup) => {
        if ('conditions' in condition) {
          const groupFilters = condition.conditions.map((filter: RawFilter) => {
            return Filter.fromRawFilter(filter, columns, initialField);
          });

          return new FilterGroup(condition.type, groupFilters);
        } else {
          return Filter.fromRawFilter(condition, columns, initialField);
        }
      });

      colorCondition.condition = new FilterGroup(type, filters);
    }

    return color;
  }
}

export const buildConditionalMeasure = (value: ConditionalMeasureObject | undefined, initialField: Field, columns: any[]): ConditionalMeasureObjectMapped | undefined => {
  if (!value || !value.conditions) {
    return undefined;
  }

  for (const measureCondition of value.conditions) {
    const { type, conditions } = measureCondition.condition;
    const filters = conditions.map(condition => Filter.fromRawFilter(condition, columns, initialField));
    (measureCondition as any).condition = new FilterGroup(type, filters);
  }

  return value as ConditionalMeasureObjectMapped;
};

type Hyperlinks = Dict<{
  displayName?: string;
  icon?: string;
  params?: Array<{ [key: string]: string }>;
}>;

class Column implements FieldResolver {
  constructor(config: ColumnConfig) {
    this.code = config.code;
    this.field = config.field;
    this.isDefault = Boolean(config.isDefault);
    this.isDuplicateColumn = Boolean(config.isDuplicateColumn);
    this.visible = config.visible || true;
    this.mode = config.mode; // StatsTable-bound - custom header modes
    this.headerConfig = config.headerConfig || new HeaderConfig();
    this.formatting = config.formatting;
    this.calcExpression = config.calcExpression;
    this.calcOnGroup = config.calcOnGroup;
    this.calcAfterNormalise = config.calcAfterNormalise;
    this.calcIgnoreFilter = config.calcIgnoreFilter;
    this.overrideSummability = config.overrideSummability;
    this.countOnAggregation = config.countOnAggregation || false;
    this.countDistinctOnAggregation = config.countDistinctOnAggregation ?? false;
    this.absolute = config.absolute;
    this.conditionalValueDisplay = config.conditionalValueDisplay || [];
    this.hyperlinks = config.hyperlinks || {};
    this.conditionalRowDisplay = config.conditionalRowDisplay || [];
    this.documentsPopup = Column.isDocumentsPopup(config.field);
    this.targetAxis = config.targetAxis;
    this.limitsPopup = Column.isLimitsPopup(config.field);
    this.sortBy = config.sortBy;
    this.hyperlinksPopup = Column.isHyperlinksPopup(config.hyperlinks || {});
    this.isRowCellEditable = config.isRowCellEditable ?? true;
    this.id = config.id! || nanoid(8);
    this.defaultSort = config.defaultSort;
    this.fieldDataPath = this.isDuplicateColumn ? this.field.getElasticPath() + this.id : this.field.getElasticPath(); // Necessary to differentiate between duplicate columns and normal ones
    this.normalise = config.normalise || false;
    this.dataBars = config.dataBars || false;
    this.displayMode = config.displayMode;
    this.showFirstValueSubtotal = config.showFirstValueSubtotal ?? false;
    this.assetNameColumn = config.field.getElasticPath() === assetNameField;
    this.quantFilters = config.quantFilters || [];
    this.hideZeroValues = config.hideZeroValues;
    this.hidePath = config.hidePath;
    this.customValue = config.customValue;
    this.rowSpan = config.rowSpan;
    this.conditionalMeasure = config.conditionalMeasure;
    this.conditionalMeasureOnGroup = config.conditionalMeasureOnGroup;
    this.conditionalMeasureForGroups = config.conditionalMeasureForGroups;
    this.sortCustom = config.sortCustom;
    this.styling = config.styling;
    this.dataPointsFrequency = config.dataPointsFrequency;
    this.forceDataGrouping = config.forceDataGrouping;
    this.min = config.min;
    this.max = config.max;
    this.hideAtGroupLevels = config.hideAtGroupLevels;
    this.initialFieldPath = config.initialFieldPath;
    this.groupTotalValues = config.groupTotalValues;
    this.valueCustomFormat = config.valueCustomFormat;
  }

  code?: string;
  field: Field;
  isDefault: boolean;
  assetNameColumn?: boolean;
  sortBy?: string;
  visible: boolean;
  mode?: string;
  headerConfig: HeaderConfig;
  formatting?: ColumnFormatting;
  overrideSummability?: OverrideSummability;
  calcExpression?: CalcExpression;
  calcOnGroup?: boolean;
  calcAfterNormalise?: boolean;
  calcIgnoreFilter?: boolean;
  documentsPopup?: boolean;
  showFirstValueSubtotal?: boolean;
  limitsPopup?: boolean;
  hyperlinksPopup?: boolean;
  countOnAggregation: boolean;
  countDistinctOnAggregation: boolean;
  conditionalValueDisplay: Filter[];
  conditionalRowDisplay: Filter[];
  hyperlinks: Hyperlinks;
  hideSubtotal?: true;
  isDuplicateColumn: boolean;
  isRowCellEditable?: boolean;
  fieldDataPath: string;
  defaultSort?: SortOrder;
  absolute?: boolean;
  targetAxis?: ChartAxis;
  id: string;
  normalise: boolean;
  dataBars: boolean;
  displayMode?: boolean;
  quantFilters?: Filter[];
  hideZeroValues?: boolean;
  hidePath?: boolean;
  customValue?: string;
  rowSpan?: number;
  conditionalMeasure?: ConditionalMeasureObjectMapped;
  conditionalMeasureOnGroup?: boolean;
  conditionalMeasureForGroups?: string;
  sortCustom?: string[];
  styling?: Record<string, any>;
  dataPointsFrequency?: DataPointsFrequency;
  forceDataGrouping?: boolean;
  min?: number;
  max?: number;
  hideAtGroupLevels?: string[];
  initialFieldPath?: string;
  groupTotalValues?: boolean;
  valueCustomFormat?: (value: Primitive, row: Row) => Primitive;

  getAllUsedFields(): Field[] {
    const metas = ReportingService.metas[this.code ?? this.field.name];

    return concatenateUniqueIndexFields(
      [this.field].filter(it => it !== undefined),
      this.formatting ? this.formatting.getAllUsedFields() : [],
      metas?.aggregation?.weights ? [new Field(metas.aggregation.weights)] : [],
      metas?.aggregation?.reference ? [new Field(metas.aggregation.reference)] : [],
      this.calcExpression?.getAllUsedFields().filter(field => !field.name.endsWith(this.id)) || [], // maybe can be removed because we have getAllFieldsUsedInCalcExpressions
      this.conditionalValueDisplay.flatMap(filter => filter.getAllUsedFields()),
      Column.isLimitsPopup(this.field) ? PopupHelper.LimitPopupRequiredFields() : [],
      metas?.quantFilters?.length ? metas.quantFilters.map(qf => qf.field) : [],
      this.headerConfig.measureAsName ? [new Field(this.headerConfig.measureAsName)] : []
    );
  }

  private static isDocumentsPopup(field: Field) {
    return field.name === 'Popups.popups_documents';
  }

  private static isLimitsPopup(field: Field) {
    return field.name === 'Popups.popups_limits';
  }

  public getFieldNode() {
    const path = this.field.name.split(Field.fieldSeparator);

    return path[path.length - 2];
  }

  private static isHyperlinksPopup(hyperlinks: Hyperlinks) {
    return Boolean(Object.keys(hyperlinks).length);
  }
}

export { Column, ColumnFormatting };
