import { NoteDTO } from '@/modules/ERP/Contacts/types';
import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';

export const patchNote = (id: string, form: Partial<NoteDTO>): Promise<NoteDTO> => {
  return requestApi({
    service: 'collaboration',
    method: 'patch',
    url: `/api/notes/${id}`,
    data: form
  }).then((resp: AxiosResponse<NoteDTO> | undefined) => {
    return resp!.data;
  });
};
