import { requestApi } from '@/core';

export const getHubSpotToken = async (): Promise<string> => {
  return requestApi({
    method: 'post',
    service: 'account',
    url: `/api/hubspot/token`,
    config: {
      errors: false
    }
  }).then(resp => resp.data);
};
