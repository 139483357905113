import { UserInformation } from '@/common/auth/api/getUserInfo';
import EntityRef from './EntityRef';

export enum ConversationType {
  TASK = 'TASK',
  NOTE = 'NOTE'
}

export interface ConversationLookup {
  refId: number | string;
  refType: ConversationType;
  comments: CommentLookup[];
  title?: string;
}

export interface CommentLookup extends EntityRef<number | null> {
  user: UserInformation;
  content: string;
  createdDate: Date | string;
  lastModifiedDate: Date | string;
}

export class Conversation implements ConversationLookup {
  constructor(
    public refType: ConversationType,
    public refId: number | string,
    public comments: Comment[] = [],
    public title?: string,
    public id: number | null = null
  ) {}

  public static fromLookup(conversation: ConversationLookup & EntityRef) {
    return new Conversation(
      conversation.refType,
      conversation.refId,
      conversation.comments.map(comment => Comment.fromLookup(comment)),
      conversation.title,
      conversation.id
    );
  }
}

export class Comment implements CommentLookup, EntityRef<number | null> {
  constructor(
    public content: string,
    public user: UserInformation,
    public createdDate: Date = new Date(),
    public lastModifiedDate: Date = new Date(),
    public id: number | null = null
  ) {}

  public static fromLookup(lookup: CommentLookup) {
    return new Comment(lookup.content, lookup.user, new Date(lookup.createdDate), new Date(lookup.lastModifiedDate), lookup.id);
  }
}
