export const htmlContentIsEmpty = (htmlContent: string): boolean => {
  let toBeTested = htmlContent;

  let htmlTag = '';
  let captureHTMLTag = false;
  for (let i = 0; i < htmlContent.length; i++) {
    const currentChar = htmlContent.charAt(i);
    if (currentChar === '<') {
      captureHTMLTag = true;
    } else if (currentChar === '>') {
      break;
    } else if (captureHTMLTag) {
      htmlTag += currentChar;
    }
  }

  toBeTested = toBeTested.replace(`<${htmlTag}>`, '');
  toBeTested = toBeTested.replace(`</${htmlTag}>`, '');

  return toBeTested.trim().length === 0;
};
