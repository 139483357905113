import { BarChartOptions, LegendPosition } from '@/modules/reporting-v2/core/components/Highcharts/types';
import { HighChartsDataUtils } from '@/modules/reporting-v2/core/HighchartsDataUtils';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { SortOrder } from '@/modules/reporting-v2/types/VisualEngine';
import { ExtendedBarChartConfig } from '..';
import { BarChartConfigMapped } from './types';
import { AxisLabel, AxisScale, BarChartValueType, Orientation, Sorting } from './VisualSpecificProps';

export default class BarChartConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as BarChartConfigMapped));
  }

  getVisualConfigProps(config: BarChartConfigMapped): ExtendedBarChartConfig {
    const chartOptions = {
      ...config._options,
      legend: {
        ...(config._options?.legend as Record<string, unknown>),
        enabled: config.legend
      }
    };

    return Object.assign(super.getVisualConfigProps(config), {
      axisLabel: config.axisLabel,
      axisScale: config.axisScale,
      chartOptions: chartOptions,
      diagonalAxisLabel: config.diagonalAxisLabel,
      legend: config.legend,
      legendPosition: config.legendPosition,
      maxValue: config.maxValue,
      mergeSeriesData: config.mergeSeriesData,
      minValue: config.minValue,
      orientation: config.orientation,
      showLabelsOnGraph: config.showLabelsOnGraph,
      showPlusBeforeValue: config.showPlusBeforeValue,
      showTopSeries: config.showTopSeries,
      sortByBar: config.sortByBar,
      sorting: config.sorting,
      stacking: config.stacking,
      valueType: config.valueType,
      xAxisInterval: config.xAxisInterval,
      yAxisInterval: config.yAxisInterval,
      colorByPoint: config.colorByPoint
    });
  }

  setVisualConfig(config: ExtendedBarChartConfig) {
    if (config.sorting) {
      const firstColumn = config.columns.find(col => col.isDefault);
      config.sort = {
        order: config.sorting === Sorting.asc ? SortOrder.ASC : SortOrder.DESC,
        field: firstColumn?.field.name
      };
    }

    super.setVisualConfig(config);

    const chartOptions = HighChartsDataUtils.getOptionsFromGoogle<BarChartOptions>(config.chartOptions as any);

    this.axisLabel = config.axisLabel;
    this.axisScale = config.axisScale;
    this.chartOptions = chartOptions;
    this.diagonalAxisLabel = config.diagonalAxisLabel;
    this.legend = config.legend;
    this.legendPosition = config.legendPosition;
    this.maxValue = config.maxValue;
    this.mergeSeriesData = config.mergeSeriesData;
    this.minValue = config.minValue;
    this.orientation = config.orientation;
    this.showLabelsOnGraph = config.showLabelsOnGraph;
    this.showPlusBeforeValue = config.showPlusBeforeValue;
    this.showTopSeries = config.showTopSeries;
    this.sortByBar = config.sortByBar;
    this.stacking = config.stacking;
    this.valueType = config.valueType;
    this.xAxisInterval = config.xAxisInterval;
    this.yAxisInterval = config.yAxisInterval;
    this.colorByPoint = config.colorByPoint;
  }

  axisLabel: AxisLabel;
  axisScale: AxisScale;
  chartOptions?: BarChartOptions;
  diagonalAxisLabel: boolean;
  legend: boolean;
  legendPosition?: LegendPosition;
  maxValue?: number;
  mergeSeriesData?: boolean;
  minValue?: number;
  orientation: Orientation;
  showLabelsOnGraph: boolean;
  showPlusBeforeValue: boolean;
  showTopSeries?: number;
  sortByBar?: string[];
  stacking?: boolean;
  valueType: BarChartValueType;
  xAxisInterval?: number;
  yAxisInterval?: number;
  colorByPoint?: boolean;
}
