import { CustomColumn } from '@/modules/report-builder/components/Forms/Visual/Schema/CustomColumnsWrapper';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';

export default class CustomTableConfig extends VisualEngine {
  customColumns: CustomColumn[];

  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);

    this.customColumns = rawVisual.config.customColumns;
    this.total = true;
  }
}
