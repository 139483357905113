import { selector, selectorFamily } from 'recoil';
import { ComponentTemplateLookup } from '@/common/types/entity/ComponentTemplate';
import listComponentTemplates from '@/modules/report-builder/api/listComponentTemplates';
import listCustomComponents from '@/modules/report-builder/api/listCustomComponents';
import { GlobalReportConfig, RawReportConfig, VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { userIsCustomComponentLibraryReader } from '@/common/recoil/security.selectors';
import { RBConfigurationState, selectedVisualState, targetPageState } from './atoms';
import { defaultReportConfig } from '../fixtures/default-config';

export const getPages = selector({
  key: 'report-builder-pages',
  get: ({ get }) => {
    const config = get(RBConfigurationSelector);
    return config.pages;
  }
});

export const RBConfigurationSelector = selector<RawReportConfig>({
  key: 'report-builder-config-selector',
  get: ({ get }) => {
    const rbState = get(RBConfigurationState);

    const reportConfig: RawReportConfig = {
      ...defaultReportConfig,
      ...rbState,
      config: {
        ...defaultReportConfig.config,
        ...rbState.config,
        filters: {
          ...defaultReportConfig.config.filters,
          ...rbState.config.filters
        },
        params: rbState.config.params, // when report was just created is {}
        margin: {
          ...defaultReportConfig.config.margin,
          ...rbState.config.margin
        }
      }
    };

    return reportConfig;
  },
  set: ({ set }, newConfig) => {
    set(RBConfigurationState, newConfig);
  }
});

export const componentSelector = selectorFamily<any, string>({
  key: 'report-builder-components-state-selector',
  get:
    id =>
    ({ get }) => {
      return get(RBConfigurationSelector)
        .pages?.find(page => page.id === get(targetPageState)!)
        ?.components?.find(component => component.id === id);
    },
  set:
    id =>
    ({ set, get }, newVal) => {
      return set(RBConfigurationSelector, report => {
        const currentPage = get(targetPageState);

        const newValues = {
          ...report,
          pages: report.pages?.map(page => {
            if (page.id !== currentPage) {
              return page;
            }

            const components = page.components?.map(component => (component.id !== id ? component : newVal));
            return { ...page, components };
          })
        };

        return newValues;
      });
    }
});

export const selectedComponentTypeSelector = selector<VisualComponent>({
  key: 'report-builder-selected-component-type',
  get: ({ get }) => {
    const id = get(selectedVisualState);
    const visual = get(componentSelector(id!));
    return visual?.component;
  }
});

export const RBGlobalConfigurationSelector = selector<GlobalReportConfig>({
  key: 'report-builder-global-config-selector',
  get: ({ get }) => {
    const rbState = get(RBConfigurationSelector);
    return rbState.config;
  },
  set: ({ set }, newConfig) => {
    return set(RBConfigurationSelector, prevVal => ({
      ...prevVal,
      config: { ...prevVal.config, ...newConfig }
    }));
  }
});

export const componentTemplatesSelector = selector<ComponentTemplateLookup[]>({
  key: 'component-template-selector',
  get: async ({ get }) => {
    const isCustomComponentLibReader = get(userIsCustomComponentLibraryReader);
    const templates = get(sesameTemplateComponentsSelector);

    if (!isCustomComponentLibReader) {
      return templates;
    }

    const customComponents = get(customComponentsSelector);
    return customComponents.concat(templates);
  }
});

export const sesameTemplateComponentsSelector = selector<ComponentTemplateLookup[]>({
  key: 'sesame-template-components-selector',
  get: async () => {
    return listComponentTemplates();
  }
});

export const customComponentsSelector = selector<ComponentTemplateLookup[]>({
  key: 'custom-components-selector',
  get: async () => {
    return listCustomComponents();
  }
});
