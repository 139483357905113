import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { UUID } from '@/common/types/types';
import { CustomReport, CustomReportRef, TransientCustomReport } from '../../../common/types/entity/CustomReport';

export const createCustomReport = async (report: TransientCustomReport, spaceId: UUID): Promise<CustomReport & CustomReportRef> => {
  report.spaceId = spaceId;

  return requestApi({
    service: 'cms',
    method: 'post',
    url: '/api/custom-reports',
    data: report
  }).then((response: AxiosResponse<CustomReport & CustomReportRef>) => response?.data);
};
