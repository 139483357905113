import { UUID } from '../types';
import EntityRef from './EntityRef';
import { CustomMetadataFieldType } from '@/modules/ERP/Common/MetadataProperties';

export enum MetadataEntityType {
  ASSET = 'ASSET',
  PORTFOLIO = 'PORTFOLIO',
  CONTACT = 'CONTACT',
  DOCUMENT = 'DOCUMENT',
  USER = 'USER',
  LEGAL_ENTITY = 'LEGAL_ENTITY'
}

export interface MetadataKeyDto extends EntityRef<UUID> {
  name: string;
  entityType: MetadataEntityType;
  colourMap?: Record<string, string>;
  format: CustomMetadataFieldType;
  origin: MetadataOrigin;
  visibleInSummary: boolean;
  value?: string;
  decimalNumber?: number;
  lastModifiedDate: string;
  lastModifiedBy: string;
  visibleInReport: boolean;
  readOnly: boolean;
  allowedValues: string[];
  /***
   Metadata keys could be created on ACCOUNT or SPACE level:
   ACCOUNT
   securityPolicy - empty or ACCOUNT_LEVEL
   securityIds - empty
   SPACE
   securityPolicy = SPACE_LEVEL
   securityIds = array of space ids
   ***/
  securityPolicy?: MetadataSecurityPolicy;
  securityIds?: Array<UUID>;
}

export enum MetadataOrigin {
  LANDYTECH = 'LANDYTECH',
  CUSTOM = 'CUSTOM'
}

export enum MetadataSecurityPolicy {
  SPACE_LEVEL = 'SPACE_LEVEL',
  ACCOUNT_LEVEL = 'ACCOUNT_LEVEL'
}

export interface MetadataKeyCreationPayload {
  name: string;
  colourMap?: Record<string, string>;
  entityType: MetadataEntityType;
  format: CustomMetadataFieldType;
  decimalNumber?: number;
  origin: MetadataOrigin;
  visibleInSummary: boolean;
  visibleInReport: boolean;
  readOnly: boolean;
  allowedValues?: string[];
  /***
   Metadata keys could be created on ACCOUNT or SPACE level:
      ACCOUNT
        securityPolicy - empty or ACCOUNT_LEVEL
        securityIds - empty
      SPACE
        securityPolicy = SPACE_LEVEL
        securityIds = array of space ids
   ***/
  securityPolicy?: MetadataSecurityPolicy;
  securityIds?: Array<UUID>;
}

export interface MetadataValueDto {
  id?: string;
  value: string;
  entityId: string;
  keyId: UUID;
  keyName?: string;
}

export interface UpdatedAllowedValueDto {
  oldValue: string;
  newValue: string;
}
