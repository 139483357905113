import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ReactQuill, { Quill as RQuill } from 'react-quill';
import * as Quill from 'quill';
import ImageResize from 'quill-image-resizer-plugin';
import { secureRandom } from '@/utils/secureRandom';

RQuill.register('modules/imageResize', ImageResize);

// this module is to workaround a nasty issue with preserving white spaces (they got stripped out):
// https://github.com/quilljs/quill/issues/1752
// https://github.com/quilljs/quill/issues/2459
// https://github.com/quilljs/quill/issues/1751
class PreserveWhiteSpace {
  constructor(private quill: any, private options: {}) {
    quill.container.style.whiteSpace = 'pre-line';
  }
}

RQuill.register('modules/preserveWhiteSpace', PreserveWhiteSpace);

// this module is used to workaround an issue with links and mention module:
// https://github.com/afry/quill-mention/issues/213
class ClickableLink {
  constructor(private quill: any, private options: {}) {
    quill.container.addEventListener('click', (evt: any) => {
      if (evt.target.tagName === 'A') {
        window.open(evt.target.href, '_blank');
      }
    });
  }
}

RQuill.register('modules/clickableLink', ClickableLink);

// redefined interfaces and properties to workaround the issue: https://github.com/zenoamaro/react-quill/issues/640
export interface UnprivilegedEditor {
  getLength(): number;

  getText(index?: number, length?: number): string;

  getHTML(): string;

  getBounds(index: number, length?: number): Quill.BoundsStatic;

  getSelection(focus?: boolean): Quill.RangeStatic;

  getContents(index?: number, length?: number): Quill.DeltaStatic;
}

export interface ComponentProps {
  id?: string;
  className?: string;
  theme?: string;
  style?: React.CSSProperties;
  readOnly?: boolean;
  value?: string | Quill.Delta;
  defaultValue?: string | Quill.Delta;
  placeholder?: string;
  tabIndex?: number;
  bounds?: string | HTMLElement;
  scrollingContainer?: string | HTMLElement;
  onChange?: (content: string, delta: Quill.Delta, source: Quill.Sources, editor: UnprivilegedEditor) => void;
  onChangeSelection?: (range: Quill.RangeStatic, source: Quill.Sources, editor: UnprivilegedEditor) => void;
  onFocus?: (range: Quill.RangeStatic, source: Quill.Sources, editor: UnprivilegedEditor) => void;
  onBlur?: (previousRange: Quill.RangeStatic, source: Quill.Sources, editor: UnprivilegedEditor) => void;
  onKeyPress?: React.EventHandler<any>;
  onKeyDown?: React.EventHandler<any>;
  onKeyUp?: React.EventHandler<any>;
  formats?: string[];
  children?: React.ReactElement<any>;
  modules?: Quill.StringMap;
  preserveWhitespace?: boolean;
}

/**
 * This component encapsulated various fixes for the original Quill component:
 * https://github.com/zenoamaro/react-quill/issues/259
 * https://github.com/quilljs/quill/issues/1940
 */
export const ReactQuillAdapted: FC<ComponentProps> = ({ onChange, ...rest }) => {
  const { defaultValue } = rest;

  const [key, setKey] = useState(randomString());

  useEffect(() => {
    setKey(randomString());
  }, [defaultValue]);

  const _onChange = useCallback(
    (content, delta, source, editor) => {
      if (source !== 'api') {
        onChange && onChange(content, delta, source, editor);
      }
    },
    [onChange]
  );

  // Quill does not like when it is used in controlled mode (see the issues above),
  // so we are forces to use "defaultValue",
  // a span with a changing key will force Quill to rerender if defaultValue changes
  return (
    <span key={key}>
      <ReactQuill {...rest} onChange={_onChange} />
    </span>
  );
};

export function useQuillModules(base: Quill.StringMap, ...extra: Quill.StringMap[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => Object.assign(base, ...extra), [base, ...extra]);
}

export const quillModulesFull: Quill.StringMap = {
  imageResize: {
    modules: ['Resize', 'DisplaySize']
  },
  clickableLink: true,
  preserveWhiteSpace: true,
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    ['clean']
  ]
};
export const quillModulesBasic: Quill.StringMap = {
  imageResize: {
    modules: ['Resize', 'DisplaySize']
  },
  clickableLink: true,
  preserveWhiteSpace: true,
  toolbar: [['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }], ['link', 'image'], ['clean']]
};
export const quillFormats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'width', 'mention'];

function randomString() {
  return 'r_' + secureRandom().toString(36).slice(2, 6);
}
