import React from 'react';
import { Select } from 'antd';
import { withSmallSuspense } from '@/common/suspense';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { usersLookupsSelector } from '@/modules/ERP/Contacts/Recoil/contacts.selectors';
import { CaretDownOutlined } from '@ant-design/icons';
import { TaskFormType } from '../CreateTask';

interface SelectListUsersProps {
  onChange: (userId: number) => void;
  values: TaskFormType;
}

const SelectListUsers: React.FunctionComponent<SelectListUsersProps> = withSmallSuspense(({ onChange, values }) => {
  const translator = useIntl();
  const users = useRecoilValue(usersLookupsSelector);

  return (
    <Select
      popupClassName={'select-list-users-dropdown'}
      className="select-list-users"
      value={values.assignee?.id}
      showSearch
      size="small"
      variant="borderless"
      suffixIcon={<CaretDownOutlined className={'modal-contact-create-clickable-icon'} />}
      onChange={onChange}
      placeholder={translator.formatMessage({ id: 'generic.select' })}
      options={users.map(user => ({
        id: user.id,
        label: user.name,
        value: user.id,
        key: user.id
      }))}
      filterOption={(input, option) => {
        const isMatchingInput = option?.label?.toString()?.toLowerCase()?.indexOf(input?.toLowerCase());
        return isMatchingInput !== undefined && isMatchingInput >= 0;
      }}
    />
  );
});

export { SelectListUsers };
