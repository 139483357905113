import React from 'react';
import { Badge, Empty, Popover, Select, Space, Spin } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import stopEventPropagation from '@/utils/stopEventPropagation';
import '@/modules/reporting-v2/core/components/DataTable/components/styles.css';
import useLimitsList from '@/modules/reporting-v2/core/components/DataTable/components/Limits/useLimitsList';
import { SingleLimit } from '@/modules/reporting-v2/core/components/DataTable/components/Limits/SingleLimit';
import { Limit } from '@/modules/reporting-v2/core/components/DataTable/components/Limits/LimitsTypes';
import { useIntl } from 'react-intl';
import { nanoid } from 'nanoid';
import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import { PopupHelper } from '@/utils/PopupHelper';
import { useLimitBreachContext } from '@/modules/reporting-v2/core/visuals/DashboardTable/LimitBreachContext';

interface IProps {
  alertsCount: number;
  warningsCount: number;
  holdingSetId: number;
}

type LimitsWrapperProps = {
  data: FlattenObject;
};

export const LimitsWrapper: React.FC<LimitsWrapperProps> = ({ data }) => {
  const hsId = data[PopupHelper.LimitHoldingSetIdPath] ?? data[PopupHelper.HoldingSetIdPath];
  const limitsNumber = useLimitBreachContext();

  let alertsCount = 0;
  let warningsCount = 0;
  if (typeof hsId === 'number' || typeof hsId === 'string') {
    alertsCount = limitsNumber[Number(hsId)]?.breach ?? 0;
    warningsCount = limitsNumber[Number(hsId)]?.warning ?? 0;
  }

  return <Limits alertsCount={alertsCount} warningsCount={warningsCount} holdingSetId={hsId as number} />;
};

const Limits: React.FC<IProps> = React.memo(({ alertsCount, warningsCount, holdingSetId }) => {
  let numberToShow;
  let status: undefined | 'error' | 'warning';

  if (alertsCount > 0) {
    numberToShow = alertsCount;
    status = 'error';
  } else if (warningsCount > 0) {
    numberToShow = warningsCount;
    status = 'warning';
  }

  const showAlerts = Boolean(numberToShow);

  return (
    <div className="limits-popup-icon-wrapper" onClick={stopEventPropagation}>
      <Popover content={<List holdingSetId={holdingSetId} />} overlayClassName="dashboard-limits-popup-overlay" trigger="click" placement="topRight">
        {!showAlerts ? (
          <CheckCircleOutlined className="antd-icon-limits-dashboard-checked" />
        ) : (
          <Badge className="limits-popup-badge" count={numberToShow} status={status} size="small" />
        )}
      </Popover>
    </div>
  );
});

interface IListProps {
  holdingSetId: number;
}

const List: React.FC<IListProps> = React.memo(({ holdingSetId }) => {
  const { fetching, categories, limitsByCategory, categoryFilter, setCategoryFilter, showShareClassNames, setShowShareClassNames } = useLimitsList(holdingSetId);
  const translator = useIntl();

  if (fetching) {
    return <Spin size="small" style={{ margin: '24px auto' }} />;
  }

  return !Object.keys(limitsByCategory).length ? (
    <Empty />
  ) : (
    <div onClick={stopEventPropagation} className="antd-popover-popups-dashboard">
      <Select
        onChange={setCategoryFilter}
        value={categoryFilter}
        allowClear
        placeholder={translator.formatMessage({ id: 'report.table.selectACategory' })}
        className="antd-limits-popups-select"
      >
        {categories.map(category => (
          <Select.Option value={category} key={category + nanoid()}>
            <strong>{category}</strong>
          </Select.Option>
        ))}
      </Select>

      <div>
        {Object.entries(limitsByCategory).map(([category, _limits]) => {
          let status: undefined | 'error' | 'warning';
          for (const limit of _limits) {
            if (limit.valueStatus === 'ALERT') {
              status = 'error';
              break;
            }
          }

          if (!status) {
            status = 'warning';
          }

          return (
            <React.Fragment key={category}>
              <h3 className="limits-popups-h3">
                <Space size="small" direction="horizontal">
                  <strong>{category}</strong>
                  <Badge status={status} count={_limits.length} size="small" />
                </Space>
              </h3>

              {_limits.map((limit: Limit) => {
                return <SingleLimit key={limit.name + nanoid()} limit={limit} showShareClassNames={showShareClassNames} setShowShareClassNames={setShowShareClassNames} />;
              })}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
});

export default Limits;
