import { SortOrder, VisualConfigProps } from '@/modules/reporting-v2/types/VisualEngine';

export default interface ExtendedHistoricalMonthlyTabConfig extends VisualConfigProps {
  transposeMonthlyTab: boolean;
  dateRange: keyof DateRange;
  totalMetric: string | undefined;
  dataFrequency: DataFrequency;
  transposedMonthOrder?: SortOrder;
  subTotal?: boolean;
  showGrandTotal?: boolean;
}

export enum DateRange {
  inception = 'From inception',
  lastTwelveMonths = 'Last 12 months',
  currentYear = 'Current year'
}

export enum cellDateRange {
  lastTwelveMonths = 'lastTwelveMonths',
  currentYear = 'currentYear',
  lastThreeYears = 'lastThreeYears'
}

export enum DataFrequency {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Yearly = 'yearly'
}
