import React from 'react';
import DataTable from './DataTable';
import { IDataTableProps } from './DataTable/DataTableTypes';
import { MeasureRenderTime } from '@/modules/reporting-v2/utils/MeasureRenderTime';

export const MemoizedTable: React.FC<{ visual: IDataTableProps['visual'] }> = React.memo(
  ({ visual }) => (
    <MeasureRenderTime id={visual.id} label={`OLD TABLE: ${visual.component} / ${visual.title} `}>
      <DataTable visual={visual} />
    </MeasureRenderTime>
  ),
  (prevProps, nextProps) => prevProps.visual.updateDataRefId === nextProps.visual.updateDataRefId
);
