import { ScatterChartOptions } from '@/modules/reporting-v2/core/components/Highcharts/types';
import { HighChartsDataUtils } from '@/modules/reporting-v2/core/HighchartsDataUtils';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedScatterChartConfig } from '..';
import { ScatterChartConfigMapped } from './types';
import { DateRange, RankingType, Serie } from './VisualSpecificProps';

export default class ScatterChartConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as ScatterChartConfigMapped));
  }

  getVisualConfigProps(config: ScatterChartConfigMapped): ExtendedScatterChartConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      trendline: config.trendline,
      legend: config.legend,
      axisLabel: config.axisLabel,
      dateRange: config.dateRange,
      series: config.series,
      chartOptions: config._options,
      rankingField: config.rankingField,
      rankingType: config.rankingType,
      rankingNumber: config.rankingNumber,
      showEmptyValues: config.showEmptyValues,
      showGroupChildren: config.showGroupChildren
    });
  }

  setVisualConfig(config: ExtendedScatterChartConfig) {
    super.setVisualConfig(config);
    this.trendline = config.trendline;
    this.legend = config.legend;
    this.axisLabel = config.axisLabel;
    this.dateRange = config.dateRange;
    this.series = config.series;
    this.chartOptions = HighChartsDataUtils.getOptionsFromGoogle<ScatterChartOptions>(config.chartOptions as any);
    this.rankingField = config.rankingField;
    this.rankingType = config.rankingType;
    this.rankingNumber = config.rankingNumber;
    this.showEmptyValues = config.showEmptyValues;
    this.showGroupChildren = config.showGroupChildren;
  }

  rankingField?: string;
  rankingType?: RankingType;
  rankingNumber?: number;
  trendline: boolean;
  legend: boolean;
  axisLabel: boolean;
  dateRange: DateRange;
  series: Serie[];
  chartOptions?: ScatterChartOptions;
  showEmptyValues: boolean;
  showGroupChildren: boolean;
}
