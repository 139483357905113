import dayjs from 'dayjs';
import { FieldResolver } from '@/modules/reporting-v2/types/FieldResolver';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { FilterOperator } from '@/types/Filters';
import { Field } from './Field';

class Condition implements FieldResolver {
  constructor(operator: FilterOperator, values: Array<Field | Primitive | dayjs.Dayjs>) {
    this.operator = operator;
    this.values = values;
  }

  operator: FilterOperator;
  values: Array<Field | Primitive | dayjs.Dayjs>;

  getAllUsedFields(): Field[] {
    if (this.values instanceof Field) return [this.values];
    else return [];
  }
}

export { Condition };
