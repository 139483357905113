import { HoldingSetLookup } from '../types/entity/HoldingSetLookup';
import { HoldingSetTypeBadges, UncategorizedBadge } from './Badges';
import { FormattedMessage } from 'react-intl';
import React, { ReactElement } from 'react';

export interface HoldingSetLabelProps {
  holdingSet?: HoldingSetLookup;
  defaultLabel?: string | ReactElement;
}

export default function ({ holdingSet, defaultLabel = <FormattedMessage id={'generic.uncategorized'} /> }: HoldingSetLabelProps) {
  return holdingSet ? (
    <>
      {HoldingSetTypeBadges[holdingSet.type]}
      {holdingSet.name}
    </>
  ) : (
    <>
      {UncategorizedBadge}
      {defaultLabel}
    </>
  );
}
