import React, { useMemo } from 'react';
import { HoldingSet } from '@/modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';
import { Column, ColumnFormatting } from '@/modules/reporting-v2/core/Column';
import { YearData } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/index';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import fastSort from 'fast-sort';
import { DataFrequency } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/VisualSpecificProps';
import { FormatType } from '@/common/types/elastic/FormatType';
import { HeaderConfig } from '@/modules/reporting-v2/utils/HeaderConfig';
import HistoricalMonthlyTabConfig from 'modules/reporting-v2/core/visuals/HistoricalMonthlyTab/HistoricalMonthlyTabConfig';
import { useIntl } from 'react-intl';
import { nanoid } from 'nanoid';
import { MemoizedDataTablev2 } from '@/modules/reporting-v2/core/components/DataTablev2/DataTable';
import type { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import type { Row } from '@/modules/reporting-v2/types/VisualEngine';
import { getDateValue } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/utils/getDateValue';

type HistoricalTransposeNotGroupedTableProps = {
  entities: HoldingSet[];
  visual: HistoricalMonthlyTabConfig;
  reportLocale: string | undefined;
  dataGroupedByMonthAndYear: Record<string, YearData[]>;
};

export const HistoricalTransposeNotGroupedTable: React.FC<HistoricalTransposeNotGroupedTableProps> = ({ entities, visual, reportLocale, dataGroupedByMonthAndYear }) => {
  const translator = useIntl();
  const visualColumns = useMemo(() => visual.columns.filter(col => col.isDefault), [visual]);
  const getColumns = (column: Column, dataFrequency: DataFrequency, multipleEntities: boolean, visualColumns: Column[]) => {
    let columns: Column[] = [];

    columns.push({
      ...column,
      fieldDataPath: column.fieldDataPath + '.month',
      formatting: new ColumnFormatting(undefined, FormatType.string),
      headerConfig: new HeaderConfig(translator.formatMessage({ id: 'generic.month' })),
      valueCustomFormat: (value: Primitive) => {
        if (typeof value !== 'string') {
          return value;
        }
        const [year, month] = value.split('-');
        const dateValue = getDateValue(month, year, dataFrequency, reportLocale);
        const prefix = dataFrequency === DataFrequency.Yearly && year.isFull === false ? '*' : '';

        return prefix + dateValue;
      }
    } as Column);

    if (multipleEntities) {
      columns.push({
        ...column,
        fieldDataPath: column.fieldDataPath + '.entity',
        formatting: new ColumnFormatting(undefined, FormatType.string),
        headerConfig: new HeaderConfig(translator.formatMessage({ id: 'generic.entity' }))
      } as Column);
    }

    columns = [...columns, ...visualColumns];

    return columns;
  };
  const getRows = (multipleEntities: boolean, dataGroupedByMonthAndYear: Record<string, YearData[]>, entities: HoldingSet[]) => {
    const rows: Row[] = [];

    entities.forEach(entity => {
      fastSort(dataGroupedByMonthAndYear[entity.id])
        .asc(year => year.year)
        .forEach(year => {
          fastSort(year.months)
            .asc(month => month.month)
            .forEach(month => {
              const newCells = [];
              let newData: FlattenObject = {};
              const resultMonthValue = year.year + '-' + month.month + '-01';

              newCells.push(resultMonthValue);

              if (multipleEntities) {
                newCells.push(entity.name);
              }

              visualColumns.forEach((col, i) => {
                const data = month.row.data;
                const elasticPath = col.fieldDataPath;
                const value = data[elasticPath];

                newCells.push(value);
                if (i === 0) {
                  newData = { ...data };
                }
              });

              rows.push({
                __id__: nanoid(10),
                cells: newCells,
                data: newData
              });
            });
        });
    });

    return rows;
  };
  const createVisual = () => {
    const multipleEntities = entities.length > 1;
    const dataFrequency = visual.dataFrequency;
    const column = visualColumns[0];

    return {
      ...visual,
      columns: getColumns(column, dataFrequency, multipleEntities, visualColumns),
      data: {
        rows: getRows(multipleEntities, dataGroupedByMonthAndYear, entities)
      }
    } as unknown as VisualEngine;
  };

  return <MemoizedDataTablev2 visual={createVisual()} />;
};
