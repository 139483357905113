import { getTheme } from '@/modules/reporting-v2/utils/theme';
import { rbColorToHexAlpha } from '@/utils/toHexAlpha';
import type { HeaderFooterGenerator, MergedReportBrandingStyles } from '@/modules/reporting-v2/types/HeaderFooter';
import { calculateWidth, getFontStyles, getFooterLogoPosition, getMainContent, getPaginationContent } from './utils';
import { getBrandingStyle } from '../utils';
import { Branding } from '@/common/types/app/User';
import { FooterLogoPositionType } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';

const footerHeight = 32;
const ratio = 0.8;
const defaultLogoWidth = 8;

const getLogoContent = (branding: Branding) => {
  if (!branding.logoSmallUrl) {
    return '';
  }

  return `<td class="logo-content">
    <div class="img-wrapper">
        <img
          src="[$image]${branding.logoSmallUrl}[$image]" alt="alt"
        />
      </div>
  </td>`;
};

export const sfFooter: HeaderFooterGenerator = ({ title, pageProperties, reportStyle, entityParam, pageType, user }): string => {
  const style = {
    ...getBrandingStyle(user.company.branding),
    ...reportStyle
  } as MergedReportBrandingStyles;
  const { footerTitle = true, pagination = true, paginationAlign = 'right' } = style;

  const hideFooter = style.footerHide === true;
  const footerHasNoContent = !footerTitle && (!pagination || style.paginationPosition !== 'footer');
  if (hideFooter || footerHasNoContent) {
    return '<style>#footer { display: none; }</style>';
  }
  const logoPosition = getFooterLogoPosition(style.footer?.logoPosition, style.footerLogoPosition) ?? FooterLogoPositionType.center;
  const logoWidth = style.footer?.logoWidth ?? style.footerLogoWidth ?? defaultLogoWidth;
  const { contentWidth, paginationWidth } = calculateWidth(logoPosition, logoWidth);

  const paginationContent = getPaginationContent(style, paginationAlign, pagination, pageType);
  const mainContent = getMainContent(title, style, footerTitle, paginationAlign, entityParam, user);

  const logoContent = getLogoContent(user.company.branding);

  const borderTop = (() => {
    if (style.footerBorder) {
      return `${style.footerBorder?.style || 'none'} ${style.footerBorder?.width}px ${rbColorToHexAlpha(style.footerBorderColor) || 'transparent'}`;
    }

    return '1px solid #006237';
  })();

  return `
  <style>
    img {
      height: 18px;
      margin-right: 8px;
    }
    .img-wrapper {
      width: 100%;
    }
    img {
      display: block;
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin: 0 auto;
    }
    #footer {
      padding: 0;
      height: 100%;
      width: 100%;
      position: relative;
      -webkit-print-color-adjust: exact;
    }
    #footer .footer-container {
      width: 100%;
      height: 42px;
      ${getFontStyles(style)}
      line-height: ${footerHeight * ratio}px;
      // background: ${rbColorToHexAlpha(style.footerBackground) || 'none'};
      border-top: ${borderTop};
      margin: 0 ${32 * ratio * pageProperties.ratio}px;
      -webkit-print-color-adjust: exact;
    }
    #footer table {
      margin-top: 6px;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
    }

    td.main-content {
      width: ${contentWidth}%;
    }

    td.pagination-content  {
      width: ${paginationWidth}%;
    }

    td.logo-content {
      width: ${logoWidth}%;
    }

    td.logo-content img {
      width: 100%;
      height auto;
    }
  </style>
  <div id="footer" class="report-theme-${getTheme()}">
    <div class="footer-container">
      <table>
        <tr>
        ${logoPosition === FooterLogoPositionType.left ? logoContent : mainContent}
        ${logoPosition === FooterLogoPositionType.left ? mainContent : logoContent}
        ${paginationContent}
        </tr>
      </table>
    </div>
  </div>
  `;
};
