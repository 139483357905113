import { requestApi } from '@/core';
import { EnumDto } from '@/modules/CustodialData/types';
import { ApiService } from 'ui-sesame-components';

export const getLegalEntityTypes = (): Promise<EnumDto[]> => {
  return requestApi({
    url: `/api/external-entities/v2/legal-entity-types`,
    service: ApiService.CLIENT,
    method: 'GET'
  }).then(({ data }) => data);
};
