import { DEFAULT, ViewConfig, ViewTypeConfig } from './ViewType';
import TransientDocumentTag from './TransientDocumentTag';
import Params from './Params';
import { DocumentTagType } from '../../../common/types/entity/DocumentTags';

export class ViewMode {
  constructor(
    public readonly viewConfig: ViewConfig = DEFAULT,
    public readonly tag?: TransientDocumentTag
  ) {}

  getMenuKey = () => ViewMode.menuKey(this.viewConfig, this.tag);

  toParams(): Map<string, any> {
    return new Map<string, any>(Object.entries({ [Params.view]: this.viewConfig.title }));
  }

  static fromParams = (params: Map<string, any>, tag?: TransientDocumentTag): ViewMode => {
    let config;
    if (tag?.type === DocumentTagType.HOLDING_SET || tag?.type === DocumentTagType.ASSET) {
      config = ViewTypeConfig.find(cfg => cfg.tag.name === tag.name);
    } else {
      config = ViewTypeConfig.find(cfg => cfg.title === params.get(Params.view));
    }
    return config ? new ViewMode(config, tag) : new ViewMode();
  };

  static menuKey = (viewConfig: ViewConfig, tag?: TransientDocumentTag) => {
    let key = viewConfig.title;

    if (tag) {
      key += ` (${tag.name})`;
    }

    return key;
  };
}
