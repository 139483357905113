import { VisualConfigProps } from '@/modules/reporting-v2/types/VisualEngine';

export default interface ExtendedStatsTableConfig extends VisualConfigProps {
  mode: Mode[];
  transpose: boolean;
}

export enum Mode {
  entity = 'entity',
  benchmark = 'benchmark',
  shareClass = 'shareClass'
}
