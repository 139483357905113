import { requestApi } from '@/core';
import { AssetType, AssetTypeCategory } from '@/modules/Onboarding/Shared/types';

export const getAssetTypes = (category: AssetTypeCategory | null): Promise<AssetType[]> =>
  requestApi({
    method: 'GET',
    service: 'asset',
    url: `/internal/api/asset-types`,
    params: { category }
  }).then(response => response.data);
