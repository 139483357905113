import { Dispatch, SetStateAction, useEffect, useMemo, useReducer } from 'react';

/**
 * A helper class/wrapper for the React.useState hook.
 * Instances of this class can be safely shared between react components,
 * this gives ability to share a stateful (with ability to change its value) property
 * of a component state upwards or downwards in the tree of components.
 * Basic usage (see more examples below):
 *
 * const state = State.for("State Value").use();
 * <span onClick={ () => { state.val = "Updated Value" } } >{ state.val }</span>
 */
export class State<T> {
  private value: T;
  private dispatch?: Dispatch<SetStateAction<T>>;
  private mounted: undefined | boolean = undefined;

  private constructor(private initial: T) {
    this.value = initial;
  }

  protected get(): T {
    return this.value;
  }
  protected set(value: T) {
    this.value = value;
    if (this.dispatch === undefined) {
      // this state has never been rendered (yet)
      this.initial = value;
    }
    (this.mounted === undefined || this.mounted) && this.dispatch && this.dispatch(value);
  }

  public get val(): T {
    return this.get();
  }
  public set val(value: T) {
    this.set(value);
  }

  /**
   * Connects this state with react rendering model.
   * Move the call of this method in the tree of components closer to the components where it is used,
   * the closer the call, the lesser re-rendering overhead (less components to re-render).
   */
  public use(): State<T> {
    // useReducer is used here as it is guaranteed by React that its dispatcher instance won't be changed
    // during/between renderers (although it is not actually 100% true)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, dispatch] = useReducer<(prev: T, action: any) => T>(
      (prev: T, action: any) => {
        return action;
      },
      // if dispatch instance changes (e.g. in case of a structural change of the tree of components,
      // i.e. a component gets wrapped into another), then we need to make sure we keep current value,
      // hence we pass it as the initial.
      this.dispatch === undefined ? this.initial : this.value
    );

    this.dispatch = dispatch;
    this.value = value;

    return this;
  }

  /**
   * Use this factory method to create a new state within a functional component.
   * Usage:
   *
   * const ExampleComponent = () => {
   *   const state = State.for("State Value").use();
   *   return <span onClick={ () => { state.val = "Updated name" } } >{ state.val }</span>
   * }
   *
   * @param initial initial value
   */
  public static for<T>(initial: T): State<T> {
    return useMemo(() => new State<T>(initial), [initial]); // eslint-disable-line
  }

  /**
   * Use this factory method to create a new state outside of functional components.
   *
   * Important: make sure you call .use() later within a functional component where it is being used.
   * Usage:
   *
   * class AppModel {
   *   name = State.init("Someone's name");
   *   color = State.init("red");
   * }
   *
   * const RootComponent = () => {
   *   const model = new AppModel();
   *   model.name.use();
   *   return (
   *     <>
   *       <ChildNameComponent name={ model.name } />
   *       <ChildColorComponent model={ model } />
   *     </>
   *   );
   * }
   *
   * const ChildNameComponent = ({ name }: { name: State<string> }) => {
   *   return <>{ name.val }</>
   * }
   *
   * const ChildColorComponent = ({ model }: { model: AppModel }) => {
   *   model.color.use();
   *   return <span onClick={ () => { model.name.val = "Updated name" } } >{ model.color.val }</span>
   * }
   *
   * @param initial initial value
   */
  public static init<T>(initial: T): State<T> {
    return new State<T>(initial);
  }

  /**
   * Experimental feature.
   */
  public useSafe(): State<T> {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      this.mounted = true;
      return () => {
        this.mounted = false;
      };
    });
    return this.use();
  }
}
