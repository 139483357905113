import { DataFrequency } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/VisualSpecificProps';

type TableMonth = { month: string; key: string; monthIndex: number; frequencyKey?: string };

export const getTableMonths = (locale?: string, dataFrequency?: DataFrequency): Array<TableMonth> => {
  if (dataFrequency === DataFrequency.Yearly) {
    return [{ month: '', key: '12', monthIndex: 12, frequencyKey: undefined }];
  }
  if (dataFrequency === DataFrequency.Quarterly) {
    return [
      { month: 'Q1', key: '03', monthIndex: 3, frequencyKey: '1' },
      { month: 'Q2', key: '06', monthIndex: 6, frequencyKey: '2' },
      { month: 'Q3', key: '09', monthIndex: 9, frequencyKey: '3' },
      { month: 'Q4', key: '12', monthIndex: 12, frequencyKey: '4' }
    ];
  }
  // @todo: when the whole App is translated into French, rework this lines
  const monthNamesFr = ['janv', 'févr', 'mars', 'avr', 'mai', 'juin', 'juill', 'août', 'sept', 'oct', 'nov', 'déc'];
  const monthNamesEn = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthNamesToUse = locale === 'fr-FR' ? monthNamesFr : monthNamesEn;
  return monthNamesToUse.map((monthName, i) => {
    return {
      month: monthName,
      key: (i + 1).toString().padStart(2, '0'),
      frequencyKey: (i + 1).toString().padStart(2, '0'),
      monthIndex: i + 1
    };
  });
};
