import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ConversationType, Comment as CommentEntity } from '../../common/types/entity/Conversation';
import './styles.css';
import EditableComment from './components/EditableComment';
import ReadonlyComment from './components/ReadonlyComment';
import deleteComment from './api/deleteComment';
import { Divider } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { companyUsers, currentUser } from '../../recoil/users';
import { quillModulesFull, useQuillModules } from '../../common/ReactQuillAdapted';
import { withSmallSuspense } from '../../common/suspense';
import { comments as commentsAtom, conversation as conversationAtom } from '../../recoil/conversations';
import useUserAndTaskMention from '../../common/hooks/useUserAndTaskMention';
import { FormattedMessage } from 'react-intl';

export interface ConversationProps {
  refType: ConversationType;
  refId: number;
  bounds?: string | HTMLElement;
}

export default function (props: ConversationProps) {
  return (
    <>
      <Divider className="conversation-header-divider" orientation="left" dashed={true}>
        <FormattedMessage id={'generic.comments'} />
      </Divider>
      <Comments {...props} />
    </>
  );
}

const Comments = withSmallSuspense(function ({ refType, refId, bounds }: ConversationProps) {
  const user = useRecoilValue(currentUser);
  const conversation = useRecoilValue(conversationAtom({ refType, refId }));
  const [comments, setComments] = useRecoilState(commentsAtom({ refType, refId }));
  const commentsEndRef = useRef<HTMLDivElement>(null);
  const mentionModule = useUserAndTaskMention(useRecoilValue(companyUsers));
  const quillModules = useQuillModules(quillModulesFull, mentionModule);
  const [needScroll, setNeedScroll] = useState(false);

  const onDelete = useCallback(
    async (comment: CommentEntity) => {
      await deleteComment(conversation.id!!, comment.id!!);
      setComments(comments.filter(cmt => cmt.id !== comment.id));
    },
    [conversation, comments, setComments]
  );

  const onCreate = useCallback(async () => {
    setComments([...comments, new CommentEntity('', user)]);
    setNeedScroll(true);
  }, [user, comments, setComments, setNeedScroll]);

  useEffect(() => {
    if (needScroll) {
      setNeedScroll(false);
      commentsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [comments, needScroll]);

  return (
    <>
      {comments.map(comment =>
        user.id === comment.user.id ? (
          <EditableComment
            key={String(comment.createdDate.getTime())}
            bounds={bounds}
            conversationId={conversation.id!}
            comment={comment}
            modules={quillModules}
            onDelete={onDelete}
            onCreate={onCreate}
          />
        ) : (
          <ReadonlyComment key={String(comment.createdDate.getTime())} comment={comment} />
        )
      )}
      <div ref={commentsEndRef} />
    </>
  );
});
