import React, { useCallback, useMemo, useState } from 'react';
import { Filter, FilterRow } from '@/modules/ERP/Common/FilterRow';
import { CalendarOutlined } from '@ant-design/icons';
import { Button, Empty, Row } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { periodFilters } from '@/modules/ERP/Contacts/ContactDetails/Tabs/TabsFilters';
import { CreateTask } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/CreateTask';
import { Text } from '@/common/components/Typography/Text';
import { useRecoilValue } from 'recoil';
import { contactsTasksSelector } from '@/modules/ERP/Contacts/Recoil/contacts.selectors';
import { withSmallSuspense } from '@/common/suspense';
import { Task } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/Task';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { EntityLinkType } from '@/common/types/entity/Link';
import { EmptyTasks } from './EmptyTasks';
import { usePermission } from '@/common/hooks/usePermission';
import { Permissions } from '@/utils/security';

import '../../../../Contacts/style.css';
import { SearchInput } from 'ui-sesame-components';

interface TasksProps {
  entityId: Primitive;
  entityType: EntityLinkType;
}

export const taskDateFilterNodeId = 'taskDateFilter';
const Tasks: React.FunctionComponent<TasksProps> = withSmallSuspense(
  React.memo(({ entityId, entityType }) => {
    const translator = useIntl();
    const [visibleModal, setVisibleModal] = useState(false);
    const [searchThroughTasks, setSearchThroughTasks] = useState('');
    const handleSearchThroughTasks = useCallback((event: any) => {
      setSearchThroughTasks(event.target.value);
    }, []);

    const filters = useMemo(() => {
      const activityDateOptions = periodFilters(translator);

      const dateKey = 'generic.date';

      return [
        {
          key: dateKey,
          filter: <Filter recoilNodeId={taskDateFilterNodeId} title={translator.formatMessage({ id: dateKey })} icon={<CalendarOutlined />} options={activityDateOptions} />
        }
      ];
    }, [translator]);

    const showCreateTaskButton = usePermission([Permissions.TASK_CONTRIBUTOR]);

    const allTasksForSpace = useRecoilValue(
      contactsTasksSelector([
        {
          search: ''
        },
        entityType,
        entityId as string,
        true
      ])
    );

    if (allTasksForSpace.length <= 0) {
      return (
        <EmptyTasks entityType={entityType}>
          {showCreateTaskButton && (
            <>
              <Button type="primary" onClick={() => setVisibleModal(true)} className="empty-state-modal-button">
                {translator.formatMessage({ id: 'generic.createTask' })}
              </Button>
              <CreateTask search={searchThroughTasks} isOpen={visibleModal} setIsOpen={setVisibleModal} objects={[{ id: entityId as string, type: entityType }]} />
            </>
          )}
        </EmptyTasks>
      );
    }

    return (
      <>
        <Row className="contact-activity-filter-row-button">
          <SearchInput
            placeholder={translator.formatMessage({ id: 'generic.search' })}
            className="contact-activity-search-bar"
            allowClear
            addonAfter={null}
            onChange={handleSearchThroughTasks}
          />
          <FilterRow withPrefix={false} filters={filters} />
          {showCreateTaskButton && (
            <Button type="primary" className={'create-button-documents-tabs'} onClick={() => setVisibleModal(true)}>
              {translator.formatMessage({ id: 'generic.createTask' })}
            </Button>
          )}
        </Row>
        <CreateTask search={searchThroughTasks} isOpen={visibleModal} setIsOpen={setVisibleModal} objects={[{ id: entityId as string, type: entityType }]} />
        <br />
        <TasksList search={searchThroughTasks} entityId={entityId as string} entityType={entityType} />
      </>
    );
  })
);

interface TaskListProps {
  search: string;
  entityId: string;
  entityType: EntityLinkType;
}

const TasksList: React.FunctionComponent<TaskListProps> = withSmallSuspense(
  React.memo(({ search, entityId, entityType }) => {
    const tasks = useRecoilValue(
      contactsTasksSelector([
        {
          search: search
        },
        entityType,
        entityId,
        true
      ])
    );

    return (
      <>
        {tasks.map(task => {
          return <Task search={search} entityType={entityType} entityId={entityId} task={task} key={task.id} id={task.id} />;
        })}
        {tasks.length <= 0 && (
          <Empty
            description={
              <Text type="secondary">
                <FormattedMessage id="generic.noTasksAvailable" />
              </Text>
            }
          />
        )}
      </>
    );
  })
);

export { Tasks };
