import { selector } from 'recoil';
import { hasPermission } from '@/utils/hasPermission';
import { Permissions } from '@/utils/security';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import { InternalRoleClasses, internalSecuritySelector } from '@/modules/User/recoil/security.selectors';

export const isLandytechLogin = (login: string) => login.endsWith('@landytech.com');

const userIsReportCentreManager = selector<boolean>({
  key: 'report-scheduler-user-is-report-centre-manager',
  get: ({ get }) => {
    return hasPermission(get(currentUserSelector), [Permissions.REPORT_CENTRE_MANAGEMENT]);
  }
});

const userIsSystemReportCentreManager = selector<boolean>({
  key: 'report-scheduler-user-is-sys-admin',
  get: ({ get }) => {
    const contentManager = get(userIsReportCentreManager);

    return hasPermission(get(currentUserSelector), [Permissions.DATA_MANAGEMENT]) && contentManager;
  }
});

const userIsReportSchedulerManager = selector<boolean>({
  key: 'report-scheduler-user-is-scheduler-manager',
  get: ({ get }) => {
    return hasPermission(get(currentUserSelector), [Permissions.SCHEDULE_REPORT_READER]);
  }
});

const userIsInternal = selector<boolean>({
  key: 'user-is-internal',
  get: ({ get }) => {
    const internal = get(internalSecuritySelector);
    if (!internal) {
      return false;
    }

    return internal[InternalRoleClasses.InternalProductOperator];
  }
});

const userIsQuantLibraryManager = selector<boolean>({
  key: 'user-is-quant-library-manager',
  get: ({ get }) => {
    return get(userIsInternal);
  }
});

const userIsContentTagsLibraryManager = selector<boolean>({
  key: 'user-is-content-tags-library-manager',
  get: ({ get }) => {
    return hasPermission(get(currentUserSelector), [Permissions.REPORT_BUILDER_PUBLISHER_CONTRIBUTOR]);
  }
});

const userIsReportCentreTemplateManager = selector<boolean>({
  key: 'report-centre-user-is-template-manager',
  get: ({ get }) => {
    return hasPermission(get(currentUserSelector), [Permissions.REPORT_CENTRE_TEMPLATE_MANAGEMENT]);
  }
});

const userIsComponentLibraryManager = selector<boolean>({
  key: 'user-is-component-library-manager',
  get: ({ get }) => {
    return get(userIsInternal) || hasPermission(get(currentUserSelector), [Permissions.CUSTOM_COMPONENT_CONTRIBUTOR]);
  }
});

const userIsCustomComponentLibraryReader = selector<boolean>({
  key: 'user-is-custom-component-library-reader',
  get: ({ get }) => {
    return hasPermission(get(currentUserSelector), [Permissions.CUSTOM_COMPONENT_READER]);
  }
});

const userHasReportBuilderAccess = selector<boolean>({
  key: 'user-has-report-builder-access',
  get: ({ get }) => {
    return hasPermission(get(currentUserSelector), [Permissions.REPORT_BUILDER_GRID_CONTRIBUTOR]);
  }
});

const isLandytechUser = selector<boolean>({
  key: 'user-is-landytech-limited',
  get: ({ get }) => {
    return isLandytechLogin(get(currentUserSelector).login);
  }
});

const userIsSpaceReader = selector<boolean>({
  key: 'user-is-space-reader',
  get: ({ get }) => {
    return hasPermission(get(currentUserSelector), [Permissions.SPACE_READER]);
  }
});

export {
  userIsInternal,
  userIsReportCentreManager,
  userIsSystemReportCentreManager,
  userIsReportSchedulerManager,
  userIsReportCentreTemplateManager,
  userIsQuantLibraryManager,
  userIsContentTagsLibraryManager,
  userIsComponentLibraryManager,
  userIsCustomComponentLibraryReader,
  userHasReportBuilderAccess,
  isLandytechUser,
  userIsSpaceReader
};
