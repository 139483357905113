export default [
  {
    enabled: 'assetEnabled',
    field: 'assetId',
    param: 'assetIds'
  },
  {
    enabled: 'riskMeasureEnabled',
    field: 'riskMeasureId',
    param: 'riskMeasureIds'
  }
];
