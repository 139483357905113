import { useMemo } from 'react';
import { Select } from 'antd';
import { HoldingSetLookup } from '@/common/types/entity/HoldingSetLookup';
import HoldingSetLabel from '@/common/entity/HoldingSetLabel';

const { Option } = Select;

export default function useUserHoldingSetsOptions(holdingSets?: Map<number, HoldingSetLookup>, emptyOption?: string, keysAsText: boolean = false) {
  const emptyKey = keysAsText ? 'holding_set_empty' : -1;
  return useMemo(
    () =>
      holdingSets
        ? [
            <Option key={emptyKey} value={emptyKey} title={emptyOption}>
              <HoldingSetLabel />
            </Option>,
            ...Array.from(holdingSets.values()).map(holdingSet => {
              const key = keysAsText ? `holding_set_${holdingSet.id}` : holdingSet.id;
              return (
                <Option key={key} value={key} title={holdingSet.name}>
                  <HoldingSetLabel holdingSet={holdingSet} />
                </Option>
              );
            })
          ]
        : [],
    [emptyKey, emptyOption, keysAsText, holdingSets]
  );
}
