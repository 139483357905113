import EntityRef from './EntityRef';

export enum ContentTagCategory {
  GENERIC = 'GENERIC',
  QUANT_CATALOGUE_CATEGORY = 'QUANT_CATALOGUE_CATEGORY',
  QUANT_CATALOGUE_SUB_CATEGORY = 'QUANT_CATALOGUE_SUB_CATEGORY'
}

export interface ContentTag extends ContentTagRef {
  name: string;
  category: ContentTagCategory;
  description?: string;
  icon?: string;
}

export interface ContentTagRef extends EntityRef {}

export interface ContentTagLookup extends ContentTagRef {
  name: string;
  category: ContentTagCategory;
  icon?: string;
}

export class TransientContentTag {
  constructor(
    public name: string,
    public category: ContentTagCategory,
    public description?: string,
    public icon?: string
  ) {}
}

export class PersistentContentTag implements ContentTag, ContentTagRef {
  constructor(
    public id: number,
    public name: string,
    public category: ContentTagCategory,
    public description?: string,
    public icon?: string
  ) {}

  public static fromContentTag(template: ContentTag & ContentTagRef) {
    return new PersistentContentTag(template.id, template.name, template.category, template.description, template.icon);
  }
}
