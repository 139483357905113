import React from 'react';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import HighCharts from '@/modules/reporting-v2/core/components/Highcharts';
import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import { config } from './config';
import schema from './schema.json';
import RawTreeMapConfig from './types';
import TreeMapConfig from './TreeMapConfig';

class TreeMap extends Visual {
  Loader = Loaders.Chart;
  renderUntilReady = true;

  static configMapper(visualConfig: RawTreeMapConfig) {
    const { breakdown, field, ...rest } = visualConfig;

    return {
      ...rest,
      columns: mapVisualConfigColumns(breakdown).concat(mapVisualConfigColumns(field)),
      group: mapVisualConfigColumns(breakdown)
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as TreeMapConfig;
  }

  getSchema() {
    return schema;
  }

  renderChartTable(visual: VisualEngine) {
    return (
      <DataTable
        visual={
          {
            ...visual,
            collapsible: true,
            subTotal: true
          } as IDataTableProps['visual']
        }
      />
    );
  }

  renderBody() {
    return <HighCharts fullSize={this.state.zoomIn} visual={this.props.visual} />;
  }
}

export default TreeMap;
