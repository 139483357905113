import React, { ReactNode } from 'react';
import { CheckSquareOutlined } from '@ant-design/icons';
import { EmptyResult } from '@/modules/ERP/Common/Result/EmptyResult';
import { EntityLinkType } from '@/common/types/entity/Link';
import { resolveEntityType } from '@/modules/ERP/Common/Result/resolveEntityType';

export const EmptyNotes: React.FC<{
  entityType: EntityLinkType;
  children: ReactNode;
}> = ({ children, entityType }) => {
  return (
    <EmptyResult
      icon={<CheckSquareOutlined style={{ fontSize: '45px', color: '#bfbfbf' }} />}
      titleId="erp.note.emptyNoteTitle"
      titleSubTitleId="erp.note.emptyNoteSubTitle"
      titleSubTitleValues={{ entityType: resolveEntityType(entityType) }}
    >
      {children}
    </EmptyResult>
  );
};
