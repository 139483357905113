import { Column } from '@/modules/reporting-v2/core/Column';
import { FilterOperator } from '@/types/Filters';

/**
 * When conditional aggregation is used with currency.
 * The symbol should be the one of the selected value in the conditional aggregation.
 * This is a very specific rule for conditional aggregation and currency.
 * @param column
 */
export const forceCurrency = (column: Column): false | string => {
  if (!column?.conditionalValueDisplay?.length) {
    return false;
  }

  if (
    column.conditionalValueDisplay[0].field.name === 'allocation.allocations.localCurrency' &&
    column.conditionalValueDisplay[0].condition.operator === FilterOperator.EQUALS &&
    column.conditionalValueDisplay[0].condition.values.length
  ) {
    return column.conditionalValueDisplay[0].condition.values[0]?.toString() ? column.conditionalValueDisplay[0].condition.values[0].toString() : false;
  }

  return false;
};
