import { useEffect } from 'react';

export default function (type: string, listener: EventListenerOrEventListenerObject, options: AddEventListenerOptions | boolean = true) {
  useEffect(() => {
    window.addEventListener(type, listener, options);
    return () => {
      window.removeEventListener(type, listener, options);
    };
  }, [type, listener, options]);
}
