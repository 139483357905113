import React, { memo } from 'react';
import { FormOutlined } from '@ant-design/icons';
import { QuantCatalogueItemLookup } from '@/common/types/entity/QuantCatalogueItem';
import { useRecoilCallback } from 'recoil';
import { QuantManagementEditMode, selectedMetricState } from '../quant-management.atoms';

interface QuantMetricEditionButtonProps {
  record: QuantCatalogueItemLookup;
}

export const QuantMetricEditionButton: React.FC<QuantMetricEditionButtonProps> = memo(({ record }) => {
  const onEdit = useRecoilCallback(
    ({ set }) =>
      () => {
        set(selectedMetricState, [record, QuantManagementEditMode.QUANT_EDITION]);
      },
    [record]
  );

  return <FormOutlined className="quant-management-lib-edit-btn" onClick={onEdit} />;
});
