import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedLimitsTableConfig } from '..';
import { LimitsTableConfigMapped } from './types';
import { Category, DecimalFormattingForMonetary, DecimalFormattingForNumber, DecimalFormattingForPercentage, LimitsGroupBy, Status } from './VisualSpecificProps';

export default class LimitsTableConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as LimitsTableConfigMapped));
  }

  getVisualConfigProps(config: LimitsTableConfigMapped): ExtendedLimitsTableConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      category: config.category,
      status: config.status,
      groupBy: config.groupBy,
      decimalFormattingForNumber: config.decimalFormattingForNumber,
      decimalFormattingForMonetary: config.decimalFormattingForMonetary,
      decimalFormattingForPercentage: config.decimalFormattingForPercentage,
      collapsible: config.collapsible,
      sortable: config.sortable,
      subTotal: config.subTotal,
      hideLimitRule: config.hideLimitRule,
      hideCategoryInLimitRule: config.hideCategoryInLimitRule
    });
  }

  setVisualConfig(config: ExtendedLimitsTableConfig) {
    super.setVisualConfig(config);
    this.category = config.category;
    this.status = config.status;
    this.groupBy = config.groupBy;
    this.decimalFormattingForNumber = config.decimalFormattingForNumber;
    this.decimalFormattingForMonetary = config.decimalFormattingForMonetary;
    this.decimalFormattingForPercentage = config.decimalFormattingForPercentage;
    this.collapsible = config.collapsible;
    this.sortable = config.sortable;
    this.subTotal = config.subTotal;
    this.hideLimitRule = config.hideLimitRule;
    this.hideCategoryInLimitRule = config.hideCategoryInLimitRule;
  }

  category: Category[];
  status: Status[];
  groupBy: LimitsGroupBy[];
  decimalFormattingForNumber: DecimalFormattingForNumber | undefined;
  decimalFormattingForMonetary: DecimalFormattingForMonetary | undefined;
  decimalFormattingForPercentage: DecimalFormattingForPercentage | undefined;
  collapsible: boolean;
  sortable: boolean;
  subTotal: boolean;
  hideLimitRule: boolean | undefined;
  hideCategoryInLimitRule: boolean | undefined;
}
