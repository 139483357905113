import { requestApi } from '@/core';
import { ApiService } from 'ui-sesame-components';
import { AssetCompositionDto } from '../types';
import { AssetDtoV2 } from '../../PortfolioV2/types';

export const getLatestAssetCompositionsByCategory = async ({
  id: assetId,
  params
}: {
  id: NonNullable<AssetDtoV2['id']>;
  params?: {
    toDate?: string;
  };
}): Promise<AssetCompositionDto['assetCompositionsByCategory']> => {
  return requestApi({
    url: `/api/v2/assets/${assetId}/compositions/by-category/latest`,
    service: ApiService.ASSET,
    method: 'GET',
    params,
    errors: false
  }).then(({ data }) => data);
};
