import { atomFamily } from 'recoil';
import { AssetFlow, AssetStep, FormState } from '@/modules/Onboarding/Shared/types';

const stepState = atomFamily<AssetStep, AssetFlow>({
  key: 'onboarding-flow-step-state',
  default: flow => {
    switch (flow) {
      case AssetFlow.CASH:
        return AssetStep.CASH_ACCOUNT_INFORMATION;
      case AssetFlow.REAL_ESTATE:
        return AssetStep.PROPERTY_INFORMATION;
      case AssetFlow.COLLECTIBLES:
        return AssetStep.COLLECTIBLE_INFORMATION;
      case AssetFlow.FINANCIAL_ASSET:
        return AssetStep.FINANCIAL_INFORMATION;
      case AssetFlow.LEGAL_ENTITY:
        return AssetStep.LEGAL_ENTITY_INFORMATION;

      default:
        throw new Error();
    }
  }
});

const formsState = atomFamily<FormState, AssetFlow>({
  key: 'onboarding-flow-form-values-state',
  default: {}
});

export { stepState, formsState };
