import React from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { RawColumn } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import { Column, ColumnFormatting } from '@/modules/reporting-v2/core/Column';
import { Field } from '@/modules/reporting-v2/core/Field';
import { Row, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import { FormatType } from '@/common/types/elastic/FormatType';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import { MemoizedTable } from '@/modules/reporting-v2/core/components/MemoizedTable';
import { HistoricalChartDateRangePeriod } from '@/modules/reporting-v2/types/HistoricalConfig';
import { config } from './config';
import PerformanceTableConfig from './PerformanceTableConfig';
import schema from './schema.json';
import { DataFrequency, DateRange } from './VisualSpecificProps';
import RawPerformanceTableConfig from './types';
import { nanoid } from 'nanoid';
import NoData from '../../components/NoData';
import { DATE_FORMAT } from 'ui-sesame-components';

import { MemoizedDataTablev2 } from '@/modules/reporting-v2/core/components/DataTablev2/DataTable';

dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);

const dateField = 'performance.date'; // OT088

const frequencyToField = {
  [DataFrequency.Quarterly]: {
    entity: 'PP23',
    benchmark: 'PP370'
  },
  [DataFrequency.Yearly]: {
    entity: 'PP24',
    benchmark: 'PP371'
  },
  [DataFrequency.QTD]: {
    entity: 'PP23',
    benchmark: 'PP370'
  },
  [DataFrequency.YTD]: {
    entity: 'PP24',
    benchmark: 'PP371'
  },
  [DataFrequency.ITD]: {
    entity: 'PP25',
    benchmark: 'PP373'
  }
};

class PerformanceTable extends Visual {
  Loader = Loaders.Table;

  static configMapper(visualConfig: RawPerformanceTableConfig) {
    let dateRange: HistoricalChartDateRangePeriod = HistoricalChartDateRangePeriod.LAST_YEAR;

    const selectedRanges = visualConfig.frequencies.map(freq => freq.dateRange);
    if (selectedRanges.includes(DateRange.ITD)) {
      dateRange = HistoricalChartDateRangePeriod.FROM_INCEPTION;
    } else if (selectedRanges.includes(DateRange.LastFullYear)) {
      dateRange = HistoricalChartDateRangePeriod.LAST_3_YEARS;
    }

    const columns = {
      columns: ['OT088', 'PP16', 'PP23', 'PP24', 'PP25', 'PP363', 'PP370', 'PP371', 'PP373'],
      defaultColumns: ['OT088', 'PP16', 'PP23', 'PP24', 'PP25', 'PP363', 'PP370', 'PP371', 'PP373'],
      filters: {},
      options: {}
    } as RawColumn;

    return {
      ...visualConfig,
      columns: mapVisualConfigColumns(columns),
      dateRange
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as PerformanceTableConfig;
  }

  getSchema() {
    return schema;
  }

  getPerformanceFormatting() {
    return this.props.visual.columns.find(col => !col.fieldDataPath.includes(dateField))?.formatting as ColumnFormatting;
  }

  getFieldDataPathFromCode(code: string) {
    return this.props.visual.columns.find(col => col.code === code)?.fieldDataPath as string;
  }

  getFirstLastAvailableDates(rows: Row[]) {
    return {
      first: dayjs(rows[rows.length - 1].data[dateField] as string),
      last: dayjs(rows[0].data[dateField] as string)
    };
  }

  getRowsInRange(rows: Row[], dateRange: DateRange) {
    let { last: dateEnd, first: dateStart } = this.getFirstLastAvailableDates(rows);

    switch (dateRange) {
      case DateRange.Current:
      case DateRange.ITD:
        break;
      case DateRange.OneYear:
        dateStart = dateEnd.clone().subtract(1, 'year');
        break;
      case DateRange.LastFullYear:
        dateStart = dateEnd.clone().subtract(1, 'year').startOf('year');
        dateEnd = dateStart.clone().endOf('year').add(1, 'day');
        break;
      case DateRange.YTD:
      default:
        dateStart = dateEnd.clone().startOf('year');
        break;
    }

    const rowsInRange = rows.filter(row => {
      const rowDate = row.data[dateField] as string | undefined;
      if (!rowDate) {
        return false;
      }

      return dayjs(rowDate).isBetween(dateStart, dateEnd);
    });

    return {
      rows: rowsInRange,
      dateStart,
      dateEnd
    };
  }

  getLastRowNearDate(rows: Row[], date: dayjs.Dayjs) {
    let row: Row | undefined = undefined;
    let maxTry = 10;
    while (!row && maxTry > 0) {
      const formattedDate = date.format(DATE_FORMAT.DATE);
      row = rows.find(r => r.data[dateField] === formattedDate);
      date.subtract(1, 'day');
      maxTry--;
    }

    return row;
  }

  buildQTDData(ogRows: Row[], newRows: Row[], columns: Column[], _: DateRange) {
    const lastData = ogRows[0].data;
    const { entity, benchmark } = frequencyToField[DataFrequency.QTD];

    columns.push(
      new Column({
        field: new Field(''),
        isDefault: true,
        headerConfig: { displayName: 'QTD' },
        formatting: this.getPerformanceFormatting()
      })
    );

    newRows[0].cells.push(lastData[this.getFieldDataPathFromCode(entity)]);
    newRows[1].cells.push(lastData[this.getFieldDataPathFromCode(benchmark)]);
  }

  buildQuarterlyData(ogRows: Row[], newRows: Row[], columns: Column[], dateRange: DateRange) {
    const { rows: ogRowsInRange, dateStart, dateEnd } = this.getRowsInRange(ogRows, dateRange);

    const quartersEndDates = [];
    let cursorDate = dayjs(dateStart).endOf('quarter');
    while (cursorDate.isBefore(dateEnd)) {
      quartersEndDates.push(dayjs(cursorDate));
      cursorDate = cursorDate.add(1, 'day').endOf('quarter');
    }

    const quarters: { row: Row; label: string }[] = [];
    quartersEndDates.forEach(qtDate => {
      const row = this.getLastRowNearDate(ogRowsInRange, qtDate);
      if (row) {
        quarters.push({ row, label: `Q${qtDate.format('Q YYYY')}` });
      }
    });

    quarters.forEach(quarter => {
      columns.push(
        new Column({
          field: new Field(''),
          headerConfig: { displayName: quarter.label },
          isDefault: true,
          formatting: this.getPerformanceFormatting()
        })
      );
      newRows[0].data = quarter.row.data;
      newRows[1].data = quarter.row.data;
      const { entity, benchmark } = frequencyToField[DataFrequency.Quarterly];
      newRows[0].cells.push(quarter.row.data[this.getFieldDataPathFromCode(entity)!]);
      newRows[1].cells.push(quarter.row.data[this.getFieldDataPathFromCode(benchmark)!]);
    });
  }

  isFullYear(ogRowsInRange: Row[], yearDate: dayjs.Dayjs): boolean {
    const startOfYear = yearDate.clone().startOf('year');

    return ogRowsInRange.some(row => {
      const rowDate = dayjs(row.data[dateField] as string);
      return rowDate.isSame(startOfYear, 'month');
    });
  }

  buildYearlyData(ogRows: Row[], newRows: Row[], columns: Column[], dateRange: DateRange) {
    const { rows: ogRowsInRange, dateStart, dateEnd } = this.getRowsInRange(ogRows, dateRange);

    const yearsEndDate = [];
    let cursorDate = dayjs(dateStart).endOf('year');
    while (cursorDate.isBefore(dateEnd)) {
      yearsEndDate.push(dayjs(cursorDate));
      cursorDate = cursorDate.add(1, 'day').endOf('year');
    }

    const years: { row: Row; label: string; isFull: boolean }[] = [];
    yearsEndDate.forEach(yDate => {
      const isFullYear = this.isFullYear(ogRowsInRange, yDate);
      const row = this.getLastRowNearDate(ogRowsInRange, yDate);
      if (row) {
        years.push({ row, label: yDate.format('YYYY'), isFull: isFullYear });
      }
    });

    years.forEach(year => {
      columns.push(
        new Column({
          field: new Field(''),
          headerConfig: {
            displayName: `${year.isFull ? '' : '*'}${year.label}`
          },
          isDefault: true,
          formatting: this.getPerformanceFormatting()
        })
      );
      newRows[0].data = year.row.data;
      newRows[1].data = year.row.data;
      const { entity, benchmark } = frequencyToField[DataFrequency.Yearly];
      newRows[0].cells.push(year.row.data[this.getFieldDataPathFromCode(entity)!]);
      newRows[1].cells.push(year.row.data[this.getFieldDataPathFromCode(benchmark)!]);
    });
  }

  buildYTDData(ogRows: Row[], newRows: Row[], columns: Column[], _: DateRange) {
    const lastData = ogRows[0].data;
    const { entity, benchmark } = frequencyToField[DataFrequency.YTD];

    columns.push(
      new Column({
        field: new Field(''),
        isDefault: true,
        headerConfig: { displayName: 'YTD' },
        formatting: this.getPerformanceFormatting()
      })
    );

    newRows[0].cells.push(lastData[this.getFieldDataPathFromCode(entity)]);
    newRows[1].cells.push(lastData[this.getFieldDataPathFromCode(benchmark)]);
  }

  buildITDData(ogRows: Row[], newRows: Row[], columns: Column[], _: DateRange) {
    const lastData = ogRows[0].data;
    const { entity, benchmark } = frequencyToField[DataFrequency.ITD];

    columns.push(
      new Column({
        field: new Field(''),
        isDefault: true,
        headerConfig: { displayName: 'ITD' },
        formatting: this.getPerformanceFormatting()
      })
    );

    newRows[0].cells.push(lastData[this.getFieldDataPathFromCode(entity)]);
    newRows[1].cells.push(lastData[this.getFieldDataPathFromCode(benchmark)]);
  }

  getDataBuildingFunction(frequency: DataFrequency) {
    switch (frequency) {
      case DataFrequency.Quarterly:
        return this.buildQuarterlyData.bind(this);
      case DataFrequency.QTD:
        return this.buildQTDData.bind(this);
      case DataFrequency.Yearly:
        return this.buildYearlyData.bind(this);
      case DataFrequency.YTD:
        return this.buildYTDData.bind(this);
      case DataFrequency.ITD:
        return this.buildITDData.bind(this);
    }
  }

  buildData(visual: PerformanceTableConfig) {
    const frequencies = visual.frequencies;

    const ogRows = visual.data.rows;
    ogRows.sort((a, b) => {
      return (b.data[dateField] as string).localeCompare(a.data[dateField] as string);
    });

    const columns: Column[] = [
      new Column({
        field: new Field(''),
        isDefault: true,
        formatting: new ColumnFormatting(undefined, FormatType.string)
      })
    ];
    const rows: Row[] = [
      { __id__: 'portfolio', cells: ['Portfolio'], data: {} },
      { __id__: 'benchmark', cells: ['Benchmark'], data: {} }
    ];

    frequencies.forEach(frequency => {
      const dataBuildingFunction = this.getDataBuildingFunction(frequency.frequency);
      if (dataBuildingFunction) {
        dataBuildingFunction(ogRows, rows, columns, frequency.dateRange);
      }
    });

    return { columns, rows };
  }

  renderBody() {
    const visualClone = Object.assign({}, this.props.visual);
    Object.setPrototypeOf(visualClone, VisualEngine.prototype);

    const { columns, rows } = this.buildData(visualClone as any);

    visualClone.htmlEditableOnly = true;
    visualClone.columns = columns;
    visualClone.data = { ...visualClone.data, rows: rows };

    const hasNoData = visualClone.data.rows.every(row => row.cells.length <= 1);
    if (hasNoData) {
      return <NoData />;
    }

    if ((visualClone as PerformanceTableConfig).transpose) {
      return <TransposedPerformanceTable visual={visualClone as IDataTableProps['visual']} />;
    }

    if (visualClone.tableVersion === 'v2') {
      return <MemoizedDataTablev2 visual={visualClone} />;
    }

    return <MemoizedTable visual={visualClone as IDataTableProps['visual']} />;
  }
}

const TransposedPerformanceTable: React.FC<{
  visual: IDataTableProps['visual'];
}> = ({ visual }) => {
  const getNewRows = (oldRows: (Row | RowGroup)[], oldColumns: Column[]) => {
    const newRows: Row[] = [];
    const oldColumnsWithoutFirst = oldColumns.slice(1);

    for (let i = 0; i < oldColumnsWithoutFirst.length; i++) {
      const oldColumn = oldColumnsWithoutFirst[i];
      const newCells: Row['cells'] = [oldColumn.headerConfig.displayName as string];

      let rowIndex = 1;
      for (const oldRow of oldRows) {
        newCells[rowIndex] = oldRow.cells[i + 1];
        rowIndex++;
      }

      newRows.push({
        __id__: oldColumn.id,
        cells: newCells,
        data: {}
      });
    }

    return newRows;
  };

  const getNewColumns = (oldRows: (Row | RowGroup)[], defaultColumn: Column) => {
    const newColumns: Column[] = [
      new Column({
        isDefault: true,
        formatting: new ColumnFormatting(undefined, FormatType.string),
        field: new Field(''),
        headerConfig: {
          displayName: ''
        }
      })
    ];

    for (const oldRow of oldRows) {
      newColumns.push(
        new Column({
          ...defaultColumn,
          id: nanoid(),
          headerConfig: {
            displayName: oldRow.cells[0] as string
          }
        })
      );
    }

    return newColumns;
  };

  const oldRows = visual.data.rows;
  const oldColumns = visual.columns;

  visual.data.rows = getNewRows(oldRows, oldColumns);
  visual.columns = getNewColumns(oldRows, oldColumns[1]);
  visual.transpose = false;

  if (visual.tableVersion === 'v1') {
    return <MemoizedTable visual={visual} />;
  }

  return <MemoizedDataTablev2 visual={visual} />;
};

export default PerformanceTable;
