import { atom, selector, selectorFamily } from 'recoil';
import { Entity, EntityListFilter } from '../types';
import { getLegalEntities } from '../api/getLegalEntities';
import { getLegalEntityTypes } from '../api/getLegalEntityTypes';
import { getAllocatedCustodians } from '../api/getAllocatedCustodians';
import { getLegalEntityByHoldingSetId } from '../api/getLegalEntityByHoldingSetId';
import { HoldingSetType } from '@/common/types/entity/HoldingSetType';

export const legalEntitiesLastUpdated = atom({
  key: 'legal-entities-last-updated',
  default: new Date()
});

export const hasLegalEntities = selector({
  key: 'has-legal-entities-validation',
  get: async () => {
    return (await getLegalEntities({ size: 1, holdingSetType: HoldingSetType.VEHICLE })).totalElements >= 1;
  }
});

export const legalEntities = selectorFamily({
  key: 'legal-entities-list',
  get:
    (params: Readonly<EntityListFilter>) =>
    ({ get }) => {
      get(legalEntitiesLastUpdated);
      return getLegalEntities(params);
    }
});

export const legalEntityByHoldingSetId = selectorFamily({
  key: 'legal-entity-by-holding-set-id',
  get:
    (params: Parameters<typeof getLegalEntityByHoldingSetId>[0]) =>
    async ({ get }) => {
      get(legalEntitiesLastUpdated);
      return await getLegalEntityByHoldingSetId(params);
    }
});

export const legalEntityLabelSelector = selectorFamily({
  key: 'legal-entity-label-selector',
  get:
    (holdingSetId: Entity['holdingSetId']) =>
    ({ get }) => {
      const entity = get(legalEntityByHoldingSetId({ holdingSetId }));
      return {
        id: String(entity?.holdingSetId),
        label: entity?.name
      };
    }
});

export const legalEntitiesAsLabelSelector = selectorFamily({
  key: 'holding-sets-as-label-selector',
  get:
    (filters: Readonly<EntityListFilter>) =>
    ({ get }) => {
      const source = get(legalEntities(filters));
      return Array.from(source.content).map(it => ({
        id: String(it.holdingSetId),
        label: it.name
      }));
    }
});

export const legalEntityTypes = selector({
  key: 'legal-entities-types-selector',
  get: getLegalEntityTypes
});

export const legalEntitiesWithCustodian = selectorFamily({
  key: 'legal-entities-with-custodians',
  get:
    (params: Readonly<EntityListFilter>) =>
    async ({ get }) => {
      const legalEntitiesResponse = get(legalEntities(params));

      const custodians = await getAllocatedCustodians();

      const legalEntitiesList = legalEntitiesResponse.content.map(entity => {
        const custodian = custodians.filter(custodian => entity.entitySummary?.relatedCustodianIds?.includes(custodian.id)) || [];
        return {
          ...entity,
          custodian: custodian
        };
      });

      return {
        ...legalEntitiesResponse,
        content: legalEntitiesList
      };
    }
});

export const conditionalLegalEntityById = selectorFamily({
  key: 'optional-legal-entity-by-id',
  get:
    (id?: Entity['holdingSetId']) =>
    ({ get }) => {
      if (!id) {
        return undefined;
      }
      return get(legalEntityByHoldingSetId({ holdingSetId: id }));
    }
});

export const allocatedCustodians = selector({
  key: 'allocated-custodians',
  get: getAllocatedCustodians
});

export const legalEntityRelatedCustodians = selectorFamily({
  key: 'legal-entity-related-custodians',
  get:
    (params: Parameters<typeof legalEntityByHoldingSetId>[0]) =>
    ({ get }) => {
      const { entitySummary: { relatedCustodianIds = [] } = {} } = get(legalEntityByHoldingSetId(params));
      return get(allocatedCustodians).filter(({ id }) => relatedCustodianIds.includes(id));
    }
});
