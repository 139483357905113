import { useMemo } from 'react';
import type { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';

const useAlignmentClass = (visual: VisualEngine) => {
  const alignmentClasses = useMemo(() => {
    const classList = [];

    if (visual.styles?.table?.textAlign) {
      classList.push(`text-align-${visual.styles?.table?.textAlign}`);
    }
    if (visual.styles?.table?.firstColumnTextAlign) {
      classList.push(`first-column-align-${visual.styles?.table?.firstColumnTextAlign}`);
    }
    if (visual.styles?.table?.lastColumnTextAlign) {
      classList.push(`last-column-align-${visual.styles?.table?.lastColumnTextAlign}`);
    }

    return classList;
  }, [visual.styles?.table]);

  return alignmentClasses;
};

export { useAlignmentClass };
