import { DaysPeriodFilter } from '@/modules/ERP/Contacts/ContactDetails/Tabs/TabsFilters';
import dayjs from 'dayjs';

export const compareLastModifiedDate = (lastModifiedDate: string, filterValue: DaysPeriodFilter): boolean => {
  switch (filterValue) {
    case DaysPeriodFilter.ALL_TIME:
      return true;
    case DaysPeriodFilter.LAST_365:
      return dayjs(lastModifiedDate).isAfter(dayjs().subtract(1, 'year'));
    case DaysPeriodFilter.LAST_185:
      return dayjs(lastModifiedDate).isAfter(dayjs().subtract(185, 'days'));
    case DaysPeriodFilter.LAST_90:
      return dayjs(lastModifiedDate).isAfter(dayjs().subtract(90, 'days'));
    case DaysPeriodFilter.LAST_30:
      return dayjs(lastModifiedDate).isAfter(dayjs().subtract(30, 'days'));
    case DaysPeriodFilter.LAST_15:
      return dayjs(lastModifiedDate).isAfter(dayjs().subtract(15, 'days'));
    case DaysPeriodFilter.LAST_7:
      return dayjs(lastModifiedDate).isAfter(dayjs().subtract(7, 'days'));
    default:
      throw new Error('Unknown date filter value');
  }
};
