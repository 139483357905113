import { useEffect, useState } from 'react';
import { TaskType } from '../../../common/types/entity/Task';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { SearchCriteria } from '../api/searchTasks';
import { Status } from '@/modules/ERP/Contacts/types';
import { TaskListParameters } from '@/modules/TMS/utils';

export default function (): [number | string | undefined, TaskListParameters | undefined, (criteria: TaskListParameters) => void] {
  const location = useLocation();
  const params = queryString.parse(location.search, { parseNumbers: true });

  const taskID = typeof params['id'] == 'number' || typeof params['id'] == 'string' ? params['id'] : undefined;
  const documentGuid = params['documentGuid'];
  const documentName = params['documentName'];
  const limitID = typeof params['limitID'] == 'number' ? params['limitID'] : undefined;
  const limitName = params['limitName'];

  const [initialSearchCriteria, setInitialSearchCriteria] = useState<TaskListParameters | undefined>(
    // if non parameters are provided, use default criteria
    !taskID && !documentGuid && !limitID ? new TaskListParameters() : undefined
  );

  useEffect(() => {
    if (taskID || (documentGuid === undefined && limitID === undefined)) {
      return;
    }
    const filters = new SearchCriteria();
    if (documentGuid !== undefined) {
      filters.refGuid = String(documentGuid);
      filters.types = [TaskType.DOCUMENT];
      filters.refName = documentName ? String(documentName) : undefined;
    } else if (limitID !== undefined) {
      filters.refId = limitID;
      filters.types = [TaskType.LIMIT];
      filters.refName = limitName ? String(limitName) : undefined;
    }
    filters.statuses = [Status.TO_DO];
    setInitialSearchCriteria(new TaskListParameters(filters));
  }, [taskID, documentGuid, documentName, limitID, limitName, setInitialSearchCriteria]);

  return [taskID, initialSearchCriteria, setInitialSearchCriteria];
}
