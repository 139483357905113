import { requestApi } from '@/core';
import { selectorFamily } from 'recoil';
import datasourceConfigs from '@/modules/reporting-v2/config/datasource';
import { ReportingService } from '@/modules/reporting-v2/core/ReportingService';
import { ConsolidationType } from '@/modules/reporting-v2/types/ReportingService';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';

const entity = {
  VEHICLE: 'vehicle',
  ASSET: 'asset',
  GROUPING: 'grouping',
  PORTFOLIO: 'portfolio'
};

const elasticQuerySelector = selectorFamily({
  key: 'ownership-allocations-query-selector',
  get:
    ({ holdingSetId, consolidationType = ConsolidationType.DIRECT }) =>
    async () => {
      await ReportingService.warmUp();

      const allocationConfig = datasourceConfigs.get('allocation');
      const holdingsetConfig = datasourceConfigs.get('holdingset');

      const allocationQueryFields = ['holdingSetMetrics.marketValueRc'];
      const holdingsetQueryFields = ['reportingCurrency', 'verifiedDate'];

      const allocationUrl = allocationConfig.uri + '?v=1.0';
      const holdingSetUrl = holdingsetConfig.uri + '?v=1.0';

      const method = 'post';

      const allocationPayload = {
        holdingSets: [{ holdingSetId, consolidationType }],
        source: {
          includes: allocationQueryFields
        }
      };

      const holdingsetPayload = {
        holdingSets: [{ holdingSetId, consolidationType }],
        source: {
          includes: holdingsetQueryFields
        }
      };

      const requests = [
        requestApi({
          method,
          service: allocationConfig.service,
          url: allocationUrl,
          data: allocationPayload,
          errors: false
        }),
        requestApi({
          method,
          service: holdingsetConfig.service,
          url: holdingSetUrl,
          data: holdingsetPayload,
          errors: false
        })
      ];

      try {
        const responses = await Promise.all(requests);
        const { reportingCurrency, verifiedDate } = responses[1]?.data?.hits?.hits?.[0]?._source || {};
        const marketValueRc = responses[0]?.data?.hits?.hits?.[0]?._source?.holdingSetMetrics?.marketValueRc;

        return { reportingCurrency, verifiedDate, marketValueRc };
      } catch (error) {
        return {};
      }
    }
});

const dataSelector = selectorFamily({
  key: 'ownership-data-selector',
  get:
    ({ holdingSetId, consolidationType }) =>
    ({ get }) => {
      const currentUser = get(currentUserSelector);
      const hasEntity = currentUser.holdingSetIds.find(id => id === holdingSetId);

      const data = { verifiedDate: 'N/A', marketValue: 'N/A' };

      if (!hasEntity) {
        return data;
      }

      const { reportingCurrency, verifiedDate, marketValueRc } = get(
        elasticQuerySelector({
          holdingSetId,
          consolidationType
        })
      );

      if (marketValueRc) {
        const formatter = new Intl.NumberFormat('en-US', {
          currency: reportingCurrency,
          style: 'currency'
        });

        data.marketValue = formatter.format(marketValueRc);
      }

      if (verifiedDate) {
        data.verifiedDate = verifiedDate;
      }

      return data;
    }
});

export { entity, dataSelector };
