import { VisualEngine } from './VisualEngine';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import type { Row, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import type { Column } from './Column';
import type { ChartOptions, ExtendedRowGroup, LegendPosition } from '@/modules/reporting-v2/core/components/Highcharts/types';
import Highcharts, { Series } from 'highcharts';

type GoogleChartArea = {};

type GoogleChartLegend =
  | {
      enabled: boolean;
      alignment: 'start' | 'center' | 'end';
      position: 'bottom' | 'labeled' | 'left' | 'none' | 'right' | 'top';
      maxLines: number;
    }
  | 'none';

type GoogleChartOptions = {
  chartArea?: GoogleChartArea;
  legend?: GoogleChartLegend;
  hAxis?: {
    title?: string;
  };
  vAxis?: {
    title?: string;
  };
};

export class HighChartsDataUtils {
  static getColumns(columns: Column[]): Column[] {
    return columns.filter(v => v.isDefault);
  }

  static concat(...args: Primitive[]) {
    return args.filter(arg => arg).join(' - ');
  }

  static isRow(row: Row | RowGroup) {
    return 'rows' in row && row.rows[0] && !('group' in row.rows[0]);
  }

  static getFlattenRows<T extends {}>(row: RowGroup, parent?: RowGroup, objectFormat?: (row: ExtendedRowGroup) => T): (T | ExtendedRowGroup)[] {
    const exRowGroup = Object.assign(row, {
      parent,
      groups: row.cells.slice(0, row.level)
    });
    const formattedRow: T | ExtendedRowGroup = objectFormat ? objectFormat(exRowGroup) : exRowGroup;

    try {
      if (this.isRow(row)) {
        return [formattedRow];
      }
      const childRows = row.rows.filter(childRow => !this.isRow(childRow)).flatMap(childRow => this.getFlattenRows<T>(childRow as RowGroup, row, objectFormat));
      return [formattedRow].concat(childRows);
    } catch {
      return [formattedRow];
    }
  }

  static getDeepRows(row: RowGroup, parent?: RowGroup, drilldown?: boolean): ExtendedRowGroup[] {
    const rowGroup: ExtendedRowGroup = {
      ...row,
      parent,
      groups: row.cells.slice(0, row.level)
    };
    try {
      if (this.isRow(row) || drilldown) {
        return [rowGroup];
      }
      return row.rows.flatMap(childRow => this.getDeepRows(childRow as RowGroup, row, drilldown));
    } catch {
      return [rowGroup];
    }
  }

  static getRows(rows: Array<RowGroup | Row | ExtendedRowGroup> = [], drilldown?: boolean): ExtendedRowGroup[] {
    return rows.flatMap(row => {
      const parent = 'parent' in row ? row.parent : undefined;
      return this.getDeepRows(row as RowGroup, parent, drilldown);
    });
  }

  static getSymbolFormat(series: Series) {
    const { color, symbol } = series as any;
    if (!symbol) return null;
    return `<span style="color:${color}; font-size: 18px; position:relative; top:1px; line-height: 0">&#9679</span>`;
  }

  static formatCell(value: Primitive, column: Column, data: FlattenObject, locale?: string) {
    return VisualEngine.formatCell(value, column, data, true, undefined, locale);
  }

  static getOptionsFromGoogle = <T extends ChartOptions>(options: GoogleChartOptions | undefined) => {
    if (options) {
      const googleLegendOpts =
        typeof options.legend !== 'string'
          ? ({
              legend: {
                enabled: !!options.legend?.enabled,
                align: options.legend?.alignment === 'start' ? 'left' : options.legend?.alignment === 'end' ? 'right' : 'center',
                verticalAlign: options.legend?.position === 'top' ? options.legend?.position : 'bottom'
              }
            } as T)
          : ({ legend: { enabled: false } } as T);
      const googleAxisOpts = {
        yAxis: {
          title: {
            text: options.vAxis?.title
          }
        },
        xAxis: {
          title: {
            text: options.hAxis?.title
          }
        }
      };
      //Keep new configuration during migration
      return Highcharts.merge(googleLegendOpts, googleAxisOpts);
    }
    return {};
  };

  static getLegendFromPosition = (legendPosition: LegendPosition | undefined): Highcharts.LegendOptions => {
    if (legendPosition === 'left') {
      return {
        align: 'left',
        layout: 'vertical',
        verticalAlign: 'middle'
      };
    }

    if (legendPosition === 'right') {
      return {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle'
      };
    }

    if (legendPosition === 'top') {
      return {
        verticalAlign: 'top'
      };
    }

    return {};
  };
}
