import React, { useCallback } from 'react';
import { Text } from '@/common/components/Typography/Text';
import { ContactLookup, ContactType } from './types';
import { useHistory } from 'react-router-dom';
import { getContactUrlInfo } from './Utils/ContactUtils';
import { useRecoilValue } from 'recoil';
import { siteTreeState } from '@/modules/App/recoil/app.atoms';

interface LinkRedirectProps {
  contact: ContactLookup;
}

const ContactLinkRedirect: React.FC<LinkRedirectProps> = ({ contact }) => {
  const history = useHistory();
  const siteTree = useRecoilValue(siteTreeState);

  let displayName: string;
  if (contact.type === ContactType.COMPANY) {
    displayName = contact.company!;
  } else {
    displayName = `${contact.firstName} ${contact.lastName}`;
  }

  const onClickLink = useCallback(() => {
    const { pathname, searchParams } = getContactUrlInfo(contact.id, contact.type, siteTree);

    history.push({ pathname, search: searchParams.toString() });
  }, [history, contact.id, contact.type]);

  return (
    <div className="entity-links-single-entity">
      <Text onClick={onClickLink} link>
        {displayName}
      </Text>
    </div>
  );
};

export { ContactLinkRedirect };
