import { CustomColumn } from '@/modules/report-builder/components/Forms/Visual/Schema/CustomColumnsWrapper';
import { RawColumn, RawConfig, VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';

const compareColumns = (prevColumns: (null | string)[], newColumns: (string | null)[]) => {
  const prevSorted = prevColumns.filter(column => typeof column === 'string').toSorted((a, b) => a.localeCompare(b));
  const newSorted = newColumns.filter(column => typeof column === 'string').toSorted((a, b) => a.localeCompare(b));

  return JSON.stringify(prevSorted) === JSON.stringify(newSorted);
};

const getDefaultColumnsToCompare = (columnsObj: RawColumn | CustomColumn): string[] => {
  if ('defaultColumns' in columnsObj) {
    return columnsObj.defaultColumns;
  }

  let collectedColumns: string[] = [];

  // in case we have sub items, for ex. CustomTable "customColumns" columns object looks like this:
  // "customColumns: [{name: '1', measures: {defaultColumns: [], ...}}])"
  if (Array.isArray(columnsObj)) {
    columnsObj.forEach(item => {
      Object.keys(item).forEach(propName => {
        const fields = item[propName].defaultColumns;
        if (!fields) {
          return;
        }
        collectedColumns = [...collectedColumns, ...fields];
      });
    });
  }

  return [...new Set(collectedColumns)];
};

export const compareColumnsOfTwoVisuals = (component: VisualComponent, configA: RawConfig, configB: RawConfig) => {
  const propNamesToCheck: Partial<Record<VisualComponent, string[]>> = {
    [VisualComponent.AllocationPie]: ['breakdown', 'field'],
    [VisualComponent.BarChart]: ['metric', 'group1', 'group2'],
    [VisualComponent.Gauge]: ['metric'],
    [VisualComponent.HistoricalChart]: ['vAxis1', 'vAxis2', 'group'],
    [VisualComponent.ScatterChart]: ['group', 'series'],
    [VisualComponent.TreeMap]: ['breakdown', 'field'],
    [VisualComponent.AllocationTable]: ['columns', 'group'],
    [VisualComponent.AssetAllocationInfo]: ['rows'],
    [VisualComponent.CustomTable]: ['customColumns'],
    [VisualComponent.DashboardTable]: ['columns'],
    [VisualComponent.DrilldownTable]: ['columns'],
    [VisualComponent.FlowsTable]: ['columns', 'group'],
    [VisualComponent.HistoricalMonthlyTab]: ['group', 'metric', 'totalMetric'],
    [VisualComponent.HoldingSetInfo]: ['rows'],
    [VisualComponent.LiquidityTable]: ['rows'],
    [VisualComponent.StatsTable]: ['columns'],
    [VisualComponent.TopTab]: ['columns', 'measures', 'group'],
    [VisualComponent.TransposeTable]: ['columns', 'group', 'valueField', 'rowField', 'columnField', 'groupCategories'],
    [VisualComponent.CallOut]: ['fields'],
    [VisualComponent.SingleValue]: ['fields'],
    [VisualComponent.SelectBox]: ['_additionalFilters', 'dataFilter'],
    [VisualComponent.LimitsTable]: ['columns']
  };

  const propNames = propNamesToCheck[component];
  if (!propNames) {
    return true;
  }

  for (const i in propNames) {
    const propName = propNames[i];

    if (!configA[propName]) {
      console.error(`Field "${propName}" doesn't exist for ${component}. Can't understand, if we need to re-fetch data`);
      return false;
    }

    // sometimes the columns contains null values, it happened after migrations of the configs
    const columnsA = getDefaultColumnsToCompare(configA[propName]) as (string | null)[];
    const columnsB = getDefaultColumnsToCompare(configB[propName]) as (string | null)[];

    if (!columnsB.length) {
      return true;
    }

    const equal = compareColumns(columnsA, columnsB);

    if (!equal && columnsB) {
      return false;
    }
  }

  return true;
};
