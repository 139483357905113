import React from 'react';
import PropTypes from 'prop-types';

const Minus = ({ width = '24px', color = 'currentColor' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M5 12h14" />
  </svg>
);

Minus.propTypes = {
  width: PropTypes.string,
  color: PropTypes.string
};

export default Minus;
