import { getTheme } from '@/modules/reporting-v2/utils/theme';
import { landytechHeader } from './landytech';
import { defaultHeader } from './default';
import { HeaderFooterGenerator } from '@/modules/reporting-v2/types/HeaderFooter';
import { ltsHeader } from '@/modules/reporting-v2/views/header/lts';

const themeHeaders: Record<string, HeaderFooterGenerator> = {
  landytech: landytechHeader
};

const getHeader: HeaderFooterGenerator = (props): string | undefined => {
  const theme = getTheme();
  let headerRenderFunction = themeHeaders[theme] ?? defaultHeader;

  if (props.custom.TEMP_LTS_HEADER_FOOTER) {
    headerRenderFunction = ltsHeader;
  }

  return headerRenderFunction(props);
};

export default getHeader;
