type Range = {
  from?: string;
  to?: string;
};

export class DateRange {
  public from?: string;
  public to?: string;

  public constructor({ from, to }: Range) {
    this.from = from || undefined;
    this.to = to;
  }

  public static displayRange(to?: string, from?: string) {
    if (from) {
      return `${from} - ${to}`;
    } else {
      return `Until ${to}`;
    }
  }
}
