import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { siteTreeState } from '@/modules/App/recoil/app.atoms';
import { useRecoilValue } from 'recoil';
import type { Column } from '@/modules/reporting-v2/core/Column';
import type { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import buildHyperLinks from '../../DataTable/buildHyperLinks';

type AssetLinkContextWrapperProps = {
  children: React.ReactElement;
  defaultColumns: Column[];
};

type AssetLinkContextValue = {
  hasAssetLink: boolean;
  openAssetLink: (data: FlattenObject) => void;
};

const AssetLinkContext = createContext<AssetLinkContextValue>({
  hasAssetLink: false,
  openAssetLink: (data: FlattenObject) => null
});

const AssetLinkContextWrapper: React.FC<AssetLinkContextWrapperProps> = ({ children, defaultColumns }) => {
  const siteTree = useRecoilValue(siteTreeState);

  const singleAssetView = useMemo(() => {
    const hasAssetNameColumn = defaultColumns.some(col => col.assetNameColumn);
    if (!hasAssetNameColumn || !siteTree) {
      return;
    }

    const queue = [...siteTree];
    while (queue.length) {
      const entry = queue.pop();
      if (!entry) {
        continue;
      }
      if (
        window.location.pathname.split('/')[1] === entry.nodes?.[0] &&
        entry.view === 'SINGLE_REPORT' &&
        entry.title.toLowerCase().includes('single') &&
        entry.title.toLowerCase().includes('asset')
      ) {
        return entry;
      } else {
        if (entry.children) {
          queue.push(...entry.children);
        }
      }
    }
  }, [defaultColumns, siteTree]);

  const customAssetLinks = useMemo(() => {
    const assetNameColumn = defaultColumns.find(col => col.assetNameColumn);
    if (!assetNameColumn) {
      return;
    }

    return assetNameColumn.hyperlinks;
  }, [defaultColumns]);

  const openAssetLink = useCallback(
    (data: FlattenObject) => {
      if (customAssetLinks && Object.keys(customAssetLinks).length > 0) {
        const links = buildHyperLinks(customAssetLinks, data);
        window.open(links[0]?.url);
        return;
      }

      if (!singleAssetView) {
        return;
      }

      const hsetId = data[Object.keys(data).find(key => key.endsWith('holdingSetId'))!];
      const assetId = data['asset_info_undated.assets.assetId'];
      const searchParams = new URLSearchParams({
        assetIds: String(assetId),
        p: String(hsetId)
      });
      window.open(singleAssetView.path + '?' + searchParams.toString());
    },
    [customAssetLinks, singleAssetView]
  );

  const contextValue = useMemo(() => {
    return {
      hasAssetLink: Boolean(customAssetLinks || singleAssetView),
      openAssetLink
    };
  }, [customAssetLinks, openAssetLink, singleAssetView]);

  return <AssetLinkContext.Provider value={contextValue}>{children}</AssetLinkContext.Provider>;
};

const useAssetLinkContext = () => {
  const assetLinkContextValue = useContext(AssetLinkContext);

  return assetLinkContextValue;
};

export { AssetLinkContextWrapper, useAssetLinkContext };
