import { HoldingSetLookup } from './HoldingSetLookup';
import EntityLookup from './EntityLookup';
import EntityRef from './EntityRef';

export type BasicTagType = string | number | Date;
export type EntityTagType = HoldingSetLookup;
export type TagValueType = BasicTagType | EntityTagType;

export interface DocumentTagLookup extends EntityLookup<string> {
  readonly id: string;
  name: string;
  readonly type: DocumentTagType;
}

export enum SystemTag {
  Entity = 'Entity',
  Asset = 'Asset',
  All = 'All',
  RecycleBin = 'RecycleBin',
  RecentDocuments = 'RecentDocuments',
  Inbox = 'Inbox',
}

export enum DocumentTagType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  HOLDING_SET = 'HOLDING_SET',
  ASSET = 'ASSET',
  JSON = 'JSON'
}

export interface DocumentTagValueLookup<T> extends EntityRef {
  readonly id: number;
  readonly tag: DocumentTagLookup;
  readonly type: DocumentTagType;
  readonly value: T;
  readonly color?: string;
}

export class Tags<T> {
  readonly [SystemTag.Entity]?: T;
  readonly [SystemTag.Asset]?: T;

  readonly [key: string]: undefined | T;

  constructor(customTags?: { [key: string]: undefined | T }) {
    customTags && Object.assign(this, customTags);
  }
}
