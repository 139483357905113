import { assetDetailsIdParamKey } from './Hooks/useAssetId';
import type SiteTreeEntry from '@/common/types/entity/SiteTreeEntry';
import { findRoute } from '@/modules/ERP/Common/utils';

export const getAssetUrlInfo = (assetId: string, siteTree: SiteTreeEntry[]) => {
  const searchParams = new URLSearchParams({
    [assetDetailsIdParamKey]: assetId
  });

  const pathname = findRoute(siteTree, 'ASSET_VIEW').path;

  return { pathname, searchParams };
};
