import EntityRef from './EntityRef';
import { ContentTag, ContentTagLookup, ContentTagRef } from './ContentTag';

export interface DisplayName {
  reference?: QuantCatalogueItemRef;
  value?: string;
}

export enum ReferenceableQuantProperty {
  currency = 'currency',
  displayName = 'displayName'
}

export enum ItemType {
  percentage = 'percentage',
  amountNoUnit = 'amountNoUnit',
  date = 'date',
  price = 'price',
  string = 'string',
  list = 'list',
  bool = 'bool'
}

export enum Cardinality {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE'
}

export interface Format {
  type: ItemType;
  decimals?: number;
}

export interface Currency {
  reference?: QuantCatalogueItemRef;
  value?: string;
}

export interface Filter {
  reference: QuantCatalogueItemRef;
  value: string;
}

export enum AggregationMethod {
  sum = 'sum',
  avg = 'avg',
  weightedAvg = 'weightedAvg'
}

export interface Aggregation {
  weightsReference?: QuantCatalogueItemRef;
  reference?: QuantCatalogueItemRef;
  method?: AggregationMethod;
}

export interface QuantCatalogueItemMeta extends QuantCatalogueItemRef {
  code: string;
  displayName: DisplayName;
  category: ContentTagLookup;
  elasticPath: string;
  format: Format;
  currency?: Currency;
  groupable: boolean;
  aggregation?: Aggregation;
  filters?: Filter[];
  cardinality: Cardinality;
}

export interface QuantCatalogueItem extends QuantCatalogueItemRef {
  code: string;
  category: ContentTag;
  subCategory: ContentTag;
  group?: string;
  elasticPath: string;
  displayName: DisplayName;
  period?: string;
  description?: string;
  hint?: string;
  methodology?: string;
  format: Format;
  currency?: Currency;
  groupable: boolean;
  aggregation?: Aggregation;
  filters?: Filter[];
  cardinality: Cardinality;
  visibleInQlGrid: boolean;
  createdDate: Date | string;
  createdBy: string;
  lastModifiedDate: Date | string;
  lastModifiedBy: string;
}

export interface QuantCatalogueItemRef extends EntityRef {}

export interface QuantCatalogueItemLookup extends QuantCatalogueItemRef {
  code: string;
  displayName: DisplayName;
  category: ContentTagLookup;
  subCategory: ContentTagLookup;
  group?: string;
  elasticPath: string;
}

export class TransientQuantCatalogueItem {
  constructor(
    public code: string,
    public category: ContentTagRef,
    public subCategory: ContentTagRef,
    public elasticPath: string,
    public displayName: DisplayName,
    public format: Format,
    public cardinality: Cardinality = Cardinality.SINGLE,
    public group?: string,
    public period?: string,
    public description?: string,
    public hint?: string,
    public methodology?: string,
    public currency?: Currency,
    public groupable: boolean = false,
    public aggregation?: Aggregation,
    public filters?: Filter[],
    public visibleInQlGrid: boolean = false
  ) {}
}

export class PersistentQuantCatalogueItem implements QuantCatalogueItem, QuantCatalogueItemRef {
  constructor(
    public id: number,
    public code: string,
    public category: ContentTag,
    public subCategory: ContentTag,
    public elasticPath: string,
    public displayName: DisplayName,
    public format: Format,
    public cardinality: Cardinality,
    public createdDate: Date,
    public createdBy: string,
    public lastModifiedDate: Date,
    public lastModifiedBy: string,
    public group?: string,
    public period?: string,
    public description?: string,
    public hint?: string,
    public methodology?: string,
    public currency?: Currency,
    public groupable: boolean = false,
    public aggregation?: Aggregation,
    public filters?: Filter[],
    public visibleInQlGrid: boolean = false
  ) {}

  public static fromQuantCatalogueItem(template: QuantCatalogueItem & QuantCatalogueItemRef) {
    return new PersistentQuantCatalogueItem(
      template.id,
      template.code,
      template.category,
      template.subCategory,
      template.elasticPath,
      template.displayName,
      template.format,
      template.cardinality,
      new Date(template.createdDate),
      template.createdBy,
      new Date(template.lastModifiedDate),
      template.lastModifiedBy,
      template.group,
      template.period,
      template.description,
      template.hint,
      template.methodology,
      template.currency,
      template.groupable,
      template.aggregation,
      template.filters,
      template.visibleInQlGrid
    );
  }
}
