import { Field } from '@/modules/reporting-v2/core/Field';
import { Filter } from '@/modules/reporting-v2/core/Filter';
import { FilterGroupType } from '@/modules/reporting-v2/core/FilterGroup';
import { RawFilter, RawFilterGroup } from '@/modules/reporting-v2/types/Filter';
import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import { concatenateUniqueIndexFields, ConfigMappedRawColumn } from '@/modules/reporting-v2/utils/IndexUtils';

class LogicalFilter {
  type: FilterGroupType;
  filter: Filter;

  constructor(filter: Filter, type: FilterGroupType = FilterGroupType.AND) {
    this.type = type;
    this.filter = filter;
  }

  getAllUsedFields(): Field[] {
    return this.filter.getAllUsedFields();
  }

  evaluate(row: FlattenObject) {
    return this.filter.evaluate(row);
  }
}

class LogicalFilterGroup {
  type: FilterGroupType;
  filters: Array<LogicalFilterGroup | LogicalFilter>;
  columns: ConfigMappedRawColumn[];
  initialField?: Field; // the field on which the filter is applied, used by default if a filter has no field selected

  constructor(filters: Array<RawFilterGroup | RawFilter>, columns: typeof this.columns, type: FilterGroupType = FilterGroupType.AND, initialField?: Field) {
    this.type = type;
    this.columns = columns;
    this.initialField = initialField;

    this.filters = filters
      .filter(filter => 'conditions' in filter || filter.baseColumn?.defaultColumns.length || this.initialField)
      .map(filter => {
        if ('conditions' in filter) {
          return new LogicalFilterGroup(filter.conditions as RawFilterGroup[], this.columns, filter.type, this.initialField);
        }
        const initialField = filter.baseColumn?.defaultColumns?.[0] ? new Field(filter.baseColumn.defaultColumns[0]) : this.initialField!;
        return new LogicalFilter(Filter.fromRawFilter(filter, this.columns, initialField), filter.type);
      });
  }

  getAllUsedFields(): Field[] {
    const fields = this.filters.map(filter => filter.getAllUsedFields());
    return concatenateUniqueIndexFields(...fields);
  }

  evaluate = (row: FlattenObject): boolean => {
    let result = true;

    this.filters.forEach(filter => {
      const filterOutput = filter.evaluate(row);

      if (filter.type === FilterGroupType.AND) result = result && filterOutput;
      if (filter.type === FilterGroupType.OR) result = result || filterOutput;
    });

    return result;
  };
}
export { LogicalFilterGroup };
