import React, { useMemo } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import './style.css';

interface IFilterButtonProps {
  label: string;
  values?: (string | Record<string, string | number>)[];
  removable?: boolean;
  onClick?: () => void;
  onFocus?: () => void;
  readonly?: boolean;
  childrenInButton?: boolean;
  children: React.ReactNode;
}

const FilterButton: React.FC<IFilterButtonProps> = ({ label, values = [], children, removable = false, onClick, readonly, childrenInButton = false }) => {
  const hasValues = values.length !== 0;

  const hasUnknownValues = values.some(val => typeof val !== 'string');
  const margin = useMemo(() => ({ marginRight: removable ? 4 : 0 }), [removable]);

  const button = (
    <label tabIndex={1} className={`filter-button ${readonly ? 'disabled' : ''}`} onClick={readonly ? undefined : onClick}>
      {label.toUpperCase()}
      {hasValues && ':'}

      {childrenInButton && children}

      {!childrenInButton && hasValues && (
        <div className="tags" style={margin}>
          <span className="tag">{typeof values[0] === 'object' ? '' : values[0]}</span>

          {values.length > 1 && (
            <span className="tag" style={fontWeight}>
              +{values.length - 1}
            </span>
          )}
        </div>
      )}
      {hasValues && removable && <CloseCircleOutlined style={marginRight} />}
    </label>
  );

  if (hasUnknownValues && !readonly) return null;

  if (!children || readonly || childrenInButton) return button;

  return (
    <Popover placement="bottomLeft" content={children} trigger="click">
      {button}
    </Popover>
  );
};

const fontWeight = { fontWeight: 600 };
const marginRight = { marginRight: -8 };

export default FilterButton;
