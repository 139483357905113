import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Comment } from '../../../common/types/entity/Conversation';
import { DATE_FORMAT, DateFormatter } from 'ui-sesame-components';

dayjs.extend(relativeTime);

type CommentTimestampProps = {
  comment: Comment;
};

export default function CommentTimestamp({ comment }: Readonly<CommentTimestampProps>) {
  const { createdDate, lastModifiedDate } = comment;
  const title = useMemo(
    () => (
      <>
        <FormattedMessage id={'generic.created'} /> <DateFormatter value={dayjs(createdDate)} format={DATE_FORMAT.DATETIME} /> :
        <br />
        <FormattedMessage id={'generic.modified'} /> <DateFormatter value={dayjs(lastModifiedDate)} format={DATE_FORMAT.DATETIME} /> :
      </>
    ),
    [createdDate, lastModifiedDate]
  );
  return <Tooltip title={title}>{dayjs(createdDate).fromNow()}</Tooltip>;
}
