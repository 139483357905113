export enum Permissions {
  SUPER_ADMIN = '#SUPER_ADMIN',
  DATA_MANAGEMENT = 'DATA_MANAGEMENT',
  CONTENT_MANAGEMENT = 'CONTENT_MANAGEMENT',
  REPORT_CENTRE_MANAGEMENT = 'REPORT_CENTRE_MANAGEMENT',
  REPORT_CENTRE_SCHEDULE_MANAGEMENT = 'REPORT_CENTRE_SCHEDULE_MANAGEMENT',
  REPORT_CENTRE_TEMPLATE_MANAGEMENT = 'REPORT_CENTRE_TEMPLATE_MANAGEMENT',
  REPORT_BUILDER_COMPONENT_LIBRARY_MANAGEMENT = 'REPORT_BUILDER_COMPONENT_LIBRARY_MANAGEMENT',
  REPORT_BUILDER_GRID_CONTRIBUTOR = 'REPORT_BUILDER_GRID_CONTRIBUTOR',
  REPORT_BUILDER_PUBLISHER_CONTRIBUTOR = 'REPORT_BUILDER_PUBLISHER_CONTRIBUTOR',
  REPORT_BUILDER_GRID_V2_CONTRIBUTOR = 'REPORT_BUILDER_GRID_V2_CONTRIBUTOR',
  QUANT_CATALOGUE_MANAGEMENT = 'QUANT_CATALOGUE_MANAGEMENT',
  SPREADSHEET_BUILDER = 'SPREADSHEET_BUILDER',
  SPACE_READER = 'SPACE_READER',
  DOCUMENT_CONTRIBUTOR = 'DOCUMENT_CONTRIBUTOR',
  DOCUMENT_READER = 'DOCUMENT_READER',
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  CLIENT_OPERATOR = 'CLIENT_OPERATOR',
  PRODUCT_OPERATOR = 'PRODUCT_OPERATOR',
  CUSTOM_REPORT_READER = 'CUSTOM_REPORT_READER',
  CUSTOM_REPORT_CONTRIBUTOR = 'CUSTOM_REPORT_CONTRIBUTOR',
  SCHEDULE_REPORT_READER = 'SCHEDULE_REPORT_READER',
  SCHEDULE_REPORT_CONTRIBUTOR = 'SCHEDULE_REPORT_CONTRIBUTOR',
  TASK_CONTRIBUTOR = 'TASK_CONTRIBUTOR',
  NOTE_CONTRIBUTOR = 'NOTE_CONTRIBUTOR',
  LINK_CONTRIBUTOR = 'LINK_CONTRIBUTOR',
  CONTACT_CONTRIBUTOR = 'CONTACT_CONTRIBUTOR',
  MESSAGE_CONTRIBUTOR = 'MESSAGE_CONTRIBUTOR',
  USER_CUSTOM_PAGE_CONTRIBUTOR = 'USER_CUSTOM_PAGE_CONTRIBUTOR',
  AAT_CONTRIBUTOR = 'AAT_CONTRIBUTOR',
  AAT_READER = 'AAT_READER',
  SESAME_API_HOLDINGS_READER = 'SESAME_API_HOLDINGS_READER',
  SESAME_API_TRANSACTIONS_READER = 'SESAME_API_TRANSACTIONS_READER',
  SESAME_API_ASSET_RISK_READER = 'SESAME_API_ASSET_RISK_READER',
  SESAME_API_ENTITY_LIST_READER = 'SESAME_API_ENTITY_LIST_READER',
  SESAME_API_SUB_PORTFOLIO_HOLDINGS_READER = 'SESAME_API_SUB_PORTFOLIO_HOLDINGS_READER',
  SESAME_API_ENTITY_RISK_READER = 'SESAME_API_ENTITY_RISK_READER',
  SESAME_API_ENTITY_PERFORMANCE_READER = 'SESAME_API_ENTITY_PERFORMANCE_READER',
  SESAME_API_ENTITY_INFO_READER = 'SESAME_API_ENTITY_INFO_READER',
  SESAME_API_ENTITY_DATES_READER = 'SESAME_API_ENTITY_DATES_READER',
  ENTITY_CONTRIBUTOR = 'ENTITY_CONTRIBUTOR',
  BULKTOOL_READER = 'BULKTOOL_READER',
  BULKTOOL_CONTRIBUTOR = 'BULKTOOL_CONTRIBUTOR',
  REPORT_TEMPLATE_READER = 'REPORT_TEMPLATE_READER',
  CUSTOM_COMPONENT_READER = 'CUSTOM_COMPONENT_READER',
  CUSTOM_COMPONENT_CONTRIBUTOR = 'CUSTOM_COMPONENT_CONTRIBUTOR',
  DOCUMENT_INBOX_READER = "DOCUMENT_INBOX_READER",
  DOCUMENT_INBOX_CONTRIBUTOR = "DOCUMENT_INBOX_CONTRIBUTOR",
}

// internal roles
export enum Roles {
  INTERNAL = 'INTERNAL',
  SUPER_ADMIN = 'SUPERADMIN',
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  CLIENT_OPERATOR = 'CLIENT_OPERATOR',
  SERVICE_OPERATOR = 'SERVICE_OPERATOR',
  QA_OPERATOR = 'QA_OPERATOR',
  PRODUCT_OPERATOR = 'PRODUCT_OPERATOR',
  SUPPORT_OPERATOR = 'SUPPORT_OPERATOR',
  PIPELINE_OPERATOR = 'PIPELINE_OPERATOR',
  SALE_OPERATOR = 'SALE_OPERATOR'
}
