import { Select } from 'antd';
import React, { useCallback } from 'react';
import EntityRef from '@/common/types/entity/EntityRef';
import getParent from '@/utils/getParent';
import { HoldingSetLookup } from '@/common/types/entity/HoldingSetLookup';
import useUserHoldingSetsOptions from './hooks/useUserHoldingSetsOptions';
import { RecoilValue, useRecoilValue } from 'recoil';
import { holdingSetsMap } from '@/recoil/holdingSets';
import { withSmallSuspense } from '@/common/suspense';
import { CaretDownOutlined } from '@ant-design/icons';

export interface HoldingSetSelectProps {
  source?: RecoilValue<Map<number, HoldingSetLookup>>;
  defaultValue?: EntityRef;
  value?: number;
  emptyOption?: string;
  onChange?: (user?: number) => void;
  className?: string;
}

export default withSmallSuspense(HoldingSetSelect);

function HoldingSetSelect({ source = holdingSetsMap, defaultValue, value, emptyOption, onChange, className }: HoldingSetSelectProps) {
  const holdingSets = useRecoilValue(source);
  const options = useUserHoldingSetsOptions(holdingSets, emptyOption);

  const _onChange = useCallback(
    (holdingSetId: number) => {
      onChange && onChange(holdingSetId);
    },
    [holdingSets, onChange]
  );

  return (
    <Select
      className={className ? `holding-set-select ${className}` : 'holding-set-select'}
      {...(defaultValue ? { defaultValue: defaultValue.id } : { defaultValue: -1 })}
      value={value}
      showSearch
      popupMatchSelectWidth={false}
      getPopupContainer={getParent}
      optionFilterProp="title"
      optionLabelProp="title"
      onChange={_onChange}
      suffixIcon={<CaretDownOutlined className={'modal-contact-create-clickable-icon'} />}
    >
      {options}
    </Select>
  );
}
