import { atom } from 'recoil';

const taskFilterState = atom<string>({
  key: 'tasks-list-state',
  default: undefined
});

const notesFilterState = atom<string>({
  key: 'notes-list-state',
  default: undefined
});

export { taskFilterState, notesFilterState };
