import { LookThroughTypes } from '@/config/lookThroughTypes';
import { useHistory } from 'react-router-dom';
import { DateRange } from '@/modules/reporting-v2/types/DateRange';
import { findHoldingSetById } from '@/utils/findHoldingSet';
import { message } from 'antd';
import { ParametersHandler } from './ParametersHandler';
import type { SesameUser } from '../SesameUser';
import type { UUID } from '@/common/types/types';
import dayjs from 'dayjs';

export interface EntityParameters {
  holdingSetId: number;
  lookThrough?: number;
  date?: string;
  range?: DateRange;
}

export enum SerializedConsolidationType {
  DIRECT = '_d',
  LOOKTHROUGH = '_l'
}

type ParamMatch = {
  holdingSet?: string;
  range?: string;
  date?: string;
  lookThrough?: string;
};

/**
 * @description
 * This utility class handles the specification behind the url parameter "p" and only deals with unreserved url characters.
 * This url parameter can be represented as a list of serialized @EntityParameters each prefixed by their identifiers.
 * Example of a list :
 *
 * p=h232_d2020-03-23_l2~h234_l1_d2020-04-21.2020-04-25
 *
 * Which would translate into :
 *
 * [
 *    {holdingSetId: 232, date: "2020-03-23", lookThrough: 2},
 *    {holdingSetId: 234, range: {from: "2020-04-21", to : "2020-04-25"}, lookThrough: 1}
 * ]
 */
class EntityParametersHandler {
  public static readonly parametersKey = 'p';
  private static readonly entitySeparator = '~';
  private static readonly parameterSeparator = '_';

  public static readonly entityLimit = (multiHoldingSetFeatures?: boolean) => (multiHoldingSetFeatures ? 5 : 1);

  public static readonly localStorageParamsKey = (id: UUID) => `params@${id}`;

  public static retrieveParamsList(currentUser: SesameUser, string?: string, multiHoldingSetFeatures?: boolean, useVerifiedCheckpointDate = true): Array<EntityParameters> {
    const params = string && !string.startsWith('?') ? string : new URLSearchParams(string || window.location.search).get(this.parametersKey);

    if (!params) {
      return [];
    }

    const matches = params.match(/(((\d+))(_(\d{8}_\d{8}))?(_(\d{8}))?(_([d,l]))?)+/g);

    if (!matches) {
      console.error(`Invalid parameters format (${params}).`);
      return [];
    }

    const parsedParams: Array<EntityParameters> = matches.map(match => {
      const serializedParam: ParamMatch = /((?<holdingSet>(\d+))(?<range>_(\d{8}_\d{8}))?(?<date>_(\d{8}))?(?<lookThrough>_([d,l]))?)/g.exec(match)!.groups!;

      const object: EntityParameters = {
        holdingSetId: this.deserializeHoldingset(serializedParam.holdingSet!)
      };

      if (serializedParam.lookThrough) {
        object.lookThrough = this.deserializeLookthrough(serializedParam.lookThrough);
      }

      if (serializedParam.date) {
        object.date = this.deserializeDate(serializedParam.date);
      }

      if (serializedParam.range) {
        object.range = this.deserializeRange(serializedParam.range);
      }

      return object;
    });

    const processedEntities = this.processEntitiesParams(currentUser, parsedParams.slice(0, this.entityLimit(multiHoldingSetFeatures)), useVerifiedCheckpointDate);
    if (processedEntities.length !== 0) {
      return processedEntities;
    }

    return [{ holdingSetId: currentUser.holdingSetTree[0].id }];
  }

  public static contains(currentUser: SesameUser, holdingSetId?: number, multiEntityFeatures?: boolean) {
    if (!holdingSetId) {
      return false;
    }

    return this.retrieveParamsList(currentUser, undefined, multiEntityFeatures).find(param => param.holdingSetId === holdingSetId) !== undefined;
  }

  public static parseQueryString(currentUser: SesameUser, multiEntityFeatures?: boolean, useVerifiedCheckpointDate = true) {
    return (name: string, _value: string | Array<string>) => {
      let value = _value;

      if (Array.isArray(value)) {
        value = value[0];
      }

      switch (name) {
        case 'p':
          return EntityParametersHandler.retrieveParamsList(currentUser, value, multiEntityFeatures, useVerifiedCheckpointDate);
        case 'page':
        case 'company': //no more int parameter for company todo replace company by accountId
        case 'accountId':
        case 'companyId':
          return value;
        default:
          return ParametersHandler.retrieveParams({ [name]: value })[name];
      }
    };
  }

  private static deserializeHoldingset(hsetId: string) {
    return Number(hsetId);
  }

  private static deserializeLookthrough(lookThrough: string) {
    return lookThrough === SerializedConsolidationType.LOOKTHROUGH ? LookThroughTypes.Lookthrough : LookThroughTypes.Direct;
  }

  /**
   * @param _date By default arrives prepended by underscore _
   */
  private static deserializeDate(_date: string, dateStart = 1) {
    const date = _date.substring(dateStart);

    return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
  }

  private static deserializeRange(_range: string) {
    const [from, to] = _range.substring(1).split(this.parameterSeparator);

    return new DateRange({
      from: from && this.deserializeDate(from, 0),
      to: to && this.deserializeDate(to, 0)
    });
  }

  public static encodeHoldingSetIds(params: Array<EntityParameters> = []) {
    return params.reduce((acc, val) => `${acc}${val.holdingSetId}`, ``);
  }

  public static cacheParams(params: Array<EntityParameters>, userId: UUID, multiEntityFeatures?: boolean) {
    const cachedParams = this.retrieveCachedParams(userId);

    if (!cachedParams || multiEntityFeatures) {
      window.localStorage.setItem(this.localStorageParamsKey(userId), JSON.stringify(params));
    } else {
      const [, ...rest] = cachedParams;
      if (params[0]) {
        window.localStorage.setItem(this.localStorageParamsKey(userId), JSON.stringify([params[0], ...rest]));
      } else {
        window.localStorage.removeItem(this.localStorageParamsKey(userId));
      }
    }
  }

  /**
   * @description remove date from cached params to use last verified date on next login
   */
  public static clearCachedEntityDate(userId: UUID) {
    const cachedParams = this.retrieveCachedParams(userId);
    if (!cachedParams) {
      return;
    }

    const paramsWithoutDate = cachedParams.map(param => {
      const { date, range, ...rest } = param;
      return rest;
    });

    this.cacheParams(paramsWithoutDate, userId);
  }

  public static setFromCache(history: ReturnType<typeof useHistory>, userId: UUID, multiHoldingSetFeatures?: boolean) {
    try {
      const params = this.retrieveCachedParams(userId);

      if (params?.length) {
        EntityParametersHandler.setAndCacheUrlParams(params.slice(0, this.entityLimit(multiHoldingSetFeatures)), history, userId, multiHoldingSetFeatures);
      }
    } catch (err) {
      console.error(`No parameters available from cache.`);
    }
  }

  /**
   * @description Sets {this.parametersKey} in URL search without discarding the rest
   */
  public static setAndCacheUrlParams(params: Array<EntityParameters>, history: ReturnType<typeof useHistory>, userId: UUID, multiEntityFeatures?: boolean) {
    const newParams = new URLSearchParams(window.location.search);

    if (newParams.has(this.parametersKey)) {
      newParams.delete(this.parametersKey);
    }

    newParams.set(this.parametersKey, this.serialize(params));

    history.push({
      search: newParams.toString()
    });

    this.cacheParams(params, userId, multiEntityFeatures);
  }

  /**
   * @throws Throws parse error if user does not have cached parameters/invalid ones
   */
  public static retrieveCachedParams(userId: UUID): Array<EntityParameters> | undefined {
    const key = this.localStorageParamsKey(userId);
    try {
      const paramsFromStorage = JSON.parse(window.localStorage.getItem(key)!);

      if (Array.isArray(paramsFromStorage)) {
        return paramsFromStorage as Array<EntityParameters>;
      }

      throw new Error();
    } catch (err) {
      localStorage.removeItem(key);
    }
  }

  /**
   * @description Some entities are not LOOK_THROUGH consolidation type enabled - for this reason, we do not want the user to query lookthrough data with them. In the case where an user tried to manually override the URL with lookthrough parameter, this takes care of overriding it.
   */
  public static processEntitiesParams(currentUser: SesameUser, paramsList: Array<EntityParameters>, useVerifiedCheckpointDate = true) {
    if (!currentUser) return [];

    return paramsList
      .map(param => {
        const entity = findHoldingSetById(currentUser.holdingSetTree, param.holdingSetId);
        if (!entity) {
          return undefined;
        }

        if (!param.lookThrough) {
          param.lookThrough = LookThroughTypes.Lookthrough;
        }

        if (!param.date && !param.range && useVerifiedCheckpointDate) {
          param.date = currentUser.verifiedDates[param.holdingSetId];
        } else if (param.date) {
          // check date is not after last verified date
          const lastVerifiedDate = currentUser.verifiedDates[param.holdingSetId];
          if (dayjs(param.date).isAfter(dayjs(lastVerifiedDate))) {
            param.date = lastVerifiedDate;
          }
        }

        if (entity.lookThroughEnabled || param.lookThrough !== LookThroughTypes.Lookthrough) {
          return param;
        } else {
          return { ...param, lookThrough: LookThroughTypes.Direct };
        }
      })
      .filter(param => param !== undefined);
  }

  public static serialize(params: Array<EntityParameters>) {
    let str = params.reduce<string>((string, param) => {
      const serializedDate = this.serializeDate(param.date, param.range);
      const serializedHoldingSetId = this.serializeHoldingSetId(param.holdingSetId);
      const serializedLookThrough = this.serializeLookThrough(param.lookThrough);

      let next = string;

      next += serializedHoldingSetId;

      if (serializedDate) next += `${this.parameterSeparator}${serializedDate}`;
      if (serializedLookThrough) next += `${serializedLookThrough}`;

      next += this.entitySeparator;

      return next;
    }, '');

    if (str.endsWith(this.entitySeparator)) {
      str = str.substring(0, str.length - 1);
    }
    return str;
  }

  private static serializeHoldingSetId(hsetId: number) {
    return String(hsetId);
  }

  public static serializeLookThrough(lookThrough?: number) {
    return lookThrough === LookThroughTypes.Lookthrough ? SerializedConsolidationType.LOOKTHROUGH : SerializedConsolidationType.DIRECT;
  }

  private static serializeDate(date?: string, range?: DateRange) {
    if (date) {
      return this.shrinkDate(date);
    }

    if (range) {
      let str = ``;

      if (range.from) {
        str += this.shrinkDate(range.from);
      }

      if (range.to) {
        str += `${this.parameterSeparator}${this.shrinkDate(range.to)}`;
      }

      return str;
    }

    return ``;
  }

  private static shrinkDate(date: string) {
    const [year, month, day] = date.split('-');

    return `${year}${month}${day}`;
  }

  public static updateUrlParameters(currentUser: SesameUser, history: ReturnType<typeof useHistory>, values: EntityParameters, multiEntityFeatures?: boolean) {
    const paramsList = this.retrieveParamsList(currentUser, undefined, multiEntityFeatures);

    const params = paramsList.map(param => {
      if (param.holdingSetId === values.holdingSetId) {
        return values;
      } else {
        return param;
      }
    });

    EntityParametersHandler.setAndCacheUrlParams(params, history, currentUser.userId, multiEntityFeatures);
  }

  public static setDefaultEntity(currentUser: SesameUser, history: ReturnType<typeof useHistory>) {
    const entities = currentUser.holdingSetTree;

    if (!entities.length) {
      return undefined;
    }

    const entity = entities[0];

    const param: EntityParameters = { holdingSetId: entity.id, lookThrough: entity.lookThroughEnabled ? LookThroughTypes.Lookthrough : LookThroughTypes.Direct };

    EntityParametersHandler.addEntityEntry(currentUser, history, param);
  }

  public static addEntityEntry(currentUser: SesameUser, history: ReturnType<typeof useHistory>, param: EntityParameters, multiEntityFeatures?: boolean) {
    if (multiEntityFeatures) {
      const params = this.retrieveParamsList(currentUser, undefined, multiEntityFeatures);

      if (params.length >= this.entityLimit(multiEntityFeatures)) {
        message.error(`Entity limit reached. Please remove some entities.`);
        return;
      }

      if (param) {
        const duplicate = params.find(existingParam => existingParam.holdingSetId === param.holdingSetId);

        if (duplicate !== undefined) {
          message.error(`This entity is already selected.`);
          return;
        }

        params.push(param);
        EntityParametersHandler.setAndCacheUrlParams(params, history, currentUser.userId, multiEntityFeatures);
      } else {
        const entities = currentUser.holdingSetTree;
        const entity = entities.find(storeEntity => !params.some(urlParam => urlParam.holdingSetId === storeEntity.id));

        if (!entity) {
          message.error(`No more entities available.`);
          return;
        }

        params.push({
          holdingSetId: entity.id,
          lookThrough: LookThroughTypes.Direct
        });
      }
    } else {
      const params: Array<EntityParameters> = [param];
      EntityParametersHandler.setAndCacheUrlParams(params, history, currentUser.userId, multiEntityFeatures);
    }
  }

  public static removeEntity(currentUser: SesameUser, history: ReturnType<typeof useHistory>, holdingSetId: number, multiEntityFeatures?: boolean) {
    const params = this.retrieveParamsList(currentUser, undefined, multiEntityFeatures).filter(param => param.holdingSetId !== holdingSetId);

    EntityParametersHandler.setAndCacheUrlParams(params, history, currentUser.userId, multiEntityFeatures);
  }
}

export { EntityParametersHandler };
