import { Button, Layout, message, Popover, Row } from 'antd';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { TransientTask } from '../../../../common/types/entity/Task';
import TaskForm from '../form/TaskForm';
import getParent from '../../../../utils/getParent';
import './styles.css';
import useGlobalEscapeKeyListener from '../../../../common/hooks/useGlobalEscapeKeyListener';
import createTask from '../../api/createTask';
import getTaskUrl from '../../getTaskUrl';
import { ContextMode } from '../form/TaskContextGrid';
import { FormattedMessage, useIntl } from 'react-intl';
import { constSelector, useRecoilCallback, useRecoilValue } from 'recoil';
import { siteTreeState } from '@/modules/App/recoil/app.atoms';
import { Text } from '@/common/components/Typography/Text';
import { TaskFormType } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/CreateTask';
import { Priority, Reminders, Status, TaskCreationPayload } from '@/modules/ERP/Contacts/types';
import { Dayjs } from 'dayjs';
import { EntityLinkType, LinkableObject } from '@/common/types/entity/Link';
import { useAssetId } from '@/modules/ERP/Asset/AssetDetails/Hooks/useAssetId';
import { existingListObjectsSelector, linkObjectSelector } from '@/modules/ERP/Common/Recoil/links.selector';
import { disableLink } from '@/modules/ERP/Contacts/Api/disableLink';
import { createLink } from '@/modules/ERP/Contacts/Api/createLink';
import { UUID } from '@/common/types/types';
import { entityLinksSelector } from '@/modules/ERP/Common/Recoil/erp.selectors';
import { taskListTableSelector } from '@/modules/ERP/Contacts/Recoil/contacts.selectors';
import { withSmallSuspense } from '@/common/suspense';
import { useLocation } from 'react-router-dom';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import { EntityParametersHandler } from '@/common/types/app/EntityParametersHandler';

const { Footer, Content } = Layout;

export interface CreateTaskDialogProps {
  task?: TransientTask;
  showContext?: ContextMode;
  getContainer?: (triggerNode: HTMLElement) => HTMLElement;
  onOpenChange?: (visible: boolean) => void;
}

const defaultSchemaTask: TaskFormType = {
  title: '',
  description: '',
  reminder: Reminders.AT_DUE_TIME,
  status: Status.TO_DO,
  priority: Priority.MODERATE,
  dueDate: null,
  assignee: null,
  context: {},
};

export const CreateTaskDialog: FC<PropsWithChildren<CreateTaskDialogProps>> = withSmallSuspense(({ children, task = new TransientTask(), showContext, getContainer = getParent, onOpenChange }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState<boolean>();
  const [disabled, setDisabled] = useState<boolean>(true);
  const translator = useIntl();
  const location = useLocation();

  const siteTree = useRecoilValue(siteTreeState);
  const holdingSetentityId = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get('p')?.split('_')[0];
  }, [location.search]);
  const entityId = useAssetId();
  
  const currentUser = useRecoilValue(currentUserSelector);
  const selectedEntities = useMemo(() => EntityParametersHandler.retrieveParamsList(currentUser, location.search), [currentUser, location]);
  const _pageObject = useRecoilValue(
    holdingSetentityId
      ? linkObjectSelector({
        type: EntityLinkType.HOLDING_SET,
        id: holdingSetentityId.toString()
      })
      : constSelector(null)
  );
  const pageObject = useMemo(() => {
    if (_pageObject) {
      return { ..._pageObject, _entityType: EntityLinkType.HOLDING_SET };
    }


    return null;
  }, [_pageObject]);

  const [createTaskData, setCreateTaskData] = useState({ ...defaultSchemaTask, context: { value: task.context?.value }, holdingSetId: selectedEntities[0].holdingSetId });
  const [selectedLinks, setSelectedLinks] = useState<Array<LinkableObject>>(pageObject !== null ? ([pageObject] as Array<LinkableObject>) : []);

  useGlobalEscapeKeyListener(() => {
    setVisible(false);
    // onOpenChange is not called for some reason automatically
    onOpenChange && onOpenChange(false);
  });
  useEffect(() => {
    if (visible !== undefined && visible) {
      setVisible(undefined);
    }
  }, [visible]);
  
  const onClickSave = useRecoilCallback(
    ({ refresh }) =>
      () => {
        setLoading(true);
        createTask(createTaskData as TaskCreationPayload)
          .then(async responseTask => {
            try {
              await Promise.all(
                selectedLinks.map(obj => {
                  createLink(EntityLinkType.TASK, responseTask.id, {
                    type: obj._entityType,
                    entityId: obj.id as UUID
                  });
                })
              );

              if (responseTask.status === Status.REMOVED) {
                await disableLink(EntityLinkType.TASK, responseTask.id);
              }
              message.success(
                <>
                  <Text>{translator.formatMessage({ id: 'generic.task' })}</Text>
                  &nbsp;
                  <a href={getTaskUrl(parseInt(responseTask.id), siteTree)}>#{responseTask.id}</a>
                  &nbsp;
                  <Text>{translator.formatMessage({ id: 'generic.hasBeenCreated' })}</Text>
                </>
              );
              setVisible(false);
              onOpenChange && onOpenChange(false);

              setSelectedLinks([]);
              setTimeout(() => {
                refresh(
                  linkObjectSelector({
                    type: EntityLinkType.TASK,
                    id: String(responseTask.id)
                  })
                );
                refresh(existingListObjectsSelector([EntityLinkType.TASK, responseTask.id]));
                setTimeout(() => {
                  if (entityId && EntityLinkType.HOLDING_SET) {
                    refresh(entityLinksSelector([EntityLinkType.HOLDING_SET, entityId]));
                  }
                  refresh(taskListTableSelector);
                }, 1000);
              }, 1000);
            } catch (err) {
              message.error(
                translator.formatMessage({
                  id: 'generic.somethingWentWrongWhenSavingLinks'
                })
              );
            }
          })
          .catch(error => {
            message.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      },
    [selectedLinks, entityId, createTaskData]
  );

  const onChange = useCallback(
    (
      values: any,
      key: string,
      value:
        | string
        | Dayjs
        | {
          id: number;
        }
        | null
    ) => {
      const newState = { ...values, [key]: value };

      setCreateTaskData(newState);

    },
    [setCreateTaskData]
  );

  return (
    <Popover
      overlayClassName={`tms-form-popover ${showContext === undefined ? 'no-context' : 'with-context'}`}
      getPopupContainer={getContainer}
      getTooltipContainer={getContainer}
      placement="top"
      {...(visible !== undefined ? { open: visible } : {})}
      open={true}
      onOpenChange={onOpenChange}
      autoAdjustOverflow={true}
      trigger="click"
      content={
        <Layout className="tms-form-popover-layout">
          <Content>
            <TaskForm setDisabled={setDisabled} task={task} setSelectedLinks={setSelectedLinks} selectedLinks={selectedLinks} onChange={onChange} createTaskData={createTaskData} showContext={showContext} />
          </Content>
          <Footer>
            <Row className="task-dialog-buttons-holder" justify="end">
              <Button onClick={() => (onOpenChange ? onOpenChange(false) : null)} style={{ marginRight: '10px' }}>
                <FormattedMessage id={'generic.cancel'} />
              </Button>
              <Button loading={loading} type="primary" disabled={disabled} htmlType="submit" onClick={onClickSave}>
                <FormattedMessage id={'generic.saveTask'} />
              </Button>
            </Row>
          </Footer>
        </Layout>
      }
    >
      {children}
    </Popover>
  );
});
