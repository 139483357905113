export interface FilterDefinition {
  operator: FilterOperator;
  value: string | string[]; // type is too vague, dates also represented as strings
  column: {
    // what is that object?
    columns: string[]; // O_o, column contains some other columns?!
    defaultColumns: string[]; // O_O, what does it have to do with filters at all?
    options: any; // what is that?
    filters: any; // whaatt? O_O omg
  };
}

export const FilterOperatorDisplayValues: {
  [key in FilterOperator]: string;
} = {
  '*': 'contains',
  '!*': 'does not contain',
  '==': 'equals',
  '!=': 'not equal',
  '^': 'starts with',
  '<': 'less than',
  '<=': 'less than or equal',
  '>': 'greater than',
  '>=': 'greater than or equal',
  boolean: 'N/A values',
  '==|boolean': 'Undefined or equal to'
};

export enum FilterOperator {
  CONTAINS = '*',
  DOES_NOT_CONTAIN = '!*',
  STARTS_WITH = '^',
  EQUALS = '==',
  NOT_EQUAL = '!=',
  GREATER_THAN = '>',
  GREATER_OR_EQUAL = '>=',
  LESS_THAN = '<',
  LESS_OR_EQUAL = '<=',
  UNDEFINED_VALUES = 'boolean',
  EQUALS_OR_UNDEFINED = '==|boolean'
}
