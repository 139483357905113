import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { TaskCreationPayload, TaskDTO } from '@/modules/ERP/Contacts/types';
import { sendHubspotCustomEvent } from '@/common/components/HubSpot/HubSpot';

export default (task: TaskCreationPayload): Promise<TaskDTO> => {
  return requestApi({
    service: 'collaboration',
    method: 'post',
    url: `/api/task`,
    data: task
  }).then((response: AxiosResponse<TaskDTO> | undefined) => {
    sendHubspotCustomEvent('pe45390240_task_created', { taskname: response?.data?.title });
    return response?.data!;
  });
};
