import React from 'react';
import HistoricalMonthlyTabConfig from './HistoricalMonthlyTabConfig';
import { Column, ColumnFormatting } from '@/modules/reporting-v2/core/Column';
import { Field } from '@/modules/reporting-v2/core/Field';
import { nanoid } from 'nanoid';
import { Row, RowGroup, SortOrder } from '@/modules/reporting-v2/types/VisualEngine';
import { IDataTableProps } from '../../components/DataTable/DataTableTypes';
import DataTable from '../../components/DataTable';
import { DateRange } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/VisualSpecificProps';
import { getDateValue } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/utils/getDateValue';
import { getMonthByKey } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/utils/getMonthByKey';
import type { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import type { ColumnConfig } from '@/modules/reporting-v2/types/Column';
import { ColumnHeader } from '@/modules/reporting-v2/types/VisualUtils';
import { getTableMonths } from './utils/getTableMonths';
import { UNCLASSIFIED } from '@/const';

const MemoizedDataTablev2 = React.lazy(async () => ({ default: (await import('@/modules/reporting-v2/core/components/DataTablev2/DataTable')).MemoizedDataTablev2 }));

type HistoricalTransposeGroupedTableProps = {
  visual: HistoricalMonthlyTabConfig;
  reportLocale: string | undefined;
};

export const HistoricalTransposeGroupedTable: React.FC<HistoricalTransposeGroupedTableProps> = ({ visual, reportLocale }) => {
  const dateColumn = visual.columns[0];
  const valueColumn = visual.columns.filter(column => column.isDefault)[0];
  const groupColumns = visual.groupByColumns
    .slice(1) // remove the first group (date column)
    .filter(i => i.isDefault)
    .map(grpCol => grpCol.field.getElasticPath());
  const dataFrequency = visual.dataFrequency;
  const isOldTableVersion = visual.tableVersion === 'v1';
  const tableMonths = getTableMonths(reportLocale, visual.dataFrequency);

  const getBaseColumns = (): Column[] => {
    return [
      new Column({
        field: new Field('empty'),
        isDefault: true,
        formatting: new ColumnFormatting(),
        id: nanoid(6),
        valueCustomFormat: (value: Primitive) => {
          if (typeof value !== 'string') {
            return value;
          }
          const [year, month] = value.split('-');
          if (!month) {
            return value;
          }
          return getDateValue(month, year, dataFrequency, reportLocale);
        }
      })
    ];
  };

  const getColumnHeaders = (columns: Column[]) => {
    const shouldHaveColumnHeaders = groupColumns.length === 2;
    if (!shouldHaveColumnHeaders) {
      return undefined;
    }

    const tempHeaders: ColumnHeader[] = [];

    for (const col of columns) {
      const colHeader = tempHeaders.find(header => header.displayName === col.headerConfig.category);
      if (colHeader) {
        colHeader.colSpan++;
        continue;
      }

      tempHeaders.push({
        colSpan: 1,
        displayName: col.headerConfig.category as string
      });
    }

    return tempHeaders;
  };

  const getMonthEndData = (rows: (Row | RowGroup)[]): RowGroup[] => {
    rows.sort((a, b) => (b.data[dateColumn.fieldDataPath] as string).localeCompare(a.data[dateColumn.fieldDataPath] as string));

    const newData: RowGroup[] = [];

    for (const row of rows) {
      const [year, month, day] = (row.data[dateColumn.fieldDataPath] as string).split('-');

      // add one row for each year
      const yearData = newData.find(si => si.group === year);
      if (!yearData) {
        newData.push({
          __id__: nanoid(6),
          rows: [
            {
              ...row,
              group: month
            }
          ],
          group: year,
          cells: row.cells,
          data: row.data,
          level: 0
        });
        continue;
      }

      const currentMonthShouldBeDisplayed = tableMonths.some(tableMonth => tableMonth.key === month);
      if (!currentMonthShouldBeDisplayed) continue;

      const yearRows = yearData.rows as RowGroup[];
      const monthDataIndex = yearRows.findIndex(row => (row.group as string) === month);

      if (!yearRows[monthDataIndex]) {
        yearData.rows.push({
          ...row,
          group: month
        });
        continue;
      }

      // if current row is closer to the end of the month than the existing row, replace it
      const [, , monthDataDay] = (yearRows[monthDataIndex].data[dateColumn.fieldDataPath] as string).split('-');
      if (parseInt(monthDataDay, 10) < parseInt(day, 10)) {
        yearRows[monthDataIndex] = { ...(row as RowGroup), group: month };
      }
    }

    return newData;
  };

  const buildRowsAndColumns = (filteredRows: RowGroup[]): { rows: RowGroup[]; columns: Column[] } => {
    const baseColumns = getBaseColumns();
    const has2Groups = groupColumns.length === 2;

    const addColumnWith1Group = (row: Row, columns: Column[]) => {
      const colPath = (row.data[groupColumns[0]] as string | undefined) ?? UNCLASSIFIED;
      const columnExist = columns.some(col => col.field.name === colPath);
      if (!columnExist) {
        columns.push(
          new Column({
            ...valueColumn,
            id: nanoid(6),
            field: new Field(colPath),
            headerConfig: { displayName: colPath }
          } as ColumnConfig)
        );
      }
    };

    const addColumnWith2Groups = (row: Row, columns: Column[]) => {
      const colPath = (row.data[groupColumns[0]] ?? UNCLASSIFIED) + '-' + (row.data[groupColumns[1]] ?? UNCLASSIFIED);
      const columnExist = columns.some(col => col.field.name === colPath);
      if (!columnExist) {
        columns.push(
          new Column({
            ...valueColumn,
            id: nanoid(6),
            field: new Field(colPath),
            headerConfig: {
              category: row.data[groupColumns[0]] as string,
              displayName: row.data[groupColumns[1]] as string
            }
          } as ColumnConfig)
        );
      }
    };

    const updateMonthRow = (monthRow: RowGroup, year: string, columns: Column[]) => {
      monthRow.group = isOldTableVersion ? `${getMonthByKey(monthRow.group as string, reportLocale)} ${year}` : `${year}-${monthRow.group}`;
      monthRow.rows = monthRow.rows.map(row => ({ ...row }));
      monthRow.cells = [];
      const grp1Rows = monthRow.rows;

      columns.forEach(col => {
        let groupRowForThisColumn: Row | RowGroup | undefined;
        if (has2Groups) {
          groupRowForThisColumn = grp1Rows
            .flatMap(grp1Row => ('rows' in grp1Row ? grp1Row.rows : []))
            .find(row => {
              const group1Value = row.data[groupColumns[0]] ?? UNCLASSIFIED;
              const group2Value = row.data[groupColumns[1]] ?? UNCLASSIFIED;
              return group1Value + '-' + group2Value === col.fieldDataPath;
            });
        } else {
          groupRowForThisColumn = grp1Rows.find(row => (row.data[groupColumns[0]] ?? UNCLASSIFIED) === col.fieldDataPath);
        }

        if (groupRowForThisColumn) {
          monthRow.cells.push(groupRowForThisColumn.data[valueColumn.fieldDataPath]);
        } else {
          monthRow.cells.push('');
        }
      });

      monthRow.rows = []; // remove children rows to remove expand button in the table
    };

    const getNewColumnsFromData = (rows: RowGroup[]): Column[] => {
      const newColumns: Column[] = [];
      rows.forEach(yearRow => {
        for (const monthRow of yearRow.rows) {
          if (!('rows' in monthRow)) continue;

          monthRow.rows.forEach(group1Row => {
            if (has2Groups && 'rows' in group1Row) {
              group1Row.rows.forEach(group2Row => {
                addColumnWith2Groups(group2Row, newColumns);
              });
            } else {
              addColumnWith1Group(group1Row, newColumns);
            }
          });
        }
      });
      newColumns.sort((a, b) => a.fieldDataPath.localeCompare(b.fieldDataPath));
      return newColumns;
    };

    const finalColumns = [...baseColumns, ...getNewColumnsFromData(filteredRows)];

    const yearRows = filteredRows.map(row => ({ ...row }));
    for (const yearRow of yearRows) {
      yearRow.rows = yearRow.rows.map(row => ({ ...row }));
      yearRow.cells = [];
      const monthRows = yearRow.rows;

      for (const monthRow of monthRows) {
        if (!('rows' in monthRow)) {
          continue;
        }
        updateMonthRow(monthRow, yearRow.group as string, finalColumns);
      }
    }

    return { rows: yearRows, columns: finalColumns };
  };

  const polishRows = (rows: RowGroup[]): (RowGroup | Row)[] => {
    const showOnlyCurrentYear = visual.dateRange === ('currentYear' as keyof DateRange);
    if (showOnlyCurrentYear) {
      if (visual.transposedMonthOrder === SortOrder.ASC) {
        rows[0].rows.reverse();
      }
      return rows[0].rows;
    }

    const showLast12Months = visual.dateRange === ('lastTwelveMonths' as keyof DateRange);
    if (showLast12Months) {
      const newRows = rows.flatMap(row => {
        for (const monthRow of row.rows) {
          if (row.group && 'group' in monthRow) {
            monthRow.group = `${row.group} ${monthRow.group}`;
          }
        }
        return row.rows;
      });
      if (visual.transposedMonthOrder === SortOrder.ASC) {
        newRows.reverse();
      }
      return newRows;
    }

    if (visual.transposedMonthOrder === SortOrder.ASC) {
      rows.forEach(row => row.rows.reverse());
      rows.reverse();
    }
    return rows;
  };

  const filteredRows = getMonthEndData(visual.data.rows);
  const { rows, columns } = buildRowsAndColumns(filteredRows);
  const polishedRows = polishRows(rows);

  const columnHeaders = getColumnHeaders(columns);

  const newVisual = {
    ...visual,
    columns: columns,
    data: {
      rows: polishedRows
    },
    categoryHeadersEnabled: groupColumns.length === 2,
    displayCategories: groupColumns.length === 2,
    columnsHeaders: columnHeaders,
    sortable: false,
    collapsible: false,
    subTotal: true
  } as IDataTableProps['visual'];

  if (isOldTableVersion) {
    return <DataTable visual={newVisual} />;
  }

  return <MemoizedDataTablev2 visual={newVisual} />;
};
