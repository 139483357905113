import type { ChartOptions } from '@/modules/reporting-v2/core/components/Highcharts/types';
import highcharts from 'highcharts';

const axisToObj = (arg: ChartOptions['yAxis']) => {
  if (!arg) {
    return {};
  }

  if (Array.isArray(arg)) {
    return { ...arg };
  }

  return { '0': arg };
};

export const mergeAxis = (a: ChartOptions, b: ChartOptions, axis: 'yAxis' | 'xAxis') => {
  const aAxis = axisToObj(a[axis]);
  const bAxis = axisToObj(b[axis]);

  const newAxisObj = highcharts.merge(aAxis, bAxis);

  return Object.values(newAxisObj);
};
