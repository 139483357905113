import React from 'react';
import { fullAccountSelector, loggedUserSelector, userInformationSelector } from '@/common/auth/recoil/user.selector';
import { AuthHeader, AuthSessionStorageManager } from '@/common/auth/storage/AuthSessionStorageManager';
import { withSmallSuspense } from '@/common/suspense';
import { Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import { constSelector, useRecoilValue } from 'recoil';
import type { FullAccount } from '@/common/types/entity/Account';
import type { UserInformation } from '@/common/auth/api/getUserInfo';

const impersonatedUserInfoDivStyles: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  right: '50%',
  fontSize: 13,
  zIndex: 2000
};

type SupportFlowInfoMessageProps = {
  firstName: string | undefined;
  lastName: string | undefined;
  actingCompany: string | undefined;
  noPermissions: boolean;
};

const SupportFlowInfoMessage = React.memo<SupportFlowInfoMessageProps>(({ firstName, lastName, actingCompany, noPermissions }) => {
  const type = 'info';

  const showImpersonationBanner = firstName && lastName;

  const Message = (
    <div>
      <FormattedMessage
        id={noPermissions ? 'generic.popup.youHaveNoRoles' : showImpersonationBanner ? 'generic.popup.youAreImpersonating' : 'generic.popup.youAreActingIn'}
        values={{ firstName, lastName, actingCompany }}
      />
    </div>
  );
  return <Alert message={Message} type={type} banner style={impersonatedUserInfoDivStyles} />;
});

const SupportFlowInfo = withSmallSuspense(
  React.memo(() => {
    const loggedUser = useRecoilValue(loggedUserSelector);

    const impersonatedUser = useRecoilValue<UserInformation | null | undefined>(
      loggedUser.impersonating ? userInformationSelector(loggedUser.accountId) : constSelector(undefined)
    );

    const actingData = {
      accountId: AuthSessionStorageManager.getHeader(AuthHeader.ACT_ACCOUNT_ID)
    };

    const actingCompany = useRecoilValue<FullAccount | undefined>(actingData.accountId ? fullAccountSelector(actingData.accountId) : constSelector(undefined));

    if (!(impersonatedUser?.firstName || impersonatedUser?.lastName) && !actingCompany?.name) {
      return null;
    }

    return (
      <SupportFlowInfoMessage
        firstName={impersonatedUser?.firstName}
        lastName={impersonatedUser?.lastName}
        actingCompany={actingCompany?.name}
        noPermissions={loggedUser.permissions?.length <= 0}
      />
    );
  })
);

type SupportFlowWrapperProps = {
  children: React.ReactNode;
};

export const SupportFlowWrapper = React.memo<SupportFlowWrapperProps>(({ children }) => {
  return (
    <>
      <SupportFlowInfo />
      {children}
    </>
  );
});
