import { AxiosResponse } from 'axios';
import { ReportEntityType } from '@/common/types/entity/ReportEntityType';
import { SecurityUtils } from '@/utils/SecurityUtils';

export const reportEntityTypeApi: Record<
  ReportEntityType,
  {
    url: (id: string) => string;
    service: string;
    serializer: (response: AxiosResponse<any> | undefined) => {
      data?: { title: string };
      configuration?: string;
    };
  }
> = {
  [ReportEntityType.CMS]: {
    url: (id: string) => `internal/api/sitetree/entries/${id}`,
    serializer: (response: AxiosResponse<any> | undefined) => ({
      data: {
        title: response?.data.title
      },
      configuration: response?.data.webPage.configuration
    }),
    service: 'cms'
  },
  [ReportEntityType.ReportCentre]: {
    url: (id: string) => {
      if (isNaN(Number(id))) {
        return (SecurityUtils.isInternalApp() ? '/internal' : '') + `/api/report-templates/${id}`;
      }
      return `/api/custom-reports/${id}`;
    },
    serializer: (response: AxiosResponse<any> | undefined) => ({
      data: {
        title: response?.data.name
      },
      configuration: response?.data.configuration
    }),
    service: 'cms'
  },
  [ReportEntityType.Template]: {
    url: (id: string) => `/api/page-templates/${id}`,
    serializer: (response: AxiosResponse<any> | undefined) => ({
      data: {
        title: response?.data.name
      },
      configuration: response?.data.configuration
    }),
    service: 'cms'
  }
};
