import { nanoid } from 'nanoid';
import { FieldResolver } from '@/modules/reporting-v2/types/FieldResolver';
import { HeaderConfig } from '@/modules/reporting-v2/utils/HeaderConfig';
import { concatenateUniqueIndexFields } from '@/modules/reporting-v2/utils/IndexUtils';
import { ColumnFormatting, assetNameField } from './Column';
import { Field } from './Field';
import { Filter } from './Filter';
import { FilterGroup } from './FilterGroup';

export default class GroupByColumn implements FieldResolver {
  constructor(
    fieldDataPath: string,
    field: Field,
    isDefault: boolean,
    headerConfig?: HeaderConfig,
    selectBoxGroupById?: string,
    conditionalRowDisplay?: (Filter | FilterGroup)[],
    conditionalValueDisplay?: (Filter | FilterGroup)[],
    formatting?: ColumnFormatting
  ) {
    this.fieldDataPath = fieldDataPath;
    this.field = field;
    this.isDefault = isDefault;
    this.headerConfig = headerConfig || new HeaderConfig();
    this.selectBoxGroupById = selectBoxGroupById;
    this.conditionalRowDisplay = conditionalRowDisplay;
    this.conditionalValueDisplay = conditionalValueDisplay;
    this.assetNameColumn = field.getElasticPath() === assetNameField;
    this.formatting = formatting;
    this.id = nanoid(8);
  }

  formatting?: ColumnFormatting;
  fieldDataPath: string;
  field: Field;
  isDefault: boolean;
  headerConfig: HeaderConfig;
  id: string;
  selectBoxGroupById?: string;
  conditionalRowDisplay?: (Filter | FilterGroup)[];
  conditionalValueDisplay?: (Filter | FilterGroup)[];
  wasDefault?: boolean; // is used in selectbox to know which column has been replaced by a global group by, so we can set it back to default once that global group by is removed
  assetNameColumn?: boolean;

  getAllUsedFields(): Field[] {
    return concatenateUniqueIndexFields([this.field]);
  }
}
