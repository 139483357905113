import { requestApi } from '@/core';
import { ApiService } from 'ui-sesame-components';
import { AssetCompositionDto } from '../types';
import { AssetDtoV2 } from '../../PortfolioV2/types';

export const getAssetCompositionById = async (pathParams: { id: NonNullable<AssetDtoV2['id']> }): Promise<AssetCompositionDto> => {
  return requestApi({
    url: `/api/v2/assets/${pathParams.id}/composition`,
    service: ApiService.ASSET,
    method: 'GET'
  }).then(({ data }) => data);
};
