import React, { Suspense } from 'react';
import { Button } from 'antd';
import { EllipsisOutlined, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import { EditCellPopover } from '@/modules/reporting-v2/core/components/DataTablev2/components/cell/EditPopover';
import { ExcelUtils } from '@/utils/excel';
import cx from 'classnames';
import { useAssetLinkContext } from '@/modules/reporting-v2/core/components/DataTablev2/context/assetLinkContext';
import { useCategoryContext } from '@/modules/reporting-v2/core/components/DataTablev2/context/categoryContext';
import { useLegendChartColorContext } from '@/modules/reporting-v2/core/components/DataTablev2/context/legendChartColorContext';
import { useOwnershipDropdownContext } from '@/modules/reporting-v2/core/components/DataTablev2/context/ownershipDropdownContext';
import { useLevelIndicatorsContext } from '@/modules/reporting-v2/core/components/DataTablev2/context/levelIndicatorContext';
import { useDataBarsContext } from '@/modules/reporting-v2/core/components/DataTablev2/context/dataBarsContext';
import { colors } from '@/modules/reporting-v2/core/formatTypes';
import { LevelIndicators, getIsLastChildRow, getParentLastChildRow } from '@/modules/reporting-v2/core/components/DataTablev2/components/cell/LevelIndicator';
import { LimitsWrapper } from '@/modules/reporting-v2/core/components/DataTable/components/Limits/Limits';
import { DocumentsWrapper } from '@/modules/reporting-v2/core/components/DataTable/components/Documents';
import { forceCurrency } from '@/modules/reporting-v2/core/forceCurrency';
import format from '@/modules/reporting-v2/core/format';
import { type CellContext, type Cell, flexRender } from '@tanstack/react-table';
import type { Row, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import type { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { useExportContext } from '@/modules/AnalyticsReportViewer/contexts/ExportContext';

const Links = React.lazy(async () => await import('@/modules/Ownership/components/Links'));

type CellContentProps = {
  index: number;
} & CellContext<RowGroup | Row, Primitive>;

export const CellContent: React.FC<CellContentProps> = ({ row, getValue, column, index, table }) => {
  const { editMode, reportConfiguration } = React.useContext(ReportContext);
  const ownershipDropdown = useOwnershipDropdownContext();
  const levelIndicatorsEnabled = useLevelIndicatorsContext();
  let initialValue = getValue();
  const isFirstCell = index === 0;
  const visualColumn = column.columnDef.meta!.column;

  const onUpdateData = React.useCallback(
    (value: string | number, formattedValue: string | number, objectValue: JSX.Element) => {
      table.options.meta!.updateData(row.original.__id__, column.id, value, index, row.original.data, formattedValue, objectValue);
    },
    [column.id, index, row.original.__id__, row.original.data, table.options.meta]
  );

  const rowCanExpand = row.getCanExpand();
  const displayExpandButton = isFirstCell && rowCanExpand;
  const displayDataBars = visualColumn.dataBars;
  const displayLegendChartColors = isFirstCell && row.depth === 0;
  const displayEditPopover = !!editMode;
  const displayLevelIndicators = isFirstCell && levelIndicatorsEnabled && row.depth > 0;
  const displayLimitBreach = visualColumn.limitsPopup;
  const displayDocuments = visualColumn.documentsPopup;
  const displayHyperlinks = visualColumn.hyperlinksPopup;
  const displayOwnershipDropdown = !displayHyperlinks && isFirstCell && ownershipDropdown;

  if (visualColumn.valueCustomFormat) {
    initialValue = visualColumn.valueCustomFormat(initialValue, row.original);
  }

  const forcedCurrency = rowCanExpand ? forceCurrency(visualColumn) : undefined;
  const cellValue = visualColumn.formatter
    ? visualColumn.formatter(initialValue, row.original.data)
    : format(visualColumn, initialValue, row.original.data, undefined, false, forcedCurrency, reportConfiguration?.config.numberLocale);

  if (displayLimitBreach) {
    return <LimitsWrapper data={row.original.data} />;
  }

  if (displayDocuments) {
    return <DocumentsWrapper data={row.original.data} />;
  }

  return (
    <div>
      {displayExpandButton && <ExpandRowButton row={row} />}
      {displayLevelIndicators && <LevelIndicators isLastChildRow={getIsLastChildRow(row, table)} parentRowLastChildRow={getParentLastChildRow(row, table)} level={row.depth} />}
      {displayDataBars && <DataBars value={initialValue as number} fieldDataPath={visualColumn.fieldDataPath} />}
      {displayLegendChartColors && <LegendChartColor rowIndex={row.index} value={initialValue} />}
      {cellValue}
      {displayOwnershipDropdown && (
        <Suspense>
          <Links
            data={{
              holdingSetId: row.original.data['holdingset.holdingSetId'],
              date: row.original.data['holdingset.verifiedDate'],
              type: 'vehicle'
            }}
          >
            <Button type="link" size="small" icon={<EllipsisOutlined />} className="no-print" />
          </Links>
        </Suspense>
      )}
      {displayEditPopover && (
        <EditCellPopover
          level={row.depth}
          formattingType={visualColumn.formatting?.type}
          columnIndex={index}
          initialValue={initialValue}
          onUpdateData={onUpdateData}
          isGroupRow={rowCanExpand}
          column={visualColumn}
        />
      )}
    </div>
  );
};

interface DataBarsProps {
  fieldDataPath: string;
  value: number;
}

const DataBars: React.FC<DataBarsProps> = ({ value, fieldDataPath }) => {
  const maxValues = useDataBarsContext();
  const maxValue = maxValues?.[fieldDataPath];

  const dataBarStyle = React.useMemo<React.CSSProperties>(() => {
    if (!maxValue) {
      return {};
    }

    const valueIsPositive = value > 0;
    const color = valueIsPositive ? colors.green : colors.red;

    return {
      width: Math.min(50, (Math.abs(value) * 100) / maxValue / 2) + '%',
      left: valueIsPositive ? '50%' : 'auto',
      right: !valueIsPositive ? '50%' : 'auto',
      background: `linear-gradient(${valueIsPositive ? '-' : ''}90deg, ${color}30 0%, ${color} 100%)`,
      [`border${valueIsPositive ? 'Left' : 'Right'}`]: '1px dashed rgba(0,0,0,0.65)'
    };
  }, [maxValue, value]);

  return <span className="cell-data-bar" style={dataBarStyle} />;
};
export default DataBars;

type LegendChartColorProps = {
  rowIndex: number;
  value: Primitive;
};

const LegendChartColor: React.FC<LegendChartColorProps> = ({ rowIndex, value }) => {
  const { getLegendChartColor, useLegendChartColor } = useLegendChartColorContext();

  if (!useLegendChartColor) {
    return null;
  }

  const colors = getLegendChartColor(value, rowIndex);

  return <span className="legend-circle-color" style={{ background: colors.color }} data-print-color={colors.printColor} />;
};

type ExpandRowButtonProps = {
  row: CellContext<RowGroup | Row, Primitive>['row'];
};

const ExpandRowButton: React.FC<ExpandRowButtonProps> = ({ row }) => {
  const onClick: React.MouseEventHandler<HTMLElement> = e => {
    e.stopPropagation();
    row.toggleExpanded();
  };

  return <Button className="no-print row-expand" type="text" icon={<ExpandRowIcon expanded={row.getIsExpanded()} />} onClick={onClick} />;
};

const ExpandRowIcon: React.FC<{ expanded: boolean }> = ({ expanded }) => {
  if (expanded) {
    return <MinusSquareOutlined />;
  }

  return <PlusSquareOutlined />;
};

type CellWrapperProps = {
  cell: Cell<RowGroup | Row, Primitive>;
  index: number;
};

export const CellWrapper: React.FC<CellWrapperProps> = ({ cell, index }) => {
  const getCategoryClass = useCategoryContext();
  const { hasAssetLink, openAssetLink } = useAssetLinkContext();
  const { exporting } = React.useContext(ReportContext);
  const { isExporting: newExporting } = useExportContext();
  const meta = cell.column.columnDef.meta;
  const visualColumn = meta!.column;
  const rowData = cell.row.original;

  let excelAttrs = {};
  if (exporting === 'excel' || newExporting === 'excel') {
    excelAttrs = ExcelUtils.getTDAttributes(visualColumn, rowData.data, cell.getValue());
  }

  const isRowGroup = 'rows' in rowData;
  const useSingleAssetPage = hasAssetLink && visualColumn.assetNameColumn && !isRowGroup;

  return (
    <td
      onClick={e => {
        if (!useSingleAssetPage) {
          return;
        }
        e.stopPropagation();
        openAssetLink(rowData.data);
      }}
      className={
        cx(getCategoryClass(index), {
          'asset-view-link': useSingleAssetPage
        }) || undefined
      }
      {...excelAttrs}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};
