import { ReportMediaClassName, a4PaperSize } from '@/modules/reporting-v2/hooks/useExport';
import { getTheme } from '@/modules/reporting-v2/utils/theme';
import { REPORT_BUILDER_CANVAS_SIZE } from '@/modules/report-builder/hooks/useReportBuilder';

const defaultMargins = { top: 48, bottom: 48 };

export default (content: string): string => {
  return `<div class="report-container ${ReportMediaClassName.print} report-theme-${getTheme()}" style="width:${a4PaperSize.landscape}px;">
  <div class="report-page active">
    <table class="report-wrapper-table">
      <tbody>
        <tr>
          <td style="width:100%;height:${Math.floor(a4PaperSize.landscape - 32 * 2) * REPORT_BUILDER_CANVAS_SIZE.landscape.ratio - defaultMargins.top - defaultMargins.bottom}px;">
            <div style="padding:16px 32px;">
              <div>
                ${content}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>`;
};
