import { UUID } from '@/common/types/types';
import requestImpersonationTermination from '@/common/auth/api/terminateImpersonation';
import requestActingTermination from '@/common/auth/api/terminateAct';
import { AuthCookieStorageManager } from './AuthCookieStorageManager';

export enum AuthHeader {
  USER_LOGIN = 'x-sesame-user-login',
  ACCOUNT_ID = 'x-sesame-account-id',
  SPACE_ID = 'x-sesame-space-id',
  IMPERSONATED_SPACE_ID = 'x-sesame-imp-space-id',
  IMPERSONATED_USER_ID = 'x-sesame-imp-user-id',
  ACT_ACCOUNT_ID = 'x-sesame-act-account-id',
  ACT_SPACE_ID = 'x-sesame-act-space-id',
  TOKEN = 'sesameToken'
}

enum RedirectURLParameters {
  ORIGIN_URL = 'originUrl'
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AuthSessionStorageManager {
  export const size = () => {
    return sessionStorage.length;
  };
  export const removeHeader = (header: AuthHeader) => {
    return sessionStorage.removeItem(header);
  };

  export const hasHeader = (header: AuthHeader) => {
    return AuthSessionStorageManager.getHeader(header) !== null;
  };

  export const getHeader = (header: AuthHeader) => {
    return sessionStorage.getItem(header);
  };

  export const setHeader = (header: AuthHeader, value: UUID) => {
    return sessionStorage.setItem(header, value);
  };

  export const clearHeaderStorage = () => {
    AuthCookieStorageManager.clear();
    return Object.values(AuthHeader).forEach(header => {
      sessionStorage.removeItem(header);
    });
  };

  export const clearAuthUrlSearchParams = () => {
    const search = new URLSearchParams(window.location.search);

    search.delete(RedirectURLParameters.ORIGIN_URL);

    Object.values(AuthHeader).forEach(key => {
      search.delete(key);
    });

    const newSearch = search.toString();

    window.history.replaceState(null, String(), `${window.location.pathname}${newSearch ? '?' + newSearch : ''}`);
  };

  export const storeOriginUrl = (originUrl: string) => {
    return sessionStorage.setItem(RedirectURLParameters.ORIGIN_URL, originUrl);
  };

  export const getOriginUrl = (): string => {
    return sessionStorage.getItem(RedirectURLParameters.ORIGIN_URL)!;
  };

  export const removeOriginUrl = () => {
    sessionStorage.removeItem(RedirectURLParameters.ORIGIN_URL);
  };

  export const redirectToOriginUrl = () => {
    const originUrl = AuthSessionStorageManager.getOriginUrl();

    AuthSessionStorageManager.removeOriginUrl();

    window.location.href = originUrl;
  };

  export const retrieveCurrentHeaders = () => {
    return Object.values(AuthHeader)
      .filter(key => key !== AuthHeader.USER_LOGIN)
      .reduce<Partial<Record<AuthHeader, UUID>>>((acc, header) => {
        const value = AuthSessionStorageManager.getHeader(header);

        if (value) {
          Reflect.set(acc, header, value);
        }

        return acc;
      }, {});
  };

  export const switchSpace = (newSpaceId: UUID, acting: boolean, impersonating: boolean, accountId?: UUID) => {
    if (acting) {
      AuthSessionStorageManager.setHeader(AuthHeader.ACT_SPACE_ID, newSpaceId);
    } else {
      AuthSessionStorageManager.setHeader(AuthHeader.SPACE_ID, newSpaceId);

      if (!impersonating && accountId) {
        const spaceId = AuthSessionStorageManager.getHeader(AuthHeader.SPACE_ID);

        AuthCookieStorageManager.set(AuthSessionStorageManager.getHeader(AuthHeader.USER_LOGIN)!, {
          [AuthHeader.ACCOUNT_ID]: accountId,
          [AuthHeader.SPACE_ID]: spaceId!
        });
      }
    }

    // We do not do a simple window.location.reload to avoid frequent 404s
    // because switching space may mean that the page the user is
    // looking at in the current space is not available in the new space
    window.location.href = window.location.origin;
  };

  export const switchAccount = async (newAccountId: UUID, acting: boolean, impersonating: boolean) => {
    AuthSessionStorageManager.setHeader(acting ? AuthHeader.ACT_ACCOUNT_ID : AuthHeader.ACCOUNT_ID, newAccountId);

    if (!acting && !impersonating) {
      const accountId = AuthSessionStorageManager.getHeader(AuthHeader.ACCOUNT_ID);
      AuthCookieStorageManager.set(AuthSessionStorageManager.getHeader(AuthHeader.USER_LOGIN)!, {
        [AuthHeader.ACCOUNT_ID]: accountId!,
        [AuthHeader.SPACE_ID]: undefined
      });
    }

    AuthSessionStorageManager.removeHeader(AuthHeader.SPACE_ID);

    // We do not do a simple window.location.reload to avoid frequent 404s
    // because switching account may mean that the page the user is
    // looking at in the current account is not available in the new account
    window.location.href = window.location.origin;
  };

  export const terminateImpersonation = async () => {
    AuthSessionStorageManager.removeHeader(AuthHeader.IMPERSONATED_SPACE_ID);
    AuthSessionStorageManager.removeHeader(AuthHeader.IMPERSONATED_USER_ID);

    await requestImpersonationTermination();

    AuthSessionStorageManager.redirectToOriginUrl();
  };

  export const isActing = () => {
    return AuthSessionStorageManager.hasHeader(AuthHeader.ACT_ACCOUNT_ID);
  };

  export const terminateAct = async () => {
    AuthSessionStorageManager.removeHeader(AuthHeader.ACT_ACCOUNT_ID);
    AuthSessionStorageManager.removeHeader(AuthHeader.ACT_SPACE_ID);
    AuthSessionStorageManager.removeHeader(AuthHeader.IMPERSONATED_SPACE_ID);
    AuthSessionStorageManager.removeHeader(AuthHeader.IMPERSONATED_USER_ID);

    await requestActingTermination();

    AuthSessionStorageManager.redirectToOriginUrl();
  };

  export const buildUrlSearchParams = () => {
    const params = new URLSearchParams();

    let headers = Object.entries(AuthSessionStorageManager.retrieveCurrentHeaders());

    const originUrl = AuthSessionStorageManager.getOriginUrl();

    if (originUrl) {
      headers = headers.concat([[RedirectURLParameters.ORIGIN_URL, originUrl]]);
    }

    headers.forEach(([key, value]) => {
      if (value) {
        params.set(key, String(value));
      }
    });

    return params;
  };

  export const readSessionInitiation = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const originUrl = urlParams.get(RedirectURLParameters.ORIGIN_URL);

    if (originUrl) {
      AuthSessionStorageManager.storeOriginUrl(originUrl);
    }

    Object.values(AuthHeader).forEach(header => {
      const value = urlParams.get(header);
      if (value) {
        if (value === 'undefined') {
          AuthSessionStorageManager.removeHeader(header);
        } else {
          AuthSessionStorageManager.setHeader(header, value);
        }
      }
    });

    AuthSessionStorageManager.clearAuthUrlSearchParams();
  };

  export const readPersisted = (email?: string) => {
    const lastUserEmail = AuthCookieStorageManager.getUserInfo();

    if (!lastUserEmail || (email && lastUserEmail === email)) {
      const key = email ?? lastUserEmail;
      const cookies = key && AuthCookieStorageManager.get(key);
      if (cookies) {
        const accountId = cookies[AuthHeader.ACCOUNT_ID];
        if (accountId) {
          AuthSessionStorageManager.setHeader(AuthHeader.ACCOUNT_ID, accountId);
        }

        const spaceId = cookies[AuthHeader.SPACE_ID];
        if (spaceId) {
          AuthSessionStorageManager.setHeader(AuthHeader.SPACE_ID, spaceId);
        }
      }
    }
  };
  export const persist = AuthCookieStorageManager.set;
  export const persistUser = AuthCookieStorageManager.setUserInfo;
}
