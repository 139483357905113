import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { SetterOrUpdater } from 'recoil';
import { Dayjs } from 'dayjs';
import { Institution } from '@/modules/ERP/Common/types';
import { CustodiedType } from '@/common/types/entity/CustodiedType';

enum AssetFlow {
  REAL_ESTATE = 'RealEstate',
  FINANCIAL_ASSET = 'Financial',
  COLLECTIBLES = 'Collectible',
  CASH = 'CashAccount',
  LEGAL_ENTITY = 'LegalEntity'
}

enum TypeEntityAPIResponse {
  HOLDING_COMPANY = 'HOLDING_COMPANY',
  TRUST = 'TRUST'
}

enum AssetStep {
  PROPERTY_INFORMATION,
  COLLECTIBLE_INFORMATION,
  HOLDING_COMPANY_OWNED,
  TRUST_OWNED,
  LEGAL_OWNER,
  CASH_ACCOUNT_INFORMATION,
  YOU,
  VALUATION_DETAILS,
  ADD_TRANSACTIONS,
  LEGAL_ENTITY_INFORMATION,
  LEGAL_OWNER_ALL_FORMS,
  FINANCIAL_INFORMATION,
  CHOOSE_FINANCIAL_DETAILS
}

enum AssetOwnership {
  YOU = '/you',
  EXISTING = '/existing',
  NEW_PARTNERSHIP = '/create-new-partnership',
  HOLDING_COMPANY = 'holding',
  TRUST = 'trust'
}

enum TransactionType {
  LINK_ACCOUNT = '/link-account',
  ENTER_MANUALLY = '/enter-manually',
  ENTER_TOTAL = '/enter-total',
  ENTER_INDIVIDUAL = '/enter-individual'
}

export enum NavigationDirection {
  BACK,
  NEXT
}

export enum VehicleType {
  HOLDING_COMPANY_ENTITY = 'HoldingCompanyEntity',
  EXISTING_LEGAL_ENTITY = 'ExistingLegalEntity',
  EXISTING_LEGAL_ENTITY_WITH_OWNERSHIP = 'ExistingLegalEntityWithOwnership',
  HELD_JOINTLY_PERSONAL_LEGAL_ENTITY = 'HeldJointlyPersonalLegalEntity',
  SINGLE_PERSONAL_ENTITY = 'SinglePersonalLegalEntity',
  TRUST_ENTITY = 'TrustEntity'
}

export interface VehicleInterface {
  type: VehicleType;
  id?: number;
  name?: string;
}

type FormValues = Record<
  string,
  | Primitive
  | Array<FormValues>
  | Dayjs
  | VehicleInterface
  | Owner[]
  | symbol
  | Date
  | {
      [key: string]: any;
    }
>;
type FormState = Record<number, FormValues>;

type StepFormProps = {
  flow: AssetFlow;
  formValues: FormValues;
  setFormValues: SetterOrUpdater<FormValues>;
};

export interface LegalEntityType {
  id: number;
  type: VehicleType;
  name?: string;
  indexForm?: string;
}

export interface Owner {
  legalEntity: LegalEntityType;
  ownership: number;
}

export interface EntityList {
  custodianId?: number;
  portfolioId?: number;
  custodiedType?: CustodiedType;
  entityType: EntityType;
  reportingCurrency: string;
  publicAsset: boolean;
  lookThroughEnabled: boolean;
  clientCompanies: number[];
  portfolioVehicleHoldingSetId?: number;
  holdingSetId: number;
  id?: number;
  vehicleId: number;
  name: string;
  custodian?: Institution;
}

// to remove when merging into one project with ui-sesame-admin-new
export enum EntityType {
  FUND = 'FUND',
  HOLDING = 'HOLDING_COMPANY',
  TRUST = 'TRUST',
  PORTFOLIO = 'PORTFOLIO',
  VEHICLE = 'VEHICLE',
  BENCHMARK = 'BENCHMARK',
  GROUPING = 'GROUPING',
  PERSONAL = 'PERSONAL',
  CONTACT = 'CONTACT'
}

// to remove when merging into one project with ui-sesame-admin-new
export enum Show {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED'
}

// to remove when merging into one project with ui-sesame-admin-new
export type EntitySearch = {
  entityType?: EntityType | string;
  name?: string;
  reportingCurrency?: string;
  publicAsset?: boolean;
  lookThroughEnabled?: boolean;
  custodianId?: number;
  clientCompanyId?: number;
  ids?: string | Array<string>;
  portfolioIds?: string | Array<string>;
  createdBy?: string;
  lastModifiedBy?: string;
  show?: Show;
  custodiedTypes?: CustodiedType[];
  size?: number;
} & Record<string, any>;

// to remove when merging with admin
export interface SelectOption {
  id: string;
  name: string;
  priority: number;
  priorityOrder: number;
  value: string;
}

// to remove when merging with admin
export enum OptionPriority {
  MOST_USED,
  OTHERS
}

// to remove when merging with admin
export type AssetNameType = {
  id: number;
  missingAssetId?: number;
  name: string;
  type: string;
  currency: string;
  riskCountry: string;
  private?: boolean;
  listed?: boolean;
};

export type AssetType = {
  name: string;
  category: string;
  pmsEnabled: boolean;
};

export enum AssetTypeCategory {
  Cash = 'Cash',
  Collectibles = 'Collectibles',
  Financial = 'Financial'
}

export interface EntityTree {
  id: string;
  type: AssetFlow;
  name: string;
  hidden: boolean;
  children: EntityTree[];
}

export type { FormState, StepFormProps, FormValues };
export { AssetFlow, AssetStep, AssetOwnership, TransactionType, TypeEntityAPIResponse };
