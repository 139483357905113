import React, { FC, ReactElement } from 'react';
import { Tooltip } from 'antd';
import formatTypes from '@/modules/reporting-v2/core/formatTypes';
import { ContextField } from '@/common/components/ContextField';
import { AutosaveIndicator } from '@/common/form/AutosaveIndicator';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Task, TaskType } from '@/common/types/entity/Task';
import { HoldingSetLabel } from '@/common/entity';
import { useIntl } from 'react-intl';
import { EnumUtils } from '@/utils/EnumUtils';
import { useRecoilValue } from 'recoil';
import { holdingSetSelector } from '@/recoil/holdingSets';
import { DATE_FORMAT } from 'ui-sesame-components';

dayjs.extend(relativeTime);

export type ContextMode = 'basic' | 'full';

export interface TaskContextGridProps {
  task: Task;
  pending?: boolean;
  saving?: boolean;
  mode: ContextMode;
  showEntity?: boolean;
}

export const TaskContextGrid: FC<TaskContextGridProps> = ({ task, pending = false, saving = false, mode = 'full', showEntity = false }) => {
  const translator = useIntl();
  const holdingSet = useRecoilValue(holdingSetSelector(task.holdingSetId));

  return (
    <div className="task-context">
      {mode === 'full' && (
        <ContextField label="Last Modified">
          <AutosaveIndicator pending={pending} saving={saving} />
          <Tooltip
            title={
              <>
                {translator.formatMessage({ id: 'generic.by' })} {task.lastModifiedBy}
                <br />
                {translator.formatMessage({ id: 'generic.on' })} {dayjs(task.lastModifiedDate).format(DATE_FORMAT.DATETIME)}
              </>
            }
          >
            {dayjs(task.lastModifiedDate).fromNow()}
          </Tooltip>
        </ContextField>
      )}
      {mode === 'full' && (
        <ContextField label="Created">
          <Tooltip title={dayjs(task.createdDate).format(DATE_FORMAT.DATETIME)}>{dayjs(task.createdDate).fromNow()}</Tooltip>
        </ContextField>
      )}
      {mode === 'full' && <ContextField label="Type">{EnumUtils.toHumanReadable(task.type)}</ContextField>}
      {mode === 'full' && showEntity && (
        <ContextField label="Entity">
          <HoldingSetLabel holdingSet={holdingSet} />
        </ContextField>
      )}
      {mode === 'full' && (task.refUrl || task.refName) && (
        <ContextField label={ReferenceLabels[task.type]}>{task.refUrl ? <a href={task.refUrl}>{task.refName || 'Link'}</a> : <>{task.refName}</>}</ContextField>
      )}
      {mode === 'full' && task.context?.limitDetail && (
        <ContextField label="Limit Detail">
          {
            <>
              {!!task.context.limitDetail.category && (
                <>
                  {translator.formatMessage({ id: 'generic.category' })}: {task.context.limitDetail.category}
                </>
              )}
              {!!task.context.limitDetail.rule && (
                <>
                  <br />
                  {translator.formatMessage({ id: 'generic.rule' })}: {task.context.limitDetail.rule}
                </>
              )}
              {!!task.context.limitDetail.limitCurrentValue && (
                <>
                  <br />
                  {translator.formatMessage({ id: 'generic.currentValue' })}: {task.context.limitDetail.limitCurrentValue}
                </>
              )}
              {!!task.context.limitDetail.valueStatus && (
                <>
                  <br />
                  {translator.formatMessage({ id: 'generic.status' })}
                  <ContextLabel
                    label={formatTypes.limits(task.context.limitDetail.valueStatus, {}, {} as any, {} as any) as ReactElement}
                    tooltip={task.context.limitDetail.valueStatus}
                  ></ContextLabel>
                </>
              )}
            </>
          }
        </ContextField>
      )}
      {mode === 'full' && task.context?.globalFilters && (
        <ContextField label={translator.formatMessage({ id: 'report.builder.modal.globalFilters' })}>
          {task.context.globalFilters.date && (
            <>
              {translator.formatMessage({ id: 'generic.date' })}: {dayjs(task.context.globalFilters.date).format(DATE_FORMAT.DATE)}
            </>
          )}
          <br />
          {task.context.globalFilters.consolidationType && (
            <>
              {translator.formatMessage({ id: 'generic.consolidationType' })}: {task.context.globalFilters.consolidationType}
            </>
          )}
        </ContextField>
      )}
      {mode === 'full' && task.context?.reportId && (
        <ContextField label={translator.formatMessage({ id: 'generic.visual' })}>
          <ContextLabel label={task.context?.reportName} tooltip={task.context?.reportId} />
        </ContextField>
      )}
      {mode === 'full' && task.context?.visualId && (
        <ContextField label={translator.formatMessage({ id: 'generic.report' })}>
          <ContextLabel label={task.context?.visualName} tooltip={task.context?.visualId} />
        </ContextField>
      )}
      {task.context?.value && (
        <ContextField label={translator.formatMessage({ id: 'tms.details.capturedValue' })} boldenLabel={true}>
          <ContextLabel label={task.context?.value} />
          <br />
          {task.context?.valueLocator?.row && (
            <>
              {translator.formatMessage({ id: 'generic.row' })}: <ContextLabel label={task.context?.valueLocator.row.toString()} />
            </>
          )}
          <br />
          {task.context?.valueLocator?.column && (
            <>
              {translator.formatMessage({ id: 'generic.column' })}: <ContextLabel label={task.context?.valueLocator.column.toString()} />
            </>
          )}
        </ContextField>
      )}
    </div>
  );
};

export function ContextLabel({ label, tooltip }: { label?: string | ReactElement; tooltip?: string | number }) {
  return (
    <Tooltip title={tooltip || label} placement="topLeft">
      {label || tooltip}
    </Tooltip>
  );
}

export const ReferenceLabels: { [key in keyof typeof TaskType]: string } = {
  GENERAL: 'Page',
  DOCUMENT: 'Document',
  LIMIT: 'Limit'
};
