import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { builderMode } from '@/modules/report-builder/recoil/atoms';
import { VisualVersioning } from '@/utils/reportBuilder';
import patchReportPageTemplate from '@/modules/report-builder/api/patchReportPageTemplate';
import { BuilderMode } from '@/modules/report-builder/types';
import queryString from 'query-string';
import createReportPageTemplate from '@/modules/report-builder/api/createReportPageTemplate';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { RawReportConfig } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { REPORT_BUILDER_CONFIG_ADAPTER_VERSION } from '@/modules/report-builder/utils/adapter';
import { reportEntityTypeApi } from '@/api/reportEntityTypeApi';
import requestApi from '@/core/requestApi';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ReportEntityType } from '@/common/types/entity/ReportEntityType';
import { useIntl } from 'react-intl';
import { SecurityUtils } from '@/utils/SecurityUtils';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import { CustomizeTemplateRequest, customizeTemplate } from '@/modules/ReportCentre/api/customizeTemplate';
import { ContentType } from '@/common/types/entity/ContentType';

interface SaveReportConfigParams {
  id?: string;
  entityType?: ReportEntityType;
  configuration: RawReportConfig;
  validate?: () => boolean;
  setFocusOnTitle?: () => void;
  afterSave?: (configuration: RawReportConfig) => void;
}

const useSaveReportConfig = ({ id, entityType, configuration, validate, setFocusOnTitle, afterSave }: SaveReportConfigParams) => {
  const currentUser = useRecoilValue(currentUserSelector);
  const history = useHistory();
  const location = useLocation();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const initialConfiguration = React.useRef<RawReportConfig | null>(null);
  const mode = useRecoilValue(builderMode);
  const translator = useIntl();

  const toString = (configuration: RawReportConfig): string => {
    return JSON.stringify({
      ...configuration,
      version: REPORT_BUILDER_CONFIG_ADAPTER_VERSION
    });
  };

  const isCustomizingCustomReportTemplate = React.useMemo(() => location.search.includes('action=customize'), [location.search]);

  /**
   * Handle Save
   * This handler is used for the Report Builder and for the Preview page
   * */
  const handleSave = React.useCallback(() => {
    if (isSaving || (validate && !validate())) {
      return;
    }

    let request: Promise<unknown> | undefined;
    const isCustomReport = entityType === ReportEntityType.ReportCentre;
    const json = VisualVersioning.computeVisualVersioning(initialConfiguration.current!, configuration);
    const jsonString = toString(json);

    setIsSaving(true);

    if (id && entityType && entityType in reportEntityTypeApi) {
      const { url } = reportEntityTypeApi[entityType];
      switch (entityType) {
        case ReportEntityType.Template: {
          request = patchReportPageTemplate(Number(id), {
            name: configuration.title!,
            configuration: jsonString
          });
          break;
        }
        case ReportEntityType.ReportCentre:
        case ReportEntityType.CMS: {
          if (isCustomizingCustomReportTemplate) {
            request = customizeTemplate(String(id), new CustomizeTemplateRequest(configuration.title!, ContentType.JSON, jsonString, currentUser.spaceId)).then(report => {
              history.push(`/builder/edit/crm/${report.id}`);
            });
          } else {
            request = requestApi({
              method: 'patch',
              url: url(id),
              service: 'cms',
              data: {
                configuration: jsonString,
                ...(isCustomReport && { name: configuration.title })
              }
            });
          }
          break;
        }
        default:
      }
    } else {
      if (mode === BuilderMode.TEMPLATE) {
        const { accountId, templateType } = queryString.parse(location.search.substring(1));

        if (accountId && templateType) {
          request = createReportPageTemplate({
            name: configuration.title!,
            configuration: jsonString,
            account: { id: accountId as string },
            type: templateType as string
          }).then(reportPageTemplate => {
            history.push(SecurityUtils.getUrlWithInternal(`/builder/edit/template/${reportPageTemplate!.id}?mode=template`));
          });
        }
      }
    }

    if (!request) {
      return;
    }

    request
      .then(() => {
        message.success(translator.formatMessage({ id: 'generic.successfullySaved' }));
        initialConfiguration.current = json;
        if (afterSave) {
          afterSave(configuration);
        }
      })
      .catch(err => {
        if (entityType === 'crm' && (err as AxiosError).response?.status === 409) {
          message.error(
            translator.formatMessage({
              id: 'report.builder.aCustomReportAlreadyExistsWithThisName'
            })
          );
          if (setFocusOnTitle) {
            setFocusOnTitle();
          }
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [
    isSaving,
    validate,
    entityType,
    configuration,
    id,
    isCustomizingCustomReportTemplate,
    currentUser.spaceId,
    mode,
    location.search,
    history,
    translator,
    afterSave,
    setFocusOnTitle
  ]);

  return {
    isSaving,
    handleSave
  };
};

export default useSaveReportConfig;
