import React, { useState } from 'react';
import { Card, Col, Layout, Row, Typography } from 'antd';
import sesameProImg from '@/modules/Onboarding/Shared/images/sesame-pro.png';
import signupImg from '@/modules/Onboarding/Shared/images/signup.png';
import '@/modules/Onboarding/Pages/SignUp/style.css';
import { FormattedMessage } from 'react-intl';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';

import { SignUpForm } from '@/modules/Onboarding/Pages/SignUp/SignUpForm';
import { AccountCreated } from '@/modules/Onboarding/Pages/SignUp/AccountCreated';
import { Spinner } from '@/modules/reporting-v2/core/components/loaders';

const SignUp: React.FunctionComponent = React.memo(() => {
  const [formValues, setFormValues] = useState<Record<string, Primitive>>({});
  const [accountCreated, setAccountCreated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Layout.Content>
      <Row gutter={[15, 30]} wrap>
        <Col xs={24} md={12} xl={7} xxl={6} style={{ height: '98vh' }}>
          <Card className={'signup-card'}>
            <img src={sesameProImg} alt={'sesame pro'} className={'signup-image-title'} />
            <div className={'signup-card-box'}>
              <Typography.Title level={1} className={'signup-title'}>
                <FormattedMessage id={'onboarding.your'} />{' '}
                <span style={{ color: '#F9D332' }}>
                  <FormattedMessage id={'onboarding.wealth'} />.
                </span>
                <br />
                <FormattedMessage id={'onboarding.theFullPicture'} />
              </Typography.Title>
              <Typography.Text className={'signup-text'}>
                <FormattedMessage id={'onboarding.sesameProSignupText'} values={{ linebreak: <br /> }} />
              </Typography.Text>
              <img src={signupImg} alt={'sesame pro'} className={'signup-image'} />
            </div>
          </Card>
        </Col>
        <Col span={1} />
        <Col
          xs={18}
          md={10}
          xl={10}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          {!isLoading && !accountCreated && <SignUpForm setIsLoading={setIsLoading} setFormValues={setFormValues} formValues={formValues} setAccountCreated={setAccountCreated} />}
          {!isLoading && accountCreated && <AccountCreated email={formValues.email} />}
          {isLoading && <Spinner className={''} />}
        </Col>
      </Row>
    </Layout.Content>
  );
});

export { SignUp };
