import React, { ReactElement } from 'react';
import { Field } from '@/modules/reporting-v2/core/Field';
import { DataTableVisualConfig } from '@/modules/reporting-v2/types/Configs';
import { FieldName } from '@/modules/reporting-v2/types/Field';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { Row, RowGroup as RowGroupType } from '@/modules/reporting-v2/types/VisualEngine';
import { DataRow } from './DataRow';
import { RowGroup } from './RowGroup';
import { VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';

interface IProps {
  childrenRows?: Array<Row | RowGroupType>;
  maxRangeValues?: Record<FieldName, number>;
  visual: DataTableVisualConfig;
  level: number;
  expand: boolean;
  rowStyle: {
    display: string;
  };
  handleColumnSelect: (field: Field, value: Primitive) => void;
  onRowSelect?: (holdingSetId: number, userId: string, data: FlattenObject) => void;
  selectedHoldingset?: number;
  overrideExpansion?: boolean;
  groupTrace?: string;
  parentRowLastChildRow?: number[];
}

const DeepRows: React.FC<IProps> = React.memo(
  ({
    childrenRows,
    maxRangeValues,
    visual,
    level,
    expand,
    rowStyle,
    handleColumnSelect,
    onRowSelect,
    selectedHoldingset,
    overrideExpansion,
    groupTrace = '',
    parentRowLastChildRow = []
  }) => {
    if (!childrenRows || !childrenRows.length) return null;

    return childrenRows.map((row, rowIndex) => {
      if ('group' in row) {
        const isLastChildRow = rowIndex === childrenRows.length - 1;
        return (
          <RowGroup
            key={row.__id__}
            rowIndex={rowIndex}
            selectedHoldingset={selectedHoldingset}
            level={row.level}
            visual={visual}
            maxRangeValues={maxRangeValues}
            groupRowsMaxRangeValues={row.maxRangeValues}
            active={expand}
            overrideExpansion={overrideExpansion}
            handleColumnSelect={handleColumnSelect}
            onRowSelect={onRowSelect}
            groupTrace={groupTrace}
            row={row}
            isLastChildRow={isLastChildRow}
            parentRowLastChildRow={parentRowLastChildRow}
          />
        );
      }
      return (
        <DataRow
          isVisualLevelHtmlEditOnly={visual.htmlEditableOnly}
          key={row.__id__}
          rowId={row.__id__}
          columns={visual.columns}
          level={level}
          style={rowStyle}
          data={row.data}
          cells={row.cells}
          options={row.options}
          maxRangeValues={maxRangeValues}
          handleColumnSelect={handleColumnSelect}
          displayCategories={visual.displayCategories}
          isLastChildRow={rowIndex === childrenRows.length - 1}
          parentRowLastChildRow={parentRowLastChildRow}
          isDashboardTable={visual.component === VisualComponent.DashboardTable}
        />
      );
    }) as unknown as ReactElement<any, any> | null; // bug with react type definitions in our version
  }
);

export default DeepRows;
