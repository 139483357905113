import React, { ReactElement } from 'react';
import { Spin } from 'antd';
import './style.css';

export const SmallSpin = <Spin className="small-spin" size="small" data-testid="spinner" />;
export const MediumSpin = <Spin className="medium-spin" size="default" data-testid="spinner" />;
export const LargeSpin = <Spin className="large-spin" size="large" data-testid="spinner" />;

export function withSmallSuspense<T>(Component: React.FC<React.PropsWithChildren<T>>, fallback: ReactElement | null = SmallSpin) {
  return (props: React.PropsWithChildren<T>) => (
    <React.Suspense fallback={<div className="small-suspense-loader">{fallback}</div>}>
      <Component {...props} />
    </React.Suspense>
  );
}
