import React, { useCallback, useState } from 'react';
import { Button, Card, Col, Collapse, Divider, Flex, Row, Space } from 'antd';
import { Text } from '@/common/components/Typography/Text';
import { EditOutlined, RightOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import DOMPurify from 'dompurify';
import { Comments } from '@/modules/ERP/Contacts/ContactDetails/Comments/Comments';
import { EditNote } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Notes/EditNote';
import { ConversationType } from '@/common/types/entity/Conversation';
import { EntityLinkType } from '@/common/types/entity/Link';
import { NoteDTO } from '@/modules/ERP/Contacts/types';
import { usePermission } from '@/common/hooks/usePermission';
import { Permissions } from '@/utils/security';

interface NoteProps {
  entityId: string;
  entityType: EntityLinkType;
  note: NoteDTO;
}

const Note: React.FC<NoteProps> = React.memo(({ entityId, entityType, note }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleClickEditNote = useCallback((e: any) => {
    e.stopPropagation();
    setVisibleModal(true);
  }, []);

  const onCollapseToggle = useCallback(
    (keys: string | string[]) => {
      if (keys.length > 0) {
        setIsActive(true);
        return;
      }
      setIsActive(false);
    },
    [setIsActive]
  );

  const isNoteContributor = usePermission([Permissions.NOTE_CONTRIBUTOR]);

  return (
    <Card className="contact-activity-card task-card">
      <Collapse ghost expandIcon={() => null} onChange={onCollapseToggle}>
        <Collapse.Panel
          header={
            <div>
              <Row justify="space-between" align="middle" gutter={24} wrap={false}>
                <Col>
                  <Row align="middle">
                    <RightOutlined className={'contact-activity-title-arrow'} rotate={isActive ? 90 : 0} />
                    <Text>{note.title}</Text>
                  </Row>
                </Col>

                <Col>
                  <Row justify="end">
                    <Space size="large">
                      <NoteDateCreated date={note.date} />
                      {isActive && isNoteContributor && <Button icon={<EditOutlined />} type="link" onClick={handleClickEditNote} />}
                    </Space>
                  </Row>
                </Col>
              </Row>
              <Divider className="task-header-divider" />
              <Text type="secondary">
                <div
                  className="task-card-description"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(note.content)
                  }}
                />
              </Text>
            </div>
          }
          key="2"
        >
          <Flex vertical>
          <Divider className="task-header-divider" style={{ marginTop: 0 }} />
          <Space size="large">
            <NoteAuthor author={note.author} />
          </Space>
          <Divider className="task-header-divider" />
          <Comments refId={note.id} refType={ConversationType.NOTE} />
          </Flex>
        </Collapse.Panel>
      </Collapse>
      {visibleModal && <EditNote contactId={entityId} noteId={note.id} isOpen={visibleModal} setIsOpen={setVisibleModal} pageEntityType={entityType} pageEntityId={entityId} />}
    </Card>
  );
});

const NoteDateCreated: React.FC<{ date: string }> = ({ date }) => {
  const translator = useIntl();

  const dateCreated = new Date(date);
  const formattedDateText = dateCreated.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
  const formattedTimeText = dateCreated.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });

  return (
    <div style={{ width: 115 }}>
      <Text type="secondary">{translator.formatMessage({ id: 'generic.dateCreated' })}</Text>
      <br />
      <Text>
        {formattedDateText}, {formattedTimeText}
      </Text>
    </div>
  );
};

const NoteAuthor: React.FC<{ author: NoteDTO['author'] }> = ({ author }) => {
  const translator = useIntl();

  return (
    <div style={{paddingLeft: '16px'}}>
      <Text type="secondary">{translator.formatMessage({ id: 'generic.author' })}</Text>
      <br />
      <Text>
        {author.firstName} {author.lastName}
      </Text>
    </div>
  );
};

export { Note };
