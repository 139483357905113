import { requestApi } from '@/core';
import { User } from '@/types';

export default (accountId: string): Promise<User[]> =>
  requestApi({
    url: `/api/accounts/${accountId}/users`,
    service: 'account',
    params: {
      size: 1e4
    }
  }).then(({ data }: any) =>
    data.content.map((userEntry: any) => ({
      id: userEntry.id,
      name: `${userEntry.firstName} ${userEntry.lastName}`
    }))
  );
