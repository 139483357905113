import { requestApi } from '@/core';
import { RecentEntity } from '@/modules/GlobalSearch/recoil/search';

export enum RecentEntityModule {
  GLOBAL_SEARCH_BAR = 'GLOBAL_SEARCH_BAR',
  DOCUMENT_VIEWED = 'DOCUMENT_VIEWED'
}

export interface RecentEntityQueryParams {
  module: RecentEntityModule;
}

export const getRecentEntities = (accountId: string, userId: string, params?: RecentEntityQueryParams): Promise<RecentEntity[]> => {
  return requestApi({
    method: 'GET',
    service: 'account',
    url: `/api/accounts/${accountId}/users/${userId}/userRecentEntities`,
    params
  }).then(response => response.data.content);
};
