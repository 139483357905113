import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { getEmptyRawColumn } from '@/modules/reporting-v2/utils/getEmptyRawColumn';
import RawHistoricalMonthlyTabConfig from './types';
import { DataFrequency } from './VisualSpecificProps';

export const config: RawHistoricalMonthlyTabConfig = {
  type: 'visual',
  metric: getEmptyRawColumn(),
  group: getEmptyRawColumn(),
  subTotal: true,
  transposeMonthlyTab: false,
  showGrandTotal: false,
  _collapsible: true,
  _reportContext: VisualReportContext.NONE,
  _sampling: true,
  _dataFrequency: DataFrequency.Monthly
};
