import React from 'react';
import { FileWordOutlined } from '@ant-design/icons';
import { Text } from '@/common/components/Typography/Text';
import { ActivityLayout } from './CardLayout';
import type { ActivityDTO } from '@/modules/ERP/Contacts/types';
import { ActivityCardTypeText } from './CardTypeText';
import { parseActivityRelatedEntities } from '../utils';

interface ActivityCardNoteProps {
  activity: ActivityDTO;
  entityId: string;
}

export const ActivityCardNote: React.FunctionComponent<ActivityCardNoteProps> = React.memo(({ activity, entityId }) => {
  const { creator, relatedEntity } = parseActivityRelatedEntities(activity, entityId);

  return (
    <ActivityLayout activity={activity} icon={<FileWordOutlined />}>
      <Text link>{creator}</Text>
      &nbsp;
      <ActivityCardTypeText activityType={activity.type} />
      <Text link>{relatedEntity}</Text>
    </ActivityLayout>
  );
});
