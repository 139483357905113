import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { Select as AntDSelect, Spin, SelectProps as AntDSelectProps } from 'antd';
import fastSort from 'fast-sort';
import formatDate, { isDate } from '@/utils/formatDate';
import { nanoid } from 'nanoid';

const { Option } = AntDSelect;

export type SelectProps = {
  fetchData?: Promise<any[]> | Function;
  firstOption?: boolean;
  onChange?: (value: string) => void;
  propText?: Function | string;
  propValue?: Function | string;
  sortOrder?: 'asc' | 'desc';
  value?: any;
  placeholder?: string;
} & AntDSelectProps<any, any>;

const Select: React.FC<SelectProps> = React.forwardRef<any, SelectProps>(
  ({ firstOption = true, value, propValue = 'id', propText = 'name', sortOrder = 'asc', fetchData, children, onChange, placeholder, ...props }, ref) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const propValueFunction = useMemo(() => (typeof propValue === 'function' ? propValue : (item: any) => item[propValue]), [propValue]);
    const propTextFunction = useMemo(
      () => (typeof propText === 'function' ? propText : (item: any) => (isDate(item[propText]) ? formatDate(item[propText]) : item[propText])),
      [propText]
    );

    const setValue = useCallback(
      (value: any) => {
        onChange && onChange(value);
      },
      [onChange]
    );

    useEffect(() => {
      if (fetchData && !loading && !options.length) {
        setLoading(true);
        (typeof fetchData === 'function' ? fetchData() : fetchData)
          .then((data: any) => {
            setOptions(data);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    }, [fetchData, loading, options]);

    useEffect(() => {
      if (!options.length) {
        return;
      }
      if (!value && options.length && firstOption) {
        setValue(propValueFunction(options[0]));
      } else if (typeof options.find((item: any) => propValueFunction(item) === value) === 'undefined') {
        setValue(options.length && firstOption ? propValueFunction(options[0]) : undefined);
      }
    }, [firstOption, options, propValueFunction, setValue, value]);

    const defaultFilterOption = (search: string, { props: { children } }: any) => {
      return String(children).toLowerCase().includes(search.toLowerCase());
    };

    return (
      <Spin spinning={loading} size="small">
        <AntDSelect
          suffixIcon={<CalendarOutlined />}
          {...props}
          ref={ref}
          onChange={setValue}
          value={options && value}
          showSearch
          filterOption={props.filterOption ?? defaultFilterOption}
        >
          {options &&
            fastSort(options)
              [sortOrder || 'asc']()
              .map(item => (
                <Option key={propValueFunction(item) + nanoid()} value={propValueFunction(item)}>
                  {propTextFunction(item)}
                </Option>
              ))}
        </AntDSelect>
      </Spin>
    );
  }
);

export default Select;
