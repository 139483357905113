import { requestApi } from '@/core';
import { ApiService } from 'ui-sesame-components';
import { AssetCategoryDto } from '../types';

export const getAssetCategories = (): Promise<AssetCategoryDto[]> => {
  return requestApi({
    url: `/api/pms/assets/asset-categories`,
    service: ApiService.ASSET,
    method: 'GET'
  }).then(({ data }) => data);
};
