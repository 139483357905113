import React from 'react';
import { colors } from '@/modules/reporting-v2/core/formatTypes';

interface IProps {
  maxValue?: number;
  value: number;
}

const DataBars = ({ value, maxValue }: IProps) => {
  const dataBarStyle = React.useMemo<React.CSSProperties>(() => {
    if (!maxValue) {
      return {};
    }

    const valueIsPositive = value > 0;
    const color = valueIsPositive ? colors.green : colors.red;

    return {
      width: Math.min(50, (Math.abs(value) * 100) / maxValue / 2) + '%',
      left: valueIsPositive ? '50%' : 'auto',
      right: !valueIsPositive ? '50%' : 'auto',
      background: `linear-gradient(${valueIsPositive ? '-' : ''}90deg, ${color}30 0%, ${color} 100%)`,
      [`border${valueIsPositive ? 'Left' : 'Right'}`]: '1px dashed rgba(0,0,0,0.65)'
    };
  }, [maxValue, value]);

  return <span className="cell-data-bar" style={dataBarStyle} />;
};

export default DataBars;
