import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { UUID } from '@/common/types/types';
import { tagReportAsTemplate } from '@/modules/report-builder/utils/tagReportAsTemplate';
import { CustomReport, CustomReportRef, TransientCustomReport } from '../../../common/types/entity/CustomReport';

export const createTemplate = async (report: TransientCustomReport, spaceId: UUID): Promise<CustomReport & CustomReportRef> => {
  report.spaceId = spaceId;

  await tagReportAsTemplate(report);

  return requestApi({
    service: 'cms',
    method: 'post',
    url: '/internal/api/report-templates',
    data: report
  }).then((response: AxiosResponse<CustomReport & CustomReportRef>) => response?.data);
};
