import AllocationPieConfig from './AllocationPieConfig';
import type { ChartExtendedPoint, ExtendedRowGroup, PieChartOptions, PieChartSeriesOptions } from '@/modules/reporting-v2/core/components/Highcharts/types';
import { HighChartsDataUtils } from '@/modules/reporting-v2/core/HighchartsDataUtils';
import { Column } from '@/modules/reporting-v2/core/Column';
import { RowGroup, SortOrder } from '@/modules/reporting-v2/types/VisualEngine';
import { PointOptionsObject, SeriesPieOptions } from 'highcharts';
import { getTheme } from '@/modules/reporting-v2/utils/theme';
import colors from './colors.json';
import { FilterGroup } from '@/modules/reporting-v2/core/FilterGroup';
import { IContext } from '@/modules/reporting-v2/core/ReportContext';

class AllocationPieUtils {
  static getOptions(visual: AllocationPieConfig, columns: Column[], rows: ExtendedRowGroup[], context: IContext): PieChartOptions {
    const _columns = visual.drilldown ? [columns[0]] : columns.slice(1);
    const hasLabelDistance = typeof visual.dataLabelDistance === 'number' || !!visual.dataLabelDistance;
    let _series = _columns.flatMap<PieChartSeriesOptions>((column, index) => {
      return {
        type: 'pie',
        name: column.headerConfig.displayName as string,
        data: rows
          .filter(row => row.level === index)
          .map(row => {
            return {
              color: row.color,
              id: row.__id__,
              drilldown: row.__id__,
              name: (row.group as string) || HighChartsDataUtils.concat(...new Set([row.cells[0], row.group])),
              y: row.cells[row.cells.length - 1] as number,
              value: row.cells[row.cells.length - 1] as number,
              custom: {
                column: columns[columns.length - 1],
                data: row.data
              }
            };
          })
      };
    });

    const sortSeries = (series: SeriesPieOptions[]) => {
      const serie = series[0];
      if (!serie.data) {
        return;
      }

      const sortFunction = (() => {
        const orderIsAsc = !visual.sort?.order || visual.sort?.order === SortOrder.ASC;
        if (orderIsAsc) {
          return (a: PointOptionsObject, b: PointOptionsObject) => {
            const _a: number = a.y ?? 0;
            const _b: number = b.y ?? 0;

            return _a - _b;
          };
        }

        return (a: PointOptionsObject, b: PointOptionsObject) => {
          const _a: number = a.y ?? 0;
          const _b: number = b.y ?? 0;

          return _b - _a;
        };
      })();

      serie.data.sort(sortFunction as any);
    };

    const sortSeriesByGroup = (series: SeriesPieOptions[], groups: string[]) => {
      const rows = series[0].data as PointOptionsObject[];

      const sortedRows: PointOptionsObject[] = [];
      groups.forEach(group => {
        const row = rows.find(r => r.name === group);
        if (row) {
          sortedRows.push(row);
        }
      });

      rows.forEach(row => {
        const rowAlreadyInList = sortedRows.find(s => s.name === row.name);
        if (!rowAlreadyInList) {
          sortedRows.push(row);
        }
      });

      series[0].data = sortedRows;
    };

    const groupSeries = (series: SeriesPieOptions[]) => {
      if (visual.drilldown) {
        return series;
      }

      const serie = series[0];
      if (!serie || !serie.data) {
        return series;
      }

      if (!visual.showTopSeries || visual.showTopSeries >= serie.data.length || visual.showTopSeries < 0) {
        return series;
      }

      const dataToShow = serie.data.splice(0, visual.showTopSeries);
      const tempValues = {
        ...(serie.data as PointOptionsObject[])[0],
        color: undefined,
        name: 'Others',
        id: 'Others',
        y: 0
      };
      for (const d of serie.data as PointOptionsObject[]) {
        tempValues.y += d?.y ?? 0;
      }

      if (tempValues.custom) {
        tempValues.custom.data[tempValues.custom.column.fieldDataPath] = tempValues.y;
      }

      serie.data = [...dataToShow, tempValues];

      return series;
    };

    const filterSeries = (series: SeriesPieOptions[]) => {
      if (visual.drilldown || !visual.filterOnGroup) {
        return series;
      }

      const serie = series[0];
      if (!serie || !serie.data) {
        return series;
      }

      const filter = FilterGroup.fromRawFilter(visual.filterOnGroup, visual.columns);

      const dataToShow: PointOptionsObject[] = [];
      const tempValues = {
        ...(serie.data as PointOptionsObject[])[0],
        name: 'Others',
        id: 'Others',
        y: 0
      };

      (serie.data as PointOptionsObject[]).forEach(d => {
        const passFilter = filter.evaluate(d.custom?.data);
        if (passFilter) {
          dataToShow.push(d);
        } else {
          tempValues.y += d?.y ?? 0;
        }
      });

      if (tempValues.custom) {
        tempValues.custom.data[tempValues.custom.column.fieldDataPath] = tempValues.y;
      }

      serie.data = [...dataToShow, tempValues];

      return series;
    };

    const drilldownFormat = (row: RowGroup) => {
      return {
        id: row.__id__,
        name: row.group,
        y: row.cells[row.cells.length - 1],
        value: row.cells[row.cells.length - 1],
        type: 'pie',
        custom: {
          data: row.data,
          column: columns[columns.length - 1]
        },
        data: row.rows.map(drilldownRow => {
          return {
            name: (drilldownRow as RowGroup).group || row.group,
            value: drilldownRow.cells[drilldownRow.cells.length - 1],
            y: drilldownRow.cells[drilldownRow.cells.length - 1],
            drilldown: drilldownRow.__id__,
            custom: {
              data: drilldownRow.data,
              column: columns[columns.length - 1]
            }
          };
        })
      };
    };

    const getDrilldownSeries = () => {
      return rows.flatMap(row => {
        return HighChartsDataUtils.getFlattenRows(row, undefined, drilldownFormat);
      });
    };

    if (visual.sort?.order || visual.showTopSeries) {
      sortSeries(_series);
    }

    if (visual.sortByGroup && visual.sortByGroup.length) {
      sortSeriesByGroup(_series, visual.sortByGroup);
    }

    if (visual.showTopSeries) {
      _series = groupSeries(_series);
    }

    if (visual.filterOnGroup?.conditions.length) {
      _series = filterSeries(_series);
    }

    return {
      chart: {
        type: 'pie',
        spacingLeft: 50,
        spacingRight: 50
      },
      colors: (colors as Record<string, string[]>)[getTheme()],
      drilldown: {
        activeDataLabelStyle: {
          color: 'rgb(75, 75, 75)',
          connectorColor: '#707070'
        }
      },
      plotOptions: {
        pie: {
          innerSize: `${visual.pieHole}%`,
          size: `${visual.chartSize}%`,
          center: ['50%', '50%'],
          dataLabels: {
            color: 'rgb(75, 75, 75)',
            connectorColor: '#707070',
            enabled: !visual.chartOnly,
            connectorPadding: 0,
            padding: 0,
            formatter: function (this) {
              const point = this.point as ChartExtendedPoint;
              let formattedLabel = visual.legendBelowChart ? '' : `${this.key} `;
              if (visual.dataLabelValue !== 'group-value') {
                const columnDecimal = point.custom.column.formatting?.decimals;
                const hasColumnDecimal = columnDecimal !== undefined && columnDecimal !== null;
                formattedLabel += this.percentage.toFixed(hasColumnDecimal ? parseInt(columnDecimal) : 2) + '%';
                return formattedLabel;
              }

              const formattedValue = HighChartsDataUtils.formatCell(
                this.point.y,
                (this.point as ChartExtendedPoint).custom.column,
                (this.point as ChartExtendedPoint).custom.data,
                context.reportConfiguration?.config.numberLocale
              );
              formattedLabel += formattedValue;
              return formattedLabel;
            },
            overflow: 'allow',
            crop: false,
            style: {
              width: 150
            },
            distance: hasLabelDistance ? visual.dataLabelDistance : 30
          },
          showInLegend: (visual.legendBelowChart && !visual.chartOnly) ?? false
        }
      },
      legend: HighChartsDataUtils.getLegendFromPosition(visual.legendPosition),
      series: _series,
      ...(visual.drilldown && {
        drilldown: {
          series: getDrilldownSeries()
        } as any
      })
    };
  }
}

export { AllocationPieUtils };
