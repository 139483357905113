import React from 'react';
import { CellWrapper } from '@/modules/reporting-v2/core/components/DataTablev2/components/cell/Cell';
import type { Cell, Row as TableRow } from '@tanstack/react-table';
import type { Row as DataRow, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import type { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import cx from 'classnames';

type RowWrapperProps = {
  row: TableRow<RowGroup | DataRow>;
  onRowClick: undefined | ((rowData: FlattenObject) => void);
  isRowSelected: undefined | ((rowData: FlattenObject) => boolean);
};

export const Row: React.FC<RowWrapperProps> = ({ row, onRowClick, isRowSelected }) => {
  const onClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    e.stopPropagation();
    onRowClick?.(row.original.data);
  };

  const className = cx({ expandable: row.getCanExpand(), selected: isRowSelected?.(row.original.data) });

  return (
    <tr className={className || undefined} onClick={onClick} style={{ '--groupLevel': row.depth } as React.CSSProperties} data-group-level={row.depth}>
      {row.getVisibleCells().map((cell, index) => {
        return <CellWrapper key={cell.id} cell={cell as Cell<RowGroup | DataRow, Primitive>} index={index} />;
      })}
    </tr>
  );
};
