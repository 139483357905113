import { requestApi } from '@/core';
import { TaskDTO } from '@/modules/ERP/Contacts/types';
import { AxiosResponse } from 'axios';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';

export interface SearchTasksInterface {
  [key: string]: Primitive | Primitive[];
}

export const getTasks = (search: SearchTasksInterface): Promise<Array<TaskDTO>> => {
  return requestApi({
    service: 'collaboration',
    method: 'post',
    url: `/api/task/search?includeRemoved=${search.includeRemoved}`,
    data: search
  }).then((resp: AxiosResponse | undefined) => {
    return resp!.data;
  });
};
