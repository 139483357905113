import React, { useMemo } from 'react';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { ActivityFilter, activityFilters, periodFilters } from '@/modules/ERP/Contacts/ContactDetails/Tabs/TabsFilters';
import { Filter, FilterRow } from '@/modules/ERP/Common/FilterRow';
import { useRecoilValue } from 'recoil';
import { entityActivitySelector, filteredEntityActivitySelector } from '@/modules/ERP/Contacts/Recoil/contacts.selectors';
import { ActivityType, EntityLinkType } from '@/common/types/entity/Link';
import { withSmallSuspense } from '@/common/suspense';
import { Collapse } from 'antd';
import { ActivityCardContact } from '@/modules/ERP/Common/Activity/CardContact';
import { ActivityCardLink } from '@/modules/ERP/Common/Activity/CardLink';
import { ActivityCardNote } from '@/modules/ERP/Common/Activity/CardNote';
import { ActivityCardTask } from '@/modules/ERP/Common/Activity/CardTask';
import { ActivityCardPortfolio } from '@/modules/ERP/Common/Activity/CardPortfolio';
import { ActivityCardDocument } from '@/modules/ERP/Common/Activity/CardDocument';
import type { ActivityDTO } from '@/modules/ERP/Contacts/types';
import retrievePageTemplate, { PageTemplate } from '@/utils/retrievePageTemplate';
import { EmptyResult } from '../Result/EmptyResult';

type MonthlyActivities = {
  year: number;
  month: number;
  activities: ActivityDTO[];
};

interface ActivityProps {
  entityId: string;
  entityType: ActivityType;
}

export const activityActivityFilterNodeId = 'activityActivityFilter';
export const activityDateFilterNodeId = 'activityDateFilter';

const Activity: React.FC<ActivityProps> = withSmallSuspense(
  React.memo(({ entityId, entityType }) => {
    const translator = useIntl();
    const activities = useRecoilValue(entityActivitySelector([entityType, entityId]));

    const filters = useMemo(() => {
      const activityOptions = activityFilters(translator);
      const activityDateOptions = periodFilters(translator);

      const availableActivityTypes = new Set(activities.map(activity => activity.entityType));
      const activityOptionsEntries = Object.entries(activityOptions).filter(([key]) => {
        return key === ActivityFilter.ALL || availableActivityTypes.has(key as ActivityType);
      });

      const activityKey = 'generic.activity';
      const lastActivityDateKey = 'generic.lastActivityDate';

      return [
        {
          key: activityKey,
          filter: (
            <Filter
              recoilNodeId={activityActivityFilterNodeId}
              title={translator.formatMessage({ id: activityKey })}
              icon={<CalendarOutlined />}
              options={Object.fromEntries(activityOptionsEntries)}
            />
          )
        },
        {
          key: lastActivityDateKey,
          filter: (
            <Filter
              recoilNodeId={activityDateFilterNodeId}
              title={translator.formatMessage({ id: lastActivityDateKey })}
              icon={<CalendarOutlined />}
              options={activityDateOptions}
            />
          )
        }
      ];
    }, [translator, activities]);
    const positiveData = activities && activities.length > 0;

    return (
      <>
        {positiveData && <FilterRow withPrefix={false} filters={filters} />}
        <ActivityList entityType={entityType} entityId={entityId} />
      </>
    );
  })
);

interface ActivityListProps {
  entityId: string;
  entityType: ActivityType;
}

export const ActivityList: React.FunctionComponent<ActivityListProps> = withSmallSuspense(
  React.memo(({ entityId, entityType }) => {
    const translator = useIntl();
    const activities = useRecoilValue(filteredEntityActivitySelector([entityType, entityId]));
    const currentPageTemplate = retrievePageTemplate();
    const translations = {
      [ActivityType.HOLDING_SET]: {
        [PageTemplate.PORTFOLIO_VIEW_LEGAL_ENTITY]: 'generic.legalEntity',
        [PageTemplate.PORTFOLIO_VIEW]: 'generic.portfolio'
      },
      [ActivityType.CONTACT]: {
        [PageTemplate.CONTACTS_LIST_COMPANIES]: 'generic.company',
        [PageTemplate.CONTACTS_LIST]: 'generic.contact'
      }
    };

    let translatedEntityType = translations[entityType]?.[currentPageTemplate] || `generic.${entityType.toLowerCase()}`;
    translatedEntityType = translator.formatMessage({ id: translatedEntityType });

    if (activities.length <= 0) {
      return (
        <EmptyResult
          icon={<ClockCircleOutlined style={{ fontSize: '45px', color: '#bfbfbf' }} />}
          titleId="erp.activity.noActivity"
          titleSubTitleId="erp.activityWillAppearHere"
          titleSubTitleValues={{ pageType: translatedEntityType.toLowerCase() }}
        >
          {''}
        </EmptyResult>
      );
    }

    const getMonthlyActivities = (activities: ActivityDTO[]) => {
      const monthlyActivities: MonthlyActivities[] = [];

      let actualMonth: number | undefined = undefined;
      let actualYear: number | undefined = undefined;
      let actualMonthActivities: ActivityDTO[] = [];
      activities.forEach((activity, index) => {
        const date = new Date(activity.timestamp);
        const month = date.getMonth();
        const year = date.getFullYear();

        if (index === 0) {
          actualMonth = month;
          actualYear = year;
        }

        if (month !== actualMonth) {
          monthlyActivities.push({
            month: actualMonth as number,
            year: actualYear as number,
            activities: actualMonthActivities
          });
          actualMonthActivities = [];
          actualMonth = month;
          actualYear = year;
        }

        actualMonthActivities.push(activity);

        if (index === activities.length - 1) {
          monthlyActivities.push({
            month,
            year,
            activities: actualMonthActivities
          });
        }
      });

      return monthlyActivities;
    };

    const monthlyActivities = getMonthlyActivities(activities);
    // defaultActiveKeys: open every months by default
    const defaultActiveKeys = monthlyActivities.map(monthlyActivitiesItem => `${monthlyActivitiesItem.month}${monthlyActivitiesItem.year}`);

    return (
      <Collapse bordered={false} ghost defaultActiveKey={defaultActiveKeys} expandIconPosition="end">
        {monthlyActivities.map(monthlyActivitiesItem => {
          const date = new Date();
          date.setDate(1);
          date.setMonth(monthlyActivitiesItem.month);
          date.setFullYear(monthlyActivitiesItem.year);

          return (
            <Collapse.Panel
              header={date.toLocaleString('en-US', {
                month: 'long',
                year: 'numeric'
              })}
              key={`${monthlyActivitiesItem.month}${monthlyActivitiesItem.year}`}
            >
              {monthlyActivitiesItem.activities.map(activity => (
                <ActivityCard key={activity.timestamp} activity={activity} entityId={entityId} entityType={entityType} />
              ))}
            </Collapse.Panel>
          );
        })}
      </Collapse>
    );
  })
);

type ActivityCardProps = {
  activity: ActivityDTO;
  entityId: string;
  entityType: ActivityType;
};

const ActivityCard: React.FC<ActivityCardProps> = ({ activity, entityId, entityType }) => {
  switch (activity.entityType) {
    case ActivityType.NOTE:
      return <ActivityCardNote activity={activity} entityId={entityId} />;
    case ActivityType.TASK:
      return <ActivityCardTask activity={activity} entityId={entityId} />;
    case ActivityType.HOLDING_SET:
      return <ActivityCardPortfolio activity={activity} entityId={entityId} />;
    case ActivityType.DOCUMENT:
      return <ActivityCardDocument activity={activity} entityId={entityId} />;
    case ActivityType.CONTACT:
      return (
        <ActivityCardContact
          activity={activity}
          entityId={entityId}
          entityType={entityType}
          isContactPage={(entityType as unknown) === EntityLinkType.CONTACT_PERSON || (entityType as unknown) === EntityLinkType.CONTACT_COMPANY}
        />
      );
    case ActivityType.LINK:
      return <ActivityCardLink activity={activity} entityId={entityId} entityType={entityType} />;
    default:
      return null;
  }
};

export { Activity };
