import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import type RawLimitsTableConfig from './types';

export const config: RawLimitsTableConfig = {
  type: 'visual',
  columns: {
    columns: ['L08', 'L11', 'L12', 'L09', 'L21', 'L22', 'L23', 'L24', 'L14', 'L15', 'L16', 'L17', 'L18', 'limits.date', '/actions/'],
    defaultColumns: ['L08', 'L11', 'L12', '/actions/'],
    options: {
      '/actions/': {
        ___displayName: 'Actions'
      },
      'limits.date': {
        ___displayName: 'Limit date'
      }
    },
    filters: {}
  },
  groupBy: [],
  category: [],
  status: [],
  dateRange: 'SELECTED_DATE',
  collapsible: true,
  subTotal: true,
  sortable: true,
  total: false,
  _reportContext: VisualReportContext.NONE
};
