import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedLiquidityTableConfig } from '..';
import { LiquidityTableConfigMapped } from './types';
import { AvgDailyVol, LiquidateDays, ParticipationRates } from './VisualSpecificProps';

export default class LiquidityTableConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as LiquidityTableConfigMapped));
  }

  getVisualConfigProps(config: LiquidityTableConfigMapped): ExtendedLiquidityTableConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      categoryHeadersEnabled: config.categoryHeadersEnabled,
      liquidateDays: config.liquidateDays,
      participationRates: config.participationRates,
      avgDailyVol: config.avgDailyVol
    });
  }

  setVisualConfig(config: ExtendedLiquidityTableConfig) {
    super.setVisualConfig(config);
    this.categoryHeadersEnabled = config.categoryHeadersEnabled;
    this.liquidateDays = config.liquidateDays;
    this.participationRates = config.participationRates;
    this.avgDailyVol = config.avgDailyVol;
  }

  categoryHeadersEnabled: boolean;
  liquidateDays: LiquidateDays[];
  participationRates: ParticipationRates[];
  avgDailyVol: AvgDailyVol;
}
