export const tabDelimiter = 'group_fields-'; // in a schema, multiple tab delimiters must have an unique identifiers appended in the json (can be 1,2,3... eg: group_fields-1) to avoid collisions

export interface SchemaFieldShape {
  type: FieldType;
  label?: string;
  title?: string;
  mode?: string;
  tooltip?: string;
  divider?: boolean;
  options?: Record<string, string>;
  fields?: Record<string, SchemaFieldShape>;
  action?: Record<string, SchemaFieldShape>;
  style?: React.CSSProperties;
}

export type SchemaFieldObject<T = any> = T extends Array<any>
  ? {
      label?: string;
      title?: string;
      type: FieldType.ARRAY;
      fields?: SchemaFields<T[number]>;
      [key: string]: unknown;
    }
  : {
      label?: string;
      title?: string;
      type: FieldType;
      fields?: SchemaFields<T>;
      options?: Record<string, string>;
      allowEmpty?: boolean;
      style?: React.CSSProperties;
      tooltip?: string;
      divider?: boolean;
      mode?: string;
      [key: string]: unknown;
    };

type GroupFieldKey = `${typeof tabDelimiter}${string}`;

export type SchemaFields<T = object> = {
  [K in keyof T]: SchemaFieldObject<T[K]>;
} & {
  [K: GroupFieldKey]: {
    type: FieldType.GROUP;
    title: string;
    fields?: SchemaFields<T>;
  };
};

export enum FieldType {
  ARRAY = 'array',
  BOOLEAN = 'boolean',
  BORDER = 'border',
  CALC = 'calc',
  CHECKBOX_GROUP = 'checkboxgroup',
  COLORPICKER = 'colorpicker',
  COLUMNS = 'columns',
  COLUMNS_OR_VISUAL_COLUMNS = 'columns_or_visualcolumns',
  CONDITIONAL_COLOR_SELECT = 'conditionalSelect',
  CONDITIONAL_MEASURE_SELECT = 'conditionalMeasureSelect',
  CUSTOM_CSS = 'customcss',
  CUSTOMCOLUMNS = 'customcolumns',
  DATE = 'date',
  DICT = 'dict',
  ENTITY_SELECTOR = 'entityselector',
  FILTER_GROUP = 'filtergroup',
  FONT = 'font',
  GLOBAL_FILTERS = 'globalfilters',
  GROUP = 'group',
  HYPERLINK = 'hyperlink',
  MARKDOWN = 'markdown',
  TABLE_VERSION = 'table_version',
  MULTICOLORPICKER = 'multicolorpicker',
  NONE = 'none',
  NUMBER = 'number',
  PAGE_TEMPLATE = 'pageTemplate',
  RADIO_GROUP = 'radiogroup',
  SELECT = 'select',
  SERIES = 'series',
  SWITCH = 'switch',
  TEXT = 'text',
  TEXT_ALIGN = 'text-align',
  UPLOAD = 'upload',
  VISUAL_COLUMNS = 'visualcolumns',
  VISUAL_SELECTOR = 'visualSelector',
  WYSIWYG_MARKDOWN = 'wysiwyg_markdown'
}
