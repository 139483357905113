import { MetadataEntityType, MetadataKeyDto } from '@/common/types/entity/Metadata';
import { requestApi } from '@/core';

export default (entityType?: MetadataEntityType): Promise<MetadataKeyDto[]> =>
  requestApi({
    method: 'GET',
    url: `/api/metadata/keys`,
    service: 'metadata',
    params: {
      entityType
    }
  }).then(response => {
    return response.data;
  });
