import React from 'react';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { config } from './config';
import schema from './schema.json';
import HoldingSetInfoConfig from './HoldingSetInfoConfig';
import RawHoldingSetInfoConfig from './types';

class HoldingSetInfo extends Visual {
  lineHeight = (length: number) => (length > 3 ? { lineHeight: '16px' } : { lineHeight: '20px' });

  static configMapper(visualConfig: RawHoldingSetInfoConfig) {
    const { rows, ...rest } = visualConfig;
    return {
      ...rest,
      columns: mapVisualConfigColumns(rows)
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as HoldingSetInfoConfig;
  }

  getSchema() {
    return schema;
  }

  renderTitle() {
    return null;
  }

  renderInteractivity() {
    return [];
  }

  renderBody = () => {
    return (
      <div
        style={{
          ...this.lineHeight(this.props.visual.columns.length),
          marginRight: 5
        }}
      >
        {this.props.visual.columns.map((column, index) => (
          <div
            key={column.field.name}
            style={
              {
                fontWeight: !index && 600,
                fontSize: !index && 16
              } as React.CSSProperties
            }
          >
            {this.props.visual.data.rows[0]?.data[column.field.getElasticPath()]}
          </div>
        ))}
      </div>
    );
  };
}

export default HoldingSetInfo;
