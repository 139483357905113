import React from 'react';
import HistoricalMonthlyTabConfig from './HistoricalMonthlyTabConfig';
import { Column, ColumnFormatting } from '@/modules/reporting-v2/core/Column';
import { Field } from '@/modules/reporting-v2/core/Field';
import { nanoid } from 'nanoid';
import { Row, RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import { DataFrequency, DateRange } from './VisualSpecificProps';
import { useIntl } from 'react-intl';
import { FieldName } from '@/modules/reporting-v2/types/Field';
import { getTableMonths } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/utils/getTableMonths';
import type { ColumnConfig } from '@/modules/reporting-v2/types/Column';
import dayjs from 'dayjs';
import { withSmallSuspense } from '@/common/suspense';
import { MemoizedDataTablev2 } from '@/modules/reporting-v2/core/components/DataTablev2/DataTable';
import { DATE_FORMAT } from 'ui-sesame-components';

const totalColumnFakeField = 'total';

type HistoricalGroupedTableProps = {
  visual: HistoricalMonthlyTabConfig;
  reportLocale: string | undefined;
};

export const HistoricalGroupedTable: React.FC<HistoricalGroupedTableProps> = withSmallSuspense(({ visual, reportLocale }) => {
  const { formatMessage } = useIntl();
  const dateColumn = visual.columns[0];
  const valueColumn = visual.columns.filter(column => column.isDefault)[0];
  const totalColumn = visual.columns.find(column => column.code === visual.totalMetric && visual.totalMetric !== undefined);
  const tableMonths = getTableMonths(reportLocale, visual.dataFrequency);
  const isGrouped = visual.groupByColumns.filter(i => i.isDefault).length > 1;
  const showOnlyCurrentYear = visual.dateRange === ('currentYear' as keyof DateRange);
  const totalColumnIndex = tableMonths.length + 1;

  const getColumns = (): Column[] => {
    const columns = [
      new Column({
        field: new Field('empty'),
        isDefault: true,
        formatting: new ColumnFormatting(),
        id: nanoid(6)
      })
    ];

    const isYearlyFrequency = visual.dataFrequency === DataFrequency.Yearly;
    tableMonths.forEach(month => {
      const displayName = isYearlyFrequency ? valueColumn.headerConfig.displayName : month.month;
      columns.push(
        new Column({
          ...(valueColumn as ColumnConfig),
          field: new Field(month.frequencyKey! ?? month.key),
          headerConfig: {
            displayName
          },
          isDefault: true,
          id: nanoid(6)
        })
      );
    });

    if (totalColumn) {
      columns.push(
        new Column({
          ...(totalColumn as ColumnConfig),
          field: new Field(totalColumnFakeField),
          headerConfig: { displayName: 'Total' }
        })
      );
    }

    return columns;
  };

  const getDateFormat = (frequency: DataFrequency) => {
    switch (frequency) {
      case DataFrequency.Yearly:
        return 'YYYY'; // 2021
      case DataFrequency.Quarterly:
        return 'Q'; // 1 - 4
      case DataFrequency.Monthly:
      default:
        return 'MM'; // 01 - 12
    }
  };

  // keep only the last row of each month/quarter/year
  const getMonthEndData = (rows: (Row | RowGroup)[]): RowGroup[] => {
    // Sort rows by date from newest to oldest
    rows.sort((a, b) => (b.data[dateColumn.fieldDataPath] as string).localeCompare(a.data[dateColumn.fieldDataPath] as string));

    const newData: RowGroup[] = [];
    const dateFormatter = getDateFormat(visual.dataFrequency);

    for (const row of rows) {
      const rowDate = row.data[dateColumn.fieldDataPath] as string;
      const [year] = rowDate.split('-');

      const formattedDate = dayjs(rowDate, DATE_FORMAT.DATE).format(dateFormatter);
      // get the key for the last month of the frequency
      // example for quarter 3, last month is september => 09
      let monthKey = tableMonths.find(tableMonth => tableMonth.frequencyKey === formattedDate)?.key;
      if (visual.dataFrequency === DataFrequency.Yearly) {
        monthKey = '12';
      }

      const yearData = newData.find(si => si.group === year);
      if (!yearData) {
        newData.push({
          __id__: nanoid(6),
          rows: [
            {
              ...row,
              group: monthKey
            }
          ],
          group: year,
          cells: row.cells,
          data: row.data,
          level: 0
        });
        continue;
      }

      const yearRows = yearData.rows as RowGroup[];
      const monthData = yearRows.find(r => {
        const rDate = r.data[dateColumn.fieldDataPath] as string;
        return dayjs(rDate, DATE_FORMAT.DATE).format(dateFormatter) === formattedDate;
      });
      if (!monthData) {
        yearData.rows.push({
          ...row,
          group: monthKey
        });
      }
    }

    return newData;
  };

  const buildRows = (filteredRows: RowGroup[]): RowGroup[] => {
    const rows: RowGroup[] = [];
    const metricColumnLength = 1;
    const totalMetricColumnLength = visual.totalMetric ? 1 : 0;
    const cellsLength = metricColumnLength + tableMonths.length + totalMetricColumnLength;

    const buildChildRow = (parentBuildedRow: RowGroup, currentOriginalRow: RowGroup, cellIndex: number, monthKey: string, isLastMonth: boolean): void => {
      let groupItem = parentBuildedRow.rows?.find(si => (si as RowGroup).group === currentOriginalRow.group);

      if (!groupItem) {
        groupItem = {
          data: {},
          rows: [],
          group: currentOriginalRow.group,
          level: parentBuildedRow.level + 1,
          cells: Array(cellsLength).fill(null),
          __id__: nanoid(6)
        } as RowGroup;
        parentBuildedRow.rows.push(groupItem);
      }

      const value = currentOriginalRow.data?.[valueColumn.fieldDataPath];
      groupItem.data[monthKey] = value;
      groupItem.cells[cellIndex] = value;

      if (isLastMonth && totalColumn) {
        groupItem.data[totalColumnFakeField] = currentOriginalRow.data?.[totalColumn.fieldDataPath];
        groupItem.cells[totalColumnIndex] = currentOriginalRow.data?.[totalColumn.fieldDataPath];
      }

      if (!currentOriginalRow.rows) {
        return;
      }

      for (const row of currentOriginalRow.rows) {
        const rowIsRowGroup = 'group' in row;
        if (!rowIsRowGroup) {
          continue;
        }

        buildChildRow(groupItem as RowGroup, row, cellIndex, monthKey, isLastMonth);
      }
    };

    const buildMonth = (monthlyData: RowGroup, yearRow: RowGroup, isLastMonth: boolean) => {
      const monthlyDataValue = monthlyData.data[valueColumn.fieldDataPath];
      const monthIndex = monthlyData.group as string;
      const tableMonthIndex = tableMonths.findIndex(tableMonth => tableMonth.key === monthIndex);

      if (visual.showGrandTotal || !isGrouped) {
        yearRow.data[monthIndex] = monthlyDataValue;
        yearRow.cells[tableMonthIndex + 1] = monthlyDataValue;
      }

      if (!monthlyData.rows) return;

      for (const element of monthlyData.rows) {
        const groupedData = element;
        buildChildRow(yearRow, groupedData as RowGroup, tableMonthIndex + 1, monthlyData.group as string, isLastMonth);
      }
    };

    const buildYearRow = (yearlyData: RowGroup) => {
      const yearRow: RowGroup = {
        data: {},
        rows: [],
        group: yearlyData.group,
        level: 0,
        fullRow: false,
        cells: Array(cellsLength).fill(null),
        __id__: yearlyData.group as string
      };

      let index = 0;
      for (const monthlyRow of yearlyData.rows) {
        buildMonth(monthlyRow as RowGroup, yearRow, index === 0);
        index++;
      }

      if (totalColumn) {
        const lastMonthData = (yearlyData.rows as RowGroup[])[0];
        yearRow.data[cellsLength - 1] = lastMonthData.data[totalColumn.fieldDataPath];
        yearRow.cells[cellsLength - 1] = lastMonthData.data[totalColumn.fieldDataPath];
      }

      rows.push(yearRow);
    };

    for (const yearlyData of filteredRows) {
      buildYearRow(yearlyData);
    }

    return rows;
  };

  const polishRows = (buildedRows: RowGroup[]): (RowGroup | Row)[] => {
    if (showOnlyCurrentYear && visual.showGrandTotal && isGrouped) {
      return buildedRows[0].rows.concat({
        cells: [formatMessage({ id: 'report.visual.historical.grandTotal' }), ...buildedRows[0].cells.slice(1)],
        data: {}
      } as Row);
    }

    return buildedRows;
  };

  const getRowsWithYearTotal = (polishedRows: (Row | RowGroup)[]) => {
    return polishedRows.map(row => {
      const isRowGroup = 'group' in row;
      if (!isRowGroup) {
        return row;
      }

      const totalRow: Row = {
        __id__: row.__id__ + 'total',
        cells: row.cells,
        data: row.data
      };
      totalRow.cells[0] = 'Total';

      return {
        ...row,
        fullRow: false,
        cells: [row.cells[0]],
        rows: [...row.rows, totalRow]
      };
    });
  };

  const filteredRows = getMonthEndData(visual.data.rows);
  const buildedRows = buildRows(filteredRows);
  const polishedRows = polishRows(buildedRows);

  const columns = getColumns();

  const getColumnsMaxRangeValue = (filteredRows: RowGroup[]): Record<FieldName, number | undefined> => {
    const result: Record<FieldName, number | undefined> = {};

    filteredRows.forEach(row => {
      row.rows.forEach(month => {
        if ('maxRangeValues' in month) {
          result[month.group as string] = month.maxRangeValues?.[valueColumn.fieldDataPath];
        }
      });
    });

    return result;
  };

  const newVisual = {
    ...visual,
    columns: columns,
    data: {
      rows: polishedRows,
      maxRangeValues: getColumnsMaxRangeValue(filteredRows)
    },
    sortable: false,
    subTotal: true,
    collapsible: isGrouped
  } as HistoricalMonthlyTabConfig;

  if (visual.tableVersion === 'v1') {
    return <DataTable visual={newVisual as IDataTableProps['visual']} />;
  }

  if (visual.showGrandTotal && isGrouped && !showOnlyCurrentYear) {
    const rowsWithTotals = getRowsWithYearTotal(polishedRows);
    newVisual.data.rows = rowsWithTotals;
  }
  return <MemoizedDataTablev2 visual={newVisual} defaultExpanded />;
});
