import { ReactElement } from 'react';
import { ColumnMetas } from '@/modules/reporting-v2/types/Column';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { Column, ColumnFormatting } from './Column';
import formatTypes from './formatTypes';
import { ReportingService } from './ReportingService';

export default (column: Column, value: Primitive, data?: FlattenObject, defaultValue = '', isChart?: boolean, forcedCurrency?: false | string, locale?: string) => {
  const type =
    column.formatting?.type ||
    (
      ReportingService.metas?.[column.code ?? column.field.name]?.formatting as {
        type?: string;
      }
    )?.type;

  if (!type || !formatTypes.hasOwnProperty(type) || (typeof value === 'number' && isNaN(value)) || value === undefined || value === null || value.toString().trim() === '') {
    if (value || value === 0) {
      return value;
    }
    if (isChart) {
      return '';
    }

    return defaultValue;
  }

  return (
    formatTypes as Record<
      string,
      (v: Primitive, m: ColumnMetas, d?: FlattenObject, f?: ColumnFormatting, isChart?: boolean, forceCurrency?: string | false, locale?: string) => ReactElement | Primitive | null
    >
  )[type](value, ReportingService.metas?.[column.code ?? column.field.name] ?? {}, data, column.formatting, isChart, forcedCurrency, locale);
};
