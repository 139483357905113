import { requestApi } from '@/core';
import { UUID } from '@/common/types/types';
import { EntityLinkType } from '@/common/types/entity/Link';

export const deleteEntityLinks = (entityType: EntityLinkType, entityId: UUID, entityName?: string): Promise<void> => {
  return requestApi({
    service: 'link',
    method: 'delete',
    url: `/api/${entityType}/${entityId}/links`
  }).then(() => Promise.resolve());
};
