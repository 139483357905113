import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { loggedUserSelector, spaceSelector, userInformationSelector, usersForCurrentUsers } from '@/common/auth/recoil/user.selector';
import { Text } from '@/common/components/Typography/Text';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Flex, message, Modal, Row } from 'antd';
import DOMPurify from 'dompurify';
import { comments as commentsAtom, conversation as conversationAtom } from '@/recoil/conversations';
import { Comment as CommentEntity, ConversationType } from '@/common/types/entity/Conversation';
import createComment from '@/modules/Conversation/api/createComment';
import { withSmallSuspense } from '@/common/suspense';
import deleteComment from '@/modules/Conversation/api/deleteComment';
import 'quill-mention';
import '../style.css';
import { userListMentionQuill } from '@/modules/ERP/Common/utils';
import { usePermission } from '@/common/hooks/usePermission';
import { Permissions } from '@/utils/security';
import { handleHtmlContent } from '@/utils/handleHtmlContent';
import { EditComment } from '@/modules/ERP/Contacts/ContactDetails/Comments/EditComments';
import { entityActivitySelector } from '@/modules/ERP/Contacts/Recoil/contacts.selectors';
import { ActivityType } from '@/common/types/entity/Link';
import { ReactQuillAdapted } from '@/common/ReactQuillAdapted';
import { EmptyResult } from '@/modules/ERP/Common/Result/EmptyResult';

interface CommentProps {
  refType: ConversationType;
  refId: number | string;
}

export const Comments: React.FunctionComponent<CommentProps> = withSmallSuspense(({ refType, refId }) => {
  const translator = useIntl();
  const loggedUser = useRecoilValue(loggedUserSelector);
  const user = useRecoilValue(userInformationSelector(loggedUser.accountId));
  const users = useRecoilValue(usersForCurrentUsers(loggedUser.accountId));
  const [mention, setMention] = useState({});
  const [reference, setReference] = useState<string>('');
  const [showCommentEditor, setShowCommentEditor] = useState<boolean>(false);
  const [nocommentState, setNocommentState] = useState<boolean>(false);

  const refTypePermissions = useMemo(() => {
    const list = [];

    if (refType === ConversationType.NOTE) {
      list.push(Permissions.NOTE_CONTRIBUTOR);
    }

    if (refType === ConversationType.TASK) {
      list.push(Permissions.TASK_CONTRIBUTOR);
    }

    return list;
  }, [refType]);

  const showSaveComment = usePermission(refTypePermissions);

  useEffect(() => {
    setMention({
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@'],
      defaultMenuOrientation: 'top',
      source: (searchTerm: any, renderList: any) => userListMentionQuill(searchTerm, renderList, users)
    });
  }, [users]);

  const handleContentChange = useCallback((content: string) => setReference(content), []);

  const handleChangeQuill = useCallback(
    (content: string) => {
      if (content.replace(/<[^>]*>?/gm, '') !== reference.replace(/<[^>]*>?/gm, '')) {
        handleHtmlContent(handleContentChange)(content);
      }
    },
    [handleContentChange, reference]
  );

  const handleSaveComment = useRecoilCallback(
    ({ refresh, snapshot }) =>
      async () => {
        if (!reference.replace(/<[^>]*>?/gm, '')) {
          message.warning('Please type a comment');
          return;
        }
        const conversation = await snapshot.getPromise(conversationAtom({ refType, refId }));
        return createComment(conversation.id!, new CommentEntity(reference, user!)).then(() => {
          message.success(translator.formatMessage({ id: 'generic.successfullyAdded' }));
          setTimeout(() => {
            refresh(
              commentsAtom({
                refType,
                refId,
                withoutNewCommentFromOldModule: true
              })
            );
            refresh(entityActivitySelector([refType as unknown as ActivityType, refId.toString()]));
            setReference('');
            setNocommentState(false);
          }, 1000);
        });
      },
    [reference, user, refId, refType]
  );

  const quillContainerElement = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // https://github.com/zenoamaro/react-quill/issues/784
    // known issue from react quill toolbars are duplicated because the component is not fully unmount => react quill issue => should we change the library ?
    // No posibility of calling recoil value in same component or else this function does not work.. visibility of components is set by lifting states 'showCommentEditor' and 'showCancelButton'
    if (quillContainerElement.current?.firstChild) {
      const children = Array.from(quillContainerElement.current.firstChild.childNodes) as HTMLElement[];
      const duplicateTooltips = children.filter(el => {
        return el.classList.contains('ql-toolbar');
      });
      if (duplicateTooltips.length > 1) {
        duplicateTooltips[0].remove();
      }
    }
  }, [showCommentEditor, quillContainerElement]);

  const handleCancelButton = () => {
    setReference('');
    if (nocommentState) {
      setShowCommentEditor(false);
    }
  };
  const handleAddComment = () => {
    setShowCommentEditor(true);
    setReference('');
  };

  return (
    <div style={{ padding: '0 16px' }}>
      <div className="comment-section-title">
        <Text strong black>
          {translator.formatMessage({ id: 'generic.comments' })}
        </Text>
      </div>

      <CommentsList refType={refType} refId={refId} setShowCommentEditor={setShowCommentEditor} setNocommentState={setNocommentState} />
      {showSaveComment && showCommentEditor ? (
        <div style={{ marginTop: '50px' }}>
          <div ref={quillContainerElement} style={{ marginTop: '30px', padding: '0 16px' }}>
            <ReactQuillAdapted
              style={{
                cursor: refType === ConversationType.NOTE ? 'not-allowed' : 'auto',
                backgroundColor: refType === ConversationType.NOTE ? 'rgba(236, 236, 236, 0.20)' : 'white'
              }}
              readOnly={refType === ConversationType.NOTE}
              value={reference}
              placeholder={translator.formatMessage({ id: 'erp.comments.addComment' })}
              preserveWhitespace={true}
              onChange={handleChangeQuill}
              modules={{ mention: mention }}
            />
            <br />

            <Row justify="space-between">
              <div>
                {nocommentState ? (
                  <Button type="default" onClick={() => handleCancelButton()}>
                    Cancel
                  </Button>
                ) : (
                  <Button disabled={!reference.replace(/<[^>]*>?/gm, '')} type="default" onClick={() => handleCancelButton()}>
                    Cancel
                  </Button>
                )}
              </div>
              <Flex justify="flex-end">
                <VisibleToSpace />
                <Button type={'primary'} disabled={!reference.replace(/<[^>]*>?/gm, '')} onClick={handleSaveComment}>
                  <FormattedMessage id={'generic.comment'} />
                </Button>
              </Flex>
            </Row>
          </div>
        </div>
      ) : (
        <EmptyResult
          icon={<MessageOutlined style={{ fontSize: '45px', color: '#bfbfbf' }} />}
          titleId="erp.comments.noComments"
          titleSubTitleId="erp.CommentsOnThisEntityWillAppearHere"
          titleSubTitleValues={{ entityType: refType.toLowerCase() }}
        >
          <Button type="primary" className="empty-state-modal-button" onClick={() => handleAddComment()}>
            <FormattedMessage id={'erp.comments.addComment'} />
          </Button>
        </EmptyResult>
      )}
    </div>
  );
});

const VisibleToSpace = withSmallSuspense(() => {
  const toSpace = useRecoilValue(spaceSelector);

  if (!toSpace) {
    return null;
  }

  return (
    <label className="ant-btn" style={{ marginRight: '0.5rem', pointerEvents: 'none' }}>
      <FormattedMessage
        id={'erp.contact.comments.visibleTo'}
        values={{
          b: (chunks: any) => <b>{chunks}</b>,
          entity: toSpace?.name
        }}
      />
    </label>
  );
});

interface CommentListProps {
  refType: ConversationType;
  refId: number | string;
  setShowCommentEditor: React.Dispatch<React.SetStateAction<boolean>>;
  setNocommentState: React.Dispatch<React.SetStateAction<boolean>>;
}

const CommentsList: React.FunctionComponent<CommentListProps> = withSmallSuspense(
  React.memo(({ refId, refType, setShowCommentEditor, setNocommentState }) => {
    const [comments] = useRecoilState(commentsAtom({ refType, refId, withoutNewCommentFromOldModule: true }));
    const user = useRecoilValue(loggedUserSelector);
    const translator = useIntl();
    const [noteToDelete, setNoteToDelete] = useState<number | null>(null);
    const [noteToEdit, setNoteToEdit] = useState<number | null>(null);
    const conversation = useRecoilValue(conversationAtom({ refType, refId }));

    useEffect(() => {
      if (comments.length > 0) {
        setShowCommentEditor(true);
        setNocommentState(false);
      }
      if (comments.length === 0) {
        setShowCommentEditor(false);
        setNocommentState(true);
      }
    }, [comments.length, setNocommentState, setShowCommentEditor]);

    const confirmDeleteComment = useRecoilCallback(
      ({ refresh }) =>
        () => {
          deleteComment(conversation.id!, noteToDelete!).then(() => {
            setTimeout(() => {
              refresh(
                commentsAtom({
                  refType,
                  refId,
                  withoutNewCommentFromOldModule: true
                })
              );
            }, 1000);
            message.success(translator.formatMessage({ id: 'generic.successfullyDeleted' }));
            setNoteToDelete(null);
          });
        },
      [conversation.id, refType, refId, noteToDelete]
    );

    const handleDeleteComment = useCallback((id: number) => {
      setNoteToDelete(id);
    }, []);

    const handleEditComment = useCallback((id: number) => {
      setNoteToEdit(id);
    }, []);
    return (
      <>
        {comments.map(comment => {
          const formatDate = new Date(comment.createdDate);
          const userHasPermissionToDelete = user.isSuperAdmin || user.userId === comment.user.id;

          const userHasPermissionToEdit = user.userId === comment.user.id;

          return (
            <div className="comment" key={comment.id}>
              <div className="comment-user">
                <UserOutlined
                  style={{
                    backgroundColor: `#5C90F0`,
                    color: '#ffffff',
                    padding: '5px',
                    borderRadius: '15px',
                    margin: '5px 5px 5px 0'
                  }}
                />
                <Text link>
                  {DOMPurify.sanitize(comment.user.firstName)} {DOMPurify.sanitize(comment.user.lastName)}{' '}
                </Text>{' '}
                <div style={{ float: 'right' }}>
                  <Text>
                    {formatDate.toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric'
                    })}
                    ,{' '}
                    {formatDate.toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false
                    })}
                  </Text>
                </div>
              </div>
              <Text type={'secondary'} style={{ display: 'inline-block' }}>
                <p
                  className="comment-content"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(comment.content)
                  }}
                ></p>
              </Text>
              <DeleteOutlined
                disabled={!userHasPermissionToDelete}
                style={{
                  fontSize: '16px',
                  float: 'right',
                  color: userHasPermissionToDelete ? 'var(--ant-color-primary)' : 'grey',
                  cursor: userHasPermissionToDelete ? 'pointer' : 'not-allowed'
                }}
                onClick={() => (userHasPermissionToDelete ? handleDeleteComment(comment.id!) : null)}
              />
              <EditOutlined
                disabled={!userHasPermissionToEdit}
                style={{
                  fontSize: '16px',
                  float: 'right',
                  color: userHasPermissionToEdit ? 'var(--ant-color-primary)' : 'grey',
                  cursor: userHasPermissionToEdit ? 'pointer' : 'not-allowed',
                  marginRight: '8px'
                }}
                onClick={() => (userHasPermissionToEdit ? handleEditComment(comment.id!) : null)}
              />
              {noteToDelete && (
                <Modal
                  onOk={confirmDeleteComment}
                  onCancel={() => setNoteToDelete(null)}
                  open={!!noteToDelete}
                  title={
                    <>
                      <ExclamationCircleOutlined
                        style={{
                          margin: '5px',
                          color: '#FAAD14',
                          fontSize: '16px'
                        }}
                      />{' '}
                      <FormattedMessage id={'generic.warning'} />
                    </>
                  }
                >
                  <FormattedMessage id={'generic.messageCommentRemoval'} />
                </Modal>
              )}
              {noteToEdit && (
                <EditComment content={comment.content} conversationId={conversation.id!} entityId={noteToEdit} setEntityToEdit={setNoteToEdit} refType={refType} refId={refId} />
              )}
            </div>
          );
        })}
      </>
    );
  })
);
