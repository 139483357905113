import React, { SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import { Empty, Menu, Popover, Row, Spin, Tag, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { Text } from '@/common/components/Typography/Text';
import { erpFilterState } from '@/modules/ERP/Common/Recoil/erp.selectors';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';

import './style.css';
import cx from 'classnames';
import { LoadMore } from '@/common/components/SearchOverlay';
import { SearchInput } from 'ui-sesame-components';

enum FilterType {
  TEXT,
  TAG
}

interface FilterProps {
  icon?: React.ReactNode;
  type?: FilterType;
  title: string;
  options: Record<string, string>;
  onChangeCallback?: (option: string) => void;
  recoilNodeId: string;
  defaultValue?: string;
  defaultLabel?: string;
  multiple?: boolean;
  loading?: boolean;
}

const Filter: React.FunctionComponent<FilterProps> = React.memo(
  ({ defaultLabel, onChangeCallback, loading = false, multiple = false, type = FilterType.TEXT, icon = null, title, recoilNodeId, options, defaultValue }) => {
    const noOptions = Object.keys(options).length <= 0;

    const translator = useIntl();

    const debounceTimeout = useRef<NodeJS.Timeout>();
    const [listLoading, setListLoading] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [nodeValue, setNodeValue] = useRecoilState(erpFilterState(recoilNodeId));
    const [searchValue, setSearchValue] = useState<string>();

    const [slice, setSlice] = useState(25);

    const onSearchInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setListLoading(true);

        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
          setSearchValue(e.target.value);
          setListLoading(false);
        }, 500);
      },
      [setSearchValue, setListLoading]
    );

    const onClick = useCallback(
      (option: string) => {
        onChangeCallback && onChangeCallback(option);

        setNodeValue(_previousValue => {
          if (!multiple) {
            return option;
          }

          const previousValue = (_previousValue as string[] | undefined) ?? [];

          const newValue = previousValue.filter(val => val !== option);

          if (newValue.length !== previousValue.length) {
            return newValue;
          }

          newValue.push(option);
          return newValue;
        });
      },
      [multiple, onChangeCallback, setNodeValue]
    );

    const slicedOptions = useMemo(() => {
      return Object.fromEntries(Object.entries(options).slice(0, slice));
    }, [options, slice]);

    const _options = useMemo(() => {
      if (!searchValue) {
        return slicedOptions;
      }

      return Object.fromEntries(Object.entries(options).filter(([val, label]) => (val + label).toLowerCase().trim().includes(searchValue.toLowerCase().trim())));
    }, [options, searchValue, slicedOptions]);

    const menu = useMemo(() => {
      return Object.entries(_options).map(([key, label]) => {
        return { key, label: <Tooltip title={label}>{label}</Tooltip>, onClick: () => onClick(key) };
      });
    }, [_options, onClick]);

    const trigger = useMemo(() => {
      return ['click'] as ['click'];
    }, []);

    const showReset = useMemo(() => {
      if (multiple) {
        return nodeValue && nodeValue.length > 0;
      }
      return Boolean(nodeValue);
    }, [multiple, nodeValue]);

    const onClickReset = useCallback(
      (ev: SyntheticEvent) => {
        ev.stopPropagation();
        setNodeValue(undefined);
      },
      [setNodeValue]
    );

    const valueDisplayed = useMemo(() => {
      if (Array.isArray(nodeValue) && nodeValue.length > 1) {
        const mappedValues = nodeValue.map(val => options[val]);

        const fullValues = mappedValues.join(', ');

        let values = fullValues;

        if (mappedValues.length > 3) {
          values = mappedValues.slice(0, 3).join(', ') + '...';
        }
        return <Tooltip title={fullValues}>{values}</Tooltip>;
      }

      return options[nodeValue as string] ?? (defaultValue ? options[defaultValue] : defaultLabel ?? Object.values(options)[0]);
    }, [options, nodeValue, defaultValue]);

    const showLoadMore = !searchValue && slice < Object.keys(options).length;

    const hide = () => {
      setPopoverOpen(false);
    };
    const handleOpenChange = (newOpen: boolean) => {
      setPopoverOpen(newOpen);
    };
    if (type === FilterType.TAG) {
      return (
        <Popover
          trigger={trigger}
          placement="bottom"
          content={
            <>
              <SearchInput onChange={onSearchInputChange} allowClear placeholder={translator.formatMessage({ id: 'generic.enterAValue' })} />
              <Spin spinning={loading || listLoading}>
                <div className="erp-filters-overlay-menu-wrapper">
                  <Menu selectedKeys={multiple ? (nodeValue as string[]) : [nodeValue as string]} multiple={multiple} items={menu} onClick={hide} />
                  {noOptions && <Empty />}
                  {showLoadMore && <LoadMore slice={slice} setSlice={setSlice} step={25} />}
                </div>
              </Spin>
            </>
          }
          open={popoverOpen}
          onOpenChange={handleOpenChange}
          showArrow={false}
          overlayClassName="erp-filters-overlay-styles"
        >
          <Tag className="erp-tag-filter-style">
            <span className={'erp-tag-filter-style-title'}>{title}</span>&nbsp;&nbsp;&nbsp;
            <strong>{valueDisplayed}</strong>&nbsp;
            <Text type="secondary">
              <DownOutlined className="erp-tag-filter-down-arrow" />
            </Text>
            {showReset && <CloseOutlined onClick={onClickReset} />}
          </Tag>
        </Popover>
      );
    }

    return (
      <Tag className="erp-tag-filter-style">
        &nbsp;&nbsp;
        {title}
        &nbsp;:&nbsp;&nbsp;
        <Popover
          trigger={trigger}
          placement="bottom"
          content={
            <>
              <SearchInput onChange={onSearchInputChange} allowClear placeholder={translator.formatMessage({ id: 'generic.enterAValue' })} />
              <Spin spinning={loading || listLoading}>
                <div className="erp-filters-overlay-menu-wrapper">
                  <Menu selectedKeys={multiple ? (nodeValue as string[]) : [nodeValue as string]} multiple={multiple} items={menu} onClick={hide} />
                  {noOptions && <Empty />}
                  {showLoadMore && <LoadMore slice={slice} setSlice={setSlice} step={25} />}
                </div>
              </Spin>
            </>
          }
          showArrow={false}
          open={popoverOpen}
          onOpenChange={handleOpenChange}
          overlayClassName="erp-filters-overlay-styles"
        >
          <Text strong>
            {valueDisplayed}
            &nbsp;
            <DownOutlined />
          </Text>
        </Popover>
      </Tag>
    );
  }
);

interface FilterRowProps {
  filters: Array<{ key: string; filter: ReturnType<typeof Filter> }>;
  withPrefix?: boolean;
  className?: string;
  additionalContent?: React.ReactNode;
}

const FilterRow: React.FunctionComponent<FilterRowProps> = React.memo(({ additionalContent, withPrefix = true, className, filters }) => {
  return (
    <Row className={cx('erp-activity-filter-wrapper', className)}>
      {withPrefix && (
        <Text className="erp-activity-filter-row-label">
          <FormattedMessage id="generic.filterBy" />
        </Text>
      )}
      {filters.map(rowFilter => (
        <div key={rowFilter.key} className="erp-activity-filter-label">
          {rowFilter.filter}
        </div>
      ))}
      {React.isValidElement(additionalContent) ? additionalContent : null}
    </Row>
  );
});

export { FilterRow, Filter, FilterType };
