import React, { useCallback, useContext, useMemo } from 'react';
import cx from 'classnames';
import { SortOrder } from '@/modules/reporting-v2/types/VisualEngine';
import { TableBody, TableFooter, TableHead, TransposeTable } from './components';
import { IDataTableProps } from './DataTableTypes';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { Field } from '@/modules/reporting-v2/core/Field';
import { FieldName } from '@/modules/reporting-v2/types/Field';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import { VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { DataTableContext } from './DataTableContext';

const DataTable: React.FC<IDataTableProps> = ({ visual, selectedHoldingset, onRowSelect }) => {
  const context = useContext(ReportContext);
  const groupByCount = useMemo(() => visual.groupByColumns?.filter(column => column.isDefault).length, [visual.groupByColumns]);

  const handleColumnSelect = useCallback(
    (field: Field, value: Primitive) => visual.crossFilterEmitter && context.toggleFilter && context.toggleFilter(field, value),
    [context, visual.crossFilterEmitter]
  );

  const handleSort = useCallback(
    (sort?: SortOrder, field?: FieldName, custom?: string[]) => {
      if (!context.refreshVisuals) {
        return;
      }

      const newConfig = { ...visual.rawConfig };
      const code = visual.columns.find(column => column.fieldDataPath === field)?.code;
      if (code) {
        const sortValue = sort === SortOrder.DESC ? 'desc' : 'asc';
        newConfig.columns!.options[code] = {
          ...newConfig.columns!.options[code],
          _sort: !sort ? undefined : sortValue
        };
      }

      context.updatePreferences?.(visual.id, newConfig, visual.version);

      visual.updateSort(context.filters, context.globalFilters, sort, field, custom);
      context.refreshVisuals(visual.id);
    },
    [context, visual]
  );

  const alignmentClasses = useMemo(() => {
    const classList = [];

    if (visual.styles?.table?.textAlign) {
      classList.push(`text-align-${visual.styles?.table?.textAlign}`);
    }
    if (visual.styles?.table?.firstColumnTextAlign) {
      classList.push(`first-column-align-${visual.styles?.table?.firstColumnTextAlign}`);
    }
    if (visual.styles?.table?.lastColumnTextAlign) {
      classList.push(`last-column-align-${visual.styles?.table?.lastColumnTextAlign}`);
    }

    return classList;
  }, [visual.styles?.table]);

  const contextValue = useMemo(() => {
    const columnsWithWidth = visual.columns.filter(col => col.styling?.width).map(col => [col.fieldDataPath, parseInt(col.styling?.width, 10)]);

    return {
      columnSizes: Object.fromEntries(columnsWithWidth)
    };
  }, [visual.columns]);

  return (
    <div
      id={`data-table-${visual.id}`}
      className={cx('report-data-table', `data-table-${visual.component}`, ...alignmentClasses, { transpose: visual.transpose })}
      style={styles.overflow as React.CSSProperties}
      data-group-levels={groupByCount}
    >
      <DataTableContext.Provider value={contextValue}>
        <table
          title={visual.title}
          cellSpacing="0"
          cellPadding="0"
          className={cx({
            zebra: visual.collapsible === false,
            collapsible: visual.collapsible
          })}
        >
          {visual.transpose ? (
            <TransposeTable
              isStatsTable={visual.component === VisualComponent.StatsTable}
              rows={visual.data.rows}
              isVisualLevelHtmlEditOnly={visual.htmlEditableOnly}
              columns={visual.columns}
              handleColumnSelect={handleColumnSelect}
              totals={visual.data.totals || {}}
            />
          ) : (
            <>
              <TableHead
                columns={visual.columns}
                columnsHeaders={visual.columnsHeaders}
                displayCategories={visual.displayCategories}
                sortable={visual.sortable}
                handleSort={handleSort}
                sort={visual.sort}
              />
              <TableBody visual={visual} handleColumnSelect={handleColumnSelect} selectedHoldingset={selectedHoldingset} onRowSelect={onRowSelect} />
              {visual.total && <TableFooter totals={visual.data.totals} columns={visual.columns} displayCategories={visual.displayCategories} />}
            </>
          )}
        </table>
      </DataTableContext.Provider>
    </div>
  );
};

const styles = {
  overflow: { overflowX: 'auto', overflowY: 'auto' }
};

export default DataTable;
