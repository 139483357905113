import { useIntl } from 'react-intl';

export enum FilterType {
  TRANSACTION_TYPE,
  DAYS_PERIOD,
  ENTITY,
  ACTIVITY
}

export enum TransactionTypeFilter {
  ALL = 'ALL',
  REDEMPTION = 'REDEMPTION',
  SUBSCRIPTION = 'SUBSCRIPTION',
  DIVIDEND = 'DIVIDEND',
  PURCHASE = 'PURCHASE'
}

export enum DaysPeriodFilter {
  ALL_TIME = 'ALL_TIME',
  LAST_365 = 'LAST_365',
  LAST_185 = 'LAST_185',
  LAST_90 = 'LAST_90',
  LAST_30 = 'LAST_30',
  LAST_15 = 'LAST_15',
  LAST_7 = 'LAST_7'
}

export enum EntityFilter {
  ALL = 'ALL',
  PORTFOLIO = 'PORTFOLIO',
  ASSET = 'ASSET',
  TASKS = 'TASKS',
  DOCUMENTS = 'DOCUMENTS'
}

export enum ActivityFilter {
  ALL = 'ALL_ACTIVITY',
  PORTFOLIO = 'PORTFOLIO',
  ASSET = 'ASSET',
  DOCUMENT = 'DOCUMENT',
  CONTACT = 'CONTACT',
  NOTE = 'NOTE',
  TASK = 'TASK',
  USER = 'USER',
  LINK = 'LINK'
}

export const periodFilters = (translator: ReturnType<typeof useIntl>) => {
  return {
    [DaysPeriodFilter.ALL_TIME]: translator.formatMessage({ id: 'generic.allTime' }),
    [DaysPeriodFilter.LAST_365]: translator.formatMessage({ id: 'generic.lastXDays' }, { days: 365 }),
    [DaysPeriodFilter.LAST_185]: translator.formatMessage({ id: 'generic.lastXDays' }, { days: 185 }),
    [DaysPeriodFilter.LAST_90]: translator.formatMessage({ id: 'generic.lastXDays' }, { days: 90 }),
    [DaysPeriodFilter.LAST_30]: translator.formatMessage({ id: 'generic.lastXDays' }, { days: 30 }),
    [DaysPeriodFilter.LAST_15]: translator.formatMessage({ id: 'generic.lastXDays' }, { days: 15 }),
    [DaysPeriodFilter.LAST_7]: translator.formatMessage({ id: 'generic.lastXDays' }, { days: 7 })
  };
};

export const activityFilters = (translator: ReturnType<typeof useIntl>, isAssetPage?: boolean): Partial<Record<ActivityFilter, string>> => {
  const filters: Partial<Record<ActivityFilter, string>> = {
    [ActivityFilter.ALL]: translator.formatMessage({ id: 'generic.allActivity' }),
    [ActivityFilter.PORTFOLIO]: translator.formatMessage({ id: 'generic.portfolio' }),
    [ActivityFilter.DOCUMENT]: translator.formatMessage({ id: 'generic.documents' }),
    [ActivityFilter.LINK]: translator.formatMessage({ id: 'generic.link' }),
    [ActivityFilter.NOTE]: translator.formatMessage({ id: 'generic.note' }),
    [ActivityFilter.TASK]: translator.formatMessage({ id: 'generic.task' }),
    [ActivityFilter.USER]: translator.formatMessage({ id: 'generic.user' }),
    [ActivityFilter.CONTACT]: translator.formatMessage({ id: 'generic.contact' })
  };

  if (isAssetPage) {
    filters[ActivityFilter.ASSET] = translator.formatMessage({ id: 'generic.asset' });
  }

  return filters;
};

export const entityFilters = (translator: ReturnType<typeof useIntl>) => {
  return {
    [EntityFilter.ALL]: translator.formatMessage({ id: 'generic.all' }),
    [EntityFilter.PORTFOLIO]: translator.formatMessage({ id: 'generic.portfolio' }),
    [EntityFilter.ASSET]: translator.formatMessage({ id: 'generic.asset' }),
    [EntityFilter.TASKS]: translator.formatMessage({ id: 'generic.tasks' }),
    [EntityFilter.DOCUMENTS]: translator.formatMessage({ id: 'generic.documents' })
  };
};

export const transactionTypeFilters = (translator: ReturnType<typeof useIntl>) => {
  return {
    [TransactionTypeFilter.ALL]: translator.formatMessage({ id: 'generic.all' }),
    [TransactionTypeFilter.REDEMPTION]: translator.formatMessage({
      id: 'generic.redemption'
    }),
    [TransactionTypeFilter.SUBSCRIPTION]: translator.formatMessage({
      id: 'generic.subscription'
    }),
    [TransactionTypeFilter.DIVIDEND]: translator.formatMessage({
      id: 'generic.dividend'
    }),
    [TransactionTypeFilter.PURCHASE]: translator.formatMessage({
      id: 'generic.purchase'
    })
  };
};
