import React from 'react';
import { message } from 'antd';
import { MainProvider } from '@/core';
import './iframe.css';
import './polyfill';
import './datadog';
import { createRoot } from 'react-dom/client';
import 'dayjs/locale/fr';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';

dayjs().locale('en-gb');

type ConsoleMethod = keyof typeof console;

const consoleMethods: ConsoleMethod[] = [
  'assert',
  'clear',
  'count',
  'debug',
  'dir',
  'dirxml',
  'error',
  'group',
  'groupCollapsed',
  'groupEnd',
  'info',
  'log',
  'profile',
  'profileEnd',
  'table',
  'time',
  'timeEnd',
  'timeStamp',
  'trace',
  'warn'
];

const disableLogging = () => {
  consoleMethods.forEach(method => {
    if (console) {
      console[method] = (() => {}) as any;
    }
  });
};

const clearLocalStorageOnNewVersion = async () => {
  const { version } = await import('../package.json');
  const STORAGE_VERSION_KEY = 'STORAGE_VERSION_KEY';
  if (!localStorage.getItem(STORAGE_VERSION_KEY)) {
    localStorage.setItem(STORAGE_VERSION_KEY, version);
  } else if (localStorage.getItem(STORAGE_VERSION_KEY) !== version) {
    localStorage.clear();
    localStorage.setItem(STORAGE_VERSION_KEY, version);
  }
};

if (import.meta.env.VITE_ENV === 'production') {
  disableLogging();
  clearLocalStorageOnNewVersion();
}

message.config({
  maxCount: 3
});

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<MainProvider />);
