import slugify from '@/utils/slugify';

const routeMapper = ({ id, title, path, icon, order, webPage: { template, url, id: webPageId } }) => {
  const sluggedPath = slugify(path);
  return {
    id,
    title,
    icon,
    path: sluggedPath,
    nodes: sluggedPath.split('/').splice(1),
    view: template,
    externalUrl: url,
    order,
    webPageId
  };
};

const buildRoute = (route, tree, duplicates) => {
  let path =
    (['APP_MENU', 'APP_MENU_V3'].includes(route.view)
      ? tree
          .filter(item => {
            let itemMenuPath = item.path.split('/')[1].trim();
            let routeMenuPath = route.path.split('/')[1].trim();

            return !['APP_MENU', 'APP_MENU_V3'].includes(item.view) && itemMenuPath === routeMenuPath && item.nodes.length === 2;
          })
          .reduce((acc, val) => {
            if (acc === null) {
              return val;
            }
            return val.order < acc ? val : acc;
          }, null)?.path
      : route.path) || route.path;

  path = route.view === 'APP_MENU_V3' ? path + '/dashboard' : path;

  return {
    ...route,
    path,
    children: tree
      .filter(child => {
        const isDirectChild = route.id !== child.id && child.path.indexOf(`${route.path}/`) === 0 && child.nodes.length === route.nodes.length + 1;

        if (isDirectChild) {
          if (child.nodes.length >= 3) duplicates.add(child.id);
          return true;
        }

        return false;
      })
      .map(entry => buildRoute(entry, tree, duplicates))
  };
};

export const getMappedSiteTree = data => {
  const tree = data.sort((a, b) => a.order - b.order).map(routeMapper);

  const duplicates = new Set();
  const mappedTree = tree.map(entry => buildRoute(entry, tree, duplicates));

  return mappedTree.filter(({ id }) => !duplicates.has(id));
};
