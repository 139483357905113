import { Button, Modal, Space, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import './style.css';
import { FormattedMessage } from 'react-intl';

type IRefreshModal = {
  isOpen: boolean;
  setParentOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RefreshModal: React.FC<IRefreshModal> = React.memo(({ isOpen, setParentOpen }) => {
  const [open, setOpen] = useState<boolean>(isOpen);
  const handleBackToSaveChanges = useCallback(() => {
    setOpen(false);
    setParentOpen(false);
  }, [setParentOpen]);

  return (
    <Modal footer={false} open={open} closable={false} mask={false} destroyOnClose centered wrapClassName={'.refresh-modal-container'} width={520}>
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Typography.Title level={3} style={{ color: 'var(--ant-color-primary)' }}>
          <FormattedMessage id={'refreshModal.newSesameVersion'} />
        </Typography.Title>
        <Typography.Paragraph style={{ fontSize: 18, textAlign: 'center' }}>
          <span>
            <FormattedMessage id={'refreshModal.weHaveUpdatedSesameForAMoreSeamlessExperience'} />
          </span>
          <br />
          <span>
            <FormattedMessage id={'refreshModal.toAvoidLosingYourChangesPleaseSaveBeforeRefreshing'} />
          </span>
        </Typography.Paragraph>
        <Space>
          <Button onClick={handleBackToSaveChanges}>
            <FormattedMessage id="refreshModal.backToSaveChanges" />
          </Button>
          <Button type="primary" onClick={() => window.location.reload()}>
            <FormattedMessage id="refreshModal.refresh" />
          </Button>
        </Space>
      </Space>
    </Modal>
  );
});
