import { requestApi } from '@/core';
import { deleteEntityLinks } from '@/modules/ERP/Contacts/Api/deleteEntityLinks';
import { EntityLinkType } from '@/common/types/entity/Link';

export const deleteNote = (id: string, noteName?: string) => {
  return requestApi({
    service: 'collaboration',
    method: 'delete',
    url: `/api/notes/${id}`
  }).then(() => {
    return deleteEntityLinks(EntityLinkType.NOTE, id, noteName);
  });
};
