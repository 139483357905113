import { AxisDateTimeLabelFormatsOptions } from 'highcharts';
import { TickRate } from '../VisualSpecificProps';

const getDateTimeFormatFromString = (dateFormat: string): string => {
  let updatedDateFormat = dateFormat;

  const safeReplace = (value: string, searchValue: string, replaceValue: string) => {
    return value.replace(new RegExp(`(?<!\\[)${searchValue}(?!\\])`), replaceValue);
  };

  updatedDateFormat = safeReplace(updatedDateFormat, 'MMMM', '%B'); // "January"
  updatedDateFormat = safeReplace(updatedDateFormat, 'MMM', '%b'); // "Jan"
  updatedDateFormat = safeReplace(updatedDateFormat, 'MM', '%m'); // "01"

  updatedDateFormat = safeReplace(updatedDateFormat, 'YYYY', '%Y'); // "2020"
  updatedDateFormat = safeReplace(updatedDateFormat, 'YY', '%y'); // "20"

  updatedDateFormat = safeReplace(updatedDateFormat, 'dddd', '%A'); // "Sunday"
  updatedDateFormat = safeReplace(updatedDateFormat, 'ddd', '%a'); // "Sun"
  updatedDateFormat = safeReplace(updatedDateFormat, 'DD', '%d'); // "01"

  updatedDateFormat = safeReplace(updatedDateFormat, 'HH', '%k'); // "23h" hours

  updatedDateFormat = safeReplace(updatedDateFormat, 'mm', '%M'); // "40" minutes

  updatedDateFormat = safeReplace(updatedDateFormat, 'Q', '%Q'); // "1" quarters

  updatedDateFormat = updatedDateFormat.replace('[', '');
  updatedDateFormat = updatedDateFormat.replace(']', '');

  return updatedDateFormat;
};

const getDateTimeFormatFromTickRate = (tickRate: TickRate) => {
  switch (tickRate) {
    case TickRate.year_ends:
    case TickRate.year_starts:
      return '%Y';
    case TickRate.quarter_ends:
    case TickRate.quarter_starts:
    case TickRate.month_ends:
    case TickRate.month_starts:
      return '%e. %b %Y';
    default:
      return '%e. %b';
  }
};

export const getDateTimeLabelFormats = (dateFormat: string | undefined, tickRate: TickRate): AxisDateTimeLabelFormatsOptions => {
  let format;

  if (dateFormat) {
    format = getDateTimeFormatFromString(dateFormat);
  }

  if (!dateFormat && tickRate === TickRate.auto) {
    return {};
  }

  if (!dateFormat && tickRate !== TickRate.auto) {
    format = getDateTimeFormatFromTickRate(tickRate);
  }

  return {
    millisecond: format,
    second: format,
    minute: format,
    hour: format,
    day: format,
    week: format,
    month: format,
    year: format
  };
};
