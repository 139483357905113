import React, { Key, useContext } from 'react';
import { Column, OverrideSummability } from '@/modules/reporting-v2/core/Column';
import { EditableValue } from '@/modules/reporting-v2/core/components';
import { ReportingService } from '@/modules/reporting-v2/core/ReportingService';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import { ExcelUtils } from '@/utils/excel';
import { forceCurrency } from '@/modules/reporting-v2/core/forceCurrency';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import { useFixedColumn } from '../hooks/useFixedColumn';
import { useCategoryClass } from '../hooks/useCategoryClass';

interface ITableFooterProps {
  totals?: FlattenObject;
  columns: Column[];
  displayCategories: boolean;
}

const TableFooter: React.FC<ITableFooterProps> = React.memo(({ totals = {}, columns, displayCategories }) => {
  return (
    <tfoot>
      <tr className="total">
        {columns
          .filter(col => col.isDefault)
          .map((column, idx) => (
            <TableFooterCell key={column.id} index={idx} columns={columns} column={column} totals={totals} displayCategories={displayCategories} />
          ))}
      </tr>
    </tfoot>
  );
});

type TableFooterCellProps = {
  column: Column;
  columns: Column[];
  index: number;
  totals: FlattenObject;
  displayCategories: boolean;
};

const TableFooterCell: React.FC<TableFooterCellProps> = ({ column, columns, index, totals, displayCategories }) => {
  const context = useContext(ReportContext);
  const columnLeft = useFixedColumn(column);
  const categoryClass = useCategoryClass(columns, column, index, displayCategories);

  const isFirst = index === 0;

  const metas = ReportingService.metas[column.code ?? column.field.name];
  const hasAggregationMethod = metas?.aggregation?.method;
  const overrideSummability = column.overrideSummability === OverrideSummability.SUMMABLE;
  const hasCalculationWithCalcOnGroup = column.calcExpression && column.calcOnGroup;
  const hideLevel = column.hideAtGroupLevels?.includes('total');
  const hide = hideLevel || (!hasAggregationMethod && !overrideSummability && !hasCalculationWithCalcOnGroup);
  const totalValue = totals[column.fieldDataPath];
  const excelAttrs = ExcelUtils.getTDAttributes(column, totals, isFirst ? 'Total' : totalValue);
  const forcedCurrency = forceCurrency(column);

  const style: React.CSSProperties = {};
  if (columnLeft !== undefined) {
    style.zIndex = 11;
    style.position = 'sticky';
    style.left = columnLeft;
  }

  return (
    <td {...(!hide && excelAttrs)} key={column.id} style={style} className={categoryClass}>
      {isFirst && <span style={styles.totalLabel}>Total</span>}
      {typeof totalValue === 'number' && !hide && (
        <EditableValue key={totalValue as Key} data={totals} defaultValue={totalValue} metas={column} field={column.fieldDataPath} htmlEditOnly>
          {VisualEngine.formatCell(totalValue, column, totals, undefined, forcedCurrency, context.reportConfiguration?.config.numberLocale)}
        </EditableValue>
      )}
    </td>
  );
};

const styles = { totalLabel: { marginRight: '1rem' } };

export default TableFooter;
