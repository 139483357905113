const regexpOperandNumber = '(-?\\d+(\\.\\d+)?)';
const regexpOperandPropName = '\\w+';
const regexpOperand = `(${regexpOperandPropName}|${regexpOperandNumber})`;
const regexpOperator = '[+*/-]';

function getOperand(operandValue: string, getDynamicFieldValue: (key: string) => number) {
  // This will first attempt to parse the hand written integer to calculate with, or try to get the dynamic field value from the map, or just return 0 if nothing is present
  return parseFloat(operandValue) || getDynamicFieldValue(operandValue) || 0;
}

function evaluate(expression: string, getDynamicFieldValue: (key: string) => number): number {
  expression = expression.replace(/\s/gi, '');

  if (!test(expression)) {
    throw new Error('Not valid mathematical expression !');
  }

  const regexExecCalcExpression = new RegExp(`(${regexpOperandNumber}|${regexpOperandPropName}|${regexpOperator})`, 'g');
  let match: RegExpExecArray | null;
  const operands = [];

  while ((match = regexExecCalcExpression.exec(expression)) !== null) {
    operands.push(match[0]);
  }

  if (operands.length === 1) return getDynamicFieldValue(operands[0]);
  else {
    const initialValue = getOperand(operands[0], getDynamicFieldValue);
    const leftOperands = operands.slice(1);
    const leftOperandsPair: [Operator, number][] = leftOperands
      .map((val, index) => {
        let operandPair: [Operator, number] | null;
        operandPair = index % 2 === 0 ? [Operator.from(val), getOperand(leftOperands[index + 1], getDynamicFieldValue)] : null;

        return operandPair;
      })
      .filter(val => val !== null)
      .map(val => val!);

    return calculate(initialValue, leftOperandsPair);
  }
}

function calculate(initialValue: number, leftOperandsPair: Array<[Operator, number]>) {
  return leftOperandsPair.reduce<number>((accumulator: number, operand) => operand[0].operation(accumulator, operand[1]), initialValue);
}

// Validate mathematical expression
function test(expression: string): boolean {
  return new RegExp(`^${regexpOperand}(${regexpOperator}${regexpOperand})*$`, 'g').test(expression);
}

abstract class Operator {
  abstract operation(operand1: number, operand2: number): number;

  abstract unity: number;

  static from(operator: string): Operator {
    switch (operator) {
      case '+':
        return new Addition();
      case '-':
        return new Subtraction();
      case '/':
        return new Division();
      case '*':
        return new Multiplication();
      default:
        throw Error(`Unknown operator : ${operator}`);
    }
  }
}

class Addition extends Operator {
  operation(operand1: number, operand2: number): number {
    return operand1 + operand2;
  }

  unity = 0;
}

class Subtraction extends Operator {
  operation(operand1: number, operand2: number): number {
    return operand1 - operand2;
  }

  unity = 0;
}

class Division extends Operator {
  operation(operand1: number, operand2: number): number {
    if (operand1 === 0) {
      return 0;
    }

    // divide by zero
    if (operand2 === 0) {
      return NaN;
    }

    return operand1 / operand2;
  }

  unity = 1;
}

class Multiplication extends Operator {
  operation(operand1: number, operand2: number): number {
    return operand1 * operand2;
  }

  unity = 1;
}

export default { evaluate, test };
