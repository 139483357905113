import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { ControlOutlined, EditOutlined, EllipsisOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Badge, Button, Dropdown, Flex, message, Modal, Row, Tooltip, Typography } from 'antd';
import { Drawer } from 'ui-sesame-components';
import printPDF from '@/api/printPDF';
import cx from 'classnames';
import { EntityParameters, EntityParametersHandler } from '@/common/types/app/EntityParametersHandler';
import deepmerge from 'deepmerge';
import memoize from 'fast-memoize';
import { Text } from '@/common/components/Typography/Text';
import FileSaver from 'file-saver';
import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { VisualOptions } from '@/modules/reporting-v2/core/components';
import { getChart } from '@/modules/reporting-v2/core/components/Highcharts';
import { Spinner } from '@/modules/reporting-v2/core/components/loaders';
import ReportContext, { IContext } from '@/modules/reporting-v2/core/ReportContext';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { RawConfig, VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { IVisualCoreProps, IVisualCoreState } from '@/modules/reporting-v2/types/VisualCoreTypes';
import { VisualType } from '@/modules/reporting-v2/types/VisualType';
import { Align } from '@/modules/reporting-v2/types/VisualUtils';
import { ExcelUtils } from '@/utils/excel';
import slugify from '@/utils/slugify';
import { PopupHelper } from '@/utils/PopupHelper';
import { findHoldingSetById } from '@/utils/findHoldingSet';
import NoData from '@/modules/reporting-v2/core/components/NoData';
import VisualContext, { IVisualContext } from './VisualContext';
import { EntitiesDrawer } from './EntitiesDrawer';
import { VisualBodyChartWrapper } from '@/common/components/VisualBodyChartWrapper';
import HistoricalChartDateRange from '@/modules/reporting-v2/core/visuals/HistoricalChart/HistoricalChartDateRange';
import { HistoricalChartConfig } from '@/modules/reporting-v2/core/visuals/index';
import { FormattedMessage } from 'react-intl';
import { error } from '@/utils/error';
import '@/modules/TMS/components/snipping/styles.css';
import isChartVisual from '@/modules/reporting-v2/utils/isChartVisual';
import { compareColumnsOfTwoVisuals } from '@/modules/reporting-v2/core/visuals/Visual/utils/compareColumnsOfTwoVisuals';
import { MediumSpin } from '@/common/suspense';
import { sendHubspotCustomEvent } from '@/common/components/HubSpot/HubSpot';

export const initialState = {
  downloading: false,
  loading: false,
  error: undefined,
  optionsDrawerOpened: false,
  chartEditModalOpened: false,
  entitiesDrawerOpened: false,
  zoomIn: false,
  ready: false
};

class Visual extends React.Component<RouteComponentProps & IVisualCoreProps, IVisualCoreState> {
  public static contextType = ReportContext;
  context: Required<IContext>;

  Loader?: React.ComponentClass | React.FC;
  skipTitlePrint?: boolean;
  renderUntilReady?: boolean;

  static merge = (...configs: Object[]) => {
    return deepmerge.all(
      configs.filter(object => object !== null && typeof object !== 'undefined'),
      {
        arrayMerge: (_, array) => array
      }
    );
  };

  constructor(props: RouteComponentProps & IVisualCoreProps) {
    super(props);

    this.state = initialState;
  }

  getSchema(): any {}

  renderChartTable(visual: VisualEngine): any {}

  async componentDidCatch(error: Error) {
    this.setState({
      error: error.message
    });
  }

  componentDidMount() {
    this.onPreLoad();
    // todo: refact: think about a proper solution
    if (this.renderUntilReady && !this.state.ready) {
      setTimeout(() => {
        this.setState({ ready: true });
      }, 0);
    }
  }

  componentDidUpdate(_: IVisualCoreProps, prevState: IVisualCoreState) {
    if (prevState.zoomIn !== this.state.zoomIn) {
      const zoomInClassName = 'zoom-in';
      const visual = document.querySelector('#report-visual-' + this.props.visual.id)!;
      const wrapper = document.getElementById(`report-${this.context.reportId}`)!;

      if (this.state.zoomIn) {
        setTimeout(() => {
          wrapper.classList.add(zoomInClassName);
          visual.classList.add(zoomInClassName);
          this.context.refreshVisuals(this.props.visual.id);
        }, 0);
      } else {
        wrapper.classList.remove(zoomInClassName);
        visual.classList.remove(zoomInClassName);
      }
    }
  }

  toggleExpand = () => {
    this.setState(prevState => ({
      zoomIn: !prevState.zoomIn
    }));
  };

  getConfig() {
    return this.props.visual;
  }

  /**
   * @summary Will be overriden by child visual classes, each with their own implementation
   */
  onPreLoad() {
    // No Op
  }

  onChangeConfig = async (_newConfig: RawConfig) => {
    const oldConfig = this.props.visual.rawConfig;
    const fieldsListIsNotChanged = compareColumnsOfTwoVisuals(this.props.visual.component, oldConfig, _newConfig);
    const dateRangeChanged = oldConfig.dateRange !== _newConfig.dateRange || oldConfig._dateRange !== _newConfig._dateRange;

    if (!_newConfig) {
      return;
    }

    const newConfig = {
      ...(oldConfig || {}),
      ..._newConfig
    };

    if ('styles' in newConfig) {
      // styles should not be saved in preferences
      delete newConfig.styles;
    }

    this.props.visual.setVisualConfig(this.props.visual.getVisualConfigProps(this.props.visual.getVisualMappedConfig(newConfig as RawConfig)));
    this.context.updatePreferences(this.props.visual.id, newConfig, this.props.visual.version);
    this.props.visual.updateData(this.context.filters, this.context.globalFilters);
    if (this.props.visual.displayCategories) this.props.visual.rearrangeCategories(this.props.visual.columns);

    if (fieldsListIsNotChanged && !dateRangeChanged) {
      this.context.refreshVisuals(this.props.visual.id);

      return;
    }

    this.setState({ loading: true });
    try {
      await this.context.triggerVisualRefetch(this.props.visual.id);
    } catch (err) {
      message.error(`Something went wrong when attempting to retrieve data. Reverting to previously selected entities for visual ${this.props.visual.title}.`);
    } finally {
      this.setState({ loading: false });
    }
  };

  resetConfig = async () => {
    await this.context.updatePreferences(this.props.visual.id, {});
    window.location.reload();
  };

  download = (type: string) => {
    const { visual } = this.props;

    const request = () => {
      switch (type) {
        case 'xlsx': {
          const excelVisual = visual.getExcel(this.context);
          if (!excelVisual) {
            error('Error while trying to get excel visual');
          }

          return ExcelUtils.exportFromVisual(excelVisual).then((blob: Blob) => ({
            extension: 'xlsx',
            content: blob
          }));
        }
        case 'pdf': {
          const content = this.context.print({ content: visual.getHTML()! }, document.getElementById(`report-visual-${visual.id}`)?.closest('td') || undefined);
          return printPDF(content).then(pdf => ({
            extension: 'pdf',
            content: new Blob([pdf], { type: 'application/pdf' })
          }));
        }
        case 'svg': {
          return new Promise((resolve, reject) => {
            const chartIndex = document.querySelector(`#report-visual-${visual.id} div[data-highcharts-chart]`)?.getAttribute('data-highcharts-chart');
            if (chartIndex) {
              const chartSVG = getChart(Number(chartIndex))?.getSVG();
              chartSVG
                ? resolve(
                    new Blob([chartSVG as any], {
                      type: 'image/svg+xml;charset=utf-8'
                    })
                  )
                : reject('Requested chart is not rendered in the engine.');
            }
            reject('No chart found.');
          }).then(svg => ({ extension: 'svg', content: svg }));
        }
        default:
      }
    };

    const getFileName = () => {
      let filename = visual.title ? slugify(visual.title.trim()) : visual.id;

      if (this.context.params?.p?.length) {
        this.props.visual.entityOrdering.forEach(entityOrder => {
          const entityId = this.context.params.p?.[entityOrder]?.holdingSetId;
          if (entityId) {
            const tree = this.props.currentUser.holdingSetTree;
            const holdingSetName = findHoldingSetById(tree, entityId)!.name;
            filename += `_${slugify(holdingSetName)}`;
          }
        });
      }

      return filename;
    };

    const requestPromise = request();
    if (!requestPromise) {
      this.context.setExporting(false);
      error('Error while downloading');
    }

    requestPromise
      .then(({ content, extension }) => {
        const filename = getFileName();
        FileSaver.saveAs(content as string, `${filename}.${extension}`, {
          autoBom: false
        });
        this.setState({ loading: false });
      })
      .finally(() => {
        // this.setState({ downloading: false });
        this.context.setExporting(false);
      });
  };

  handleOpenOptionsModal = () =>
    this.setState({
      optionsDrawerOpened: true
    });

  handleCloseOptionsDrawer = () =>
    this.setState({
      optionsDrawerOpened: false
    });

  handleValueChange = (data: FlattenObject, value: Primitive, field: string, rowId?: string) => {
    if (rowId) {
      this.props.visual.mutateGroupByData(data, value as number, field, rowId);
    } else {
      this.props.visual.mutateInitialDataframeAfterEdit(data, value, field, this.context.filters, this.context.globalFilters);
    }

    this.context.refreshVisuals(this.props.visual.id);
  };

  settingsOptions = (hasNoData: boolean | number) => {
    const { component } = this.props.visual;

    return [
      ...(!hasNoData
        ? [
            {
              label: <FormattedMessage id={'generic.exportToExcel'} />,
              key: 'excel-export',
              onClick: () => {
                this.context.setExporting('excel');
                setTimeout(() => {
                  this.download('xlsx');
                }, 0);
              }
            },
            {
              label: <FormattedMessage id={'generic.exportToPdf'} />,
              key: 'pdf-export',
              onClick: () => {
                this.context.setExporting('pdf');
                setTimeout(() => {
                  this.download('pdf');
                }, 0);
              }
            },
            isChartVisual(component)
              ? {
                  label: <FormattedMessage id={'generic.exportToSvg'} />,
                  key: 'svg-export',
                  onClick: () => {
                    this.download('svg');
                  }
                }
              : null
          ]
        : [null]),

      this.context.reportConfiguration.config.hideVisualSettings
        ? null
        : {
            label: <FormattedMessage id={'generic.settings'} />,
            key: 'options',
            onClick: this.handleOpenOptionsModal
          }
    ];
  };

  openEntitiesDrawer = () => {
    this.setState({ entitiesDrawerOpened: true });
  };

  closeEntitiesDrawer = () => {
    this.setState({ entitiesDrawerOpened: false });
  };

  toggleEditMode = () => {
    if (this.context.editMode === this.props.visual.id) {
      this.context.deactivateEditMode();
    } else {
      if (isChartVisual(this.props.visual.component)) {
        this.setState({ chartEditModalOpened: true });
      }
      this.context.activateEditMode(this.props.visual.id);
    }
  };

  renderEditModeInformation = () => {
    if (this.context.editMode) {
      return (
        <strong>
          <FormattedMessage id={'report.body.visual.deactivateEditMode'} />
        </strong>
      );
    }

    return (
      <>
        <strong>
          <FormattedMessage id={'report.body.visual.activateEditMode'} />
        </strong>
        <br />
        <br />
        <p>
          <FormattedMessage id={'report.body.visual.thisSettingAllowsYouToModifyValuesAcrossThePage'} />
        </p>
      </>
    );
  };

  renderInteractivity() {
    const { data, columns } = this.props.visual;
    const hasNoData = columns?.length && data.rows.length === 0;

    let icon = this.state.zoomIn ? <ZoomOutOutlined /> : <ZoomInOutlined />;

    return [
      <Tooltip key="zoom" title={this.state.zoomIn ? 'Zoom Out' : 'Zoom In'} placement={this.state.zoomIn ? 'bottom' : 'top'}>
        <Button size="large" onClick={this.toggleExpand} type="link" icon={icon} data-testid="visual-interactivity-zoom" />
      </Tooltip>,
      <Tooltip key="edit" title={this.renderEditModeInformation()} placement={this.state.zoomIn ? 'bottom' : 'top'}>
        <Button
          onClick={this.toggleEditMode}
          className={this.context.editMode === this.props.visual.id ? 'animate-edit-pen-info' : undefined}
          type="link"
          size="large"
          icon={<EditOutlined />}
          style={{ color: this.context.editMode ? 'var(--ant-color-primary)' : undefined }}
          data-testid="visual-interactivity-edit"
        />
      </Tooltip>,
      <Tooltip key="entities" title={<FormattedMessage id={'report.body.selectedEntities'} />} placement={this.state.zoomIn ? 'bottom' : 'top'}>
        {this.props.multiEntityFeatures && (
          <Badge count={this.props.visual.entityOrdering.length} style={{ backgroundColor: 'var(--ant-color-primary)', top: 7, right: 7 }}>
            <Button
              onClick={this.openEntitiesDrawer}
              size="large"
              className="visual-entity-selector-hover-icon"
              type="link"
              icon={<ControlOutlined />}
              data-testid="visual-interactivity-multi-entity"
            />
          </Badge>
        )}
      </Tooltip>,
      <Dropdown key="dropdown" placement="bottomRight" menu={{ items: this.settingsOptions(hasNoData) }}>
        <Button key="exports" type="link" size="large" icon={<EllipsisOutlined />} data-testid="visual-interactivity-settings" />
      </Dropdown>,
      <Drawer key="optionDrawer" title={<FormattedMessage id={'generic.edit'} />} open={this.state.optionsDrawerOpened} onClose={this.handleCloseOptionsDrawer}>
        <VisualOptions
          values={this.props.visual.rawConfig}
          schema={this.getSchema()}
          onSubmit={this.onChangeConfig}
          onReset={this.resetConfig}
          isFetching={this.state.loading}
          config={this.getConfig()}
          fields={this.props.visual.getAllUsedFields().map(field => field.name)}
        />
      </Drawer>,
      <EntitiesDrawer
        key="entities-drawer"
        title={
          <>
            <Typography.Title level={4}>{<FormattedMessage id={'generic.availableEntities'} />}</Typography.Title>
            <Text>{this.getDrawerTitleText()}</Text>
          </>
        }
        entityOrdering={this.props.visual.entityOrdering}
        params={this.context.params?.p ?? []}
        onChange={this.handleChangeOrdering}
        historicalEnabled={this.props.visual.historicalConfig.enabled}
        open={this.state.entitiesDrawerOpened}
        onClose={this.closeEntitiesDrawer}
      />
    ];
  }

  private getDrawerTitleText() {
    let drawerTitleText = '';
    const visualTitle = this.props.visual.title;
    const reportTitle = this.context.title;

    if (reportTitle) {
      drawerTitleText += `${reportTitle}`;

      if (visualTitle) {
        drawerTitleText += ` - `;
      }
    }

    if (visualTitle) {
      drawerTitleText += `${visualTitle}`;
    }

    return drawerTitleText;
  }

  private isMultiEntity() {
    const isMultiEntityReport = this.props.multiEntityFeatures;

    if (this.props.visual.component === VisualComponent.CallOut) {
      return isMultiEntityReport && this.props.visual.columns.filter(col => col.isDefault).length <= 1;
    }

    return isMultiEntityReport && this.props.visual.multiEntity;
  }

  handleChangeOrdering = async (paramHoldingSetId: number) => {
    this.setState({ loading: true });

    const oldOrdering = this.props.visual.entityOrdering;

    const param = (this.context.params.p ?? []).findIndex((param: EntityParameters) => param.holdingSetId === paramHoldingSetId);

    this.props.visual.setOrdering(param, this.isMultiEntity());

    try {
      await this.context.triggerVisualRefetch(this.props.visual.id);
    } catch (err) {
      message.error(`Something went wrong when attempting to retrieve data. Reverting to previously selected entities for visual ${this.props.visual.title}.`);

      this.props.visual.entityOrdering = oldOrdering;
      this.setState({ loading: false });
      return;
    }

    this.props.visual.rawConfig.entity = this.props.visual.entityOrdering;

    this.setState({ loading: false });

    this.context.updatePreferences(this.props.visual.id, this.props.visual.rawConfig, this.props.visual.version);
  };

  renderTitle(): string | ReactElement | null {
    if (!this.props.visual.title) {
      return <span>&nbsp;</span>;
    }

    return this.props.visual.title;
  }

  protected toggleEntityColumnDisplay() {
    if (this.props.visual.multiEntityFeatures) {
      const entityColumn = this.props.visual.columns.find(col => col.field.name === 'holdingset.name')!;

      if (this.props.visual.entityOrdering.length <= 1) {
        entityColumn.isDefault = false;
      } else {
        entityColumn.isDefault = true;
      }
    }
  }

  renderBody(): React.ReactNode | ReactElement | null {
    return null;
  }

  render() {
    const { visual } = this.props;

    if (!visual) {
      return null;
    }

    if (this.state.loading) {
      return (
        <Flex justify="center" align="center" style={{ height: '100%' }}>
          {MediumSpin}
        </Flex>
      );
    }

    return this.props.onRender(visual, !this.renderUntilReady || this.state.ready ? this.renderVisual() : this.renderLoader());
  }

  getMemoizedVisualContext = memoize(
    (ctx: IVisualContext) => {
      return ctx;
    },
    {
      //@ts-expect-error Serializer fn isn't generic..
      serializer: (ctx: IVisualContext) => {
        const { onValueChange, ...restCtx } = ctx;

        return JSON.stringify(restCtx);
      }
    }
  );

  renderLoader() {
    return <div className="visual-loader">{this.Loader ? <this.Loader /> : <Spinner className="" />}</div>;
  }

  getVisualBody(hasNoData: boolean) {
    const { visual } = this.props;

    if (visual.component === VisualComponent.SelectBox || visual.component === VisualComponent.CallOut) {
      return this.renderBody();
    }

    if (hasNoData || this.props.loading) {
      const isFilterOrWidth = [VisualType.FILTER, VisualType.WIDGET].includes(visual.type);
      return isFilterOrWidth ? null : <NoData />;
    }

    return this.renderBody();
  }

  renderVisual() {
    const { visual } = this.props;

    const interactivity = this.renderInteractivity();
    const displayInteractivity = !this.context.editMode || this.context.editMode === this.props.visual.id;

    const hasPopups = visual?.columns && visual?.columns?.some(col => PopupHelper.IsPopupField(col.field.name));
    const hasNoData = visual.columns?.length > 0 && visual.data.rows.length === 0 && !hasPopups && visual.component !== VisualComponent.TextImage;

    const ctx = this.getMemoizedVisualContext({
      id: visual.id,
      onValueChange: this.handleValueChange
    });

    setTimeout(() => {
      // When visual is loaded, we need to check if it has no data and update context.updateVisualsHasNoData array
      // If all visuals on the current page have no data, we will hide this page.
      this.context.updateVisualsHasNoData(this.props.visual.id, Boolean(hasNoData));
    }, 0);

    const VisualBody = this.getVisualBody(hasNoData);

    const extraMargin = hasNoData || visual.rawConfig.legendPosition === 'bottom';

    if (this.state.error) {
      return (
        <div className="report-error">
          <FormattedMessage id={'report.modal.somethingWentWrongPleaseContactAnAdministrator'} />
        </div>
      );
    }

    return (
      <VisualContext.Provider value={ctx}>
        <div
          onClick={() => {
            const param = EntityParametersHandler.retrieveParamsList(this.props.currentUser)[0];

            const hset = param?.holdingSetId ? findHoldingSetById(this.props.currentUser.holdingSetTree, param.holdingSetId) : undefined;

            sendHubspotCustomEvent('pe45390240_interacts_with_a_visual', {
              visualtitle: this.props.visual.title,
              pagetitle: this.context.siteTreeEntry?.title,
              entityname: hset?.name,
              date: param.date
            });
          }}
          className={cx('visual-wrapper', {
            'no-print': hasNoData && this.props.visual.hideInPdfIfNoData,
            'low-opacity': this.state.loading,
            'high-opacity': !this.state.loading,
            'blur-edit-mode': this.context.editMode && this.props.visual.id !== this.context.editMode,
            'v-align-center': this.props.visual.visualPositionAlignment === Align.MIDDLE
          })}
        >
          {(this.state.downloading || this.props.loading) && (
            <div className="visual-overlay">
              <Spinner className="" />
            </div>
          )}
          {
            <>
              {interactivity && displayInteractivity && (
                <div
                  data-testid="visual-interactivity"
                  className={`visual-interactivity ${this.context.editMode === this.props.visual.id && 'visual-force-interactivity-display'}`}
                  style={cssStyles.nonePointerEvents as React.CSSProperties}
                >
                  <Row justify="end">
                    <div style={cssStyles.autoPointerEvents as React.CSSProperties}>{interactivity}</div>
                  </Row>
                </div>
              )}

              <Modal
                className="chart-edition-modal"
                title={this.renderTitle()}
                width={800}
                afterClose={this.context.deactivateEditMode}
                onOk={() => this.setState({ chartEditModalOpened: false })}
                open={isChartVisual(visual.component) && this.state.chartEditModalOpened}
                onCancel={() => this.setState({ chartEditModalOpened: false })}
                destroyOnClose
              >
                <VisualBodyChartWrapper VisualBody={VisualBody} />
                <div className="table-wrapper">{this.renderChartTable(visual)}</div>
              </Modal>

              {!visual.hiddenTitle && visual.title && this.renderTitle() && visual?.type !== VisualType.FILTER && (
                <div
                  data-testid="visual-title"
                  className={cx('visual-title nowrap', {
                    'no-print': this.skipTitlePrint
                  })}
                >
                  <label>{this.renderTitle()}</label>
                </div>
              )}
              {visual.component === VisualComponent.HistoricalChart && !this.props.loading && (
                <HistoricalChartDateRange extraMargin={extraMargin} visual={visual as HistoricalChartConfig} />
              )}
              {VisualBody}
            </>
          }
        </div>
      </VisualContext.Provider>
    );
  }
}

const cssStyles = {
  autoPointerEvents: { pointerEvents: 'auto' },
  nonePointerEvents: { pointerEvents: 'none' }
};

export default Visual;
