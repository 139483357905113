import { requestApi } from '@/core';
import { ApiService } from 'ui-sesame-components';
import { PrivateIssuerDto } from '../types';

export const getPrivateIssuerById = async (pathParams: { id: number }, opts?: { errors?: boolean }): Promise<PrivateIssuerDto> => {
  return requestApi({
    ...opts,
    url: `/api/private-issuers/${pathParams.id}`,
    service: ApiService.ASSET,
    method: 'GET'
  }).then(({ data }) => data);
};
