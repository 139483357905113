import { selector } from 'recoil';
import getFlattenHoldingSetTree from '@/api/getFlattenHoldingSetTree';
import { HoldingSetType } from '@/common/types/entity/HoldingSetType';

export const vehiclesSelector = selector({
  key: 'ownership-vehicles-selector',
  get: async () => {
    const tree = await getFlattenHoldingSetTree();

    return tree.filter((element: any) => element.type === HoldingSetType.VEHICLE);
  }
});
