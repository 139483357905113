import { selectorFamily } from 'recoil';
import getHoldingSetTreeCheckpoints from '@/api/getHoldingSetTreeCheckpoints';

interface ValidatedDate {
  date: string;
}

export const holdingSetVerifiedDates = selectorFamily({
  key: 'holding-set-verified-dates-selector',
  get: id => () => {
    return getHoldingSetTreeCheckpoints(id);
  }
});

const holdingSetValidatedDatesSelector = selectorFamily<Array<string>, number | undefined>({
  key: 'holding-set-validated-dates-selector',
  get:
    id =>
    ({ get }) => {
      if (!id) {
        return [];
      }
      return get(holdingSetVerifiedDates(id)).map((date: ValidatedDate) => date.date);
    }
});

export { holdingSetValidatedDatesSelector };
