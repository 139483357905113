import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import HighCharts from '@/modules/reporting-v2/core/components/Highcharts';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import { findHoldingSetById } from '@/utils/findHoldingSet';
import { config } from './config';
import schema from './schema.json';
import RawBarChartConfig from './types';
import BarChartConfig from './BarchartConfig';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import { Sorting } from './VisualSpecificProps';

class BarChart extends Visual {
  Loader = Loaders.Chart;
  renderUntilReady = true;

  static configMapper(visualConfig: RawBarChartConfig) {
    const { group1, group2, metric, sortBy, sorting, ...rest } = visualConfig;

    const group = {
      options: { ...(group1.options || {}), ...(group2.options || {}) },
      filters: { ...(group1.filters || {}), ...(group2.filters || {}) },
      defaultColumns: [...new Set(group1.defaultColumns.concat(group2.defaultColumns))],
      columns: [...new Set(group1.columns.concat(group2.columns))]
    };

    const columns =
      sorting && sortBy?.defaultColumns
        ? {
            ...metric,
            ...{
              columns: [...new Set(metric.columns.concat(sortBy.defaultColumns))]
            }
          }
        : metric;

    return {
      ...rest,
      columns: mapVisualConfigColumns(columns),
      group: mapVisualConfigColumns(group),
      ...(sorting && (typeof sortBy?.defaultColumns[0] === 'string' || typeof metric.defaultColumns[0] === 'string')
        ? {
            sort: {
              field: sortBy?.defaultColumns[0] || metric.defaultColumns[0],
              order: sorting === Sorting.asc ? Sorting.asc : Sorting.desc
            }
          }
        : {})
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as BarChartConfig;
  }

  getSchema() {
    return schema;
  }

  renderChartTable(_visual: VisualEngine) {
    const rows = [];
    const tree = this.props.currentUser.holdingSetTree;

    for (const [entityId, entityRows] of _visual.data.rowsByEntity) {
      const holdingSet = findHoldingSetById(tree, entityId);
      for (const row of entityRows) {
        const group = `${holdingSet?.name} - ${(row as RowGroup).group}`;
        rows.push({
          ...row,
          group,
          cells: [group, row.cells[0]]
        });
      }
    }

    const PLACEHOLDER_COLUMN = {
      field: {
        name: ''
      },
      isDefault: true,
      visible: true,
      headerConfig: {
        displayName: '',
        category: ''
      },
      formatting: {
        type: 'string'
      },
      countDistinctOnAggregation: false,
      conditionalValueDisplay: [],
      conditionalRowDisplay: [],
      hyperlinks: {},
      fieldDataPath: '',
      id: 'placeholder'
    };

    const beautifiedVisual = {
      ..._visual,
      columns: [PLACEHOLDER_COLUMN, ..._visual.columns],
      data: {
        ..._visual.data,
        rows
      },
      subTotal: true
    };

    return <DataTable visual={beautifiedVisual as IDataTableProps['visual']} />;
  }

  renderBody() {
    return <HighCharts fullSize={this.state.zoomIn} visual={this.props.visual} />;
  }
}

export default BarChart;
