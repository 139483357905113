import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedSingleValueConfig } from '..';
import { SingleValueConfigMapped } from './types';

export default class SingleValueConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as SingleValueConfigMapped));
  }

  getVisualConfigProps(config: SingleValueConfigMapped): ExtendedSingleValueConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      metadata: config.metadata,
      logo: config.logo,
      bgAlign: config.bgAlign,
      emptyValue: config.emptyValue,
      dateFormat: config.dateFormat
    });
  }

  setVisualConfig(config: ExtendedSingleValueConfig) {
    super.setVisualConfig(config);
    this.metadata = config.metadata;
    this.logo = config.logo;
    this.bgAlign = config.bgAlign;
    this.emptyValue = config.emptyValue;
    this.total = true;
    this.dateFormat = config.dateFormat;
  }

  metadata?: string;
  logo?: boolean;
  bgAlign?: string;
  emptyValue?: string;
  dateFormat?: string;
}
