import { nanoid } from 'nanoid';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import cx from 'classnames';
import ReactGridLayout from 'react-grid-layout';
import { ReportOrientation } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { REPORT_BUILDER_CANVAS_SIZE } from '@/modules/report-builder/hooks/useReportBuilder';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './style.css';

export const DEFAULT_REPORT_GRID_COLS = 12;

interface IProps extends ReactGridLayout.ReactGridLayoutProps {
  heightRatio: number;
  children: React.ReactNode;
  autoWidth?: boolean;
  autoHeight?: boolean;
  showOutOfCanvasOverlay?: boolean;
  orientation: ReportOrientation;
  scale?: number;
}

const RGL_CLASSNAME_PREFIX = 'react-grid-layout-';

const ReportGrid = ({
  showOutOfCanvasOverlay = false,
  margin = [0, 0],
  cols = DEFAULT_REPORT_GRID_COLS,
  autoWidth = false,
  heightRatio,
  autoHeight = false,
  orientation,
  scale = 1,
  children,
  ...props
}: IProps) => {
  const [id] = useState(nanoid(3));
  const [parentWrapper, setParentWrapper] = useState<HTMLElement | null>();
  const wrapper = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>();
  const height = useMemo(() => width! * heightRatio, [width, heightRatio]);
  const style = useMemo(() => (autoHeight ? { width, minHeight: height } : { width }), [autoHeight, width, height]);

  useEffect(() => {
    if (wrapper.current) {
      setParentWrapper(wrapper.current.parentElement);
    }
  }, [wrapper]);

  useEffect(() => {
    if (autoWidth && parentWrapper) {
      const applyWidth = () => {
        setWidth(parentWrapper.offsetWidth);
      };

      applyWidth();

      window.addEventListener('resize', applyWidth);
      return () => {
        window.removeEventListener('resize', applyWidth);
      };
    }
  }, [autoWidth, id, parentWrapper]);

  useEffect(() => {
    if (!autoWidth && props.width) {
      setWidth(props.width);
    }
  }, [autoWidth, props.width]);

  if (autoWidth && !width) {
    // to be able to get the container width value
    return (
      <div ref={wrapper} className={RGL_CLASSNAME_PREFIX + id}>
        {children}
      </div>
    );
  }

  return (
    <ReactGridLayout
      containerPadding={[0, 0]}
      margin={margin}
      cols={cols * scale}
      {...props}
      className={cx(props.className, RGL_CLASSNAME_PREFIX + id)}
      width={width}
      rowHeight={Math.floor(height / REPORT_BUILDER_CANVAS_SIZE[orientation].rows) / scale - margin[0]}
      style={{ ...style, '--ratio': `${heightRatio}%` } as any}
      compactType={null}
    >
      {children}
    </ReactGridLayout>
  );
};

export default ReportGrid;
