import { UUID } from '@/common/types/types';
import { AuthHeader, AuthSessionStorageManager } from '@/common/auth/storage/AuthSessionStorageManager';
import { redirectTo50xOnRequestError, shouldRedirectTo50x } from '@/core/redirectTo50xOnRequestError';
import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { auth0Utils } from '@/core/Auth0Utils';

const failedLoginAttemptKey = 'failed_login_attempt_1';

export interface LoggedUser {
  login: string;
  availableAccountIds: Array<UUID>;
  accountId: UUID;
  userId: UUID;
  availableSpaceIds: Array<UUID>;
  isSuperAdmin: boolean;
  locale: string;
  spaceId: UUID;
  roleId: UUID;
  userIds: Array<UUID>;
  holdingSetIds: Array<number>;
  permissions: Array<string>;
  token: string;
  expiresAt: string;
  acting: boolean;
  impersonating: boolean;
  internal: boolean;
}

export default (): Promise<LoggedUser> => {
  const currentCount = getFailedLoginAttemptKey();

  if (currentCount >= 2) {
    clearFailedLoginData();
    return Promise.reject({ failed: true });
  }

  AuthSessionStorageManager.readPersisted();
  AuthSessionStorageManager.readSessionInitiation();

  return requestLogin().catch(handleLoginError).then(handleLoginSuccess);
};

const getFailedLoginAttemptKey = (): number => {
  const currentCount = Number(sessionStorage.getItem(failedLoginAttemptKey));
  return Number.isInteger(currentCount) ? currentCount : 0;
};

const clearFailedLoginData = (): void => {
  sessionStorage.removeItem(failedLoginAttemptKey);
};

const requestLogin = (): Promise<AxiosResponse<LoggedUser>> => {
  return requestApi({
    url: '/api/login',
    service: 'gateway',
    method: 'get'
  });
};

const handleLoginError = (error: any): Promise<undefined> => {
  console.log(error.message);

  if (shouldRedirectTo50x(error)) {
    redirectTo50xOnRequestError();
    return Promise.resolve(undefined);
  }

  updateFailedLoginCount();
  clearAuthStorage();
  auth0Utils.logout!();
  window.location.reload();

  return Promise.resolve(undefined);
};

const handleLoginSuccess = (response: AxiosResponse<LoggedUser> | undefined): LoggedUser => {
  if (response) {
    const user = response.data;
    clearFailedLoginData();

    if (!user.acting && !user.impersonating) {
      AuthSessionStorageManager.persist(user.login, {
        [AuthHeader.ACCOUNT_ID]: user.accountId,
        [AuthHeader.SPACE_ID]: user.spaceId
      });

      if (!AuthSessionStorageManager.hasHeader(AuthHeader.USER_LOGIN)) {
        AuthSessionStorageManager.setHeader(AuthHeader.USER_LOGIN, user.login);
      }
    }

    updateAuthSessionStorage(user);

    return user;
  }
};

const updateFailedLoginCount = (): void => {
  const count = getFailedLoginAttemptKey();
  sessionStorage.setItem(failedLoginAttemptKey, String(count + 1));
};

const clearAuthStorage = (): void => {
  AuthSessionStorageManager.clearHeaderStorage();
};

const updateAuthSessionStorage = (user: LoggedUser): void => {
  if (!AuthSessionStorageManager.hasHeader(AuthHeader.ACCOUNT_ID)) {
    AuthSessionStorageManager.setHeader(AuthHeader.ACCOUNT_ID, user.accountId);
  }

  if (!AuthSessionStorageManager.hasHeader(AuthHeader.SPACE_ID)) {
    AuthSessionStorageManager.setHeader(AuthHeader.SPACE_ID, user.spaceId);
  }

  if (user.acting && !AuthSessionStorageManager.hasHeader(AuthHeader.ACT_SPACE_ID)) {
    AuthSessionStorageManager.setHeader(AuthHeader.ACT_SPACE_ID, user.spaceId);
  }
};
