import { selector, selectorFamily } from 'recoil';
import WebPage from '@/common/types/entity/WebPage';
import getWebPageById from '@/api/getWebPageById';
import { loggedUserSelector } from '@/common/auth/recoil/user.selector';

export const webPageByIdSelector = selectorFamily<WebPage, number>({
  key: 'webpage-by-id-selector',
  get: id => async () => {
    const webpage = await getWebPageById(id);

    return webpage!;
  }
});

export const noPermissionsSelector = selector<boolean>({
  key: 'no-permissions-state-selector',
  get: ({ get }) => {
    const user = get(loggedUserSelector);

    return user.availableSpaceIds?.length <= 0;
  }
});
