import React, { useMemo } from 'react';
import { Column } from '@/modules/reporting-v2/core/Column';
import { Button, Space } from 'antd';
import { DataTableVisualConfig } from '@/modules/reporting-v2/types/Configs';
import { EllipsisOutlined } from '@ant-design/icons';
import { Field } from '@/modules/reporting-v2/core/Field';
import { FieldName } from '@/modules/reporting-v2/types/Field';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { Links } from '@/modules/Ownership/components';
import { RowGroup as RowGroupType } from '@/modules/reporting-v2/types/VisualEngine';
import { SubtotalCell } from './SubtotalCell';
import { useRowGroup, useSubtotalRow } from '../hooks';
import DataBars from './DataBars';
import DeepRows from './DeepRows';
import GroupContent from './GroupContent';
import Hyperlinks from './Hyperlinks';
import { useFixedColumn } from '../hooks/useFixedColumn';
import { useCategoryClass } from '@/modules/reporting-v2/core/components/DataTable/hooks/useCategoryClass';
import { LevelIndicators } from '@/modules/reporting-v2/core/components/DataTable/components/LevelIndicator';
import { VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';

interface IProps {
  visual: DataTableVisualConfig;
  level: number;
  maxRangeValues?: Record<FieldName, number>;
  groupRowsMaxRangeValues?: Record<FieldName, number>;
  active?: boolean;
  handleColumnSelect: (field: Field, value: Primitive) => void;
  selectedHoldingset?: number;
  onRowSelect?: (holdingSetId: number, userId: string, data: FlattenObject) => void;
  onRowExpand?: (group: string, expanded: boolean) => void;
  rowIndex: number;
  overrideExpansion?: boolean;
  groupTrace: string;
  row: RowGroupType;
  isLastChildRow: boolean;
  parentRowLastChildRow: number[];
}

const RowGroup: React.FC<IProps> = React.memo(
  ({
    rowIndex,
    handleColumnSelect,
    visual,
    level,
    active,
    selectedHoldingset,
    onRowSelect,
    maxRangeValues,
    groupRowsMaxRangeValues,
    overrideExpansion,
    groupTrace,
    row,
    isLastChildRow,
    parentRowLastChildRow
  }) => {
    const maxGroupLevel = visual.groupByColumns.filter(col => col.isDefault).length - 1;
    const { onExpand, tableRowStyles, className, _onRowSelect, _overrideExpansion, expand, rowStyle } = useRowGroup(
      row.group as string,
      row.data,
      visual.collapsible!,
      visual.id,
      groupTrace,
      maxGroupLevel,
      level,
      active,
      selectedHoldingset,
      onRowSelect,
      overrideExpansion,
      row.fullRow
    );

    const collapsible = useMemo(
      () => visual.collapsible! || level < visual.groupByColumns.filter(column => column.isDefault).length - 1,
      [level, visual.collapsible, visual.groupByColumns]
    );

    const renderSubtotalColumns = () => {
      return (
        <SubtotalColumns
          collapsible={collapsible}
          handleColumnSelect={handleColumnSelect}
          onExpand={onExpand}
          row={row}
          rowIndex={rowIndex}
          visual={visual}
          maxRangeValues={maxRangeValues}
          level={level}
          isLastChildRow={isLastChildRow}
          parentRowLastChildRow={parentRowLastChildRow}
        />
      );
    };

    return (
      <>
        <tr
          className={className}
          style={
            {
              '--groupLevel': level,
              '--children-length': row.rows.length || 0,
              ...tableRowStyles
            } as React.CSSProperties
          }
          onClick={_onRowSelect}
          data-group-level={level}
          data-children-length={row.rows.length || 0}
        >
          {row.fullRow && <td colSpan={visual.columns.length}>{row.group}</td>}
          {!row.fullRow && visual.subTotal && renderSubtotalColumns()}
          {!row.fullRow && !visual.subTotal && (
            <td style={{ textAlign: 'left' }} colSpan={visual.columns.length}>
              <GroupContent collapsible={visual.collapsible!} data={row.data} group={row.group as string} hasChildren={row.rows?.length !== 0} onExpand={onExpand} />
            </td>
          )}
        </tr>
        {collapsible && (
          <DeepRows
            visual={visual}
            expand={expand}
            overrideExpansion={_overrideExpansion}
            level={level + 1}
            childrenRows={row.rows}
            maxRangeValues={groupRowsMaxRangeValues}
            rowStyle={rowStyle}
            selectedHoldingset={selectedHoldingset}
            handleColumnSelect={handleColumnSelect}
            onRowSelect={onRowSelect}
            groupTrace={groupTrace + row.group}
            parentRowLastChildRow={isLastChildRow && level > 0 ? [...parentRowLastChildRow, level] : parentRowLastChildRow}
          />
        )}
        {row.fullRow && visual.subTotal && <tr>{renderSubtotalColumns()}</tr>}
      </>
    );
  }
);

type SubtotalColumnsProps = {
  visual: DataTableVisualConfig;
  maxRangeValues?: Record<FieldName, number>;
  handleColumnSelect: (field: Field, value: Primitive) => void;
  onExpand: (e: any) => void;
  rowIndex: number;
  row: RowGroupType;
  collapsible: boolean;
  level: number;
  isLastChildRow: boolean;
  parentRowLastChildRow: number[];
};

const SubtotalColumns: React.FC<SubtotalColumnsProps> = ({
  visual,
  row,
  collapsible,
  handleColumnSelect,
  maxRangeValues,
  onExpand,
  rowIndex,
  level,
  isLastChildRow,
  parentRowLastChildRow
}) => {
  return (
    <>
      {visual.columns
        .filter(col => col.isDefault)
        .map((col, index) => (
          <SubtotalColumn
            row={row}
            col={col}
            collapsible={collapsible}
            handleColumnSelect={handleColumnSelect}
            index={index}
            key={col.id}
            maxRangeValues={maxRangeValues}
            onExpand={onExpand}
            rowIndex={rowIndex}
            visual={visual}
            level={level}
            isLastChildRow={isLastChildRow}
            parentRowLastChildRow={parentRowLastChildRow}
          />
        ))}
    </>
  );
};

interface SubtotalRowProps {
  col: Column;
  collapsible: boolean;
  handleColumnSelect: IProps['handleColumnSelect'];
  index: number;
  maxRangeValues: IProps['maxRangeValues'];
  onExpand: (e: any) => void;
  rowIndex: IProps['rowIndex'];
  visual: IProps['visual'];
  level: number;
  row: IProps['row'];
  isLastChildRow: boolean;
  parentRowLastChildRow: number[];
}

const SubtotalColumn: React.FC<SubtotalRowProps> = React.memo(
  ({ col, collapsible, handleColumnSelect, index, maxRangeValues, onExpand, rowIndex, visual, level, row, isLastChildRow, parentRowLastChildRow }) => {
    const { excelAttrs, isGroupable, links, hideSubtotal, chartStyle } = useSubtotalRow(visual, col, row.data, row.cells, rowIndex, index, row.group as string, level);
    const columnLeft = useFixedColumn(col);
    const categoryClass = useCategoryClass(visual.columns, col, index, visual.displayCategories);

    if (col.hideSubtotal || col.hideAtGroupLevels?.includes(String(level))) {
      delete (excelAttrs as Record<string, any>).v;
    }

    const isFirstColumn = index === 0;
    const isDashboardTable = visual.component === VisualComponent.DashboardTable;
    const useLevelIndicator = isDashboardTable && isFirstColumn && level > 0;

    const styles = useMemo(() => {
      const styles: React.CSSProperties = {};

      if (col.dataBars) {
        styles.textAlign = 'right';
      }
      if (isFirstColumn) {
        styles.textAlign = 'left';
      }

      if (col.styling?.fixed) {
        styles.left = columnLeft;
        styles.zIndex = 2;
        styles.position = 'sticky';
      }
      return styles;
    }, [col.dataBars, col.styling?.fixed, isFirstColumn, columnLeft]);

    return (
      <td key={col.id} style={styles} {...excelAttrs} className={categoryClass} onClick={() => isGroupable && handleColumnSelect(col.field, row.cells[index])}>
        {useLevelIndicator && <LevelIndicators parentRowLastChildRow={parentRowLastChildRow} isLastChildRow={isLastChildRow} level={level} />}
        <Space>
          {isFirstColumn && row.fullRow && 'Total'}
          {isFirstColumn && !row.fullRow && (
            <>
              {visual.legendChartColor && (
                <div>
                  <span className="legend-circle-color" style={{ background: chartStyle.color }} data-print-color={chartStyle.printColor} />
                </div>
              )}
              <GroupContent
                collapsible={collapsible}
                assetNameColumn={col.assetNameColumn}
                data={row.data}
                group={row.group as string}
                hasChildren={row.rows?.length !== 0}
                onExpand={onExpand}
              />
              {col.hyperlinksPopup ? (
                <Hyperlinks hyperlinks={links} />
              ) : (
                visual.ownershipDropdown && (
                  <Links
                    data={{
                      holdingSetId: row.data['holdingset.holdingSetId'],
                      date: row.data['holdingset.verifiedDate'],
                      type: 'vehicle'
                    }}
                  >
                    <Button type="link" size="small" icon={<EllipsisOutlined />} className="no-print" />
                  </Links>
                )
              )}
            </>
          )}
          {col.dataBars && <DataBars value={row.cells[index] as number} maxValue={maxRangeValues?.[col.fieldDataPath]} />}
          <SubtotalCell rowIndex={index} rowId={row.__id__} col={col} data={row.data} hideSubtotal={hideSubtotal} value={row.cells[index]} group={row.group as string} />
        </Space>
      </td>
    );
  }
);

export { RowGroup };
