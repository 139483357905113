import React, { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';

const styles = { width: '100%', textAlign: 'center' as CanvasTextAlign } as CSSProperties;

interface NoDataProps {}

const NoData: React.FunctionComponent<NoDataProps> = () => {
  return (
    <div className="visual-no-data" style={styles}>
      <FormattedMessage id={'generic.noData'} />
    </div>
  );
};

export default NoData;
