import React, { useMemo } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Text } from '@/common/components/Typography/Text';
import { ActivityType } from '@/common/types/entity/Link';
import { ActivityLayout } from './CardLayout';
import { ActivityCardTypeIcon } from './CardTypeIcon';
import { ActivityCardTypeText } from './CardTypeText';
import type { ActivityDTO } from '@/modules/ERP/Contacts/types';
import { parseActivityRelatedEntities } from '../utils';

interface ActivityCardContactProps {
  activity: ActivityDTO;
  entityId: string;
  entityType: ActivityType;
  isContactPage?: boolean;
}

export const ActivityCardContact: React.FunctionComponent<ActivityCardContactProps> = React.memo(({ activity, entityId, entityType, isContactPage }) => {
  const icon = useMemo(() => {
    if (isContactPage) {
      return <ActivityCardTypeIcon type={activity.type} />;
    }
    return <UserOutlined />;
  }, [isContactPage, activity.type]);

  const { creator, relatedEntity } = parseActivityRelatedEntities(activity, entityId);
  return (
    <ActivityLayout activity={activity} icon={icon}>
      <Text link>{creator}</Text>
      &nbsp;
      <ActivityCardTypeText activityType={activity.type} />
      <Text link>{relatedEntity}</Text>
    </ActivityLayout>
  );
});
