import { atom, atomFamily } from 'recoil';
import { PageTemplate } from '@/utils/retrievePageTemplate';
import { Contact } from '../types';
import { MetadataEntityType } from '@/common/types/entity/Metadata';

const contactFilterState = atomFamily<string, PageTemplate>({
  key: 'contacts-filter-state',
  default: undefined
});

const contactsCompanyFilterState = atomFamily<string | undefined, PageTemplate>({
  key: 'contacts-company-filter-state',
  default: undefined
});

const contactsCountryFilterState = atomFamily<string | undefined, PageTemplate>({
  key: 'contacts-country-filter-state',
  default: undefined
});

const managePropertiesTableState = atomFamily<boolean, MetadataEntityType>({
  key: 'manage-contact-properties-table-state',
  default: false
});

export const managePropertiesDrawerOpened = atomFamily<boolean, MetadataEntityType>({
  key: 'manage-contact-properties-drawer-state',
  default: false
});

export const erpSelectedContactsAtom = atom<Contact[]>({
  key: 'erp-selected-contact-atom',
  default: []
});

const contactsDataSheetActive = atom<boolean>({
  key: 'contacts-datasheet-active',
  default: false
});

export { contactFilterState, contactsCompanyFilterState, contactsCountryFilterState, contactsDataSheetActive, managePropertiesTableState };
