import React, { useCallback } from 'react';
import { Text } from '@/common/components/Typography/Text';

type AssetLinkWrapperProps = {
  assetLink: string | undefined;
  children: React.ReactNode;
};

export const AssetLinkWrapper = ({ assetLink, children: cellValue }: AssetLinkWrapperProps) => {
  const openAssetLink = useCallback(() => {
    window.open(assetLink as string);
  }, [assetLink]);

  if (!assetLink) {
    return cellValue;
  }

  return (
    <Text link className="print-no-link" onClick={openAssetLink}>
      {cellValue}
    </Text>
  );
};
