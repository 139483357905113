import { RawColumn, VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { HistoricalRange } from '@/modules/reporting-v2/types/HistoricalConfig';
import { SortOrder } from '@/modules/reporting-v2/types/VisualEngine';
import { EntityParameters } from '@/common/types/app/EntityParametersHandler';
import { DataFrequency } from '@/modules/reporting-v2/core/visuals/HistoricalMonthlyTab/VisualSpecificProps';
import { Column } from '@/modules/reporting-v2/core/Column';
import { IVisualDataStructure } from '@/modules/reporting-v2/types/ReportViewerServiceTypes';
import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import { Index } from '@/modules/reporting-v2/types/Index';
import GC from '@mescius/spread-sheets';

export const SpreadsheetVisualComponent = 'SpreadsheetVisual' as VisualComponent;

export type SpreadsheetSelection = {
  range: string;
  sheetName: string;
};

export type SpreadsheetEntityData = EntityParameters & { name: string };

export interface SesameSpreadsheetRangeOptions {
  description?: string;
  color?: string;
  fields?: RawColumn;
  groupBy?: RawColumn;
  historical?: boolean;
  dateRange?: HistoricalRange;
  sortBy?: RawColumn;
  sortOrder?: SortOrder;
  limit?: number;
  showChildren?: boolean;
  total?: boolean;
  subTotal?: boolean;
  frequency?: DataFrequency;
  pivot?: boolean;
  values?: RawColumn;
  columnsHeader?: boolean;
  multiEntity?: boolean;
  visibility?: number[];
}
export interface SesameSpreadsheetRange extends SpreadsheetSelection {
  id: string;
  options: SesameSpreadsheetRangeOptions;
}

export interface SesameSpreadsheetConfig {
  title?: string;
  ranges: SesameSpreadsheetRange[];
}

export interface SpreadsheetData {
  rawData: FlattenObject[];
  data: IVisualDataStructure;
  columns: Column[];
  primaryIndex: Index;
}

export enum DEFAULT_SHEETS {
  CONFIG = '__config__'
}

export interface SpreadsheetCache {
  date: string;
  data: {
    workbook?: object;
    config: SesameSpreadsheetConfig;
  };
}

export type CellModel = {
  value: any;
  format?: string;
  style?: GC.Spread.Sheets.Style;
  isDate?: boolean;
};
