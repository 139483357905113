import { atom } from 'recoil';

const portfolioFilterState = atom<string>({
  key: 'portfolio-list-state',
  default: undefined
});
const currencyPortfolioByFilterState = atom<string>({
  key: 'currency-portfolio-filter-state',
  default: undefined
});

const custodianByFilterState = atom<string>({
  key: 'custodian-portfolio-filter-state',
  default: undefined
});
const legalEntityFilterState = atom<string>({
  key: 'legal-entity-list-state',
  default: undefined
});
export { portfolioFilterState, legalEntityFilterState, custodianByFilterState, currencyPortfolioByFilterState };
