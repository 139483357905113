import axios from 'axios';
import { message } from 'antd';
import Cookie from 'js-cookie';
import { AuthSessionStorageManager } from '@/common/auth/storage/AuthSessionStorageManager';
import { SecurityUtils } from '@/utils/SecurityUtils';
import queryString from 'query-string';

const pentestCookieName = 'x-sesame-pentest-urls';
const reportPrintInternalCookieName = 'x-sesame-internal-urls';

axios.interceptors.request.use(async config => {
  const token = await SecurityUtils.retrieveAuth0AccessToken();

  config.headers = {
    ...config.headers,
    ...AuthSessionStorageManager.retrieveCurrentHeaders()
  };

  if (token && !config.overridePreviousToken) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const getPentestApiUrl = (service, url) => {
  return `${import.meta.env.VITE_API_GATEWAY}${service}${url}`;
};

const getScheduleReportApiUrl = (service, url) => {
  const scheduleService = service === 'gateway' ? 'gateway-sesame' : `service-${service}`;
  return `http://${scheduleService}.default.svc.cluster.local:8080${url}`;
};

const retrieveServiceUrl = service => {
  return import.meta.env[`VITE_API_${service.toUpperCase().replace('SERVICE_', '')}`];
};

export const getApiUrl = (url, service = import.meta.env.VITE_API_SESAME) => {
  if (url.startsWith('http')) {
    // At the moment, this is only used for current remote version fetching (refresh modal)
    return url;
  }

  const pentestCookie = Cookie.get(pentestCookieName);
  const scheduleReportCookie = Cookie.get(reportPrintInternalCookieName);

  if (pentestCookie && service !== 'gateway') {
    return getPentestApiUrl(service, url);
  }

  if (scheduleReportCookie) {
    return getScheduleReportApiUrl(service, url);
  }

  const serviceUrl = retrieveServiceUrl(service);
  const cleanedServiceUrl = serviceUrl.replace(/\/+$/, '');
  const cleanedUrl = url.replace(/^\/+/, '');

  return `${cleanedServiceUrl}/${cleanedUrl}`;
};

export const makeApiRequest = config => {
  const { service, url, ...restConfig } = config;
  return axios({
    ...restConfig,
    url: getApiUrl(url, service),
    paramsSerializer: params => queryString.stringify(params, { arrayFormat: 'comma' })
  });
};

const requestApi = (config, onCallback) => {
  const { errors = true, ...restConfig } = config;
  return makeApiRequest(restConfig)
    .then(data => {
      if (onCallback) {
        onCallback(data);
      }
      return data;
    })
    .catch(async error => {
      if (error.response && error.response.status === 401) {
        await SecurityUtils.resetAuthentication();
      }

      if (errors && config.config?.errors !== false) {
        if (error.message === 'Network Error') {
          message.error('An error occurred while connecting to the server. Please refresh your browser.');
        } else {
          message.error(error.response?.data.description || error.response?.data.message || error.toString());
        }
      }

      if (config.onError) {
        config.onError(error);
      }

      if (onCallback) {
        onCallback(error);
      }

      return Promise.reject(error);
    });
};

export default requestApi;
