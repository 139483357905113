import { useContext } from 'react';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import { quintileScoreSelector } from '@/modules/reporting-v2/core/visuals/Gauge/QuintileScore/recoil';
import { useRecoilValue } from 'recoil';

export const useQuintileScore = () => {
  const reportContext = useContext(ReportContext);
  const selectedEntity = reportContext.params?.p?.[0];
  const scoreData = useRecoilValue(
    quintileScoreSelector({
      holdingSetId: selectedEntity?.holdingSetId,
      date: selectedEntity?.date
    })
  );

  return scoreData?.quintileScore;
};
