import DataTable from '@/modules/reporting-v2/core/components/DataTable';
import * as Loaders from '@/modules/reporting-v2/core/components/loaders';
import { mapVisualConfigColumns } from '@/modules/reporting-v2/utils';
import Visual from '@/modules/reporting-v2/core/visuals/Visual/index';
import { config } from './config';
import schema from './schema.json';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { IDataTableProps } from '@/modules/reporting-v2/core/components/DataTable/DataTableTypes';
import { Column, ColumnFormatting } from '@/modules/reporting-v2/core/Column';
import { Row } from '@/modules/reporting-v2/types/VisualEngine';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { FormatType } from '@/common/types/elastic/FormatType';
import deepmerge from 'deepmerge';
import CustomTableConfig from './CustomTableConfig';
import { duplicateColumnSeparator } from '@/utils/getGenericFieldPath';
import { Field } from '@/modules/reporting-v2/core/Field';
import { RawColumn } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { nanoid } from 'nanoid';
import { DataTablev2 } from '@/modules/reporting-v2/core/components/DataTablev2/DataTable';
import { MeasureRenderTime } from '@/modules/reporting-v2/utils/MeasureRenderTime';

class CustomTable extends Visual {
  Loader = Loaders.Table;

  static configMapper(visualConfig: any) {
    const { customColumns, ...rest } = visualConfig;

    const columns = (customColumns as any[]).reduce((prev, curr) => deepmerge(prev, curr.measures), {});

    return {
      ...rest,
      columns: mapVisualConfigColumns(columns),
      customColumns
    };
  }

  getConfig() {
    return Visual.merge(config, super.getConfig()) as VisualEngine;
  }

  getSchema() {
    return schema;
  }

  getMappedColumn(code: string | undefined, columns: Column[]) {
    if (!code) {
      return undefined;
    }

    const isDuplicatedCode = code.includes(duplicateColumnSeparator);
    if (!isDuplicatedCode) {
      return columns.find(column => column.code === code);
    }

    const codeId = code.split(duplicateColumnSeparator)[1];
    return columns.find(column => column.id === codeId);
  }

  getRows(visual: CustomTableConfig) {
    const data = visual.data.totals;
    const columns = visual.customColumns;
    const numberOfRows = (columns[0].measures as RawColumn).defaultColumns.length;
    const rows: Row[] = [...Array(numberOfRows)].map(() => {
      return {
        __id__: nanoid(),
        cells: Array(columns.length),
        data: data
      };
    });

    rows.forEach((row, rowIndex) => {
      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        const column = columns[colIndex];

        const measureCode = (column.measures as RawColumn).defaultColumns[rowIndex];
        const measure = this.getMappedColumn(measureCode, visual.columns);

        if (!measure) {
          row.cells[colIndex] = '';
          continue;
        }

        const cellValue = data[measure.fieldDataPath];
        const formattedCellValue = VisualEngine.formatCell(cellValue, measure, data);

        row.cells[colIndex] = formattedCellValue as Primitive;

        this.handleCustomValues(measure, rows, rowIndex, colIndex);
      }
    });

    return rows;
  }

  handleCustomValues(measure: Column, rows: Row[], rowIndex: number, colIndex: number) {
    if (!measure.customValue) {
      return;
    }

    rows[rowIndex].cells[colIndex] = measure.customValue;

    if (!measure.rowSpan) {
      return;
    }

    const cellAlreadyHaveOptions = rows[rowIndex].options?.[colIndex];
    if (cellAlreadyHaveOptions) {
      return;
    }

    rows[rowIndex].options = {
      ...rows[rowIndex].options,
      [colIndex]: {
        props: {
          rowSpan: measure.rowSpan
        }
      }
    };

    for (let i = 1; i < measure.rowSpan; i++) {
      const rowDoesNotExist = !rows[rowIndex + i];
      if (rowDoesNotExist) {
        continue;
      }

      // hide next column cells so the rowspan cell take their slot
      rows[rowIndex + i].options = {
        ...rows[rowIndex + i].options,
        [colIndex]: {
          visible: false
        }
      };
    }
  }

  getColumns(visual: CustomTableConfig) {
    const formatting = new ColumnFormatting(undefined, FormatType.string);

    return visual.customColumns.map(
      ccolumn =>
        new Column({
          headerConfig: { displayName: ccolumn.name },
          field: new Field(''),
          formatting,
          isDefault: true
        })
    );
  }

  getCloneVisual(visual: VisualEngine) {
    const visualClone = Object.assign({}, visual);
    Object.setPrototypeOf(visualClone, VisualEngine.prototype);

    return visualClone;
  }

  renderBody() {
    const originalVisual = this.props.visual as CustomTableConfig;
    const visualClone = this.getCloneVisual(originalVisual);
    const rows = this.getRows(originalVisual);
    const columns = this.getColumns(originalVisual);

    visualClone.data.rows = rows;
    visualClone.columns = columns;
    visualClone.total = false;

    if (originalVisual.tableVersion === 'v1') {
      return (
        <MeasureRenderTime id={originalVisual.id} label={`OLD TABLE: ${originalVisual.component} / ${originalVisual.title} `}>
          <DataTable visual={visualClone as IDataTableProps['visual']} />
        </MeasureRenderTime>
      );
    }

    return (
      <MeasureRenderTime id={originalVisual.id} label={`NEW TABLE: ${originalVisual.component} / ${originalVisual.title} `}>
        <DataTablev2 visual={visualClone as IDataTableProps['visual']} />
      </MeasureRenderTime>
    );
  }
}

export default CustomTable;
