import React, { Key, useContext } from 'react';
import { Column } from '@/modules/reporting-v2/core/Column';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { ColumnMetas } from '@/modules/reporting-v2/types/Column';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import EditableValue from '../../EditableValue';
import Documents from './Documents';
import { LimitsWrapper } from '@/modules/reporting-v2/core/components/DataTable/components/Limits/Limits';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import { forceCurrency } from '@/modules/reporting-v2/core/forceCurrency';
import { UNCLASSIFIED } from '@/const';

interface IProps {
  value: Primitive;
  col: Column;
  data: FlattenObject;
  hideSubtotal?: boolean;
  rowId?: string;
  group: string | undefined;
  rowIndex: number;
}

const SubtotalCell: React.FC<IProps> = React.memo(({ rowId, hideSubtotal, col, data, value, group, rowIndex }) => {
  const context = useContext(ReportContext);

  if (col.limitsPopup) {
    return <LimitsWrapper data={data} />;
  }

  if (col.documentsPopup) {
    return <Documents holdingSetId={data['holdingset.holdingSetId'] as number} />;
  }

  if (hideSubtotal || col.hideSubtotal || (group === UNCLASSIFIED && rowIndex === 0)) {
    return null;
  }

  if (!col.isRowCellEditable) {
    return <>{VisualEngine.formatCell(value, col, data, false, undefined, context.reportConfiguration?.config.numberLocale)}</>;
  }

  return (
    <EditableValue
      rowId={rowId}
      key={value as Key}
      data={data}
      metas={col as unknown as ColumnMetas}
      defaultValue={value}
      field={col.fieldDataPath}
      htmlEditOnly={group === UNCLASSIFIED}
    >
      {VisualEngine.formatCell(value, col, data, false, forceCurrency(col), context.reportConfiguration?.config.numberLocale)}
    </EditableValue>
  );
});

export { SubtotalCell };
