export const onboardingRouteParamKey = 'ob_page';

export enum OnboardingRoutes {
  SIGN_UP = '/signup'
}
export enum OnboardingPage {
  START = 'start',
  SELECT_CURRENCY = 'currency',
  ADD_ASSETS = 'assets',
  REAL_ESTATE = 'real-estate',
  CASH = 'cash',
  FINANCIAL = 'financial',
  COLLECTIBLES = 'collectibles',
  SIGN_UP = 'signup',
  LEGAL_ENTITY = 'legal-entity',
  OWNERSHIP_MAP = 'ownership',
  SUCCESSFULLY_ADDED = 'successfully-added'
}
