import { flattenedUserHsets } from '@/recoil/holdingSets';
import React, { createContext, useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import useSWR from 'swr';
import { ConsolidationType } from '@/modules/reporting-v2/types/ReportingService';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import { MediumSpin } from '@/common/suspense';
import { getLimits } from '@/api/getLimits';

type HSID = number;
type LimitBreachContextValue = Record<HSID, { breach: number; warning: number }>;
const LimitBreachContext = createContext<LimitBreachContextValue>({});

const useLimitBreachContext = () => {
  const limitBreachContext = useContext(LimitBreachContext);
  if (limitBreachContext === undefined) {
    console.error('useLimitBreachContext must be used within a LimitBreachContextProvider');
    return {};
  }

  return limitBreachContext;
};

type LimitBreachContextWrapperProps = {
  children: React.ReactNode;
};

const LimitBreachContextWrapper: React.FC<LimitBreachContextWrapperProps> = ({ children }) => {
  const userEntities = useRecoilValue(flattenedUserHsets);
  const user = useRecoilValue(currentUserSelector);

  const directEntities = useMemo(
    () =>
      userEntities
        .filter(entity => user.verifiedDates[entity.id])
        .map(entity => ({
          date: user.verifiedDates[entity.id],
          holdingSetId: entity.id,
          consolidationType: ConsolidationType.DIRECT
        })),
    [userEntities, user.verifiedDates]
  );
  const lookthroughEntities = useMemo(
    () =>
      userEntities
        .filter(entity => user.verifiedDates[entity.id] && entity.lookThroughEnabled)
        .map(entity => ({
          date: user.verifiedDates[entity.id],
          holdingSetId: entity.id,
          consolidationType: ConsolidationType.LOOK_THROUGH
        })),
    [userEntities, user.verifiedDates]
  );

  const { data: directData, isLoading: directLoading } = useSWR(directEntities, { fetcher: getLimitsNumber, revalidateIfStale: false });
  const { data: lookthroughData, isLoading: lookthroughLoading } = useSWR(lookthroughEntities, { fetcher: getLimitsNumber, revalidateIfStale: false });

  const contextValue = useMemo<LimitBreachContextValue>(() => {
    if (directLoading || lookthroughLoading) return {};

    const returnValue: LimitBreachContextValue = {};
    const verifiedEntities = Object.keys(user.verifiedDates);
    verifiedEntities.forEach(entityId => {
      const direct = directData?.find((d: Record<string, any>) => d.holdingSetId === Number(entityId));
      const lookthrough = lookthroughData?.find((d: Record<string, any>) => d.holdingSetId === Number(entityId));

      const breach = (direct?.numberOfAlerts || 0) + (lookthrough?.numberOfAlerts || 0);
      const warning = (direct?.numberOfWarnings || 0) + (lookthrough?.numberOfWarnings || 0);

      returnValue[Number(entityId)] = { breach, warning };
    });

    return returnValue;
  }, [user.verifiedDates, directData, directLoading, lookthroughData, lookthroughLoading]);

  if (directLoading || lookthroughLoading) {
    return MediumSpin;
  }

  return <LimitBreachContext.Provider value={contextValue}>{children}</LimitBreachContext.Provider>;
};

const getLimitsNumber = async (entities: Record<string, number | string>[]) => {
  const payload = {
    holdingSets: entities,
    source: {
      includes: ['holdingSetId', 'consolidationType', 'date', 'numberOfAlerts', 'numberOfWarnings']
    }
  };

  const data = await getLimits(payload);

  return data.map((hit: Record<string, any>) => hit._source ?? {});
};

export { useLimitBreachContext, LimitBreachContextWrapper, LimitBreachContext };
