import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { getEmptyRawColumn } from '@/modules/reporting-v2/utils/getEmptyRawColumn';
import type RawDashboardTableConfig from './types';

export const config: RawDashboardTableConfig = {
  type: 'visual',
  columns: getEmptyRawColumn(),
  categories: false,
  total: false,
  _collapsible: true,
  subTotal: true,
  _reportContext: VisualReportContext.NONE
};
