import { VisualConfigProps } from '@/modules/reporting-v2/types/VisualEngine';

export default interface ExtendedLiquidityTableConfig extends VisualConfigProps {
  categoryHeadersEnabled: boolean;
  liquidateDays: LiquidateDays[];
  participationRates: ParticipationRates[];
  avgDailyVol: AvgDailyVol;
}
export enum AvgDailyVol {
  thirtyDays = 'thirtyDays',
  oneYear = 'oneYear'
}

export type LiquidateDays = '1' | '3' | '5' | '7' | '10' | '30' | '60' | '90' | '120' | '180' | 'Infinity';

export type ParticipationRates = '5' | '10' | '15' | '20' | '25' | '30';
