import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import { EntityLinkType, LinkLookup } from '@/common/types/entity/Link';

export const getLinkForEntities = (entityType: EntityLinkType, entityIds: string[]): Promise<Record<string, Array<LinkLookup>>> => {
  return requestApi({
    service: 'link',
    method: 'get',
    url: `/api/${entityType}/${entityIds}`,
    params: {
      size: 100
    }
  }).then((resp: AxiosResponse<Record<string, Array<LinkLookup>>> | undefined) => {
    return resp!.data;
  });
};
