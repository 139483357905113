import { useContext, useMemo } from 'react';
import { Column } from '@/modules/reporting-v2/core/Column';
import { ReportingService } from '@/modules/reporting-v2/core/ReportingService';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { ExcelUtils } from '@/utils/excel';
import { IMappedHyperLink } from '@/modules/reporting-v2/types/Column';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { getTheme } from '@/modules/reporting-v2/utils/theme';
import { parseChartStyles } from '@/modules/Styling/utils/parseChartStyles';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import chartColors from '@/modules/reporting-v2/core/visuals/AllocationPie/colors.json';
import chartContrastColors from '@/modules/reporting-v2/core/components/Highcharts/contrastColors.json';
import { RowGroup } from '@/modules/reporting-v2/types/VisualEngine';
import { Dict } from '@/modules/reporting-v2/types/Common';
import buildHyperLinks from '../buildHyperLinks';

const useSubtotalRow = (visual: VisualEngine, col: Column, data: FlattenObject, cells: Primitive[], rowIndex: number, cellIndex: number, group: string, level: number) => {
  const context = useContext(ReportContext);
  const excelAttrs = ExcelUtils.getTDAttributes(col, data, cellIndex === 0 ? group ?? cells[cellIndex] : cells[cellIndex]);

  const isGroupable = ReportingService.metas[col.code ?? col.field.name]?.groupable;
  const links: IMappedHyperLink[] = !col.hyperlinksPopup ? [] : buildHyperLinks(col.hyperlinks, data);

  const hideSubtotal = cells[cellIndex] === group || col.hideAtGroupLevels?.includes(String(level));

  const chartStyle = useMemo(() => {
    const theme = getTheme();
    const colors = (chartColors as Dict<string[]>)[theme];

    if (visual.styles?.colorScheme?.linkToVisualColorScheme) {
      const chartVisual = context.visuals!.get(visual.styles.colorScheme.linkToVisualColorScheme)!;

      if (!chartVisual) {
        return {};
      }
      const printChartColors = parseChartStyles(chartVisual.styles!).colors;
      const chartGroupIndex = chartVisual.data.rows.findIndex(row => (row as RowGroup).group === group);

      return {
        color: colors[chartGroupIndex % colors.length],
        printColor: printChartColors?.[chartGroupIndex % printChartColors.length]
      };
    } else {
      const contrastColors = (chartContrastColors as Dict<string[]>)[theme];
      return {
        color: colors[rowIndex % colors.length],
        printColor: contrastColors[rowIndex % contrastColors.length]
      };
    }
  }, [context, visual, rowIndex, group]);

  return {
    isGroupable,
    excelAttrs,
    links,
    hideSubtotal,
    chartStyle
  };
};

export default useSubtotalRow;
