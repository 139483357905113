import { message } from 'antd';
import { PersistentCustomReport } from '@/common/types/entity/CustomReport';
import { nanoid } from 'nanoid';
import { ReportEntityType } from '@/common/types/entity/ReportEntityType';
import { adapter } from '@/modules/report-builder/utils';
import { REPORT_BUILDER_CONFIG_ADAPTER_VERSION } from '@/modules/report-builder/utils/adapter';
import { RawReportConfig, RawVisual, ReportOrientation, VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { IntlShape, useIntl } from 'react-intl';
import queryString from 'query-string';
import { useRecoilValue } from 'recoil';
import { currentUserSelector } from '@/modules/User/recoil/user.atoms';
import { SecurityUtils } from './SecurityUtils';

const PreviewCustomReport = (customReportConfiguration: string, reportId: number): void => {
  const translator = useIntl();
  try {
    const parsedConfig = adapter(JSON.parse(customReportConfiguration));

    window.localStorage.setItem('preview', JSON.stringify(parsedConfig));
    window.open(SecurityUtils.getUrlWithInternal(`/builder/preview/crm/${reportId}`));
  } catch (err) {
    message.error(
      translator.formatMessage({
        id: 'report.builder.thisReportCannotBePreviewedDueToMisconfigurations'
      })
    );
  }
};

const PreviewFavouriteCustomReport = (customReportConfiguration: string, reportId: number, holdingSetId: number, consolidationType?: string) => {
  const translator = useIntl();
  const userId = useRecoilValue(currentUserSelector).id;

  try {
    const parsedConfig = adapter(JSON.parse(customReportConfiguration));
    let previewFilters: { holdingSetId?: number; lookThrough?: string } = {};

    try {
      const originalFilters = JSON.parse(window.localStorage.getItem(`filters@${userId}`)!);
      previewFilters = { ...originalFilters };
    } catch (err) {}

    previewFilters.holdingSetId = holdingSetId;
    previewFilters.lookThrough = consolidationType;

    window.localStorage.setItem(`filters@${userId}`, JSON.stringify(previewFilters));
    window.localStorage.setItem('preview', JSON.stringify(parsedConfig));
    window.open(SecurityUtils.getUrlWithInternal(`/builder/preview/crm/favorite/${reportId}`));
  } catch (err) {
    message.error(
      translator.formatMessage({
        id: 'report.builder.thisReportCannotBePreviewedDueToMisconfigurations'
      })
    );
  }
};

export const PreviewSingleVisual = (component: VisualComponent, config: RawVisual, translator: IntlShape) => {
  const parsedConfig = adapter({
    version: REPORT_BUILDER_CONFIG_ADAPTER_VERSION,
    id: 'report',
    title: translator.formatMessage({ id: 'report.builder.untitledReport' }),
    config: {
      orientation: ReportOrientation.PORTRAIT,
      pagination: false,
      stylesCode: '',
      globalFilters: { filters: [] },
      filters: {
        consolidationType: true,
        holdingSet: true,
        date: true,
        endDate: false
      }
    },
    pages: [
      {
        id: 'page',
        title: translator.formatMessage({ id: 'report.builder.untitledPage' }),
        layout: [{ w: 12, h: 5, x: 0, y: 0, i: 'WgY' }],
        components: [{ id: 'WgY', component, config }]
      }
    ]
  });

  window.localStorage.setItem('preview', JSON.stringify(parsedConfig));
  window.open(SecurityUtils.getUrlWithInternal(`/builder/preview`));
};

const goToReportBuilder = (report: PersistentCustomReport, entityType: ReportEntityType, customize = false) => {
  const query = {} as Record<string, any>;

  if (customize) {
    query.action = 'customize';
  }

  window.open(
    SecurityUtils.getUrlWithInternal(
      queryString.stringifyUrl({
        url: `${import.meta.env.VITE_BASE_URL}builder/edit/${entityType}/${report.id}`,
        query
      })
    )
  );
};

const goToSpreadsheetBuilder = (report?: PersistentCustomReport, params = '', preview = false) => {
  window.open(SecurityUtils.getUrlWithInternal(`${import.meta.env.VITE_BASE_URL}builder/spreadsheet${preview ? '/preview' : ''}/${report?.id || ''}${params ? '?' + params : ''}`));
};

const goToReportBuilderTemplateCreation = () => {
  window.open(SecurityUtils.getUrlWithInternal(`${import.meta.env.VITE_BASE_URL}builder?action=create-crm`));
};

const goToReportBuilderCustomReportCreation = () => {
  window.open(SecurityUtils.getUrlWithInternal(`${import.meta.env.VITE_BASE_URL}builder?action=create-crm&type=custom`));
};

class VisualVersioning {
  private constructor() {}

  private static findVisualById(id: string, initialConfig: RawReportConfig): RawVisual | undefined {
    let foundVisual = undefined;

    for (const page of initialConfig.pages) {
      const found = page.components.find(visual => visual.id === id);

      if (found) {
        foundVisual = found;
        break;
      } else continue;
    }

    return foundVisual;
  }

  public static computeVisualVersioning(initialConfig: RawReportConfig, newConfig: RawReportConfig): RawReportConfig {
    const reportIsNew = !initialConfig || Array.isArray(initialConfig.pages) === false;
    if (reportIsNew) {
      return {
        ...newConfig,
        pages: newConfig.pages.map(page => {
          const newVisuals = page.components.map(visual => {
            const newVisual = { ...visual };
            return Object.assign(newVisual, { version: nanoid(10) });
          });

          return { ...page, components: newVisuals };
        })
      };
    }

    const configCopy = { ...newConfig };

    configCopy.pages = configCopy.pages.map(page => {
      const pageCopy = { ...page };

      pageCopy.components = pageCopy.components.map(visual => {
        const visualCopy = { ...visual };
        const initialVisualConfig = this.findVisualById(visualCopy.id, initialConfig);

        const visualShouldHaveANewVersion = this.visualShouldHaveANewVersion(initialVisualConfig, visualCopy);
        if (visualShouldHaveANewVersion) {
          return Object.assign(visualCopy, { version: nanoid(10) });
        }

        return visualCopy;
      });

      return pageCopy;
    });

    return configCopy;
  }

  private static visualShouldHaveANewVersion(initialVisualConfig: RawVisual | undefined, newVisualConfig: RawVisual) {
    const visualIsNew = initialVisualConfig === undefined;
    if (visualIsNew) {
      return true;
    }

    const initialVisualStringified = JSON.stringify(initialVisualConfig);
    const newVisualStringified = JSON.stringify(newVisualConfig);

    const visualHaveNotChanged = initialVisualStringified === newVisualStringified;
    if (visualHaveNotChanged) {
      return false;
    }

    return true;
  }
}

export {
  PreviewCustomReport,
  goToReportBuilder,
  goToSpreadsheetBuilder,
  goToReportBuilderTemplateCreation,
  goToReportBuilderCustomReportCreation,
  PreviewFavouriteCustomReport,
  VisualVersioning
};
