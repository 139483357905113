import { useEffect, useMemo, useState } from 'react';
import getLimitsThreshold from '@/utils/getLimitsThreshold';
import { PopupHelper } from '@/utils/PopupHelper';
import { Limit } from '@/modules/reporting-v2/core/components/DataTable/components/Limits/LimitsTypes';
import { castLimitFormatToSesameFormat } from '@/common/types/app/LimitFormat';
import formatTypes from '@/modules/reporting-v2/core/formatTypes';
import { ColumnFormatting } from '@/modules/reporting-v2/core/Column';
import { Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { ConsolidationType } from '@/modules/reporting-v2/types/ReportingService';
import { useIntl } from 'react-intl';
import { getLimits } from '@/api/getLimits';
import { FormatType } from '@/common/types/elastic/FormatType';

export default (holdingSetId: number) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [limits, setLimits] = useState<Limit[]>([]);
  const [categoryFilter, setCategoryFilter] = useState<string | undefined>(undefined);
  const [showShareClassNames, setShowShareClassNames] = useState<Record<number, boolean>>({});
  const translator = useIntl();

  const limitsByCategory = useMemo(
    () =>
      limits.reduce((acc, limit) => {
        if (categoryFilter && limit.category !== categoryFilter) return acc;

        let ini = undefined;
        let end = undefined;

        if (limit.valueStatus === 'ALERT') {
          ini = limit.operators.ini;
          end = limit.operators.end;
        }

        if (limit.valueStatus === 'WARNING') {
          ini = limit.operators.warningIni;
          end = limit.operators.warningEnd;
        }

        const sesameLimitFormat = castLimitFormatToSesameFormat(limit.format!);

        if (sesameLimitFormat === FormatType.percentage) {
          ini = ini ? ini / 100 : ini;
          end = end ? end / 100 : end;
        }

        const formattedIni = formatTypes[sesameLimitFormat] ? formatTypes[sesameLimitFormat](ini, {}, {}, new ColumnFormatting(), false) : ini;
        const formattedEnd = formatTypes[sesameLimitFormat] ? formatTypes[sesameLimitFormat](end, {}, {}, new ColumnFormatting(), false) : end;

        limit.threshold = getLimitsThreshold(translator, formattedIni as Primitive, formattedEnd as Primitive, limit.operators.type);

        if (acc[limit.category]) {
          acc[limit.category].push(limit);
        } else {
          acc[limit.category] = [limit];
        }

        return acc;
      }, {} as { [key: string]: Limit[] }),
    [categoryFilter, limits, translator]
  );

  const categories = useMemo(() => [...new Set(limits.map(limit => limit.category))], [limits]);

  useEffect(() => {
    setFetching(true);

    const payloadDirect = {
      holdingSets: [{ holdingSetId, consolidationType: ConsolidationType.DIRECT }],
      source: {
        includes: PopupHelper.LimitPopupListFields().map(field => PopupHelper.ToElasticFieldName(field))
      }
    };
    const payloadLookthrough = {
      holdingSets: [{ holdingSetId, consolidationType: ConsolidationType.LOOK_THROUGH }],
      source: {
        includes: PopupHelper.LimitPopupListFields().map(field => PopupHelper.ToElasticFieldName(field))
      }
    };

    Promise.all([getLimits(payloadDirect), getLimits(payloadLookthrough)])
      .then(([resp1, resp2]) => {
        return (resp1[0]?._source?.limits || []).concat(resp2[0]?._source?.limits || []);
      })
      .then((limits: Limit[]) => {
        const unCompliantLimits = limits.filter(limit => limit.category !== 'VALIDATION' && (limit.valueStatus === 'ALERT' || limit.valueStatus === 'WARNING'));

        Promise.all(unCompliantLimits).then(limits => {
          if (limits?.length) {
            setLimits(limits);
          }

          setFetching(false);
        });
      });
  }, [holdingSetId]);

  return {
    fetching,
    limitsByCategory,
    categories,
    categoryFilter,
    setCategoryFilter,
    showShareClassNames,
    setShowShareClassNames
  };
};
