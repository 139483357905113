import React, { useCallback } from 'react';
import { Text } from '@/common/components/Typography/Text';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { siteTreeState } from '@/modules/App/recoil/app.atoms';
import { getHoldingSetUrlInfo } from '@/modules/ERP/HoldingSets/utils';
import { EntityType } from '@/modules/Onboarding/Shared/types';
import { HoldingSet } from '@/modules/reporting-v2/core/visuals/DashboardTable/holdingset.utils';

interface LinkRedirectProps {
  holdingSet: HoldingSet;
}

const HoldingSetLinkRedirect: React.FC<LinkRedirectProps> = ({ holdingSet }) => {
  const history = useHistory();
  const siteTree = useRecoilValue(siteTreeState);
  const onClickLink = useCallback(() => {
    const { pathname, searchParams } = getHoldingSetUrlInfo(String(holdingSet.id), holdingSet.type as EntityType, siteTree!);

    history.push({ pathname, search: searchParams.toString() });
  }, [history, holdingSet.id, holdingSet.type]);

  return (
    <div className="entity-links-single-entity">
      <Text onClick={onClickLink} link>
        {holdingSet.name}
      </Text>
    </div>
  );
};

export { HoldingSetLinkRedirect };
