import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedLimitsCallOutsConfig } from '..';
import { LimitsCallOutsConfigMapped } from './types';
import { Category } from './VisualSpecificProps';

export default class LimitsCallOutsConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as LimitsCallOutsConfigMapped));
  }

  getVisualConfigProps(config: LimitsCallOutsConfigMapped): ExtendedLimitsCallOutsConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      category: config.category
    });
  }

  setVisualConfig(config: ExtendedLimitsCallOutsConfig) {
    super.setVisualConfig(config);
    this.category = config.category;
  }

  category: Category[];
}
