import { createContext, useContext } from 'react';

export const VisualFormContext = createContext({});

export const useVisualFormContext = () => {
  return useContext(VisualFormContext);
};

type VisualFormContextProviderProps = {
  children: React.ReactNode;
  visualFormValue: Record<string, unknown>;
};

export const VisualFormContextProvider: React.FC<VisualFormContextProviderProps> = ({ children, visualFormValue }) => {
  return <VisualFormContext.Provider value={visualFormValue}>{children}</VisualFormContext.Provider>;
};
