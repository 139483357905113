import { Column } from '@/modules/reporting-v2/core/Column';

const useCategoryClass = (columns: Column[], column: Column, index: number, showCategories: boolean) => {
  if (!showCategories) {
    return '';
  }

  const categoryClasses: string[] = [];

  const defaultColumns = columns.filter(col => col.isDefault);

  const currentCategory = column.headerConfig.category;
  const previousColumnCategory = defaultColumns[index - 1]?.headerConfig.category;
  const nextColumnCategory = defaultColumns[index + 1]?.headerConfig.category;

  if (previousColumnCategory !== currentCategory) {
    categoryClasses.push('category-start');
  }

  if (nextColumnCategory !== currentCategory) {
    categoryClasses.push('category-end');
  }

  return categoryClasses.join(' ');
};

export { useCategoryClass };
