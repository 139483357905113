import { atom, selector } from 'recoil';
import { getAccountSpaces } from '@/api/getAccountSpaces';
import { SpaceDto, SpaceFilter } from '@/common/types/entity/Space';
import { userState } from '@/modules/User/recoil/user.atoms';
import getAccountUsers from '../api/getAccountUsers';
import { UserLookup } from '../common/types/entity/UserLookup';
import { SesameUser } from '../common/types/SesameUser';
import { ExternalAccountLookup } from '../common/types/entity/Account';
import { loggedUserSelector } from '@/common/auth/recoil/user.selector';

export const sesameUser = selector<SesameUser>({
  key: 'sesameUser',
  get: ({ get }) => get(userState).currentUser
});

export const currentUser = selector<UserLookup>({
  key: 'currentUser',
  get: ({ get }) => {
    const { id, firstName, lastName, login, enabled, company, type } = get(sesameUser);
    const companyLookup: ExternalAccountLookup = {
      id: company.id,
      name: company.name
    };
    return {
      id,
      firstName,
      lastName,
      login,
      enabled,
      company: companyLookup,
      type
    };
  }
});

export const companyUsers = selector<UserLookup[]>({
  key: 'companyUsers',
  get: async ({ get }) => {
    return getAccountUsers(get(sesameUser).accountId);
  }
});

export const companyUsersMap = selector<Map<number, UserLookup>>({
  key: 'companyUsersMap',
  get: ({ get }) => {
    return new Map(get(companyUsers).map(user => [user.id, user]));
  }
});

export const atomUserSpacesFilter = atom<Partial<SpaceFilter>>({
  key: 'atomUserSpacesFilter',
  default: {}
});

export const userSpacesSelector = selector<SpaceDto[]>({
  key: 'userSpacesSelector',
  get: async ({ get }) => {
    const filter = get(atomUserSpacesFilter);
    const accountId = get(loggedUserSelector).accountId;

    const filterWithSize = { ...filter, size: 9999, sort: 'name' };

    return (await getAccountSpaces(accountId, filterWithSize)).content;
  }
});
