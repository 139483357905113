import type { AxiosResponse } from 'axios';
import { requestApi } from '@/core';
import { Institution } from '../../Common/types';

export const getAllocatedCustodians = (): Promise<Institution[]> => {
  return requestApi({
    method: 'get',
    service: 'client',
    url: `/api/companies/allocated-custodians`
  }).then((response: AxiosResponse<Institution[]> | undefined) => response!.data);
};
