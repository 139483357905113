import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import WebPage from '../common/types/entity/WebPage';

export default (id: number): Promise<WebPage | undefined> =>
  requestApi({
    service: 'cms',
    method: 'get',
    url: `/api/webpages/${id}`
  }).then((response: AxiosResponse<WebPage> | undefined) => response?.data!);
