import { useCallback } from 'react';
import { Comment, CommentLookup } from '../../../../common/types/entity/Conversation';
import patchComment from '../../api/patchComment';
import createComment from '../../api/createComment';
import useAutosave from '../../../../common/form/useAutosave';

export default function (conversationId: number, comment: Comment, onCreate?: (comment: Comment, lookup: CommentLookup) => void) {
  const commentPersister = useCallback(
    async (comment: Comment, attrs: { [key: string]: any }) => {
      if (comment.id) {
        return patchComment(conversationId, comment.id, attrs);
      } else {
        return createComment(conversationId, comment);
      }
    },
    [conversationId]
  );

  const errorHandler = useCallback(() => {}, []); // do nothing, errors are displayed by the autosave indicator

  const updateHandler = useCallback(
    (lookup: CommentLookup) => {
      const created = !comment.id;
      comment.id = lookup.id;
      comment.user = lookup.user;
      // createdDate left untouched on purpose, it is used as react "key", this keeps user cursor/focus in the editor field
      comment.lastModifiedDate = new Date(lookup.lastModifiedDate);
      if (created) {
        onCreate && onCreate(comment, lookup);
      }
    },
    [comment, onCreate]
  );

  return useAutosave<Comment, CommentLookup>(500, commentPersister, updateHandler, errorHandler, [comment]);
}
