import React, { useEffect, useRef, useState } from 'react';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Divider, Spin } from 'antd';
import { ShareClass, Limit } from '@/modules/reporting-v2/core/components/DataTable/components/Limits/LimitsTypes';
import { castLimitFormatToSesameFormat } from '@/common/types/app/LimitFormat';
import formatTypes from '@/modules/reporting-v2/core/formatTypes';
import { ColumnFormatting } from '@/modules/reporting-v2/core/Column';
import { requestApi } from '@/core';
import { LimitStatus } from '@/modules/reporting-v2/core/components/DataTable/components/Limits/LimitStatus';
import { LimitShareClass } from '@/modules/reporting-v2/core/components/DataTable/components/Limits/LimitShareClass';
import useVisibleOnScreen from '@/common/hooks/useVisibleOnScreen';
import { useIntl } from 'react-intl';
import { nanoid } from 'nanoid';

interface ILimitProps {
  limit: Limit;
  setShowShareClassNames: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
  showShareClassNames: Record<number, boolean>;
}

export const SingleLimit: React.FC<ILimitProps> = React.memo(({ limit, setShowShareClassNames, showShareClassNames }) => {
  const [shareClassBreaches, setShareClassBreaches] = useState<ShareClass[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestedApi, setRequestedApi] = useState<boolean>(false);
  const ref: any = useRef<HTMLDivElement>();
  const isVisibleOnScreen = useVisibleOnScreen<HTMLDivElement>(ref);
  const translator = useIntl();

  useEffect(() => {
    if (isVisibleOnScreen && !requestedApi) {
      setIsLoading(true);
      requestApi({
        service: 'limit',
        url: `/api/limits/${limit.limitHoldingSetId}/items`,
        errors: false
      })
        .then(response => {
          const shareClassBreachesArray = (response.data ?? []).filter((shareClass: ShareClass) => shareClass.valueStatus === 'ALERT' || shareClass.valueStatus === 'WARNING');
          setShareClassBreaches(shareClassBreachesArray);
        })
        .catch(() => null)
        .finally(() => {
          setRequestedApi(true);
          setIsLoading(false);
        });
    }
  }, [limit.limitHoldingSetId, isVisibleOnScreen, requestedApi]);

  const sesameLimitFormat = castLimitFormatToSesameFormat(limit.format!);
  const currentValue = formatTypes[sesameLimitFormat] ? formatTypes[sesameLimitFormat](limit.limitCurrentValue, {}, {}, new ColumnFormatting(), false) : limit.limitCurrentValue;

  return (
    <div key={limit.name} ref={ref}>
      <div>
        <strong>{limit.name}</strong>
      </div>
      {limit.threshold && <div>{limit.threshold}</div>}
      <div>
        {translator.formatMessage({ id: 'generic.value' })} {currentValue}
      </div>
      <div>
        {translator.formatMessage({ id: 'generic.status' })} <LimitStatus valueStatus={limit.valueStatus} />
      </div>
      {isLoading && <Spin size="small" style={{ margin: '24px auto' }} />}
      {!isLoading && shareClassBreaches?.length > 0 && (
        <>
          <br />
          {showShareClassNames[limit.limitHoldingSetId] ? (
            <MinusCircleOutlined
              onClick={() => {
                setShowShareClassNames(prevState => ({
                  ...prevState,
                  [limit.limitHoldingSetId]: !prevState[limit.limitHoldingSetId]
                }));
              }}
            />
          ) : (
            <PlusCircleOutlined
              onClick={() => {
                setShowShareClassNames(prevState => ({
                  ...prevState,
                  [limit.limitHoldingSetId]: !prevState[limit.limitHoldingSetId]
                }));
              }}
            />
          )}
          &nbsp; Breakdown
        </>
      )}
      {!isLoading && showShareClassNames[limit.limitHoldingSetId] && (
        <div>
          {shareClassBreaches.map(shareClass => {
            return (
              <React.Fragment key={shareClass.currentValue + nanoid()}>
                <br />
                <LimitShareClass
                  description={shareClass.description}
                  valueStatus={shareClass.valueStatus}
                  currentValue={shareClass.currentValue}
                  currentValueStr={shareClass.currentValueStr}
                />
              </React.Fragment>
            );
          })}
        </div>
      )}
      <Divider dashed />
    </div>
  );
});
