import { Field } from '@/modules/reporting-v2/core/Field';

export class PopupHelper {
  static readonly PopupIndex = 'Popups';
  static readonly PopupFields = ['Popups.popups_documents', 'Popups.popups_limits'];
  static readonly LimitPopupRequiredFields = () => [
    new Field('limits.holdingSetId'),
    new Field('limits.consolidationType'),
    new Field('limits.date'),
    new Field('limits.numberOfAlerts'),
    new Field('limits.numberOfWarnings')
  ];
  static readonly LimitPopupListFields = () => [
    new Field('limits.limits.category'),
    new Field('limits.limits.name'),
    new Field('limits.limits.limitCurrentValue'),
    new Field('limits.limits.valueStatus'),
    new Field('limits.limits.operators'),
    new Field('limits.limits.limitHoldingSetId'),
    new Field('limits.limits.format')
  ];
  static readonly GetPopupMetadata = (field: string) =>
    PopupHelper.IsPopupField(field)
      ? {
          visible: true,
          summable: false,
          groupable: false,
          category: PopupHelper.PopupIndex,
          displayName: PopupHelper.GetDisplayName(field)
        }
      : {};
  static readonly DocumentsKey = 'popups_documents';
  static readonly LimitsKey = 'popups_limits';
  static readonly PopupsFieldDisplayNames: Record<string, string> = {
    [PopupHelper.LimitsKey]: 'Limits alerts',
    [PopupHelper.DocumentsKey]: 'Documents'
  };
  static readonly GetDisplayName = (field: string): string => PopupHelper.PopupsFieldDisplayNames[PopupHelper.GetPopupKey(field)];
  static readonly LimitsNumberOfAlertsPath = 'limits.numberOfAlerts';
  static readonly LimitNumberOfWarningsPath = 'limits.numberOfWarnings';
  static readonly LimitConsolidationTypePath = 'limits.consolidationType';
  static readonly HoldingSetConsolidationTypePath = 'holdingset.consolidationType';
  static readonly HoldingSetIdPath = 'holdingset.holdingSetId';
  static readonly LimitHoldingSetIdPath = 'limits.holdingSetId';
  static readonly LimitDatePath = 'limits.date';
  static readonly HoldingSetDatePath = 'holdingset.verifiedDate';
  static readonly IsPopupField = (field: string) => {
    return field.includes(PopupHelper.PopupIndex);
  };
  static readonly GetPopupKey = (field: string) => field.split('.')[1];
  static readonly ToElasticFieldName = (field: Field) => field.name.split('.').slice(1).join('.');
}
