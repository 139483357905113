import { EntityLinkType, EntityLinkTypeMenu, LinkableObject } from '@/common/types/entity/Link';
import { ActivityDTO, ContactLookup, NoteDTO, TaskDTO } from '@/modules/ERP/Contacts/types';
import { DocumentLookup } from '@/common/types/entity/DocumentLookup';
import { Asset } from '@/api/getAsset';
import { HoldingSetLookup } from '@/common/types/entity/HoldingSetLookup';
import { User } from '@/types';
import SiteTreeEntry from '@/common/types/entity/SiteTreeEntry';

export const translateEntityObjectName = (object: LinkableObject) => {
  if ('CONTACT_' + (object as ContactLookup).type === EntityLinkType.CONTACT_COMPANY) {
    return (object as ContactLookup).company;
  }
  if ('CONTACT_' + (object as ContactLookup).type === EntityLinkType.CONTACT_PERSON) {
    return `${(object as ContactLookup).firstName} ${(object as ContactLookup).lastName}`;
  }

  if (object._entityType === EntityLinkType.NOTE || object._entityType === EntityLinkType.TASK) {
    return (object as NoteDTO | TaskDTO).title;
  }

  return (object as DocumentLookup | Asset | HoldingSetLookup).name;
};

export const filterObjectByName = (object: LinkableObject, searchValue: string) => {
  if ('CONTACT_' + (object as ContactLookup).type === EntityLinkType.CONTACT_COMPANY) {
    return (object as ContactLookup).company?.toLowerCase().trim().includes(searchValue.toLowerCase().trim());
  }
  if ('CONTACT_' + (object as ContactLookup).type === EntityLinkType.CONTACT_PERSON) {
    return `${(object as ContactLookup).firstName}${(object as ContactLookup).lastName}`.toLowerCase().trim().includes(searchValue.toLowerCase().trim());
  }

  if (object._entityType === EntityLinkType.NOTE || object._entityType === EntityLinkType.TASK) {
    return (object as NoteDTO).title.toLowerCase().trim().includes(searchValue.toLowerCase().trim());
  }

  return (object as DocumentLookup | Asset | HoldingSetLookup).name.toLowerCase().trim().includes(searchValue.toLowerCase().trim());
};

/**
 * @param activity
 * @param entityId the id of the entity you are currently looking at
 * @param entityType the type of the entity you are currently looking at
 *
 * @description Usually an activity has 4 related entities.
 * 1 the type of the activity with no name (ex: LINK). Its ID is the entityId of the activity
 * 2 the entity you are currently looking
 * 3 the user who created the activity
 * 4 the other entity
 *
 * @example You linked a note "note 1" to the asset "asset 1".
 * You are looking at "asset 1"
 * relatedEntity 1: type LINK, id is ID of the activity, no name;
 * relatedEntity 2: type ASSET, name "asset 1", id "asset 1" ID;
 * relatedEntity 3: type USER, name "your name", id your user ID;
 * relatedEntity 4: type NOTE, name "note 1", id "note 1" id;
 */
export const parseActivityRelatedEntities = (activity: ActivityDTO, entityId: string) => {
  const creator = activity.relatedEntities.find(act => act.isCreator)?.name!;

  const relatedEntity = activity.relatedEntities.find(act => !act.isCreator && act.id === entityId)?.name!;

  return { creator, relatedEntity };
};

/**
 *
 * @param siteTree
 * @param templateToFind
 * @param titleToFind
 * @description used for to redirect user by experience. Avoid switch between sesame-pro and erp
 */
export const findRoute = (siteTree: any, templateToFind: any, titleToFind?: any) => {
  const experience = window.location.pathname.split('/')[1];

  const experienceTree = siteTree.filter((entry: any) => entry.nodes[0] === experience);

  if (!experienceTree.length) {
    return;
  }

  const findUrl = (queue: SiteTreeEntry[]) => {
    while (queue.length) {
      const element = queue.pop()!;

      if (titleToFind) {
        if (titleToFind === element.title && templateToFind && element.view) {
          return element;
        }
      }

      if (!titleToFind && element.view === templateToFind) {
        return element;
      }

      if (element.children) {
        queue.push(...element.children);
      }
    }
  };

  let url = findUrl([...experienceTree]);

  if (!url) {
    url = findUrl([...siteTree]);
  }

  return url;
};

const sortingOrder: Record<EntityLinkTypeMenu, number> = {
  [EntityLinkTypeMenu.VEHICLE]: 1,
  [EntityLinkTypeMenu.PORTFOLIO]: 3,
  [EntityLinkTypeMenu.GROUPING]: 2,
  [EntityLinkTypeMenu.ASSET]: 4,
  [EntityLinkTypeMenu.CONTACT_PERSON]: 6,
  [EntityLinkTypeMenu.CONTACT_COMPANY]: 7,
  [EntityLinkTypeMenu.TASK]: 8,
  [EntityLinkTypeMenu.NOTE]: 9,
  [EntityLinkTypeMenu.DOCUMENT]: 5,
  [EntityLinkTypeMenu.OWNER]: 10
};

export const sortEntityType = (a: EntityLinkTypeMenu, b: EntityLinkTypeMenu) => {
  return sortingOrder[a] - sortingOrder[b];
};

export const userListMentionQuill = (searchTerm: any, renderList: any, users: User[] | null) => {
  const values = users?.map(user => {
    return {
      id: user.id,
      value: user.name,
      type: user.name
    };
  });

  if (searchTerm.length === 0) {
    renderList(values, searchTerm);
  } else {
    const matches = [];
    if (values) {
      for (const element of values) if (~element.value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(element);
    }
    renderList(matches, searchTerm);
  }
};

export const sortColumns = (a: string, b: string) => {
  if (a && !b) {
    return -1;
  }
  if (b && !a) {
    return 1;
  }
  if (!b && !a) {
    return 0;
  }
  return a.toLowerCase().localeCompare(b.toLowerCase());
};
