import React from 'react';
import type { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { useAlignmentClass } from '@/modules/reporting-v2/core/components/DataTablev2/hooks/useAlignmentClass';
import cx from 'classnames';

type TableWrapperProps = {
  visual: VisualEngine;
  rowClickable?: boolean;
  transpose?: boolean;
  children: React.ReactNode;
};

const TableWrapper: React.FC<TableWrapperProps> = ({ visual, rowClickable, transpose, children }) => {
  const alignmentClass = useAlignmentClass(visual);
  return (
    <div
      id={`data-table-${visual.id}`}
      className={cx('report-data-table', 'report-data-table-v2', ...alignmentClass, `data-table-${visual.component}`, { 'row-clickable': rowClickable, transpose: transpose })}
    >
      <table title={visual.title}>{children}</table>
    </div>
  );
};

export { TableWrapper };
