import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedDateSlicerConfig } from '..';
import { DateSlicerConfigMapped } from './types';
import { DateRange } from './VisualSpecificProps';

export default class DateSlicerConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as DateSlicerConfigMapped));
  }

  getVisualConfigProps(config: DateSlicerConfigMapped): ExtendedDateSlicerConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      fromDate: config.fromDate,
      toDate: config.toDate,
      startDateField: config.startDateField,
      endDateField: config.endDateField,
      dateRange: config.dateRange
    });
  }

  setVisualConfig(config: ExtendedDateSlicerConfig) {
    super.setVisualConfig(config);
    this.fromDate = config.fromDate;
    this.toDate = config.toDate;
    this.startDateField = config.startDateField;
    this.endDateField = config.endDateField;
    this.dateRange = config.dateRange;
  }

  startDateField?: string;
  fromDate?: string;
  toDate?: string;
  endDateField?: string;
  dateRange?: DateRange;
}
