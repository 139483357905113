import { requestApi } from '@/core';

export default (conversation_id: number, comment_id: number): Promise<void> =>
  requestApi({
    service: 'collaboration',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'delete',
    url: `/api/conversation/${conversation_id}/comment/${comment_id}`
  }).then(() => Promise.resolve());
