import React from 'react';
import { Column } from '@/modules/reporting-v2/core/Column';
import { SortOrder, VisualSort } from '@/modules/reporting-v2/types/VisualEngine';
import { Tooltip } from 'antd';
import cx from 'classnames';
import { useFixedColumn } from '../hooks/useFixedColumn';
import { useCategoryClass } from '../hooks/useCategoryClass';

interface ITableHeadProps {
  columns: Column[];
  displayCategories: boolean;
  handleSort: (order: VisualSort['order'], field: VisualSort['field'], custom: VisualSort['custom']) => void;
  sortable?: boolean;
  sort?: VisualSort;
  columnsHeaders?: Array<{ displayName: string; colSpan: number }>;
}

const columnStyles = {
  inlineDisplay: { display: 'inline-block' }
};

const TableHead: React.FC<ITableHeadProps> = React.memo(({ columns, displayCategories, sortable, sort, handleSort, columnsHeaders }) => {
  return (
    <thead data-testid="table-header">
      {displayCategories && columnsHeaders && (
        <tr className="columns-header">
          {columnsHeaders.map(header => {
            return (
              <th className="category" key={header.displayName} colSpan={header.colSpan}>
                {header.displayName}
              </th>
            );
          })}
        </tr>
      )}
      <tr>
        {columns
          .filter(col => col.isDefault)
          .map((col, index) => (
            <TableColumn key={col.id} col={col} columns={columns} index={index} handleSort={handleSort} sort={sort} sortable={sortable} displayCategories={displayCategories} />
          ))}
      </tr>
    </thead>
  );
});

interface TableColumnProps {
  col: Column;
  handleSort: ITableHeadProps['handleSort'];
  sort: ITableHeadProps['sort'];
  sortable?: boolean;
  columns: Column[];
  index: number;
  displayCategories: boolean;
}

const TableColumn: React.FC<TableColumnProps> = React.memo(({ col, handleSort, sort, sortable, columns, index, displayCategories }) => {
  const columnLeft = useFixedColumn(col);
  const categoryClass = useCategoryClass(columns, col, index, displayCategories);

  const onSort = () => {
    if (!sort || !sort.order) {
      handleSort(SortOrder.ASC, col.field.name, col.sortCustom);
      return;
    }

    if (sort.order === SortOrder.ASC) {
      handleSort(SortOrder.DESC, col.field.name, col.sortCustom);
      return;
    }

    if (sort.order === SortOrder.DESC) {
      handleSort(undefined, col.field.name, col.sortCustom);
    }
  };

  const style: React.CSSProperties = {
    ...(!col.visible && { display: 'none' }),
    top: 0
  };
  if (columnLeft !== undefined) {
    style.left = columnLeft;
    style.zIndex = 11;
    style.position = 'sticky';
  }
  const width = col.styling?.width;
  if (width) {
    style.width = width;
    style.maxWidth = width;
    style.minWidth = width;
  }

  return (
    <th data-key={col.field.name} key={col.id} style={style} className={categoryClass}>
      <span style={columnStyles.inlineDisplay}>
        <span className={sortable ? 'sortable' : ''}>
          <Tooltip title={col.formatting?.tooltipText}>{col.headerConfig.displayName ?? <>&nbsp;</>}</Tooltip>
          {sortable && (
            <span
              onMouseDown={onSort}
              className={cx('sort-icon', {
                [sort?.order as string]: sort && sort.field === col.field.name
              })}
            >
              <span className="arrow up" />
              <span className="arrow down" />
            </span>
          )}
        </span>
      </span>
    </th>
  );
});

export default TableHead;
