import { requestApi } from '@/core';
import { AxiosResponse } from 'axios';
import EntityRef from '../../../common/types/entity/EntityRef';
import { ConversationLookup, ConversationType, Conversation } from '../../../common/types/entity/Conversation';

export default (refType: ConversationType, refId: number | string): Promise<Conversation> =>
  requestApi({
    service: 'collaboration',
    method: 'get',
    url: `/api/conversation`,
    params: { refType, refId },
    errors: false
  }).then((response: AxiosResponse<ConversationLookup & EntityRef> | undefined) => Conversation.fromLookup(response.data));
