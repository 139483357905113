import { FormatType } from '../elastic/FormatType';

export enum LimitFormat {
  PERCENTAGE = 'PERCENTAGE',
  MONETARY = 'MONETARY'
}

export const castLimitFormatToSesameFormat = (limitFormat: LimitFormat) => {
  switch (limitFormat) {
    case LimitFormat.PERCENTAGE:
      return FormatType.percentage;
    case LimitFormat.MONETARY:
      return FormatType.price;
    default:
      return limitFormat;
  }
};
