export default (data: Record<string, any>): string => `
<style>
  @page {
    size: ${data.pageStyle.width}px ${data.pageStyle.height}px;
  }

  html, body, .report-page {
    min-width: 100vw;
    min-height: 100vh;
  }

  body {
    margin: 0;
    font-family: Lato, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .report-page {
    page-break-after: always;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
  }
</style>
`;
