import { Dict } from '@/modules/reporting-v2/types/Common';
import { VisualConfigProps } from '@/modules/reporting-v2/types/VisualEngine';
import { HistoricalChartAxis } from '@/modules/reporting-v2/core/visuals/HistoricalChart/types';

export default interface ExtendedHistoricalChartConfig extends VisualConfigProps {
  dateRange: DateRange;
  startDate?: string;
  dateFormat?: string;
  orientation: Orientation;
  tickRate: TickRate;
  compareValues: boolean;
  compareBase: '0' | '100' | undefined;
  alignAxisToCenter?: boolean;
  connectGaps: boolean;
  chartOptions?: Dict<string | boolean | Dict<string> | {}>;
  datapointsFrequency?: DataPointsFrequency;
  series?: Serie;
  isEntityBenchmark?: boolean;
  stacked?: boolean;
  stockTools?: {
    enabled?: boolean;
  };
  showTopSeries?: number;
  diagonalAxisLabel: boolean;
  yAxisInterval?: number;
  minValue?: number;
  maxValue?: number;
  showMarkers?: boolean;
  showValueInsideBars?: boolean;
  xAxis?: HistoricalChartAxis;
  yAxis?: HistoricalChartAxis;
  customSort?: string[];
}

export type Serie = {
  axis0: SerieAxis;
  axis1: SerieAxis;
};

type SerieAxis = Dict<{
  type: 'line' | 'bars' | 'area';
  stackedBar?: boolean;
  dataApproximation?: 'close' | 'average' | 'open' | 'sum';
}>;

export enum Orientation {
  ComboChart = 'ComboChart',
  BarChart = 'BarChart',
  AreaChart = 'AreaChart'
}

export enum TickRate {
  daily = 'daily',
  week_starts = 'week_starts',
  week_ends = 'week_ends',
  month_starts = 'month_starts',
  month_ends = 'month_ends',
  quarter_starts = 'quarter_starts',
  quarter_ends = 'quarter_ends',
  year_starts = 'year_starts',
  year_ends = 'year_ends',
  auto = 'auto'
}

export enum DateRange {
  FROM_INCEPTION = 'Inception',
  LAST_YEAR = 'Last year',
  LAST_3_MONTHS = 'Last 3 months',
  LAST_MONTH = 'Last month'
}

export enum DataPointsFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly'
}
