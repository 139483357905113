import { getTheme } from '@/modules/reporting-v2/utils/theme';
import { rbColorToHexAlpha } from '@/utils/toHexAlpha';
import type { HeaderFooterGenerator, MergedReportBrandingStyles } from '@/modules/reporting-v2/types/HeaderFooter';
import { getFontStyles, getFooterContent, getMainContent, getPaginationContent } from './utils';
import { getBrandingStyle } from '../utils';

const footerHeight = 32;
const ratio = 0.8;

export const defaultFooter: HeaderFooterGenerator = ({ title, pageProperties, reportStyle, entityParam, pageType, user }): string => {
  const style = {
    ...getBrandingStyle(user.company.branding),
    ...reportStyle
  } as MergedReportBrandingStyles;
  const { footerTitle = true, pagination = true, paginationAlign = 'right' } = style;

  const hideFooter = style.footerHide === true;
  const footerHasNoContent = !footerTitle && (!pagination || style.paginationPosition !== 'footer');
  if (hideFooter || footerHasNoContent) {
    return '<style>#footer { display: none; }</style>';
  }

  const paginationContent = getPaginationContent(style, paginationAlign, pagination, pageType);
  const mainContent = getMainContent(title, style, footerTitle, paginationAlign, entityParam, user);
  const footerContent = getFooterContent(mainContent, paginationContent, paginationAlign);

  return `
  <style>
    #footer {
      padding: 0;
      height: 100%;
      width: 100%;
      -webkit-print-color-adjust: exact;
    }
    #footer .footer-container {
      width: 100%;
      ${getFontStyles(style)}
      line-height: ${footerHeight * ratio}px;
      background: ${rbColorToHexAlpha(style.footerBackground) || 'none'};
      border-top: ${style.footerBorder?.style || 'none'} ${style.footerBorder?.width}px ${rbColorToHexAlpha(style.footerBorderColor) || 'transparent'};
      -webkit-print-color-adjust: exact;
    }
    #footer table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
    }
    #footer table td {
      padding: 0 ${32 * ratio * pageProperties.ratio}px;
    }
  </style>
  <div id="footer" class="report-theme-${getTheme()}">
    <div class="footer-container">
      <table>
        <tr>
        ${footerContent}
        </tr>
      </table>
    </div>
  </div>
  `;
};
