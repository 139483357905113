import { message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Row } from '@/modules/reporting-v2/types/VisualEngine';
import { DateRange } from '@/modules/reporting-v2/core/visuals/DateSlicer/VisualSpecificProps';

const getDates = (data: Array<Row>, hardFromDate?: string, hardToDate?: string, startDateField?: string, endDateField?: string, dateRange?: string, paramsDate?: string | null) => {
  if (dateRange === DateRange.FUTURE_DATES && paramsDate) {
    return [new Date(paramsDate).getTime(), new Date('2200-12-31').getTime()];
  }

  if (hardFromDate && hardToDate) {
    return [new Date(hardFromDate).getTime(), new Date(hardToDate).getTime()];
  }

  if (dateRange && hardToDate) {
    const toDate = dayjs(hardToDate);
    const startDate = fromDateRange(toDate, dateRange);

    return [startDate?.toDate().getTime(), toDate.toDate().getTime()];
  }

  if (startDateField && endDateField) {
    const startDateFromData = new Date(getSingleDateFromData(startDateField, data, 'earliest')!)?.getTime();
    const endDateFromData = new Date(getSingleDateFromData(endDateField, data, 'latest')!)?.getTime();

    return [startDateFromData, endDateFromData];
  }

  if (dateRange && endDateField) {
    const endDateFromData = new Date(getSingleDateFromData(endDateField, data, 'latest')!);
    const startDate = fromDateRange(dayjs(endDateFromData), dateRange);

    return [startDate?.toDate().getTime(), endDateFromData.getTime()];
  }

  if (dateRange && hardFromDate) {
    const toDate = new Date(hardFromDate).getTime();
    const fromDate = fromDateRange(dayjs(hardFromDate), dateRange)?.toDate().getTime();
    return [fromDate, toDate];
  }

  if (startDateField && hardToDate) {
    const startDateFromData = new Date(getSingleDateFromData(startDateField, data, 'earliest')!)?.getTime();
    const toDate = new Date(hardToDate).getTime();

    return [startDateFromData, toDate];
  }

  if (endDateField && hardFromDate) {
    const endDateFromData = new Date(getSingleDateFromData(endDateField, data, 'latest')!).getTime();
    const fromDate = new Date(hardFromDate).getTime();

    return [fromDate, endDateFromData];
  }

  if (dateRange && !hardToDate && !hardFromDate && !startDateField && !endDateField) {
    return [fromDateRange(dayjs(paramsDate), dateRange)?.toDate().getTime(), new Date(paramsDate!).getTime()];
  }

  // hardfromdate && !daterange && !hardtodate &&!enddatefield
  if (hardFromDate && !dateRange && !hardToDate && !endDateField) {
    return [new Date(hardFromDate).getTime(), new Date(paramsDate!).getTime()];
  }

  // stardatefield && !enddatefield
  if (startDateField && !endDateField) {
    const endDate = paramsDate ? new Date(paramsDate) : new Date();
    const startDateFromData = new Date(getSingleDateFromData(startDateField, data, 'earliest')!)?.getTime();

    return [startDateFromData, endDate.getTime()];
  }

  message.error('Invalid DateSlicer configuration. Please review the configuration.');
  return [undefined, undefined];
};

/* ↓↓↓↓↓ Dates comparing logic ------------------------------------- ↓↓↓↓↓ */

const getSingleDateFromData = (field: string, data: Array<Row>, criteria: string) => {
  return data.reduce<undefined | string>((accum, row: Row) => {
    const date = row.data[field] as string | undefined;
    if (date) {
      if (!accum) return date;
      switch (criteria) {
        case 'latest':
          return isLaterDate(accum, date) ? date : accum;
        case 'earliest':
          return isEarlierDate(accum, date) ? date : accum;
        default:
          return accum;
      }
    }
    return accum;
  }, undefined);
};

const isLaterDate = (accumDate: string, dateToCompare: string) => {
  const [year, month, day] = dateToCompare.split('-');
  const [accumYear, accumMonth, accumDay] = accumDate.split('-');

  if (year < accumYear) {
    return false;
  }

  if (year > accumYear) {
    return true;
  }

  if (month < accumMonth) {
    return false;
  }

  if (month > accumMonth) {
    return true;
  }

  if (day < accumDay) {
    return false;
  }

  if (day > accumDay) {
    return true;
  }

  return false;
};

const isEarlierDate = (accumDate: string, dateToCompare: string) => {
  const [year, month, day] = dateToCompare.split('-');
  const [accumYear, accumMonth, accumDay] = accumDate.split('-');

  if (year < accumYear) {
    return true;
  }

  if (year > accumYear) {
    return false;
  }

  if (month < accumMonth) {
    return true;
  }

  if (month > accumMonth) {
    return false;
  }

  if (day < accumDay) {
    return true;
  }

  if (day > accumDay) {
    return false;
  }

  return false;
};

const fromDateRange = (endDate: Dayjs, dateRange: string) => {
  switch (dateRange) {
    case 'FROM_INCEPTION':
      return undefined;
    case 'SELECTED_DATE':
      return endDate.clone();
    case 'YEAR_TO_DATE':
      return endDate.clone().startOf('year');
    case 'LAST_3_YEARS':
      return endDate.clone().subtract(3, 'years');
    case 'LAST_YEAR':
      return endDate.clone().subtract(1, 'years');
    case 'LAST_3_MONTHS':
      return endDate.clone().subtract(3, 'months');
    case 'LAST_MONTH':
      return endDate.clone().subtract(1, 'months');
    case 'LAST_WEEK':
      return endDate.clone().subtract(1, 'week');
    default:
      return endDate.clone().subtract(3, 'months');
  }
};

export { getDates };
