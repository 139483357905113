import React, { Key, useContext } from 'react';
import { Column } from '@/modules/reporting-v2/core/Column';
import { Field } from '@/modules/reporting-v2/core/Field';
import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { ColumnMetas } from '@/modules/reporting-v2/types/Column';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { EditableValue } from '../../index';
import { Row } from '@/modules/reporting-v2/types/VisualEngine';
import ReportContext from '@/modules/reporting-v2/core/ReportContext';
import { nanoid } from 'nanoid';

interface ITransposeTableProps {
  columns: Column[];
  totals: FlattenObject;
  handleColumnSelect: (field: Field, value: Primitive) => void;
  isVisualLevelHtmlEditOnly: boolean;
  rows: Row[];
  isStatsTable: boolean;
}

const TransposeTable: React.FC<ITransposeTableProps> = React.memo(({ isStatsTable, rows, columns, isVisualLevelHtmlEditOnly, totals }) => {
  const { reportConfiguration } = useContext(ReportContext);

  return (
    <tbody>
      {columns
        .filter(col => col.isDefault)
        .map((col, index) => {
          return (
            <tr key={col.id}>
              <td className="group">{col.headerConfig.displayName}</td>
              <td>
                <EditableValue
                  htmlEditOnly={isVisualLevelHtmlEditOnly}
                  key={totals[col.fieldDataPath] as Key}
                  metas={col as unknown as ColumnMetas}
                  defaultValue={totals[col.fieldDataPath]}
                  field={col.fieldDataPath}
                  data={totals}
                >
                  {VisualEngine.formatCell(totals[col.fieldDataPath], col, totals, undefined, undefined, reportConfiguration?.config.numberLocale)}
                </EditableValue>
              </td>
              {isStatsTable &&
                rows.map(row => {
                  return (
                    <td key={(totals[col.fieldDataPath] as Key) + nanoid()}>
                      <EditableValue
                        htmlEditOnly={isVisualLevelHtmlEditOnly}
                        key={totals[col.fieldDataPath] as Key}
                        metas={col as unknown as ColumnMetas}
                        defaultValue={totals[col.fieldDataPath]}
                        field={col.fieldDataPath}
                        data={totals}
                      >
                        {VisualEngine.formatCell(row.cells[index], col, undefined, undefined, undefined, reportConfiguration?.config.numberLocale)}
                      </EditableValue>
                    </td>
                  );
                })}
            </tr>
          );
        })}
    </tbody>
  );
});

export default TransposeTable;
