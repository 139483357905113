import deepmerge from 'deepmerge';

export const objectDeepMerge = <T extends Object>(...configs: T[]): T => {
  return deepmerge.all<T>(
    configs.filter(object => object !== null && typeof object !== 'undefined'),
    {
      arrayMerge: (_, array) => array
    }
  );
};
