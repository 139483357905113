import { isLandytechLogin } from '@/common/recoil/security.selectors';
import { datadogRum } from '@datadog/browser-rum';
import { dataDogEnabled } from '@/datadog';
import { Roles } from '@/utils/security';

export const getCurrentUser = (user, account, holdingSetTree, verifiedDates) => {
  return Promise.resolve()
    .then(async () => {
      if (dataDogEnabled) {
        datadogRum.setUser({
          id: user.userId,
          accountId: user.accountId,
          spaceId: user.spaceId,
          isSuperAdmin: user.isSuperAdmin,
          internal: user.acting || isLandytechLogin(user.login),
          accountType: account.type,
          accountCategory: account.category
        });
      }

      if (import.meta.env.VITE_ENV === 'production' && window.pendo) {
        // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
        // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
        // Call this function in your authentication promise handler or callback when your visitor and account id values are available
        // Please use Strings, Numbers, or Bools for value types.
        window.pendo.initialize({
          excludeAllText: true,
          visitor: {
            id: user.userId, // Required if user is logged in, default creates anonymous ID
            // email:        // Recommended if using Pendo Feedback, or NPS Email
            // full_name:    // Recommended if using Pendo Feedback
            // role:         // Optional

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
            isSuperAdmin: user.isSuperAdmin,
            internal: user.acting || isLandytechLogin(user.login)
          },

          account: {
            id: user.accountId, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
            // name:         // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
            type: account.type,
            category: account.category,
            onboarding: account.onboarding
          }
        });
      }

      const _user = {
        ...user,
        internal: user.roles?.includes(Roles.INTERNAL),
        company: { ...account, branding: account.branding }
      };
      return { user: _user, holdingSetTree, verifiedDates };
    })
    .then(({ user, holdingSetTree, verifiedDates }) => {
      let holdingSetIds = [];

      const getIds = items => {
        items.forEach(item => {
          holdingSetIds = holdingSetIds.concat(item.id, item.childrenIds);
          if (item.children.length) {
            getIds(item.children);
          }
        });
      };

      getIds(holdingSetTree);

      return {
        ...user,
        holdingSetTree,
        verifiedDates,
        holdingSetIds: [...new Set(holdingSetIds)]
      };
    });
};
