import React, { useMemo } from 'react';
import { ColumnMetas } from '@/modules/reporting-v2/types/Column';
import { FlattenObject } from '@/modules/reporting-v2/types/FlattenObject';
import { EditableValue } from '@/modules/reporting-v2/core/components';
import { assetNameField } from '@/modules/reporting-v2/core/Column';
import { getSingleAssetLink } from '@/modules/Ownership/components/Links';
import * as DataTableIcons from '../icons';
import { Text } from '@/common/components/Typography/Text';
import { siteTreeState } from '@/modules/App/recoil/app.atoms';
import { useRecoilValue } from 'recoil';
import { Space } from 'antd';

interface IProps {
  data: FlattenObject;
  group: string;
  onExpand: (e: any) => void;
  hasChildren: boolean;
  collapsible: boolean;
  assetNameColumn?: boolean;
}

const groupMetas = { field: 'group' };

const GroupContent: React.FC<IProps> = React.memo(({ onExpand, assetNameColumn, data, group, hasChildren, collapsible }) => {
  const siteTree = useRecoilValue(siteTreeState);
  const isAssetGroup = assetNameColumn && data[assetNameField] === group;

  const link = useMemo(() => {
    return getSingleAssetLink(data, siteTree);
  }, [data, siteTree]);

  const groupValue = useMemo(() => {
    if (!isAssetGroup || !link) {
      return group;
    }

    return (
      <Text link className="print-no-link">
        {group}
      </Text>
    );
  }, [isAssetGroup, link, group]);

  return (
    <Space size={4}>
      {hasChildren && collapsible && (
        <span onClick={onExpand} className="no-print">
          <DataTableIcons.Plus className="plus" />
          <DataTableIcons.Minus className="minus" />
        </span>
      )}
      <div
        onClick={() => {
          if (isAssetGroup && link) {
            window.open(link);
          }
        }}
      >
        <EditableValue key={group} data={data} metas={groupMetas as unknown as ColumnMetas} defaultValue={group} field="group" htmlEditOnly>
          {groupValue}
        </EditableValue>
      </div>
    </Space>
  );
});

export default GroupContent;
