import { VisualReportContext } from '@/modules/reporting-v2/types/VisualUtils';
import { DateRange } from './VisualSpecificProps';
import type RawScatterChartConfig from './types';

export const config: RawScatterChartConfig = {
  type: 'visual',
  _sampling: true,
  legend: true,
  series: [],
  axisLabel: true,
  showGroupChildren: true,
  showEmtpyValues: false,
  _dateRange: DateRange.SELECTED_DATE,
  _reportContext: VisualReportContext.NONE
};
