import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import { vertical as verticalCurve } from 'svg-line-curved';

const padding = 10;

interface ConnectionProps {
  color?: string;
  strokeWidth?: number;
  parent: HTMLElement;
  node: HTMLElement;
}

const Connection: React.FC<ConnectionProps> = ({ color = 'currentColor', strokeWidth = 2, parent, node }) => {
  const req = useRef<number>();

  const [{ width, d, height, x, y }, setData] = useState({
    d: '',
    width: 0,
    height: 0,
    x: 0,
    y: 0
  });

  useEffect(() => {
    const handleConnect = () => {
      const source = {
        width: parent.offsetWidth,
        height: parent.offsetHeight,
        x: parent.offsetLeft,
        y: parent.offsetTop
      };

      const target = {
        width: node.offsetWidth,
        height: node.offsetHeight,
        x: node.offsetLeft,
        y: node.offsetTop
      };

      const onLeftSide = target.x + target.width / 2 < source.x + source.width / 2;

      let width = Math.abs(source.x + source.width / 2 - (target.x + target.width / 2)) + padding * 2,
        height = Math.abs(source.y + source.height - target.y) + padding * 2,
        y = source.y + source.height - padding,
        x = (onLeftSide ? target.x + target.width / 2 : source.x + source.width / 2) - padding;

      const d = {
        source: {
          x: onLeftSide ? padding : width - padding,
          y: height - padding
        },
        target: {
          x: onLeftSide ? width - padding : padding,
          y: padding
        }
      };

      setData({
        d: verticalCurve(d.source.x, d.source.y, d.target.x, d.target.y),
        width,
        height,
        x,
        y
      });
    };

    const render = () => {
      handleConnect();
      req.current = window.requestAnimationFrame(render);
    };

    render();

    return () => {
      if (req) {
        window.cancelAnimationFrame(req.current as number);
      }
    };
  }, [node.offsetHeight, node.offsetLeft, node.offsetTop, node.offsetWidth, parent.offsetHeight, parent.offsetLeft, parent.offsetTop, parent.offsetWidth]);

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      style={{
        position: 'absolute',
        left: x,
        top: y,
        zIndex: -1
      }}
    >
      <path d={d} stroke={color} strokeWidth={strokeWidth} />
    </svg>
  );
};

export default Connection;
