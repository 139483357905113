import React, { useContext, useEffect, useMemo, useRef } from 'react';
import HighchartsReact from 'highcharts-react-official';
import 'highcharts/css/stocktools/gui.css';
import HC from 'highcharts/highstock';
import HCIndicators from 'highcharts/indicators/indicators-all';
import HCTrendline from 'highcharts/indicators/trendline';
import HCAnnotations from 'highcharts/modules/annotations-advanced';
import HCBrokenAxis from 'highcharts/modules/broken-axis';
import HCDragPanes from 'highcharts/modules/drag-panes';
import HCDrilldown from 'highcharts/modules/drilldown';
import HCExportData from 'highcharts/modules/export-data';
import HCExporting from 'highcharts/modules/exporting';
import HCMore from 'highcharts/highcharts-more';
import HCBullet from 'highcharts/modules/bullet';
import HCFS from 'highcharts/modules/full-screen';
import HCOfflineExporting from 'highcharts/modules/offline-exporting';
import HCPriceIndicator from 'highcharts/modules/price-indicator';
import HCStockTools from 'highcharts/modules/stock-tools';
import HCTreemap from 'highcharts/modules/treemap';
import { HighChartsDataUtils } from '@/modules/reporting-v2/core/HighchartsDataUtils';
import ReportContext, { MultiEntityFeatureContext } from '@/modules/reporting-v2/core/ReportContext';
import { ReportingService } from '@/modules/reporting-v2/core/ReportingService';
import { AllocationPieConfig, BarChartConfig, GaugeConfig, HistoricalChartConfig, ScatterChartConfig, TreeMapConfig } from '@/modules/reporting-v2/core/visuals';
import { AllocationPieUtils } from '@/modules/reporting-v2/core/visuals/AllocationPie/AllocationPieUtils';
import { BarChartUtils } from '@/modules/reporting-v2/core/visuals/BarChart/BarChartUtils';
import { GaugeUtils } from '@/modules/reporting-v2/core/visuals/Gauge/GaugeUtils';
import { HistoricalChartUtils } from '@/modules/reporting-v2/core/visuals/HistoricalChart/HistoricalChartUtils';
import { ScatterChartUtils } from '@/modules/reporting-v2/core/visuals/ScatterChart/ScatterChartUtils';
import { TreeMapUtils } from '@/modules/reporting-v2/core/visuals/TreeMap/TreeMapUtils';
import { Dict } from '@/modules/reporting-v2/types/Common';
import { VisualComponent } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { getTheme } from '@/modules/reporting-v2/utils/theme';
import colors from './colors.json';
import { useRecoilValue } from 'recoil';
import { companyBranding } from '@/recoil/branding';
import { objectDeepMerge } from '@/utils/objectDeepmerge';
import { parseChartStyles } from '@/modules/Styling/utils/parseChartStyles';
import { parseStyles } from '@/modules/Styling/utils/parseStyles';
import { mergeAxis } from '@/modules/reporting-v2/utils/chartAxisMerge';
import { shouldApplyStylesInWebview } from '@/modules/Styling/utils/shouldApplyStylesInWebview';
import type { ChartVisualConfig } from '@/modules/reporting-v2/types/Configs';
import type { ChartExtendedPoint, ChartOptions, ChartReference } from '@/modules/reporting-v2/core/components/Highcharts/types';

HCExporting(HC);
HCExportData(HC);
HCOfflineExporting(HC);
HCIndicators(HC);
HCTrendline(HC);
HCTreemap(HC);
HCDrilldown(HC);
HCDragPanes(HC);
HCAnnotations(HC);
HCPriceIndicator(HC);
HCFS(HC);
HCStockTools(HC);
HCBrokenAxis(HC);
HCMore(HC);
HCBullet(HC);

HC.wrap(HC.Axis.prototype, 'getLinePath', function (this: any, proceed, lineWidth) {
  const axis = this,
    brokenAxis = axis.brokenAxis,
    path = proceed.call(this, lineWidth),
    start = path[0];
  let x = start[1],
    y = start[2];

  (brokenAxis.breakArray || []).forEach(function (brk: { from: number; to: number; len: number }) {
    if (axis.horiz) {
      x = axis.toPixels(brk.from);
      path.splice(
        1,
        0,
        ['L', x - 4, y], // stop
        ['M', x - 9, y + 5],
        ['L', x + 1, y - 5], // left slanted line
        ['M', x - 1, y + 5],
        ['L', x + 9, y - 5], // higher slanted line
        ['M', x + 4, y]
      );
    } else {
      y = axis.toPixels(brk.from);
      path.splice(
        1,
        0,
        ['L', x, y - 4], // stop
        ['M', x + 5, y - 9],
        ['L', x - 5, y + 1], // lower slanted line
        ['M', x + 5, y - 1],
        ['L', x - 5, y + 9], // higher slanted line
        ['M', x, y + 4]
      );
    }
  });
  return path;
});

HC.dateFormats.Q = function (timestamp) {
  const date = new Date(timestamp);

  const month = date.getMonth();
  if (month < 3) {
    return '1';
  }

  if (month < 6) {
    return '2';
  }

  if (month < 9) {
    return '3';
  }

  return '4';
};

export function getChart(index: number): HC.Chart | undefined {
  return HC.charts.find(chart => chart?.index === index);
}

export function mergeChartOptions(...args: ChartOptions[]): ChartOptions {
  return HC.merge({}, ...args);
}

export interface IChartProps {
  fullSize?: boolean;
  visual: ChartVisualConfig;
}

const HighCharts = ({ visual, fullSize }: IChartProps) => {
  const chartRef = useRef<ChartReference>(null);
  const context = useContext(ReportContext);
  const companyStyles = useRecoilValue(companyBranding);

  const multiEntityFeatures = useContext(MultiEntityFeatureContext);
  const columns = useMemo(() => HighChartsDataUtils.getColumns(visual.columns), [visual.columns]);
  const isDrilldown = 'drilldown' in visual && visual.drilldown;
  const rows = useMemo(() => {
    if (visual.data.rowsByEntity.size > 0) {
      for (const entityId of Array.from(visual.data.rowsByEntity.keys())) {
        const newRows = HighChartsDataUtils.getRows(visual.data.rowsByEntity.get(entityId), isDrilldown);
        visual.data.rowsByEntity.set(entityId, newRows);
      }
    }
    return HighChartsDataUtils.getRows(visual.data.rows, isDrilldown);
  }, [visual.data.rows, visual.data.rowsByEntity, isDrilldown]);

  const rawGlobalStyles = objectDeepMerge(companyStyles.styles ?? {}, context.reportConfiguration?.config?.customStyling ? context.reportConfiguration.config.styles ?? {} : {});
  const parsedGlobalStyles = parseStyles(rawGlobalStyles);
  const parsedStyles = visual?.styles ? objectDeepMerge(parsedGlobalStyles, visual.styles) : parsedGlobalStyles;

  const chartStyles = parseChartStyles(parsedStyles) as ChartOptions;

  const crossFilter = (point: ChartExtendedPoint) => {
    if (context.replaceFilter && context.removeFilter && visual.crossFilterEmitter) {
      const {
        custom: { data }
      } = point;
      if (!ReportingService.metas[columns[0].code ?? columns[0].field.name]?.aggregation) {
        if (context.filters.find(filter => filter.field.name === columns[0].field.name || filter.field.name === columns[0].code)) {
          context.removeFilter(columns[0].field, data[columns[0].field.getElasticPath()]);
        } else {
          context.replaceFilter([
            {
              field: columns[0].field,
              value: data[columns[0].field.getElasticPath()]
            }
          ]);
        }
        context.refreshVisuals!(visual.id);
      }
    }
  };

  let chartOptions: ChartOptions = {};
  const chartDefaultOptions: ChartOptions = {
    stockTools: {
      gui: {
        enabled: false,
        buttons: ['indicators', 'separator', 'simpleShapes', 'flags', 'toggleAnnotations']
      }
    },
    chart: {
      style: {
        fontFamily: 'Lato, sans-serif'
      }
    },
    colors: (colors as Dict<string[]>)[getTheme()],
    tooltip: {
      shared: true, // Allows a better hover
      outside: true,
      useHTML: true,
      style: {
        zIndex: '9999'
      },
      pointFormatter: function (this: HC.Point) {
        try {
          const symbol = HighChartsDataUtils.getSymbolFormat(this.series);
          const value = 'change' in this ? this.change : this.y;
          const formattedValue = HighChartsDataUtils.formatCell(
            value as number | undefined,
            (this as ChartExtendedPoint).custom.column,
            (this as ChartExtendedPoint).custom.data,
            context.reportConfiguration?.config.numberLocale
          );
          return `<div>${symbol ?? ''} <b>${this.series.name}</b>: ${formattedValue}</div>`;
        } catch {
          return `<div>${this.series.name} ${this.y}</div>`;
        }
      }
    },
    yAxis: {
      opposite: false, // Keep left
      crosshair: true
    },
    legend: {
      itemDistance: 8,
      alignColumns: false,
      navigation: {
        enabled: false
      }
    },
    title: {
      text: undefined
    },
    exporting: {
      enabled: false,
      csv: {
        dateFormat: '%Y-%m-%d'
      }
    },
    credits: {
      enabled: false
    }
  };
  const chartEvents: ChartOptions = {
    drilldown: {
      activeDataLabelStyle: {
        textDecoration: 'none'
      }
    },
    plotOptions: {
      pie: {
        point: {
          events: {
            click() {
              crossFilter(this as ChartExtendedPoint);
            }
          }
        }
      },
      column: {
        point: {
          events: {
            click() {
              crossFilter(this as ChartExtendedPoint);
            }
          }
        }
      },
      bar: {
        point: {
          events: {
            click() {
              crossFilter(this as ChartExtendedPoint);
            }
          }
        }
      },
      line: {
        point: {
          events: {
            click() {
              crossFilter(this as ChartExtendedPoint);
            }
          }
        }
      },
      area: {
        point: {
          events: {
            click() {
              crossFilter(this as ChartExtendedPoint);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    // Resize on ZoomIn
    const { current } = chartRef;
    if (current) {
      const { chart: currentChart } = current;
      currentChart.reflow();
    }
  }, [fullSize]);

  switch (visual.component) {
    case VisualComponent.AllocationPie:
      chartOptions = AllocationPieUtils.getOptions(visual as AllocationPieConfig, columns, rows, context);
      break;
    case VisualComponent.HistoricalChart: {
      chartOptions = HistoricalChartUtils.getOptions(visual as HistoricalChartConfig, columns, rows, fullSize, multiEntityFeatures, context);
      break;
    }
    case VisualComponent.BarChart:
      chartOptions = BarChartUtils.getOptions(visual as BarChartConfig, columns, rows, multiEntityFeatures, context);
      break;
    case VisualComponent.ScatterChart:
      chartOptions = ScatterChartUtils.getOptions(visual as ScatterChartConfig, columns, rows, context);
      break;
    case VisualComponent.TreeMap:
      chartOptions = TreeMapUtils.getOptions(visual as TreeMapConfig, columns, rows);
      break;
    case VisualComponent.Gauge:
      chartOptions = GaugeUtils.getOptions(visual as GaugeConfig, columns, context, chartStyles.colors);
      break;
    default:
      throw new Error();
  }

  const mergedOptions = mergeChartOptions(chartDefaultOptions, chartEvents, chartOptions);

  let finalOptions = mergedOptions;

  const shouldApplyStyles = shouldApplyStylesInWebview(companyStyles.applyStylesInWebview, context.reportConfiguration?.config?.applyStylesInWebview);
  if (shouldApplyStyles) {
    finalOptions = mergeChartOptions(mergedOptions, chartStyles);
    finalOptions.yAxis = mergeAxis(mergedOptions, chartStyles, 'yAxis');
  }

  return <HighchartsReact ref={chartRef} containerProps={containerProps} highcharts={HC} immutable={true} allowChartUpdate options={finalOptions} />;
};

const containerProps = { style: { height: '100%' } };

export default HighCharts;
