import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { GhostVisualConfigMapped } from './types';
import ExtendedGhostVisualConfig from '@/modules/reporting-v2/core/visuals/GhostVisual/VisualSpecificProps';
import { getRange } from '@/modules/reporting-v2/types/HistoricalConfig';
import { HistoricalConfig } from '@/modules/reporting-v2/core/HistoricalConfig';

export default class GhostVisualConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config));
  }

  getVisualConfigProps(config: GhostVisualConfigMapped): ExtendedGhostVisualConfig {
    return config.isHistorical
      ? {
          ...super.getVisualConfigProps(config),
          historicalConfig: HistoricalConfig.enabled(getRange(config.dateRange), false)
        }
      : super.getVisualConfigProps(config);
  }

  setVisualConfig(config: ExtendedGhostVisualConfig) {
    super.setVisualConfig(config);
  }
}
