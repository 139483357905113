import { atom } from 'recoil';

const taskCreatedByFilterState = atom<string>({
  key: 'task-created-by-filter-state',
  default: undefined
});

const taskAssigneeFilterState = atom<string>({
  key: 'task-assignee-filter-state',
  default: undefined
});

const taskStatusFilterState = atom<string>({
  key: 'task-status-filter-state',
  default: undefined
});

const taskPriorityFilterState = atom<string>({
  key: 'task-priority-filter-state',
  default: undefined
});

const taskLinkedWithOptionFilterState = atom<string>({
  key: 'task-linked-filter-state',
  default: undefined
});

export { taskCreatedByFilterState, taskAssigneeFilterState, taskStatusFilterState, taskPriorityFilterState, taskLinkedWithOptionFilterState };
