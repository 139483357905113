import { report, visual, table, starRating } from '@/modules/reporting-v2/core/styles';
import { getThemeStyle } from '@/modules/reporting-v2/core/styles/themes';

export default (): string => `<style>
${report}
${visual}
${table}
${starRating}
${getThemeStyle()}
</style>`;
