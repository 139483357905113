import React, { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useValidation } from '@/modules/Onboarding/hooks/useValidation';
import { useFormContext } from '@/common/form';
import { createNote } from '@/modules/ERP/Contacts/Api/createNote';
import { NoteCreationPayload, NoteDTO } from '@/modules/ERP/Contacts/types';
import { message, Modal, Row, Typography } from 'antd';
import { TextInput } from '@/common/components/Input/TextInput';
import ReactQuill from 'react-quill';
import { contactsNotesSelector, filteredEntityActivitySelector, notesListTableSelector } from '@/modules/ERP/Contacts/Recoil/contacts.selectors';
import { constSelector, useRecoilCallback, useRecoilValue } from 'recoil';
import { UUID } from '@/common/types/types';
import { createLink } from '@/modules/ERP/Contacts/Api/createLink';
import { ActivityType, EntityLinkType, LinkableObject } from '@/common/types/entity/Link';
import { TaskSiderItem } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/Form/SiderItem';
import { SelectLinksContent } from '@/modules/ERP/Contacts/ContactDetails/Tabs/Tasks/Form/SelectLinks';
import { Text } from '@/common/components/Typography/Text';
import { descriptionMaxLength } from '../Tasks/CreateTask';
import { linkObjectSelector } from '@/modules/ERP/Common/Recoil/links.selector';
import { withSmallSuspense } from '@/common/suspense';
import { userListMentionQuill } from '@/modules/ERP/Common/utils';
import { loggedUserSelector, usersForCurrentUsers } from '@/common/auth/recoil/user.selector';
import { Form } from '@/common/form/form';
import { z } from 'zod';
import { string } from '@/common/form/schema';
import 'react-quill/dist/quill.bubble.css';
import 'quill-mention/dist/quill.mention.min.css';

interface CreateNoteProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  entityId?: UUID;
  entityType: EntityLinkType;
  search: string;
}

const CreateNote: React.FunctionComponent<CreateNoteProps> = withSmallSuspense(
  React.memo(({ isOpen, setIsOpen, entityId, entityType, search }) => {
    const _pageObject = useRecoilValue(
      entityId
        ? linkObjectSelector({
            type: entityType,
            id: entityId
          })
        : constSelector(null)
    );

    const pageObject = useMemo(() => {
      if (_pageObject) {
        return { ..._pageObject, _entityType: entityType };
      }

      return null;
    }, [_pageObject, entityType]);

    const translator = useIntl();
    const [form, setForms] = useState<Partial<NoteDTO>>({});
    const [selectedLinks, setSelectedLinks] = useState<Array<LinkableObject>>(pageObject !== null ? ([pageObject] as Array<LinkableObject>) : []);

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isUserInputValid, setIsUserInputValid] = useState(true);
    const maxAmountofCharactersAttained = form.content ? form.content.length >= descriptionMaxLength : null;

    const onChange = useCallback(
      (values: NoteDTO, key: string, value: string) => {
        const newState = { ...values, [key]: value };

        setForms(newState);
        if (key === 'description' && typeof value === 'string') {
          if (value.length >= descriptionMaxLength) {
            value = value.substring(0, descriptionMaxLength);
          }
          newState.content = value;
        }

        if (value !== undefined) {
          const updatedValues = { ...values, [key]: value };
          const validation = CreateNoteSchema.safeParse(updatedValues);
          if (validation.success) {
            setIsUserInputValid(true);
          } else {
            setIsUserInputValid(false);
          }
        }
      },
      [setForms]
    );

    const validation = useValidation(
      [
        {
          name: 'title',
          required: true
        },
        {
          name: 'content',
          required: true
        }
      ],
      form
    );

    const handleContentChange = useCallback((content: string) => onChange(form, 'content', content), [form, onChange]);

    const handleChangeQuill = useCallback(
      (content: string, delta, source, editor) => {
        const textContent = editor.getText().substring(0, descriptionMaxLength);
        handleContentChange(textContent);
      },
      [handleContentChange]
    );

    const formContext = useFormContext(form, onChange, false, false);

    const onClickSave = useRecoilCallback(
      ({ refresh }) =>
        () => {
          setConfirmLoading(true);
          createNote(form as NoteCreationPayload)
            .then(async responseNote => {
              try {
                await Promise.all(
                  selectedLinks.map(obj => {
                    createLink(EntityLinkType.NOTE, responseNote.id, {
                      type: obj._entityType,
                      entityId: obj.id as UUID
                    });
                  })
                );

                message.success(translator.formatMessage({ id: 'generic.successfullyAdded' }));
                setIsOpen(false);
                setForms({});
                setSelectedLinks([]);
                setTimeout(() => {
                  if (entityId) {
                    refresh(contactsNotesSelector([search, entityType, entityId]));
                  }
                  if (entityType === EntityLinkType.CONTACT_COMPANY || entityType === EntityLinkType.CONTACT_PERSON) {
                    refresh(filteredEntityActivitySelector([ActivityType.CONTACT, entityId!]));
                  } else {
                    refresh(filteredEntityActivitySelector([entityType as unknown as ActivityType, entityId!]));
                  }
                  refresh(notesListTableSelector);
                }, 1000);
              } catch (err) {
                message.error(
                  translator.formatMessage({
                    id: 'generic.somethingWentWrongWhenSavingLinks'
                  })
                );
              }
            })
            .catch(error => {
              message.error(error);
            })
            .finally(() => {
              setConfirmLoading(false);
            });
        },
      [form, selectedLinks, entityType]
    );

    const loggedUser = useRecoilValue(loggedUserSelector);
    const users = useRecoilValue(usersForCurrentUsers(loggedUser.accountId));

    const [mention, setMention] = useState({});
    useEffect(() => {
      setMention({
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        defaultMenuOrientation: 'top',
        source: (searchTerm: any, renderList: any) => userListMentionQuill(searchTerm, renderList, users)
      });
    }, [users]);

    const modules = useMemo(() => {
      return {
        toolbar: {
          container: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ size: ['small', false, 'large', 'huge'] }, { color: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
            ['link']
          ]
        },
        clipboard: { matchVisual: false },
        mention: mention
      };
    }, [mention]);
    const mustBeprovided = translator.formatMessage({ id: 'erp.mustBeProvided' });
    const mustNotContainOnlyWhiteSpaces = translator.formatMessage({ id: 'erp.mustNotContainOnlyWhiteSpaces' });
    const CreateNoteSchema = z.object({
      title: string({ required_error: translator.formatMessage({ id: 'generic.title' }) + ' ' + mustBeprovided })
        .noOnlyWhitespace({ message: translator.formatMessage({ id: 'generic.title' }) + ' ' + mustNotContainOnlyWhiteSpaces })
        .safeString()
        .simpleNameWithNumbers()
        .min(1)
    });
    const quillRef = useRef(null);

    useEffect(() => {
      if (quillRef.current) {
        const quill = quillRef.current.getEditor();
        quill.on('text-change', (delta, oldDelta, source) => {
          if (source === 'user') {
            const currentText = quill.getText();
            if (currentText.length > descriptionMaxLength) {
              const trimmedText = currentText.substring(0, descriptionMaxLength);
              quill.root.innerHTML = trimmedText;
            }
          }
        });
      }
    }, [form.content]);

    return (
      <Modal
        onOk={onClickSave}
        width={'600px'}
        className={'modal-contact-create'}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        okText={translator.formatMessage({ id: 'generic.saveNote' })}
        okButtonProps={{ disabled: !validation || !isUserInputValid }}
        destroyOnClose
        confirmLoading={confirmLoading}
      >
        <Typography.Title level={4}>{translator.formatMessage({ id: 'generic.createNote' })}</Typography.Title>
        <Row style={{ width: '100%' }}>
          <Form schema={CreateNoteSchema} initialValues={formContext?.object} onChange={onChange} style={{ width: '100%' }} layout="vertical">
            <Form.Item name="title" label={translator.formatMessage({ id: 'generic.title' })} colon={false} className={'modal-contact-create-title-field'} required>
              <TextInput maxLength={100} style={{ borderRadius: '5px', width: '100%' }} placeholder={translator.formatMessage({ id: 'generic.title' })} />
            </Form.Item>
          </Form>
        </Row>
        <Row style={{ width: '100%', display: 'block' }}>
          <Form.Item
            name="description"
            label={translator.formatMessage({ id: 'generic.description' })}
            colon={false}
            className={'modal-contact-create-description-field'}
            required
          ></Form.Item>
          <ReactQuill
            placeholder={translator.formatMessage({
              id: 'generic.enterNoteDescription'
            })}
            modules={modules}
            ref={quillRef}
            onChange={handleChangeQuill}
            onKeyDown={event => {
              if (maxAmountofCharactersAttained && event.key !== 'Backspace') {
                event.preventDefault();
              }
            }}
          />
          <Row justify="end">
            <Text type={Number.isInteger(form.content?.length) && (form.content?.length as number) >= descriptionMaxLength ? 'danger' : undefined}>
              {form.content?.replace(/<[^>]*>?/gm, '').length ?? 0} / {descriptionMaxLength}
            </Text>
          </Row>
        </Row>
        <Row>
          <TaskSiderItem
            title={translator.formatMessage({ id: 'generic.links' })}
            content={<SelectLinksContent selectedLinks={selectedLinks} setSelectedLinks={setSelectedLinks} defaultSelectedType={EntityLinkType.ASSET} />}
            clickableText={translator.formatMessage({ id: 'generic.xNumberLinks' }, { number: selectedLinks.length })}
          />
          {/*<TaskSider*/}
          {/*  icon={*/}
          {/*    <ShareAltOutlined*/}
          {/*      className={'modal-contact-create-sider-text-icon'}*/}
          {/*    />*/}
          {/*  }*/}
          {/*  title={translator.formatMessage({ id: 'share' })}*/}
          {/*  content={''}*/}
          {/*  clickableText={'Shared with'}*/}
          {/*/>*/}
        </Row>
      </Modal>
    );
  })
);

export { CreateNote };
