import React, { ReactElement } from 'react';
import dayjs from 'dayjs';
import { CheckCircleFilled, ExclamationCircleFilled, QuestionCircleFilled, WarningFilled } from '@ant-design/icons';
import { FlattenObject, Primitive } from '@/modules/reporting-v2/types/FlattenObject';
import { ColumnMetas } from '@/modules/reporting-v2/types/Column';
import { ColumnFormatting, ConditionalColor, ConditionalColorObject } from './Column';
import { error } from '@/utils/error';
import { FilterGroup } from './FilterGroup';
import { NumberFormatType } from '@/common/types/elastic/FormatType';
import { capitalizeFirstLetterOfSecondWord } from '@/utils/capitalizeFirstLetterOfSecondWord';
import { DATE_FORMAT } from 'ui-sesame-components';

export const colors = {
  red: '#ca4a41',
  green: '#59b38f',
  white: '#ffffff',
  black: '#000000'
};

export const customFormattingType = 'custom';

export const decimals = (length: number) => [...new Array(length)].map(() => '0').join('');

export const price = (value: Primitive, length: number | undefined, locale?: string, numberFormat?: NumberFormatType) => {
  const formatConfig: Intl.NumberFormatOptions = {
    maximumFractionDigits: length || 0,
    minimumFractionDigits: length || 0,
    ...getNumberFormattingOptions(numberFormat)
  };
  const numberFormatter = new Intl.NumberFormat(locale || 'en-US', formatConfig);

  return capitalizeFirstLetterOfSecondWord(numberFormatter.format(value as number)).replaceAll('\u202f', '\u00a0');
};

export const findRule = (mode: ConditionalColorObject, data?: FlattenObject) => {
  if (!data) {
    error('Color condition not supported with this visual.');
  }
  return mode.conditions?.find(condition => (condition.condition as FilterGroup).evaluate(data));
};

export const numberColor = (value: Primitive, mode: ConditionalColor, isPercentage: boolean, rawValue?: Primitive, data?: FlattenObject) => {
  let color = colors.black;

  const modeHasConditions = typeof mode !== 'string' && mode.conditions && mode.conditions.length;
  if (modeHasConditions) {
    const foundRule = findRule(mode, data);
    if (foundRule) {
      color = foundRule.value;
    }
  }
  if (!modeHasConditions) {
    color = value?.toString()?.includes('-') ? colors.red : colors.green;
  }

  const style = {} as React.CSSProperties;

  const modeHighlightIsDot = typeof mode !== 'string' && mode.highlight?.toLowerCase?.() === 'dot';
  if (modeHighlightIsDot) {
    return <Dot color={color} />;
  }
  const modeHighlightIsTag = typeof mode !== 'string' && mode.highlight?.toLowerCase?.() === 'tag';
  if (modeHighlightIsTag) {
    if (color !== colors.black && typeof color === 'string') {
      style.color = colors.white;
      style.background = color;
      style.padding = '0 4px';
      style.borderRadius = '4px';
    }
  }

  if (!modeHighlightIsTag) {
    style.color = color;
  }

  return <span style={style}>{value}</span>;
};

const getNumberFormattingOptions = (numberFormat: NumberFormatType | undefined): Intl.NumberFormatOptions => {
  switch (numberFormat) {
    case NumberFormatType.abbreviation:
      return {
        notation: 'compact',
        compactDisplay: 'short',
        minimumIntegerDigits: 1,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      };
    case NumberFormatType.decimal_format:
      return {
        notation: 'compact',
        compactDisplay: 'short',
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };

    case NumberFormatType.textual_represention:
      return {
        notation: 'compact',
        compactDisplay: 'long',
        minimumIntegerDigits: 1,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      };
    case NumberFormatType.full_number:
    default:
      return {};
  }
};

export const formatPrice = (
  value: Primitive,
  __: ColumnMetas,
  data: FlattenObject,
  formatting: ColumnFormatting,
  isChart?: boolean,
  forcedCurrency?: string | false,
  locale?: string
) => {
  let code = formatting.currencyDirectValue;

  if (!code) {
    const currency = formatting?.currency?.name;

    if (currency) {
      code = data[currency] as string;
    }
  }

  const currencyFormatOptions: Intl.NumberFormatOptions = {};

  if (code) {
    currencyFormatOptions.style = 'currency';
    currencyFormatOptions.currency = code;
  } else {
    currencyFormatOptions.style = 'decimal';
  }

  if (forcedCurrency) {
    currencyFormatOptions.currency = forcedCurrency;
    currencyFormatOptions.style = 'currency';
  }

  if (formatting.decimals !== undefined) {
    const minimumFractionDigits = parseInt(formatting.decimals, 10);
    currencyFormatOptions.minimumFractionDigits = typeof minimumFractionDigits !== 'number' || isNaN(minimumFractionDigits) ? 0 : minimumFractionDigits;
    const maximumFractionDigits = parseInt(formatting.decimals, 10);
    currencyFormatOptions.maximumFractionDigits = typeof maximumFractionDigits !== 'number' || isNaN(maximumFractionDigits) ? 10 : maximumFractionDigits;
  }

  if (formatting.compact) {
    currencyFormatOptions.notation = 'compact';
    currencyFormatOptions.compactDisplay = 'short';
    currencyFormatOptions.minimumSignificantDigits = formatting.forceDecimals ? undefined : 1;
    currencyFormatOptions.maximumSignificantDigits = formatting.forceDecimals ? undefined : 4;
  }

  const content = new Intl.NumberFormat(locale ?? 'en-US', { ...currencyFormatOptions, ...getNumberFormattingOptions(formatting.numberFormat) })
    .format(value as number)
    .replaceAll('\u202f', '\u00a0'); // narrow non breakable space to non breakable space

  let formattedValue = content as ReactElement | Primitive;

  const color = formatting.color;

  if (typeof color !== 'string' && color && color.highlight && !isChart) {
    formattedValue = numberColor(formattedValue as Primitive, color, false, value, data);
  }

  return formattedValue;
};

export default {
  percentage: (
    value: Primitive,
    columnMeta: ColumnMetas,
    data: FlattenObject,
    formatting: ColumnFormatting,
    isChart?: boolean,
    forceCurrency?: boolean,
    locale?: string
  ): ReactElement | Primitive => {
    const formatConfig: Intl.NumberFormatOptions = {
      style: 'percent'
    };

    if (formatting.decimals !== undefined) {
      const minimumFractionDigits = parseInt(formatting.decimals, 10);
      formatConfig.minimumFractionDigits = typeof minimumFractionDigits !== 'number' || isNaN(minimumFractionDigits) ? 0 : minimumFractionDigits;
      const maximumFractionDigits = parseInt(formatting.decimals, 10);
      formatConfig.maximumFractionDigits = typeof maximumFractionDigits !== 'number' || isNaN(maximumFractionDigits) ? 10 : maximumFractionDigits;
    }

    const numberFormatter = new Intl.NumberFormat(locale ?? 'en-US', { ...formatConfig, ...getNumberFormattingOptions(formatting.numberFormat) });

    const content = capitalizeFirstLetterOfSecondWord(numberFormatter.format(value as number)).replaceAll('\u202f', '\u00a0');
    if (formatting.color && !isChart) {
      return numberColor(content, formatting.color, true, value, data);
    }
    return content;
  },
  price: formatPrice,
  amountNoUnit: (
    value: Primitive,
    __: ColumnMetas,
    data: FlattenObject,
    formatting: ColumnFormatting,
    isChart?: boolean,
    forceCurrency?: boolean,
    locale?: string
  ): ReactElement | Primitive => {
    const content = price(value, parseInt(formatting.decimals!, 10), locale, formatting.numberFormat);

    if (formatting.color && !isChart) {
      return numberColor(content, formatting.color, false, undefined, data);
    }
    return content;
  },
  date: (value: Primitive, __: ColumnMetas, _: FlattenObject, formatting: ColumnFormatting) => {
    if (!formatting.format) {
      return dayjs(value as MomentInput).format(DATE_FORMAT.DATE);
    }
    return dayjs(value as MomentInput).format(formatting.format);
  },
  string: (value: Primitive, __: ColumnMetas, data: FlattenObject, formatting: ColumnFormatting, isChart?: boolean) => {
    if (formatting.color && !isChart) {
      return numberColor(value, formatting.color, false, undefined, data);
    }
    return value;
  },
  bool: (value: boolean, __: ColumnMetas, _: FlattenObject, ___: ColumnFormatting, isChart?: boolean): ReactElement | string => {
    if (isChart) {
      return value ? 'Yes' : 'No';
    }
    return <span style={{ fontSize: 14, fontWeight: 500, opacity: 0.85 }}>{value ? 'Yes' : 'No'}</span>;
  },
  limits: (value: string | boolean, __: ColumnMetas, _: FlattenObject, ___: ColumnFormatting, isChart?: boolean): ReactElement | string | null => {
    if (isChart) {
      return '';
    }

    const style = {
      fontSize: 20,
      marginLeft: 4,
      verticalAlign: -3
    };

    switch (value) {
      case 'WARNING':
        return (
          <WarningFilled
            style={{
              color: '#FFBF00',
              ...style
            }}
          />
        );
      case 'ALERT':
      case true:
        return (
          <ExclamationCircleFilled
            style={{
              color: '#f5222d',
              ...style
            }}
          />
        );
      case 'INVALID':
        return (
          <QuestionCircleFilled
            style={{
              color: '#bfbfbf',
              ...style
            }}
          />
        );
      case 'OK':
      case false:
        return (
          <CheckCircleFilled
            style={{
              color: '#59b38f',
              ...style
            }}
          />
        );
      default:
        return null;
    }
  }
};

type DotProps = {
  color: string;
};
export const Dot: React.FC<DotProps> = ({ color }) => {
  return <div data-testid="dot" style={{ borderRadius: '50%', width: 20, height: 20, background: color }} />;
};
