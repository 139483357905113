import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedFlowsTableConfig } from '..';
import { FlowsTableConfigMapped } from './types';
import { DateRange } from '@/modules/reporting-v2/core/visuals/HistoricalChart/VisualSpecificProps';

export default class FlowsTableConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as FlowsTableConfigMapped));
  }

  getVisualConfigProps(config: FlowsTableConfigMapped): ExtendedFlowsTableConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      dateRange: config.dateRange,
      categoryHeadersEnabled: config.categoryHeadersEnabled,
      collapsible: config._collapsible,
      total: config.total,
      subTotal: config.subTotal
    });
  }

  setVisualConfig(config: ExtendedFlowsTableConfig) {
    super.setVisualConfig(config);
    this.dateRange = config.dateRange;
    this.categoryHeadersEnabled = config.categoryHeadersEnabled;
    this.collapsible = config.collapsible;
    this.total = config.total;
    this.subTotal = config.subTotal;
    this.sortable = true;
  }

  dateRange: DateRange;
  categoryHeadersEnabled: boolean;
  collapsible: boolean;
  total: boolean;
  subTotal: boolean;
}
