import { VisualEngine } from '@/modules/reporting-v2/core/VisualEngine';
import { RawVisual } from '@/modules/reporting-v2/types/ReportBuilderTypesUtils';
import { ExtendedTopTabConfig } from '..';
import { TopTabConfigMapped } from './types';

export default class TopTabConfig extends VisualEngine {
  constructor(rawVisual: RawVisual, multiEntityFeatures?: boolean) {
    super(rawVisual, multiEntityFeatures);
    const config = this.getVisualMappedConfig(rawVisual.config);
    this.setVisualConfig(this.getVisualConfigProps(config as TopTabConfigMapped));
  }

  getVisualConfigProps(config: TopTabConfigMapped): ExtendedTopTabConfig {
    return Object.assign(super.getVisualConfigProps(config), {
      collapsible: config._collapsible,
      subTotal: config.subTotal,
      total: config.total,
      categoryHeadersEnabled: config.categoryHeadersEnabled,
      limit: parseInt(config.limit, 10) || 10
    });
  }

  setVisualConfig(config: ExtendedTopTabConfig) {
    super.setVisualConfig(config);
    this.collapsible = config.collapsible;
    this.subTotal = config.subTotal;
    this.total = config.total;
    this.categoryHeadersEnabled = config.categoryHeadersEnabled;
    this.limit = config.limit;
  }

  collapsible: boolean;
  subTotal: boolean;
  total: boolean;
  categoryHeadersEnabled: boolean;
  limit: number;
}
