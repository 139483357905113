import { StylesConfig } from '@/modules/reporting-v2/types/VisualEngine';
import defaultColors from '@/modules/reporting-v2/core/components/Highcharts/contrastColors.json';
import { getTheme } from '@/modules/reporting-v2/utils/theme';
import { removeEmpty } from '@/utils/removeEmpty';

const defaultValues = {
  fontFamily: 'Lato, sans-serif',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 'normal'
};

export const parseChartStyles = (stylesConfig: StylesConfig): Highcharts.Options => {
  let colors = stylesConfig.chart?.chartColors ?? [];

  if (colors.length > 0) {
    // 35 is the amount of colors there are in the default colors.
    // If the chart contains custom colors, we want to repeat them enough times (35 here, like default colors)
    // so the chart does not run out of colors if there are too few.
    while (colors.length < 35) {
      colors = colors.concat(colors);
    }
  }

  const yAxis: Highcharts.Options['yAxis'] = [
    {
      gridLineColor: stylesConfig.chart?.gridLineColor,
      labels: {
        style: {
          width: stylesConfig.chart?.yAxis?.labels?.width,
          whiteSpace: stylesConfig.chart?.yAxis?.labels?.whiteSpace ?? 'wrap',
          textOverflow: stylesConfig.chart?.yAxis?.labels?.textOverflow ?? 'none',
          fontFamily: stylesConfig.chart?.label?.fontFamily ?? defaultValues.fontFamily,
          fontStyle: stylesConfig.chart?.label?.fontStyle ?? defaultValues.fontStyle,
          fontWeight: (stylesConfig.chart?.label?.fontWeight as string) ?? defaultValues.fontWeight,
          fontSize: stylesConfig.chart?.label?.fontSize ? (stylesConfig.chart?.label?.fontSize as string) : defaultValues.fontSize,
          color: stylesConfig.chart?.label?.color
        }
      }
    }
  ];
  yAxis[0] = removeEmpty(yAxis[0]);
  yAxis[1] = yAxis[0];
  if (stylesConfig.gauge?.valueLabel) {
    yAxis[1] = {
      ...yAxis[0],
      labels: {
        style: {
          ...yAxis[0].labels?.style,
          color: stylesConfig.gauge?.valueLabel?.color,
          fontFamily: stylesConfig.gauge?.valueLabel?.fontFamily ?? defaultValues.fontFamily,
          fontStyle: stylesConfig.gauge?.valueLabel?.fontStyle ?? defaultValues.fontStyle,
          fontWeight: (stylesConfig.gauge?.valueLabel?.fontWeight as string) ?? defaultValues.fontWeight,
          fontSize: stylesConfig.gauge?.valueLabel?.fontSize ? (stylesConfig.gauge?.valueLabel?.fontSize as string) : defaultValues.fontSize
        }
      }
    };
  }

  const chartStyles = {
    colors: colors.length ? colors : (defaultColors as Record<string, string[]>)[getTheme()],
    plotOptions: {
      pie: {
        dataLabels: {
          connectorColor: stylesConfig.chart?.connectorColor ?? '#707070'
        }
      },
      series: {
        dataLabels: {
          style: {
            fontFamily: stylesConfig.chart?.dataLabel?.fontFamily ?? defaultValues.fontFamily,
            fontSize: stylesConfig.chart?.dataLabel?.fontSize ? stylesConfig.chart?.dataLabel?.fontSize : defaultValues.fontSize,
            color: stylesConfig.chart?.dataLabel?.color
          }
        }
      },
      gauge: {
        dataLabels: {
          style: {
            color: stylesConfig.chart?.dataLabel?.color ?? 'black'
          }
        },
        dial: {
          baseLength: stylesConfig.gauge?.dial?.baseLength,
          rearLength: stylesConfig?.gauge?.dial?.rearLength,
          radius: stylesConfig?.gauge?.dial?.radius,
          backgroundColor: stylesConfig?.gauge?.dial?.backgroundColor,
          baseWidth: stylesConfig?.gauge?.dial?.baseWidth,
          borderColor: stylesConfig?.gauge?.dial?.borderColor,
          borderWidth: stylesConfig?.gauge?.dial?.borderWith as number | undefined,
          topWidth: stylesConfig?.gauge?.dial?.topWidth
        },
        pivot: {
          radius: stylesConfig?.gauge?.pivot?.radius,
          backgroundColor: stylesConfig?.gauge?.pivot?.backgroundColor,
          borderColor: stylesConfig?.gauge?.pivot?.borderColor,
          borderWidth: stylesConfig?.gauge?.pivot?.borderWith
        }
      }
    },
    drilldown: {
      activeDataLabelStyle: {
        textDecoration: 'none',
        fontFamily: stylesConfig.chart?.label?.fontFamily ?? defaultValues.fontFamily,
        fontSize: stylesConfig.chart?.label?.fontSize ? (stylesConfig.chart?.label?.fontSize as string) : defaultValues.fontSize,
        color: stylesConfig.chart?.label?.color,
        activeDataLabelStyle: {
          color: stylesConfig.chart?.connectorColor ?? '#707070',
          connectorColor: '#707070'
        }
      }
    },
    chart: {
      plotShadow: stylesConfig.chart?.plotShadow,
      backgroundColor: 'transparent',
      plotBackgroundColor: stylesConfig.chart?.backgroundColor ?? 'transparent',
      plotBorderColor: stylesConfig.chart?.borderColor ?? '#ffffff',
      plotBorderWidth: stylesConfig.chart?.borderColor ? 1 : undefined,
      className: stylesConfig.chart?.className ?? undefined
    },
    yAxis: yAxis,
    xAxis: {
      gridLineColor: stylesConfig.chart?.gridLineColor,
      labels: {
        style: {
          width: stylesConfig.chart?.xAxis?.labels?.width,
          whiteSpace: stylesConfig.chart?.xAxis?.labels?.whiteSpace ?? 'wrap',
          textOverflow: stylesConfig.chart?.xAxis?.labels?.textOverflow ?? 'none',
          fontFamily: stylesConfig.chart?.label?.fontFamily ?? defaultValues.fontFamily,
          fontStyle: stylesConfig.chart?.label?.fontStyle ?? defaultValues.fontStyle,
          fontWeight: stylesConfig.chart?.label?.fontWeight ?? defaultValues.fontWeight,
          fontSize: stylesConfig.chart?.label?.fontSize ? stylesConfig.chart?.label?.fontSize : defaultValues.fontSize,
          color: stylesConfig.chart?.label?.color
        }
      }
    },
    legend: {
      itemStyle: {
        fontFamily: stylesConfig.chart?.legend?.fontFamily ?? defaultValues.fontFamily,
        fontStyle: stylesConfig.chart?.label?.fontStyle ?? defaultValues.fontStyle,
        fontWeight: stylesConfig.chart?.label?.fontWeight ?? defaultValues.fontWeight,
        fontSize: stylesConfig.chart?.legend?.fontSize ? stylesConfig.chart?.legend?.fontSize : defaultValues.fontSize,
        color: stylesConfig.chart?.legend?.color
      }
    }
  } as Highcharts.Options;

  return removeEmpty(chartStyles);
};
